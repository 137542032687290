import * as React from 'react';
import { Image, Link } from 'rsv8-components';
import styled from 'styled-components';

const Span = styled.span`
  display: block;
  img {
    max-width: 100%;
    @media (max-width: 768px) {
      margin: 10px 0 0 5px;
    }
  }
`;

const Logo = () => (
  <Span>
    <Link to="/home">
      <Image themeVariation="secondary-program-logo1" />
    </Link>
  </Span>
);

export default Logo;
