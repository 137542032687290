import { IndividualNomination } from '../../../../types/index';
import * as events from '../../../events';
import { individualNominationSelectors } from '../../../selectors/index';
import { clearCurrentNomination } from '../nomineeSave/index';

export const selectProductBase = (appKey): IndividualNomination.Action => {
  return {
    type: events.SELECT_PRODUCT,
    payload: appKey
  };
};

export const setIndividualNominationProductId = (value) => ({
  type: events.SET_INDIVIDUAL_NOMINATION_PRODUCT,
  payload: value
});

const setNewProduct = (dispatch, value) => {
  dispatch(clearCurrentNomination());
  dispatch(selectProductBase(value));
};

const selectProduct = (value, confirmText = 'Changing Product now will clear later steps.') => (dispatch, getState) => {
  const state = getState();
  if (individualNominationSelectors.getCurrentProductId(state) !== value) {
    const clearNominationData = individualNominationSelectors.getClearCurrentNominationData(getState());
    if (clearNominationData) {
      setNewProduct(dispatch, value);
    } else if (individualNominationSelectors.getCurrentCustomFields(state).length === 0 || confirm(confirmText)) {
      setNewProduct(dispatch, value);
    }
  }
};

export default selectProduct;
