import styled from 'styled-components';
import { AddToCartButton, LinkButton } from '../../../components/ProductTable/ProductTableStyles';

export const FavoritePanel = styled.div`
  box-shadow: 0 2px 17px 2px rgba(28, 39, 76, 0.09);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 25px;
  min-height: 213px;
  padding: 15px;
` as any;

export const RemoveButton = styled(AddToCartButton)`
  margin-right: 25px;
` as any;

export const UndoFavoritePanel = styled(FavoritePanel)`
  flex-direction: row;
  justify-content: space-between;
  min-height: 75px;
  padding: 15px 30px;
` as any;

export const UndoContent = styled.div`
  align-items: center;
  display: flex;
` as any;

export const UndoButton = styled(LinkButton)`
  padding-bottom: 1px;
  padding-left: 20px;
` as any;

export const PanelContainer = styled.div`
  display: flex;
` as any;

export const PanelImage = styled.img`
  height: 97px;
  width: 97px;
  align-self: center;
` as any;

export const PanelInformation = styled.div`
  margin-left: 20px;
` as any;

export const PanelName = styled.div`` as any;

export const PanelPoints = styled.div`
  padding: 10px 0;
` as any;

export const PanelButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
` as any;
