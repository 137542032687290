import { push } from 'react-router-redux';
import { ActivityAddModel } from 'xcel-api-generator/dist/social';
import { destroy, destroyRequest, getRequestEndpoint } from 'xcel-redux-orm';
import { convertToPatchOperations } from 'xcel-util';
import * as events from '../../events';
import contentCultureSelector from '../../selectors/contentCulture';
import { activitiesSelector, validVersionToRemove } from '../../selectors/social';
import { setVersionAndCulture } from './cultures';
import { debounce } from './helpers';
import {
  createActivityTypesByActivityTypeActivities,
  deleteActivitiesById,
  getActivities as getActivitiesAPI,
  getActivitiesByIdDetails as getActivitiesDetails,
  patchActivitiesById
  } from './mapActions';

export const setActivityId: any = (activityId) => ({
  type: events.SET_ACTIVITY_ID,
  activityId
});

export const getActivities: any = (queries = {}, options = {}) => async (dispatch, getState) => {
  debounce(async () => {
    try {
      dispatch(destroyRequest('getActivities'));
      dispatch(destroy('socialDetailModal', null));
      await dispatch(getActivitiesAPI(queries, options));
    } catch (error) {
      alert(`An error has occurred listing all activities: ${error.message}`);
    }
  });
};

export const getActivitiesByIdDetails: any = (activityId) => async (dispatch, getState) => {
  // removing the current activity in order to bring the details
  // and inject in the redux state
  dispatch(destroyRequest('getActivitiesByIdDetails'));
  try {
    await dispatch(getActivitiesDetails({ id: activityId }));
    dispatch(setActivityId(activityId));
    // setting the default culture and version for editing
    setVersionAndCulture(dispatch, getState(), activityId);
  } catch (error) {
    alert(`An error has occurred getting activity details: ${error.message}`);
  }
};

export const editActivityById: any = (id: string, activity: any) => async (dispatch) => {
  debounce(async () => {
    await dispatch(patchActivitiesById({ id, body: convertToPatchOperations(activity) }));
    // getting again the social activity edited from api
    dispatch(destroyRequest('getActivitiesByIdDetails'));
    await dispatch(getActivitiesDetails({ id }));
  });
};

export const createActivity: any = (activityType: string, body: ActivityAddModel) => async (dispatch, getState) => {
  const { data } = await dispatch(
    createActivityTypesByActivityTypeActivities({
      activityType,
      body
    })
  );

  dispatch(push(`/social/edit/${data.id}`));

  // Update the new activity and set it as Published by default
  // but its version should be still in Draft
  await dispatch(editActivityById(data.id, { isPublished: true }));
};

export const removeActivity: any = (activityId: string) => async (dispatch, getState) => {
  try {
    // first get the details of this specific activityType
    await dispatch(getActivitiesDetails({ id: activityId }));
    const state = getState();
    const activity = activitiesSelector.selectOne(state, activityId);
    // validating if the activity has content or published versions
    // if it so, it couldn't be removed
    const notValidToDelete = activity.cultures.some(({ id }) => {
      const { versions } = contentCultureSelector.selectOne(state, id);

      return versions.some((version) => !validVersionToRemove(version));
    });

    if (!notValidToDelete) {
      dispatch(destroy('socialDetailModal', [activityId]));
      await dispatch(deleteActivitiesById({ id: activityId }));
    } else {
      throw new Error(`This activity has content or has been published in a culture.`);
    }
  } catch (error) {
    let message: string;
    const request = getRequestEndpoint(getState(), 'deleteActivitiesById');
    // when it's a request issue
    if (request && !request.succeeded) {
      const resMessage = request.error.response.data.errors;
      message = resMessage && resMessage[0] && resMessage[0].title;
      // or when it's a logic/code issue here
    } else {
      message = error.message;
    }

    alert(`Cannot delete activity: ${message}`);
  }
};
