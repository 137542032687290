import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
`;

export const LikeActionWrapper = styled.div`
  min-width: 90px;
`;
