import * as React from 'react';
import { register, withContent, withTheme } from 'xcel-react-core';
import { TextStyle } from './Text';
const Header = (Component) => ({ text, children, ...props }: any) => {
  const StyledHeader = TextStyle.withComponent(Component);
  return <StyledHeader {...props}>{text || children}</StyledHeader>;
};

const mapHeaderContent = (getContent) => ({
  text: getContent('text', { type: 'text' })
});

const withHeaderContent = withContent(mapHeaderContent);

const withHeaderTheme = withTheme();

const createTypography = (name: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6') => {
  return register(`rsv8-components/typography/${name}`)(withHeaderContent, withHeaderTheme)(Header(name));
};

export const HeaderOne = createTypography('h1') as any;

export const HeaderTwo = createTypography('h2') as any;

export const HeaderThree = createTypography('h3') as any;

export const HeaderFour = createTypography('h4') as any;

export const HeaderFive = createTypography('h5') as any;

export const HeaderSix = createTypography('h6') as any;
