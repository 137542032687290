import { AnyAction, combineReducers } from 'redux';
import fieldReducer from './field.reducer';
import fieldTypeReducer from './fieldTypes.reducer';
import formReducer from './form.reducer';
import validationReducer from './validations.reducer';

export { AnyAction };

export default combineReducers({
  form: formReducer,
  field: fieldReducer,
  fieldTypes: fieldTypeReducer,
  validations: validationReducer
});
