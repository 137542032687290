import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Text } from 'rsv8-components';
import { register, withResource } from 'xcel-react-core';
import { getAccountSummary, getProduct, postCart } from '../../redux/actions';
import { getCurrentAccountSummary, getProductDetail } from '../../redux/selectors';
import { isCartDisabled } from '../../utils/cart';
import { Breadcrumb } from './Breadcrumb';
import { DetailFavoritesButton } from './DetailFavoritesButton';
import { ImageViewer } from './ImageViewer';
import { ProductGroups } from './ProductGroups';
import { ProductOptions } from './ProductOptions';
import { ProductPromo } from './ProductPromo';
import {
  AddToCartButton,
  ButtonContainer,
  CartIconContainer,
  ContactUsLink,
  ContentContainer,
  DescriptionToggle,
  DescriptionToggleIcon,
  DetailLink,
  HiddenProductId,
  MobileImage,
  MobileSlider,
  PRODUCT_DESCRIPTION_HEIGHT,
  ProductDescription,
  ProductDescriptionHeading,
  ProductName,
  ResultsIcon,
  ShippingDescription,
  ShopCartIcon,
  SingleImage
  } from './styles';
// import { parse } from 'query-string';

class ProductDetail extends React.Component<any, any> {
  productOptionSelection: Array<any>;
  productGroupsSelection: Array<any>;
  private productDescription: React.Component;

  constructor(props: any) {
    super(props);
    this.productOptionSelection = [];
    this.productGroupsSelection = [];
    this.state = {
      detailExpanded: false,
      detailHidden: false,
      images: [],
      selectedImage: '',
      settings: {
        arrows: false,
        className: 'slider variable-width',
        dots: true,
        slidesToScroll: 1,
        slidesToShow: 1,
        speed: 500
      },
      isAddCartDisabled: false,
      imageLoaded: false,
      productGroupProduct: {}
      // defaultGroupProduct: undefined
    };
  }

  componentDidMount() {
    let product;
    this.props.actions.getProduct(decodeURIComponent(this.props.match.params.id)).then((res) => {
      product = this.props.product;
      product && product.imageLg && this.setState({ selectedImage: product.imageLg });
      window.addEventListener('resize', this.checkHeight);
      this.checkHeight();
      // if (qs.defaultGroupProduct) {
      //   const currentProduct = product.groupedProducts.filter((a) => a.productId === atob(qs.defaultGroupProduct))[0];
      //   this.setState({ productGroupProduct: currentProduct });
      // }
      if (product && product.alternateImages && product.alternateImages.length > 0) {
        let images = [
          {
            image: product.imageLg,
            imageResponsive: product.imageLg,
            href: '#'
          }
        ];

        product.alternateImages.forEach((image) => {
          images.push({
            image: image.imageLg,
            imageResponsive: image.imageLg,
            href: '#'
          });
        });
        this.setState({ images });
      }
      if (product && product.productGroupId) {
        this.setState({ isAddCartDisabled: true });
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkHeight);
  }

  getProductDescriptionContent = (component: React.Component) => {
    this.productDescription = component;
  };

  checkHeight = () => {
    if (!this.props.product) return;

    const { detailHidden } = this.state;
    const nodeContent = findDOMNode(this.productDescription) as HTMLElement;
    const rectNode = nodeContent && nodeContent.getBoundingClientRect();
    const descriptionHeight = rectNode && rectNode.height;

    if (descriptionHeight > PRODUCT_DESCRIPTION_HEIGHT && !detailHidden) {
      this.setState({ detailHidden: true });
    }
  };

  showDetail = () => {
    return this.props.product && this.props.product.id === decodeURIComponent(this.props.match.params.id);
  };

  addToCart = (product) => (e) => {
    this.setState({ isAddCartDisabled: true });
    let optionSelected = findDOMNode(this.productOptionSelection[product.id]) as any;
    if (optionSelected) {
      optionSelected = optionSelected.value;
    }
    let groupedProductSelected = findDOMNode(this.productGroupsSelection[product.id]) as any;
    if (groupedProductSelected) {
      groupedProductSelected = product.groupedProducts.find((prod) => prod.productId === groupedProductSelected.value);
    }
    this.props.actions.postCart(product, optionSelected, groupedProductSelected).then((res) => {
      this.setState({ isAddCartDisabled: false });
    });
  };

  // showTrending = (product) => {
  //   const maxLevel = product.categories.reduce((prev, current) => {
  //     return prev.level > current.level ? prev : current;
  //   });
  //   return (
  //     <TrendingCarousel
  //       cardPath={`/catalog/search?filterCategories=${maxLevel.id}`}
  //       cardText={`Shop All ${maxLevel.name}`}
  //       filterDepartment={maxLevel.id}
  //       title={`You might also like`}
  //     />
  //   );
  // };

  changeProductGroupsProduct = () => {
    var node = findDOMNode(this.productGroupsSelection[this.props.product.id]) as any;
    if (node == null) return;
    const currentValue = node.value;
    const currentProduct = this.props.product.groupedProducts.filter((a) => a.productId === currentValue)[0];
    if (currentProduct === undefined || Object.keys(currentProduct).length === 0) {
      this.setState({ isAddCartDisabled: true });
    } else {
      this.setState({ isAddCartDisabled: false });
    }
    this.setState({ productGroupProduct: currentProduct });
  };

  changeSelectedImage = (imagePath) => (e) => {
    if (imagePath !== this.state.selectedImage) {
      this.setState({ selectedImage: imagePath });
    }
  };

  toggleFullDescription = () => (e) => {
    this.setState({ detailExpanded: !this.state.detailExpanded });
  };

  canShowFavoritesButton = () => {
    return true;
  };

  render() {
    const {
      accountSummary,
      product,
      addToCartText,
      lessDetailLabel,
      moreDetailLabel,
      productDescriptionText,
      backToResultsText,
      location,
      contactUsForDetails
    } = this.props;

    return (
      <Grid themeVariation="page-wrapper">
        {this.showDetail() && (
          <React.Fragment>
            {product && this.props.location && this.props.location.search && (
              <Row>
                <Col xs={22} xsOffset={1} sm={24} smOffset={0} md={12} mdOffset={0}>
                  {this.props.location.search.includes('filterCategories') && (
                    <Breadcrumb location={location} product={product} />
                  )}
                  <DetailLink to={`/catalog/search${this.props.location.search}`}>
                    <ResultsIcon name="angle-left" />
                    {backToResultsText}
                  </DetailLink>
                </Col>
              </Row>
            )}

            <Row>
              <Col xsHidden={true} smHidden={true} md={12}>
                {product.alternateImages && product.alternateImages.length > 0 ? (
                  <ImageViewer
                    handleChange={this.changeSelectedImage}
                    currentImage={this.state.selectedImage}
                    product={product}
                  />
                ) : (
                  <SingleImage
                    loaded={this.state.imageLoaded}
                    onLoad={() => this.setState({ imageLoaded: true })}
                    src={this.state.selectedImage}
                  />
                )}
                <HiddenProductId>{product.productId}</HiddenProductId>
              </Col>
              <Col xs={22} xsOffset={1} sm={24} smOffset={0} md={12} mdOffset={0}>
                <ContentContainer>
                  <DetailLink to={`/catalog/search?filterBrands=${product.manufacturerId}`}>
                    {product.manufacturer}
                  </DetailLink>
                  {product.groupedProducts && product.groupedProducts.length > 0 ? (
                    <ProductName dangerouslySetInnerHTML={{ __html: product.productGroupName }} />
                  ) : (
                    <ProductName dangerouslySetInnerHTML={{ __html: product.name }} />
                  )}
                  {product.alternateImages && product.alternateImages.length > 0 ? (
                    <MobileSlider sliderSettings={this.state.settings} contentSlides={this.state.images} />
                  ) : (
                    <MobileImage src={this.state.selectedImage} />
                  )}
                  {accountSummary && product.pointValue > 0 ? (
                    <ProductPromo
                      hasRestrictedPoints={accountSummary.hasRestrictedPoints}
                      product={product}
                      currentPoints={accountSummary.available.total}
                      currentTaxable={accountSummary.available.taxable}
                      productGroupsProduct={this.state.productGroupProduct}
                    />
                  ) : (
                    <ContactUsLink themeVariation="primary-button" to={`${window.location.search}#zero-point`}>
                      {contactUsForDetails}
                    </ContactUsLink>
                  )}
                  {product.productOptions && product.productOptions.length > 0 && (
                    <ProductOptions productOptionSelection={this.productOptionSelection} product={product} />
                  )}
                  {product.groupedProducts && product.groupedProducts.length > 0 && (
                    <ProductGroups
                      productGroupsSelection={this.productGroupsSelection}
                      // defaultGroupProduct={this.state.defaultGroupProduct}
                      handleChange={this.changeProductGroupsProduct}
                      product={product}
                    />
                  )}
                  {/* Break Me Here */}
                  {accountSummary && product.pointValue > 0 && (
                    <ButtonContainer>
                      <AddToCartButton
                        onClick={this.addToCart(product)}
                        disabled={isCartDisabled(product, accountSummary) || this.state.isAddCartDisabled}
                      >
                        <CartIconContainer>
                          <ShopCartIcon themeVariation="add-cart-button-icon" />
                        </CartIconContainer>
                        {addToCartText}
                      </AddToCartButton>
                      {this.canShowFavoritesButton() && (
                        <DetailFavoritesButton product={product} productGroupProduct={this.state.productGroupProduct} />
                      )}
                    </ButtonContainer>
                  )}
                  {product.productShipMethod && product.productShipMethod.shipMethodDescription && (
                    <ShippingDescription>
                      <Text
                        themeVariation="secondary-text-light"
                        dangerouslySetInnerHTML={{ __html: product.productShipMethod.shipMethodDescription }}
                      />
                    </ShippingDescription>
                  )}

                  <ProductDescriptionHeading>{productDescriptionText}</ProductDescriptionHeading>
                  <ProductDescription
                    className="catalog__product-description"
                    hideDetail={this.state.detailHidden}
                    detailExpanded={this.state.detailExpanded}
                  >
                    <Text
                      ref={this.getProductDescriptionContent}
                      themeVariation="secondary-text-light"
                      dangerouslySetInnerHTML={{ __html: product.description }}
                    />
                  </ProductDescription>
                  {/* Break Me here? */}
                  {this.state.detailHidden && (
                    <DescriptionToggle themeVariation="primary-link" onClick={this.toggleFullDescription()}>
                      <DescriptionToggleIcon name={this.state.detailExpanded ? `angle-up` : `angle-down`} />
                      {this.state.detailExpanded ? lessDetailLabel : moreDetailLabel}
                    </DescriptionToggle>
                  )}
                </ContentContainer>
              </Col>
            </Row>
            {/* <TrendingContainer>{this.showTrending(product)}</TrendingContainer> */}
          </React.Fragment>
        )}
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  accountSummary: getCurrentAccountSummary(state),
  product: getProductDetail(state, ownProps)
});

const mapResourceToProps = (getResource) => ({
  addToCartText: getResource('catalog.addToCartText', 'Add To Cart'),
  backToResultsText: getResource('catalog.backToResultsText', 'Back to Results'),
  productDescriptionText: getResource('catalog.productDescriptionText', 'Product Description'),
  shoppingCartIcon: getResource('catalog.shoppingCartIcon', '/clientassets/images/shopicon.svg'),
  moreDetailLabel: getResource('catalog.productDetail.moreDetailLabel', 'View Full Description'),
  lessDetailLabel: getResource('catalog.productDetail.lessDetailLabel', 'View Less'),
  contactUsForDetails: getResource('catalog.productDetail.contactUsForDetails', 'Contact Us for Details')
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getAccountSummary,
      getProduct,
      postCart
    },
    dispatch
  )
});

const compose = register('rsv8-catalog/ProductDetail');

export default compose(withResource(mapResourceToProps), connect(mapStateToProps, mapDispatchToProps))(ProductDetail);
