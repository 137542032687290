import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { cultureSelector } from 'rsv8-client';
import { PageRenderer } from 'rsv8-cms';
import { toast } from 'rsv8-components';
import { withWizard } from 'rsv8-wizard';
import { register, withResource } from 'xcel-react-core';
import { AwardSelectionProps } from '../../components/Award/AwardSelection';
import { AwardSelection } from '../../components/index';
import { individualNominationActions } from '../../redux/actions/index';
import { individualNominationSelectors, nominationSelectors } from '../../redux/selectors/index';
import { Resources } from '../../types/index';

const { selectAward, getAwardDetails, getAwards } = individualNominationActions;

interface IndividualAwardSelectProps extends AwardSelectionProps {
  award: Resources.AwardAttributes;
}
class IndividualAwardSelect extends React.Component<IndividualAwardSelectProps, any> {
  constructor(props: IndividualAwardSelectProps) {
    super(props);

    this.checkValid = this.checkValid.bind(this);
  }

  componentDidMount() {
    if (this.props.wizard) {
      this.props.wizard.registerIncrement(this.checkValid);
    }
  }

  checkValid() {
    if (this.props.award && !this.props.award.isEligible) {
      toast({
        template: 'ContentToast',
        contentTemplate: (
          <PageRenderer
            path={'toast/nomination/individual/threshold'}
            onNotFound={() => <PageRenderer path={`toast/nomination/individual/threshold`} cultureId="0" />}
          />
        )
      });

      return Promise.reject('Nominee is no longer eligible');
    }

    return Promise.resolve();
  }

  render() {
    return <AwardSelection {...this.props} />;
  }
}

const mapState = (state) => {
  const award = individualNominationSelectors.getCurrentAward(state);
  return {
    selectedAward: individualNominationSelectors.getCurrentAwardId(state),
    award: Array.isArray(award) ? award[0] : award,
    requestData: nominationSelectors.getNomineeAwardsRequest(state),
    cultureId: cultureSelector.getActiveCultureId(state)
  };
};

const mapDispatch = (dispatch) => ({
  actions: bindActionCreators({ getAwards, selectAward, getAwardDetails }, dispatch)
});

const mapResource = (getResource) => ({
  confirmAwardChangeText: getResource(
    'individualNomination.confirmAwardChangeText',
    'Changing award at this point will clear data from later steps.'
  )
});

const compose = register('rsv8-nomination/IndividualNominationAwardSelection');

export default compose(
  connect(
    mapState,
    mapDispatch
  ),
  withResource(mapResource),
  withWizard()
)(IndividualAwardSelect) as React.SFC<{ awards: Array<Resources.Award>; nominee: any }>;
