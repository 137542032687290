import styled from 'styled-components';
import WizardContainer from './Container';
import WizardPanel from './WizardPanel';

const WizardRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
` as any;
export { WizardPanel, WizardContainer, WizardRow };
