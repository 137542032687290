import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { clientStateSelectors } from 'rsv8-client';
import { register, withContent } from 'xcel-react-core';
import { emptyArray } from 'xcel-util';
import { getNavigationMenu } from '../../redux/navigationMenu.actions';
import { navigationMenuClose, navigationMenuToggle } from '../../redux/navigationMenu.actions';
import { getHamburgerMenuStatus, getMenuItemsByAppKey } from '../../redux/navigationMenu.selector';
import { MenuContext } from './NavigationContext';
import { authStateSelectors } from "rsv8-auth/dist/module";

interface ModularMenuProps {
  actions: {
    getNavigationMenu: any;
    navigationMenuClose: any;
  };
  location: any;
  appKey: any;
  culture: any;
  template: any;
}

interface ModularMenuState {
  openItems: any;
  isOpen: any;
  open: any;
  close: any;
  closeAll: any;
  template: any;
}

class ModularMenu extends React.Component<ModularMenuProps, ModularMenuState> {
  openItems: any;
  openNav: any;

  constructor(props) {
    super(props);
    this.openItems = [];
    this.state = {
      openItems: this.openItems,
      isOpen: this.isOpen,
      open: this.open,
      close: this.close,
      closeAll: this.closeAll,
      template: undefined // Think this is what I want -- From AJ
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.culture !== this.props.culture) {
      let appKey = this.props.appKey || '';
      this.props.actions.getNavigationMenu({ appKey: appKey });
    }

    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.props.actions.navigationMenuClose();
    }
  }

  componentDidMount() {
    let appKey = this.props.appKey || 'main';
    this.props.actions.getNavigationMenu({ appKey: appKey });
    const template = this.props.template && require(`../NavigationTemplates/${this.props.template}`).default;
    this.setState({ template: template });
    window.addEventListener('touchstart', this.closeAll);
  }

  componentWillUnmount() {
    window.removeEventListener('touchstart', this.closeAll);
  }

  isOpen = (id) => {
    const found = this.openItems && this.openItems.filter((item) => item === id);
    if (found.length !== 0) {
      return true;
    }
    return false;
  };

  open = (id) => {
    if (this.openItems.filter((item) => item === id).length === 0) {
      this.openItems.push(id);
      this.setState({ openItems: this.openItems });
    }
  };

  close = (id) => {
    this.openItems = this.openItems.filter((item) => item !== id);
    this.setState({
      openItems: this.openItems
    });
  };

  closeAll = (e) => {
    this.openItems = [];
    this.setState({ openItems: this.openItems });
  };

  render() {
    const NavigationTemplate = this.state.template;

    if (NavigationTemplate) {
      return (
        <MenuContext.Provider value={this.state}>
          {NavigationTemplate && (
            <NavigationTemplate currentNav={this.openNav} {...this.props} cultureId={this.props.culture.id}>
              {this.props.children}
            </NavigationTemplate>
          )}
        </MenuContext.Provider>
      );
    } else {
      return null;
    }
  }
}

const mapState = (state, ownProps) => {
  const menuItems = ownProps.menuItems || getMenuItemsByAppKey(state, ownProps.appKey) || emptyArray;
  const auth = authStateSelectors.getAuth(state);
  if (menuItems && menuItems.length && auth.isOAuth) {
    for (let menuItem of menuItems) {
      const index = menuItem.children.findIndex(child => child.label === 'Change Password');
      if (index > -1) {
        menuItem.children.splice(index, 1);
      }
    }
  }
  return {
    culture: clientStateSelectors.getCultureActive(state),
    routing: state.routing,
    menuItems: menuItems,
    isBurgerOpen: getHamburgerMenuStatus(state)
  };
};

const mapDispatch = (dispatch) => ({
  actions: bindActionCreators(
    {
      getNavigationMenu,
      navigationMenuToggle,
      navigationMenuClose
    },
    dispatch
  )
});

const mapContentToProps = (getContent) => ({
  appKey: getContent('appKey', { type: 'none', label: 'Enter the Application Key for the menu you want to use' }),
  template: getContent('template', { type: 'none', label: 'Enter the Template for the menu you want to use' }),
  title: getContent('title', { type: 'text', label: 'Enter the title for the navigation section' })
});

export default register('rsv8-navigation/ModularMenu')(withContent(mapContentToProps), connect(mapState, mapDispatch))(
  withRouter(ModularMenu as any)
);
