import * as React from 'react';
import { connect } from 'react-redux';
import { RebateTimePeriod, ShipToLocation } from 'xcel-api-generator/dist/alcon';
import { getCurrencyValue } from '../../../utils';
import * as selectors from '../../redux/selectors';
import { LocationDetail } from './LocationDetail';
import { LocationBody, LocationContainer, LocationHeader } from './styles';

const MarketShareLocation: React.FC<Props> = ({ location, baseTimePeriod, currentTimePeriod }) => {
  return (
    <LocationContainer key={location.shipToLocationId} className="location">
      <LocationHeader>
        <div className="location__title">{`${location.name} #${location.shipToLocationId}`}</div>
        <div className="location__subtitle">{location.addressOne}</div>
        {!!location.addressTwo && <div className="location__subtitle">{location.addressTwo}</div>}
      </LocationHeader>

      <LocationBody>
        <LocationDetail
          label={baseTimePeriod.name}
          amount={getCurrencyValue(location.baseLineSaleAmount, 2)}
          percentage={location.baseLinePercentage}
        />

        <LocationDetail
          label={currentTimePeriod.name}
          amount={getCurrencyValue(location.currentSaleAmount, 2)}
          percentage={location.currentPercentage}
        />
      </LocationBody>
    </LocationContainer>
  );
};

const mapStateToProps = (state) => ({
  baseTimePeriod: selectors.baseTimePeriodSelector(state),
  currentTimePeriod: selectors.currentTimePeriodSelector(state),
});

interface StateProps {
  baseTimePeriod: RebateTimePeriod;
  currentTimePeriod: RebateTimePeriod;
}
interface DispatchProps {}
interface OwnProps {
  location: ShipToLocation;
}
type Props = StateProps & DispatchProps & OwnProps;

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps)(MarketShareLocation);
