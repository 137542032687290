import { combineReducers } from 'redux';
import { CURRENT_NOMINATION_KEY, NOMINEE_SEARCH_KEY } from '../../../constants';
import currentNomination from './currentNomination';
import nomineeSearch from './nomineeSearch';

const singleNominationReducer = combineReducers({
  [CURRENT_NOMINATION_KEY]: currentNomination,
  [NOMINEE_SEARCH_KEY]: nomineeSearch
});

export default singleNominationReducer;
