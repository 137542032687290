import { combineReducers } from 'redux';
import {
  ROOT_STATE,
  ACHIEVEMENT_STATE,
  FAVORITE_STATE,
  REDEEM_STATE
} from '../constants';
import * as actions from './actions';
import { favoritesReducer, favoriteUndoReducer } from './reducers/favorites';
import { achievementReducer } from './reducers/achievement';
import { redeemReducer } from './reducers/redeem';

const reducers = {[ROOT_STATE]: 
  combineReducers({
    [FAVORITE_STATE]: combineReducers({
      favoriteProducts: favoritesReducer,
      favoriteUndo: favoriteUndoReducer
    }),
    [ACHIEVEMENT_STATE]: achievementReducer,
    [REDEEM_STATE]: redeemReducer
  })
};

export { actions, reducers };
