import styled from 'styled-components';

export interface RecognitionImageProps {
  className?: string;
  imageURI: any;
}

const RecognitionImage = styled.div`
  height: 130px;
  background-image: url(${(props: RecognitionImageProps) => props.imageURI});
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 15px;
  background-size: auto 130px;
`;

export { RecognitionImage };
