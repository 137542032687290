import * as React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import imageApiActions from 'rsv8-components/dist/app/redux/imageApiActions';
import imageSelector from 'rsv8-components/dist/app/redux/imageSelectors';
import { assetService } from 'xcel-asset-service';
import { destroyRequest } from 'xcel-redux-orm';
import { getNotifications, markNotificationsAsRead } from '../../redux/notifications.actions';
import { notificationsSelector } from '../../redux/notifications.selector';
import { MenuItem, NavItem } from '../MenuItem';
import NavDropdown from './NavDropdown';
import NotificationsDropdown from './NotificationsDropdown';
import {
  HorizontalRule,
  NotificationCount,
  NotificationDate,
  NotificationItem,
  NotificationMessage,
  NotificationsContainer,
  NotificationsHeader,
  PrimaryNavStyledWrap
  } from './styles/HeaderNavigationStyles';

interface HeaderNavigationWithNotificationsProps {
  menuItems: any;
  userImage: any;
  cultureId: any;
  getNewImage: any;
  actions: any;
  notifications: any;
}

const HeaderNavigationWithNotifications: React.FC<HeaderNavigationWithNotificationsProps> = ({
  getNewImage,
  actions,
  notifications,
  menuItems,
  userImage,
  cultureId
}) => {
  const [newMessagesCount, setNewMessagesCount] = React.useState(0)

  React.useEffect(() => {
    getNewImage();
    actions.getNotifications();
  }, [getNewImage, actions]);

  React.useEffect(() => {
    setNewMessagesCount(notifications.newMessagesCount || 0)
  }, [notifications.newMessagesCount]);

  const handleNotificationIconClick = React.useCallback(() => {
    if (newMessagesCount > 0) {
      setNewMessagesCount(0);
      actions.markNotificationsAsRead();
    }
  }, [newMessagesCount, actions]);

  const renderMenuItem = React.useCallback((item, i: number) => {
    try {
      const parsedMeta = JSON.parse(item.meta);
      const imageURL = parsedMeta !== null ? parsedMeta.menuItemImage : '';
  
      if (notifications?.messages && item.label === 'Notification center') {
        return (
          <NotificationsDropdown
            key={i}
            cultureId={cultureId}
            id={item.id}
            title={
              <>
                <img
                  src={assetService.getImage(imageURL)}
                  className="userImageIcon"
                  onClick={handleNotificationIconClick}
                />
                {newMessagesCount > 0 && (
                  <NotificationCount>
                    <span>{newMessagesCount}</span>
                  </NotificationCount>
                )}
              </>
            }
            className="notificationCenter"
          >
            <NotificationsContainer>
              <NotificationsHeader>Notifications</NotificationsHeader>
              {notifications.messages.map((child, key) => {
                const date = new Date(child.messageDate);
                const formattedDate = new Intl.DateTimeFormat('en-US').format(date);
                return (
                  <div id={child.messageId} key={key}>
                    <HorizontalRule />
                    <NotificationItem>
                      <NotificationDate>{formattedDate}</NotificationDate>
                      <NotificationMessage>{child.message}</NotificationMessage>
                    </NotificationItem>
                  </div>
                );
              })}
            </NotificationsContainer>
          </NotificationsDropdown>
        );
      }
      
      if (item.children.length > 0) {
        return (
          <NavDropdown
            key={i}
            cultureId={cultureId}
            id={item.id}
            title={
              <img
                src={
                  item.label === 'Log Out' && userImage !== null
                    ? userImage.url
                    : assetService.getImage(imageURL)
                }
                className="userImageIcon"
              />
            }
            targetUrl={item.targetUrl}
          >
            {item.children.map((child, key) => {
              return (
                <MenuItem id={child.id} key={key} cultureId={cultureId} href={child.targetUrl}>
                  {item.children[key].label}
                </MenuItem>
              );
            })}
          </NavDropdown>
        );
      }
  
      return (
        <NavItem key={i} cultureId={cultureId} href={item.targetUrl}>
          <img src={assetService.getImage(imageURL)} />
        </NavItem>
      );
    } catch (e) {
      return null
    }
  }, [cultureId, userImage, notifications, newMessagesCount]);

  return (
    <div>
      <PrimaryNavStyledWrap>
        <Navbar fluid={false}>
          <Nav>
            {menuItems.map(renderMenuItem)}
          </Nav>
        </Navbar>
      </PrimaryNavStyledWrap>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  getNewImage: () => {
    const fn = () => {
      dispatch(destroyRequest('getTypeByImageType'));
      dispatch(imageApiActions.getTypeByImageType({ imageType: 'small' }));
      dispatch(imageApiActions.getTypeByImageType({ imageType: 'large' }));
    };
    return fn;
  },
  actions: bindActionCreators({ getNotifications, markNotificationsAsRead }, dispatch)
});

const mapStateToProps = (state) => ({
  userImage: imageSelector.selectOne(state, 'small'),
  notifications: notificationsSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderNavigationWithNotifications);
