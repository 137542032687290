import * as React from 'react';
import {connect} from 'react-redux';
import {register, withContent, withTheme} from 'xcel-react-core';
import {withApiDataProvider} from "xcel-redux-orm";
import { getSummary } from "../../redux/actions";
import { dashboardSelector } from "../../redux/selectors";
import {Dashboard} from "xcel-api-generator/dist/alcon";

interface Props {
    children: any;
    contentKey: string;
    dashboard: Dashboard;
}

const RequireContentAffiliate: React.FC<Props> = (
    {
        children,
        contentKey,
        dashboard
    }) => {
    if (dashboard && dashboard.affiliation && dashboard.affiliation.contentKey === contentKey) {
        return children;
    }

    return null;
};

const mapStateToProps = state => ({
    dashboard: dashboardSelector.selectMany(state)[0]
});

const mapContentToProps = (getContent) => ({
    contentKey: getContent('contentKey', {type: 'string', label: 'Affiliation Content Key'}),
});

export default register("rsv8-account/RequireContentAffiliate")(
    connect(mapStateToProps),
    withContent(mapContentToProps),
    withApiDataProvider(getSummary, dashboardSelector),
    withTheme()
)(RequireContentAffiliate);
