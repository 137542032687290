import * as React from 'react';
import { register, withResource } from 'xcel-react-core';

class PageIcon extends React.Component<any> {
  componentDidMount() {
    let favicon = document.getElementById('favicon')
    favicon.setAttribute('href', this.props.icon)
  }
  componentDidUpdate() {
    let favicon = document.getElementById('favicon')
    favicon.setAttribute('href', this.props.icon)
  }
  render() {
    return null;
  }
}

const mapResources = (getResource) => {
  return {
    icon: getResource('pageIcon', "/favicon.ico")
  };
};

export default register('rsv8-app/PageIcon')(withResource(mapResources))(PageIcon);
