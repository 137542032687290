import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  HeaderDecoration,
  HeaderOne,
  Image,
  Link,
  Text
  } from 'rsv8-components';
import { LayoutContainer } from '../../../components/Layout';
import { withResource } from 'xcel-react-core'
import { compose } from 'redux'

const UsernameReminderSent: any = ({ imageURI, loginHelpLinkPath, resource: {emailSentText, contactEmail} }) => (
  <div>
    <HeaderDecoration />
    <Col xs={22} xsOffset={1} lg={22} lgOffset={1} md={22} mdOffset={1}>
      <Image themeVariation="primary-company-logo1" />
    </Col>
    <LayoutContainer>
      <Row>
        <Col xs={22} xsOffset={1} lg={7} lgOffset={8} md={7} mdOffset={8}>
          <Image themeVariation="icon-email-sent" />
        </Col>
      </Row>
      <Row>
        <Col xs={22} xsOffset={1} lg={12} lgOffset={6} md={18} mdOffset={4}>
          <HeaderOne textalign="center">A username reminder was emailed to you!</HeaderOne>
        </Col>
      </Row>
      <Row>
        <Col xs={22} xsOffset={1} lg={12} lgOffset={6} md={18} mdOffset={4}>
          <Text themeVariation="center" display="block">
            {emailSentText}
            <Link href={`mailto:${contactEmail}`}>{contactEmail}</Link>
          </Text>
        </Col>
      </Row>
      <br />
      <Row>
        <Link textalign="center" href={loginHelpLinkPath || '/'} display="block">
          Back to the login page {'>'}
        </Link>
      </Row>
    </LayoutContainer>
  </div>
);

const mapResourceToProps = (getResource) => ({
  resource: {
    emailSentText: getResource('usernameReminderSent.emailSentText', 'Check your email address associated with your account. We will send you a reminder of your username. Never received your email? Contact your HR Service Center at '),
    contactEmail: getResource('usernameReminderSent.contactEmail', 'support@rewardstation-live.net' )
  }
})


export default compose(
  withResource(mapResourceToProps)
)(UsernameReminderSent);
