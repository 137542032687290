import * as React from 'react';
import SimpleBar from './SimpleBar';
import * as styles from './style';

const BarMaximum = 100;

interface Props {
  chartWidgetSize: number;
  subTitleText: string;
  myAmount: number;
  myGoalTarget: number;
  color: string;
  goalRemaining: string | number;
}

export const GoalInProgress: React.FC<Props> = ({
  chartWidgetSize,
  myAmount,
  myGoalTarget,
  color,
  goalRemaining,
  subTitleText
}) => (
  <styles.GoalsLeftContainer className="goal-in-progress" color={color}>
    <styles.GoalsPointsContainer>
      <styles.PointsRemaining color="white">{goalRemaining}</styles.PointsRemaining>
      <styles.PointsSubtitle color="white">{subTitleText}</styles.PointsSubtitle>
    </styles.GoalsPointsContainer>

    <styles.GoalsBarContainer>
      <SimpleBar chartWidgetSize={chartWidgetSize} current={Number(myAmount >= myGoalTarget ? BarMaximum : Math.round(myAmount / myGoalTarget * BarMaximum))} maximum={BarMaximum} totalBarColor='white' myPointsBarColor='navy' />
    </styles.GoalsBarContainer>
  </styles.GoalsLeftContainer>
)