import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getConfigurationProperty } from 'xcel-config';
import { register, withContent, withTheme, withResource } from 'xcel-react-core';
import AuthService from '../../../services/OAuthService';
import { changeFirstVisitState } from '../../redux/auth.actions';
import { userLoginOAuth } from '../../redux/user.actions';
import { toast } from 'rsv8-components';

let popupOpened = false;

const OAuth = ({
  actions: { userLoginOAuth, changeFirstVisitState },
  redirectPath,
  disableShowPassword,
  match,
  location,
  children,
  resource: { errorMessageText },
  className,
  dispatch,
  ...rest
}) => {
  let authority = getConfigurationProperty(["authority"]);
  let identityServerConfigData = getConfigurationProperty([
    "identityServerConfigData"
  ]);
  const isOAuth = authority && identityServerConfigData;
  if (isOAuth) {
    if (!popupOpened) {
      const { userManager } = AuthService;
      userManager.signinRedirect().then(async () => {
        popupOpened = false;
        changeFirstVisitState(false);
        if (location.state === null || typeof location.state === "undefined") {
          userLoginOAuth(redirectPath ? redirectPath : '/home');
        } else {
          dispatch(push(location.stat));
        }
      }).catch(async e => {
        toast({
          template: 'Default',
          themeVariation: 'error-text',
          message: errorMessageText
        });
        popupOpened = false;
        changeFirstVisitState(false);
        dispatch(push('/login'));
      });
      popupOpened = true;
    }
  } else {
    dispatch(push('/login'));
  }

  return <></>;
};

const mapState = () => ({});

const mapDispatch = dispatch => ({
  actions: bindActionCreators({ userLoginOAuth, changeFirstVisitState }, dispatch),
  dispatch: dispatch
});

const mapContentToProps = getContent => ({
  redirectPath: getContent("redirectPath", {
    type: "none",
    label: "Redirect Path"
  }),
});

const mapResourceToProps = (getResource) => ({
  resource: {
    errorMessageText: getResource(
        'login.authenticationFailed',
        'Authentication failed'
    )
  }
});

export default register("rsv8-auth/OAuth")(
  withContent(mapContentToProps),
  withResource(mapResourceToProps),
  withTheme(),
  connect(
    mapState,
    mapDispatch
  )
)(OAuth);