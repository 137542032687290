import * as React from 'react';
import { BackendResourceData } from './BackendResourceContext';

export interface BackendResourceItemProps {
  id: string;
  data: BackendResourceData;
  text: string;
}

const ErrorItem: React.SFC<BackendResourceItemProps> = ({ id, data, text, ...rest }) => <span {...rest}>{text}</span>;

export default ErrorItem;
