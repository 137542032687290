import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PageRenderer } from 'rsv8-cms';
import { ModularMenu } from 'rsv8-navigation';
import { Step, Wizard } from 'rsv8-wizard';
import { register, withResource } from 'xcel-react-core';
import { INDIVIDUAL_NOMINATION_WIZARD_NAME } from '../../constants';
import { individualNominationActions } from '../../redux/actions/index';
import NominationDisplay from './ProgressHeader';

const { clearState: clearIndividualNomination } = individualNominationActions;

export interface NominationWizardProps {
  clearIndividualNomination: Function;
  saveText: string;
  skipSelectAwardStep: string;
}

interface NominationWizardState {}

class NominationWizard extends React.Component<NominationWizardProps, NominationWizardState> {
  mySteps: Array<Step> = [
    { title: 'Nominee', component: 'rsv8-nomination/IndividualSelectNominee', hideBackButton: true },
    { title: 'Awards', component: 'rsv8-nomination/IndividualSelectAward', skip: this.props.skipSelectAwardStep === 'true' },
    { title: 'Product', component: 'rsv8-nomination/IndividualSelectProduct' },
    { title: 'Criteria', component: 'rsv8-nomination/IndividualSelectCriteria' },
    { title: 'Details', component: 'rsv8-nomination/IndividualSelectDetails' },
    {
      title: 'Confirm',
      component: 'rsv8-nomination/IndividualNominationReview',
      nextText: this.props.saveText
    },
    {
      title: 'Success',
      component: 'rsv8-nomination/IndividualNominationComplete',
      hideBackButton: true,
      hideNextButton: true
    }
  ];

  componentWillUnmount() {
    this.props.clearIndividualNomination();
  }
  
  render() {
    const { clearIndividualNomination: test, saveText, ...rest } = this.props;
    return (
      <React.Fragment>
        <PageRenderer path={`partial/nomination/eligibility`} onNotFound={() => null} />
        <ModularMenu appKey="nominationNav" title="Recognition" template="NominationsHeaderNav" />
        <NominationDisplay />
        <Wizard {...rest} name={INDIVIDUAL_NOMINATION_WIZARD_NAME} steps={this.mySteps} />
      </React.Fragment>
    );
  }
}

const mapDispatch = (dispatch) => ({
  clearIndividualNomination: bindActionCreators(clearIndividualNomination, dispatch)
});

const mapResource = (getResource) => ({
  saveText: getResource('nominees.submitButtonText', 'Submit'),
  skipSelectAwardStep: getResource('nominees.skipSelectAwardStep', 'false')
});

const compose = register('rsv8-nomination/IndividualNominationWizard');

export default compose(
  connect(
    null,
    mapDispatch
  ),
  withResource(mapResource)
)(NominationWizard);
