import * as React from 'react';
import styled, { keyframes } from 'styled-components';

const LoaderGhostContainer = styled.div``;

const LoaderKeyFrames = keyframes`
    0%   { opacity: 0.25;}
    50%  { opacity: .5;}
    100% { opacity: 0.7;}
`;

const LoadGhost1 = styled.div`
  opacity: 0;
  width: 30%;
  background: #ccc;
  height: 24px;
  margin-bottom: 20px;
  animation: ${LoaderKeyFrames} 1.25s linear infinite alternate;
  animation-delay: 0.2s;
  @media (max-width: 767px) {
    width: 72%;
  }
`;

const LoadGhost2 = styled.div`
  opacity: 0;
  width: 44%;
  background: #dcdcdc;
  height: 14px;
  margin-bottom: 10px;
  animation: ${LoaderKeyFrames} 0.55s linear infinite alternate;
  animation-delay: 0.2s;
  @media (max-width: 767px) {
    width: 86%;
  }
`;

const LoadGhost3 = styled.div`
  opacity: 0;
  width: 34%;
  background: #dcdcdc;
  height: 14px;
  margin-bottom: 10px;
  animation: ${LoaderKeyFrames} 0.95s linear infinite alternate;
  animation-delay: 0.2s;
  @media (max-width: 767px) {
    width: 76%;
  }
`;

const LoaderText = () => {
  return (
    <LoaderGhostContainer>
      <LoadGhost1 />
      <LoadGhost2 />
      <LoadGhost3 />
      <LoadGhost2 />
      <LoadGhost3 />
    </LoaderGhostContainer>
  );
};

export default LoaderText;
