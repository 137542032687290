import { withResource } from 'xcel-react-core';

export type WithModalResourcesProps = ReturnType<typeof mapResource>;

const mapResource = (getResource) => ({
  headers: {
    nominee: getResource('nominationDetailModal.nomineeHeader', 'Nominee'),
    nominator: getResource('nominationDetailModal.nominatorHeader', 'Nominator'),
    details: getResource('nominationDetailModal.detailsHeader', 'Nomination Details'),
    approval: getResource('nominationDetailModal.approvalHeader', 'Approve'),
    finalApproverDecision: getResource('nominationDetailModal.finalApproverDecision', "Final Approver's Decision"),
    teamMembers: getResource('nominationDetailModal.teamMembers', 'Team Members')
  },
  labels: {
    createDate: getResource('nominationDetailModal.createDateLabel', 'Created At'),
    award: getResource('nominationDetailModal.awardLabel', 'Award'),
    status: getResource('nominationDetailModal.statusLabel', 'Status'),
    type: getResource('nominationDetailModal.nominationTypeLabel', 'Type'),
    pointValue: getResource('nominationDetailModal.pointValueLabel', 'Value'),
    downloadCertificate: getResource('nominationDetailModal.downloadCertificateLabel', 'Download Certificate'),
    finalApprover: getResource('nominationDetailModal.finalApprover', "Final Approver"),
    points: getResource('nominationDetailModal.points', "points"),
  }
});

export default withResource(mapResource);
