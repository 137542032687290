import styled from 'styled-components';

export default styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 355px;
  padding: 15px 10px;
` as any;
