import { ClientCountryModel, ClientCultureModel, ClientModel } from 'xcel-api-generator/dist/user';
import { Resource } from 'xcel-redux-orm';
import clientSelector from './client';
import countrySelector from './country';
import cultureSelector from './culture';
// legacy state selectors for backwards compatability.
const clientStateSelectors = {
  getClientActive: clientSelector.getActive,
  getCultureActive: cultureSelector.getActive,
  getCultureList: cultureSelector.getAll,
  getCountry: countrySelector.getAll
};

export {
  clientSelector,
  countrySelector,
  cultureSelector,
  clientStateSelectors,
  ClientCountryModel,
  ClientCultureModel,
  ClientModel,
  Resource
};
