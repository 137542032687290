import * as R from 'ramda';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PageRenderer } from 'rsv8-cms';
import { toast } from 'rsv8-components';
import { register, withResource } from 'xcel-react-core';
import { nominationActions } from '../../redux/actions/getApplication';
import { individualNominationActions, nomineeSearch } from '../../redux/actions/index';
import { individualNominationSelectors } from '../../redux/index';
import { Resources } from '../../types';
import NomineeLayout from './NomineeLayout';
const { selectAward, getAwardDetails, getAwards } = individualNominationActions;

class NominationSearch extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  componentWillMount() {
    if (this.props.wizard) {
      this.props.wizard.registerIncrement(this.checkValid);
    }
  }

  componentDidMount() {
    this.props.actions.getNominationApplication({});
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    const { actions, currentNominee, wizard, skipSelectAwardStep } = this.props;
    if (prevProps.currentNominee !== currentNominee) {
      await actions.getAwards(currentNominee);
      if (wizard && prevProps.currentNominee && skipSelectAwardStep !== 'true') {
        wizard.enableAllSteps();
      }
    }
  }

  isAwardsNotEligible = (awards) => {
    const notEligible = (award: Resources.Award) => !award.isEligible;
    return R.all(notEligible)(awards);
  };

  checkValid = async () => {
    const { currentNominee, awards, actions, skipSelectAwardStep } = this.props;
    if (currentNominee) {
      if (!awards || !awards.length || this.isAwardsNotEligible(awards)) {
        toast({
          template: 'ContentToast',
          contentTemplate: (
            <PageRenderer
              onNotFound={() => <PageRenderer path={`toast/nomination/individual/userNotEligible`} cultureId="0" />}
              path={'toast/nomination/individual/userNotEligible'}
            />
          )
        });
        return Promise.reject('Selected nominee does not have eligible awards');
      }
      if (skipSelectAwardStep === 'true') {
        const award = awards[0].id;
        await actions.getAwardDetails(award, currentNominee);
        await actions.selectAward(award, null);
      }
      this.setState({ hasError: false });
      return Promise.resolve();
    } else {
      this.setState({ hasError: true });
      return Promise.reject('Please select a nominee.');
    }
  };

  render() {
    const { currentNominee, errorText } = this.props;
    return <NomineeLayout currentNominee={currentNominee} hasError={this.state.hasError} errorText={errorText} />;
  }
}

const mapState = (state) => {
  const awards = individualNominationSelectors.getAwards(state);
  return {
    awards: awards,
    currentNominee: individualNominationSelectors.getCurrentNomineeId(state)
  };
};

const mapDispatch = (dispatch) => ({
  actions: bindActionCreators(
    {
      nomineeSearch,
      getNominationApplication: nominationActions.getApplication,
      selectAward,
      getAwards,
      getAwardDetails
    },
    dispatch
  )
});

const mapResource = (getResource) => ({
  errorText: getResource('individualNomination.selectNomineeError', 'Please Select a Nominee'),
  skipSelectAwardStep: getResource('nominees.skipSelectAwardStep', 'false')
});

const compose = register('rsv8-nomination/IndividualSelectNominee');

export default compose(connect(mapState, mapDispatch), withResource(mapResource))(NominationSearch);
