import { contentCultureSelector, contentDetailSelector, contentSelector } from '.';
import { ContentCultureModel, VersionModel } from 'xcel-api-generator/dist/cms';
import { createTypeSelector } from 'xcel-redux-orm';
import { Resource } from 'xcel-redux-orm';
import { emptyArray } from 'xcel-util';
export interface ContentVersionModel extends VersionModel {
  contentCulture: ContentCultureModel;
}
const contentVersionSelector = {
  ...createTypeSelector<ContentVersionModel>('versionModel'),
  getVersionsByContentId: (state, contentId) => {
    const content = contentDetailSelector.selectOne(state, contentId);
    const culture = content.cultures[0];
    return culture && contentVersionSelector.getVersionsByContentCultureId(state, culture.id);
  },
  getVersionsByContentCultureId: (state, contentCultureId) => {
    return contentVersionSelector.searchMany(
      state,
      (version) => typeof version.content === 'object' && version.contentCulture.id === contentCultureId
    );
  },
  getActiveVersion: (state, contentTypeId) => {
    const { versionId } = contentSelector.getActiveProperty(state, contentTypeId);
    if (!versionId) return null;
    return contentVersionSelector.selectOne(state, versionId);
  },
  getActiveVersions: (state, contentTypeId) => {
    const contentCulture = contentCultureSelector.getActive(state, contentTypeId);
    if (!contentCulture) {
      return emptyArray;
    }
    const versions = contentVersionSelector.searchMany(
      state,
      (version) => version.contentCulture.id === contentCulture.id
    );
    return versions ? versions : emptyArray;
  },
  canPublishVersion: (state, contentTypeId) => {
    const version = contentVersionSelector.getActiveVersion(state, contentTypeId);
    if (!version) {
      return false;
    }
    const versionResponse = contentVersionSelector.getActiveVersions(state, contentTypeId);
    const publishPredicate = (it) => it.id !== version.id && it.updatedDateTimeUtc >= version.updatedDateTimeUtc;

    if (Array.isArray(versionResponse)) {
      const conflictVersions = versionResponse.filter((it) => publishPredicate(it));
      return conflictVersions.length === 0;
    }

    return !publishPredicate(versionResponse);
  }
};
export { Resource };
export default contentVersionSelector;
