import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { HeaderFour } from 'rsv8-components';
import { Toastify } from 'rsv8-components';
import styled from 'styled-components';
import { AssetGrid, FileUpload, RouteTree } from '../../components';
import EditAssetDisplay from '../../components/AssetGrid/modals/EditAssetDisplay';
import AssetAdminModal from '../../components/Modal';
import { setActiveModal } from '../../redux/actions';
import { getCurrentModal } from '../../redux/selectors';

interface ViewProps {
  activeModal: any;
  actions?: {
    setActiveModal?: typeof setActiveModal;
  };
}

interface ViewState {
  file?: {
    fileName?: string;
  };
  activeModal?: string;
}

const RouteWrapper = styled.div`
  width: 100%;
  &:after {
    content: '';
    clear: both;
  }
`;

const TreeWrap = styled.div`
  float: left;
  border-right: 2px solid #dddddd;
  width: 20%;
`;

const StyledImageContainer = styled.div`
  float: left;
  width: 80%;
`;

class AssetsRoutesList extends React.Component<ViewProps, ViewState> {
  state = { file: { fileName: '' }, activeModal: '' };

  handleGridImageClick = (onImageClick) => {
    this.setState({ activeModal: 'select-image', file: onImageClick });
  };

  handleHide = () => {
    this.setState({ activeModal: '', file: {} });
  };

  public render() {
    let {
      file,
      file: { fileName },
      activeModal
    } = this.state;
    return (
      <RouteWrapper>
        <HeaderFour>Asset Routes</HeaderFour>

        <TreeWrap>
          <RouteTree />
        </TreeWrap>

        <StyledImageContainer>
          <FileUpload />
          <AssetGrid onImageClick={this.handleGridImageClick} />
        </StyledImageContainer>

        <Toastify hideProgressBar={true} autoClose={false} />

        <AssetAdminModal
          handleHide={this.handleHide}
          show={activeModal === 'select-image'}
          modaleTitle={fileName}
          size="97%"
        >
          <EditAssetDisplay file={file} handleDelete={this.handleHide} />
        </AssetAdminModal>
      </RouteWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setActiveModal
    },
    dispatch
  )
});

const mapStatetoProps = (state) => {
  return {
    activeModal: getCurrentModal(state)
  };
};

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(AssetsRoutesList as any);
