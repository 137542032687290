import { equals } from 'ramda';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { cultureSelector } from 'rsv8-client';
import { Loader } from 'rsv8-components';
import { ensureArray } from 'xcel-util';
import { getCustomFields } from '../../redux/actions';
import { getFieldsByGroup, getFieldsWereFound } from '../../redux/selectors';
import CustomField from './CustomField';

export interface CustomFieldsProps {
  formType: string;
  groupKey: string | string[];
  appKey?: string;
  component?: any;
  parentIds?: any;
  [otherProps: string]: any;
}

interface InternalProps extends CustomFieldsProps {
  fields: Array<String>;
  actions: any;
  shouldHide?: boolean;
  showPage?: Function;
}

class CustomFields extends React.Component<InternalProps, {}> {
  componentWillMount() {
    this.getCustomFields(this.props);
  }

  componentWillReceiveProps(nextProps: InternalProps) {
    if (
      this.props.appKey !== nextProps.appKey ||
      this.props.formType !== nextProps.formType ||
      !equals(this.props.groupKey, nextProps.groupKey) ||
      this.props.cultureId !== nextProps.cultureId
    ) {
      this.getCustomFields(nextProps);
    }
  }

  getCustomFields = ({ formType, groupKey, appKey }: InternalProps) => {
    if (formType && appKey) {
      this.props.actions.getCustomFields({ formType, groupKey: ensureArray(groupKey), appKey } as any);
    } else if (formType && groupKey && !appKey) {
      this.props.actions.getCustomFields({ formType, groupKey: ensureArray(groupKey) } as any);
    }
  };

  shouldDisplayFields = (fields) => {
    if (fields === undefined) {
      return false;
    } else if (Array.isArray(fields)) {
      if (fields.length === 0) {
        return false;
      } else if (fields.every((v) => v === undefined)) {
        return false;
      }
    }
    this.props.showPage && this.props.showPage();
    return true;
  };

  render() {
    const { fields, formType, groupKey, shouldHide, component: Component = CustomField, ...rest } = this.props;

    return (
      <React.Fragment>
        {this.shouldDisplayFields(fields) ? (
          fields.map((field, i) => {
            return <Component key={i} field={field} fieldKey={formType} {...rest} />;
          })
        ) : shouldHide ? null : (
          <Loader />
        )}
      </React.Fragment>
    );
  }
}

const mapState = (state, ownProps) => {
  const fields = getFieldsByGroup(state, ensureArray(ownProps.groupKey), ownProps.formType, ownProps.appKey);
  const { parentIds } = ownProps;
  if (parentIds) {
    for (let field of fields) {
      const parentId = parentIds.find(item => item.id === field.parentId);
      if (field.options && field.options.length && parentId) {
        if (Array.isArray(parentId.values)) {
          field.options = field.options.filter(option => parentId.values.find(value => value === option.parentValue) || option.parentValue === undefined);
        } else {
          field.options = field.options.filter(option => option.parentValue === parentId.values || option.parentValue === undefined);
        }
      }
    }
  }

  return {
    fields: fields,
    shouldHide: !getFieldsWereFound(
      state,
      ownProps.formType,
      ownProps.appKey,
      ensureArray(ownProps.groupKey).join('-')
    ),
    cultureId: cultureSelector.getActiveCultureId(state)
  };
};

const mapDispatch = (dispatch) => ({ actions: bindActionCreators({ getCustomFields }, dispatch) });

export default connect(
  mapState,
  mapDispatch
)(CustomFields as any) as React.ComponentType<CustomFieldsProps>;
