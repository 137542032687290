import * as React from 'react';
import * as Icon from 'react-fontawesome';
import { connect } from 'react-redux';
import { Dashboard, GoalDto, Performance, Program } from 'xcel-api-generator/dist/alcon';
import { register, withContent, withTheme } from 'xcel-react-core';
import { withApiDataProvider } from 'xcel-redux-orm';
import { getCurrencyValue } from '../../../utils';
import { GoalContent, GoalType, ProgramName } from '../../constants';
import { getProgram, getSummary } from '../../redux/actions';
import { dashboardSelector, performanceSelector, programSelector } from '../../redux/selectors';
import { OverlayPopover } from '../Overlay';
import { PlaceHolderBar } from './PlaceHolderBar';
import { BarCell, BarCellLabel, BarCellValue, BarContainer, BarToggle, MarketShareBarCell } from './styles';

const Goal: React.FC<{
  goal: GoalDto;
  index: number;
  goalsContent?: GoalContent[];
}> = ({ goal, index, goalsContent }) => {
  const goalContent = goalsContent ? goalsContent.find((e) => e.id === goal.id) : undefined;
  const goalTitle = goalContent ? goalContent.goalTitle : `GOAL #${index + 1}`;
  const goalTitleColor = goalContent ? goalContent.goalTitleColor : undefined;

  return (
    <BarCell key={index} className={`dashboard-bar__goal-${index + 1}`}>
      <BarCellLabel color={goalTitleColor}>{goalTitle}</BarCellLabel>

      <BarCellValue key={'units-cell' + index}>{goal.targetUnits}</BarCellValue>
    </BarCell>
  );
};

const OptiPortDashboardBar: React.FC<{
  dashboard: Dashboard;
  performance: Performance;
  goalsContent?: GoalContent[];
  mySortedGoals: GoalDto[];
}> = ({ dashboard, performance, goalsContent, mySortedGoals }) => (
  <>
    <BarCell className="dashboard-bar__points">
      <BarCellLabel>YTD UNITS</BarCellLabel>
      <BarCellValue>{performance.current.ytdUnits}</BarCellValue>
    </BarCell>

    <BarCell className="dashboard-bar__q">
      <BarCellLabel>QUARTER</BarCellLabel>
      <BarCellValue>{dashboard.currentTimePeriod.name}</BarCellValue>
    </BarCell>

    {mySortedGoals
      .filter((goal) => goal.goalType === GoalType.Units)
      .map((goal, index) => (
        <Goal goal={goal} index={index} goalsContent={goalsContent} />
      ))}
  </>
);

const VisionSourceDashboardBar: React.FC<{
  dashboard: Dashboard;
  performance: Performance;
  goalsContent?: GoalContent[];
  mySortedGoals: GoalDto[];
}> = ({ dashboard, performance, goalsContent, mySortedGoals }) => (
  <>
    <BarCell className="dashboard-bar__points">
      <BarCellLabel>YTD UNITS</BarCellLabel>
      <BarCellValue>{performance.current.ytdUnits}</BarCellValue>
    </BarCell>

    <BarCell className="dashboard-bar__tier">
      <BarCellLabel>TIER</BarCellLabel>
      <BarCellValue>{(dashboard.currentTier && dashboard.currentTier.name) || '-'}</BarCellValue>
    </BarCell>

    <BarCell className="dashboard-bar__q">
      <BarCellLabel>QUARTER</BarCellLabel>
      <BarCellValue>{dashboard.currentTimePeriod.name}</BarCellValue>
    </BarCell>

    {mySortedGoals
      .filter((goal) => goal.goalType === GoalType.Units)
      .map((goal, index) => (
        <Goal goal={goal} index={index} goalsContent={goalsContent} />
      ))}
  </>
);

const UnitsDashboardBar: React.FC<{
  dashboard: Dashboard;
  performance: Performance;
  goalsContent?: GoalContent[];
  mySortedGoals: GoalDto[];
}> = ({ dashboard, performance, goalsContent, mySortedGoals }) => (
  <>
    <BarCell className="dashboard-bar__points">
      <BarCellLabel>UNITS</BarCellLabel>
      <BarCellValue>{performance.current.units}</BarCellValue>
    </BarCell>

    <BarCell className="dashboard-bar__rebate_percent">
      <BarCellLabel>% TO REBATE</BarCellLabel>
      <BarCellValue>{performance.percentageToRebate}%</BarCellValue>
    </BarCell>

    <BarCell className="dashboard-bar__tier">
      <BarCellLabel>TIER</BarCellLabel>
      <BarCellValue>{(dashboard.currentTier && dashboard.currentTier.name) || '-'}</BarCellValue>
    </BarCell>

    <BarCell className="dashboard-bar__q">
      <BarCellLabel>QUARTER</BarCellLabel>
      <BarCellValue>{dashboard.currentTimePeriod.name}</BarCellValue>
    </BarCell>

    {mySortedGoals.map((goal, index) => (
      <Goal goal={goal} index={index} goalsContent={goalsContent} />
    ))}
  </>
);

const MarketShareDashboardBar: React.FC<{
  dashboard: Dashboard;
  performance: Performance;
}> = ({ dashboard: { baselineTimePeriod, currentTimePeriod }, performance: { baseline, current, runrate } }) => (
  <>
    <MarketShareBarCell className="dashboard-bar__rebate_percent">
      <BarCellLabel>RUN RATE ESTIMATED REBATE</BarCellLabel>
      <BarCellValue>{getCurrencyValue(runrate.rebate)}</BarCellValue>
    </MarketShareBarCell>

    <MarketShareBarCell className="dashboard-bar__q">
      <BarCellLabel>QUARTER</BarCellLabel>
      <BarCellValue>{currentTimePeriod.name}</BarCellValue>
    </MarketShareBarCell>

    <MarketShareBarCell className="dashboard-bar__goal-1">
      <BarCellLabel>{baselineTimePeriod.name} SALES</BarCellLabel>
      <BarCellValue>{getCurrencyValue(baseline.sales)}</BarCellValue>
    </MarketShareBarCell>

    <MarketShareBarCell className="dashboard-bar__goal-2">
      <BarCellLabel>{currentTimePeriod.name} SALES</BarCellLabel>
      <BarCellValue>{getCurrencyValue(current.sales)}</BarCellValue>
    </MarketShareBarCell>

    <MarketShareBarCell className="dashboard-bar__goal-3">
      <BarCellLabel>RUN RATE ESTIMATED SALES</BarCellLabel>
      <BarCellValue>{getCurrencyValue(runrate.sales)}</BarCellValue>
    </MarketShareBarCell>
  </>
);

const DashboardBar: React.FC<Props> = ({ dashboard, program, performance, goalsContent, infoButtonText, className }) => {
  const [collapsed, setCollapsed] = React.useState(true);

  if (!performance || !dashboard || !program) {
    return <PlaceHolderBar collapsed={collapsed} className={className} />;
  }

  const mySortedGoals = dashboard.infoBarGoals
    ? Array.isArray(dashboard.infoBarGoals)
      ? dashboard.infoBarGoals.filter((goal) => goal.displayInInfobar).sort((a, b) => a.sequence - b.sequence)
      : [dashboard.infoBarGoals]
    : [];

  return (
    <div className={className}>
      <BarContainer collapsed={collapsed}>
        <div className="dashboard-bar__content">
          {ProgramName.match(ProgramName.OptiPort, program) && (
            <OptiPortDashboardBar
              dashboard={dashboard}
              performance={performance}
              goalsContent={goalsContent}
              mySortedGoals={mySortedGoals}
            />
          )}

          {ProgramName.match(ProgramName.VisionSource, program) && (
            <VisionSourceDashboardBar
              dashboard={dashboard}
              performance={performance}
              goalsContent={goalsContent}
              mySortedGoals={mySortedGoals}
            />
          )}

          {(ProgramName.match(ProgramName.Magnifeye, program) || ProgramName.match(ProgramName.Platinum, program)) && (
            <UnitsDashboardBar dashboard={dashboard} performance={performance} goalsContent={goalsContent} mySortedGoals={mySortedGoals} />
          )}

          {(ProgramName.match(ProgramName.MarketShare, program) || ProgramName.match(ProgramName.TotalPartner, program)) && (
            <MarketShareDashboardBar dashboard={dashboard} performance={performance} />
          )}

          <BarCell className="dashboard-bar__projected-rebate">
            <BarCellLabel>PROJECTED REBATE</BarCellLabel>
            <BarCellValue>{getCurrencyValue(performance.current.rebate)}</BarCellValue>
          </BarCell>

          <OverlayPopover id="popover-dashboardbar-title" placement="bottom">
            {infoButtonText}
          </OverlayPopover>
        </div>

        <BarToggle collapsed={collapsed} onClick={() => setCollapsed(!collapsed)}>
          {collapsed && <div className="dashboard-bar__toggle-label">QUICKSTATS</div>}

          <div className="dashboard-bar__toggle-icon">
            {collapsed && <Icon name="angle-down" />}
            {!collapsed && <Icon name="angle-up" />}
          </div>
        </BarToggle>
      </BarContainer>
    </div>
  );
};

const mapContentToProps = (getContent) => ({
  goalsContent: getContent('goalsContent', {
    type: 'array',
    schema: [
      { model: 'id', label: 'Goal key' },
      { model: 'goalTitle', label: 'Title of the goal' },
      { model: 'goalTitleColor', label: 'Color of title of the goal' },
    ],
  }),
  infoButtonText: getContent('infoButtonText', {
    type: 'string',
    schema: [{ model: 'Add info button text', label: 'add info button text' }],
  }),
});

const mapStateToProps = (state) => ({
  dashboard: dashboardSelector.selectMany(state)[0],
  program: programSelector.selectMany(state)[0],
  performance: performanceSelector.selectMany(state)[0],
});

interface StateProps {
  dashboard: Dashboard;
  program?: Program;
  performance: Performance;
}
interface ContentProps {
  goalsContent?: GoalContent[];
  infoButtonText: string;
}
type Props = React.HTMLAttributes<any> & StateProps & ContentProps;

export default register('alcon-information/DashboardBar')(
  connect<StateProps>(mapStateToProps),
  withApiDataProvider(getSummary, dashboardSelector),
  withApiDataProvider(getProgram, programSelector),
  withContent(mapContentToProps),
  withTheme()
)(DashboardBar);
