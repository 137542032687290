import * as React from 'react';
import { Grid } from 'react-bootstrap';
import styled from 'styled-components';

/*margin: auto;
  max-width: ${props => props.maxWidth || '375'}px;
  */

const LayoutContainerBase = styled(Grid)``;

const LayoutContainer = (props) => <LayoutContainerBase {...props} />;

export default LayoutContainer;
