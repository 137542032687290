import * as React from 'react';
import {register, withTheme} from 'xcel-react-core';
import { PermissionsOptions } from "./PermissionsOptions";
import RequirePermissions from "./RequirePermissions";

const RebatePermissionsRoute: React.FC = ({children}) => {
    const permissionsOptions: PermissionsOptions = {
        canViewRebateOptions: true
    };

    return (
        <RequirePermissions permissionsOptions={permissionsOptions} redirectUrl='/program/program-overview'>
            {children}
        </RequirePermissions>
    )
};

export default register("rsv8-account/RebatePermissionsRoute")(
    withTheme())
(RebatePermissionsRoute);
