import * as React from 'react';
import { withResource } from 'xcel-react-core';
import { Text } from '../../../components/Typography';

const ResultCountText: React.SFC<{ currentCount: number; totalCount: number; resultCountText: string }> = (props) => (
  <Text themeVariation="result-count-text" className={`result-count-text`}>
    {props.resultCountText}
  </Text>
);

const mapResource = (getResource) => ({
  resultCountText: getResource('DataTableDefault.resultCountText', 'Showing results {currentCount} of {totalCount}')
});

export default withResource(mapResource)(ResultCountText);
