import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { InjectedFormProps } from 'redux-form';
import { withResource } from 'xcel-react-core';
import { getLastRequest, getRequestEndpoint } from 'xcel-redux-orm';
import { nominationActions } from '../../redux';

export type SubmitNominationFn = typeof nominationActions.createProcess;

export interface ApprovalFormProps {
  processId: number;
  applicationId: string;
  actions: {
    canApprove?: boolean;
    canDeny?: boolean;
    canSendBack?: boolean;
    canEscalate?: boolean;
  };
  approveNomination: SubmitNominationFn;
  changeVisibility?: any;
  autoSubmit?: boolean;
}

const mapState = (state) => ({
  lastRequest: getLastRequest(state, 'createProcess'),
  requestEndpoint: getRequestEndpoint(state, 'createProcess')
});

const mapDispatch = (dispatch, ownProps: ApprovalFormProps) =>
  bindActionCreators({ submitNomination: ownProps.approveNomination }, dispatch);

const mapResource = (getResource) => ({
  buttons: {
    approve: getResource('nominationDetailModal.approvalSubmitButton', 'Approve'),
    deny: getResource('nominationDetailModal.denialSubmitButton', 'Deny'),
    escalate: getResource('nominationDetailModal.escalateSubmitButton', 'Approve'),
    sendback: getResource('nominationDetailModal.approvalSubmitButton', 'Send Back')
  },
  resources: {
    approve: {
      error: getResource('nominationDetailModal.approvalErrorMessage', 'Problem approving nomination'),
      success: getResource('nominationDetailModal.approvalSuccessMessage', 'Nomination successfully approved')
    },
    escalate: {
      error: getResource('nominationDetailModal.escalateErrorMessage', 'Problem escalating nomination'),
      success: getResource('nominationDetailModal.escalateSuccessMessage', 'Approval successfully escalated')
    },
    deny: {
      error: getResource('nominationDetailModal.denialErrorMessage', 'Problem denying nomination'),
      success: getResource('nominationDetailModal.denialSuccessMessage', 'Approval successfully denied')
    },
    sendback: {
      error: getResource('nominationDetailModal.sendbackErrorMessage', 'Problem sending back to nominator'),
      success: getResource('nominationDetailModal.sendbackSuccessMessage', 'Successfully send back to nominator')
    }
  }
});

export const connectApprovalForm = compose(
  withResource(mapResource),
  connect(
    mapState,
    mapDispatch
  )
);

export interface ApprovalFormInternalProps extends ApprovalFormProps, InjectedFormProps {
  approveNomination: SubmitNominationFn;
  buttons: {
    approve: string;
    deny: string;
    escalate: string;
    sendback: string;
  };
  resources: {
    approve: { error: string; success: string };
    escalate: { error: string; success: string };
    deny: { error: string; success: string };
    sendback: { error: string; success: string };
  };
  lastRequest: any;
  requestEndpoint: any;
}
