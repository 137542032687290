import * as React from 'react';
import { withResource } from 'xcel-react-core';
import { DetailFormControl, OptionsFormGroup, OptionsText } from '../ProductOptions/styles';
// export interface Props {

// }

class ProductGroups extends React.Component<any, any> {
  render() {
    const { product, productGroupsSelection, handleChange, selectProductDropdownTitle } = this.props;

    return (
      <React.Fragment>
        <OptionsText>{selectProductDropdownTitle}</OptionsText>
        <OptionsFormGroup controlId="productGroupsControl">
          <DetailFormControl
            componentClass="select"
            name={product.id}
            placeholder="select"
            // can take onChange
            onChange={handleChange}
            ref={(component) => {
              productGroupsSelection[product.id] = component;
            }}
            // defaultValue={defaultGroupProduct}
          >
            <option>Select One</option>
            {product.groupedProducts
              .sort((a, b) => {
                if (a.pointValue < b.pointValue) return -1;
                if (a.pointValue > b.pointValue) return 1;
                return 0;
              })
              .map((option) => (
                <option key={option.productId} value={option.productId}>
                  {option.productName}
                </option>
              ))}
          </DetailFormControl>
        </OptionsFormGroup>
      </React.Fragment>
    );
  }
}

const mapResourceToProps = (getResource) => ({
  selectProductDropdownTitle: getResource('catalog.productGroupings.selectProduct', 'Select a Product')
});

export default withResource(mapResourceToProps)(ProductGroups);
