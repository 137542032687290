import { equals } from 'ramda';
import { themePlugin } from '../../config';
import processData from '../util/themeProcessor';
import componentReducer from './component.reducer';
import * as events from './events';
import fontReducer from './font.reducer';
import { setValue } from 'xcel-util';
// import { setValue } from 'xcel-util';
// import processData from '../util/themeProcessor';
const initialState = {
  currentStyle: 'default',
  pseudoSelector: [],
  mediaQuery: [],
  processed: {},
  fonts: {},
};
const themeReducer = (state: any = initialState, action) => {
  state.font = fontReducer(state.font, action);
  state.component = componentReducer(state.component, action);
  state.mediaQuery = state.mediaQuery ? state.mediaQuery : [];
  switch (action.type) {
    case events.COMPONENT_STYLE_PSEUDO_SELECTOR_SET: {
      return { ...state, pseudoSelector: action.pseudoSelector };
    }
    case events.COMPONENT_STYLE_SELECT:
      return { ...state, pseudoSelector: [] };
    case events.COMPONENT_STYLE_MEDIA_QUERY_SET: {
      return { ...state, mediaQuery: action.mediaQuery };
    }
    case themePlugin.events.PLUGIN_UPDATED: {
      const { data, type } = action.payload;
      const plugin = data;
      if (type !== 'Theme') {
        return state;
      }
      const processed = processData(
        { fonts: { ...plugin.fonts }, colors: { ...plugin.colors } },
        { ...plugin.theme }
      );

      if (!equals(processed, state.processed)) {
        return {
          ...state,
          processed,
          fonts: plugin.fonts,
        };
      }
      return state;
    }
    case events.COMPONENT_VARIATION_SELECT: {
      return { ...state, currentStyle: action.payload };
    }
    case events.COMPONENT_CREATE_SELECTOR: {
      return setValue(
        state,
        `rsv-cms-admin.content.Theme.edit.content.theme.${
          action.component
        }.selectors`,
        [...action.selectors]
      );
    }
    default:
      return state;
  }
};
export default themeReducer;
