import * as React from 'react';
import styled from 'styled-components';
import NomineeImage from './NomineeImage';

const NomineeNameContainer = styled.div`
  display: flex;
  align-items: center;
` as any;

const NomineeName = styled.span`
  padding-left: 15px;
` as any;

const NomineeWithName = (props) => (
  <NomineeNameContainer>
    <NomineeImage nominee={props.nominee} width={props.width ? props.width : '60px'} caption={false} />
    <NomineeName>
      {props.nominee.firstName} {props.nominee.lastName}
    </NomineeName>
  </NomineeNameContainer>
);

export default NomineeWithName;
