import * as events from '../events';
const componentActions = {
  propertySet: ({ model, property, value }) => ({
    type: events.COMPONENT_PROPERTY_SET,
    model,
    property,
    value
  }),
  move: ({ from, to }) => ({
    type: events.COMPONENT_MOVE,
    from,
    to
  }),
  add: ({ component, to }) => ({
    type: events.COMPONENT_ADD,
    component,
    to
  }),
  remove: ({ from }) => ({
    type: events.COMPONENT_REMOVE,
    from
  }),
  duplicate: ({ from }) => ({
    type: events.COMPONENT_DUPLICATE,
    from
  })
};

export default componentActions;
