import { Icon } from 'rsv8-components';
import styled from 'styled-components';

export const StyledIcon = styled(Icon as any)`
  margin-right: 5px;
` as any;

export const PaddedDiv = styled.div`
  padding: 15px 0;
` as any;

export const FlexDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
` as any;
