// Reducer Keys
export const TEAM_NOMINATION_REDUCER_KEY = 'rsv8-nomination/team';
export const INDIVIDUAL_NOMINATION_REDUCER_KEY = 'rsv8-nomination/individual';
export const NOMINATION_ACTIVITY_REDUCER_KEY = 'rsv8-nomination/activity';
export const CURRENT_NOMINATION_KEY = 'currentNomination';
export const NOMINEE_SEARCH_KEY = 'nominationMetaData';
export const NOMINATION_META_KEY = 'nominationMetaData';

// Endpoint Name Strings
export const NOMINATIONS_HISTORY_ENDPOINT = 'getNominationHistory';
export const ADD_TEAM_MEMBER_ENDPOINT_NAME = 'addTeamMember';
export const REMOVE_TEAM_MEMBER_ENDPOINT_NAME = 'removeTeamMember';
export const NOMINATIONS_INDIVIDUAL_DRAFT_ENDPOINT = 'getAllIndividualNominationDrafts';
export const NOMINATIONS_TEAM_DRAFT_ENDPOINT = 'getAllTeamNominationDrafts';
export const NOMINEE_SEARCH_ENDPOINT_NAME = 'NomineeSearch';
export const NOMINEE_SCROLL_ENDPOINT_NAME = 'NomineeScroll';
export const DELETE_DRAFT_ENDPOINT = 'deleteDraftNomination';
export const GET_INDIVIDUAL_DRAFT_ENDPOINT = 'getIndividualNominationDraft';
export const GET_TEAM_DRAFT_ENDPOINT = 'getTeamNominationDraft';
export const NOMINATION_SUBMISSION_ENDPOINT_NAME = 'getNominationsDraftById';

// Redux Form and Wizard Names
export const NOMINATION_ACTIVITY_HISTORY_SEARCH_FORM_NAME = 'NominationActivityHistorySearchForm';
export const INDIVIDUAL_NOMINATION_WIZARD_NAME = 'IndividualNominationWizard';
export const TEAM_NOMINATION_WIZARD_NAME = 'TeamNominationWizard';
export const NOMINEE_FILTER_FORM_NAME = 'NomineeFilterForm';
export const REDEEMING_GIFT_WIZARD_NAME = 'RedeemingGiftWizardName';

// JSON-API Type Strings
export const JSON_TYPE_NOMINEE = 'nominee';
export const JSON_TYPE_AWARD = 'application';
export const JSON_TYPE_PRODUCT = 'nominationApplicationProductViewModel';
export const JSON_TYPE_FILTER = 'filter';
export const JSON_TYPE_ROLE = 'teamMemberRole';
export const JSON_TYPE_WINNERS = 'winner';
export const JSON_TYPE_INDIVIDUAL_DRAFT_NOMINATION = 'draft';
export const JSON_TYPE_TEAM_DRAFT_NOMINATION = 'teamDraft';
export const JSON_TYPE_APPROVAL_HISTORY = 'approvalHistory';
export const JSON_TYPE_PENDING_APPROVAL = 'pendingApproval';
export const JSON_TYPE_INDIVIDUAL_NOMINATION_HISTORY = 'history';
export const JSON_TYPE_NOMINATION_DETAIL = 'nominationDetail';
export const JSON_TYPE_TEAM_NOMINATION_HISTORY = 'teamNomination';
