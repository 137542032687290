import * as React from 'react';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PageRenderer } from 'rsv8-cms';
import { toast } from 'rsv8-components';
import { Context, withWizard } from 'rsv8-wizard';
import { register, withResource } from 'xcel-react-core';
import { TeamReviewInstructions } from '../../components/index';
import { ErrorFormContainer } from '../../components/SearchBar/SearchBar';
import { teamMemberActions } from '../../redux';
import { teamNominationActions, teamNominationSelectors } from '../../redux/index';
import { Resources } from '../../types';
import { NarrowGrid } from './Shared/styles';
import TeamMemberPanel from './Shared/TeamMemberPanel';
import TeamSummary from './Shared/TeamSummary';

interface InternalProps {
  teamMembers: Array<Resources.Nominee>;
  submitNomination: () => void;
  validateTeamMember: Function;
  wizard: Context;
  errorText: any;
}

class TeamMembers extends React.Component<InternalProps, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }
  componentDidMount() {
    if (this.props.wizard) {
      this.checkValid()
        .then(() => {
          this.props.wizard.registerIncrement(this.props.submitNomination);
        })
        .catch((error) => {
          this.props.wizard.registerIncrement(() => Promise.reject(error));
        });
    }
  }

  checkValid = async () => {
    const { teamMembers } = this.props;
    if (this.validTeamMembers(teamMembers)) {
      const invalidMember = await this.validateMembersForThreshold(teamMembers);
      if (invalidMember) {
        toast({
          template: 'ContentToast',
          contentTemplate: (
            <PageRenderer
              path={'toast/nomination/team/threshold'}
              onNotFound={() => <PageRenderer path={`toast/nomination/team/threshold`} cultureId="0" />}
              nominee={invalidMember}
            />
          )
        });
        return Promise.reject('One nominee passed the threshold');
      }

      this.setState({ hasError: false });

      return Promise.resolve();
    } else {
      this.setState({ hasError: true });
      return Promise.reject('Please Select at least two Nominees');
    }
  };

  render() {
    return (
      <NarrowGrid>
        <TeamReviewInstructions />
        {this.state.hasError ? (
          <ErrorFormContainer>
            <Alert bsStyle="danger">{this.props.errorText}</Alert>
          </ErrorFormContainer>
        ) : null}
        <TeamSummary editable={true} />
        {this.props.teamMembers.map((member) => (
          <TeamMemberPanel key={member.id} nominee={member} editable={true} />
        ))}
      </NarrowGrid>
    );
  }
  private validTeamMembers(teamMembers: Array<Resources.Nominee>) {
    return teamMembers !== undefined && teamMembers !== null && teamMembers.length >= 2;
  }

  private async validateMembersForThreshold(teamMembers: Array<Resources.Nominee>) {
    let promisesToWait = teamMembers.map((tm) => {
      return this.props.validateTeamMember(tm.id);
    });

    let resp = await Promise.all(promisesToWait);
    return resp.indexOf(false) !== -1 ? teamMembers[resp.indexOf(false)] : null;
  }
}

const mapResource = (getResource) => ({
  errorText: getResource('teamNomination.teamMemberCountConfirmationError', 'Please Select at least two Nominees')
});

const mapState = (state) => ({ teamMembers: teamNominationSelectors.getCurrentNominees(state) });

const mapDispatch = (dispatch) =>
  bindActionCreators(
    { submitNomination: teamNominationActions.submit, validateTeamMember: teamMemberActions.validateTeamMember },
    dispatch
  );

export default register('rsv8-nomination/TeamNominationConfirmation')(
  connect(
    mapState,
    mapDispatch
  ),
  withWizard(),
  withResource(mapResource)
)(TeamMembers);
