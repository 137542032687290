import themeCmsPlugin from './cms.plugin.actions';
import selector from './cms.plugin.selectors';
import * as events from './events';
export const fontPreviewSet = (font) => ({ type: events.THEME_FONT_PREVIEW_SET, font });

export const fontAdd = (font) => (dispatch, getState) => {
  let fonts = selector.getFonts(getState());
  dispatch(themeCmsPlugin.setProperty({ fonts: { ...fonts, [font.name]: font } }));
};
let updateTimeout;
export const fontUpdate = (font) => (dispatch, getState) => {
  let fonts = selector.getFonts(getState());
  dispatch(themeCmsPlugin.setProperty({ fonts: { ...fonts, [font.name]: font } }));
  clearTimeout(updateTimeout);
};
export const fontRemove = (name) => (dispatch, getState) => {
  let fonts = selector.getFonts(getState());
  let key = Object.keys(fonts).find((fontName: string) => fontName.toLowerCase() === name.toLowerCase());
  delete fonts[key];
  dispatch(themeCmsPlugin.setProperty({ fonts: { ...fonts } }));
};
