import { userStateSelectors } from 'rsv8-auth';
import { getReduxStore } from 'xcel-redux-module';
import clientSelector from '../redux/selectors/client';

const activeClientMiddleware = {
  request: ({ request }) => ({ reject, next, resolve }) => {
    const store = getReduxStore();
    if (store) {
      const state = store.getState();
      const client = clientSelector.getActive(state);
      const user = userStateSelectors.getUser(state);
      const clientId = client ? client.id : user && user.clientId;
      if (clientId !== undefined && clientId !== 0) {
        request.headers = {
          ...request.headers,
          clientId
        };
      }
    }
    next();
  }
};

export default activeClientMiddleware;
