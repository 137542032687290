import * as React from "react";
import { connect } from "react-redux";
import {
  register
} from "xcel-react-core";
import { getConfigurationProperty } from "xcel-config";
import { push } from "react-router-redux";

const LogoutRedirectCallback = ({
  dispatch,
  ...rest
}) => {
  let identityServerConfigData = getConfigurationProperty([
    "identityServerConfigData"
  ]);
  if (identityServerConfigData) {
    dispatch(push("/oauth"));
  } else {
    dispatch(push("/login"));
  }

  return <></>;
};

const mapDispatch = dispatch => ({
  dispatch: dispatch
});

export default register("rsv8-auth/LogoutRedirectCallback")(
  connect(mapDispatch)
)(LogoutRedirectCallback);
