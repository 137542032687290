import styled from 'styled-components';
const TreeBranch = styled.ul`
  margin: 0 0 0 0.25em;
  position: relative;
  list-style: none;
  padding: 0;
  ul {
    margin: 0 0 0 0.5em; /* indentation */
    padding: 0;
    list-style: none;
    position: relative;
  }
  ul:before {
    content: '';
    display: block;
    width: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-left: 1px dashed;
  }
  li {
    margin: 0;
    padding: 0 0.5em; /* indentation + .5em */
    line-height: 2em;
    font-weight: bold;
    position: relative;
  }
  ul li:before {
    content: '';
    display: block;
    width: 0.5em; /* same with indentation */
    height: 0;
    border-top: 1px dashed;
    margin-top: -1px; /* border top width */
    position: absolute;
    top: 1em; /* (line-height/2) */
    left: 0;
  }
  li span {
    white-space: nowrap;
  }
  ${(props: any) =>
    props.isLast &&
    `
    li:before{
      background: white;
    } 
  `};
  ul:last-child li:last-child:before {
    position: absolute;
    height: auto;
    top: 1em; /* (line-height/2) */
    bottom: 0;
  }
` as any;

export default TreeBranch;
