import { register, withContent } from 'xcel-react-core';
import { getValue } from 'xcel-util';
const Guard = ({ property, value, showWhenMatch, children, ...rest }) => {
  if (property === undefined || value === undefined) return children;
  let propertyValue = getValue(rest, property);
  if (propertyValue !== undefined) propertyValue = propertyValue.toString();
  const match = propertyValue === value;
  if (!match && showWhenMatch) return null;
  if (match && !showWhenMatch) return null;
  return children;
};
const mapContentToProps = (getContent) => ({
  property: getContent('property', { type: 'none', label: 'Property to Check' }),
  value: getContent('value', {
    type: 'none',
    label: 'Match Value'
  }),
  showWhenMatch: getContent('showWhenMatch', {
    type: 'checkbox',
    label: 'Show if value matches'
  })
});
export default register('rsv8-components/Guard')(withContent(mapContentToProps))(Guard);
