import * as React from 'react';
import { Text } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import CartOptions from '../../../components/Cart/CartOptions';
import CartQuantity from '../../../components/Cart/CartQuantity';
import { CartButton, CatalogTableInfoLink, CatalogTableInfoProduct } from '../../../components/ProductTable/ProductTableStyles';
import {
  CartPanel,
  PanelButtons,
  PanelContent,
  PanelImage,
  PanelInformation,
  PanelsContainer,
  PanelShipMethod,
  PanelValue
  } from './styles';

interface CartPanelsProps {
  cartProducts: any;
  removeFromCart: (product: any) => any;
  changeOption: any;
  changeQuantity: any;
  removeFromCartLabel: string;
  pointsAbbrevLabel: string;
  accountSummary: any;
}

class CartPanels extends React.Component<CartPanelsProps, any> {
  render() {
    const {
      cartProducts,
      changeOption,
      changeQuantity,
      removeFromCartLabel,
      pointsAbbrevLabel,
      accountSummary
    } = this.props;

    return (
      <PanelsContainer className="hidden-sm hidden-md hidden-lg hidden-xl">
        {cartProducts &&
          cartProducts.filter((product) => product !== null).map((product, index) => (
            <CartPanel key={index}>
              <PanelInformation>
                <PanelImage imageurl={product.imageLg} />
                <PanelContent>
                  <CatalogTableInfoLink to={`/catalog/search?filterBrands=${product.manufacturerId}`}>
                    {product.manufacturer}
                  </CatalogTableInfoLink>
                  <CatalogTableInfoProduct
                    themeVariation="text-link"
                    to={`/catalog/product/${encodeURIComponent(product.encodedProductId)}`}
                    dangerouslySetInnerHTML={{ __html: product.productName }}
                  />
                  <PanelValue>
                    <Text themeVariation="secondary-text-bold">
                      {`${Number(product.pointValue || product.productPoints).toLocaleString()} ${pointsAbbrevLabel}`}
                    </Text>
                  </PanelValue>
                  {product.productOptionItems.length > 0 && (
                    <CartOptions product={product} changeOption={changeOption} />
                  )}
                </PanelContent>
              </PanelInformation>
              <PanelShipMethod>{product.shipMethod}</PanelShipMethod>
              <PanelButtons>
                <CartQuantity
                  product={product}
                  changeQuantity={changeQuantity}
                  accountSummary={accountSummary}
                  cartProducts={cartProducts}
                />
                <CartButton themeVariation="primary-link" onClick={this.props.removeFromCart(product)}>
                  {removeFromCartLabel}
                </CartButton>
              </PanelButtons>
            </CartPanel>
          ))}
      </PanelsContainer>
    );
  }
}

const mapResourceToProps = (getResource) => ({
  pointsAbbrevLabel: getResource('catalog.shoppingCart.pointsAbbrevLabel', 'pts.'),
  removeFromCartLabel: getResource('catalog.shoppingCart.removeFromCartLabel', 'Remove')
});

export default withResource(mapResourceToProps)(CartPanels);
