import { isEmpty } from 'lodash';
import { cultureSelector } from 'rsv8-client';
import { LocalShareableFeed, ShareableFeed } from '../../api/get-shareables-activities';
import {
  ACTIVITIES_FAILED,
  ACTIVITIES_LOADING,
  SHAREABLES_ACTIVITIES_FAILED,
  SHAREABLES_ACTIVITIES_LOADING
  } from '../constants';
import { ROOT_SOCIAL } from '../events';
import { initialState } from '../reducers';

export const rootModule = (state: typeof initialState) => {
  const root = !isEmpty(state[ROOT_SOCIAL]) ? state[ROOT_SOCIAL] : { ...initialState };
  return root;
};

export const activities = (appState) => {
  const root = rootModule(appState);
  return (root.activities && root.activities.data) || [];
};

export const getActivitiesState = (appState: any) => {
  const {
    activities: { state }
  } = rootModule(appState);
  return state;
};

export const actionInProcess = (appState: any) => {
  return rootModule(appState).activities.actionInProcess;
};

export const isLoadingActivities = (appState: any) => {
  return getShareablesActivitiesState(appState) === ACTIVITIES_LOADING;
};

export const isFailedActivities = (appState: any) => {
  return getShareablesActivitiesState(appState) === ACTIVITIES_FAILED;
};

export const getShareablesActivityGroups = (appState) => {
  const cultureId = Number(cultureSelector.getActiveCultureId(appState));
  const {
    shareablesActivities: { data }
  } = rootModule(appState);
  return localizeShareablesActivityGroups(data, cultureId);
};

function localizeShareablesActivityGroups(data: ShareableFeed[] = [], cultureId: number): LocalShareableFeed[] {
  const localFeed: LocalShareableFeed[] = data.reduce((acc, item) => {
    acc.push({
      ...item,
      activities: (item.activities || []).map((activity) => {
        return {
          ...activity,
          actions: (activity.actions || []).map((action) => {
            const localInfo = (action.locals || []).find((info) => info.cultureId === cultureId);
            return {
              id: action.id,
              label: localInfo ? localInfo.label : '',
              iconDefault: localInfo ? localInfo.iconDefault : '',
              iconHover: localInfo ? localInfo.iconHover : '',
              iconClicked: localInfo ? localInfo.iconClicked : ''
            };
          })
        };
      })
    });
    return acc;
  }, []);
  return localFeed;
}

export const getShareablesActivitiesState = (appState: any) => {
  const { shareablesActivities } = rootModule(appState);
  return (shareablesActivities && shareablesActivities.state) || { ...initialState }.shareablesActivities.state;
};

export const isLoadingShareablesActivities = (appState: any) => {
  return getShareablesActivitiesState(appState) === SHAREABLES_ACTIVITIES_LOADING;
};

export const isFailedShareablesActivities = (appState: any) => {
  return getShareablesActivitiesState(appState) === SHAREABLES_ACTIVITIES_FAILED;
};
