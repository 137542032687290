import * as moment from 'moment';
import { equals } from 'ramda';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose, withPropsOnChange } from 'recompose';
import { bindActionCreators } from 'redux';
import {
  BaseCollapse,
  HeaderOne,
  HorizontalRule,
  Icon,
  Panel,
  PanelBody,
  Text
  } from 'rsv8-components';
import styled from 'styled-components';
import { DivProps } from 'xcel-react-core';
import { nominationActivityActions } from '../../../redux';
import { nominationDetailSelectors } from '../../../redux/selectors/nomination';
import { NominationActivity, Resources } from '../../../types';
import { NomineeImage } from '../../NomineeImage';
import PointCost from '../../PointCost/index';
import { TextRow } from '../../TextRow';
import { TeamIcon, withModalResources, WithModalResourcesProps } from '../util';
import {
  Body,
  CircularContainer,
  Status,
  StatusContainer,
  StyledCollapse,
  StyledPanel
  } from './styles';
import TeamDetails from './TeamDetails';
import { TeamRow } from './TeamRow';

const TeamContainer = styled.div`
  padding: 50px 50px 10px 50px;
` as any;

const ImageContainer = styled.div`
  position: absolute;
  top: -45px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 10px;
` as any;

const StyledPanelBody = styled(PanelBody)`
  position: relative;
  width: 100%;
` as any;

const StyledHeaderOne = styled(HeaderOne)`
  margin-top: 15px !important;
  margin-bottom: 20px !important;
` as any;

const TeamPanel = styled(StyledPanel)`
  height: 150px;
` as any;

const MemberPanel = styled(Panel)`
  margin: 0 0 15px 0;
  border-radius: 0;
  border: 0;
  box-shadow: 0px 1px 5px 0px rgba(119, 119, 119, 0.5);
` as any;

const MemberPanelBody = styled(PanelBody)`
  display: flex;
` as any;

const Member = styled.div`
  display: flex;
  align-items: center;
` as any;

const Head = styled.div`
  width: 100%;
`;

const Tail = styled.div`
  width: 100%;
  padding-bottom: 5px;
  text-align: center;
  cursor: pointer;
`;
const PointSummary = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  > div {
    flex-basis: 50%;
  }
`;
const PointSummaryBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const Caption = styled(Text)`
  padding: 10px;
`;
export interface TeamBodyProps extends DivProps {
  nomination: Resources.TeamHistoryDetails;
  nominationType: NominationActivity.ActivityTables;
  nominationDetails: Array<Resources.NominatorDetail>;
  teamMemberIds: Array<string>;
  nominationDetailIds: Array<string>;
  customFields: Record<string, string>;
  loadTeamMembers: typeof nominationActivityActions.getTeamHistoryMemberDetails;
}

class TeamBody extends React.PureComponent<TeamBodyProps & WithModalResourcesProps, {}> {
  componentDidUpdate(prevProps: TeamBodyProps, prevState: {}) {
    if (!equals(prevProps.nominationDetailIds, this.props.nominationDetailIds)) {
      this.handleLoad();
    }
  }

  handleLoad = () => {
    this.props.loadTeamMembers(this.props.nominationDetailIds);
  };

  reduceCustomFields = (acc, field) => ({ ...acc, [field.customFieldId]: field.value });

  renderHeader = ({ open, onClick }) => {
    return (
      <Head>
        <HorizontalRule themeVariation="primary-xs-margin" />
        {!open && (
          <Tail onClick={onClick}>
            <Icon icon="chevron-down" />
          </Tail>
        )}
      </Head>
    );
  };

  renderTail = ({ open, onClick }) => {
    return (
      <Tail onClick={onClick}>
        <Icon icon="chevron-up" />
      </Tail>
    );
  };

  renderDetails = (nominatorDetails, index) => {
    return (
      nominatorDetails.nomination && (
        <MemberPanel>
          <MemberPanelBody>
            <Member>
              <NomineeImage
                onClick={this.props.onClick}
                separate={false}
                nominee={nominatorDetails.nomineeUser}
                caption={false}
                orientation="right"
              />
              <Caption>{`${nominatorDetails.nomineeUser.firstName} ${nominatorDetails.nomineeUser.lastName}`}</Caption>
            </Member>
          </MemberPanelBody>
          <BaseCollapse heading={this.renderHeader} tailComponent={this.renderTail} tailCollapses={true}>
            <PanelBody>
              <TextRow head={this.props.labels.createDate}>
                {moment(nominatorDetails.nomination.createDate).format('MM/DD/YYYY')}
              </TextRow>
              <TextRow head={this.props.labels.status}>
                <StatusContainer>
                  <Status status={nominatorDetails.nomination.statusId.toString()} />
                </StatusContainer>
              </TextRow>
              <PointSummary>
                <PointSummaryBlock>
                  <Text variationName="field-label">{this.props.labels.pointValue}</Text>
                  {nominatorDetails.awardValue}
                </PointSummaryBlock>
                <PointCost
                  flexDirection={'column'}
                  padding={'0px'}
                  showCost={false}
                  id={nominatorDetails.applicationId}
                  pointValue={nominatorDetails.awardValue}
                  nomineeId={nominatorDetails.nomination.nomineeId}
                />
              </PointSummary>
              {/* <TextRow head={this.props.labels.pointValue}>{nominatorDetails.nomination.pointValue}</TextRow> */}
            </PanelBody>
            <HorizontalRule themeVariation="primary-xs-margin" />
          </BaseCollapse>
        </MemberPanel>
      )
    );
  };

  render() {
    return (
      <Body>
        <TeamContainer>
          <TeamPanel>
            <StyledPanelBody>
              <CircularContainer>{''}</CircularContainer>
              <ImageContainer>
                <TeamIcon>
                  <StyledHeaderOne>{this.props.nomination.teamName}</StyledHeaderOne>
                </TeamIcon>
              </ImageContainer>
            </StyledPanelBody>
          </TeamPanel>
        </TeamContainer>
        <StyledCollapse themeVariation="secondary" title={this.props.headers.details} style={{ marginBottom: '1px' }}>
          <TeamRow head="Team Name">{this.props.nomination.teamName}</TeamRow>
          <TeamRow head="Team ID">{this.props.nomination.teamId}</TeamRow>
          <TeamRow head="Status">
            <StatusContainer>
              <Status status={this.props.nomination.statusId} />
            </StatusContainer>
          </TeamRow>
          <TeamRow head="Award">{this.props.nomination.applicationName}</TeamRow>
          <TeamDetails
            formType="TeamNomination"
            appKey={this.props.nomination.applicationKey}
            customFields={this.props.customFields}
          />
        </StyledCollapse>
        <StyledCollapse themeVariation="secondary" title={this.props.headers.teamMembers}>
          {this.props.nominationDetails.map(this.renderDetails)}
        </StyledCollapse>
      </Body>
    );
  }
}

const mapProps = (props: TeamBodyProps) => ({
  nominationDetailIds: (props.nomination.teamMembers || []).map((m) => m.nominationDetailId),
  teamMemberIds: (props.nomination.teamMembers || []).map((m) => m.userId),
  customFields: props.nomination && props.nomination.customFields
});

const mapState = (state, ownProps: TeamBodyProps) => ({
  nominationDetails: nominationDetailSelectors.selectMany(state, ownProps.nominationDetailIds)
});

const mapDispatch = (dispatch) =>
  bindActionCreators({ loadTeamMembers: nominationActivityActions.getTeamHistoryMemberDetails }, dispatch);

export default compose<TeamBodyProps, Pick<TeamBodyProps, 'nomination' | 'nominationType'>>(
  withPropsOnChange(['nomination'], mapProps),
  withModalResources,
  connect(mapState, mapDispatch)
)(TeamBody);
