import * as React from 'react';
import { connect } from 'react-redux';
import { Amount } from 'xcel-api-generator/dist/alcon';
import * as selectors from "../../redux/selectors";
import GaugeChart from './GaugeChart';

const UnitsGauge: React.FC<Props> = ({
  chartWidgetSize,
  labelColor,
  gaugeColor,
  completedGaugeColor,
  valueLabelSize,
  dialMiddleText = 'units',
  bottomLabelSize,
  labelFont,
  gaugeContent,
  amounts,
}) => (
  <>
    {gaugeContent.map((element: any) => {
      const amount = amounts.find((el) => el.id.toLowerCase() === element.gaugeid.toLowerCase());
      const current = amounts.find((el) => el.id.toLowerCase() === 'current');
      if (!amount) return null;

      return (
        <GaugeChart
          key={amount.id}
          chartWidgetSize={chartWidgetSize}
          labelColor={labelColor}
          gaugeColor={gaugeColor}
          completedGaugeColor={completedGaugeColor}
          valueLabelSize={valueLabelSize}
          dialMiddleText={dialMiddleText}
          bottomLabelSize={bottomLabelSize}
          labelFont={labelFont}
          title={amount.name}
          currentValue={current.units || 0}
          maxValue={amount.units}
          maxValueFormatted={amount.units}
        />
      );
    })}
  </>
)

const mapStateToProps = state => ({
  amounts: selectors.amountSelector.selectMany(state),
})

interface StateProps {
  amounts: Amount[];
}
interface DispatchProps { }
interface OwnProps {
  labelColor: string;
  gaugeColor: string;
  completedGaugeColor: string;
  valueLabelSize: string;
  bottomLabelSize: string;
  dialMiddleText: string;
  chartWidgetSize: number;
  labelFont: string;
  gaugeContent: Array<any>;
}
type Props = StateProps & DispatchProps & OwnProps;

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps)(UnitsGauge);