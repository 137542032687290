import * as React from 'react';
import { connect } from 'react-redux';
import { Panel, PanelBody } from 'rsv8-components';
import styled from 'styled-components';
import { productSelectors } from '../../redux/selectors/index';
import { Resources } from '../../types';
import Product from './Product';

const MyPanel: any = styled(Panel)`
  min-height: 175px;
  background: transparent;
  margin-left: 0%;

  @media (max-width: 1366px) {
    min-height: 210px;
  }
  @media (max-width: 992px) {
    min-height: 235px;
  }
  @media (max-width: 768px) {
    min-height: auto;
  }

  &.panel {
    height: 95%;
    box-shadow: none;
    border: 0;

    &:hover {
      background-color: transparent;
    }

    &.active {
      box-shadow: none;
      .ProductCard {
        border: 2px solid rgba(66, 139, 202, 1) !important;
      }

      &:hover {
        .ProductCard {
          border: none;
        }
      }
    }
  }
`;

export interface ProductPanelProps {
  id: string;
  active?: boolean;
  disabled?: boolean;
  reduxOptionId: any;
  onClick: (event: any) => void;
  handleOption: (id: string, pointValue: number) => void;
  productRenderer?: React.SFC<{ product: any }> | React.ComponentClass<{ product: any }>;
  className?: string;
}

interface InternalProps extends ProductPanelProps {
  product: Resources.Product;
  dispatch: Function;
}

const ProductPanel: React.SFC<InternalProps> = ({
  id,
  product,
  active = false,
  className,
  disabled = false,
  productRenderer: ProductComponent = Product,
  dispatch,
  ...rest
}) => {
  return (
    <MyPanel hover={true} className={`${className || ''} ${active ? 'active' : ''}`} {...rest}>
      <PanelBody>
        <ProductComponent product={product} {...rest} />
      </PanelBody>
    </MyPanel>
  );
};

const mapState = (state, ownProps) => {
  const selectedProduct = productSelectors.selectOne(state, ownProps.id) || ({} as Resources.ProductAttributes);
  const product = Array.isArray(selectedProduct) ? selectedProduct[0] : selectedProduct;

  return {
    product
  };
};

export default connect(
  mapState,
  null
)(ProductPanel) as React.ComponentClass<ProductPanelProps>;
