import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators, compose } from 'redux';
import { registry } from 'xcel-react-core';
import { Context, State, Step } from '../../../types';
import {
  decrementWizard,
  incrementWizard,
  registerWizard,
  setWizardStepByStepTitle,
  skipStep,
  unregisterWizard,
  unskipAll
  } from '../../redux/actions';
import { getWizard } from '../../redux/selectors';
import { WizardContext } from '../WizardContext';
import { WizardContainer } from './styles';
import { moveWizard } from './util';
import DefaultProgressBar from './WizardProgress';

export interface WizardProps {
  name: string;
  steps: Array<Step>;
  progressRenderer?: React.SFC<{ wizard: Context; name: string }>;
  props?: any;
  initialStep?: any;
}

interface InternalProps extends WizardProps {
  actions: {
    setWizardStepByStepTitle: any;
    registerWizard: any;
    unregisterWizard: any;
    incrementWizard: any;
    decrementWizard: any;
    removeStep: any;
    enableAll: any;
  };
  wizard: State;
}

class Wizard extends React.Component<InternalProps, Context> {
  decrementPromises: Array<Function> = [];
  incrementPromises: Array<Function> = [];

  static getDerivedStateFromProps = (nextProps: InternalProps, prevState: Context) => ({ ...nextProps.wizard });

  constructor(props: any) {
    super(props);
    this.state = {
      ...props.wizard,
      increment: this.increment,
      decrement: this.decrement,
      registerDecrement: this.registerDecrement,
      registerIncrement: this.registerIncrement,
      forceIncrement: this.forceIncrement,
      forceDecrement: this.forceDecrement,
      forceWizardStep: this.forceWizardStep,
      removeWizardStep: this.removeCurrentStep,
      enableAllSteps: this.enableAllSteps
    };
  }

  componentDidMount() {
    const { name, steps, initialStep, actions } = this.props;
    actions.registerWizard(name, { steps, initialStep });
  }

  componentWillReceiveProps(nextProps: InternalProps) {
    if (this.props.name !== nextProps.name) {
      throw "One does not simply change a Wizard's name";
    }
  }

  componentWillUnmount() {
    this.props.actions.unregisterWizard(this.props.name);
  }

  registerIncrement = (fn: Function): void => {
    this.incrementPromises = [...this.incrementPromises, fn];
  };

  registerDecrement = (fn: Function): void => {
    this.decrementPromises = [...this.decrementPromises, fn];
  };

  moveSuccess = (fn: (n: string) => void) => (): void => {
    this.clearPromises();
    fn(this.props.name);
  };

  increment = (): void => {
    if (this.props['data-cms-editing'] === true) {
      this.forceIncrement();
    } else {
      moveWizard(this.incrementPromises, this.moveSuccess(this.props.actions.incrementWizard));
    }
  };

  decrement = (): void => {
    if (this.props['data-cms-editing'] === true) {
      this.forceDecrement();
    } else {
      moveWizard(this.decrementPromises, this.moveSuccess(this.props.actions.decrementWizard));
    }
  };

  forceWizardStep = (title: string): void => {
    this.clearPromises();
    this.props.actions.setWizardStepByStepTitle(this.props.name, title);
  };

  forceIncrement = (): void => {
    console.warn('[Wizard] Use forceIncrement with caution. The workflow may rely on callbacks that this will skip.');
    this.moveSuccess(this.props.actions.incrementWizard)();
  };

  forceDecrement = (): void => {
    console.warn('[Wizard] Use forceDecrement with caution. The workflow may rely on callbacks that this will skip.');
    this.moveSuccess(this.props.actions.decrementWizard)();
  };

  clearPromises = (): void => {
    this.incrementPromises = [];
    this.decrementPromises = [];
  };

  removeCurrentStep = (): void => {
    this.props.actions.removeStep(this.props.name, this.props.wizard.currentStep);
  };

  enableAllSteps = (): void => {
    this.props.actions.enableAll(this.props.name);
  };

  render() {
    const { currentStep } = this.props.wizard;
    if (currentStep === undefined) {
      return null;
    }
    const Component = registry.get().components[currentStep.component];
    const WizardProgress = this.props.progressRenderer || DefaultProgressBar;

    return (
      <React.Fragment>
        <WizardContext.Provider value={this.state}>
          <WizardContainer>{Component && <Component wizard={this.state} {...this.props.props} />}</WizardContainer>
        </WizardContext.Provider>
        <WizardProgress name={this.props.name} wizard={this.state} />
      </React.Fragment>
    );
  }
}

const mapState = (state, ownProps: any) => ({ wizard: getWizard(state, ownProps.name) });

const mapDispatch = (dispatch) => ({
  actions: bindActionCreators(
    {
      setWizardStepByStepTitle,
      incrementWizard,
      decrementWizard,
      unregisterWizard,
      registerWizard,
      enableAll: unskipAll,
      removeStep: skipStep
    },
    dispatch
  )
});

export default compose<any>(
  withRouter,
  connect(
    mapState,
    mapDispatch
  )
)(Wizard) as React.SFC<WizardProps>;
