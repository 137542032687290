import * as React from 'react';
import { connect } from 'react-redux';
import { Text } from 'rsv8-components';
import styled from 'styled-components';
import { MarketShareAmount, RebateTimePeriod } from 'xcel-api-generator/dist/alcon';
import { register, withContent, withTheme } from 'xcel-react-core';
import { withApiDataProvider } from 'xcel-redux-orm';
import { getCurrencyValue, getGoalValues, getRoundedValue, isNumber } from '../../../utils';
import { getSummary } from '../../redux/actions';
import { currentTimePeriodSelector, dashboardSelector, marketShareAmountSelector } from '../../redux/selectors';

const StyledTable = styled.table`
  margin: 0 auto;
  border-collapse: separate;
  border-top: 1px solid #b2b2b2;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  td {
    width: 50%;
    border-right: 1px solid #b2b2b2;
    border-bottom: 1px solid #b2b2b2;
  }

  tr:first-child td:first-child {
    border-top-left-radius: 10px;
  }
  tr:first-child td:last-child {
    border-top-right-radius: 10px;
  }
  tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
  }
  tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
  }
`;

const StyledText = styled.div`
  padding: 15px 50px;
  text-align: center;
`;

const ForecastTable: React.FC<Props> = ({
  textForValues,
  textForEmptyValues,
  currentTimePeriod,
  target,
  emptyValue = '-',
  shareGoalLabel,
  salesGoalLabel,
  rebateLabel,
  className,
}) => {
  if (!target || !currentTimePeriod) return null;

  const rebateValue = React.useMemo(() => {
    const { baseRebate, growthRebate } = target;
    return Math.max(isNumber(baseRebate) ? baseRebate : 0, isNumber(growthRebate) ? growthRebate : 0);
  }, [target]);

  const goals = React.useMemo(() => {
    return getGoalValues(target);
  }, [target]);

  const { shareGoal, salesGoal } = goals;

  return (
    <>
      <StyledTable className={className}>
        <tbody>
          <tr>
            <td className="share-label">
              {currentTimePeriod.name}
              <br />
              {shareGoalLabel}
            </td>

            <td className="share-value">{(shareGoal && getRoundedValue(shareGoal, 1) + '%') || emptyValue}</td>
          </tr>

          <tr>
            <td className="share-label">
              {currentTimePeriod.name}
              <br />
              {salesGoalLabel}
            </td>

            <td className="share-value">{(salesGoal && getCurrencyValue(salesGoal, 2)) || emptyValue}</td>
          </tr>

          <tr>
            <td className="share-label">{rebateLabel}</td>

            <td className="share-value">{(rebateValue && getCurrencyValue(rebateValue, 2)) || emptyValue}</td>
          </tr>
        </tbody>
      </StyledTable>
      <StyledText>
        <Text>{salesGoal > 0 || shareGoal > 0 ? textForValues : textForEmptyValues}</Text>
      </StyledText>
    </>
  );
};

const mapContentToProps = (getContent) => ({
  textForValues: getContent('textForValues', {
    type: 'text',
    label: 'Text to show for when at least one value is greater than 0',
  }),
  textForEmptyValues: getContent('textForEmptyValues', { type: 'text', label: 'Text to show when all values are 0' }),
  emptyValue: getContent('emptyValue', { type: 'text', label: 'Empty value' }),
  shareGoalLabel: getContent('shareGoalLabel', { type: 'text', label: 'Share Goal Label' }),
  salesGoalLabel: getContent('salesGoalLabel', { type: 'text', label: 'Sales Goal Label' }),
  rebateLabel: getContent('rebateLabel', { type: 'text', label: 'Rebate Label' }),
});

const mapStateToProps = (state, ownProps) => ({
  target: marketShareAmountSelector.selectOne(state, 'Target'),
  currentTimePeriod: currentTimePeriodSelector(state),
  ownProps,
});

interface StateProps {
  target: MarketShareAmount;
  currentTimePeriod: RebateTimePeriod;
}
interface ContentProps {
  textForValues: string;
  textForEmptyValues: string;
  emptyValue?: string;
  shareGoalLabel: string;
  salesGoalLabel: string;
  rebateLabel: string;
}
type Props = React.HTMLAttributes<any> & StateProps & ContentProps;

export default register('rsv8-alcon/ForecastTable')(
  connect(mapStateToProps),
  withApiDataProvider(getSummary, dashboardSelector),
  withContent(mapContentToProps),
  withTheme()
)(ForecastTable);
