import * as React from 'react';
import { connect } from 'react-redux';
import { Loader } from 'rsv8-components';
import { isValidatingKey } from '../../app/redux/selectors';
// Display at least something when it's making the accessToken validation
const AccessKeyLoader = ({ accessKeyValidation }) => {
  return accessKeyValidation ? <Loader /> : null;
};

const mapStateToProps = (state) => ({
  accessKeyValidation: isValidatingKey(state)
});

export default connect(mapStateToProps)(AccessKeyLoader) as any;
