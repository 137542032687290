import * as React from 'react';
import { Image } from 'rsv8-components';
import styled from 'styled-components';

const AwardsEmptyContainer = styled.div`
  background-color: #ffffff;
  border: 1px solid #d4d4d4;
  padding: 100px 25px;
  margin-top: 50px;
  font-size: 16px;
  color: #7c8180;
  font-weight: bold;
  text-align: center;
  border-radius: 3px;
`;

const EmptyMessage = styled.div`
  color: #7c8180;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
`;

type EmptyProps = {
  isDisplayed: boolean;
  emptyText: string;
};

const PlaceholderEmpty = ({ isDisplayed, emptyText }: EmptyProps) =>
  isDisplayed ? (
    <AwardsEmptyContainer>
      <Image themeVariation="nomination-ribbon" />
      <EmptyMessage>{emptyText}</EmptyMessage>
    </AwardsEmptyContainer>
  ) : null;

export default PlaceholderEmpty;
