import GalleryController from 'embla-carousel-react';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { PageRenderer } from 'rsv8-cms';
import { Loader } from 'rsv8-components';
import styled from 'styled-components';
import { ProductModel } from 'xcel-api-generator/dist/catalog';
import {
  register,
  withContent,
  withResource,
  withTheme
  } from 'xcel-react-core';
import { Card } from '../../components/Card';
import { Navigation } from '../../components/Navigation';
import { getFavoriteProducts } from '../../redux/actions/getFavorites';
import { getFavoriteProductList, getFavoritesLoaded } from '../../redux/selectors';

const Container = styled.div`
  box-shadow: 0 0 5px 0 rgba(34, 34, 34, 0.2);
  min-height: 525px;
`;
const SpinnerContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const GalleryContainer = styled.div`
  display: flex;
`;

interface Props {
  actions: {
    getFavoriteProducts: ({ id }: { id: string }) => void;
  };
  favoritesList: ProductModel[];
  isLoaded: boolean;
  resources: {
    title: string;
    currencyText: string;
    buttonText: string;
  };
}

const FavoritesOverview: React.FC<Props> = ({ resources, favoritesList, actions, isLoaded }) => {
  const [gallery, setGallery] = React.useState(null);
  const [scrollSnaps, setScrollSnaps] = React.useState([]);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const [prevBtnEnabled, togglePrevBtnEnabled] = React.useState(false);
  const [nextBtnEnabled, toggleNextBtnEnabled] = React.useState(false);

  const scrollPrev = React.useCallback(() => gallery.scrollPrev(), [gallery]);
  const scrollNext = React.useCallback(() => gallery.scrollNext(), [gallery]);
  const scrollTo = React.useCallback((index) => gallery.scrollTo(index), [gallery]);

  React.useEffect(() => {
    actions.getFavoriteProducts({ id: 'favorite' });
  }, []);

  React.useEffect(() => {
    const onSelect = () => {
      setSelectedIndex(gallery.selectedScrollSnap());
      togglePrevBtnEnabled(gallery.canScrollPrev());
      toggleNextBtnEnabled(gallery.canScrollNext());
    };
    if (gallery) {
      setScrollSnaps(gallery.scrollSnapList());
      gallery.on('select', onSelect);
      onSelect();
    }
    return () => gallery && gallery.destroy();
  }, [gallery]);

  if (!isLoaded) {
    return (
      <SpinnerContainer>
        <Loader />
      </SpinnerContainer>
    );
  }
  if (favoritesList.length > 0) {
    return (
      <Container>
        <GalleryController emblaRef={setGallery} options={{ loop: false }}>
          <GalleryContainer>
            {favoritesList.map((favorite, idx) => (
              <Card
                product={favorite}
                key={`${idx}${favorite.id}`}
                title={resources.title}
                buttonText={resources.buttonText}
                currencyText={resources.currencyText}
              />
            ))}
          </GalleryContainer>
        </GalleryController>

        <Navigation
          increment={scrollNext}
          decrement={scrollPrev}
          items={scrollSnaps}
          selected={selectedIndex}
          scrollTo={scrollTo}
          nextBtnEnabled={nextBtnEnabled}
          prevBtnEnabled={prevBtnEnabled}
        />
      </Container>
    );
  }
  return (
    <Container>
      <PageRenderer path={`rf-rewards/no-favorites`} />
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  favoritesList: getFavoriteProductList(state),
  isLoaded: getFavoritesLoaded(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    {
      getFavoriteProducts
    },
    dispatch
  )
});
const mapResource = (getResource) => ({
  resources: {
    title: getResource('FavoritesOverview.title', 'YOUR FAVORITES'),
    buttonText: getResource('FavoritesOverview.buttonText', 'REDEEM NOW'),
    currencyText: getResource('FavoritesOverview.currencyText', 'points')
  }
});
export default register('rsv8-favorites/FavoritesOverview')(
  withContent(),
  withTheme(),
  connect<any, any>(mapStateToProps, mapDispatchToProps),
  withResource(mapResource)
)(FavoritesOverview);