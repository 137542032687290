import { mapJsonApiMethodAction } from 'xcel-redux-orm';
import { nominationApi } from '../../../../api/index';
import { teamNominationSelectors } from '../../../selectors/index';

const saveTeamMemberRequest = mapJsonApiMethodAction({
  method: nominationApi.updateTeamDraftByIdMembersAndUserId,
  endpoint: 'updateTeamMember'
});

const saveTeamMember = (i) => async (dispatch, getState) => {
  const state = getState();
  const nominationId = teamNominationSelectors.getCurrentNominationId(state);
  if (nominationId) {
    let teamMember = teamNominationSelectors.getNomineeDetails(state, i);
    try {
      await dispatch(saveTeamMemberRequest({ id: nominationId, userId: i, model: teamMember as any }));
    } catch (error) {
      console.log(error);
    }
  }
};

const saveAllTeamMembers = () => (dispatch, getState) => {
  const currentTeamMembers = teamNominationSelectors.getCurrentNomineeIds(getState());
  currentTeamMembers.forEach((id) => dispatch(saveTeamMember(id)));
};

export { saveTeamMember, saveAllTeamMembers };
