import * as React from 'react';

// TODO: Replace hardcoded fill color.
const SupportIcon = (props) => (
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <title>support</title>
    <circle fill="#ffffff" cx="14.8" cy="19.76" r="0.97" />
    <path
      fill="#ffffff"
      d="M15.45,17.05c0-1.08.39-1.39,1.55-2.2s1.65-1.4,1.65-2.56c0-1.71-1.33-2.79-3.4-2.79a4,4,0,0,0-2.66.81,3.45,3.45,0,0,0-1.05,1.6.71.71,0,0,0,0,.24c0,.3.25.47.52.54a1.07,1.07,0,0,0,.25,0,.6.6,0,0,0,.59-.46,2.24,2.24,0,0,1,2.32-1.61c1.33,0,2.13.66,2.13,1.69,0,.72-.31,1.08-1.34,1.81a4.26,4.26,0,0,0-1.26,1.06A2.57,2.57,0,0,0,14.19,17v.39c0,.37.28.55.64.55a.55.55,0,0,0,.62-.55Z"
    />
    <path fill="#ffffff" d="M15,6a9,9,0,1,1-9,9,9,9,0,0,1,9-9m0-1A10,10,0,1,0,25,15,10,10,0,0,0,15,5Z" />
  </svg>
);

export default SupportIcon;
