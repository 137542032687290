import * as React from 'react';
import styled from 'styled-components';
import { register, withContent, withTheme } from 'xcel-react-core';
import { Button } from 'react-bootstrap'

interface ResponsiveDynamicTableProps {
  columns: Array<any>
  data: Array<any>
  colDef: Array<any>
  className?: any
  loadMoreLabel?: string
  paginationPageSize: number
}

interface ResponsiveDynamicTableState {
  items: Array<any>
  page: number
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
  border-radius: 3px;
  border: 1px solid #cccccc;
  &:first-child {
    margin-top: 0px;
  }
  &:last-child {
    margin-bottom: 50px;
    padding-bottom: 10px;
  }
`;
const Cell = styled.div`
  flex: 1 1 100%;
  padding: 10px 0 0 0;
  overflow: initial !important;
  height: 53px;
  display: flex;
  align-items: center;
  padding: 10px 20px 10px 20px;
  border-bottom: 1px solid #ddd;
`;
const Text = styled.div`
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  background-color: transparent;
  color: #414141;
`;
const Header = styled.div`
  flex: 1;
  vertical-align: top;
  box-sizing: border-box;
  padding-right: 5%;
`;
const Value = styled.div`
  margin: 10px;
  flex: 1;
  text-align: right;
`;
const FullWidthValue = styled.div`
  width: 100%;
`;

export const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 25px;
` as any;

class ResponsiveDynamicTable extends React.Component<ResponsiveDynamicTableProps, ResponsiveDynamicTableState> {

  static defaultProps = {
    paginationPageSize: 10,
    data: []
  }

  state = {
    items: [],
    page: 1
  }

  loadMore = (): void => this.setState({ page: this.state.page + 1 })

  render() {
    const { props, state } = this;
    const { columns, data, className } = props;
    const gridSize = props.paginationPageSize * state.page
    return (
      <React.Fragment>
        <div className={className} style={{ borderRadius: 3, marginLeft: 10, marginRight: 10 }}>
          {data.slice(0, gridSize).map((record, index) =>
            (
              <Row key={index}>
                {columns.map((column, i, array) => {
                  const isLastElement = array.length - 1 === i
                  const style = isLastElement ? { borderBottom: 0 } : {}
                  const { renderCell, renderHeader, ...rest } = column;
                  const label = column.headerName || column.name
                  const value = column.cellRendererFramework
                    ? column.cellRendererFramework
                    : record[ column.field ] || '';

                  const CustomComponent = column.cellRendererFramework
                  if (CustomComponent) {
                    const isFistElement = i === 0
                    const headerStyle = isFistElement ? { backgroundColor: '#f1f1f1', textTransform: 'uppercase' } : {}
                    return (<Cell key={column.field + index} {...rest} style={{ ...style, ...headerStyle }}>
                      {!isFistElement && label && <Header style={{ textAlign: 'left' }}><Text>{label}</Text></Header>}
                      <CustomComponent colDef={column} value={record[ column.field ]} data={record}/>
                    </Cell>)

                  } else {
                    return (
                      <Cell key={column.field + index} {...rest} style={style}>
                        {label && <Header style={{ textAlign: 'left' }}><Text>{label}</Text></Header>}
                        {label ?
                          <Value><Text>{value}</Text></Value> :
                          <FullWidthValue><Text>{value}</Text></FullWidthValue>}
                      </Cell>
                    );
                  }
                })}
              </Row>
            ))}
          {data.length >= gridSize &&
          <LoadMoreContainer>
            <Button onClick={() => this.loadMore()}>Load More</Button>
          </LoadMoreContainer>
          }
        </div>
      </React.Fragment>
    );
  }
}

export { ResponsiveDynamicTable };

export default register('rsv8-metrics/ResponsiveDynamicTable')(
  withTheme(),
  withContent()
)(ResponsiveDynamicTable);
