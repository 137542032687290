import * as React from 'react';
import { connect } from 'react-redux';
import { Icon, PanelBody } from 'rsv8-components';
import styled from 'styled-components';
import { nomineeSelectors } from '../../redux/index';
import { NomineeImage } from '../NomineeImage/index';

const StyledNomineeImage = styled(NomineeImage)`
  flex-grow: 1;
` as any;
const MyBody = styled(PanelBody)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .fa {
    justify-self: flex-end;
  }
`;

export interface NomineeItemProps {
  id: string;
  loading: boolean;
  onClick: (e: any) => void;
}

interface InternalProps extends NomineeItemProps {
  nominee: any;
}

const CurrentTeamMember: React.SFC<InternalProps> = ({ id, nominee, onClick, loading }) => {
  return (
    <MyBody>
      <StyledNomineeImage width="45px" nominee={nominee} caption={true} orientation="right" />
      <Icon icon="trash" onClick={onClick} />
    </MyBody>
  );
};

const mapState = (state, ownProps) => ({ nominee: nomineeSelectors.selectOne(state, ownProps.id) });

export default connect(
  mapState,
  null
)(CurrentTeamMember) as React.ComponentType<NomineeItemProps>;
