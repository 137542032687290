export type ValidationStateParams = {
  touched: boolean;
  error: undefined | string;
  warning: undefined | string;
  active: boolean;
};

export const getValidationState = ({ touched, error, warning, active }: ValidationStateParams) => {
  // console.log(error, warning, active);
  if (!touched) {
    return null;
  }
  if (error !== undefined && error !== '') {
    return 'error';
  } else if (warning !== undefined && warning !== '') {
    return 'warning';
  } else {
    return null;
  }
};
