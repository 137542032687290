import * as React from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';
import styled from 'styled-components';

const OptionsDropdown = styled.div`
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
` as any;

const OptionsFormGroup = styled(FormGroup)`
  margin-bottom: 0;
` as any;

const OptionsFormControl = styled(FormControl)`` as any;

class CartOptions extends React.Component<any, any> {
  render() {
    const { changeOption, product } = this.props;

    return (
      <OptionsDropdown>
        <OptionsFormGroup controlId="formControlsSelect">
          <OptionsFormControl
            componentClass="select"
            defaultValue={product.productOptionId}
            onChange={(e) => {
              changeOption(e, product.id);
            }}
            placeholder="select"
          >
            {product.productOptionItems.map((option) => (
              <option key={option.id} value={option.id}>
                {option.selection}
              </option>
            ))}
          </OptionsFormControl>
        </OptionsFormGroup>
      </OptionsDropdown>
    );
  }
}

export default CartOptions;
