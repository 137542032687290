import { equals } from 'ramda';
import { nomineeSearchSelectors as selectors } from '../../selectors/index';
import { createSearch } from './miscActions';
import nomineeSearch from './nomineeSearch';

export const nomineeFilter = (values) => (dispatch, getState) => {
  const lastSearch = createSearch(selectors.getLatestNomineeSearch(getState()));
  const quickConvert = {
    ...values,
    query: lastSearch.query,
    sort: lastSearch.sort,
    customData1: values.customdata1 || values.customData1,
    customData2: values.customdata2 || values.customData2,
    customData3: values.customdata3 || values.customData3
  };
  const addedSearch = createSearch(quickConvert);
  if (!equals(lastSearch, addedSearch)) {
    dispatch(nomineeSearch(addedSearch, { clearFilters: false }));
  }
};

export const nomineeReset = () => (dispatch, getState) => {
  const lastSearch = createSearch(selectors.getLatestNomineeSearch(getState()));
  const { organization, repType, customData1, customData2, customData3, region, ...rest } = lastSearch;
  dispatch(nomineeSearch(createSearch(rest), { clearFilters: true }));
};
