export const SET_PARENT_ASSET_ROUTE_SUCCESS = 'rsv8-assets-admin/SET_PARENT_ASSET_ROUTE_SUCCESS';
export const SET_PARENT_ASSET_ROUTE_FAILURE = 'rsv8-assets-admin/SET_PARENT_ASSET_ROUTE_FAILURE';
export const SET_PARENT_ASSET_ROUTE_REQUEST = 'rsv8-assets-admin/SET_PARENT_ASSET_ROUTE_REQUEST';

export const SET_EDITING_ROUTE_SUCCESS = 'rsv8-assets-admin/SET_EDITING_ROUTE_SUCCESS';
export const SET_EDITING_ROUTE_FAILURE = 'rsv8-assets-admin/SET_EDITING_ROUTE_SUCCESS';
export const SET_EDITING_ROUTE_REQUEST = 'rsv8-assets-admin/SET_EDITING_ROUTE_SUCCESS';

export const SET_ACTIVE_MODAL_SUCCESS = 'rsv8-assets-admin/SET_ACTIVE_MODAL_SUCCESS';
export const SET_ACTIVE_MODAL_FAILURE = 'rsv8-assets-admin/SET_ACTIVE_MODAL_FAILURE';
export const SET_ACTIVE_MODAL_REQUEST = 'rsv8-assets-admin/SET_ACTIVE_MODAL_REQUEST';
