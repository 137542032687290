import * as React from 'react';
import styled from 'styled-components';
import { register, withContent, withTheme } from 'xcel-react-core';
import CardBody from './CardBody';

export type Props = {
  imageSide: string;
  backgroundImage: string;
};

const ImageCardWrap: any = styled.div`
  display: flex;
  position: relative;
  margin: 0;
  min-width: 150px;
  flex-direction: ${(props: Props) => (props.imageSide === 'left' ? `row` : 'row-reverse')};
  @media screen and (max-width: 767px) {
    display: block;
    flex-direction: inherit;
  }
` as any;

const ImageContainer = styled.div`
  background-position: center center;
  background-image: ${(props: Props) => (props.backgroundImage ? `url(${props.backgroundImage})` : '')};
  margin: 0;
  position: relative;
  width: ${(props: Props) => (props.backgroundImage ? `40%` : '0')};
  @media screen and (max-width: 767px) {
    height: 325px;
    width: 100%;
  }
` as any;

const ChildContainer = styled.div`
  width: ${(props: Props) => (props.backgroundImage ? `60%` : '100%')};
  @media screen and (max-width: 767px) {
    width: 100%;
  }
` as any;

const TextCard: React.SFC<Props> = ({ backgroundImage, children, ...rest }) => {
  return (
    <ImageCardWrap {...rest}>
      <ImageContainer backgroundImage={backgroundImage} />
      <ChildContainer>
        <CardBody>{children}</CardBody>
      </ChildContainer>
    </ImageCardWrap>
  );
};

const mapContentToProps = (getContent) => ({
  imageSide: getContent('imageSide', { type: 'none' }),
  backgroundImage: getContent('backgroundImage', { type: 'none' })
});
export default register('rsv8-components/TextCard')(withContent(mapContentToProps), withTheme())(TextCard);
export { TextCard };
