import { authObserver } from 'xcel-observer';
/**
 * List of queued actions that have transpired since auth has occured
 */
let queuedActions = [];
let authChangeHandlers = [];
/**
 * This is the currently assigned token
 */
let auth = undefined;

authObserver.subscribe((authData) => {
  if (auth !== undefined && (auth === authData || auth.accessToken === authData.accessToken)) return;
  auth = authData;

  if (auth !== undefined && auth.isValid === true && auth.accessToken !== undefined) {
    console.log(
      'setAuth',
      `has been executed (isAnonymous:${auth.isAnonymous}), delayed execution of (${
        queuedActions.length
      }) actions have been executed.`
    );
    // First we must uppdate all the auth handlers
    authChangeHandlers.forEach((handler) => handler(auth));
    // Then we call all the queued actions if any.
    queuedActions.forEach((action) => action());
    queuedActions = [];
  }
});

// we must queue these requests till the auth has been resolved.
const createAction = ({ provider, onAuthChange, props, originalAction }) => () => {
  return originalAction(...props);
};
/**
 *
 * @param {Object} properties {provider, originalAction, onAuthChange, include, exlude}
 */
const createDelayedAction = ({ provider, originalAction, onAuthChange, include, exclude }) => (...props) => {
  let action = createAction({ provider, onAuthChange, props, originalAction });
  if (auth === undefined || !auth.isValid) {
    return new Promise((resolve, reject) => {
      try {
        // in order for the results to be queued they need to be awaitable (promise)
        queuedActions.push(() => {
          let response = action();
          // if we are given an awaitable response then we can resolve it.
          if (response.then) {
            response.then(resolve, reject);
          }
        });
      } catch (e) {
        throw e;
      }
    });
  } else {
    return action();
  }
};
/**
 * Will remap all functions to delay execution till authentication
 * has been created and initialized
 * white/black list any functions you don't want to delay execution of
 * @param {Any} provider any object that has functions which require authentication
 * @param {Object} options handle event onAuthChange, add white list ({Array} include) or add black list ({Array} exclude)
 */
export const withAuth: any = (provider, { onAuthChange, include, exclude }) => {
  if (typeof onAuthChange === 'function') {
    authChangeHandlers.push(onAuthChange);
  }
  if (auth !== undefined) {
    onAuthChange({ provider, auth });
  }
  for (let key in provider) {
    if (typeof provider[key] === 'function') {
      if (include !== undefined && include.indexOf(key) === -1) continue;
      if (exclude !== undefined && exclude.indexOf(key) > -1) continue;
      const originalAction = provider[key];
      provider[key] = createDelayedAction({ provider, originalAction, onAuthChange, include, exclude });
    }
  }
  return provider;
};
