import * as React from 'react';
import { accountStyles as styles, Table, TableCell, TableHeaderCell, TableRow } from 'rsv8-components';
import styled from 'styled-components';
import { getCurrencyValue } from '../../../utils';

const DesktopTableContainer = styled(styles.DesktopTableContainer)`
  th, td {
    text-align: left !important;
  }
`;

interface DesktopTableProps {
  data: any;
  onSearch?: any;
  onSort?: any;
}

const DesktopTable = ({
  data,
  onSearch,
  onSort,
  ...rest
}: DesktopTableProps) => {

  function renderRows(items: any = []) {
    return items.map((item, index) => (
      <TableRow key={index} style={{ height: '60px' }}>
        <TableCell>{item.quarter}</TableCell>
        <TableCell>{item.amount === 0 ? '-' : getCurrencyValue(item.amount)}</TableCell>
      </TableRow>
    ));
  }

  return (
    <DesktopTableContainer>
      {data !== undefined && data.length > 0 ? (
        <Table {...rest} striped={false} bordered={false} hover={true} condensed={true} onSort={onSort}>
          <thead>
            <TableRow>
              <TableHeaderCell field="quarter">Quarter</TableHeaderCell>
              <TableHeaderCell field="rebateAmount">Rebate Amount</TableHeaderCell>
            </TableRow>
          </thead>
          <tbody>{renderRows(data)}</tbody>
        </Table>
      ) : <styles.NoDataContainer>No Data Available</styles.NoDataContainer>}
    </DesktopTableContainer>
  );
};

export default DesktopTable;