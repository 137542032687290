import { themePlugin } from '../../config';
import {
  Dictionary,
  ThemeColor,
  ThemeExample,
  ThemeFont,
  ThemeStyle
  } from '../types';
import { getTheme } from './theme.selectors';

export default {
  getColors: (state): Dictionary<ThemeColor> => themePlugin.selector.getProperties(state).colors || {},
  getFonts: (state): Dictionary<ThemeFont> => themePlugin.selector.getProperties(state).fonts || {},
  getExamples: (state): Dictionary<Array<ThemeExample>> => themePlugin.selector.getProperties(state).examples || [],
  getTheme: (state): Dictionary<ThemeStyle> => themePlugin.selector.getProperties(state).theme || {},
  getProcessed: (state) => getTheme(state).processed
};
