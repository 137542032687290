import * as React from 'react';
import FormJsonBuilder from './FormJsonBuilder';

const FormWrapper = (props) => {
  const { fields, ...rest } = props.json;
  const formName = rest.form || rest.name || rest.id;
  return <FormJsonBuilder form={formName} json={props.json} {...rest} {...props} />;
};

export default FormWrapper;
