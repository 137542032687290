import * as download from "downloadjs";

export function saveFile(url: string): void {
  // PATCH: downloadjs forces application/octet-stream if Safari
  // https://github.com/rndme/download/blob/master/download.js#L28,L55
  // https://discussions.apple.com/thread/3697948
  // if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
  // window.open(url);
  // } else {

  /* WORKAROUND
   If we're currently in secure context (HTTPS) rewrite URL for resource
   so no HTTP resources are requested in secure context. Otherwise such
   requests are forcefully blocked by browser.
   Better solution is to rewrite references in database and always return
   secure URL of assets.
  */
  let fixedUrl = url;
  const isHTTPS = window.location.protocol === "https:";

  if (isHTTPS) {
    fixedUrl = url.replace(/^http:\/\//i, "https://");
  }

  // use set timeout to make it work in IE
  setTimeout(() => {
    download(fixedUrl);
  }, 0);
  // }
}

export function getMimeTypeByFileExt(url: string): string | undefined {
  const MIME_TYPES = {
    png: "image/png",
    jpeg: "image/jpeg",
    jpg: "image/jpeg",
    gif: "image/gif",
    svg: "image/svg+xml",
    tiff: "image/tiff",
    webp: "image/webp"
  };

  const urlParts = url.split(".");

  return MIME_TYPES[urlParts[urlParts.length - 1]];
}

export function getImgFilename(url: string) {
  const parsed = url.split("/");
  return parsed[parsed.length - 1] || url;
}
