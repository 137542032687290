import * as React from 'react';
import {
  Alert,
  Col,
  Grid,
  Row
  } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PageRenderer } from 'rsv8-cms';
import { Context, withWizard } from 'rsv8-wizard';
import styled from 'styled-components';
import { register, withResource } from 'xcel-react-core';
import { TeamAwardInstructions as AwardInstructions } from '../../components/index';
import NominationCollapse from '../../components/NominationCollapse/index';
import { ErrorFormContainer } from '../../components/SearchBar/SearchBar';
import { nominationActions } from '../../redux/actions/getApplication';
import { teamNominationActions } from '../../redux/actions/index';
import { teamNominationSelectors } from '../../redux/selectors/index';
import { Resources } from '../../types';
import AwardSelection from './AwardSelection';

const { selectAward, getAwardDetails } = teamNominationActions;

const StyledGrid = styled(Grid)`
  &.hide {
    display: none;
  }
` as any;

const AwardWrapper = styled.div`
  padding-top: 50px;
` as any;
interface InternalProps {
  awards: Array<Resources.Award>;
  wizard: Context;
  currentAward: string;
  errorText: any;
  buttonTitle: string;
  actions: {
    selectAward: (awardId: string | number, confirmText: string) => void;
    getAwardDetails: (awardId: string, nominee: number) => void;
    getNominationApplication: any;
  };
  hideAwardDetails: string;
}

interface AwardPageState {
  hasError: boolean;
  showPage: boolean;
}

class AwardPage extends React.Component<InternalProps, AwardPageState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, showPage: false };
  }

  async componentWillMount() {
    await this.props.actions.getNominationApplication({});
    if (this.props.awards.length === 1) {
      this.skipAwardSection(this.props.awards[0].id);
    } else {
      this.setState({ showPage: true });
    }
  }

  componentDidMount() {
    if (this.props.wizard) {
      this.props.wizard.registerIncrement(this.checkValid);
    }
  }

  skipAwardSection = async (value) => {
    await this.forceSelection(value);
    if (this.props.wizard) {
      this.props.wizard.removeWizardStep();
      this.props.wizard.increment();
    }
  };

  forceSelection = (value) => {
    this.props.actions.getAwardDetails(value, null);
    this.props.actions.selectAward(value, null);
  };

  checkValid = () => {
    const { currentAward } = this.props;
    if (currentAward !== undefined && currentAward !== null && currentAward !== '') {
      this.setState({ hasError: false });
      return Promise.resolve();
    } else {
      this.setState({ hasError: true });
      return Promise.reject('Please select an award.');
    }
  };

  render() {
    return (
      <StyledGrid className={!this.state.showPage && 'hide'}>
        <Row>
          <Col xs={24} lg={20} lgOffset={2}>
            <AwardInstructions />
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            {this.state.hasError && !this.props.currentAward ? (
              <ErrorFormContainer>
                <Alert bsStyle="danger">{this.props.errorText}</Alert>
              </ErrorFormContainer>
            ) : null}
            <AwardWrapper>
              <AwardSelection awards={this.props.awards} />
              {this.props.hideAwardDetails === 'false' && (
                <NominationCollapse title={this.props.buttonTitle}>
                  <PageRenderer path={`partial/nomination/team/award-approval-details`} />
                </NominationCollapse>
              )}
            </AwardWrapper>
          </Col>
        </Row>
      </StyledGrid>
    );
  }
}

const mapState = (state) => {
  const awards = teamNominationSelectors.getAwards(state);
  return {
    currentAward: teamNominationSelectors.getCurrentAwardId(state),
    awards: !Array.isArray(awards) ? [awards] : awards
  };
};

const mapDispatch = (dispatch) => ({
  actions: bindActionCreators(
    { selectAward, getAwardDetails, getNominationApplication: nominationActions.getApplication },
    dispatch
  )
});

const mapResource = (getResource) => ({
  errorText: getResource('teamNomination.selectAwardError', 'Please Select an Award'),
  buttonTitle: getResource('nominationProcess.awardDetailsButtonTitle', 'Award Approval Details'),
  hideAwardDetails: getResource('teamNomination.hideAwardDetails', 'false')
});

const compose = register('rsv8-nomination/TeamSelectAward');

export default compose(
  connect(
    mapState,
    mapDispatch
  ),
  withResource(mapResource),
  withWizard()
)(AwardPage) as React.ComponentClass<{}>;
