import styled from 'styled-components';

const SubNavStyle = styled.div`
  .nav-title {
    font-size: 30px;
    color: #4c4f4f;
    font-weight: bold;
    margin-top: 23px;
    margin-bottom: 23px;
  }
  .navbar {
    border-radius: 0;
    margin-bottom: 0;
    background: #f7f7f7;
    min-height: auto;
    border: 0;
    border-bottom: 1px solid #e7e7e7;
    @media screen and (max-width: 767px) {
      padding-left: 15px;
      padding-right: 15px;
    }
    .nav {
      white-space: nowrap;
      overflow-y: hidden;
      overflow-x: auto;
      max-width: 100%;
      width: 100%;
      @media screen and (max-width: 767px) {
        margin-top: 0;
        margin-bottom: 0;
      }
      > li {
        border-bottom: 4px solid transparent;
        margin: 0 15px;
        display: inline-block;
        float: none;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
        position: static;
        &:hover {
          border-bottom: 4px solid #b5be35;
        }
        &:first-child {
          margin-left: 0;
        }
        &.open {
          a {
            background-color: transparent;
          }
        }
        a {
          color: #606060;
          font-size: 14px;
          padding-left: 0;
          padding-right: 0;
          @media screen and (max-width: 767px) {
            padding-top: 15px;
            padding-bottom: 15px;
          }
        }
        &.nav-link-selected {
          border-bottom: 4px solid #b5be35;
        }

        > .dropdown-menu {
          background-color: #ffffff;
          border: 0;
          border-radius: 0;
          left: auto;
          top: auto;
          padding-bottom: 0;
          position: absolute;
          -webkit-box-shadow: 0px 0px 21px -1px rgba(28, 39, 76, 0.13);
          -moz-box-shadow: 0px 0px 21px -1px rgba(28, 39, 76, 0.13);
          box-shadow: 0px 0px 21px -1px rgba(28, 39, 76, 0.13);
          > li {
            display: block;
            position: static;
            border-bottom: 1px solid #ededed;
            &:hover {
              background-color: #f5f5f5;
            }
            a {
              padding: 16px 55px 16px 35px;
              position: relative;
              .caret {
                top: 7px;
                -webkit-transform: translateY(-50%) rotate(-90deg);
                -ms-transform: translateY(-50%) rotate(-90deg);
                transform: translateY(-50%) rotate(-90deg);
                position: absolute;
                right: 21px;
                top: 50%;
              }
            }
            .dropdown-menu {
              border-radius: 0;
              height: auto;
              min-height: 100%;
              top: 0;
              left: 100%;
              padding: 0;
              margin-top: 0;
              padding-top: 15px;
              li {
                border-bottom: none;
                margin: 0;
                padding: 0;
                position: static;
                display: block;
                &:hover {
                  background-color: #f5f5f5;
                }
                a {
                  padding: 8px 35px;
                  position: relative;
                }
                .caret {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
` as any;

export default SubNavStyle;
