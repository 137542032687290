import styled from 'styled-components';
const Particle = styled.div`
  opacity: 0;
  position: absolute;
  background-image: url(${(props: any) => props.image});
  background-size: ${(props: any) => `${props.size - 2}px ${props.size - 2}px;`};
  background-repeat: no-repeat;
  animation: ${(props: any) => props.animation} ${(props: any) => props.duration}s ease-in 2;
  height: ${(props: any) => props.size}px;
  width: ${(props: any) => props.size}px;
  top: ${(props: any) => props.top + '%'};
  left: ${(props: any) => props.left + '%'};
  animation-delay: ${(props: any) => props.delay}s;
` as any;

export default Particle;
