import { Resource } from 'xcel-redux-orm';
import { contentSelector, contentVersionSelector } from '../../redux/selectors';
import { ContentVersionModel } from '../../redux/selectors/contentVersion';
const singleContentSelector = (contentTypeId, contentKey) => ({
  getProperties: (state: any) => contentSelector.getEditContentProperties(state, contentTypeId),
  getRaw: (state: any) => contentSelector.getRaw(state, contentTypeId),
  getEdit: (state: any) => contentSelector.getEdit(state, contentTypeId),
  getActiveVersion: (state: any) => contentVersionSelector.getActiveVersion(state, contentTypeId)
});
export { ContentVersionModel, Resource };

export default singleContentSelector;
