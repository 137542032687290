import { BaseHTMLAttributes, ComponentType } from 'react';
import * as Icon from 'react-fontawesome';
import { pure, withProps } from 'recompose';
import { register, withContent, withTheme } from 'xcel-react-core';

export interface IconOptions {
  spin: boolean;
  pulse: boolean;
  fixedWidth: boolean;
  border: boolean;
  // listItem: boolean;
  flip: 'vertical' | 'horizontal' | 'both';
  size: string;
  rotation: number;
  className: string;
  tag: 'i' | 'em' | 'span' | 'a';
  ariaLabel: string;
  // style: object;
  // pull: 'right' | 'left';
  // transform: any;
  // mask: Array<any>;
  // symbol: string | boolean;
  // inverse: boolean;
}

export interface IconProps extends Partial<IconOptions>, BaseHTMLAttributes<any> {
  icon: string;
}
const mapContentToProps = (getContent) => ({ icon: getContent('icon', { type: 'none', label: 'Icon' }) });
const mapProps = (props) => ({ name: props.icon });

export default register('rsv8-components/Icon')(pure, withTheme(), withContent(mapContentToProps), withProps(mapProps))(
  Icon as any
) as ComponentType<IconProps>;
