import * as React from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';
import { Badge } from 'xcel-api-generator/dist/alcon';
import { assetService } from 'xcel-asset-service';
import { register, withContent, withTheme } from "xcel-react-core";
import { withApiDataProvider } from "xcel-redux-orm";
import { getSummary } from "../../redux/actions";
import { badgeSelector, dashboardSelector } from "../../redux/selectors";
import { OverlayPopover } from '../Overlay';

interface Props extends React.HTMLProps<any> {
  badgeKey: string;
  overlayPlacement: string;
  usePlaceholder: boolean;
  badges: Badge[];
}

const WrapperContainer = styled.div`
  position: relative;

  .overlay-popover-icon {
    position: absolute;
    top: -6px !important;
    right: 8px !important;
    margin: 0;
  }
`;

const Badge: React.FC<Props> = ({
  badgeKey,
  overlayPlacement = "bottom",
  usePlaceholder,
  badges,
  className
}) => {
  const badge = badgeKey && badges.find(b => b.badgeKey === badgeKey);
  if (!badge) {
    return null;
  }

  const imageUrl = badge.achieved ? badge.achievedImage : badge.notAchievedImage;
  if (!imageUrl) {
    if (!usePlaceholder) return null;

    return (
      <WrapperContainer className={className}>
        <div className='placeholder' />
      </WrapperContainer>
    );
  }

  return (
    <WrapperContainer className={className}>
      <img src={assetService.getImage(`badges/${imageUrl}`)} alt={badge.badgeName} />

      {badge.badgeMessage && (
        <OverlayPopover id="popover-badge-message" placement={overlayPlacement}>
          {badge.badgeMessage}
        </OverlayPopover>
      )}
    </WrapperContainer>
  );
}

const mapContentToProps = (getContent) => ({
  badgeKey: getContent("badgeKey", { type: "string", label: "Badge Key" }),
  overlayPlacement: getContent("overlayPlacement", { type: "string", label: "Overlay Placement" }),
  usePlaceholder: getContent("usePlaceholder", { type: "boolean", label: "Show Placeholder on epmpty?" }),
});

const mapStateToProps = (state, ownProps) => ({
  badges: badgeSelector.selectMany(state),
  ownProps
});

export default register("rsv8-alcon/Badge")(
  connect(mapStateToProps),
  withApiDataProvider(getSummary, dashboardSelector),
  withContent(mapContentToProps),
  withTheme(),
)(Badge);