import { nomineeSearchSelectors as selectors } from '../../selectors/index';
import nomineeSearch from './nomineeSearch';

const nomineeSort = (field, direction) => (dispatch, getState) => {
  const lastSearch = selectors.getLatestNomineeSearch(getState());
  const newSearch = { ...lastSearch, sort: { field, direction }, page: lastSearch.page ? lastSearch.page : undefined };
  dispatch(nomineeSearch(newSearch));
};

export default nomineeSort;
