import * as React from 'react';
import {
  Button,
  Jumbotron,
  ListGroup,
  ListGroupItem
  } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createApplication, getApplication, updateApplication } from '../../redux/navigationMenuAdmin.actions';
import { AddApplicationForm, EditApplicationForm } from '../AdminForms';
import AdminModal from '../AdminModal';

interface ApplicationControlProps {
  actions: {
    deleteApplicationByAppKey: any;
    getApplication: any;
    updateApplication: any;
  };
  clientId: any;
  navigationMenuAdmin: any;
}

interface ApplicationControlState {
  treeData: any;
}

class ApplicationControl extends React.Component<ApplicationControlProps, ApplicationControlState> {
  constructor(props) {
    super(props);

    this.state = {
      treeData: [{}]
    };
  }

  toggleIsActive = (item) => {
    const { id, isActive } = item;
    let newActiveStatus;
    if (isActive) newActiveStatus = false;
    if (!isActive) newActiveStatus = true;
    let model = {
      isActive: newActiveStatus
    };

    this.props.actions.updateApplication(id, model).then(() => this.props.actions.getApplication(this.props.clientId));
  };

  render() {
    const { application } = this.props.navigationMenuAdmin;
    const sortedApplication = application.sort((a, b) => {
      return b.isActive - a.isActive;
    });

    return (
      <Jumbotron>
        <AdminModal title="Add Apllication" modaleTitle="Add Application" formName="AddApplication" {...this.props}>
          <AddApplicationForm {...this.props} />
        </AdminModal>
        <br />
        <br />
        <ListGroup>
          {sortedApplication &&
            sortedApplication.map((item, i) => (
              <ListGroupItem className={item.isActive ? '' : 'list-group-item-danger'} key={i}>
                <span> {item.name}</span>
                <br />
                <span>Default Application: {item.isDefault ? 'true' : 'false'}</span>
                <br />
                <span>Key: {item.id}</span>
                <br />

                <AdminModal title="Edit Apllication" modaleTitle="Edit Application" formName="EditApplicationForm">
                  <EditApplicationForm {...this.props} {...this.props} node={item} />
                </AdminModal>
                <Button
                  style={{ marginLeft: '15px' }}
                  className={item.isActive ? 'btn-success ' : 'btn-danger'}
                  onClick={() => this.toggleIsActive(item)}
                >
                  Toggle Status to <strong>{item.isActive ? 'Inactive' : 'Active'}</strong>
                </Button>
              </ListGroupItem>
            ))}
        </ListGroup>
      </Jumbotron>
    );
  }
}

const mapState = (state) => ({
  navigationMenuAdmin: state.navigationMenuAdmin
});
const mapDispatch = (dispatch) => ({
  actions: bindActionCreators(
    {
      getApplication,
      updateApplication,
      createApplication
    },
    dispatch
  )
});
export default connect(
  mapState,
  mapDispatch
)(ApplicationControl as any) as any;
