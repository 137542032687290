import * as React from 'react';
import { ComponentType } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Text } from 'rsv8-components';
import { DataTableColumn, DataTableDefault } from 'rsv8-components';
import { SORT_DIRECTIONS } from 'rsv8-components';
import { register, withContent, withResource } from 'xcel-react-core';
import { ApiDataProviderProps, withApiDataProvider } from 'xcel-redux-orm';
import { NominationStatusBlock } from '../../../components/index';
import { individualDraftSelectors, nominationActions, teamDraftSelectors } from '../../../redux/index';
import DraftIcon from './DraftIcon';

const renderDate = ({ record, column }) => new Date(record[column.field]).toLocaleDateString();
const renderStatus = ({ record, column }) => <NominationStatusBlock data={record} field={column.field} />;
const renderContextMenu = ({ record, isMobile }) => (
  <React.Fragment>
    <DraftIcon data={record} />
    {isMobile ? <Text themeVariation="nomination-name">{record.teamName}</Text> : null}
  </React.Fragment>
);
const defaultSort = { field: 'createDate', direction: SORT_DIRECTIONS.desc };
const TeamDraftTable: React.SFC<
  ApiDataProviderProps<ReturnType<typeof individualDraftSelectors.selectMany>> & ReturnType<typeof mapResource>
> = (props) => {
  const { columnLabels, emptyText } = props;

  return (
    <Row>
      <Col sm={24}>
        <DataTableDefault {...props} defaultSort={defaultSort} emptyIcon="icon-complete" emptyText={emptyText}>
          <DataTableColumn renderCell={renderContextMenu} width={'45px'} />
          <DataTableColumn field="teamName" className="hidden-xs">
            {columnLabels.teamName}
          </DataTableColumn>
          <DataTableColumn field="createDate" renderCell={renderDate}>
            {columnLabels.createDate}
          </DataTableColumn>
          <DataTableColumn field="status" renderCell={renderStatus}>
            {columnLabels.status}
          </DataTableColumn>
          <DataTableColumn field="applicationName">{columnLabels.applicationName}</DataTableColumn>
          <DataTableColumn field="awardType">{columnLabels.awardType}</DataTableColumn>
        </DataTableDefault>
      </Col>
    </Row>
  );
};

const mapResource = (getResource) => ({
  columnLabels: {
    teamName: getResource('nominationActivity.teamNameTableHeader', 'Team Name'),
    createDate: getResource('nominationActivity.dateCreatedTableHeader', 'Date'),
    status: getResource('nominationActivity.awardStatusTableHeader', 'Status'),
    applicationName: getResource('nominationActivity.applicationNameTableHeader', 'Award'),
    awardType: getResource('nominationActivity.awardTypeTableHeader', 'Type')
  },
  emptyText: getResource('teamDraftTable.emptyText', 'There are no drafts that need your attention')
});

export default register('rsv8-nomination/TeamNominationDraftsTable')(
  withContent(),
  withResource(mapResource),
  withApiDataProvider(nominationActions.getTeamDraft, teamDraftSelectors, { sort: defaultSort })
)(TeamDraftTable) as ComponentType<{}>;
