/**
 * Easing functions
 *
 * Based on Robert penner's ease function in order to make a smooth animation
 * `https://github.com/danro/jquery-easing/blob/master/jquery.easing.js`
 * The functions' schema is almost the same, they reuse these variables
 * begin - the initial value
 * finish - the final value
 * currentTime - current moment of the animation(based on intervals)
 * duration - how long take the animation
 */

import { interval } from '../animations'; 

// supported Easing function
// NOTE: if you added a new one, please, don't forget to add the type
export enum EaseType {
  LINEAR,
  QUAD_IN_OUT,
  CUBIC_IN_OUT,
  QUART_IN_OUT
};

// this easing function doesn't modify the value
// it's the basic one
export function linear(begin: number, finish: number, currentTime: number, duration: number) {
  return ((finish - begin) * currentTime / duration) +  begin;
}

export function quadInOut(begin: number, finish: number, currentTime: number, duration: number) {
  let result: number;
  let change = finish - begin;
  
  if((currentTime /= duration / 2) < 1) {
    result = (change / 2 * currentTime * currentTime);
  } else {
    result = -change / 2 * ((--currentTime) * (currentTime - 2) - 1);
  }
  return result + begin; 
}

export function cubicInOut(begin: number, finish: number, currentTime: number, duration: number) {
  let result: number;
  let change = finish - begin;
  
  if((currentTime /= duration / 2) < 1) {
    result = change / 2 * Math.pow(currentTime, 3);
  } else {
    result = change / 2 * (Math.pow(currentTime - 2, 3) + 2);
  }
  
  return result + begin;
}

export function quartInOut(begin: number, finish: number, currentTime: number, duration: number) {
  let result: number;
  let change = finish - begin;
  
  if((currentTime /= duration / 2) < 1) {
    result = change / 2 * Math.pow(currentTime, 4);
  } else {
    result = change / 2 * (Math.pow(currentTime - 2, 4) + 2);
  }
  
  return result + begin;
}

// animation wrapper
export function animate(
  initVal: number,
  endVal: number,
  duration: number,
  easeType: EaseType,
  callback: Function
) {
  let easeFn: Function;
  switch(easeType) {
    case EaseType.QUAD_IN_OUT:
      easeFn = quadInOut;
      break;
    case EaseType.CUBIC_IN_OUT:
      easeFn = cubicInOut;
      break;
    case EaseType.QUART_IN_OUT:
      easeFn = quartInOut;
      break;
    case EaseType.LINEAR:
    default:
      easeFn = linear;
      break;
  }
  
  interval(theta => {
    let nextValue = easeFn(initVal, endVal, theta, duration);
    callback(nextValue);
  }, duration, false);
}