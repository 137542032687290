import * as React from 'react';
import { FieldContainer } from '../../../../../components/Label';
import { BrandGroup, BrandGroupAlpha, BrandsCheckboxGroup } from '../../../BrandsModal/styles';
import { sortBrands } from '../../../util/sortBrands';

class BrandAlphaFilter extends React.Component<any, any> {
  state = {
    brandGroups: {}
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.brandOptions !== prevProps.brandOptions) {
      this.setState({ brandGroups: sortBrands(this.props.brandOptions) });
    }
  }

  render() {
    return (
      <React.Fragment>
        {Object.keys(this.state.brandGroups).map((brand) => (
          <BrandGroup key={brand}>
            <BrandGroupAlpha>{brand}</BrandGroupAlpha>
            <FieldContainer>
              <BrandsCheckboxGroup
                component={'checkbox-group'}
                name="brandFiltersModal"
                options={this.state.brandGroups[brand]}
                onChange={this.props.handleBrandChange}
                value={this.props.currentlyChecked}
              />
            </FieldContainer>
          </BrandGroup>
        ))}
      </React.Fragment>
    );
  }
}

export default BrandAlphaFilter;
