import { Row as BSRow } from 'react-bootstrap';
import { FormGroup } from 'react-bootstrap';
import styled from 'styled-components';

export const Row = styled(BSRow)`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const GcRow = styled(Row)`
  margin: 0;
  box-shadow: 0px 2px 17px 0px #ccc;
  -webkit-box-shadow: 0px 2px 17px 0px #ccc;
  -moz-box-shadow: 0px 2px 17px 0px #ccc;
  text-align: center;
` as any;

export const ProductFormGroup = styled(FormGroup)`
  margin: 10px auto;
  width: 60%;
` as any;

export const SpaceHolder = styled.div`
  height: 54px;
`;