import { authStateSelectors, userStateSelectors } from 'rsv8-auth';
import { promiseDispatcher } from 'xcel-react-core';
import { cmsApi } from '../../api';
import * as events from '../events';

const cmsContentGet = ({ name, path, cultureId, state }) =>
  promiseDispatcher(
    (params, options = {}) => {
      const user = userStateSelectors.getUser(state);
      const auth = authStateSelectors.getAuth(state);
      if ((!auth || auth.isOAuth || !auth.isValid) && user.clientId) {
        return cmsApi.getContentsPublic(
          { ...params, clientId: user.clientId },
          { ...options, forceCultureId: cultureId }
        );
      } else {
        return cmsApi.getContents(params, {
          ...options,
          forceCultureId: cultureId
        });
      }
    },
    {
      success: (payload) => {
        return {
          payload,
          cultureId,
          name,
          path,
          type: events.CONTENT_GET_SUCCESS
        };
      },
      failure: (payload) => {
        return {
          payload,
          cultureId,
          name,
          path,
          type: events.CONTENT_GET_FAILURE
        };
      },
      request: (payload) => {
        return {
          payload: payload,
          cultureId,
          name,
          path,
          type: events.CONTENT_GET_REQUEST
        };
      }
    }
  );

const cmsPageGet = ({ name, path, prefix = '', contentType = 'Page' }, cultureId) => (dispatch, getState) => {
  const state = getState();
  path = path;
  name = name || 'default';
  const params = { name, path, prefix, contentType, cultureId, state: state };
  return dispatch(cmsContentGet(params)(params));
};

export { cmsPageGet };
