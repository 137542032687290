import { UserProfileModel } from 'xcel-api-generator/dist/user';
import { build, selectOne } from 'xcel-redux-orm';
import { createTypeSelector } from 'xcel-redux-orm';
import { AUTH_USER_STATE, USER_PROFILE_JSON_TYPE } from '../../constants';

// MISC - REDUX

export const getCurrentAccountSummary = (state) => selectOne(state, 'summary', state[AUTH_USER_STATE].userId);

export const getRouting = (state) => state.routing;

// USER - ORM
export const getUserProfile = (state) => build(state, USER_PROFILE_JSON_TYPE, state[AUTH_USER_STATE].userId);

export const userSelector = createTypeSelector<UserProfileModel>(USER_PROFILE_JSON_TYPE);
export const getLastUser = (state) => userSelector.selectLast(state, 'getUser')[0];
