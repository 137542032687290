export default (clientId) => {
  let scopedStyles: any = { styles: ['/css/bootstrap.min.css'] };
  if (clientId === 100102) {
    scopedStyles = {
      url: 'http://xcel-rf.s3.amazonaws.com/qa/clientassets/randf/css/',
      style: [
        'http://xcel-rf.s3.amazonaws.com/qa/clientassets/randf/css/bootstrap.min.css',
        'http://xcel-rf.s3.amazonaws.com/qa/clientassets/randf/css/clarity.css',
        'http://xcel-rf.s3.amazonaws.com/qa/clientassets/randf/css/extras.css',
        'http://xcel-rf.s3.amazonaws.com/qa/clientassets/randf/css/MyFontsWebfontsKit.css',
        'http://xcel-rf.s3.amazonaws.com/qa/static/css/main.de3601d2.css'
      ]
    };
  }
  return scopedStyles;
};
