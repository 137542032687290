import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import styled from 'styled-components';

const StyledDiv = styled.div`
  display: inline-block;
  padding-left: 5px;
`;

const StyledModal = styled(Modal)`
  .modal-dialog {
    width: ${(props) => (props.size ? props.size : '50%')};
  }
`;
interface AdminModalProps {
  modaleTitle?: string;
  icon?: any;
  color?: any;
  show: boolean;
  handleClick?: any;
  handleHide?: any;
  onClick?: any;
  size?: any;
}

class AssetAdminModal extends React.Component<AdminModalProps> {
  render() {
    return (
      <StyledDiv>
        <span onClick={this.props.handleClick}>
          <i className={this.props.icon} style={{ color: this.props.color }} />
        </span>

        <StyledModal size={this.props.size} show={this.props.show} onHide={this.props.handleHide}>
          <Modal.Header closeButton={true}>
            <Modal.Title>{this.props.modaleTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.props.children}</Modal.Body>
        </StyledModal>
      </StyledDiv>
    );
  }
}

const mapStateToProps = (state) => {
  let reduxFormSlice = 'rsv8-forms/form';
  let formSelector = state[reduxFormSlice];
  return formSelector;
};

export default connect(mapStateToProps)(AssetAdminModal);
