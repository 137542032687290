import * as React from 'react';
import styled from 'styled-components';
import { getValue } from 'xcel-util';
import { getThemeComponents } from '../../../../config';

const TreeBranch = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;

  ${(props: any) => {
    return (
      !props.isRoot &&
      `
      padding-left: 1em;
      li:last-child ul {
        border-left: 4px solid white;
        margin-left: -15px;
      }
    `
    );
  }};
  ${(props: any) => {
    return props.isRoot && `margin-top:-1em;`;
  }};
` as any;
const TreeLeaf = styled.li`
  padding: 0;
  margin: 0;
  list-style: none;
  cursor: pointer;
  ${(props: any) => {
    return (
      !props.isRoot &&
      `
      padding-left: 1em;
      border: 1px dotted black;
      border-width: 0 0 1px 1px;
    `
    );
  }} ${(props: any) => {
    return props.hasChildren && 'border-bottom: 0px;';
  }};
  p {
    margin: 0;
    background: white;
    position: relative;
    top: 0.9em;
  }
  ${(props: any) => {
    return (
      !props.isRoot &&
      `
    ul {
      border-top: 1px dotted black;
      margin-left: -1em;
      padding-left: 2em;
    }
    `
    );
  }};
  ${(props: any) => {
    return (
      props.isActive && `> p{background-color:black; color:white;border-radius:5px;margin-left:-10px;padding:0px 10px;}`
    );
  }};
` as any;
const Icon = styled.i`
  position: relative;
  font-size: 16px;
  margin-top: 1px;
` as any;
const TreeNode = ({ data, isRoot, active, activeProperty, onClick, ...rest }: any) => {
  const hasChildren = data && data.children && data.children.length > 0;
  const handleClick = (node) => (e) => {
    e.stopPropagation();
    if (onClick) {
      onClick(node);
    }
  };
  const isActive = data[activeProperty] === active;
  return (
    <TreeBranch isRoot={isRoot}>
      <TreeLeaf isRoot={isRoot} hasChildren={hasChildren} onClick={handleClick(data)} isActive={isActive}>
        <p>
          <Icon className={hasChildren ? 'ion-code-working' : 'ion-code'} />
          {data.name}
        </p>
        {hasChildren &&
          data.children.map((child, index) => {
            return (
              <TreeNode key={index} data={child} onClick={onClick} active={active} activeProperty={activeProperty} />
            );
          })}
      </TreeLeaf>
    </TreeBranch>
  );
};
const Tree = (props) => {
  return <TreeNode {...props} isRoot={true} />;
};

const ComponentStyleSelect = ({ name, selectedStyle, onChange }) => {
  const themeComponents = getThemeComponents();
  const themeComponent = getValue(themeComponents, name);
  const label = name && name.replace(/.+\./, '');
  if (!themeComponent) return null;
  const data = {
    name: label,
    namespace: name,
    children: themeComponent.children
      ? themeComponent.children.map((child) => ({
          name: child.replace(/.+\//, ''),
          namespace: `${name}=>${child.replace('/', '.')}`
        }))
      : [],
    active: true
  };
  const handleChange = (node) => {
    if (onChange) {
      onChange(node.namespace);
    }
  };
  return (
    <div className="form-group">
      <Tree data={data} onClick={handleChange} active={selectedStyle} activeProperty={'namespace'} />
    </div>
  );
};
export default ComponentStyleSelect;
