import { destroyRequest } from 'xcel-redux-orm';
import * as events from '../../events';

import { achievementApiActions } from './achievementApiActions';
import {
  GET_ACHEIVEMENT_PROGRAMS,
  GET_ACHEIVEMENT_PROGRAM_LEVELS,
  ACHIEVEMENT_STATE,
  ROOT_STATE
} from '../../../constants';
import { getRedemptionOrder } from '../../selectors/achievementSelectors';

export const getAchievementPrograms = (params: { returnLevels?: boolean }) => async (dispatch) => {
  dispatch(destroyRequest(GET_ACHEIVEMENT_PROGRAMS));
  await dispatch(achievementApiActions.getAchievementPrograms(params, { jsonApi: true }));
};

export const getAchievementProgramsByIdLevel = (params: { id: string, returnProducts?: boolean }) => async (dispatch) => {
  dispatch(destroyRequest(GET_ACHEIVEMENT_PROGRAM_LEVELS));
  await dispatch(achievementApiActions.getAchievementProgramsByIdLevel(params, { jsonApi: true }));
};

export const setOrderInfo = (info) => ({
  type: events.SET_ORDER_INFO,
  payload: info
});

export const setSharePreview = (info) => ({
  type: events.SET_SHARE_PREVIEW,
  payload: info
});

export const redeemAchievement = (info) => async (dispatch, getState) => {
  try {
    const root = getState()[ROOT_STATE];
    const { programId, programLevelId, id } = root[ACHIEVEMENT_STATE].product;
    const { achievementId } = root[ACHIEVEMENT_STATE].programLevel;
    const body = { achievementId, programId, programLevelId, programLevelProductId: Number(id), ...info };
    const res = await dispatch(achievementApiActions.createRedemptionsRedeemProduct({ body }, { jsonApi: true }));
    // ToDo: replace with state selector
    const order = res.included[0].attributes;
    await dispatch(setOrderInfo(order));

    const [sharePreview] = await dispatch(achievementApiActions.getRedemptionsShowPreviewsById({ id: achievementId }, { jsonApi: true }));
    return dispatch(setSharePreview(sharePreview));
  } catch(e) {
    console.error('ERROR: ', e);
  }
};

export const getAchievementRedemption = (id: number) => async (dispatch, getState) => {
  try {
    await dispatch(achievementApiActions.getRedemptionsGetByIdById({id}, { jsonApi: true }));
    const [order] = getRedemptionOrder(getState());
    return dispatch(setOrderInfo(order));
  } catch(e) {
    console.error('ERROR: ', e);
  }
};

export const shareRedemption = (achievementId: number) => async (dispatch, getState) => {
  const { sharePreview } = getState()[ROOT_STATE][ACHIEVEMENT_STATE];
  if (sharePreview) {
    const { previewHtml, ...rest } = sharePreview;
    const body = { ...rest, achievementId };
    await dispatch(achievementApiActions.createRedemptionsShareRedemption({body}, { jsonApi: true }));
    return true;
  }
  return false;
};
