import { combineReducers } from 'redux';
import {
  CART_STATE,
  FAVORITE_STATE,
  ORDER_STATE,
  ROOT_STATE,
  SEARCH_STATE,
  TRENDING_STATE
  } from '../constants';
import * as actions from './actions';
import { cartReducer } from './reducers/cart/cart';
import { checkoutReducer } from './reducers/order/checkout';
import { favoritesReducer, favoriteUndoReducer } from './reducers/product/favorites';
import setCurrentProductsSearchReducer from './reducers/product/search';
import { trendingProductsReducer, trendingReducer } from './reducers/product/trending';
import { catalogSelectors } from './selectors';

const reducers = {[ROOT_STATE]: 
  combineReducers({
    [FAVORITE_STATE]: combineReducers({
      favoriteProducts: favoritesReducer,
      favoriteUndo: favoriteUndoReducer
    }),
    [TRENDING_STATE]: combineReducers({
      trendingProducts: trendingReducer,
      trendingProductsDetails: trendingProductsReducer
    }),
    [SEARCH_STATE]: setCurrentProductsSearchReducer,
    [CART_STATE]: cartReducer,
    [ORDER_STATE]: checkoutReducer
  })
};

export { actions, catalogSelectors, reducers };
