import { cacheService } from 'xcel-cache-service';
import { reduxObserver } from 'xcel-observer';
import { cachePlugin, createReduxModule } from 'xcel-redux-module';
import * as reducers from './app/redux';
//import { navigationAdminService } from './config';
//import { authEvents } from 'rsv8-auth';

const navigationModule = cachePlugin(cacheService)(
  createReduxModule({
    name: 'Navigation',
    reducers
  })
) as any;

reduxObserver.addModule(navigationModule);
export { navigationModule };
