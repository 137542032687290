import * as React from 'react';
import { HelpBlock } from 'react-bootstrap';
import { getValidationState } from '../../util';

const labelHelper = ({ meta, validationState, helpText }) => {
  const warningPresent: boolean = !!(validationState === 'warning' && meta.warning);
  const errorPresent: boolean = !!(validationState === 'error' && meta.error);
  const helpTextPresent: boolean = !!(typeof helpText === 'string' && helpText !== '');
  const displayBottomText: boolean = helpTextPresent || errorPresent || warningPresent;
  const bottomTextClass: string = validationState === 'error' || validationState === 'warning' ? validationState : '';
  const bottomText = errorPresent ? meta.error : warningPresent ? meta.warning : helpTextPresent ? helpText : '';
  return { displayBottomText, bottomTextClass, bottomText };
};

const StatusText: React.SFC<{ meta: any; helpText?: string; className?: string; spanComponent?: any }> = ({
  meta,
  helpText,
  className,
  spanComponent: SpanComponent
}) => {
  const validationState = getValidationState(meta);
  const { displayBottomText, bottomTextClass, bottomText } = labelHelper({ meta, validationState, helpText });
  const Component = SpanComponent ? SpanComponent : HelpBlock;
  return (
    <React.Fragment>
      {displayBottomText ? (
        <Component className={className ? `${className} ${bottomTextClass}` : bottomTextClass}>{bottomText}</Component>
      ) : null}
    </React.Fragment>
  );
};

export default StatusText;
