import * as React from 'react';
import * as Icon from 'react-fontawesome';
import { Text } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import { CheckoutModuleHeading } from '../styles';
import {
  EditInfoButton,
  EditInfoContainer,
  ShippingInfoLabel,
  ShippingInfoTable,
  ShippingInfoValue,
  ShippingInfoWrapper
  } from './styles';

export interface ShippingInfoProps {
  addressOneLabel: string;
  addressTwoLabel: string;
  businessNameLabel: string;
  cityLabel: string;
  countryLabel: string;
  editButtonLabel: string;
  formCount: number;
  fullNameLabel: string;
  postalCodeLabel: string;
  shippingInfoHeading: string;
  stateLabel: string;
  toggleEdit: Function;
  userProfile: any;
  confirmation?: boolean;
  provinceLabel: string;
}

class ShippingInfo extends React.Component<ShippingInfoProps, any> {
  render() {
    const {
      addressOneLabel,
      addressTwoLabel,
      businessNameLabel,
      cityLabel,
      countryLabel,
      editButtonLabel,
      formCount,
      fullNameLabel,
      postalCodeLabel,
      shippingInfoHeading,
      stateLabel,
      toggleEdit,
      userProfile,
      confirmation,
      provinceLabel
    } = this.props;

    return (
      <ShippingInfoWrapper>
        <CheckoutModuleHeading themeVariation="default-sm-margin">
          {formCount ? `${formCount}. ${shippingInfoHeading}` : shippingInfoHeading}
          {toggleEdit && (
            <EditInfoContainer>
              <EditInfoButton themeVariation="primary-link" onClick={toggleEdit()}>
                <Icon name="pencil" /> {editButtonLabel}
              </EditInfoButton>
            </EditInfoContainer>
          )}
        </CheckoutModuleHeading>
        <ShippingInfoTable nopadding={confirmation}>
          <ShippingInfoLabel>
            <Text themeVariation="secondary-text">{fullNameLabel}</Text>
          </ShippingInfoLabel>
          <ShippingInfoValue>
            <Text themeVariation="secondary-text">{`${userProfile.firstName} ${userProfile.lastName}`}</Text>
          </ShippingInfoValue>

          <ShippingInfoLabel>
            <Text themeVariation="secondary-text">{businessNameLabel}</Text>
          </ShippingInfoLabel>
          <ShippingInfoValue>
            <Text themeVariation="secondary-text">{userProfile.businessName || `-`}</Text>
          </ShippingInfoValue>

          <ShippingInfoLabel>
            <Text themeVariation="secondary-text">{addressOneLabel}</Text>
          </ShippingInfoLabel>
          <ShippingInfoValue>
            <Text themeVariation="secondary-text">{userProfile.addressOne}</Text>
          </ShippingInfoValue>

          <ShippingInfoLabel>
            <Text themeVariation="secondary-text">{addressTwoLabel}</Text>
          </ShippingInfoLabel>
          <ShippingInfoValue>
            <Text themeVariation="secondary-text">{userProfile.addressTwo}</Text>
          </ShippingInfoValue>

          <ShippingInfoLabel>
            <Text themeVariation="secondary-text">{cityLabel}</Text>
          </ShippingInfoLabel>
          <ShippingInfoValue>
            <Text themeVariation="secondary-text">{userProfile.city}</Text>
          </ShippingInfoValue>

          {userProfile.countryId === 226 ? (
            <React.Fragment>
              <ShippingInfoLabel>
                <Text themeVariation="secondary-text">{stateLabel}</Text>
              </ShippingInfoLabel>
              <ShippingInfoValue>
                <Text themeVariation="secondary-text">{userProfile.state}</Text>
              </ShippingInfoValue>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <ShippingInfoLabel>
                <Text themeVariation="secondary-text">{provinceLabel}</Text>
              </ShippingInfoLabel>
              <ShippingInfoValue>
                <Text themeVariation="secondary-text">{userProfile.province}</Text>
              </ShippingInfoValue>
            </React.Fragment>
          )}

          <ShippingInfoLabel>
            <Text themeVariation="secondary-text">{countryLabel}</Text>
          </ShippingInfoLabel>
          <ShippingInfoValue>
            <Text themeVariation="secondary-text">{userProfile.country}</Text>
          </ShippingInfoValue>

          <ShippingInfoLabel>
            <Text themeVariation="secondary-text">{postalCodeLabel}</Text>
          </ShippingInfoLabel>
          <ShippingInfoValue>
            <Text themeVariation="secondary-text">{userProfile.postalCode}</Text>
          </ShippingInfoValue>
        </ShippingInfoTable>
      </ShippingInfoWrapper>
    );
  }
}

const mapResourceToProps = (getResource) => ({
  shippingInfoHeading: getResource('catalog.checkout.shippingInfoHeading', 'Shipping Information'),
  editButtonLabel: getResource('catalog.checkout.editLabel', 'Edit'),
  fullNameLabel: getResource('catalog.checkout.fullNameLabel', 'Full Name'),
  businessNameLabel: getResource('catalog.checkout.businessNameLabel', 'Business Name'),
  addressOneLabel: getResource('catalog.checkout.addressOneLabel', 'Address'),
  addressTwoLabel: getResource('catalog.checkout.addressTwoLabel', 'Address (Line 2)'),
  cityLabel: getResource('catalog.checkout.cityLabel', 'City'),
  stateLabel: getResource('catalog.checkout.stateLabel', 'State'),
  countryLabel: getResource('catalog.checkout.countryLabel', 'Country'),
  postalCodeLabel: getResource('catalog.checkout.postalCodeLabel', 'Postal Code'),
  provinceLabel: getResource('catalog.checkout.provinceLabel', 'Province')
});

export default withResource(mapResourceToProps)(ShippingInfo);
