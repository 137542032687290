import * as React from 'react';
import rgbaToHex from '../../../util/rgbaToHex';
import { DropDownContainer } from './styles';
import ColorSwatch from './styles/ColorSwatch';
const margin = '4px 0px 0px 4px';
class ColorSelector extends React.Component<any> {
  state = { open: false };
  componentDidMount() {
    window.addEventListener('click', this.handleGlobalClick);
  }
  componentWillUnmount() {
    window.removeEventListener('click', this.handleGlobalClick);
  }
  handleGlobalClick = (e) => {
    // if we click outside of the control, let's hide the menu
    this.setState({ open: false });
  };
  handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ open: true });
  };
  handleChange = (color) => (e) => {
    e.stopPropagation();
    const { onChange } = this.props;
    if (onChange) {
      onChange(color.value, color);
    }
    this.setState({ open: false });
  };
  render() {
    const { colors, name, value } = this.props;
    const selected = colors.filter((color) => color.name === name)[0];
    // lets reduce our array into groups
    const colorGroup =
      colors &&
      colors.reduce((obj, color) => {
        if (!obj[color.group]) obj[color.group] = [];
        obj[color.group].push(color);
        return obj;
      }, {});
    return (
      <div>
        <div onClick={this.handleClick}>
          <ColorSwatch
            color={selected && selected.value}
            title={selected && `${selected.label} (${rgbaToHex(selected.value)})`}
            value={value}
          />
        </div>
        <DropDownContainer open={this.state.open} onClick={this.handleClick}>
          <div className="dropdown-menu">
            <ColorSwatch margin={margin} onClick={this.handleChange({ label: 'None' })} />
            {colorGroup !== undefined &&
              Object.keys(colorGroup)
                .sort()
                .map((groupName, groupIndex) => {
                  return (
                    <div key={groupName}>
                      <h6>{groupName}</h6>

                      {colorGroup[groupName].sort().map((color, colorIndex) => {
                        return (
                          <ColorSwatch
                            key={color.name}
                            title={`${color.label} (${rgbaToHex(color.value)})`}
                            margin={margin}
                            color={color.value}
                            value={color.value}
                            onClick={this.handleChange(color)}
                          />
                        );
                      })}
                    </div>
                  );
                })}
          </div>
        </DropDownContainer>
      </div>
    );
  }
}

export default ColorSelector;
