import * as React from 'react';
import styled from 'styled-components';
import {
  DEFAULT_BACKGROUND,
  DEFAULT_COLOR,
  DEFAULT_RADIUS,
  DEFAULT_WIDTH
  } from './constants';

export interface ColoredCircleProps {
  color?: string;
  height?: string;
  width?: string;
  radius?: string;
  backgroundColor?: string;
}

const ColoredCircleBase = styled.div`
  background-color: ${(props: any) => props.backgroundColor || DEFAULT_BACKGROUND};
  border-radius: ${(props: any) => props.radius || DEFAULT_RADIUS};
  height: ${(props: any) => props.width || DEFAULT_WIDTH};
  width: ${(props: any) => props.width || DEFAULT_WIDTH};
  color: ${(props: any) => props.color || DEFAULT_COLOR};
  font-size: calc(${(props: any) => props.width || DEFAULT_WIDTH} / 3);
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover .initialCircle {
    position: absolute;
    text-align: center;
    width: 100%;
  }
  .initialCircle img {
    border-radius: 50%;
    height: 140px;
    width: 140px;
  }
  .imageCircle {
    display: none; 
  }
  &:hover .imageCircle {
    background-color: #000000;
    border-radius: 100%;
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    height: 140px;
    opacity: 0.79;
    text-align: center;
    width: 140px;  
  }
  &:hover .imageCircle span, &:hover .imageCircle .fa-camera {
    display: block;
    position: relative;
    top: 32%;
  }
  &:hover .imageCircle .fa-camera {
    font-size: 25px;
    padding-bottom: 5px;
  }
` as any;

const ColoredCircle: React.SFC<ColoredCircleProps> = ({ children, ...rest }) => (
  <ColoredCircleBase {...rest}>{children}</ColoredCircleBase>
);

export default ColoredCircle;
