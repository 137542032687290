import * as React from 'react';
import {
  Col,
  Nav,
  Navbar,
  Row
  } from 'react-bootstrap';
import { HeaderOne } from 'rsv8-components';
import { Link } from 'rsv8-components';
import { HeaderFour } from 'rsv8-components';
import { register, withTheme } from 'xcel-react-core';
import { touchDetect } from 'xcel-util';
import { NavItem } from '../MenuItem';
import NavDropdown from './NavDropdown';
import { SubNavStyle } from './styles';
import { HeaderContainer } from './styles/HeaderNavigationStyles';

interface NominationsHeaderNavProps {
  menuItems: any;
  routing: any;
  cultureId: any;
  title: any;
  className: any;
  customNavItems?: any;
}

interface NominationsHeaderNavState {
  menu: any;
}

class NominationsHeaderNav extends React.Component<NominationsHeaderNavProps, NominationsHeaderNavState> {
  constructor(props: any) {
    super(props);
    this.state = { menu: undefined };
  }

  componentDidMount() {
    const { menuItems, routing, customNavItems } = this.props;
    if (menuItems !== undefined) {
      this.setState({ menu: this.mapMenuItemChildren(menuItems, routing) });
    } else if (customNavItems !== undefined) {
      this.setState({ menu: customNavItems });
    }
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    const { menuItems, routing, customNavItems } = this.props;
    if (
      (menuItems !== undefined && menuItems !== prevProps.menuItems) ||
      (routing && prevProps.routing && routing.location !== prevProps.routing.location)
    ) {
      this.setState({ menu: this.mapMenuItemChildren(menuItems, routing) });
    } else if (customNavItems !== undefined && customNavItems !== prevProps.customNavItems) {
      this.setState({ menu: customNavItems });
    }
  }

  mapMenuItemChildren = (menuItems, routing, count = 0) => {
    return menuItems.map((child, i) => {
      if (child.children && child.children.length > 0 && touchDetect() !== true) {
        return this.dropdownMenuItem(child, count);
      } else {
        return this.singleMenuItem(child, routing);
      }
    });
  };

  dropdownMenuItem = (item, count, routing?: any) => (
    <NavDropdown {...item} cultureId={this.props.cultureId} title={item.label} href={item.targetUrl}>
      {count !== 0 && (
        <HeaderFour themeVariation="no-margin-padding">
          <Link to={item.targetUrl}>{item.label}</Link>
        </HeaderFour>
      )}
      {this.mapMenuItemChildren(item.children, routing, count + 1)}
    </NavDropdown>
  );

  singleMenuItem = (item, routing) => (
    <NavItem
      meta={item.meta}
      href={item.targetUrl}
      id={item.id}
      cultureId={this.props.cultureId}
      className={
        (routing && routing.location && routing.location.pathname === item.targetUrl) || item.active
          ? 'nav-link-selected'
          : ''
      }
    >
      {item.label}
    </NavItem>
  );

  render() {
    let { title, className, children } = this.props;

    return (
      <SubNavStyle className={className}>
        <Navbar fluid={false}>
          <Row>
            <Col sm={24}>
              <HeaderContainer>
                <HeaderOne variationName="equal-margins" className="nav-title hidden-xs">
                  {title}
                </HeaderOne>
                {children}
              </HeaderContainer>
            </Col>
          </Row>
          <Col sm={24}>
            <Nav>{this.state.menu}</Nav>
          </Col>
        </Navbar>
      </SubNavStyle>
    );
  }
}

export default register('rsv8-navigation/SubNavigation')(withTheme())(NominationsHeaderNav);
