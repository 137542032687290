import { push } from 'connected-react-router';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { RouteProps } from 'react-router';
import { bindActionCreators } from 'redux';
import { socialActions, socialSelector } from 'rsv8-cms';
import { ReactField } from 'rsv8-forms';
import { SocialDetailModel } from 'xcel-api-generator/dist/social';
import ActivityTable from '../../components/ActivityTable';
import CreateActivityModal from '../../components/CreateActivityModal';

enum SortOrder {
  ASC_DIR = 'Asc',
  DESC_DIR = 'Desc',
  UNSORT = 'unsorted'
}

const DEFAULT_SORT_PROP = 'createdDateTimeUtc';
const DEFAULT_DIR = SortOrder.ASC_DIR;

export interface ActivityListProps extends RouteProps {
  activities: Array<SocialDetailModel>;
  loaded: boolean;
  getActivities: any;
  getActivityTypes: any;
  createActivity: any;
  removeActivity: any;
  setEditCulture: any;
  setEditVersion: any;
  goToEditActivityView: any;
}

export interface State {
  activities: Array<SocialDetailModel>;
}

class ActivityList extends React.Component<ActivityListProps, State> {
  state = {
    activities: []
  };

  componentWillReceiveProps({ activities, loaded }: ActivityListProps) {
    const listChanged = this.props.activities.length !== activities.length;
    const newActLoaded = this.props.loaded !== loaded;

    if (newActLoaded || listChanged) {
      this.setState({ activities: this.sorting(activities) });
    }
  }

  componentDidMount() {
    const { getActivities, getActivityTypes, setEditCulture, setEditVersion } = this.props;

    getActivities();
    getActivityTypes({});

    // reset editing persistence
    setEditCulture(undefined);
    setEditVersion(undefined);
  }

  onSearch = (input) => {
    const { getActivities } = this.props;
    let query = input.length > 2 ? { pagingFilter: `name = "${input}"` } : {};

    getActivities(query);
  };

  onClickRow = (item) => {
    const { goToEditActivityView } = this.props;
    goToEditActivityView(item.id);
  };

  onRemove = (item) => {
    const { removeActivity } = this.props;
    if (window.confirm('Are you sure you want to delete this activity?')) {
      removeActivity(item.id);
    }
  };

  onSort = ({ direction, field }) => {
    const { activities } = this.props;
    let sortedActivities: any[];

    if (direction !== SortOrder.UNSORT) {
      sortedActivities = this.sorting(activities, field, direction);
    } else {
      sortedActivities = this.sorting(activities);
    }

    this.setState({ activities: sortedActivities });
  };

  sorting(activities, field = DEFAULT_SORT_PROP, direction = DEFAULT_DIR) {
    // copy the list in order to not modify the original reference
    const activitiesCopy = activities.slice();

    return activitiesCopy.sort((activity1, activity2) => {
      const value1 = activity1[field];
      const value2 = activity2[field];
      let sortValue: number;

      switch (direction) {
        case SortOrder.ASC_DIR:
          sortValue = this.validateSortType(value1, value2);
          break;
        case SortOrder.DESC_DIR:
          sortValue = this.validateSortType(value2, value1);
          break;
        case SortOrder.UNSORT:
        default:
          // return 0 when they are the same
          sortValue = 0;
          break;
      }

      return sortValue;
    });
  }

  /**
   * Validate the type of sorting - if it's sorting by date or string
   *
   * @param {string} value1
   * @param {string} value2
   * @return - 1 or -1, it depends or the sorting order
   */
  validateSortType(value1: string, value2: string) {
    const date1 = Date.parse(value1);
    const date2 = Date.parse(value2);
    let sortValue: number;

    if (!isNaN(date1) && !isNaN(date2)) {
      sortValue = date1 < date2 ? 1 : -1;
    } else if (typeof value1 === 'string' && typeof value2 === 'string') {
      sortValue = value1.toLowerCase() < value2.toLowerCase() ? 1 : -1;
    }

    return sortValue;
  }

  render() {
    return (
      <div className="container-fluid">
        <Row>
          <Col md={12}>
            <ReactField component={'bs-input'} placeholder="Search" label={'Search'} onChange={this.onSearch} />
          </Col>
        </Row>
        <CreateActivityModal createActivity={this.props.createActivity} />
        <ActivityTable
          loaded={this.props.loaded}
          items={this.state.activities}
          onClick={this.onClickRow}
          onDelete={this.onRemove}
          onSort={this.onSort}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activities: socialSelector.activities(state),
  loaded: socialSelector.activitiesLoaded(state)
});

const mapDispatchToProps = (dispatch) => ({
  getActivities: bindActionCreators(socialActions.getActivities, dispatch),
  getActivityTypes: bindActionCreators(socialActions.getActivityTypes, dispatch),
  createActivity: bindActionCreators(socialActions.createActivity, dispatch),
  removeActivity: bindActionCreators(socialActions.removeActivity, dispatch),
  setEditCulture: bindActionCreators(socialActions.setEditCulture, dispatch),
  setEditVersion: bindActionCreators(socialActions.setEditVersion, dispatch),
  goToEditActivityView: (itemId) => {
    dispatch(push(`/social/edit/${itemId}`));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityList);
