import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, HeaderThree, Text, toast } from 'rsv8-components';
import { ReactField } from 'rsv8-forms';
import { Amount, GoalDto, Program } from 'xcel-api-generator/dist/alcon';
import { register, withContent, withResource, withTheme } from 'xcel-react-core';
import { withApiDataProvider } from 'xcel-redux-orm';
import { getCurrencyValue, getMarketSharePercent, getRoundedValue, getValueOrNull, getValueOrZero } from '../../../utils';
import { ProgramType } from '../../constants';
import { createMagnifeyeGoalCalculate, createMagnifeyeGoalSave, getMagnifeyeV2Summary, getProgram } from '../../redux/actions';
import * as selectors from '../../redux/selectors';
import { OverlayPopover } from '../Overlay';
import {
  AlignedCol,
  BranchingImage,
  CloseButton,
  ContentRow,
  ExtraSpace,
  HeaderRow,
  HiddenButton,
  HiddenContainer,
  ProjectionValue,
  StyledGoal,
  StyledGoalContainer,
  StyledGoalDescription,
  StyledGoalName,
  StyledGoalNote,
  StyledHorizontalRule,
  StyledNames,
  StyledNote,
  StyledSign,
} from './styles';

const initialAmount: Amount = {
  id: undefined,
  units: undefined,
  sales: undefined,
  ytdUnits: undefined,
  ytdSales: undefined,
  marketShare: undefined,
  name: undefined,
  rebate: undefined,
  margin: undefined,
  discount: undefined,
  patients: undefined,
  patientsPerWeek: undefined,
  patientsPerRemainingWeek: undefined,
  ytdPatients: undefined,
  ytdPatientsPerWeek: undefined,
  ytdPatientsPerRemainingWeek: undefined,
  totalValue: undefined,
};

type GoalContent = {
  key: string;
  name: string;
  description: string;
  achievedMsg: string;
  notAchievedMsg: string;
  notAvailableMsg: string;
  isSelected?: boolean;
  isAvailable?: boolean;
  isAchieved?: boolean;
};

type GoalProps = {
  goalNote: string;
  goalContent: GoalContent;
  onGoalClick: Function;
};
const Goal: React.FC<GoalProps> = ({ goalNote, goalContent, onGoalClick }) => (
  <StyledGoal selected={goalContent.isSelected} onClick={() => onGoalClick(goalContent.key)}>
    <div className="goal-checkmark">
      <i className="fa fa-check" />
    </div>
    <StyledGoalNote>{goalNote}</StyledGoalNote>
    <StyledGoalName dangerouslySetInnerHTML={{ __html: goalContent.name }} />
    <StyledGoalDescription dangerouslySetInnerHTML={{ __html: goalContent.description }} />
  </StyledGoal>
);

const CreateYourGoals: React.FC<Props> = ({
  className,

  actions: { createMagnifeyeGoalCalculate, createMagnifeyeGoalSave },

  resource: {
    goalNote,
    goalColor = '#00AE44',
    totalValueLabel,
    totalValueNote,
    marginLabel,
    marginNote,
    rebateLabel,
    rebateNote,
    priceDiscountsLabel,
    costSavingsNote,

    patientsGoalLabel,
    estimatedPatientsLabel,
    suggestedPatientsNote,
    projectedPatientsLabel,
    projectedPatientsNote,
    totalPatientsLabel,
    totalPatientsNote,

    myUnitsGoalLabel,
    currentUnitsQuarterLabel,
    suggestedUnitsQuarterLabel,

    myShareGoalLabel,
    baselineShareLabel,
    currentShareLabel,
    suggestedShareLabel,
    unitsShareLabel,

    calculateBtnLabel,
    calculateBtnNote,
    saveBtnLabel,
    saveBtnNote,

    calculateGoalMessage,
    calculateGoalFailedMessage,
    saveGoalMessage,
    saveGoalFailedMessage,
  },

  title,
  infoIconText,
  headerNote,
  messageSectionTitle,
  messageSectionNote,
  allGoalsAchievedMsg,
  goalsContent,

  program,
  goals,
  baseline,
  current,
  runRate,
}) => {
  if (!program || !current || !runRate || !goals || !goalsContent) {
    return null;
  }

  const [visible, setVisible] = React.useState<boolean>(false);
  const [suggestedPatients, setSuggestedPatients] = React.useState<string>('');
  const [selectedAmount, setSelectedAmount] = React.useState<Amount>(initialAmount);
  const [myGoals, setMyGoals] = React.useState<GoalContent[]>([]);

  const totalPatients = React.useMemo(() => {
    if (!suggestedPatients) {
      return '-';
    }

    const patients = getValueOrZero(suggestedPatients);
    if (patients <= runRate.patients) {
      return 0;
    }
    return (patients - runRate.patients).toFixed(1);
  }, [runRate, suggestedPatients]);

  React.useEffect(() => {
    const res: GoalContent[] = [];
    for (const goal of goalsContent) {
      for (const g of goals) {
        if (!g.isActive) continue;

        if (g.id === goal.key) {
          res.push({
            ...goal,
            isSelected: false,
            isAvailable: g.isAvailable,
            isAchieved: g.isAvailable && current.patients > g.amount.patients,
          });
          break;
        }
      }
    }

    setMyGoals(res);
  }, [goals]);

  const onCreateGoalsClick = () => {
    if (!visible) {
      setVisible(true);
    }

    setTimeout(() => {
      const element = document.getElementById('create-goals-visibility');
      if (!element) {
        return;
      }

      window.scrollTo({
        top: element.parentElement.parentElement.parentElement.parentElement.offsetTop - 60,
        behavior: 'smooth',
      });
    }, 100);
  };

  const onGoalClick = (goalKey: string) => {
    // Update selected goals
    const updatedMyGoals = myGoals.slice();
    for (const g of updatedMyGoals) {
      if (g.key === goalKey) {
        g.isSelected = !g.isSelected;
      }
    }
    setMyGoals(updatedMyGoals);

    // Find suggested amount
    let amount: Amount = initialAmount;
    for (const myGoal of updatedMyGoals) {
      if (!myGoal.isSelected || !myGoal.isAvailable) {
        continue;
      }

      const goal = goals.find((g) => g.id === myGoal.key);
      if (!goal) {
        continue;
      }

      if (!amount.patients || goal.amount.patients > amount.patients) {
        amount = goal.amount;
      }
    }

    setSelectedAmount(amount);
    setSuggestedPatients(getValueOrZero(amount.patients).toFixed(1));
  };

  const onChange = (value: string) => {
    setSuggestedPatients(value);
  };

  const onCalculateClick = async () => {
    try {
      const res = await createMagnifeyeGoalCalculate({ patients: getValueOrZero(suggestedPatients) });
      toast(
        {
          template: 'Default',
          message: calculateGoalMessage,
        },
        {
          className: 'success-toast-body',
          closeButton: false,
        }
      );

      const amount: Amount = res?.data?.attributes;
      const patients = getValueOrZero(String(amount.patients));
      setSelectedAmount(amount);
      setSuggestedPatients(patients.toFixed(1));
    } catch (error) {
      console.error(error);
      toast(
        {
          template: 'Default',
          themeVariation: 'error-text',
          message: calculateGoalFailedMessage,
        },
        {
          className: 'error-toast-body',
          closeButton: false,
        }
      );
    }
  };

  const onSaveClick = async () => {
    try {
      const res = await createMagnifeyeGoalSave({ patients: getValueOrZero(suggestedPatients) });
      toast(
        {
          template: 'Default',
          message: saveGoalMessage,
        },
        {
          className: 'success-toast-body',
          closeButton: false,
        }
      );

      const amount: Amount = res?.data?.attributes;
      const patients = getValueOrZero(String(amount.patients));
      setSelectedAmount(amount);
      setSuggestedPatients(patients.toFixed(1));
    } catch (error) {
      console.error(error);
      toast(
        {
          template: 'Default',
          themeVariation: 'error-text',
          message: saveGoalFailedMessage,
        },
        {
          className: 'error-toast-body',
          closeButton: false,
        }
      );
    }
  };

  return (
    <HiddenContainer className={className} visible={visible}>
      <HiddenButton id="create-goals-visibility" onClick={onCreateGoalsClick} />

      <CloseButton onClick={() => setVisible(false)}>
        <i className="fa fa-times" aria-hidden="true" />
      </CloseButton>

      <HeaderRow color="#FFFFFF">
        <AlignedCol xs={24}>
          <HeaderThree themeVariation="my-dashboard">
            {title + ' '}
            <OverlayPopover id="popover-create-your-goals" placement="bottom">
              {infoIconText}
            </OverlayPopover>
          </HeaderThree>
        </AlignedCol>
      </HeaderRow>

      <ContentRow padding="0">
        <AlignedCol xs={24} sm={22} smOffset={1} md={18} mdOffset={3} lg={12} lgOffset={6}>
          <Text dangerouslySetInnerHTML={{ __html: headerNote }} />
        </AlignedCol>

        <AlignedCol xs={24}>
          <StyledGoalContainer>
            {myGoals.map((goal) => (
              <Goal key={goal.key} goalNote={goalNote} goalContent={goal} onGoalClick={onGoalClick} />
            ))}
          </StyledGoalContainer>
        </AlignedCol>
      </ContentRow>

      {!!selectedAmount && !!myGoals.find((goal) => goal.isSelected) && (
        <>
          <ContentRow padding="0">
            <AlignedCol xs={24}>
              <StyledHorizontalRule />
            </AlignedCol>

            <AlignedCol xs={24}>
              <HeaderThree themeVariation="my-dashboard" dangerouslySetInnerHTML={{ __html: messageSectionTitle }} />
            </AlignedCol>
          </ContentRow>

          <ContentRow padding="20px 0 0">
            {myGoals.filter((goal) => goal.isSelected).every((goal) => goal.isAchieved) && (
              <AlignedCol xs={24} md={12} mdOffset={6}>
                <Text
                  dangerouslySetInnerHTML={{
                    __html: allGoalsAchievedMsg,
                  }}
                />
                <br />
                <br />
              </AlignedCol>
            )}

            {!myGoals.filter((goal) => goal.isSelected).every((goal) => goal.isAchieved) &&
              myGoals.map((goal) => {
                if (!goal.isSelected) return null;

                let goalMsg: string;
                if (!goal.isAvailable) {
                  goalMsg = goal.notAvailableMsg;
                } else if (!goal.isAchieved) {
                  goalMsg = goal.notAchievedMsg;
                } else {
                  goalMsg = goal.achievedMsg;
                }
                if (!goalMsg) return null;

                return (
                  <AlignedCol key={goal.key} xs={24} md={12} mdOffset={6}>
                    <Text
                      dangerouslySetInnerHTML={{
                        __html: goalMsg,
                      }}
                    />
                    <br />
                    <br />
                  </AlignedCol>
                );
              })}

            <AlignedCol xs={24} md={12} mdOffset={6}>
              <Text dangerouslySetInnerHTML={{ __html: messageSectionNote }} />
            </AlignedCol>
          </ContentRow>

          <ContentRow padding="20px 0 40px">
            <AlignedCol xs={24} lg={12} className="total-goal-column">
              <AlignedCol xs={22} xsOffset={1} sm={18} smOffset={3}>
                <StyledNames themeVariation="my-dashboard">{totalValueLabel}</StyledNames>
                <ProjectionValue color={goalColor} fontSize={26} lineHeight={26}>
                  {getCurrencyValue(getValueOrNull(selectedAmount.totalValue)) || '-'}
                </ProjectionValue>
                <StyledNote dangerouslySetInnerHTML={{ __html: totalValueNote }} />
              </AlignedCol>

              <AlignedCol xs={24}>
                <BranchingImage />
              </AlignedCol>

              <AlignedCol xs={16} xsOffset={4} sm={8} smOffset={0}>
                <StyledNames themeVariation="my-dashboard">{marginLabel}</StyledNames>
                <ProjectionValue>{getCurrencyValue(getValueOrNull(selectedAmount.margin)) || '-'}</ProjectionValue>
                <StyledNote dangerouslySetInnerHTML={{ __html: marginNote }} />
              </AlignedCol>

              <AlignedCol xs={16} xsOffset={4} sm={8} smOffset={0}>
                <StyledNames themeVariation="my-dashboard">{rebateLabel}</StyledNames>
                <ProjectionValue>{getCurrencyValue(getValueOrNull(selectedAmount.rebate)) || '-'}</ProjectionValue>
                <StyledNote dangerouslySetInnerHTML={{ __html: rebateNote }} />
              </AlignedCol>

              <AlignedCol xs={16} xsOffset={4} sm={8} smOffset={0}>
                <StyledNames themeVariation="my-dashboard">{priceDiscountsLabel}</StyledNames>
                <ProjectionValue>{getCurrencyValue(getValueOrNull(selectedAmount.discount)) || '-'}</ProjectionValue>
                <StyledNote dangerouslySetInnerHTML={{ __html: costSavingsNote }} />
              </AlignedCol>

              <AlignedCol xs={24} lgHidden={true}>
                <StyledHorizontalRule />
              </AlignedCol>
            </AlignedCol>

            <AlignedCol xs={24} lg={12} className="my-goal-column">
              <AlignedCol xs={24}>
                <StyledNames themeVariation="my-dashboard">{patientsGoalLabel}</StyledNames>
              </AlignedCol>

              <AlignedCol xs={16} xsOffset={4} sm={7} smOffset={0}>
                <StyledNames themeVariation="my-dashboard" fontSize={16} lineHeight={18}>
                  <ExtraSpace />
                  {estimatedPatientsLabel}
                </StyledNames>
                <ProjectionValue>
                  <ReactField
                    name="suggestedPatients"
                    component="bs-input"
                    type="number"
                    value={suggestedPatients == null ? '-' : suggestedPatients}
                    onChange={onChange}
                  />
                </ProjectionValue>
                <StyledNote dangerouslySetInnerHTML={{ __html: suggestedPatientsNote }} />
              </AlignedCol>

              <AlignedCol xs={16} xsOffset={4} sm={1} smOffset={0}>
                <StyledSign themeVariation="my-dashboard">-</StyledSign>
              </AlignedCol>

              <AlignedCol xs={16} xsOffset={4} sm={7} smOffset={0}>
                <StyledNames themeVariation="my-dashboard" fontSize={16} lineHeight={18}>
                  <ExtraSpace />
                  {projectedPatientsLabel}
                </StyledNames>
                <ProjectionValue>{getRoundedValue(runRate.patients, 1) || '-'}</ProjectionValue>
                <StyledNote dangerouslySetInnerHTML={{ __html: projectedPatientsNote }} />
              </AlignedCol>

              <AlignedCol xs={16} xsOffset={4} sm={1} smOffset={0}>
                <StyledSign themeVariation="my-dashboard">=</StyledSign>
              </AlignedCol>

              <AlignedCol xs={16} xsOffset={4} sm={8} smOffset={0}>
                <StyledNames themeVariation="my-dashboard" fontSize={16} lineHeight={18}>
                  {totalPatientsLabel}
                </StyledNames>
                <ProjectionValue color={goalColor}>{totalPatients}</ProjectionValue>
                <StyledNote dangerouslySetInnerHTML={{ __html: totalPatientsNote }} />
              </AlignedCol>

              <AlignedCol xs={24}>
                <StyledHorizontalRule />
              </AlignedCol>

              {!(ProgramType.match(ProgramType.MarketShare, program) || ProgramType.match(ProgramType.TotalPartner, program)) && (
                <>
                  <AlignedCol xs={24}>
                    <StyledNames themeVariation="my-dashboard">{myUnitsGoalLabel}</StyledNames>
                  </AlignedCol>

                  <AlignedCol xs={16} xsOffset={4} sm={7} smOffset={4}>
                    <StyledNames themeVariation="my-dashboard" fontSize={16} lineHeight={18}>
                      {currentUnitsQuarterLabel}
                    </StyledNames>
                    <ProjectionValue>{getRoundedValue(getValueOrNull(current.units), 1) || '-'}</ProjectionValue>
                  </AlignedCol>

                  <AlignedCol xs={16} xsOffset={4} sm={7} smOffset={2}>
                    <StyledNames themeVariation="my-dashboard" fontSize={16} lineHeight={18}>
                      {suggestedUnitsQuarterLabel}
                    </StyledNames>
                    <ProjectionValue>{getRoundedValue(getValueOrNull(selectedAmount.units), 1) || '-'}</ProjectionValue>
                  </AlignedCol>
                </>
              )}

              {(ProgramType.match(ProgramType.MarketShare, program) || ProgramType.match(ProgramType.TotalPartner, program)) && (
                <>
                  <AlignedCol xs={24}>
                    <StyledNames themeVariation="my-dashboard">{myShareGoalLabel}</StyledNames>
                  </AlignedCol>

                  <AlignedCol xs={16} xsOffset={4} sm={7} smOffset={0}>
                    <StyledNames themeVariation="my-dashboard" fontSize={16} lineHeight={18}>
                      {baselineShareLabel}
                    </StyledNames>
                    <ProjectionValue>{getMarketSharePercent(baseline, '-')}</ProjectionValue>
                  </AlignedCol>

                  <AlignedCol xs={16} xsOffset={4} sm={7} smOffset={1}>
                    <StyledNames themeVariation="my-dashboard" fontSize={16} lineHeight={18}>
                      {currentShareLabel}
                    </StyledNames>
                    <ProjectionValue>{getMarketSharePercent(current, '-')}</ProjectionValue>
                  </AlignedCol>

                  <AlignedCol xs={16} xsOffset={4} sm={8} smOffset={1}>
                    <StyledNames themeVariation="my-dashboard" fontSize={16} lineHeight={18}>
                      {suggestedShareLabel}
                    </StyledNames>
                    <ProjectionValue>{getMarketSharePercent(selectedAmount, '-')}</ProjectionValue>
                  </AlignedCol>

                  <AlignedCol xs={16} xsOffset={4} sm={7} smOffset={8} padding="10px 0">
                    <StyledNames themeVariation="my-dashboard" fontSize={16} lineHeight={18}>
                      {unitsShareLabel}
                    </StyledNames>
                    <ProjectionValue>{getRoundedValue(selectedAmount.units, 1) || '-'}</ProjectionValue>
                  </AlignedCol>
                </>
              )}
            </AlignedCol>

            <AlignedCol xs={20} xsOffset={2} sm={10} smOffset={1} md={11} mdOffset={0} lg={9} lgOffset={2}>
              <StyledNote margin="40px auto 20px" dangerouslySetInnerHTML={{ __html: calculateBtnNote }} />
              <Button themeVariation="my-dashboard" onClick={onCalculateClick} disabled={!suggestedPatients}>
                {calculateBtnLabel}
              </Button>
            </AlignedCol>

            <AlignedCol xs={20} xsOffset={2} sm={10} smOffset={2} md={11} mdOffset={2} lg={9} lgOffset={2}>
              <StyledNote margin="40px auto 20px" dangerouslySetInnerHTML={{ __html: saveBtnNote }} />
              <Button themeVariation="my-dashboard" onClick={onSaveClick} disabled={!suggestedPatients}>
                {saveBtnLabel}
              </Button>
            </AlignedCol>
          </ContentRow>
        </>
      )}
    </HiddenContainer>
  );
};

const mapContentToProps = (getContent) => ({
  title: getContent('title', { type: 'string', label: 'Title' }),
  infoIconText: getContent('infoIconText', { type: 'string', label: 'Info Icon Text' }),
  headerNote: getContent('headerNote', { type: 'string', label: 'Header Note' }),
  messageSectionTitle: getContent('messageSectionTitle', { type: 'string', label: 'Message Section Title' }),
  messageSectionNote: getContent('messageSectionNote', { type: 'string', label: 'Message Section Note' }),
  allGoalsAchievedMsg: getContent('allGoalsAchievedMsg', { type: 'string', label: 'All Goals Achieved Message' }),
  goalsContent: getContent('goalsContent', {
    type: 'array',
    schema: [
      { model: 'key', label: 'Goal key' },
      { model: 'name', label: 'Goal name' },
      { model: 'description', label: 'Goal description' },
    ],
    label: 'Goals Content',
  }),
});
const mapResourceToProps = (getResource) => ({
  resource: {
    goalNote: getResource('alcon.dashboard.createGoals.goalNote', 'I WANT TO:'),
    goalColor: getResource('alcon.dashboard.createGoals.goalColor', '#00AE44'),
    totalValueLabel: getResource('alcon.dashboard.createGoals.totalValueLabel', 'Total Value Goal'),
    totalValueNote: getResource(
      'alcon.dashboard.createGoals.totalValueNote',
      'Sum of your <b>goal</b> margin, rebate, and price discounts'
    ),
    marginLabel: getResource('alcon.dashboard.createGoals.marginLabel', 'Margin'),
    marginNote: getResource('alcon.dashboard.createGoals.marginNote', '(Goal Anticipated Margin is MSRP - List Price)'),
    rebateLabel: getResource('alcon.dashboard.createGoals.rebateLabel', 'Rebate'),
    rebateNote: getResource('alcon.dashboard.createGoals.rebateNote', '(Goal Practice Rebate)'),
    priceDiscountsLabel: getResource('alcon.dashboard.createGoals.priceDiscountsLabel', 'Price Discounts'),
    costSavingsNote: getResource('alcon.dashboard.createGoals.costSavingsNote', '(Goal Product Price Discounts)'),

    patientsGoalLabel: getResource('alcon.dashboard.createGoals.patientsGoalLabel', 'My Patients Goal'),
    estimatedPatientsLabel: getResource('alcon.dashboard.createGoals.estimatedPatientsLabel', 'Estimated Patients'),
    suggestedPatientsNote: getResource(
      'alcon.dashboard.createGoals.suggestedPatientsNote',
      '(Estimated Total Patients needed this quarter)'
    ),
    projectedPatientsLabel: getResource('alcon.dashboard.createGoals.projectedPatientsLabel', 'Projected Patients'),
    projectedPatientsNote: getResource(
      'alcon.dashboard.createGoals.projectedPatientsNote',
      '(Total Patients reflected in your projections)'
    ),
    totalPatientsLabel: getResource('alcon.dashboard.createGoals.totalPatientsLabel', 'Total Incremental Patients from Current Projection'),
    totalPatientsNote: getResource(
      'alcon.dashboard.createGoals.totalPatientsNote',
      '(The difference between Estimated Patients and Projected Patients)'
    ),

    myUnitsGoalLabel: getResource('alcon.dashboard.createGoals.myUnitsGoalLabel', 'My Units Goal'),
    currentUnitsQuarterLabel: getResource('alcon.dashboard.createGoals.currentUnitsQuarterLabel', 'Current Units this Quarter'),
    suggestedUnitsQuarterLabel: getResource('alcon.dashboard.createGoals.suggestedUnitsQuarterLabel', 'Estimated Units this Quarter'),

    myShareGoalLabel: getResource('alcon.dashboard.createGoals.myShareGoalLabel ', 'My Share Goal'),
    baselineShareLabel: getResource('alcon.dashboard.createGoals.baselineShareLabel', 'Baseline Share'),
    currentShareLabel: getResource('alcon.dashboard.createGoals.suggestedUnitsQuarterLabel', 'Current Share'),
    suggestedShareLabel: getResource('alcon.dashboard.createGoals.suggestedShareLabel', 'Suggested Share'),
    unitsShareLabel: getResource('alcon.dashboard.createGoals.unitsShareLabel', 'Estimated Units'),

    calculateBtnLabel: getResource('alcon.dashboard.createGoals.calculateBtnLabel', 'RE-CALCULATE BASED ON REVISED PATIENT INPUT'),
    calculateBtnNote: getResource(
      'alcon.dashboard.createGoals.calculateBtnNote',
      'If you have changed the number in the Estimated Patients field please use this button to recalculate your goals.'
    ),
    saveBtnLabel: getResource('alcon.dashboard.createGoals.saveBtnLabel', 'SAVE YOUR GOALS TO THE DASHBOARD'),
    saveBtnNote: getResource(
      'alcon.dashboard.createGoals.saveBtnNote',
      'To populate these calculations into the My Goals section above, use the Save button below.'
    ),

    calculateGoalMessage: getResource('alcon.dashboard.createGoals.calculateGoalMessage', 'Your goals have successfully been updated'),
    calculateGoalFailedMessage: getResource(
      'alcon.dashboard.createGoals.calculateGoalFailedMessage',
      'There was an error completing your request. Please contact the portal support team for assistance.'
    ),
    saveGoalMessage: getResource('alcon.dashboard.createGoals.saveGoalMessage', 'Your goals have successfully been saved'),
    saveGoalFailedMessage: getResource(
      'alcon.dashboard.createGoals.saveGoalFailedMessage',
      'There was an error completing your request. Please contact the portal support team for assistance.'
    ),
  },
});

export const mapStateToProps = (state) => ({
  program: selectors.programSelector.selectMany(state)[0],
  goals: selectors.goalSelector.selectMany(state),
  baseline: selectors.performanceAmountSelector(state, 'Baseline'),
  current: selectors.performanceAmountSelector(state, 'Current'),
  runRate: selectors.performanceAmountSelector(state, 'Run Rate'),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ createMagnifeyeGoalCalculate, createMagnifeyeGoalSave }, dispatch),
});

interface StateProps {
  program: Program;
  goals?: GoalDto[];
  baseline: Amount;
  current: Amount;
  runRate: Amount;
}
interface DispatchProps {
  actions: {
    createMagnifeyeGoalCalculate: Function;
    createMagnifeyeGoalSave: Function;
  };
}
interface ContentProps {
  title: string;
  infoIconText: string;
  headerNote: string;
  messageSectionTitle: string;
  messageSectionNote: string;
  allGoalsAchievedMsg: string;
  goalsContent: GoalContent[];
}
interface ResourceProps {
  resource: {
    goalNote: string;
    goalColor: string;
    totalValueLabel: string;
    totalValueNote: string;
    marginLabel: string;
    marginNote: string;
    rebateLabel: string;
    rebateNote: string;
    priceDiscountsLabel: string;
    costSavingsNote: string;

    patientsGoalLabel: string;
    estimatedPatientsLabel: string;
    suggestedPatientsNote: string;
    projectedPatientsLabel: string;
    projectedPatientsNote: string;
    totalPatientsLabel: string;
    totalPatientsNote: string;

    myUnitsGoalLabel: string;
    currentUnitsQuarterLabel: string;
    suggestedUnitsQuarterLabel: string;

    myShareGoalLabel: string;
    baselineShareLabel: string;
    currentShareLabel: string;
    suggestedShareLabel: string;
    unitsShareLabel: string;

    calculateBtnLabel: string;
    calculateBtnNote: string;
    saveBtnLabel: string;
    saveBtnNote: string;

    calculateGoalMessage: string;
    calculateGoalFailedMessage: string;
    saveGoalMessage: string;
    saveGoalFailedMessage: string;
  };
}
type Props = StateProps & DispatchProps & ContentProps & ResourceProps & React.HtmlHTMLAttributes<any>;

export default register('rsv8-alcon/CreateYourGoals')(
  withApiDataProvider(getMagnifeyeV2Summary, selectors.performanceSelector),
  withApiDataProvider(getProgram, selectors.programSelector),
  connect<StateProps>(mapStateToProps, mapDispatchToProps),
  withContent(mapContentToProps),
  withResource(mapResourceToProps),
  withTheme()
)(CreateYourGoals);
