import { Forms } from '../../types';
import {
  BSInput,
  BSSelect,
  Checkbox,
  CheckboxGroup,
  Input,
  MultiSelect,
  RadioGroup,
  Textarea
  } from '../Components/index';
import ComponentProviderClass from './ComponentProvider';

export const ComponentProviderMap = {
  'bs-input': BSInput,
  'bs-select': BSSelect,
  checkbox: Checkbox,
  'checkbox-group': CheckboxGroup,
  'simple-input': Input,
  'multi-select': MultiSelect,
  'radio-group': RadioGroup,
  'bs-textarea': Textarea
};
export { Forms };
export const ComponentProvider = new ComponentProviderClass(ComponentProviderMap);
