import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withResource } from 'xcel-react-core';
import { ForgotUserPass } from '../../../components/Layout';

const UserNotFound = ({ loginHelpLinkPath, resource: { headerText } }) => (
  <ForgotUserPass loginHelpLinkPath={loginHelpLinkPath} headerText={headerText} />
);

const mapState = () => ({});

const mapDispatch = (dispatch) => ({});

const mapResourceToProps = (getResource) => ({
  resource: {
    headerText: getResource(
      'userNotFound.headerText',
      'We’re sorry but there is no account associated to that username.'
    )
  }
});

export default compose(
  withResource(mapResourceToProps),
  connect(
    mapState,
    mapDispatch
  )
)(UserNotFound) as any;
