import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ComponentRenderer } from 'rsv8-cms';
import { register, withContent, withTheme } from 'xcel-react-core';
import { withApiDataProvider } from 'xcel-redux-orm';
import { getOrganization, getUserAccessMetricByMetricKey } from '../../redux/actions';
import { organizationSelector } from '../../redux/selectors';

const UserMetricChangeForTimePeriod: React.FC<Props> = ({
  className,
  children,
  ownerId,
  actions,
  program,
  metric,
  timePeriod,
  iconUp = 'fa fa-play fa-rotate-270',
  iconDown = 'fa fa-play fa-rotate-90',
  iconNoChange = 'fa fa-play fa-rotate-180',
  ...props
}) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [value, setValue] = React.useState<number>(null);

  React.useEffect(() => {
    if (!!program && !!timePeriod && !!metric && !!ownerId) {
      setLoading(true);
      actions
        .getUserAccessMetricByMetricKey({ programKey: program, metricKey: metric, timePeriodKey: timePeriod, userId: ownerId })
        .then((res) => {
          if (!!res && !!res.data && !!res.data.attributes) {
            setValue(Number(res.data.attributes.value));
          } else {
            setValue(null);
          }
        })
        .catch((err) => {
          console.error('Cannot load user metric', err);
          setValue(null);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [program, metric, timePeriod, ownerId]);

  const content = React.useMemo(() => {
    let content = null;
    if (Array.isArray(children)) {
      content = { content: children };
    } else if (typeof children === 'object') {
      const isEditing = props['data-cms-editing'];
      if (isEditing) {
        content = { content: (children as any).props.components };
      } else {
        content = { content: (children as any).props.data };
      }
    }
    return content;
  }, [children]);

  if (loading) {
    return (
      <div className={className}>
        <span className="fa fa-circle-o-notch fa-spin" />
      </div>
    );
  }

  if (value == null) {
    return <div className={className} />;
  }

  const valueIcon = value == 0 ? iconNoChange : value > 0 ? iconUp : iconDown;

  return (
    <div className={className}>
      {content != null && <ComponentRenderer value={value} icon={`<span class="${valueIcon}"></span>`} data={content.content} />}
      {content == null && (
        <div className="value">
          <span className={valueIcon}></span> {value}%
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (getState) => ({
  ownerId: organizationSelector.selectMany(getState)[0]?.ownerUserId,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ getUserAccessMetricByMetricKey }, dispatch),
});
const mapContentToProps = (getContent) => ({
  program: getContent('program', { type: 'string', label: 'Program Key' }),
  metric: getContent('metric', { type: 'string', label: 'Metric Key' }),
  timePeriod: getContent('timePeriod', { type: 'string', label: 'Time Period Key' }),
  iconUp: getContent('iconUp', { type: 'string', label: 'Icon Up' }),
  iconDown: getContent('iconDown', { type: 'string', label: 'Icon Down' }),
  iconNoChange: getContent('iconNoChange', { type: 'string', label: 'Icon No Change' }),
});

interface StateProps {
  ownerId: number;
}
interface DispatchProps {
  actions: {
    getUserAccessMetricByMetricKey: Function;
  };
}
interface ContentProps {
  program: string;
  metric: string;
  timePeriod: string;
  iconUp?: string;
  iconDown?: string;
  iconNoChange?: string;
}
type Props = React.HTMLAttributes<any> & StateProps & DispatchProps & ContentProps;

export default register('rsv8-alcon/UserMetricChangeForTimePeriod')(
  connect<StateProps, DispatchProps>(mapStateToProps, mapDispatchToProps),
  withContent(mapContentToProps),
  withTheme(),
  withApiDataProvider(getOrganization, organizationSelector)
)(UserMetricChangeForTimePeriod);
