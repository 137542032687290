import { HeaderTwo } from 'rsv8-components';
import styled from 'styled-components';

const PointFilterContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  background: #eef5f7;
  padding: 25px 30px;

  label {
    font-weight: normal;
  }

  @media (max-width: 768px) {
    form {
      padding: 0;
    }
  }

  @media (min-width: 769px) {
    margin: 0 auto 60px auto;
    max-width: 750px;
    padding: 25px 71px;
  }

  button {
    margin-left: 25px;

    @media (max-width: 768px) {
      margin-left: 0;
      width: 100%;
    }
  }
` as any;

const BrowseHeading = styled(HeaderTwo)`
  color: #4c4f4f;
  font-size: 32px;
  margin: 5px 0 25px 0;
  text-align: center;
` as any;

const Divider = styled.div`
  border-top: solid 1px #dddddd;
  margin-bottom: 25px;

  @media (max-width: 768px) {
    display: none;
  }
` as any;

export { PointFilterContainer, BrowseHeading, Divider };
