export {
  IndividualAwardInstructions,
  IndividualCriteriaInstructions,
  IndividualDetailInstructions,
  IndividualNomineeInstructions,
  IndividualReviewInstructions,
  IndividualProductInstructions,
  IndividualCongratulationsInstructions
} from './IndividualInstructions';
export {
  TeamAwardInstructions,
  TeamProductInstructions,
  TeamCriteriaInstructions,
  TeamDetailInstructions,
  TeamNomineeInstructions,
  TeamReviewInstructions,
  TeamCongratulationsInstructions,
  TeamDetailsNomineeInstructions
} from './TeamInstructions';
