import { repeat, zipObj } from 'ramda';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { teamMemberActions, teamNominationActions } from '../../redux/actions/index';
import { teamNominationSelectors } from '../../redux/selectors/index';
import NomineeDetailFormHead from './NomineeDetailFormHead';
import TeamMemberDetailPanel from './NomineeDetailPanel';

export interface NomineeDetailFormProps {
  openOnError: boolean;
}

interface InternalProps extends NomineeDetailFormProps {
  currentNominees: Array<string>;
  hasAwardOptions: boolean;
  hasRoleOptions: boolean;
  awardOptions: Array<{ value: any; label: any }>;
  roleOptions: Array<{ id: any; name: string; description: string }>;
  defaultRole: any;
  defaultAward: any;
  actions: {
    changeAllAward: Function;
    changeAllRoles: Function;
    setDefaultRole: (value: any) => void;
    setDefaultAward: (value: any) => void;
  };
}

interface DetailFormState {
  nominees: Array<string>;
  allNomineeRole: number;
  allNomineeAward: string;
  allNominees: {
    [index: string]: boolean;
  };
}

class NomineeDetailForm extends React.Component<InternalProps, DetailFormState> {
  static getDerivedStateFromProps(nextProps: InternalProps, prevState: DetailFormState): Partial<DetailFormState> {
    const roleValues = nextProps.roleOptions.map((v) => v.id);
    return {
      allNomineeRole: roleValues.indexOf(prevState.allNomineeRole) === -1 ? roleValues[0] : prevState.allNomineeRole
    };
  }
  handleRoleChange = (val) => this.setState({ allNomineeRole: Number(val) });

  constructor(props: any) {
    super(props);
    this.state = { nominees: [], allNomineeRole: 0, allNomineeAward: '', allNominees: {} };
  }

  componentDidUpdate(prevProps: InternalProps, prevState: DetailFormState) {
    if (
      this.props.hasAwardOptions &&
      !this.props.defaultAward &&
      this.props.awardOptions[0].value !== prevProps.awardOptions[0].value
    ) {
      this.props.actions.setDefaultAward(this.props.awardOptions[0].value);
    }
    if (
      this.props.hasRoleOptions &&
      !this.props.defaultRole &&
      this.props.roleOptions[0].id !== prevProps.roleOptions[0].id
    ) {
      this.props.actions.setDefaultRole(this.props.roleOptions[0].id);
    }
  }

  handleApplyClick = () => {
    this.props.actions.changeAllAward(this.state.allNomineeAward, this.state.nominees);
    this.props.actions.changeAllRoles(this.state.allNomineeRole, this.state.nominees);
  };

  handleSelectNominee = (val, id) => {
    val
      ? this.setState({
          allNominees: { ...this.state.allNominees, [id]: true },
          nominees: this.state.nominees.concat([id])
        })
      : this.setState({
          allNominees: { ...this.state.allNominees, [id]: false },
          nominees: this.state.nominees.filter((n) => n !== id)
        });
  };

  handleSelectAllNominees = (val) => {
    this.setState({
      nominees: val ? this.props.currentNominees : [],
      allNominees: zipObj(this.props.currentNominees, repeat(!!val, this.props.currentNominees.length))
    });
  };
  getRoleOptions = () => {
    return this.props.roleOptions.map((role) => ({ value: role.id, label: role.name }));
  };
  renderNomineePanel = (id, key) => (
    <TeamMemberDetailPanel
      key={key}
      teamMemberId={id}
      selected={this.state.allNominees[id]}
      handleSelect={this.handleSelectNominee}
      openOnError={this.props.openOnError}
    />
  );

  render() {
    return (
      <React.Fragment>
        <NomineeDetailFormHead
          roleOptions={this.getRoleOptions()}
          handleApplyClick={this.handleApplyClick}
          hasRoleOptions={this.props.hasRoleOptions}
          handleRoleChange={this.handleRoleChange}
          handleSelectAll={this.handleSelectAllNominees}
        />
        {this.props.currentNominees.map(this.renderNomineePanel)}
      </React.Fragment>
    );
  }
}

const mapState = (state) => ({
  currentNominees: teamNominationSelectors.getCurrentNomineeIds(state),
  hasRoleOptions: teamNominationSelectors.getAwardHasRoles(state),
  roleOptions: teamNominationSelectors.getNomineeRoleOptions(state),
  defaultRole: teamNominationSelectors.getDefaultTeamMemberRole(state),
  defaultAward: teamNominationSelectors.getDefaultTeamMemberAward(state)
});

const mapDispatch = (dispatch) => ({
  actions: bindActionCreators(
    {
      changeAllRoles: teamMemberActions.setRole,
      changeAllAward: teamMemberActions.setAward,
      setDefaultRole: teamNominationActions.setDefaultRole,
      setDefaultAward: teamNominationActions.setDefaultAward
    },
    dispatch
  )
});

export default compose<any>(
  connect(
    mapState,
    mapDispatch
  )
)(NomineeDetailForm) as React.ComponentType<NomineeDetailFormProps>;
