import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import CodeLink from '../MenuItem/CodeLink';
import { MenuContext } from '../ModularMenus/NavigationContext';
const StyleAuthCodeLink = styled(CodeLink)`
  ${(props: any) => props.backgroundImage && `background-image: url("/clientassets/images${props.backgroundImage}");`};
` as any;
let AuthCodeLink = (props) => {
  return (
    <MenuContext.Consumer {...props}>
      {({ open, close, closeAll }: any) => {
        return (
          <StyleAuthCodeLink
            onMouseEnter={() => open(props.id)}
            onMouseLeave={() => close(props.id)}
            onClick={closeAll}
            href={props.targetUrl}
            className={props.className}
            backgroundImage={props.imageURL}
          >
            {props.label}
          </StyleAuthCodeLink>
        );
      }}
    </MenuContext.Consumer>
  );
};
const mapStateToProps = (state) => ({ routing: state.routing });
export default connect(mapStateToProps)(AuthCodeLink) as any;
