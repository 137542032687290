import styled from 'styled-components';

export const FitRow = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  height: 85%;
`;

/**
 * Filled Column
 * This component fills the whole width and height of its parent component.
 *
 * NOTE: In order to make work this component property you will need place it
 *       in a flex context. So its parent should be a flex box with position relative
 *       and make sure it inherits the height size
 */
export const FillColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 600px;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  align-content: flex-start;
  flex-direction: row;
`;

export const CroppedContent = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  display: block;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
`;
