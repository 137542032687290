import { TeamNomination } from '../../../types/index';
import * as events from '../../events';
import { teamNominationSelectors } from '../../selectors/index';

export const selectProductOptionPointValueBase = (appKey): TeamNomination.Action => {
  return {
    type: events.SELECT_PRODUCT_OPTION_POINT_VALUE,
    payload: appKey
  };
};

export const setTeamNominationPointValueId = (value) => ({
  type: events.SET_TEAM_NOMINATION_PRODUCT_VALUE,
  payload: value
});

const selectProductOptionPointValue = (value) => (dispatch, getState) => {
  const state = getState();
  if (teamNominationSelectors.getCurrentProductId(state) !== value) {
    dispatch(selectProductOptionPointValueBase(value));
  }
};

export default selectProductOptionPointValue;
