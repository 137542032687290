import * as React from 'react';
import { Button } from 'react-bootstrap';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReactField } from 'rsv8-forms';
import AssetAdminModal from '../../components/Modal';
import { setActiveModal } from '../../redux/actions';
import { getCurrentModal } from '../../redux/selectors';
import { AssetGrid } from '../AssetGrid';
import { FileUpload } from '../FileUpload';
import { RouteTree } from '../RouteTree';

interface SelectAsset {
  activeModal: any;
  actions?: {
    setActiveModal?: typeof setActiveModal;
  };
  currentDesktopUrl: string;
  onChange: Function;
}

class SelectAssetModal extends React.Component<SelectAsset> {
  state = { value: this.props.currentDesktopUrl || '', activeModal: '' };
  handleHide = () => {
    this.setState({ activeModal: '', file: {} });
  };

  handleButtonClick = (onImageClick) => {
    this.setState({ activeModal: 'insert-asset', file: onImageClick });

    this.props.onChange(onImageClick);
  };

  handleChange = (e) => {
    const value = e.target.value;
    this.setState({ state: value });
  };

  handleGridImageClick = (value) => {
    this.setState({ value: value.id, activeModal: '' });
    this.props.onChange(value.id);
  };

  handleBlur = () => {
    this.props.onChange(this.state.value);
  };

  render() {
    const { value, activeModal } = this.state;
    return (
      <div>
        <ReactField
          className="form-control"
          component="input"
          value={value}
          onBlur={this.handleBlur}
          onChange={(e) => this.setState({ value: e })}
        />
        <Button bsStyle="success" onClick={this.handleButtonClick}>
          Select Asset
        </Button>
        <AssetAdminModal
          handleHide={this.handleHide}
          show={activeModal === 'insert-asset'}
          modaleTitle="Select Asset"
          size="97%"
        >
          <Row>
            <Col sm={4}>
              <RouteTree />
            </Col>
            <Col sm={20}>
              <FileUpload />
              <AssetGrid onImageClick={this.handleGridImageClick} />
            </Col>
          </Row>
        </AssetAdminModal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setActiveModal
    },
    dispatch
  )
});

const mapStatetoProps = (state) => {
  return {
    activeModal: getCurrentModal(state)
  };
};

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(SelectAssetModal as any);
