import * as React from 'react';
import styled from 'styled-components';

const LoginMaxWidthBase = styled.div`
  @media screen and (min-width: 768px) {
    max-width: 329px;
  }
  @media (orientation: landscape) {
    max-width: none;
  }
  margin: auto;
`;

const LoginMaxWidth = (props) => <LoginMaxWidthBase {...props} />;

export default LoginMaxWidth;
