import { Patient } from 'xcel-api-generator/dist/alcon';
import { KEYSTONE_PATIENT_REDUCER_KEY, PATIENTS_REDUCER_KEY, PATIENT_REDUCER_KEY, PatientsStateProps } from '../../constants';

const initialState: PatientsStateProps = {
  patients: [],
  keystonePatients: 0,
};

const patientsReducer = (state: PatientsStateProps = initialState, action: { type: string; payload }) => {
  const { type, payload } = action;
  switch (type) {
    case PATIENT_REDUCER_KEY: {
      const patients = payload as Patient;
      return {
        ...state,
        patients: [
          ...state.patients.filter(
            (p) => !(p.productFamilyKey == patients.productFamilyKey && p.productTypeKey == patients.productTypeKey)
          ),
          patients,
        ],
      };
    }
    case PATIENTS_REDUCER_KEY: {
      const patients = payload as Patient[];
      return {
        ...state,
        patients: [...patients],
      };
    }
    case KEYSTONE_PATIENT_REDUCER_KEY: {
      const patients = payload as number;
      return {
        ...state,
        keystonePatients: patients,
      };
    }
    default:
      return state;
  }
};
export { patientsReducer };
