import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Card, Link, Text } from 'rsv8-components';
import styled from 'styled-components';
import { register, withContent } from 'xcel-react-core';
import { Calendar, CalendarSvg } from '../../components/Calendar';

const Container = styled.div`
  background: #cbe3e9;
  padding: 30px;
  height: 415px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
// interface Props {}
const Heading = styled(Text)`
  font-size: 21px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.24;
  letter-spacing: normal;
  color: #0b2265;
  text-align: left;
`;
const NominationSubmissions: React.FunctionComponent = (props) => {
  return (
    <Card>
      <Container>
        <Heading>Your Nomination Submissions</Heading>
        <div style={{ alignSelf: 'center', display: 'flex' }}>
          <CalendarSvg />
          <Calendar anchorId="calendarSvg" />
        </div>
        <Link
          to="/nominations/dashboard"
          themeVariation="secondary-alternate-button"
          style={{ width: '80%', alignSelf: 'center', textAlign: 'center' }}
        >
          Submit a Nomination
        </Link>
      </Container>
    </Card>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({}, dispatch)
});

export default register('rsv8-data-vis/NominationSubmissions')(
  withContent(),
  connect<any, any>(
    mapStateToProps,
    mapDispatchToProps
  )
)(NominationSubmissions);
