import { setValue } from 'xcel-util';
import { Dictionary, ThemeStyle } from '../types';
import themeCmsPlugin from './cms.plugin.actions';
import selector from './cms.plugin.selectors';
import * as events from './events';
import { getMediaQuery, getPseudoSelector } from './theme.selectors';
import { getTheme } from './theme.selectors';

export const componentActiveStyleSet = ({ name }) => ({
  type: events.COMPONENT_ACTIVE_STYLE_SET,
  name,
});
let updateTimeout;
export const componentStylePropertySet = ({ property, value }) => (
  dispatch,
  getState
) => {
  const state = getState();
  let theme = getTheme(state);
  let themeData: Dictionary<ThemeStyle> = selector.getTheme(getState());
  let activeSectors = getPseudoSelector(state);
  let activeMediaQuery = getMediaQuery(state);
  let name = theme.component.selectedStyle
    .replace(/\./g, '/')
    .replace(/=>/g, '.')
    .split('.');

  themeData = setValue(
    {},
    [
      ...name,
      'styles',
      theme.currentStyle,
      ...activeSectors,
      ...activeMediaQuery,
    ].join('|'),
    { [property]: value },
    '|'
  );
  dispatch(themeCmsPlugin.setProperty({ theme: themeData }));
  clearTimeout(updateTimeout);
};
export const componentStyleReset = () => (dispatch, getState) => {
  // this doesn't work right now so i'm turning it off. till i can fix it later.
  // const state = getState();
  // let theme = getTheme(state);
  // let themeData: Dictionary<ThemeStyle> = selector.getTheme(getState());
  // let name = theme.component.selectedStyle.replace(/\./g, '/').replace(/=>/g, '.');
  // let style = `${name}.styles.${theme.currentStyle}`;
  // themeData = setValue({ ...themeData }, style, {});
  // dispatch(themeCmsPlugin.setProperty({ theme: themeData }));
};

export const componentStylePseudoSelectorSet = ({ pseudoSelector }) => ({
  type: events.COMPONENT_STYLE_PSEUDO_SELECTOR_SET,
  pseudoSelector,
});
export const componentStyleMediaQuerySet = ({ mediaQuery }) => ({
  type: events.COMPONENT_STYLE_MEDIA_QUERY_SET,
  mediaQuery,
});
export const componentStyleSelect = ({ name }) => ({
  type: events.COMPONENT_STYLE_SELECT,
  name,
});
export const componentVariationSelect = variation => ({
  type: events.COMPONENT_VARIATION_SELECT,
  payload: variation,
});
export const createNewSelector = (selectors, component) => ({
  type: events.COMPONENT_CREATE_SELECTOR,
  selectors,
  component
})

export const componentVariationCreate = ({ name, variation }) => (
  dispatch,
  getState
) => {
  let themeData: Dictionary<ThemeStyle> = selector.getTheme(getState());
  let style = `${name.replace(/\./g, '/')}.styles.${variation}`;
  themeData = setValue(themeData, style, {});
  dispatch(themeCmsPlugin.setProperty({ theme: themeData }));
  clearTimeout(updateTimeout);
};
