import * as React from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { PointsLongHand } from 'rsv8-account';
import { Link } from 'rsv8-components';
import { ModularMenu } from 'rsv8-navigation';
import styled from 'styled-components';
import { register, withTheme } from 'xcel-react-core';
// import { CartIconWithCount } from 'rsv8-catalog';

const PrimaryNavStyledWrap = styled.div`
  border-bottom: 1px solid #e7e7e7;
`;

const PointCartWrapper = styled.div`
  height: 54px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 54px;
  a {
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

const Navigation = (props) => (
  <PrimaryNavStyledWrap className={props.className}>
    <Grid fluid={false}>
      <Row className="hidden-sm hidden-md hidden-lg hidden-xl">
        <Col xs={24}>
          <div className="hidden-sm hidden-md hidden-lg hidden-xl">
            <ModularMenu
              appKey="OHI_primary_mobile_replacement"
              template="PrimaryNavigationMobileIconNav"
              stickyScrollThreshold={100}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <div className="hidden-xs">
          <ModularMenu template="PrimaryNavigation" appKey="main" stickyScrollThreshold={100} xs={18}>
            <PointCartWrapper className="points-cart-wrapper">
              <Link to="/account/information" className="points">
                <PointsLongHand />
              </Link>
              {/* <CartIconWithCount className="cart" /> */}
            </PointCartWrapper>
          </ModularMenu>
        </div>
      </Row>
    </Grid>
  </PrimaryNavStyledWrap>
);

export default register('rsv8-app/Navigation')(withTheme())(Navigation);
