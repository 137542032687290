import { HeaderTwo } from 'rsv8-components';
import styled from 'styled-components';
import { CheckoutModuleHeading } from '../../components/Checkout/styles';

export const CheckoutConfirmHeading = styled(HeaderTwo)`
  text-align: center;
` as any;

export const CheckoutConfirmSubHeading = styled(CheckoutModuleHeading)`
  line-height: 1.45;
` as any;

export const GyftInstructionsContainer = styled.ul`
  padding-left: 20px;
` as any;

export const GyftInstructionItem = styled.li`
  margin-top: 20px;
` as any;

export const ConfirmationNumberContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 25px;
` as any;

export const GyftEmail = styled.div`
  margin: 20px 0;
` as any;
