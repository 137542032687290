import { awardSelectors, teamNominationSelectors } from '../../../selectors';
import { getAwards } from '../../getAwards/getApplications';

export const validateTeamMember = ( userId ) => async (dispatch, getState) => {
  const state = getState();
  const currentNomination = teamNominationSelectors.getCurrentAward(state);
  if(!currentNomination) {
    return true;  // this is done only for Individual nominations
  }
  await dispatch(getAwards( userId ));

  const app = awardSelectors.selectOne(getState(), currentNomination.id.toString());
  return app.isEligible;
};
