import * as React from 'react';
import { ApiDataProviderPagination as PagingData } from 'xcel-redux-orm';

// Use this in your wrapped components for max happy
export interface LoadMoreHOCParams {
  pagingData: PagingData;
  currentPage: number;
  isLoading: boolean;
  loadMoreAction: Function;
}

export interface ButtonTemplateProps {
  handleClick: Function;
  loading: boolean;
}
export { PagingData };

// SEE THE README.
export const loadMore = (pagingData: PagingData, currentPage: number, isLoading: boolean, loadMoreAction: Function) => (
  ButtonTemplate: any,
  LoadingTemplate?: any
) => {
  return class extends React.Component<any, any> {
    handlePageLoad = () => {
      if (this.hasAdditionalPages()) {
        loadMoreAction();
      }
    };

    hasAdditionalPages() {
      return !pagingData.isLastPage;
    }

    render() {
      if (isLoading && LoadingTemplate !== undefined) {
        return <LoadingTemplate />;
      } else {
        return this.hasAdditionalPages() ? (
          <ButtonTemplate handleClick={this.handlePageLoad} loading={isLoading} />
        ) : null;
      }
    }
  };
};

export default loadMore;
