import * as events from './events';
import { AlertState } from '../types';
export const ALERT_REDUCER_KEY = 'rsv8-components/Alert';

const alertReducer = (state = { alerts: [] } as AlertState, action) => {
  const { type, payload } = action;
  switch (type) {
    case events.CREATE_ALERT:
      const id = Math.random().toString();
      return { alerts: [...state.alerts, { id, ...payload }] };
    case events.REMOVE_ALERT:
      return { alerts: state.alerts.filter((alert) => alert.id !== payload) };
    default:
      return state;
  }
};

export default alertReducer;
