import * as React from 'react';
import styled from 'styled-components';

import IconDropDown from '../Icon/IconDropDown';

const AwardToggler = styled.div`
  display: block;
  height: 36px;
  width: 100%;
  border-top: solid 1px #d4d4d4;
  text-align: center;
  cursor: pointer;
`;

type BtnProps = {
  clickHandler: any;
  open: boolean;
};

const AwardButton = ({ clickHandler, open }: BtnProps) => {
  return (
    <AwardToggler onClick={clickHandler}>
      <IconDropDown open={open} />
    </AwardToggler>
  );
};

export default AwardButton;
