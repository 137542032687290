import { push } from 'connected-react-router';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Button,
  HeaderDecoration,
  HeaderOne,
  Image
  } from 'rsv8-components';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';
import { LayoutContainer } from '../../../components/Layout';

const LoginButton = styled(Button)`
  margin: auto;
  padding: 10px;
`;

const StyledCol = styled(Col)`
  text-align: center;
`;

const Confirmation = ({ handleRedirect, redirectPath, resource: { confirmationHeaderText, loginButtonText } }) => (
  <div>
    <HeaderDecoration />
    <Col xs={22} xsOffset={1} lg={22} lgOffset={1} md={22} mdOffset={1}>
      <Image themeVariation="primary-company-logo1" />
    </Col>
    <LayoutContainer>
      <Row>
        <Col xs={22} xsOffset={1} lg={8} lgOffset={8} md={7} mdOffset={8}>
          <Image themeVariation="icon-confirmation" />
        </Col>
      </Row>
      <Row>
        <Col xs={22} xsOffset={1} lg={12} lgOffset={6} md={22}>
          <HeaderOne textalign="center">{confirmationHeaderText}</HeaderOne>
        </Col>
      </Row>
      <Row>
        <StyledCol xs={10} xsOffset={7} lg={8} lgOffset={8} md={7} mdOffset={8}>
          <LoginButton type="button" onClick={handleRedirect}>
            {loginButtonText}
          </LoginButton>
        </StyledCol>
      </Row>
    </LayoutContainer>
  </div>
);

const mapState = () => ({});

const mapDispatch = (dispatch, ownProps) => ({
  handleRedirect: () => dispatch(push(ownProps.redirectPath || '/'))
});

const mapResourceToProps = (getResource) => ({
  resource: {
    confirmationHeaderText: getResource(
      'resetPasswordConfirmation.confirmationHeaderText',
      'You’ve successfully reset your password'
    ),
    loginButtonText: getResource('resetPasswordConfirmation.loginButtonText', 'Log in now')
  }
});

export default compose(
  withResource(mapResourceToProps),
  connect(
    mapState,
    mapDispatch
  )
)(Confirmation) as any;
