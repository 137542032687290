import { toPairs } from 'ramda';
import * as React from 'react';
import { compose } from 'redux';
import { withResource } from 'xcel-react-core';
import { Resources } from '../../types';
import { Award } from '../index';
import NominationCollapse from '../NominationCollapse/index';
import { Row } from './styles';

export interface AwardDetailsProps {
  awards?: Array<Resources.Award>;
}

interface Props extends AwardDetailsProps {
  awardDetails: any;
}

class AwardDetails extends React.Component<Props, { open: boolean }> {
  constructor(props: Props) {
    super(props);
    this.state = { open: false };
  }

  toggleOpen = () => this.setState({ open: !this.state.open });

  renderAward = ([key, award], i) => {
    return (
      <Row key={i}>
        <Award award={award} imageWidth="90px" />
      </Row>
    );
  };

  render() {
    return (
      <NominationCollapse title={this.props.awardDetails}>
        {toPairs(this.props.awards || {}).map(this.renderAward)}
      </NominationCollapse>
    );
  }
}

const mapResource = (getResource) => ({
  awardDetails: getResource('nominationProcess.awardDetailHeader', 'Award Details')
});

export default compose<any>(withResource(mapResource))(AwardDetails) as React.ComponentClass<AwardDetailsProps>;
