import * as React from 'react';
import * as Icon from 'react-fontawesome';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators, compose } from 'redux';
import { Drawer, HeaderFive, HeaderFour } from 'rsv8-components';
import { ReactField } from 'rsv8-forms';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';
import StyledRadioGroup from '../../../../components/Buttons/RadioGroup/StyledRadioGroup';
import { FieldContainer, FieldLabel } from '../../../../components/Label';
import { sortSearch } from '../../../../redux/actions';
import { DrawerButton, DrawerFooter } from '../../styles';

const SortContainer = styled.div`
  margin-left: 30px;
  margin-top: 25px;
` as any;

export interface SortDrawerProps {
  actions: {
    sortSearch: any;
  };
  cancelLabel: string;
  filterLabel: string;
  highLowSortLabel: string;
  history: any;
  lowHighSortLabel: string;
  popularSortLabel: string;
  searchSort: string;
  sortByLabel: string;
  toggleDrawer: any;
  isOpen: boolean;
  sortByTitle: string;
}

class SortDrawer extends React.Component<SortDrawerProps, any> {
  state = {
    mobileSort: '',
    sortGroup: [
      {
        label: <FieldLabel isRadio={true} labelName={this.props.popularSortLabel} />,
        value: 'none'
      },
      {
        label: <FieldLabel isRadio={true} labelName={this.props.lowHighSortLabel} />,
        value: 'Asc'
      },
      {
        label: <FieldLabel isRadio={true} labelName={this.props.highLowSortLabel} />,
        value: 'Desc'
      }
    ]
  };

  componentDidMount() {
    this.setState({ mobileSort: this.props.searchSort });
  }

  hideSortDrawer = () => {
    this.props.toggleDrawer(false);
    this.setState({
      mobileSort: this.props.searchSort
    });
  };

  submitSortDrawer = () => {
    this.props.toggleDrawer(false);
    this.props.actions.sortSearch(this.state.mobileSort, this.props.history);
  };

  handleMobileSortChange = (value) => {
    this.setState({ mobileSort: value });
  };

  render() {
    const { sortByTitle, sortByLabel, isOpen, toggleDrawer, cancelLabel, filterLabel } = this.props;

    return (
      <Drawer
        start="md"
        buttonText="Sort"
        buttonIcon="sort-amount-asc"
        onHide={() => toggleDrawer(false)}
        isOpen={isOpen}
        onOpen={() => toggleDrawer(true)}
      >
        <HeaderFour>
          <Icon name="sort-amount-asc" />
          {sortByTitle}
        </HeaderFour>
        <SortContainer>
          <HeaderFive>{sortByLabel}</HeaderFive>
          <FieldContainer>
            <ReactField
              component={StyledRadioGroup}
              name="mobileSort"
              options={this.state.sortGroup}
              onChange={this.handleMobileSortChange}
              value={this.state.mobileSort ? this.state.mobileSort : 'none'}
            />
          </FieldContainer>
        </SortContainer>
        <DrawerFooter>
          <DrawerButton onClick={this.hideSortDrawer}>{cancelLabel}</DrawerButton>
          <DrawerButton onClick={this.submitSortDrawer}>{filterLabel}</DrawerButton>
        </DrawerFooter>
      </Drawer>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({});

const mapResourceToProps = (getResource) => ({
  cancelLabel: getResource('catalog.search.cancelLabel', 'Cancel'),
  filterLabel: getResource('catalog.search.filterLabel', 'Filter'),
  sortByTitle: getResource('catalog.search.sortByTitle', 'Sort By'),
  sortByLabel: getResource('catalog.search.sortByMobileLabel', 'Sort your results by...'),
  popularSortLabel: getResource('catalog.search.popularSortLabel', 'Popular'),
  lowHighSortLabel: getResource('catalog.search.lowHighSortLabel', 'Point Value: Low to High'),
  highLowSortLabel: getResource('catalog.search.highLowSortLabel', 'Point Value: High to Low')
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      sortSearch
    },
    dispatch
  )
});

export default withRouter(
  compose<any>(
    connect(
      mapStateToProps,
      mapDispatchToProps
    ),
    withResource(mapResourceToProps)
  )(SortDrawer)
) as any;
