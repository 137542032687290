import * as React from 'react';
import {
  HeaderThree,
  Modal,
  ModalBody,
  ModalHeader,
  Text
  } from 'rsv8-components';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';

// Really, Facebook? https://github.com/facebook/react/issues/1881
const StyledHeaderThreee = styled(HeaderThree)`
  margin-bottom: 0px !important;
`;

// Take that, Facebook.
const DIV_STYLE = { marginTop: '30px' };

const WinnerDetailModal: React.SFC<any> = (props) => {
  const { winnerDetail } = props;

  if (winnerDetail === undefined) {
    return null;
  }
  const fields = [
    {
      label: props.labels.recipient,
      value: `${winnerDetail.firstName} ${winnerDetail.lastName}`
    },
    {
      label: props.labels.teamName,
      value: winnerDetail.teamName
    },
    {
      label: props.labels.criteria,
      value: winnerDetail.criteria
    },
    {
      label: props.labels.description,
      value: winnerDetail.description
    }
  ];

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <ModalHeader closeButton={true}>
        <StyledHeaderThreee>{props.labels.modalTitle}</StyledHeaderThreee>
      </ModalHeader>
      <ModalBody>
        {fields.map((field, index) => (
          <div key={`winnerDetailModal-${index}`} style={DIV_STYLE}>
            <Text themeVariation={`secondary-text-bold`}>{field.label}</Text>
            <br />
            <Text>{field.value}</Text>
          </div>
        ))}
      </ModalBody>
    </Modal>
  );
};

const mapResource = (getResource) => ({
  labels: {
    modalTitle: getResource('awardWinners.Modal.modalTitle', 'Award Details'),
    recipient: getResource('awardWinners.Modal.recipient', 'Recipient'),
    description: getResource('awardWinners.Modal.description', 'Description'),
    criteria: getResource('nominationActivity.criteriaTableHeader', 'Criteria'),
    teamName: getResource('nominationActivity.teamNameTableHeader', 'Team Name')
  }
});

export default withResource(mapResource)(WinnerDetailModal);
