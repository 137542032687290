import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { assetService } from 'xcel-asset-service';
import { register, withContent, withResource, withTheme } from 'xcel-react-core';
import { withApiDataProvider } from 'xcel-redux-orm';
import * as utils from '../../../utils';
import { fetchRedeemOption, saveMagnifeyeRedeem } from '../../redux/actions';
import { pointSummarySelector, redeemOptionSelector, redemptionHistorySelector } from '../../redux/selectors';
import RebateModal from '../RebateModal';
import { RedeemOptionContainer } from './styles';

export interface PrepaidCardProps {
  redeemOption: any;
  pointSummary: any;
  redemptionHistory: any;
  content: any;
  title: string;
  amountTitle: string;
  quantityTitle: string;
  submit: string;
  loadingDropdown: string;
  selectOption: string;
  currencySymbol: string;
  currencyAbbreviation: string;
  insufficientAmountText: string;
  actions: any;
  prepaidCardDescription: string;
  prepaidCardImage: string;
}

export const PrepaidCardContent: React.SFC<PrepaidCardProps> = ({
  actions,
  pointSummary,
  redemptionHistory,
  redeemOption,
  content,
  title,
  amountTitle,
  quantityTitle,
  submit,
  loadingDropdown,
  selectOption,
  currencySymbol,
  currencyAbbreviation,
  insufficientAmountText,
  prepaidCardDescription,
  prepaidCardImage,
  ...rest
}) => {
  const [amount, setAmount] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [available, setAvailable] = useState(0);
  const [productId, setProductId] = useState('');
  const calculateCost = () => amount * quantity;

  const [total, setTotal] = useState(calculateCost());

  useEffect(() => {
    setTotal(calculateCost());
  }, [amount, quantity]);

  useEffect(() => {
    const userPoints = pointSummary === undefined ? undefined : utils.calculateUserPoints(pointSummary.available);
    setAvailable(userPoints);
  }, [pointSummary]);

  const getCashValue = (points) => {
    return (points / 100).toFixed(2);
  };

  const selectedCardValue = (value) => {
    setAmount(value);

    const getSelectedOption = redeemOption.products.find((i) => i.points / 100 === value);
    const getSelectedOptionsProductId = getSelectedOption.productId;
    setProductId(getSelectedOptionsProductId);
  };

  const sortDropdownList = (option) => {
    const sortOptions = option.sort((a, b) => a.id - b.id);
    const sorted = sortOptions.map((i) => (
      <option key={i.id} id={i.productId} value={getCashValue(i.points)}>
        {i.name}
      </option>
    ));

    return sorted;
  };

  async function handleSubmit(event) {
    event.preventDefault();

    if (event !== undefined && event !== null) {
      const redemptionId = redeemOption.id;

      if (redemptionId && redemptionId !== null && redemptionId !== '') {
        const redemptionBody = {
          redeemOptionId: redemptionId,
          amount: amount,
          productId: productId,
          quantity: quantity,
        };

        await actions.saveMagnifeyeRedeem(redemptionBody);
      }
    }
  }

  if (!redeemOption || !redeemOption.canRedeem) {
    return null;
  }

  const imageUrl = prepaidCardImage ? assetService.getImage(prepaidCardImage) : `/clientassets/images/products/${redeemOption.imageUrl}`;

  return (
    <div {...rest}>
      <RedeemOptionContainer>
        <div className="redeem-option__image">{redeemOption && <img src={imageUrl} alt="" />}</div>

        <div className="redeem-option__details">
          <div className="redeem-option__title" dangerouslySetInnerHTML={{ __html: title }} />
          <div className="redeem-option__label">{amountTitle}</div>

          <div className="redeem-option__input-wrap">
            <select className="form-control" onChange={(e) => selectedCardValue(+e.target.value)}>
              {redeemOption && redeemOption.products ? (
                <option value={selectOption}>{selectOption}</option>
              ) : (
                <option value={loadingDropdown}>{loadingDropdown}</option>
              )}
              {redeemOption && redeemOption.products && sortDropdownList(redeemOption.products)}
            </select>
          </div>

          <div className="redeem-option__label">{quantityTitle}</div>

          <div className="redeem-option__input-wrap">
            <select className="form-control" name="" id="" value={quantity} onChange={(e) => setQuantity(+e.target.value)}>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
                <option key={i} value={i}>
                  {i}
                </option>
              ))}
            </select>
          </div>

          <div>
            {available !== 0 && available >= total && amount !== 0 && (
              <div className="redeem-option__total">
                {currencySymbol}
                {total.toFixed(2)} {currencyAbbreviation}
              </div>
            )}
          </div>

          <div>
            {available !== 0 && available < total && (
              <div className="redeem-option__warning">
                <i className="fa fa-exclamation-triangle" aria-hidden="true" />
                {insufficientAmountText}
              </div>
            )}
          </div>

          <div className="redeem-option__notes">
            {redeemOption && redeemOption.description && <div dangerouslySetInnerHTML={{ __html: prepaidCardDescription }} />}
          </div>

          <div
            onClick={handleSubmit}
            className={available !== 0 && available >= total && amount !== 0 ? 'redeem-option__btn' : 'redeem-option__btn__disabled'}
          >
            {submit}
          </div>
          {redemptionHistory !== undefined ? (
            <RebateModal
              image={imageUrl}
              value={redemptionHistory.faceValue}
              quantity={redemptionHistory.quantity}
              orderTotal={redemptionHistory.amount}
              confirmationNumber={redemptionHistory.id}
            />
          ) : null}
        </div>
      </RedeemOptionContainer>
    </div>
  );
};

export const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ saveMagnifeyeRedeem }, dispatch),
});

export const mapStateToProps = (state) => ({
  redeemOption: redeemOptionSelector.selectMany(state)[0],
  pointSummary: pointSummarySelector.selectMany(state)[0],
  redemptionHistory: redemptionHistorySelector.selectMany(state)[0],
});

export const mapContentToProps = (getContent) => ({
  insufficientAmountText: getContent('insufficientAmountText'),
  prepaidCardDescription: getContent('prepaidCardDescription'),
  prepaidCardImage: getContent('prepaidCardImage'),
});

const mapResourceToProps = (getResource) => ({
  title: getResource('PrepaidCard.title', 'Prepaid VISA Card'),
  amountTitle: getResource('PrepaidCard.amountTitle', 'Amount'),
  quantityTitle: getResource('PrepaidCard.quantityTitle', 'Quantity'),
  submit: getResource('PrepaidCard.submit', 'Submit'),
  loadingDropdown: getResource('PrepaidCard.loading', 'Loading...'),
  selectOption: getResource('PrepaidCard.selectOption', 'Select Amount'),
  currencySymbol: getResource('PrepaidCard.currencySymbol', '$'),
  currencyAbbreviation: getResource('PrepaidCard.currencyAbbreviation', 'USD'),
});

export default register('alcon-products/PrepaidCard')(
  connect(mapStateToProps, mapDispatchToProps),
  withApiDataProvider(() => fetchRedeemOption('product'), redeemOptionSelector),
  withTheme(),
  withContent(mapContentToProps),
  withResource(mapResourceToProps)
)(PrepaidCardContent);
