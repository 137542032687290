import { HeaderTwo, Link } from 'rsv8-components';
import styled from 'styled-components';

const PointTotalContainer = styled.div`
  float: right;

  @media (max-width: 768px) {
    order: 0;
  }
` as any;

const CheckoutButton = styled(Link)`
  background-color: #00a9e0;
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
  padding: 10px 25px;
  text-align: center;
  transition: background-color 0.2s linear;

  @media (max-width: 768px) {
    margin: 25px 0;
    order: 1;
  }
  @media (min-width: 769px) {
    float: right;
  }

  &:active,
  &:focus,
  &:hover,
  &:active:focus {
    background-color: #00a9e0;
    color: #fff;
    outline: none;
    text-decoration: none;
  }
` as any;

const FlexContainer = styled.div`
  @media (min-width: 769px) {
    margin-bottom: 150px;
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
` as any;

const CartHeading = styled(HeaderTwo)`
  color: #4c4f4f;
  display: inline-flex;
  font-weight: bold;
  margin-bottom: 25px;

  @media (min-width: 769px) {
    margin-bottom: 30px !important;
  }

  span {
    color: #b5be35;
    margin-right: 15px;
  }
` as any;

const EmptyStateText = styled.div`
  color: #7c8180;
  font-size: 20px;
  font-weight: bold;
` as any;

const CartHeadingIcon = styled.img`
  height: 29px;
  margin-right: 15px;
  width: 26px;
` as any;

export const EmptyCartImage = styled.img`
  width: 150px;
  height: auto;
  padding-bottom: 20px;
` as any;

export { CartHeading, CartHeadingIcon, CheckoutButton, EmptyStateText, FlexContainer, PointTotalContainer };
