import { push } from 'connected-react-router';
import * as React from 'react';
import { Grid } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { PageRenderer } from 'rsv8-cms';
import { toast } from 'rsv8-components';
import { Separator } from 'rsv8-components';
import { RecognitionButtons } from '../../components/index';
import { nominationActions } from '../../redux/actions/getApplication';
import { applicationSelector } from '../../redux/selectors/index';
import NominationBadges from './NominationBadges';

interface NominationDashboardProps {
  push: (path: string) => void;
  getNominationApplication: (id: any) => void;
  nominationApplications: any;
}

class NominationDashboard extends React.Component<NominationDashboardProps, {}> {
  state = { canNominate: false, showTeamButton: false };
  onViewAwards = () => undefined;
  onApproveNominations = () => undefined;
  onTrackNominations = () => undefined;

  async componentDidMount() {
    await this.props.getNominationApplication({});
    const canNominate = this.props.nominationApplications && this.props.nominationApplications.length > 0;
    if (!canNominate)
      toast({
        template: 'ContentToast',
        contentTemplate: (
          <PageRenderer
            path={'toast/nomination/nomination-error'}
            onNotFound={() => <PageRenderer path={`toast/nomination/nomination-error`} cultureId="0" />}
          />
        )
      });
    this.setState({ canNominate });
    this.handleTeamButton();
  }

  handleTeamButton() {
    const awardOptions = this.props.nominationApplications;
    if (awardOptions.find((option) => option.allowTeamNominations === true)) {
      this.setState({ showTeamButton: true });
    }
  }

  render() {
    return (
      <Grid>
        {this.state.canNominate && <RecognitionButtons showTeamButton={this.state.showTeamButton} />}
        <Separator />
        <NominationBadges
          onTrackNominations={this.onTrackNominations}
          onViewAwards={this.onViewAwards}
          onApproveNominations={this.onApproveNominations}
        />
      </Grid>
    );
  }
}

const mapState = (state, ownProps) => ({
  nominationApplications: applicationSelector.selectMany(state, null)
});

const mapDispatch = (dispatch) =>
  bindActionCreators({ push, getNominationApplication: nominationActions.getApplication }, dispatch);

export default compose<React.SFC<NominationDashboardProps>>(
  connect(
    mapState,
    mapDispatch
  )
)(NominationDashboard) as React.ComponentType<{}>;
