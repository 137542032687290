import { evolve } from 'ramda';
import { transformTypeSelector } from 'xcel-redux-orm';

const toDate = (s: string, locales?: string | string[]) => new Date(s).toLocaleDateString(locales);

const modifyCreateDate = evolve({ createDate: toDate }) as <P extends any>(v: P) => P;

const modifySelectorCreateDate = <P extends any>(selector: P): P =>
  transformTypeSelector(selector as any, modifyCreateDate) as any;

export const createSearchString = (v: any) =>
  `${v.firstName}${v.lastName}${v.applicationName}${v.status}${v.awardType}`;

export const createSearchFn = (search: string) => (v: any) => {
  return createSearchString(v).includes(search);
};

export const getPointRange = (application) => {
  if (!application.pointRange) return [];
  const { minimum, maximum, increment } = application.pointRange;
  return new Array((maximum - minimum) / increment + 1).fill(0).map((_, i) => {
    const value = i * increment + minimum;
    return { label: value.toString(), value };
  });
};
export { toDate, modifyCreateDate, modifySelectorCreateDate };
