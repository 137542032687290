import * as React from 'react';
import { Text } from 'rsv8-components';
import {
  ErrorIcon,
  FilterFormControl,
  FilterPointsButton,
  FormGroupDivider,
  PointFilterControlLabel,
  PointFilterForm,
  PointFilterFormGroup
  } from './styles';

interface PointRangeSearchProps {
  displayError: any;
  handleSubmit: any;
  minText: any;
  maxText: any;
  handleMaxChange: any;
  handleMinChange: any;
  buttonText: any;
  isFilter?: boolean;
  minVal: string;
  maxVal: string;
}

// TODO: Refactor -- AJ B.

class PointRangeSearch extends React.Component<PointRangeSearchProps, any> {
  displayErrorIcon = () =>
    this.props.displayError ? <ErrorIcon isFilter={this.props.isFilter} name="times-circle-o" size="2x" /> : null;

  render() {
    const {
      handleSubmit,
      displayError,
      minText,
      maxText,
      handleMaxChange,
      isFilter,
      handleMinChange,
      buttonText,
      minVal,
      maxVal
    } = this.props;
    return (
      <PointFilterForm inline={true} onSubmit={handleSubmit} className={displayError ? 'danger' : ''}>
        <PointFilterFormGroup controlId="formInlineMinVal">
          {this.displayErrorIcon()}
          <PointFilterControlLabel>
            <Text themeVariation="primary-table-heading">{minText}</Text>
          </PointFilterControlLabel>
          <FilterFormControl
            hasError={displayError}
            isFilter={isFilter}
            autoComplete="off"
            type="text"
            onChange={handleMinChange}
            placeholder=""
            value={minVal}
          />
        </PointFilterFormGroup>
        <FormGroupDivider>-</FormGroupDivider>
        <PointFilterFormGroup controlId="formInlineMaxVal">
          {this.displayErrorIcon()}
          <PointFilterControlLabel>
            <Text themeVariation="primary-table-heading">{maxText}</Text>
          </PointFilterControlLabel>
          <FilterFormControl
            hasError={displayError}
            isFilter={isFilter}
            autoComplete="off"
            type="text"
            onChange={handleMaxChange}
            placeholder=""
            value={maxVal}
          />
        </PointFilterFormGroup>
        <FilterPointsButton themeVariation="primary" type="submit">
          {buttonText}
        </FilterPointsButton>
      </PointFilterForm>
    );
  }
}

export default PointRangeSearch;
