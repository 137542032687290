 const TempGoals =
[
  {id: "NEWPCORDERS", type: "calculatorResult", attributes: {key: "NEWPCORDERS", value: "100"}},
  {id: "NEWCENROLLMENTS", type: "calculatorResult", attributes: {key: "NEWCENROLLMENTS", value: "100"}},
  {id: "CONSULTANTSTOECS", type: "calculatorResult", attributes: {key: "CONSULTANTSTOECS", value: "100"}},
  {id: "NEWPCENROLLMENTS", type: "calculatorResult", attributes:{key: "NEWPCENROLLMENTS", value: "100"}},
  {id: "COMPLETEPROFILE", type: "calculatorResult", attributes: {key: "TOTCOMPLETEPROFILEAL", value: "100"}},
  {id: "TOTAL", type: "calculatorResult", attributes: {key: "TOTAL", value: "1000"}}
];

const TempMetrics =[
  {id: "NEWPCORDERS", type: "calculatorResult", attributes: {key: "NEWPCORDERS", value: "100"}},
  {id: "NEWCENROLLMENTS", type: "calculatorResult", attributes: {key: "NEWCENROLLMENTS", value: "100"}},
  {id: "CONSULTANTSTOECS", type: "calculatorResult", attributes: {key: "CONSULTANTSTOECS", value: "100"}},
  {id: "NEWPCENROLLMENTS", type: "calculatorResult", attributes:{key: "NEWPCENROLLMENTS", value: "100"}},
  {id: "COMPLETEPROFILE", type: "calculatorResult", attributes: {key: "TOTCOMPLETEPROFILEAL", value: "100"}},
  {id: "TOTAL", type: "calculatorResult", attributes: {key: "TOTAL", value: "100"}}
];

const TempStatus =[{status:"pending",color:"red"},{status:"final",color:"orange"}];
export {TempGoals,TempStatus,TempMetrics}
