import * as React from 'react';
import { compose } from 'redux';
import {
  Button,
  HeaderThree,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text
  } from 'rsv8-components';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';
import { numberFormat } from 'xcel-util';

export interface Props {
  earning: any;
  visible: false;
  handleClose: Function;
  closeLabel: string;
  programLabel: string;
  pointReasonLabel: string;
  pointsLabel: string;
  descriptionLabel: string;
  title: string;
}

const Info = styled.div`
  padding: 15px 0;
`;

export const EarningInfoLabel = styled.div`
  color: #4c4f4f;
  flex-grow: 1;
  margin-bottom: 10px;
  overflow: hidden;
  width: 40%;

  @media (min-width: 769px) {
    width: 33%;
  }
` as any;

export const EarningInfoValue = styled.div`
  color: #4c4f4f;
  flex-grow: 1;
  margin-bottom: 10px;
  overflow: hidden;
  width: 60%;

  @media (min-width: 769px) {
    width: 67%;
  }
` as any;

class EarningDetailModal extends React.Component<Props> {
  renderModalBody() {
    const { earning, programLabel, pointReasonLabel, pointsLabel, descriptionLabel } = this.props;

    if (earning === undefined) {
      return null;
    }

    const fields = [
      {
        label: programLabel,
        value: earning.programName
      },
      {
        label: pointReasonLabel,
        value: earning.pointReasonCodeName
      },
      {
        label: pointsLabel,
        value: numberFormat(earning.points)
      },
      {
        label: descriptionLabel,
        value: earning.description
      }
    ];
    return (
      <Info>
        {fields.map((field, index) => (
          <React.Fragment key={`EarningDetailMomentField${index}`}>
            <EarningInfoLabel>
              <Text themeVariation={`secondary-text-bold`}>{field.label}</Text>
            </EarningInfoLabel>
            <EarningInfoValue>
              <Text themeVariation={`secondary-text`}>{field.value}</Text>
            </EarningInfoValue>
          </React.Fragment>
        ))}
      </Info>
    );
  }

  render() {
    return (
      <Modal onHide={this.props.handleClose} show={this.props.earning !== undefined}>
        <ModalHeader>
          <HeaderThree>{this.props.title}</HeaderThree>
        </ModalHeader>
        <ModalBody>{this.renderModalBody()}</ModalBody>
        <ModalFooter>
          <Button themeVariation={`secondary`} onClick={this.props.handleClose}>
            {this.props.closeLabel}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapResource = (getResource) => ({
  closeLabel: getResource('accountBalance.modal.modalCloseLabel', 'Close'),
  title: getResource('accountBalance.earningsModal.title', 'Earning Details'),
  programLabel: getResource('accountBalance.earningsModal.programLabel', 'Program'),
  pointReasonLabel: getResource('accountBalance.earningsModal.pointReasonLabel', 'Point Reason Code'),
  pointsLabel: getResource('accountBalance.earningsModal.pointsLabel', 'Points'),
  descriptionLabel: getResource('accountBalance.earningsModal.descriptionLabel', 'Description')
});

export default compose(withResource(mapResource))(EarningDetailModal as any);
