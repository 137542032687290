import { groupBy } from 'lodash';
import socialApiService from 'xcel-api-generator/dist/social';

interface RawActivityMetadata {
  availableUserActions: AvailableUserAction[];
}

export enum FeedType {
  Main = 'main',
  Shareables = 'shareables'
}

export interface RawShareableActivity {
  activityName: string;
  activityType: string;
  activityTypeMetadata: string; // RawUserAction
  allowDelete: boolean;
  content: AllTypesContent[];
  description: string;
  feedType: string;
  metadata: string; // RawMetadata
  path: string;
  publishedDateTimeUtc: string;
  title: string;
  userActions: any[];
}

export type AllTypesContent = LinkTypeContent & ContentTypeContent & ImageTypeContent;

export interface ImageTypeContent {
  component: string;
  content: {
    imgSrc: string;
    mimeType: string;
  };
}

export interface ContentTypeContent {
  component: string;
  is_active: boolean;
  content: {
    content: string;
    margin: string;
  };
}

export interface LinkTypeContent {
  component: string;
  children: AllTypesContent[];
  content: {
    themeVariation: string;
    href?: string;
  };
}

export interface AvailableUserAction {
  action: string;
  localAction: LocalAction[];
}

export interface LocalAction {
  cultureId: number;
  label: string;
  iconDefault: string;
  iconHover: string;
  iconClicked: string;
}

interface RawMetadata {
  groupKey: string;
  groupLabel: string;
}

export interface ShareableFeed {
  feedGroupId: string;
  feedGroupLabel: string;
  activities: ShareableFeedActivity[];
}

export interface LocalShareableFeed {
  feedGroupId: string;
  feedGroupLabel: string;
  activities: LocalShareableFeedActivity[];
}

interface ShareableFeedActivity extends ShareableFeedActivityInfo {
  actions: ActivityAction[];
}

interface ShareableFeedActivityInfo {
  id: string;
  activityName: string;
  activityType: string;
  allowDelete: boolean;
  title: string;
  publishedDateTimeUtc: string;
  feedType: string;
  description: string;
  imgSrc: string;
  href: string;
}

export interface LocalShareableFeedActivity extends ShareableFeedActivityInfo {
  actions: LocalActivityAction[];
}

export interface LocalActivityAction {
  id: string;
  label: string;
  iconDefault: string;
  iconHover: string;
  iconClicked: string;
}

interface ActivityAction {
  id: string;
  locals: {
    cultureId: number;
    label: string;
    iconDefault: string;
    iconHover: string;
    iconClicked: string;
  }[];
}

export async function getShareablesActivities(params: any = {}): Promise<ShareableFeed[]> {
  const result: RawShareableActivity[] = await socialApiService.getActivitiesByFeedType({
    ...params,
    feedType: FeedType.Shareables
  });
  return mapShareablesActivitiesResponse(result);
}

function mapShareablesActivitiesResponse(data: RawShareableActivity[]): ShareableFeed[] {
  const parsedData = data
    .map((item) => {
      return {
        ...item,
        metadata: parseMetada(item),
        activityTypeMetadata: parseActivityMetadata(item.activityTypeMetadata)
      };
    })
    .filter((item) => !!item.metadata);

  const grouped = groupBy(parsedData, (item) => item.metadata.groupKey);

  return Object.keys(grouped).map((key) => {
    const activities = grouped[key];
    return {
      feedGroupId: activities[0].metadata.groupKey,
      feedGroupLabel: activities[0].metadata.groupLabel,
      activities: activities
        .map((item) => ({
          id: item.path,
          activityName: item.activityName,
          activityType: item.activityType,
          allowDelete: item.allowDelete,
          title: item.title,
          publishedDateTimeUtc: item.publishedDateTimeUtc,
          feedType: item.feedType,
          description: item.description,
          imgSrc: parseImgSrc(item.content),
          href: parseLinkHref(item.content),
          actions: parseActions(item.activityTypeMetadata)
        }))
        .filter((item) => !!item.imgSrc)
    };
  });
}

function parseLinkHref(list: AllTypesContent[] = []): string {
  return (Array.isArray(list) ? list : []).reduce((acc, content) => {
    if (content.component !== 'rsv8-components/Link') {
      return acc;
    }
    return content.content.href || '';
  }, '');
}

function parseImgSrc(list: AllTypesContent[] = []): string {
  return (Array.isArray(list) ? list : []).reduce((acc, content) => {
    if (content.component !== 'rsv8-components/Image') {
      return acc;
    }
    return content.content.imgSrc || '';
  }, '');
}

function parseActions(data: RawActivityMetadata | null): ActivityAction[] {
  if (!data) {
    return [];
  }
  return data.availableUserActions.map((item) => ({
    id: item.action,
    locals: item.localAction.slice()
  }));
}

function parseMetada(activity: RawShareableActivity): RawMetadata | null {
  try {
    return JSON.parse(activity.metadata);
  } catch (err) {
    console.debug('An error occurred while parsing "metadata" field');
    console.error(err);
    return null;
  }
}

export function parseActivityMetadata(activityTypeMetadata: string = ''): RawActivityMetadata | null {
  try {
    return JSON.parse(activityTypeMetadata);
  } catch (err) {
    console.debug('An error occurred while parsing "activityTypeMetadata" field');
    console.error(err);
    return null;
  }
}
