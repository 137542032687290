import styled from 'styled-components';
import createFontStyle from '../../../util/createFontStyle';

const FontText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1;
  ${(props) => createFontStyle(props)};
  select {
    ${(props) => createFontStyle(props)};
  }
  span {
    ${(props) => createFontStyle(props)};
    strong {
      font-size: 12px;
    }
  }
` as any;

export default FontText;
