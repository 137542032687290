import { Panel } from 'rsv8-components';
import styled from 'styled-components';

export const CollapseContainer = styled.div`
  .thumbnail {
    padding: 0px;
    margin: 4px;
  }
`;

export const CollapsePanel = styled(Panel)`
  padding-left: 15px;
  padding-right: 15px;
`;

export const CollapseButton = styled.div`
  text-align: center;
  font-size: 16px;
  cursor: pointer;
`;
