export const ROOT_STATE = 'rsv8-achievement';
export const ACHIEVEMENT_STATE = 'achievement';
export const FAVORITE_STATE = 'favorites';
export const REDEEM_STATE = 'order';
export const AUTH_USER_STATE = 'rsv8-auth/user';

export const PROGRAM_JSON_TYPE = 'program';
export const PROGRAM_LEVEL_JSON_TYPE = 'programLevel';
export const REDEMPTION_ORDER_JSON_TYPE = 'redemptionOrder';
export const USER_PROFILE_JSON_TYPE = 'userProfileModel';
export const PRODUCT_LIST_JSON_TYPE = 'productListRollupResult';

export const GET_USER_FAVORITES_ENDPOINT = 'getProductlistByIdUser';
export const GET_PROGRAMS = 'getProductlistByIdUser';
export const GET_ACHEIVEMENT_PROGRAMS = 'getAchievementPrograms';
export const GET_ACHEIVEMENT_PROGRAM_LEVELS = 'getAchievementProgramsByIdLevel';
export const GET_REDEMPTION_BY_ID = 'getRedemptionsGetByIdById';
export const CREATE_REDEMPTION = 'createRedemptionsRedeemProduct';
