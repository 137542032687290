import * as React from 'react';
import { connect } from "react-redux";
import { useState, useEffect } from 'react';
import {Card,CardBody,Container,Image,Content} from 'rsv8-components'
import { getAccount, getPermissions } from "../../redux/actions";
import { withApiDataProvider } from "xcel-redux-orm";
import * as style from "./styles/orgStyles";
import { accountSelector } from "../../redux/selectors";
import { permissionsSelector } from "../../redux/selectors";
import {
  register,
  withContent,
  withTheme,
} from "xcel-react-core";
interface OrgProps {
  account: any;
  permissions: {[key: string]: boolean};
  cardPermissions?: {rule: string}[];
  cardTitle:string;
  cardContent:string;
  cardIconSrc:string;
}

const updateCard: React.SFC<OrgProps> = ({
  cardTitle="Need to make an update?",
  cardContent=`See something that isn’t correct? <a href="mailto:magnifeye@rewardstation.com">Click here</a> to contact our support team.`,
  cardIconSrc="/icons/icon-account-stars.svg",
  permissions,
  cardPermissions,
  account,
  ...rest
}) => {
  const [loading, setLoading]= useState(true);
  const [canUpdate,setcanUpdate] = useState(false);
  useEffect(() => {
    if (account) {
      const isValidPermission = cardPermissions ? !cardPermissions.some(({ rule }) => !permissions[rule]) : true;
      setcanUpdate(isValidPermission && account.map(element => element.canUpdate));
      setLoading(false);
    }
  }, [account, permissions]);

//  /// const renderLabel = (label) => <BarCellLabel>{label}</BarCellLabel>;
// //   const renderValue = (value) => <BarCellValue>{value}</BarCellValue>;
//   if(!loading) {
//     console.log(shipTo);
//    console.log(billTo);
//    console.log(affiliation)
//   }
  return (
    <div {...rest}>
    {loading && (
      <style.ShipToComponent className="row">
        <div className="col-lg-12">.col-sm-4</div>
        <div className="col-sm-4">.col-sm-4</div>
      </style.ShipToComponent>
    )}
    {!loading && canUpdate && (
      <Card themeVariation="with-shadow">
        <CardBody themeVariation="account-update-card">
          <Image imgSrc={cardIconSrc}/>
          <Container themeVariation="default">
            <h3 style={{fontFamily: 'open-sans-bold', color:'#505d6f', fontSize:'24px'}}>{cardTitle}</h3>
            <Content themeVariation="default" content={{content: cardContent}}/>
          </Container>
        </CardBody>
      </Card>
    )}
   </div>
  )
};

const mapStateToProps = state => {
  const permissionObj = permissionsSelector.selectMany(state)[0];
  return {
    account: accountSelector.selectMany(state),
    permissions: permissionObj ? permissionObj.permissions : {},
  };
};

/**
 * Content that the admin can enter to change this component
 * @param getContent 
 */
const mapContentToProps = (getContent) => ({
  cardTitle: getContent('cardTitle', {type: 'none', label: 'title for card '}),
  cardContent: getContent('carSubtitle', {type: 'none', label: 'subtitle for card '}),
  CardIconSrc: getContent('CarIconSrc', {type: 'none', label: 'href for card picture or icon'}), 
  cardPermissions: getContent("cardPermissions", {
    type: "array",
    schema: [
      { model: "rule", label: "Permission rule to check" },
    ]
  }),
});

export default register("alcon-information/updateCard")(
  connect(mapStateToProps),
  withApiDataProvider(getAccount, accountSelector),
  withApiDataProvider(getPermissions, permissionsSelector),
  withTheme(),
  withContent(mapContentToProps),
)(updateCard);
