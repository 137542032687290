import * as React from 'react';
import { connect } from 'react-redux';
import { Button, HeaderThree } from 'rsv8-components';
import { Amount } from 'xcel-api-generator/dist/alcon';
import { register, withContent, withResource, withTheme } from 'xcel-react-core';
import { withApiDataProvider } from 'xcel-redux-orm';
import { getCurrencyValue, getRoundedValue, getValueOrNull } from '../../../utils';
import { getMagnifeyeV2Summary } from '../../redux/actions';
import * as selectors from '../../redux/selectors';
import NoDataSpinner from '../NoDataSpinner';
import { OverlayPopover } from '../Overlay';
import { AlignedCol, ContentRow, HeaderRow, ProjectionValue, StyledHorizontalRule, StyledNames, StyledNote } from './styles';

const MyGoals: React.FC<Props> = ({
  className,
  resource: {
    goalColor = '#00AE44',
    totalValueLabel,
    totalValueNote,
    totalPatientsLabel,
    incrementalPatientsLabel,
    sectionNote,
    createGoalsBtnLabel,
  },
  title,
  infoIconText,

  target,
  runRate,
}) => {
  const toggleCreateGoalsVisibility = (event) => {
    event.preventDefault();
    try {
      document.getElementById('create-goals-visibility').click();
    } catch (e) {
      console.log('toggleCreateGoalsVisibility', e.message);
    }
  };

  if (!target || !runRate) {
    return <NoDataSpinner />;
  }

  return (
    <div className={className}>
      <HeaderRow>
        <AlignedCol xs={24}>
          <HeaderThree themeVariation="my-dashboard">
            {title + ' '}
            <OverlayPopover id="popover-my-goals" placement="bottom">
              {infoIconText}
            </OverlayPopover>
          </HeaderThree>
        </AlignedCol>
      </HeaderRow>

      <ContentRow>
        <AlignedCol xs={24} sm={22} smOffset={1}>
          <AlignedCol xs={22} xsOffset={1} sm={18} smOffset={3}>
            <StyledNames themeVariation="my-dashboard">{totalValueLabel}</StyledNames>
            <ProjectionValue color={goalColor} fontSize={26} lineHeight={26}>
              {getCurrencyValue(getValueOrNull(target.totalValue)) || '-'}
            </ProjectionValue>
            <StyledNote dangerouslySetInnerHTML={{ __html: totalValueNote }} />
          </AlignedCol>

          <AlignedCol xs={22} xsOffset={1}>
            <StyledHorizontalRule />
          </AlignedCol>

          <AlignedCol xs={16} xsOffset={4} sm={10} smOffset={1} md={10} mdOffset={1} lg={10} lgOffset={1}>
            <StyledNames themeVariation="my-dashboard" fontSize={16} lineHeight={18}>
              {totalPatientsLabel}
            </StyledNames>
            <ProjectionValue>{getRoundedValue(getValueOrNull(target.patients - runRate.patients), 1) || '-'}</ProjectionValue>
          </AlignedCol>

          <AlignedCol xs={16} xsOffset={4} sm={10} smOffset={2} md={10} mdOffset={2} lg={10} lgOffset={2}>
            <StyledNames themeVariation="my-dashboard" fontSize={16} lineHeight={18}>
              {incrementalPatientsLabel}
            </StyledNames>
            <ProjectionValue>
              {getRoundedValue(getValueOrNull(target.patientsPerRemainingWeek - runRate.patientsPerRemainingWeek), 1) || '-'}
            </ProjectionValue>
          </AlignedCol>

          <AlignedCol xs={20} xsOffset={2} sm={18} smOffset={3} lg={16} lgOffset={4}>
            <StyledNote dangerouslySetInnerHTML={{ __html: sectionNote }} margin="30px auto" />
          </AlignedCol>

          <AlignedCol xs={24}>
            <Button themeVariation="my-dashboard" onClick={toggleCreateGoalsVisibility}>
              {createGoalsBtnLabel}
            </Button>
          </AlignedCol>
        </AlignedCol>
      </ContentRow>
    </div>
  );
};

const mapContentToProps = (getContent) => ({
  title: getContent('title', { type: 'string', label: 'Title' }),
  infoIconText: getContent('infoIconText', { type: 'string', label: 'Info Icon Text' }),
});
const mapResourceToProps = (getResource) => ({
  resource: {
    goalColor: getResource('alcon.dashboard.myGoals.goalColor', '#00AE44'),
    totalValueLabel: getResource('alcon.dashboard.myGoals.totalValueLabel', 'Total Value Goal'),
    totalValueNote: getResource('alcon.dashboard.myGoals.totalValueNote', '(Sum of your <b>goal</b> margin, rebate, and price discounts)'),
    totalPatientsLabel: getResource('alcon.dashboard.myGoals.totalPatientsLabel', 'Total Incremental Patients from Current Projection'),
    incrementalPatientsLabel: getResource(
      'alcon.dashboard.myGoals.incrementalPatientsLabel',
      'Incremental Patients per Week (Weeks Remaining this Quarter)'
    ),
    sectionNote: getResource(
      'alcon.dashboard.myGoals.sectionNote',
      'The numbers in the My Goals section are calculated based on the goals you create using the button below.'
    ),
    createGoalsBtnLabel: getResource('alcon.dashboard.myGoals.createGoalsBtnLabel', 'CREATE YOUR GOALS'),
  },
});

export const mapStateToProps = (state) => ({
  target: selectors.performanceAmountSelector(state, 'Target'),
  runRate: selectors.performanceAmountSelector(state, 'Run Rate'),
});

interface StateProps {
  target: Amount;
  runRate: Amount;
}
interface ContentProps {
  title: string;
  infoIconText: string;
}
interface ResourceProps {
  resource: {
    goalColor: string;
    totalValueLabel: string;
    totalValueNote: string;
    totalPatientsLabel: string;
    incrementalPatientsLabel: string;
    sectionNote: string;
    createGoalsBtnLabel: string;
  };
}
type Props = StateProps & ContentProps & ResourceProps & React.HtmlHTMLAttributes<any>;

export default register('rsv8-alcon/MyGoals')(
  withApiDataProvider(getMagnifeyeV2Summary, selectors.performanceSelector),
  connect<StateProps>(mapStateToProps),
  withContent(mapContentToProps),
  withResource(mapResourceToProps),
  withTheme()
)(MyGoals);
