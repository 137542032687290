import {
  componentFromProp,
  compose,
  defaultProps,
  withProps
  } from 'recompose';
import { ExtractProps } from 'xcel-react-core';

export interface DangerousInterface {
  <P extends keyof JSX.IntrinsicElements = 'div'>(props: DangerousProps & JSX.IntrinsicElements[P]): JSX.Element;
  <P extends React.ComponentType<any>>(props: DangerousProps & ExtractProps<P>): JSX.Element;
}

export type DangerousProps = {
  html?: string;
  component?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
};

const Dangerous = (compose(
  defaultProps({ component: 'div' }),
  withProps((props: any) => ({ html: undefined, dangerouslySetInnerHTML: { __html: props.html } }))
)(componentFromProp('component')) as any) as DangerousInterface;

export { Dangerous };
