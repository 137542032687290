import { dashboardSelector } from "./summary";

export const shipToLocationSelector = (state) => {
  const dashboard = dashboardSelector.selectMany(state)[0];
  if (!dashboard || !dashboard.shipToLocations) return [];

  return Array.isArray(dashboard.shipToLocations)
    ? dashboard.shipToLocations
    : [dashboard.shipToLocations];
};
