import { ROOT_STATE } from '../constants';
export const getRootState = (state: any) => state[ROOT_STATE];
export const getContentState = (state: any) => {
  const data = getRootState(state);
  return data && data.content;
};
export const getContentTypeState = (state: any, contentTypeId: string) => {
  const data = getContentState(state);
  return (data && data[contentTypeId]) || {};
};
