import * as React from 'react';
import { ActivityAction } from '../../../../../api/delete-user-action';
import { AvailableUserAction, LocalAction } from '../../../../../api/get-shareables-activities';
import {
  CustomIcon,
  LabelText,
  LikeActionWrapper,
  StyledClickedLikeIcon,
  StyledDefaultLikeIcon
  } from './styles';

interface LikeActionProps {
  postPath: string;
  actionInProcess: boolean;
  userActions: any[];
  cultureId: number;
  availableUserActions: AvailableUserAction[];
  unlikeActivity: (path: string, params?: any) => void;
  likeActivity: (path: string, params?: any) => void;
}
export class LikeAction extends React.PureComponent<LikeActionProps> {
  render() {
    if (!this.availableLikeAction) {
      return null;
    }

    return (
      <LikeActionWrapper onClick={this.onClick} hoverUrl={this.localAction && this.localAction.iconHover}>
        {this.getIcon()}
        <LabelText className="label-text-likes-count">{this.likesCount}</LabelText>
      </LikeActionWrapper>
    );
  }

  private onClick = () => {
    if (this.props.actionInProcess) {
      return;
    }

    if (this.likedAlready) {
      this.props.unlikeActivity(this.props.postPath);
    } else {
      this.props.likeActivity(this.props.postPath);
    }
  };

  private getIcon() {
    const localAction = this.localAction;
    const shouldRenderCustomIcon =
      !!localAction && !!localAction.iconClicked && !!localAction.iconDefault && !!localAction.iconClicked;

    if (shouldRenderCustomIcon) {
      return (
        <CustomIcon
          className="custom-like-icon"
          url={this.likedAlready ? localAction.iconClicked : localAction.iconDefault}
        />
      );
    }

    if (this.likedAlready) {
      return <StyledClickedLikeIcon className="clicked-like-icon" />;
    }

    return <StyledDefaultLikeIcon className="default-like-icon" />;
  }

  private get likesCount(): number {
    return (this.props.userActions || []).reduce((acc, act) => {
      return act.actionId === ActivityAction.Like ? acc + 1 : acc;
    }, 0);
  }

  private get availableLikeAction(): AvailableUserAction | null {
    return this.props.availableUserActions.find((action) => action.action === ActivityAction.Like) || null;
  }

  private get localAction(): LocalAction | null {
    const action = this.availableLikeAction;
    const localLikeActions = action.localAction || [];
    return localLikeActions.find((act) => act.cultureId === this.props.cultureId) || localLikeActions[0] || null;
  }

  private get likedAlready(): boolean {
    const userActions = this.props.userActions || [];
    return userActions.reduce(
      (acc, act) => (act.actionId === ActivityAction.Like && !!act.isCurrentUser ? true : acc),
      false
    );
  }
}
