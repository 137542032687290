import * as React from 'react';
import { Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { register, withResource } from 'xcel-react-core';
import { HeaderOne, Text, Link } from "rsv8-components";
import { PaddedRow, CentredContainer, BigButton } from "./Elements";

const REDEEMED_AWARD_DESCRIPTION_TEXT = 'Thank you for your redeeming your digital gift card. You deserve it!\n' +
    'You will receive two emails: one with your order confirmation code and one with your\n' +
    'redemption code. All instructions and details will be included in those emails.\n' +
    'Contact support if you have any questions.';

interface InternalProps {
    resource: {
        viewNominationViaEmailButtonText: string;
        redeemedAwardViaEmailDescriptionText: string;
        redeemedAwardViaEmailHeader: string;
    };
}

const RedeemedAwardForm: React.SFC<InternalProps> = (props) => {

    return (
        <React.Fragment>
            <PaddedRow>
                <Col>
                    <CentredContainer>
                        <HeaderOne themeVariation="primary-link-3xl-bold-text">
                            {props.resource.redeemedAwardViaEmailHeader}
                        </HeaderOne>
                    </CentredContainer>
                </Col>
            </PaddedRow>

            <PaddedRow>
                <Col mdOffset={3} lgOffset={4} xs={24} md={18} lg={16}>
                    <Text themeVariation="center">{props.resource.redeemedAwardViaEmailDescriptionText}</Text>
                </Col>
            </PaddedRow>

            <PaddedRow>
               <CentredContainer>
                   <Link to={'/nominations/awards'}>
                       <BigButton>{props.resource.viewNominationViaEmailButtonText}</BigButton>
                   </Link>
               </CentredContainer>
            </PaddedRow>
        </React.Fragment>
    );
};

const mapResource = (getResource) => ({
    resource: {
        viewNominationViaEmailButtonText: getResource('nominations.viewNominationViaEmailButtonText', 'View nomination'),
        redeemedAwardViaEmailHeader: getResource('nominations.redeemedAwardViaEmailHeader', '{userName}, your redemption code is on its way!'),
        redeemedAwardViaEmailDescriptionText: getResource('nominations.redeemedAwardViaEmailDescriptionText', REDEEMED_AWARD_DESCRIPTION_TEXT),
    }
});

export default register('rsv8-nomination/RedeemedAwardForm')(
    connect(
        null
    ),
    withResource(mapResource),
)(RedeemedAwardForm);
