import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { register, withContent, withResource } from 'xcel-react-core';
import ItemCarousel from '../../components/ItemCarousel/ItemCarousel';
import { getFavoriteProducts, getTrendingByDepartment, getTrendingProducts } from '../../redux/actions';
import {
  getFavoriteList,
  getTrendingDepartmentProductList,
  getTrendingDepartmentRequest,
  getTrendingProductList,
  getTrendingRequest
  } from '../../redux/selectors';

interface TrendingCarouselProps {
  actions: {
    getFavoriteProducts: any;
    getTrendingByDepartment: any;
    getTrendingProducts: any;
  };
  allLink?: boolean;
  cardPath: string;
  cardText: string;
  catalogIcon: any;
  favoritesList: any;
  filterByPoints?: boolean;
  filterDepartment?: number;
  title: string;
  titleAlign?: boolean;
  titleSubtext?: string;
  trendingProducts: Array<any>;
  trendingLoaded: any;
}

class TrendingCarousel extends React.Component<TrendingCarouselProps, any> {
  componentDidMount() {
    const { filterDepartment } = this.props;

    filterDepartment
      ? this.props.actions.getTrendingByDepartment(filterDepartment)
      : this.props.actions.getTrendingProducts();

    this.props.actions.getFavoriteProducts({ id: 'favorite' });
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.filterDepartment !== this.props.filterDepartment) {
      this.props.actions.getTrendingByDepartment(this.props.filterDepartment);
      this.props.actions.getFavoriteProducts({ id: 'favorite' });
    }
  }

  render() {
    const { allLink, catalogIcon, trendingLoaded, favoritesList, trendingProducts, ...rest } = this.props;

    return (
      <ItemCarousel
        allLink={allLink ? `/catalog/favorites` : undefined}
        favoritesList={favoritesList}
        products={trendingProducts}
        cardIcon={catalogIcon}
        dataLoaded={trendingLoaded.succeeded}
        {...rest}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return ownProps.filterDepartment
    ? {
        favoritesList: getFavoriteList(state),
        trendingProducts: getTrendingDepartmentProductList(state),
        trendingLoaded: getTrendingDepartmentRequest(state)
      }
    : {
        favoritesList: getFavoriteList(state),
        trendingProducts: getTrendingProductList(state),
        trendingLoaded: getTrendingRequest(state)
      };
};

const mapResourceToProps = (getResource) => ({
  catalogIcon: getResource('catalog.catalogIcon', '/clientassets/images/icons/shop-emptystate.svg'),
  title: getResource('catalog.shopLanding.trendingCarouselTitle', 'Trending Rewards'),
  cardText: getResource('catalog.shopLanding.trendingCarouselCardText', 'Browse By Department')
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getTrendingProducts,
      getTrendingByDepartment,
      getFavoriteProducts
    },
    dispatch
  )
});

const mapContentToProps = (getContent) => ({
  cardPath: getContent('cardPath', { type: 'none' })
});

export default register('rsv8-catalog/TrendingCarousel')(
  connect(mapStateToProps, mapDispatchToProps),
  withContent(mapContentToProps),
  withResource(mapResourceToProps)
)(TrendingCarousel);
