import styled from 'styled-components';
import { HeaderTwo } from 'rsv8-components';

export const AchievementLevelsContent = styled.div``;

export const AchievementLevelsHeading = styled(HeaderTwo)`
  text-align: center;
  margin-bottom: 1.5rem;
`;
export const AchievementLevelCircles = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 3rem 0;
  margin: 0;
`;
export const AchievementLevelCirclesRow = styled.ul`
  list-style: none;
  display: flex;
  align-items: flex-start;
  justify-content: left;
  flex-wrap: nowrap;
  padding: 0;
  margin: 0;
`;

export const AchievementLevelCircle = styled.li`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  flex: 0 0 100px;
  border-radius: 100%;
  border: 1px solid #cbd1d0;
  color: #cbd1d0;
  position: relative;
  &.achieved_and_redeemed {
    border-color: rgba(0, 0, 0, 0.2);
    color: #fff;
  }
  &.achieved_not_redeemed {
    border-color: #0c0c0c;
    color: #fff;
  }
  &.next_to_be_achieved {
    border-color: #cbd1d0;
    color: #cbd1d0;
  }
  &.not_yet_achieved {
    border-color: rgba(0, 0, 0, 0.2);
    color: #cbd1d0;
  }
  & + & {
    margin-left: 1rem;
  }
`;

export const AchievementLevelCircleContent = styled.div`
  width: 90px;
  height: 90px;
  border-radius: 100%;
  font-size: 1.3rem;
  line-height: 1.2;
  text-align: center;
  padding: 0.25rem;
  background: #fafafa;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  .achieved_and_redeemed > & {
    background-color: #cbd1d0;
  }
  .achieved_not_redeemed > & {
    background-color: #0c0c0c;
  }
  .next_to_be_achieved > & {
    background-color: #fafafa;
  }
  .not_yet_achieved > & {
    background-color: #fafafa;
  }
`;

export const AchievementLevelCheckIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  width: 22px;
  border-radius: 100%;
  background: #e44c9a;
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.2);
`;
