import * as React from 'react';
import { connect } from 'react-redux';
import { ReactField } from 'rsv8-forms';
import { Loader } from 'rsv8-components';
import styled from 'styled-components';
import clientActions from '../../redux/actions/client';
import clientSelector from '../../redux/selectors/client';
import { sortClients } from '../../util';
const ClientItem = styled.li`
  span {
    color: black;
    text-decoration: none;
    font-size: 18px;
    cursor: pointer;
    display: inline-block;
  }
  font-weight: 700;
  list-style: none;
  padding: 2px 5px;
  border-radius: 5px;
  ${(props: any) => props.active && 'background-color:black; span{color:white;}'};
` as any;
const ClientContainer = styled.ul`
  padding: 0;
`;
class ClientView extends React.Component<any> {
  state = {
    search: ''
  };
  handleSearchChange = (value) => {
    this.setState({ search: value.trim() });
  };
  clientSet = (client) => (e) => {
    const { onClick, dispatch } = this.props;
    dispatch(clientActions.setActive(client));
    if (onClick) {
      onClick(client);
    }
  };
  render() {
    const { list, active } = this.props;

    return (
      <div>
        {list && (
          <ReactField component="bs-input" onChange={this.handleSearchChange} placeholder="Search for client..." />
        )}
        <ClientContainer>
          {list.length === 0 && <Loader />}
          {list &&
            list
              .sort(sortClients)
              .filter(
                (client) =>
                  this.state.search === '' || client.name.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1
              )
              .map((client, i) => {
                return (
                  <ClientItem key={i} active={client.id === active.id}>
                    <span onClick={this.clientSet(client)}>{client.name}</span>
                  </ClientItem>
                );
              })}
        </ClientContainer>
      </div>
    );
  }
}
const mapState = (state) => ({
  list: clientSelector.selectMany(state, null),
  active: clientSelector.getActive(state)
});
export default connect(mapState)(ClientView) as any;
