import * as t from '../events';
import { NominationDashboard } from '../../types';

const initialState: NominationDashboard.State = {
  nomination: {
    test: true
  },
  unfinishedNominationsCount: 0
};

function nominationDashboard(state: NominationDashboard.State = initialState, action: any): NominationDashboard.State {
  switch (action.type) {
    case t.NOMINATE_INDIVIDUAL:
      return {
        ...state,
        nomination: {
          test: true
        }
      };
    case t.NOMINATE_GROUP:
      return {
        ...state,
        nomination: {
          test: false
        }
      };
    default:
      return { ...state };
  }
}

export default nominationDashboard;
