import { Link } from 'rsv8-components';
import styled from 'styled-components';

const Brand = styled(Link)`
  color: #7c8180;
  font-size: 12px;
  text-decoration: none;

  &:active,
  &:focus,
  &:hover {
    color: #7c8180;
    text-decoration: none;
  }
` as any;

const ProductName = styled(Link)`
  color: #4c4f4f;
  display: block;
  text-decoration: none;
  padding-bottom: 10px;

  &:active,
  &:focus,
  &:hover {
    color: #4c4f4f;
    text-decoration: none;
  }
` as any;

const Details = styled(Link)`
  color: #4192a5;
  display: block;
  font-size: 14px;

  &:active,
  &:focus,
  &:hover {
    color: #4192a5;
    text-decoration: none;
  }
` as any;

const PointsNeeded = styled.div`
  color: #7c8180;
  font-size: 12px;
` as any;

const PointsNeededEmpty = styled(PointsNeeded)`
  height: 24px;
` as any;

const PointsRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-self: flex-end;
  margin-top: auto;
` as any;

const ImageLink = styled(Link)`` as any;

const ProductContainer = styled.div`
  width: 100%;
` as any;

const PrevPrice = styled.span`
  color: #d8374b;
  margin-left: 10px;
` as any;

const PromoDate = styled.div`
  color: #838887;
  margin-top: 5px;
` as any;

const CardImage = styled.div`
  background-image: url(${(props: any) => props.imageurl});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 150px;
  width: 150px;
` as any;

export const ImageContainer = styled.div`
  margin: 0 auto 15px auto;
`as any;

export {
  CardImage,
  PointsRow,
  PrevPrice,
  PointsNeededEmpty,
  PromoDate,
  PointsNeeded,
  ProductContainer,
  ImageLink,
  Details,
  ProductName,
  Brand
};
