import * as React from 'react';
import * as PropTypes from 'prop-types';
import { createChainedFunction } from 'react-bootstrap/lib/utils';
class NavItem extends React.Component<any> {
  static propTypes = {
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    role: PropTypes.string,
    href: PropTypes.string,
    onClick: PropTypes.func,
    onSelect: PropTypes.func,
    eventKey: PropTypes.any
  };
  static defaultProps = {
    active: false,
    disabled: false
  };

  handleClick = (e) => {
    if (this.props.disabled) {
      e.preventDefault();
      return;
    }

    if (this.props.onSelect) {
      this.props.onSelect(this.props.eventKey, e);
    }
  };

  render() {
    const { active, disabled, onClick, className, style, ...props }: any = this.props;

    delete props.onSelect;
    delete props.eventKey;

    // These are injected down by `<Nav>` for building `<SubNav>`s.
    delete props.activeKey;
    delete props.activeHref;

    if (!props.role) {
      if (props.href === '#') {
        props.role = 'button';
      }
    } else if (props.role === 'tab') {
      props['aria-selected'] = active;
    }
    return (
      <li role="presentation" className={`${className} ${active && 'active'} ${disabled && 'disabled'}`} style={style}>
        <a disabled={disabled} onClick={createChainedFunction(onClick, this.handleClick)} {...props} />
      </li>
    );
  }
}

export default NavItem;
