import * as React from 'react';
import { connect } from 'react-redux';
import { ReactField } from 'rsv8-forms';
import { getValue } from 'xcel-util';
import { contentSelector } from '../../../../../../redux';

export interface ComponentVariationSelectProps {
  name: string;
  theme: any;
  content: any;
  onChange?: any;
  dispatch: any;
}
const ThemeVariationSelect = ({
  name,
  theme,
  content,
  onChange,
  dispatch,
  ...props
}: ComponentVariationSelectProps) => {
  const themeComponentStyles = getValue(theme, `theme.${name}.styles`);
  const options = themeComponentStyles
    ? Object.keys(themeComponentStyles)
        .sort()
        .map((key) => ({ value: key, label: key }))
    : [];

  options.forEach(function(item: any, i: number) {
    if (item.value === 'default') {
      options.splice(i, 1);
      options.unshift(item);
    }
  });

  const handleChange = (value) => {
    // need to do something here.
    if (onChange) {
      onChange(value);
    }
  };
  return (
    <ReactField
      label="Theme Variation"
      value={(content && content.themeVariation) || 'default'}
      options={options}
      component="bs-select"
      onChange={handleChange}
    />
  );
};
const mapStateToProps = (state) => ({
  theme: contentSelector.getEditContentProperties(state, 'Theme')
});

export default connect(mapStateToProps)(ThemeVariationSelect);
