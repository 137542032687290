import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withResource } from 'xcel-react-core';
import { ForgotUserPass } from '../../../components/Layout';

const EmailNotFound = ({ loginHelpLinkPath, resource: { headerText } }) => (
  <ForgotUserPass loginHelpLinkPath={loginHelpLinkPath} headerText={headerText} />
);

const mapState = () => ({});

const mapDispatch = (dispatch) => ({});

const mapResourceToProps = (getResource) => ({
  resource: {
    headerText: getResource('emailNotFound.headerText', 'We’re sorry but there is no account associated to that email.')
  }
});

export default compose(
  withResource(mapResourceToProps),
  connect(
    mapState,
    mapDispatch
  )
)(EmailNotFound) as any;
