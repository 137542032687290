import * as React from 'react';
import { Link } from 'react-router-dom';
import { register, withTheme } from 'xcel-react-core';
import { MenuContext } from '../ModularMenus/NavigationContext';

const MenuItem = (props) => {
  const { href, children, id, ...rest } = props;
  return (
    <MenuContext.Consumer {...props}>
      {({ open, close, closeAll }: any) => {
        const handleMouseEnter = (e) => {
          open(id);
        };

        const handleMouseLeave = (e) => {
          close(id);
        };

        let parsedMeta = props.meta ? JSON.parse(props.meta) : {};

        // Doesnt look like this is doing anything -- from AJ
        // let imageURL = '';
        // if (parsedMeta !== null) {
        //   imageURL = parsedMeta.menuItemImage;
        // }

        const stopOnTouchPropagation = (e) => {
          e.stopPropagation();
        };
        return (
          <li {...rest} className={props.className}>
            {href ? (
              <Link
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={closeAll}
                to={href}
                onTouchStart={stopOnTouchPropagation}
                target={parsedMeta.linkTarget || null}
              >
                {children}
              </Link>
            ) : (
              <a>{children}</a>
            )}
          </li>
        );
      }}
    </MenuContext.Consumer>
  );
};
const NavItem = register('rsv8-navigation/MenuItem')(withTheme())(MenuItem);

export { MenuItem, NavItem };
