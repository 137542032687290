import * as React from 'react';
import { connect } from 'react-redux';
import { FabButton } from 'rsv8-components';
import themeCmsPlugin from '../../redux/cms.plugin.actions';
const PublishButton = ({ dispatch }) => (
  <FabButton className="btn btn-primary" title="Publish" onClick={() => dispatch(themeCmsPlugin.publish())}>
    <i className="fa fa-cloud-upload" aria-hidden="true" />
  </FabButton>
);
export default connect()(PublishButton);
