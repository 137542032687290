import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { clientSelector } from 'rsv8-client';
import styled, { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { assetService } from 'xcel-asset-service';
import { getTheme } from '../../redux/theme.selectors';
const convertRelativeUrls = (fontFace: string) => {
  let urlRegex = /url\(\s*['"]([^'"]+)['"]\s*\)/g;
  const matches = fontFace.match(urlRegex);
  if (!matches) return fontFace;
  let urls = matches.map((url) => url.replace(urlRegex, '$1'));
  fontFace = urls.reduce((str, url) => {
    str = str.replace(url, assetService.getFont(url));
    return str;
  }, fontFace);
  return fontFace;
};
const GlobalStyledThemeProvider = styled(StyledThemeProvider)`
  ${(props: any) => {
    return (
      props.fonts &&
      Object.keys(props.fonts)
        .map((key) => props.fonts[key])
        .filter((font) => font.face !== undefined)
        .map((font) => convertRelativeUrls(font.face))
    );
  }};
` as any;

const ThemeProvider = ({ processed, client, fonts, children, ...rest }) => {
  return (
    <GlobalStyledThemeProvider fonts={fonts} {...rest} theme={processed || {}}>
      {children}
    </GlobalStyledThemeProvider>
  );
};
const mapStateToProps = (state) => ({
  processed: getTheme(state).processed,
  fonts: getTheme(state).fonts,
  client: clientSelector.getActive(state)
});
export default withRouter(connect(mapStateToProps)(ThemeProvider as any) as any);
