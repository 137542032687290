export const NAVIGATION_MENU_REQUEST = 'NAVIGATION_MENU_REQUEST';
export const NAVIGATION_MENU_SUCCESS = 'NAVIGATION_MENU_SUCCESS';
export const NAVIGATION_MENU_FAILURE = 'NAVIGATION_MENU_FAILURE';

export const NAVIGATION_MENU_TOGGLE = 'NAVIGATION_MENU_TOGGLE';
export const NAVIGATION_MENU_CLOSE = 'NAVIGATION_MENU_CLOSE';

export const NAVIGATION_MENU_UPDATE = 'NAVIGATION_MENU_UPDATE';

export const NAVIGATION_MENU_ADMIN_REQUEST = 'NAVIGATION_MENU_ADMIN_REQUEST';
export const NAVIGATION_MENU_ADMIN_SUCCESS = 'NAVIGATION_MENU_ADMIN_SUCCESS';
export const NAVIGATION_MENU_ADMIN_FAILURE = 'NAVIGATION_MENU_ADMIN_FAILURE';

export const NAVIGATION_MENU_ADMIN_UPDATE = 'NAVIGATION_MENU_ADMIN_UPDATE';
export const NAVIGATION_MENU_ADMIN_DELETE = 'NAVIGATION_MENU_ADMIN_DELETE';

export const NAVIGATION_MENU_ADMIN_LOOKUP_SUCCESS = 'NAVIGATION_MENU_ADMIN_LOOKUP_SUCCESS';

export const READ_ONLY_ADMIN_MENU_SUCCESS = 'READ_ONLY_ADMIN_MENU_SUCCESS';

export const NAVIGATION_TEMP_STORE_SUCCESS = 'NAVIGATION_TEMP_STORE_SUCCESS';

export const NAVIGATION_MENU_ADMIN_APPLICATION_SUCCESS = 'NAVIGATION_MENU_ADMIN_APPLICATION_SUCCESS';

export const NAVIGATION_MENU_ADMIN_GROUP_MENU_SUCCESS = 'NAVIGATION_MENU_ADMIN_GROUP_MENU_SUCCESS';

export const POINT_REQUEST = 'POINT_REQUEST';
export const POINT_SUCCESS = 'POINT_SUCCESS';
export const POINT_FAILURE = 'POINT_FAILURE';

export const GET_NAVIGATION_MENU_REQUEST = 'nav/GET_NAVIGATION_MENU_REQUEST';
export const GET_NAVIGATION_MENU_SUCCESS = 'nav/GET_NAVIGATION_MENU_SUCCESS';
export const GET_NAVIGATION_MENU_FAILURE = 'nav/GET_NAVIGATION_MENU_FAILURE';

export const SHOW_BOOTSTRAP_ALERT = 'SHOW_BOOTSTRAP_ALERT';

export const SHOW_LAST_FORM = 'SHOW_LAST_FORM';

export const NAVIGATION_MENU_ADMIN_PROVIDER_REQUEST = 'NAVIGATION_MENU_ADMIN_PROVIDER_REQUEST';
export const NAVIGATION_MENU_ADMIN_PROVIDER_SUCCESS = 'NAVIGATION_MENU_ADMIN_PROVIDER_SUCCESS';
export const NAVIGATION_MENU_ADMIN_PROVIDER_FAILURE = 'NAVIGATION_MENU_ADMIN_PROVIDER_FAILURE';

export const NOTIFICATIONS_GET = 'NOTIFICATION_CENTER_GET';
