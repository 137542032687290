import * as React from 'react';
import { Icon } from 'rsv8-components';
import WizardLinkWrapper from './WizardLinkWrapper';

export interface WizardLinkProps extends React.HTMLProps<{}> {
  stepName: string;
  visible?: boolean;
}

const WizardLink: React.SFC<WizardLinkProps> = ({ children, stepName, visible = true, ...rest }) =>
  visible ? (
    <WizardLinkWrapper name={stepName} {...rest}>
      <Icon icon="pencil" />
      {'  '}
      {children}
    </WizardLinkWrapper>
  ) : null;

export default WizardLink;
