import AuthRoute from './app/auth/AuthRoute';
import SSOLoadingPage from './app/auth/Login/Login';
import OAuth from './app/auth/Login/OAuth';
import OAuthProvider from './app/auth/OAuthProvider';
import RegisterByCode from './app/auth/RegisterByCode/RegisterByCode';
import { ForgotUsernameRoutes, ResetPasswordRoutes } from './app/auth/Routes';
import SessionExpireWarning from './app/auth/SessionExpireWarning/SessionExpireWarning';
import SessionWatcher from './app/auth/SessionWatcher';
import authPath from './app/paths/Auth';
import userPath from './app/paths/User';
import userActions from './app/redux/actions/user';
import { authActions, authEvents, authModule } from './authModule';
import AccessKeyLoader from './components/AccessKeyLoader';
import AuthLinkBehavior from './components/AuthLinkBehaviour';
import { UpdatePendo } from './components/Pendo';
import { authApiProvider } from './config/authApiProvider';
import { discoveryApiProvider } from './config/discoveryApiProvider';
import registerAuthValidators from './config/registerAuthValidators';
import { withAuth } from './config/withAuth';
import authMiddleware from './middleware/authMiddleware';
import clientMiddleware from './middleware/clientMiddleware';

import {
  AUTH_STATE,
  getAuth,
  getLastUpdateDate,
  getSession,
  getUser,
  isAdminUser,
  SESSION_STATE,
  USER_STATE,
  getClientId
} from './app/redux/selectors';

const authStateSelectors = {
  getAuth,
  AUTH_STATE,
};
const sessionStateSelectors = {
  getSession,
  getLastUpdateDate,
  SESSION_STATE,
};
const userStateSelectors = {
  isAdminUser,
  getUser,
  USER_STATE,
};

export {
  userActions,
  authActions,
  authEvents,
  AuthLinkBehavior,
  UpdatePendo,
  getClientId,
  authMiddleware,
  clientMiddleware,
  AccessKeyLoader,
  authModule,
  SessionWatcher,
  OAuthProvider,
  authApiProvider,
  withAuth,
  AuthRoute,
  registerAuthValidators,
  authStateSelectors,
  sessionStateSelectors,
  userStateSelectors,
  discoveryApiProvider,
  authPath,
  userPath,
  ResetPasswordRoutes,
  ForgotUsernameRoutes,
  SSOLoadingPage,
  RegisterByCode,
  SessionExpireWarning,
  OAuth,
};
