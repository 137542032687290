import * as React from 'react';
import { Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Icon, PanelBody, Text } from 'rsv8-components';
import { ReactField } from 'rsv8-forms';
import { withResource } from 'xcel-react-core';
import { Div, NomineeImage, TeamMemberDeleteButton } from '../../../components/index';
import { nomineeSelectors } from '../../../redux/selectors/index';
import { Resources } from '../../../types';

export interface DetailPanelHeadProps {
  teamMemberId: string;
  selectNominee: (val: any) => void;
  changeRole: (val: any) => void;
  changeAward: (val: any) => void;
  selected: boolean;
  displayEdit: boolean;
  handleEdit: (v: any) => void;
  deleteNominee: () => void;
  hasAwardOptions: boolean;
  hasRoleOptions: boolean;
  awardOptions: Array<{ value: any; label: any }>;
  roleOptions: Array<{ value: any; label: any }>;
  teamMemberRole: string;
  teamMemberAward: string;
}

interface Props extends DetailPanelHeadProps {
  roleLabel: any;
  selectRoleLabel: any;
  selectAwardValueLabel: any;
  editLabel: any;
  awardLabel: any;
  nominee: Resources.Nominee;
}

const addInitialOption = (label, arr) => {
  return [].concat([{ value: '', label: label }], ...arr);
};

const DetailPanelHead: React.SFC<Props> = (props) => {
  return (
    <PanelBody>
      <Col xs={props.hasAwardOptions || props.hasRoleOptions ? 8 : 12}>
        {props.hasAwardOptions || props.hasRoleOptions ? (
          <Div display="inline-block">
            <ReactField name="selected" component="checkbox" onChange={props.selectNominee} value={props.selected} />
          </Div>
        ) : null}
        <Div display="inline-block">
          <NomineeImage nominee={props.nominee} caption={true} orientation="right" width="45px" />
        </Div>
      </Col>
      {props.hasRoleOptions ? (
        <Col xs={8}>
          <ReactField
            name="role"
            component="bs-select"
            label={props.roleLabel}
            initialValue={props.roleOptions[0].value}
            value={props.teamMemberRole}
            onChange={props.changeRole}
            options={addInitialOption(props.selectRoleLabel, props.roleOptions)}
          />
        </Col>
      ) : null}
      {props.hasAwardOptions ? (
        <Col xs={8}>
          <ReactField
            name="award"
            component="bs-select"
            label={props.awardLabel}
            initialValue={props.awardOptions[0].value}
            value={props.teamMemberAward}
            onChange={props.changeAward}
            options={addInitialOption(props.selectAwardValueLabel, props.awardOptions)}
          />
        </Col>
      ) : null}
      <Div float="right">
        {props.displayEdit ? (
          <Text themeVariation="primary-link" onClick={props.handleEdit}>
            <Icon icon="pencil" /> {props.editLabel}
          </Text>
        ) : null}
        <TeamMemberDeleteButton id={props.teamMemberId} />
      </Div>
    </PanelBody>
  );
};

const mapState = (state, ownProps: DetailPanelHeadProps) => ({
  nominee: nomineeSelectors.selectOne(state, ownProps.teamMemberId)
});

const mapResource = (getResource) => ({
  awardLabel: getResource('nominationProcess.awardValueLabel', 'Award Value'),
  roleLabel: getResource('nominationProcess.roleLabel', 'Role'),
  selectRoleLabel: getResource('nominationProcess.selectRoleLabel', 'Select Role'),
  selectAwardValueLabel: getResource('nominationProcess.selectAwardLabel', 'Select Award Value'),
  editLabel: getResource('nominationProcess.editLabel', 'Edit')
});

export default compose(
  withResource(mapResource),
  connect(mapState)
)(DetailPanelHead) as React.ComponentType<DetailPanelHeadProps>;
