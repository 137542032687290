import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ensureArray } from 'xcel-util/dist';
import { NomineeSearchResults } from '../../components/index';
import { teamMemberActions, teamNominationSelectors } from '../../redux/index';

export interface NomineeDisplayProps {}

interface InternalProps extends NomineeDisplayProps {
  currentNominees: string | Array<string>;
  selectNominee: any;
  loadingNominees: Array<string>;
}

const NomineeDisplay: React.SFC<InternalProps> = (props) => {
  const currentNominees = ensureArray(props.currentNominees);
  return (
    <NomineeSearchResults
      currentNominees={currentNominees}
      selectNominee={props.selectNominee}
      loadingNominees={props.loadingNominees}
    />
  );
};

const mapState = (state) => ({
  currentNominees: teamNominationSelectors.getCurrentNomineeIds(state),
  loadingNominees: teamNominationSelectors.getLoadingNominees(state)
});

const mapDispatch = (dispatch) => bindActionCreators({ selectNominee: teamMemberActions.select }, dispatch);

export default connect(
  mapState,
  mapDispatch
)(NomineeDisplay as any) as React.ComponentClass<NomineeDisplayProps>;
