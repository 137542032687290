import * as React from 'react';
import imageApi from '../../../../api';
import AvatarEditor from './AvatarEditor';
import DragAndDrop from './DragAndDrop';
import {
  AvatarBody,
  AvatarHeader,
  AvatarModalContainer,
  StyledInput,
  StyledLabel
  } from './styles/index';

interface ImageWasSelected {
  file: Object;
  data?: any;
}

export interface AvatarModalProps {
  dispatch: Function;
  getNewImage: Function;
  onClick: boolean;
  className?: string;
  imageApi: any;
  droppedImage: ImageWasSelected;
  labels: any;
}

export interface AvatarModalState {
  open: boolean;
  userProfilePic: string;
  editor: any;
  scaleValue: number;
  rotateValue: number;
  selectedImage: ImageWasSelected;
}

export class AvatarModal extends React.Component<AvatarModalProps, AvatarModalState> {
  state = {
    open: false,
    userProfilePic: '',
    editor: null,
    scaleValue: 2,
    rotateValue: 0,
    selectedImage: null,
    droppedImage: null
  };

  setEditorRef = (editor) => this.setState({ editor });

  profileImageChange = (fileChangeEvent) => {
    const file = fileChangeEvent.target.files[0];
    const { type } = file;
    if (type.endsWith('jpeg') || type.endsWith('png') || type.endsWith('jpg')) {
      this.setState({ selectedImage: file });
    }
  };

  dropImageChange = (fileChangeEvent) => {
    const file = fileChangeEvent;
    const { type } = file;
    if (type.endsWith('jpeg') || type.endsWith('png') || type.endsWith('jpg')) {
      this.setState({ selectedImage: file });
    }
  };

  // Convert URL format to blob for formData consumption
  urlToBlob = (url) => {
    const byteString = atob(url.split(',')[1]);
    const mimeString = url
      .split(',')[0]
      .split(':')[1]
      .split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    let i;
    for (i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const bb = new Blob([ab], { type: mimeString });

    return bb;
  };

  onCrop = async (props) => {
    const { editor } = this.state;
    if (editor != null) {
      // Convert Canvas to URL format
      const url = editor.getImageScaledToCanvas().toDataURL();
      // If you want to view image in modal below crop tool - Uncomment below
      // this.setState({ userProfilePic: url });

      const blob = this.urlToBlob(url);
      const formData = new FormData();
      formData.append('file:', blob);

      // Remove Save button - Add Spinner
      document.getElementById('cropButtonSubmit').style.display = 'none';
      document.getElementById('cropButtonLoader').style.display = 'inline-block';

      // Make POST call and send to S3
      try {
        await imageApi.createImage(
          {
            file: url,
            data: formData
          },
          {
            config: {
              headers: { 'Content-Type': 'multipart/form-data' }
            }
          }
        );
        this.setState({ open: !this.state.open });
        this.props.getNewImage();
      } catch (err) {
        this.setState({ open: !this.state.open });
        alert("We're sorry. This file cannot be uploaded right now. Please try again.");
        if (err.response === undefined) {
          return err; // catch code errors
        } else {
          return err.response.data.errors[0]; // catch server errors
        }
      }
    }
  };

  onCancel = () => {
    this.setState({ selectedImage: null });
  };

  onScaleChange = (value: number) => {
    this.setState({ scaleValue: value });
  };

  onRotateChange = (value: number) => {
    this.setState({ rotateValue: value })
  };

  toggleState = () => this.setState({ open: !this.state.open });

  displayModalBody = (selectedImage) => {
    if (selectedImage === null) {
      return (
        <div>
          <DragAndDrop type="file" name="selectedDrop" droppedImage={this.dropImageChange} />
          <div>
            {this.props.labels.imageRequirements}
          </div>
          <StyledLabel className="btn btn-primary selectFile">
            {this.props.labels.selectFile}
            <StyledInput type="file" name="selectedButton" onChange={this.profileImageChange} />
          </StyledLabel>
        </div>
      );
    } else {
      return (
        <AvatarEditor
          className={this.props.className}
          imageSrc={this.state.selectedImage}
          setEditorRef={this.setEditorRef}
          onCrop={this.onCrop}
          onCancel={this.onCancel}
          scaleValue={this.state.scaleValue}
          rotateValue={this.state.rotateValue}
          onScaleChange={this.onScaleChange}
          onRotateChange={this.onRotateChange}
        />
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="imageCircle" onClick={this.toggleState}>
          <i className={this.props.labels.iconCameraClassName} />
          <span>{this.props.labels.addPhoto}</span>
        </div>
        <AvatarModalContainer show={this.state.open} onHide={this.toggleState}>
          <div className={this.props.className}>
            <AvatarHeader className="avatarHeader" closeButton={true}>
              {this.props.labels.dialogLabel}
            </AvatarHeader>
          </div>
          <AvatarBody className="avatarBody">{this.displayModalBody(this.state.selectedImage)}</AvatarBody>
          <img id="croppedAvatar" src={this.state.userProfilePic} />
        </AvatarModalContainer>
      </React.Fragment>
    );
  }
}

export default AvatarModal;
