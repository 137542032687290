import { getConfigurationProperty } from 'xcel-config';
import { cacheService } from 'xcel-cache-service';
import { authObserver, reduxObserver } from 'xcel-observer';
import { onSelectorChange } from 'xcel-react-core';
import { cachePlugin, createReduxModule } from 'xcel-redux-module';
import { queryString } from 'xcel-util';
import reducers from './app/redux';
import * as auth from './app/redux/auth.actions';
import * as authEvents from './app/redux/events';
import { getAuth, getUser } from './app/redux/selectors';
import * as user from './app/redux/user.actions';
import userActions from './app/redux/actions/user';
import { changeFirstVisitState } from './app/redux/auth.actions';
import OAuthService from "./services/OAuthService";
import { push } from "connected-react-router";

const authActions = {
  auth,
  user
};
// handle side effects here
const authModule = cachePlugin(cacheService)(
  createReduxModule({
    name: 'rsv8-auth',
    init: async ({ store }) => {
      const now = new Date();
      const currentAuth = getAuth(store.getState());

      const identityServerConfigData = OAuthService.getIdentityServerConfigData();
      if ((!currentAuth || !currentAuth.isValid || new Date(currentAuth.expireDate) < now) && !identityServerConfigData) {
        store.dispatch(auth.authTokenBasicCreate());
      }

      if (identityServerConfigData) {
        OAuthService.initializeUserManager();
        const { apiHost } = identityServerConfigData;
        const userData = { clientId: apiHost.clientId };
        if (!currentAuth || !currentAuth.isValid || !currentAuth.isOAuth || new Date(currentAuth.expireDate) < now) {
          store.dispatch(changeFirstVisitState(true));
          store.dispatch(userActions.setData(userData));
          const { pathname } = store.history.location;
          if (!OAuthService.isCurrentOAuthRoute(pathname) && !OAuthService.isPublicRoute(pathname)) {
            store.dispatch(push(store.history.location.search ? `/oauth${store.history.location.search}` : '/oauth'));
          }
        } else {
          try {
            await OAuthService.userManager.getUser();
          } catch (e) {
            await OAuthService.userManager.clearStaleState();
            store.dispatch(changeFirstVisitState(true));
            store.dispatch(userActions.setData(userData));
            store.dispatch(push('/logout'));
          }
        }
      }

      if (
        !queryString.getParam('error') &&
        (currentAuth.doingGigyaSSOSync === false || !currentAuth.doingGigyaSSOSync)
      ) {
        store.dispatch(auth.authDoGigyaSSOSync());
      }

      const authChange = onSelectorChange(store, getAuth);
      const userChange = onSelectorChange(store, getUser);
      store.subscribe(() => {
        userChange((current, next) => {
          if (next && next.reportsOnly === 'True') {
            const accessToken = getAuth(store.getState()).accessToken;
            const redirect = getConfigurationProperty(['clients', next.clientId, 'reportsOnly']) as string;
            if (redirect) {
              window.location.href = redirect.replace('{accessToken}', accessToken);
            }
          }
        });
        authChange((current, next) => {
          if (current === undefined || (current.accessToken !== next.accessToken && next.isValid)) {
            // not sure why reportsOnly had to be a string and not just a boolean.
            authObserver.addAuth(next);
          }
          if (
            current &&
            next &&
            next.doingGigyaSSOSync === false &&
            current.doingGigyaSSOSync === next.doingGigyaSSOSync
          ) {
            store.dispatch(auth.authDoGigyaSSOSync());
          }
        });
      });
    },
    reducers
  })
) as any;

reduxObserver.addModule(authModule);
export { authModule, authEvents, authActions };
