import * as React from 'react';
import MenuItemForm from './MenuItemForm';

let AddMenuItemForm = (props) => {
  return (
    <MenuItemForm {...props}>
      {() => {
        return '';
      }}
    </MenuItemForm>
  );
};
export default AddMenuItemForm;
