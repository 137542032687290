import { Button, Container, Content, HeaderTwo, Image, Text } from 'rsv8-components';
import styled from 'styled-components';

export const CalculatorMarginTop = styled.div`
  margin: 100px 0 50px;
` as any;

export const CalculatorContainer = styled.div`
  width: 1200px;
  vertical-align: middle;
  text-align: center;
  align-self: center;
  align-items: center;
  align-content: center;
  box-shadow: 0px 2px 17px 2px rgba(28, 39, 76, 0.08);
  border-radius: 5px;
  border-collapse: separate;
  background-color: #ffffff;
  margin: 40px auto 0;
  padding-top: 20px;
  @media (max-width: 1440px) {
    width: 90%;
  }
` as any;

export const RebateCalculatorForm = styled.form`
  width: 100%;
  height: 100%;
  border: 0px;
  vertical-align: middle;
  text-align: center;
  background-color: transparent;
  @media (max-width: 1440px) {
    margin: 0%;
    width: 100%;
    height: 100%;
  }
  @media (max-width: 425px) {
    margin: 0%;
    width: 100%;
    height: 100%;
  }
` as any;

export const SaveOrShareCard = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  vertical-align: bottom;
  text-align: center;
  border: 0px transparent;
  padding: 10px 30px 30px;
  border-radius: 0px;
` as any;

export const SaveOrShareNote = styled.span`
  text-align: left;
  font-size: 10px;
  letter-spacing: 0;
  color: #505d6f;
  opacity: 1;
` as any;

export const SaveOrShareTable = styled.div`
  width: 100%;
  height: 100%;
  max-width: 725px;
  background-color: #fafafa;
  vertical-align: top;
  text-align: center;
  border: 0px transparent;
  border-radius: 0px;
  margin: 0 auto;
  padding: 0;
` as any;

export const SummaryStatisticsCard = styled.div<{ textAlign: string }>`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  vertical-align: bottom;
  text-align: ${(props: any) => (props.textAlign ? props.textAlign : 'center')};
  border: 0px;
  border-radius: 0px;
  padding: 0 47px 0 40px;
  margin-bottom: 20px;
` as any;

export const ProductSummaryInfoIconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  .overlay-popover-icon {
    margin-left: 10px;
    position: static;
  }
` as any;

export const ScrollableDataCard = styled.div`
  width: 100%;
  height: 345px;
  vertical-align: middle;
  text-align: center;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 0 0px 0 rgba(80, 93, 111, 0.25);
  background-color: #ffffff;
  padding: 0 40px 30px;
  border: 0px;
  ::-webkit-scrollbar {
    width: 7px;
    border-radius: 3.5px;
    background-color: #e1e1e1;
  }
  ::-webkit-scrollbar-track {
    border-radius: 3.5px;
    background-color: #ffffff;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 3.5px;
    background-color: #e1e1e1;
  }
  @media only screen and (max-width: 767px) {
    padding: 0 20px 20px;
  }
` as any;

export const ProductTable = styled.div`
  border: 0px;
  background-color: transparent;
  border-radius: 0px;
  vertical-align: middle;
  text-align: center;
  width: 100%;
` as any;

export const ProductTableWithBorder = styled(ProductTable)`
  border: 1px solid #ffffff;
` as any;

export const ProductCardDiv = styled.div`
  background: transparent;
  /*padding-left: 0px;
  padding-right: 0px;*/
  padding: 0px;
  vertical-align: middle;
  text-align: center;
` as any;

export const ProductTableRow = styled.div`
  display: flex;
  background-color: transparent;
  width: 100%;
` as any;

export const ProductTableCell = styled.div`
  display: flex;
  align-items: center;
  background-color: transparent;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.5px;
  border: 0px;
  padding: 0px;
  vertical-align: middle;
  text-align: center;
` as any;

export const SummaryStatisticsRow = styled(ProductTableCell)`
  position: relative;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 1024px) {
    flex-wrap: wrap;
    justify-content: center;
  }
` as any;

export const ShareOrSaveButtons = styled.div`
  display: flex;
  justify-content: center;
` as any;

export const ShareOrSaveTableCell = styled.div`
  background-color: transparent;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  border: 0;
  padding: 5px;
  vertical-align: middle;
  text-align: center;
` as any;

export const SaveOrShareTitle = styled.h4`
  width: 100%;
  height: 29px;
  font-family: open-sans-bold;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #505d6f;
  padding: 0;
  margin: 14px 0 10px;
  background-color: transparent;
` as any;

export const SaveOrShareDescription = styled(Text)`
  width: 100%;
  font-family: open-sans;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.5px;
  text-align: center;
  color: #657386;
  display: block;
  margin-bottom: 20px;
  white-space: normal;
  background-color: transparent;
` as any;

export const SaveButton = styled.button`
  width: 265px;
  height: 50px;
  border-radius: 25px;
  background-color: #003595;
  font-family: open-sans;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 1.5px;
  text-align: center;
  color: #ffffff;
  margin-right: 10px;
  border: 0;
  outline: none;
` as any;

export const SaveButtonMobile = styled.button`
  width: 265px;
  height: 50px;
  border-radius: 25px;
  background-color: #003595;
  font-family: open-sans;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 1.5px;
  text-align: center;
  color: #ffffff;
  border: 0;
  outline: none;
` as any;

export const ShareButton = styled.button`
  width: 250px;
  height: 50px;
  border-radius: 25px;
  border: solid 2px #00aeef;
  font-family: open-sans;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 1.5px;
  text-align: center;
  background-color: transparent;
  color: #00aeef;
  margin-left: 10px;
  outline: none;
` as any;

export const ShareButtonMobile = styled.button`
  width: 250px;
  height: 50px;
  border-radius: 25px;
  border: solid 2px #00aeef;
  font-family: open-sans;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 1.5px;
  text-align: center;
  background-color: transparent;
  color: #00aeef;
  outline: none;
` as any;

export const HiddenReCalculateButton = styled.button`
  display: none;
` as any;

export const ReCalculateButton = styled.button`
  width: 180px;
  height: 50px;
  padding: 0px;
  margin: 0px;
  border-radius: 25px;
  border: solid 2px #00aeef;
  font-family: open-sans-bold;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 1.5px;
  text-align: center;
  background-color: transparent;
  color: #00aeef;
  outline: none;
  :hover {
    border: solid 2px #b2e6fa;
    color: #b2e6fa;
  }
  @media (max-width: 1024px) {
    display: none;
  }
` as any;

export const MobileReCalculateButton = styled.button`
  width: 100%;
  height: 100px;
  border-radius: 0px;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background-color: rgba(255, 255, 255, 0.5);
  font-family: open-sans-bold;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.08;
  margin: 0px;
  padding: 0px;
  letter-spacing: 1.6px;
  text-align: center;
  vertical-align: middle;
  color: #00aeef;
  outline: none;
  border: 0;
  margin-top: -35px;
  /* :hover {
    border: solid 2px #b2e6fa;
    color: #b2e6fa;
  } */
  /* use z index and margin to move above the rows*/
  @media (max-width: 425px) {
    width: 100%;
  }
` as any;

export const AutoPopulateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fafafa;
  border-radius: 5px;
  margin: -20px 0 20px;
  padding: 30px;
`;

// Market Share Calculator Styles Start Here

export const CalculatorWrapper = styled.div`
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: #ffffff;
  overflow: hidden;
`;

export const WrapperPadding = styled.div`
  padding: 20px;
  @media screen and (min-width: 768px) {
    padding: 30px 50px;
  }
  @media screen and (min-width: 1366px) {
    padding: 40px 100px;
  }

  .total-estimated-quarterly-rebate {
    text-align: center;
    margin: 15px auto 0;
    span:last-of-type {
      margin: 10px auto 0;
    }
  }
  @media (min-width: 768px) {
    .total-estimated-quarterly-rebate {
      span:last-of-type {
        max-width: 270px;
      }
    }
  }
`;

export const CalculatorForm = styled.form`
  text-align: center;
  margin-bottom: 40px;
`;

export const Title = styled(HeaderTwo)`
  font-size: 28px !important;
  line-height: 34px !important;
  text-align: center;
  max-width: 530px;
  margin: 0 auto !important;
`;

export const HowRetabesNote = styled(Content)`
  text-align: center;
  margin: 30px auto !important;
`;

export const ValueWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 40px auto !important;

  @media (max-width: 767px) {
    gap: 16px;
  }

  & > div {
    text-align: center;
    align-self: end;
    width: 200px;
    padding: 0 10px;
  }
  & > div > div {
    width: 115px;
    margin-left: auto;
    margin-right: auto;
  }
  .share-goal {
    & > div {
      position: relative;
      &::before {
        content: '%';
        position: absolute;
        top: 6px;
        right: 10px;
      }
    }
  }
  .sales-goal {
    & > div {
      position: relative;
      &::before {
        content: '$';
        position: absolute;
        top: 6px;
        left: 10px;
      }
    }
  }
`;

export const InputLabel = styled(Text)`
  font-size: 16px !important;
  line-height: 20px !important;
`;

export const InputLabelWide = styled(Text)`
  font-size: 16px !important;
  line-height: 20px !important;
  letter-spacing: 2px;
  @media (max-width: 767px) {
    display: block;
  }
`;

export const InputValueContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #b7bcbf;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 5px 10px;
  margin-top: 10px;
  .form-group {
    margin-bottom: 0;
    flex-grow: 1;
  }
  input {
    width: 100%;
    border: 0;
    border-bottom: 1px solid #707070;
    text-align: center;
    &.form-control {
      font-size: 16px !important;
      line-height: 24px !important;
      padding: 0;
      height: auto;
      border-radius: 0;
      box-shadow: none;
    }
  }
  /* Close number buttons */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  min-height: 40px;
`;
export const ValueContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #efefef;
  font-size: 16px !important;
  line-height: 23px !important;
  text-align: center;
  border-radius: 10px;
  padding: 5px 10px;
  margin-top: 10px;
  min-height: 40px;
`;

export const Result = styled(Text)`
  font-size: 16px !important;
  line-height: 20px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: #00aeef;
  color: #ffffff !important;
  min-height: 40px;
`;

export const CalculateButton = styled(Button)`
  display: block;
  margin: 30px auto;
`;

export const InfoGraphic = styled(Container)`
  overflow: hidden;
  text-align: center;
  color: #00aeef;
  font-size: 16px;
  line-height: 22px;
  .base-market-share {
    background-color: #efefef;
    color: #b2b2b2;
    .calculation > span {
      background-color: #b2b2b2;
      color: #ffffff;
    }
  }
  .growth-market-share {
    background-color: #003594;
    color: #ffffff;
    .description {
      color: #00aeef;
    }
    .calculation > span {
      background-color: #00aeef;
    }
  }
`;

export const MarketShareRebate = styled.div`
  border-radius: 10px;
  padding: 20px;
  .title {
    position: relative;
    display: inline-block;
    margin: 0 auto;
    padding: 0 20px;
    letter-spacing: 2px;
    .overlay-popover-icon {
      top: 4px;
      right: 0;
    }
  }
  .description {
    letter-spacing: 1px;
    font-size: 12px;
    line-height: 14px;
    padding: 15px 0 5px 0;
  }
  .calculation {
    font-size: 24px;
    line-height: 28px;
    padding: 5px;
    span {
      display: inline-block;
      border-radius: 10px;
      padding: 4px 10px;
      min-width: 130px;
    }
  }
  @media (max-width: 767px) {
    .calculation > span {
      margin: 10px auto 0;
    }
  }
`;

export const YearDateRebate = styled.div`
  background-color: #00aeef;
  color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  .title {
    letter-spacing: 2px;
    margin-bottom: 10px;
  }
  .summary {
    display: inline-block;
    font-size: 24px;
    line-height: 28px;
    min-height: 36px;
    padding: 5px;
    background-color: #2cc6ff;
    border-radius: 10px;
    padding: 4px 10px;
    min-width: 130px;
    @media screen and (max-width: 767px) {
      margin-top: 10px;
    }
  }
  .quarters {
    display: flex;
    justify-content: space-between;
    padding: 5px 0 0 0;
    & > div {
      width: 23%;
      .calculation {
        display: inline-block;
        font-size: 24px;
        line-height: 28px;
        min-height: 36px;
        padding: 5px;
        background-color: #2cc6ff;
        border-radius: 10px;
        padding: 4px 10px;
        min-width: 130px;
        margin-top: 10px;
        width: 100%;
      }
      .estimated {
        color: #00aeef;
      }
      .status {
        color: #ffffff;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        margin-top: 8px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .title {
      display: none;
    }
    .quarters {
      flex-wrap: wrap;
      & > div {
        width: 100%;
        margin: 10px 70px;
      }
    }
  }
  @media screen and (min-width: 768px) {
    padding: 20px 10px 30px;
  }
  @media screen and (min-width: 992px) {
    padding: 20px 50px 30px;
  }
`;

export const PlusEquals = styled.div`
  letter-spacing: 2px;
  padding: 30px 0;
  color: #505d6f;
  font-weight: 700;
  span {
    font-size: 28px;
    line-height: 34px;
  }
`;

export const Timeline = styled(Image)`
  width: 100%;
  height: 20px;
  margin: 10px auto 0 auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  @media screen and (min-width: 992px) {
    width: 560px;
    height: 50px;
    background-position: center center;
  }
  @media screen and (min-width: 1366px) {
    width: 550px;
  }
`;

export const SaveForecast = styled.div`
  background-color: #fbfbfd;
  text-align: center;
  margin-top: 30px;
  padding: 30px;
  button {
    display: block;
    margin: 20px auto;
  }
`;

export const SaveForecastTitle = styled(HeaderTwo)`
  font-size: 24px !important;
`;
