import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { AddBehavior } from 'rsv8-components';
import { register, withContent } from 'xcel-react-core';
import { confirmLandingVisit } from '../../redux/actions/landing';

export interface LandingConfirmBehaviorProps extends RouteComponentProps<any> {
  dispatch: Function;
  actions: any;
}

export interface LandingConfirmBehaviorState {}

class LandingConfirmBehavior extends React.Component<LandingConfirmBehaviorProps, LandingConfirmBehaviorState> {
  constructor(props: LandingConfirmBehaviorProps) {
    super(props);

    this.state = {};
  }

  async handleClick(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();
    try {
      await this.props.actions.confirmLandingVisit(this.props.location.pathname);
    } catch (error) {
      console.log(error);
    }
  }

  public render() {
    return <AddBehavior {...this.props} onClick={(e) => this.handleClick(e)} />;
  }
}

const mapDispatch = (dispatch) => ({
  actions: bindActionCreators({ confirmLandingVisit }, dispatch)
});

export default register('rsv8-app/LandingConfirmBehavior')(withContent())(
  withRouter(
    connect(
      null,
      mapDispatch
    )(LandingConfirmBehavior)
  )
);
