import styled from 'styled-components';
import { Radio } from 'react-bootstrap';
import {AnimatedCircle} from "rsv8-components";

export const Spinner = styled(AnimatedCircle)`
  margin-top: 12px;
` as any;

export const ModalBodyContainer = styled.div`
  font-family: open-sans,sans-serif;
  font-size: 15px;
  padding: 0 10px 50px 10px;
  text-align: center;
` as any;

export const PopulateOptionsHeader = styled.div`
  font-family: open-sans-bold,open-sans,sans-serif;
  font-size: 28px;

  @media only screen and (max-width: 760px) {
    font-size: 25px;
  }
` as any;

export const PopulateOptionsContainer = styled.div`
  border: 1px solid #dddddd;
  border-radius: 5px;
  box-shadow: 0px 0px 4px #ccc;
  margin: 20px auto;
  width: 90%;

  @media only screen and (max-width: 760px) {
    font-size: 25px;
  }
` as any;

export const PopulateOptionsInnerLeft = styled.div`
  display: inline-block;
  width: 10%;
` as any;

export const PopulateOptionsInnerRight = styled.div`
  display: inline-block;
  padding: 10px 30px;
  width: 90%;
` as any;

export const PopulateOptionsTitle = styled.div`
  font-family: open-sans-bold,open-sans,sans-serif;
  font-size: 16px;
  padding: 10px 0 0;

  @media only screen and (max-width: 760px) {
    font-size: 15px;
  }
` as any;

export const PopulateOptionsDescription = styled.div`
  font-size: 12px;
` as any;

export const Checked = styled.span`
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #00aeef;
  height: 15px;
  left: 30px;
  padding-right: 6px;
  position: absolute;
  top: 0;
  width: 15px;

  &:after {
    content: '';
    display: none;
    position: absolute;
  }

  @media only screen and (max-width: 760px) {
    left: 18px;
  }
` as any;

export const StyledRadioButton = styled(Radio)`
  cursor: pointer;
  display: inline-block;
  vertical-align: bottom;
  width: 100%;

  label {
    font-size: 12px;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
    width: 70%;

    @media only screen and (max-width: 760px) {
      font-size: 10px;
    }
  }

  input {
    opacity: 0;
  }

  input:checked ~ ${Checked} {
    border-color: #00aeef;

    &:after {
      background: #00aeef;
      border-radius: 50%;
      display: block;
      height: 9px;
      left: 2px;
      top: 2px;
      width: 9px;
    }
  }
` as any;

export const AutoPopulateButton = styled.a`
  background-color: #ffffff;
  border-radius: 30px;
  border: 2px solid #00aeef;;
  color: #00aeef;
  display: block;
  font-family: open-sans;
  font-size: 15px;
  margin: 0 auto 15px;
  padding: 11px 0;
  text-transform: uppercase;
  width: 275px;

  :hover {
    background-color: #00aeef;
    border: 2px solid #00aeef;
    color: #ffffff;
    cursor: pointer;
    text-decoration: none;
  }

  @media only screen and (max-width: 760px) {
    margin: 0 auto 10px;
    width: 215px;
  }
` as any;
