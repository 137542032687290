import * as d3 from 'd3';
import * as _ from 'lodash';
import * as React from 'react';

interface Props {
  id: any;
  chartData: any;
}

export const HorizontalBarChart: React.FunctionComponent<Props> = ({ id, chartData }) => {
  const [margin] = React.useState({ top: 0, right: 30, bottom: 0, left: 0 });

  var barHeight = 8,
    groupHeight = barHeight,
    gapBetweenGroups = 13,
    spaceForLabels = 100;
  const draw = () => {
    let svg = d3.select(`#${id}`);

    var x = d3.scaleBand().domain([
      0,
      d3.max(chartData, function(d) {
        return d.value;
      })
    ]);

    d3.select(`#chart-${id}`).remove();

    let g = svg
      .append('svg')
      .attr('id', `chart-${id}`)
      .attr('width', '100%')
      .attr('height', '100px')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    const bounds = svg.node().getBoundingClientRect(),
      width = bounds.width - margin.left - margin.right;

    x.rangeRound([0, width]);
    let bar = g
      .selectAll('g')
      .data(chartData)
      .enter()
      .append('g')
      .attr('width', '100%')
      .attr('transform', function(d, i) {
        return 'translate(' + spaceForLabels + ',' + (i * (barHeight + gapBetweenGroups) + 5) + ')';
      });

    bar
      .append('rect')
      .attr('fill', '#e5f3f9')
      .attr('class', 'horizontalFullBar')
      .attr('width', x.bandwidth())
      .attr('height', barHeight - 1)
      .attr('rx', 5)
      .attr('y', 1);
    bar
      .selectAll('.horizontalFullBar')
      .attr('width', x.bandwidth())
      .attr('height', barHeight - 1);

    // Create rectangles of the correct width
    bar
      .append('rect')
      .attr('fill', '#1474a4')
      .attr('class', 'bar')
      .attr('width', function(d) {
        return (d.value / 100) * x.bandwidth();
      })
      .attr('height', barHeight - 1)
      .attr('rx', 5)
      .attr('y', 1);
    bar
      .selectAll('.bar')
      .attr('width', function(d) {
        return (d.value / 100) * x.bandwidth();
      })
      .attr('height', barHeight - 1);

    // Add text label in bar
    bar
      .append('text')
      .attr('x', function(d) {
        return x.bandwidth() + 10;
      })
      .attr('y', '8px')
      .attr('fill', '#5d5d5d')
      .attr('dy', '0')
      .style('font-family', 'CentraleSans-Regular')
      .style('font-size', '11px')
      .style('font-weight', 'normal')
      .style('color', '#5d5d5d')
      .text(function(d) {
        return _.round(d.value, 2) + '%';
      });

    // Draw labels
    bar
      .append('text')
      .attr('text-anchor', 'end')
      .attr('start-offset', '100%')
      .attr('class', 'label')
      .style('font-family', 'CentraleSans-Regular')
      .style('font-size', '11px')
      .style('font-weight', 'normal')
      .style('color', '#5d5d5d')
      .attr('x', function(d) {
        return -10;
      })
      .attr('y', groupHeight / 2)
      .attr('dy', '.35em')
      .text(function(d, i) {
        return d.label;
      });
    bar.exit().remove();
  };

  React.useEffect(() => {
    draw();
    window.addEventListener('resize', draw);

    return () => {
      window.removeEventListener('resize', draw);
    };
  }, []);

  return <div id={id} />;
};
