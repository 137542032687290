import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Card, Link, Text } from 'rsv8-components';
import styled from 'styled-components';
import { register, withContent } from 'xcel-react-core';
import { Spinner } from '../../components/Spinner';

const Container = styled.div`
  background: #fad37c;
  padding: 30px;
  height: 415px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
`;
// interface Props {}
const Heading = styled(Text)`
  font-size: 21px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.24;
  letter-spacing: normal;
  color: #394568;
  text-align: left;
`;

const StatusText = styled(Text)`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #0b2265;
`;
const NominationApprovals: React.FunctionComponent = (props) => {
  return (
    <Card>
      <Container>
        <Heading>Your Nomination Approvals</Heading>
        <div style={{ alignSelf: 'center' }}>
          <Spinner id="circles1" />
        </div>
        <div style={{ alignSelf: 'center', width: '50%', textAlign: 'center' }}>
          <StatusText>nominations are awaiting</StatusText>
          <br />
          <StatusText>your review</StatusText>
        </div>

        <Link
          to="/nominations/approval"
          themeVariation="secondary-alternate-button"
          style={{ width: '80%', alignSelf: 'center', textAlign: 'center' }}
        >
          Approve Nominations
        </Link>
      </Container>
    </Card>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({}, dispatch)
});

export default register('rsv8-data-vis/NominationApprovals')(
  withContent(),
  connect<any, any>(
    mapStateToProps,
    mapDispatchToProps
  )
)(NominationApprovals);
