import styled from 'styled-components';
import { RemoveIcon } from './RemoveIcon';

export const StyledRemoveIcon = styled(RemoveIcon)`
  display: block;
  width: 16px;
  height: 17px;
  fill: #000;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const FeedCardStyle = styled.div`
  margin: 20px;
  padding: 20px;
  box-shadow: 0 2px 16px 2px rgba(28, 39, 76, 0.13);
  position: relative;
` as any;

export const FeedCardHeaderStyle = styled.div`
  display: flex;
  line-height: 18px;
  margin-bottom: 20px;
` as any;

export const FeedCardImage = styled.div`
  width: 46px;
  height: 46px;
  margin-right: 5px;
  overflow: hidden;
  display: inline-block;
` as any;

export const FeedCardTitle = styled.div`
  font-size: 14px;
  color: #4c4f4f;
` as any;

export const FeedCardCaption = styled.div`
  font-size: 12px;
  color: #7c8180;
` as any;
