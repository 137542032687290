import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Button, Text } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import OrderHistoryContext from '../OrderHistoryContext';
import {
  OrderConfirmation,
  OrderPlaced,
  OrderPointTotal,
  OrderTopBar,
  TopBarConfirmation,
  TopBarContent,
  TopBarHeading
  } from '../styles';

const OrderDetails: React.SFC<any> = ({
  orderPlacedLabel,
  pointTotalLabel,
  pointValueAbbrevLabel,
  confirmationLabel,
  disablePointTotalModal
}) => {
  return (
    <OrderHistoryContext.Consumer>
      {({ products, togglePointsModal }) =>
        products && (
          <OrderTopBar className="order-header">
            <OrderPlaced>
              <TopBarHeading>
                <Text themeVariation="secondary-text-sm">{orderPlacedLabel}</Text>
              </TopBarHeading>
              <TopBarContent>
                <Text themeVariation="secondary-text-bold">{moment(products.orderDate).format('MM/DD/YYYY')}</Text>
              </TopBarContent>
            </OrderPlaced>
            <OrderPointTotal>
              <TopBarHeading>
                <Text themeVariation="secondary-text-sm">{pointTotalLabel}</Text>
              </TopBarHeading>
              <TopBarContent>
                {disablePointTotalModal === 'true' ? (
                  <Text themeVariation="secondary-text-bold">{`${products.orderPoints} ${pointValueAbbrevLabel}`}</Text>
                ) : (
                  <Button themeVariation="primary-link" onClick={() => togglePointsModal(products)}>
                    {`${products.orderPoints} ${pointValueAbbrevLabel}`}
                  </Button>
                )}
              </TopBarContent>
            </OrderPointTotal>
            <OrderConfirmation>
              <TopBarHeading>
                <Text themeVariation="secondary-text-sm">{confirmationLabel}</Text>
              </TopBarHeading>
              <TopBarConfirmation>
                <Text themeVariation="secondary-text-bold">{products.id}</Text>
              </TopBarConfirmation>
            </OrderConfirmation>
          </OrderTopBar>
        )
      }
    </OrderHistoryContext.Consumer>
  );
};

const mapResourceToProps = (getResource) => ({
  orderPlacedLabel: getResource('catalog.orderHistory.orderPlacedLabel', 'Order Placed'),
  pointTotalLabel: getResource('catalog.orderHistory.pointTotalLabel', 'Point Total'),
  pointValueAbbrevLabel: getResource('catalog.orderHistory.pointValueAbbrevLabel', 'pts.'),
  confirmationLabel: getResource('catalog.orderHistory.confirmationLabel', 'Confirmation #'),
  disablePointTotalModal: getResource('catalog.orderHistory.disablePointTotalModal', 'false')
});

export default compose<any>(
  connect(),
  withResource(mapResourceToProps)
)(OrderDetails) as any;
