import * as React from 'react';
import { Table, TableHeaderCell, TableRow } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import { NomineeTableProps } from '../Shared/index';
import HeaderCell from './HeaderCell';
import NomineeRow from './NomineeRow';

interface InternalProps extends NomineeTableProps {
  columnHeaders: {
    supervisor: any;
    department: any;
    lastName: any;
    firstName: any;
  };
}

class NomineeTable extends React.Component<InternalProps, {}> {
  renderNomineeRow = (key, i) => (
    <NomineeRow
      key={i}
      userId={key}
      active={this.props.currentNominees.indexOf(key.toString()) !== -1}
      loading={this.props.loadingNominees.indexOf(key.toString()) !== -1}
      selectNominee={this.props.selectNominee}
    />
  );

  render() {
    const { nomineeIds, columnHeaders } = this.props;
    return (
      <div className={this.props.className}>
        <Table condensed={true} hover={true}>
          <thead>
            <TableRow>
              <TableHeaderCell />
              <TableHeaderCell />
              <HeaderCell value="FirstName" label={columnHeaders.firstName} />
              <HeaderCell value="LastName" label={columnHeaders.lastName} />
              <HeaderCell value="OrganizationName" label={columnHeaders.department} />
              <HeaderCell value="ManagerName" label={columnHeaders.supervisor} />
            </TableRow>
          </thead>
          <tbody>{nomineeIds.map(this.renderNomineeRow)}</tbody>
        </Table>
      </div>
    );
  }
}

const mapResource = (getResource) => ({
  columnHeaders: {
    supervisor: getResource('nominees.supervisorHeaderLabel', 'Supervisor'),
    department: getResource('nominees.departmentHeaderLabel', 'Department'),
    firstName: getResource('nominees.firstNameHeaderLabel', 'First Name'),
    lastName: getResource('nominees.lastNameHeaderLabel', 'Last Name')
  }
});

export default withResource(mapResource)(NomineeTable) as React.SFC<NomineeTableProps>;
