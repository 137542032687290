import { Collapse } from 'rsv8-components';
import { Panel } from 'rsv8-components';
import styled from 'styled-components';
import { StatusBlock } from '../../NominationStatus';

export const StyledCollapse = styled(Collapse)``as any;

export const Body = styled.div`
  ${StyledCollapse} {
    border-top: 1px solid #ddd !important;
  }
  ${StyledCollapse} ~ ${StyledCollapse} {
    border-top: 0 !important;
  }
` as any;

export const NomineeContainer = styled.div`
  padding: 60px 50px 10px 50px;
` as any;

export const StatusContainer = styled.div`
  display: inline-block;
` as any;

export const Status = styled(StatusBlock)`
  padding: 0 20px;
` as any;

export const Certificate = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
` as any;

export const CircularContainer = styled.div`
height: 90px;
width: 90px;
border-radius: 50%;
position: absolute;
top: -45px;
box-shadow: 0 0 10px #a9a9a9;
left: 50%;
transform: translateX(-50%);

&::before {
  display: block;
  position: absolute;
  content: '';
  width: 150%;
  height: 57%;
  left: -25%;
  top: 45px;
  background: #fff;
}

&::after {
  display: block;
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: white;
  border-radius: 50%;
}
` as any;

export const StyledPanel = styled(Panel)`
  border: 0;
  box-shadow: 0 0 10px #a9a9a9;
` as any;
