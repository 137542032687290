import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button } from 'rsv8-components';
import { register, withContent } from 'xcel-react-core';
import { salesActions } from '../../redux/actions';

interface Props {
  createEntryRedirect: typeof salesActions.createEntryRedirect;
  qs: { [index: string]: any };
}

const NewEntryButton: React.FunctionComponent<Props> = (props) => {
  // TODO: Create CMS way to feed in appKey
  // Would allow button placement anywhere in v8
  return (
    <div {...props}>
      <Button
        onClick={() => {
          props.createEntryRedirect(props.qs);
        }}
      >
        New Sales Entry
      </Button>
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ createEntryRedirect: salesActions.createEntryRedirect }, dispatch);

export default register('rsv8-sales-entry/NewEntryButton')(
  withContent(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(NewEntryButton);
