import * as React from 'react';
import { Field } from 'rsv8-forms';
import styled from 'styled-components';
import mapCustomFieldToInput from './mapFieldToInput';

const StyledLabel = styled.label`
  ${({ required }: any) =>
    required
      ? `
    &::after {
    color: red;
    content: ' *';
  }`
      : ''}
`;

export interface CustomFieldProps {
  field?: any;
  fieldKey: string;
  groupKey?: string;
  mapFieldToInput: (v: any) => any;
}

class CustomField extends React.Component<CustomFieldProps, any> {
  render() {
    const { field: definition, fieldKey, mapFieldToInput = mapCustomFieldToInput } = this.props;

    let InputComponent = null;

    if (definition === undefined) {
      return null;
    }

    const { name, validate, fieldType, label, ...rest } = definition;
    if (mapFieldToInput) {
      InputComponent = mapFieldToInput(definition);
    } else {
      return null;
    }

    return (
      <Field
        name={`${fieldKey}.${name}`}
        helpIconText={this.props.field.instructions}
        component={InputComponent}
        validate={validate}
        label={<StyledLabel {...rest}>{label}</StyledLabel>}
        {...rest}
      />
    );
  }
}

export default CustomField;
