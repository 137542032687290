import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loader, Text } from 'rsv8-components';
import styled from 'styled-components';
import { SalesEntryDetail } from 'xcel-api-generator/dist/sales';
import { register } from 'xcel-react-core';
import { salesActions } from '../../redux/actions';
import { saleDetailSelectors } from '../../redux/selectors';
import { SalesActivityContext } from '../SalesActivity/SalesActivity';

const Details = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
`;

const DetailRow = styled(Row)`
  margin-bottom: 10px;
`;

interface Props {
  getDetails: typeof salesActions.getApplicationByAppKeyEntryAndId;
  entryKey: string;
  data: SalesEntryDetail;
}

const initialRenderData = [
  { label: 'Date Submitted', entryProp: 'creationDate' },
  { label: 'Confirmation Number', entryProp: 'confirmationNumber' },
  { label: 'Product Name', entryProp: 'productName' },
  { label: 'Quantity', entryProp: 'quantity' },
  { label: 'Status', entryProp: 'status' },
  { label: 'Points', entryProp: 'pointsAwarded' }
];

const SaleDetailEntry: React.FunctionComponent<Props> = ({ getDetails, data }) => {
  const context = React.useContext(SalesActivityContext);

  React.useEffect(
    () => {
      if (context.qs.appKey && context.qs.entryKey) {
        getDetails({ appKey: context.qs.appKey, id: context.qs.entryKey });
      }
    },
    [context.qs.entryKey]
  );

  if (!data /* && request for data is loading */) return <Loader />;

  return (
    <Details>
      {initialRenderData.map((dataRow) => (
        <DetailRow>
          <Col xs={10}>
            <Text>
              <strong>{dataRow.label}</strong>
            </Text>
          </Col>
          <Col xs={14}>
            <Text>{data[dataRow.entryProp]}</Text>
          </Col>
        </DetailRow>
      ))}
    </Details>
  );
};

const mapStateToProps = (state: any, ownProps) => ({
  data: saleDetailSelectors.selectOne(state, ownProps.qs.entryKey)
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      getDetails: salesActions.getApplicationByAppKeyEntryAndId
    },
    dispatch
  );

export default register('rsv8-sales-entry/SaleDetailEntry')(
  connect(
    mapStateToProps,
    mapDispatch
  )
)(SaleDetailEntry);
