import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { accountStyles as styles } from 'rsv8-components';
import { ReactField } from 'rsv8-forms';
import styled from 'styled-components';
import { register, withContent, withTheme } from 'xcel-react-core';
import { withApiDataProvider } from 'xcel-redux-orm';
import { getRebate } from '../../redux/actions';
import { rebateSelector } from '../../redux/selectors';
import DesktopTable from './DesktopTable';
import { getCurrencyValue } from '../../../utils';

interface RebateHistoryProps {
  actions: any;
  dataTableTitle: string;
  rebateData: any;
}

enum SortOrdering {
  ASC_DIR = 'Asc',
  DESC_DIR = 'Desc',
  UNSORT = 'unsorted'
}

const MobileDropDownField = styled(ReactField)`
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: solid 1px #b2b2b2;
  text-align: center;
  align-content: center;
  align-self: center;
  vertical-align: middle;
  font-family: open-sans;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: #505d6f;
` as any;

const tabletSize: number = 1024;
const mobileSize: number = 511;

const NoDataAvailableText: string = "No Data Available";

class RebateHistory extends React.Component<RebateHistoryProps> {

  state = {
    tableData: [],
    windowSize: window.innerWidth,
    windowScrollY: -1,
    option: 0
  };

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    this.setState({ tableData: this.props.rebateData });
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.rebateData !== this.props.rebateData) {
      this.setState({ tableData: this.props.rebateData });
    }
  }

  removeSpaceToLowerCase(value: string) {
    if (value !== undefined && value !== null) {
      return value.replace(" ", "").toLocaleLowerCase();
    } else {
      return value;
    }
  };

  renderTabletTable(props: any, headerNames: any, rebateData: any) {
    if (headerNames.length > 0) {

      return (
        <styles.TabletDataTable>
          <styles.TabletTableHeader>
            {headerNames.map((headerValue, index) => (
              <styles.TabletTableHeaderCell>
                <styles.TabletTableHeaderText>
                  {headerValue}
                </styles.TabletTableHeaderText>
              </styles.TabletTableHeaderCell>
            ))}
          </styles.TabletTableHeader>
          { (rebateData && rebateData.length > 0) ? rebateData.map((rebateObject) => (
            <styles.TabletTableRow>
              <styles.TabletTableCell>
                <styles.TabletTableText>
                  {rebateObject.quarter}
                </styles.TabletTableText>
              </styles.TabletTableCell>
              <styles.TabletTableCell>
                <styles.TabletTableText>
                  {rebateObject.amount === 0 ? '-' : getCurrencyValue(rebateObject.amount)}
                </styles.TabletTableText>
              </styles.TabletTableCell>
            </styles.TabletTableRow>
          )) :
            <styles.TabletTableRow>
              <styles.DesktopNoDataTableCell colSpan={'2'}>
                <styles.NoDataContainer>
                  {NoDataAvailableText}
                </styles.NoDataContainer>
              </styles.DesktopNoDataTableCell>
            </styles.TabletTableRow>
          }
        </styles.TabletDataTable>
      );
    } else {
      return null;
    }
  }

  renderMobileTable(
    props: any,
    headerNames: any,
    rebateData: any,
    selectedOption: any
  ) {

    let selectedData: any;

    if (rebateData && rebateData.length > 0 && selectedOption !== null) {
      selectedData = rebateData.filter(
        item => item.quarter === selectedOption
      );

      if (selectedData !== undefined && selectedOption.length > 0 && selectedOption !== 'Select Quarter') {
        return (
          <div>
            {selectedData.map((data, index) => (
              <styles.MobileDataTable {...props}>
                {/*create header */}
                <styles.MobileDataTableHeaderRow>
                  <styles.MobileDataTableHeaderCell>
                    <styles.MobileDataTableHeaderText>
                      {headerNames[1]}
                    </styles.MobileDataTableHeaderText>
                  </styles.MobileDataTableHeaderCell>

                  <styles.MobileDataTableCell>
                    <styles.DataTableText>
                      {data.amount === 0 || data.amount === "" ? '-' : getCurrencyValue(data.amount)}
                    </styles.DataTableText>
                  </styles.MobileDataTableCell>
                </styles.MobileDataTableHeaderRow>

              </styles.MobileDataTable>
            ))}
          </div>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
  handleOnSort = ({ direction, field }) => {
    if (direction === SortOrdering.UNSORT) {
      return;
    }

    const { tableData } = this.state;
    tableData.sort((a, b) => {
      const value1 = a[field];
      const value2 = b[field];

      switch (direction) {
        case SortOrdering.ASC_DIR:
          if (typeof (value1) === 'string') {
            return value1.toLowerCase() < value2.toLowerCase() ? 1 : -1;
          } else {
            return value1 < value2 ? 1 : -1;
          }
        case SortOrdering.DESC_DIR:
          if (typeof (value1) === 'string') {
            return value1.toLowerCase() > value2.toLowerCase() ? 1 : -1;
          } else {
            return value1 > value2 ? 1 : -1;
          }
        default: return 0;
      }
    });

    this.setState({ tableData: tableData });

  }

  onResize = () => {
    this.setState({ windowSize: window.innerWidth });
  };

  render() {
    const { windowSize, option, tableData } = this.state;
    const { dataTableTitle } = this.props;
    const headerNames: any = [
      "Quarter",
      "Rebate Amount"
    ];

    if (windowSize > tabletSize) {
      return (
        <div {...this.props}>
          <styles.DesktopContainer>
            <styles.DesktopContainerTitle>
              {dataTableTitle}
            </styles.DesktopContainerTitle>
            <DesktopTable data={tableData} onSort={this.handleOnSort} />
          </styles.DesktopContainer>
        </div>
      );
    } else if (windowSize > mobileSize) {
      return (
        <div {...this.props}>
          <styles.TabletContainer>
            <styles.TabletContainerTitle>
              {dataTableTitle}
            </styles.TabletContainerTitle>
            {this.renderTabletTable(this.props, headerNames, tableData)}
          </styles.TabletContainer>
        </div>
      );
    } else {

      let dropdownOptions: any = _.union([{ label: 'Select Quarter', value: 'Select Quarter' }], _.uniqWith(tableData.map(data => {
        return {
          label: data.quarter,
          value: data.quarter
        };
      }), _.isEqual));

      return (
        <div {...this.props}>
          <styles.MobileContainer>
            <styles.MobileDataTableTitle>
              {dataTableTitle}
            </styles.MobileDataTableTitle>
            <styles.MobileDropDownContainer>
              {tableData && tableData !== undefined && tableData.length > 0 ? (
                <MobileDropDownField
                  component="bs-select"
                  options={dropdownOptions}
                  onChange={value => this.setState({ option: value })}
                />
              ) : (
                  <styles.NoDataContainer>
                    {NoDataAvailableText}
                  </styles.NoDataContainer>
                )}
            </styles.MobileDropDownContainer>

            {this.renderMobileTable(
              this.props,
              headerNames,
              tableData,
              option
            )}
          </styles.MobileContainer>
        </div>
      );
    }
  }
};

const mapStateToProps = state => ({
  rebateData: rebateSelector.selectMany(state)
});

const mapContentToProps = getContent => ({
  dataTableTitle: getContent("dataTableTitle", {
    type: "string",
    schema: [{ model: "Data Table Title", label: "Data Table Title:" }]
  })
});

export default register("alcon-information/RebateHistory")(
  connect(mapStateToProps),
  withApiDataProvider(getRebate, rebateSelector),
  withContent(mapContentToProps),
  withTheme()
)(RebateHistory);

export { RebateHistory };

