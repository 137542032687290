import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as Icon from 'react-fontawesome';
import Slider from 'react-slick-deux';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { assetService } from 'xcel-asset-service';
import { register, withContent, withTheme } from 'xcel-react-core';
import Link from '../Link';
import {
  CarouselArrow,
  SingleImage,
  SingleImageWrapper,
  SliderContainer,
  SliderImage
  } from './styles';

const CarouselPrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <CarouselArrow className={className} onClick={onClick}>
      <Icon name="angle-left" size="3x" />
    </CarouselArrow>
  );
};

const CarouselNextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <CarouselArrow className={className} onClick={onClick}>
      <Icon name="angle-right" size="3x" />
    </CarouselArrow>
  );
};

class ImageSlider extends React.Component<any, any> {
  slider: any = null;

  componentDidMount() {
    const { contentSlides }: any = this.props;
    if (contentSlides && contentSlides.length > 1) {
      let activeDot: any = this.slider && this.slider.querySelector('.slick-dots .slick-active');
      if (activeDot) activeDot.className = 'slick-active active';
    }
  }
  onMount = (el: any) => {
    this.slider = ReactDOM.findDOMNode(el);
  };

  render() {
    const isEditing = this.props['data-cms-editing'];
    const { sliderSettings, children, ...rest }: any = this.props;

    let { contentSlides } = this.props;

    if (isEditing && contentSlides !== undefined && contentSlides.props !== undefined) {
      contentSlides = contentSlides.props.editingValue;
    }

    if (contentSlides && Array.isArray(contentSlides) && contentSlides.length > 1) {
      let defaultSliderSettings = {
        dots: true,
        prevArrow: <CarouselPrevArrow />,
        nextArrow: <CarouselNextArrow />,
        afterChange: () => {
          let activeDot: any = this.slider && this.slider.querySelector('.slick-dots .slick-active');
          if (activeDot) activeDot.className = 'slick-active active';
        }
      };

      const settings = Object.assign({}, defaultSliderSettings, sliderSettings);

      return (
        <SliderContainer {...rest}>
          <div ref={this.onMount}>
            <Slider {...settings}>
              {contentSlides.map((slide, i) => (
                <div key={i}>
                  {slide.imageResponsive && (
                    <React.Fragment>
                      <Link to={slide.href && slide.href}>
                        <SliderImage
                          src={assetService.getImage(slide.imageResponsive)}
                          className="hidden-sm hidden-md hidden-lg"
                        />
                        <SliderImage src={assetService.getImage(slide.image)} className="hidden-xs" />
                      </Link>
                    </React.Fragment>
                  )}

                  {!slide.imageResponsive && (
                    <React.Fragment>
                      <Link to={slide.href && slide.href}>
                        <SliderImage src={assetService.getImage(slide.image)} />
                      </Link>
                    </React.Fragment>
                  )}
                </div>
              ))}
            </Slider>
          </div>
        </SliderContainer>
      );
    } else if (contentSlides && Array.isArray(contentSlides) && contentSlides.length === 1) {
      return contentSlides.map((singleSlide, i) => (
        <SingleImageWrapper key={i}>
          {singleSlide.imageResponsive && (
            <React.Fragment>
              <Link to={singleSlide.href && singleSlide.href}>
                <SingleImage
                  {...rest}
                  src={assetService.getImage(singleSlide.image)}
                  className="hidden-sm hidden-md hidden-lg"
                />
                <SingleImage {...rest} src={assetService.getImage(singleSlide.imageResponsive)} className="hidden-xs" />
              </Link>
            </React.Fragment>
          )}
          {!singleSlide.imageResponsive && (
            <React.Fragment>
              <Link to={singleSlide.href && singleSlide.href}>
                <SingleImage {...rest} key={i} src={assetService.getImage(singleSlide.image)} />
              </Link>
            </React.Fragment>
          )}
        </SingleImageWrapper>
      ));
    } else {
      return '';
    }
  }
}
const mapContentToProps = (getContent) => ({
  contentSlides: getContent('contentSlides', {
    type: 'array',
    schema: [
      { model: 'image', label: 'Image' },
      { model: 'imageResponsive', label: 'Responsive Image (small)' },
      { model: 'href', label: 'Image URL' }
    ]
  }),
  sliderSettings: getContent('sliderSettings', { type: 'object' })
});

export { ImageSlider };
export default register('rsv8-components/ImageSlider')(
  withContent(mapContentToProps),
  withTheme(['.slick-dots li button:before', '.slick-dots .slick-active button:before', '.slick-arrow'])
)(ImageSlider);
