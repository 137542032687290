import * as React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { register, withContent, withTheme } from 'xcel-react-core';
import { TextStyle } from '../Typography/Text';
const externalLinkRegex = /^https?:\/\//;
const emailLinkRegex = /^mailto?:/;
const StyledAnchor = TextStyle.withComponent('a');
const StyledLink = TextStyle.withComponent(ReactRouterLink);
const Link = ({ href, target, children, to, ...rest }: any) => {
  href = href || to;
  return externalLinkRegex.test(href) || emailLinkRegex.test(href) || !href ? (
    <StyledAnchor {...rest} href={href} target={target}>
      {children}
    </StyledAnchor>
  ) : (
    <StyledLink {...rest} target={target} to={href}>
      {children}
    </StyledLink>
  );
};

const mapContentToProps = (getContent) => ({
  href: getContent('href', { type: 'none' }),
  target: getContent('target', { type: 'none' })
});

export default register('rsv8-components/Link')(withContent(mapContentToProps), withTheme())(Link);
