import * as React from 'react';

interface LikeIconProps {
  className?: string;
  onClick?: () => void;
}

export function DefaultLikeIcon(props: LikeIconProps) {
  return (
    <svg className={props.className} onClick={props.onClick} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
      <path d="M486.4 972.8c-4.283 0-8.566-1.074-12.434-3.222-4.808-2.67-119.088-66.624-235.122-171.376-68.643-61.97-123.467-125.363-162.944-188.418-50.365-80.443-75.901-160.715-75.901-238.584 0-148.218 120.582-268.8 268.8-268.8 50.173 0 103.462 18.805 150.051 52.952 27.251 19.973 50.442 44.043 67.549 69.606 17.107-25.565 40.299-49.634 67.55-69.606 46.589-34.147 99.878-52.952 150.050-52.952 148.218 0 268.8 120.582 268.8 268.8 0 77.869-25.538 158.141-75.901 238.584-39.478 63.054-94.301 126.446-162.944 188.418-116.034 104.754-230.314 168.706-235.122 171.376-3.867 2.149-8.15 3.222-12.434 3.222zM268.8 153.6c-119.986 0-217.6 97.614-217.6 217.6 0 155.624 120.302 297.077 221.224 388.338 90.131 81.504 181.44 138.658 213.976 158.042 32.536-19.384 123.845-76.538 213.976-158.042 100.922-91.261 221.224-232.714 221.224-388.338 0-119.986-97.616-217.6-217.6-217.6-87.187 0-171.856 71.725-193.314 136.096-3.485 10.453-13.267 17.504-24.286 17.504s-20.802-7.051-24.286-17.504c-21.456-64.371-106.125-136.096-193.314-136.096z" />
    </svg>
  );
}

export function ClickedLikeIcon(props: LikeIconProps) {
  return (
    <svg className={props.className} onClick={props.onClick} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 18">
      <path
        fill-rule="evenodd"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M9.67 16.816c-4.677-2.659-6.82-6.068-7.437-6.83-.63-.781-2.861-5.913.894-8.18C5.63.296 7.92.908 10 3.644 12.079.908 14.37.295 16.873 1.806c3.755 2.267 1.524 7.399.894 8.18-.63.78-2.86 4.331-7.767 7.014z"
      />
    </svg>
  );
}
