import * as React from 'react';
import { Breadcrumb, Col, Row } from 'react-bootstrap';
import { connect, DispatchProp } from 'react-redux';
import { push } from 'react-router-redux';
import { ReactField } from 'rsv8-forms';
import styled from 'styled-components';
import { emptyArray } from 'xcel-util';
import ContentTable from '../../components/ContentTable';
import ContentTypeDropdown from '../../components/Dropdown/ContentType';
import { contentActions, contentTypeActions, routeActions } from '../../redux/actions';
import { contentTypeSelector, routeSelector } from '../../redux/selectors';
import { RouteItem, RouteList } from '../../redux/types/route';
import ContentListToolbar from './components/ContentListToolbar';

const ContentListBreadcrumb = styled(Breadcrumb)`
  position: relative;
  margin: 0;
  background-color: #2695f3;
  span {
    color: white;
    text-decoration: none;
  }
`;
export interface ContentListProps {
  activeRoutes: RouteList[];
  breadcrumbs: RouteList[];
  contentTypeId: string;
  match: { path: string };
  dispatch: any;
}
class ContentList extends React.Component<ContentListProps> {
  state = {
    sort: undefined
  };
  static getDerivedStateFromProps({ contentTypeId, activeRoutes, dispatch }: ContentListProps, prevState: { contentTypeId: string }) {
    if (prevState === null || contentTypeId !== prevState.contentTypeId) {
      if (activeRoutes) {
        dispatch(routeActions.setActive(contentTypeId, [activeRoutes[0].id]))
      } else {
        dispatch(routeActions.getRoot(contentTypeId));
      }
    }
    return { contentTypeId };
  }
  componentDidMount() {
    const { dispatch, activeRoutes } = this.props;

    if (!activeRoutes) {
      dispatch(contentTypeActions.setActive('Page'));
    }

  }
  handleClickRoute = (e, route) => {
    const { dispatch, contentTypeId } = this.props;
    dispatch(routeActions.setActive(contentTypeId, [route.id]));
  };
  handleRowClick = (item, e) => {
    const { dispatch, contentTypeId } = this.props;
    if (item.type === 'routeModel') {
      if (e.button === 0) {
        dispatch(routeActions.getById(contentTypeId, item.id));
      }
    } else {
      const editUrl = (['Page', 'Landing'].indexOf(contentTypeId) !== -1) ?
        `/cms/content/edit/${item.id}` :
        `/cms/json/edit/${item.id}`;

      if (e.button === 0) {
        dispatch(push(editUrl));
      } else if (e.button === 1) {
        window.open(editUrl, "_blank")
      }
    }
  };
  handleSearch = (search) => {
    const { dispatch, contentTypeId } = this.props;
    dispatch(routeActions.search(contentTypeId, search));
  };
  handleDelete = (item) => {
    const { dispatch, contentTypeId } = this.props;
    if (
      confirm(`Are you sure you want to delete the ${item.type === 'routeModel' ? 'route' : 'content'} ${item.label}?`)
    ) {
      if (item.type === 'routeModel') {
        dispatch(routeActions.delete(contentTypeId, item.id));
      } else {
        dispatch(contentActions.delete(contentTypeId, item.id));
      }
    }
  };
  handleClickBreadcrumb = (crumb) => (e) => {
    const { dispatch, contentTypeId } = this.props;
    dispatch(routeActions.setActive(contentTypeId, [crumb.id]));
  };
  handleSort = (sort) => {
    this.setState({ sort });
  };
  sortField = (a, b) => {
    if (!this.state.sort) return 0;
    let { field, direction } = this.state.sort;
    if (a[field] > b[field]) return direction === 'Asc' ? 1 : -1;
    else if (a[field] < b[field]) return direction === 'Asc' ? -1 : 1;
    return 0;
  };
  sortItems = (a, b) => {
    if (a.type !== b.type) {
      if (a.type !== 'routeModel') {
        return 1;
      } else {
        return -1;
      }
    }
    return this.sortField(a, b);
  };
  render() {
    const { activeRoutes, breadcrumbs } = this.props;
    const items = activeRoutes && activeRoutes.reduce((obj, next) => {
      if (next) {
        return [...obj, ...next.items];
      }
      return obj;
    }, []) || [];

    return (
      <div className="container-fluid">
        <Row>
          <Col md={12}>
            <ReactField component={'bs-input'} placeholder="Search" label={'Search'} onChange={this.handleSearch} />
          </Col>
          <Col md={12}>
            <ContentTypeDropdown />
          </Col>
        </Row>
        <ContentListBreadcrumb>
          {breadcrumbs.map((crumb, index) => {
            return (
              <Breadcrumb.Item key={index}>
                <span onClick={this.handleClickBreadcrumb(crumb)}>
                  {crumb.label.trim() === '' ? 'home' : crumb.label}
                </span>
              </Breadcrumb.Item>
            );
          })}

          <ContentListToolbar />
        </ContentListBreadcrumb>

        <ContentTable
          items={items.sort(this.sortItems)}
          onSort={this.handleSort}
          onClick={this.handleRowClick}
          onSearch={this.handleSearch}
          onDelete={this.handleDelete}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const contentTypeId = contentTypeSelector.getActive(state);
  // get active state
  return {
    contentTypeId,
    activeRoutes: routeSelector.getActive(state, contentTypeId),
    breadcrumbs: routeSelector.getActiveBreadcrumbs(state, contentTypeId) || emptyArray
  };
};
export { DispatchProp, RouteItem };
export default connect(mapStateToProps as any)(ContentList as any);
