import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import Switch from 'react-switch';
import { bindActionCreators } from 'redux';
import { accountStyles as styles, toast } from 'rsv8-components';
import { ReactField } from 'rsv8-forms';
import styled from 'styled-components';
import { assetService } from 'xcel-asset-service';
import { register, withTheme } from 'xcel-react-core';
import { createParticipantsSave, getParticipants } from '../../redux/actions/';
import DesktopTable from './DesktopTable';

interface UserManagmentProps {
  actions: any;
}

enum SortOrdering {
  ASC_DIR = 'Asc',
  DESC_DIR = 'Desc',
  UNSORT = 'unsorted'
}

const MobileDropDownField = styled(ReactField)`
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: solid 1px #b2b2b2;
  text-align: center;
  align-content: center;
  align-self: center;
  vertical-align: middle;
  font-family: open-sans;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: #505d6f;
` as any;

const NoDataAvailableText: string = "Additional users at your practice will appear here";

class UserManagmentControl extends React.Component<UserManagmentProps> {
  state = {
    userAcounts: [],
    loaded: false,
    windowWidth: window.innerWidth,
    option: null
  };

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    this.loadUserAccounts();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  handleActivationClick = async (item) => {
    await this.props.actions.createParticipantsSave({
      userId: item.participantUserId,
      active: item.active
    });
    toast({
      template: 'CatalogBody',
      productName: '',
      productImage: assetService.getImage("icons/save-calculation.png"),
      productManufacturer: 'User Managment',
      actionText: `Active state was changed: ${item.active ? 'Active' : 'Inactive'}`
    });
    this.loadUserAccounts();
  }

  loadUserAccounts = async () => {
    this.setState({ loaded: false });
    const result = await this.props.actions.getParticipants();
    this.setState({
      userAcounts: result,
      loaded: true
    });
  }

  handleOnSort = ({ direction, field }) => {
    if (direction === SortOrdering.UNSORT) {
      return;
    }

    const { userAcounts } = this.state;
    userAcounts.sort((userAccount1, userAccount2) => {
      const value1 = userAccount1[field];
      const value2 = userAccount2[field];

      switch (direction) {
        case SortOrdering.ASC_DIR:
          if (typeof (value1) === 'string') {
            return value1.toLowerCase() < value2.toLowerCase() ? 1 : -1;
          } else {
            return value1 < value2 ? 1 : -1;
          }
        case SortOrdering.DESC_DIR:
          if (typeof (value1) === 'string') {
            return value1.toLowerCase() > value2.toLowerCase() ? 1 : -1;
          } else {
            return value1 > value2 ? 1 : -1;
          }
        default: return 0;
      }
    });

    this.setState({ userAcounts: userAcounts });

  }

  removeSpaceToLowerCase = (value: string) => {
    if (value !== undefined && value !== null) {
      return value.replace(" ", "").toLocaleLowerCase();
    } else {
      return value;
    }
  };

  renderTabletTable(props: any, headerNames: any, userAccounts: any) {
    if (headerNames.length > 0) {

      return (
        <styles.TabletDataTable>
          <styles.TabletTableHeader>
            {headerNames.map((headerValue, index) => (
              <styles.TabletTableHeaderCell>
                <styles.TabletTableHeaderText>{headerValue}</styles.TabletTableHeaderText>
              </styles.TabletTableHeaderCell>
            ))}
          </styles.TabletTableHeader>
          { (userAccounts && userAccounts.length > 0) ? userAccounts.map((userAccount) => (
            <styles.TabletTableRow>
              <styles.TabletTableCell>
                <styles.TabletTableText>
                  {userAccount.lastName}
                </styles.TabletTableText>
              </styles.TabletTableCell>
              <styles.TabletTableCell>
                <styles.TabletTableText>
                  {userAccount.firstName}
                </styles.TabletTableText>
              </styles.TabletTableCell>
              <styles.TabletTableCell>
                <styles.TabletTableText>
                  {userAccount.email}
                </styles.TabletTableText>
              </styles.TabletTableCell>
              <styles.TabletTableCell>
                <styles.TabletTableText>
                  {userAccount.repTypeName}
                </styles.TabletTableText>
              </styles.TabletTableCell>
              <styles.TabletTableCell>
                <styles.TabletTableText>
                  <Switch
                    onChange={(checked) => {
                      userAccount.active = checked;
                      this.handleActivationClick(userAccount);
                    }}
                    checked={userAccount.active}
                    onColor="#003595"
                    offColor="#999999"
                    onHandleColor="#fff"
                    offHandleColor="#fff"
                    handleDiameter={15}
                    uncheckedIcon={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          fontSize: 9.5,
                          color: "#fff",
                          paddingRight: 20
                        }}
                      >
                        Inactive
                      </div>
                    }
                    checkedIcon={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          fontSize: 9.5,
                          color: "#fff",
                          paddingLeft: 20
                        }}
                      >
                        Active
                      </div>
                    }
                    height={20}
                    width={65}
                    disabled={!userAccount.canBeEdited}
                  />
                </styles.TabletTableText>
              </styles.TabletTableCell>
            </styles.TabletTableRow>
          )) :
            <styles.TabletTableRow>
              <styles.DesktopNoDataTableCell colSpan={'5'}>
                <styles.NoDataContainer>
                  {NoDataAvailableText}
                </styles.NoDataContainer>
              </styles.DesktopNoDataTableCell>
            </styles.TabletTableRow>
          }

        </styles.TabletDataTable>
      );
    } else {
      return null;
    }
  }

  renderMobileTable(
    props: any,
    headerNames: any,
    userData: any,
    selectedOptionFunc: any
  ) {
    let selectedData: any;
    const selectedOption = selectedOptionFunc ? selectedOptionFunc() : undefined;
    if (userData && userData.length > 0 && selectedOption) {
      selectedData = userData.filter(
        item => item.participantUserId.toString() === selectedOption
      );

      if (selectedData !== undefined && selectedOption.length > 0) {
        return (
          <div>
            {selectedData.map((data, index) => (
              <styles.MobileDataTable {...props}>
                {/*create header */}
                <styles.MobileDataTableHeaderRow>
                  <styles.MobileDataTableHeaderCell>
                    <styles.MobileDataTableHeaderText>
                      {headerNames[0]}
                    </styles.MobileDataTableHeaderText>
                  </styles.MobileDataTableHeaderCell>

                  <styles.MobileDataTableCell>
                    <styles.DataTableText>
                      {data.firstName}
                    </styles.DataTableText>
                  </styles.MobileDataTableCell>
                </styles.MobileDataTableHeaderRow>

                <styles.MobileDataTableHeaderRow>
                  <styles.MobileDataTableHeaderCell>
                    <styles.MobileDataTableHeaderText>
                      {headerNames[1]}
                    </styles.MobileDataTableHeaderText>
                  </styles.MobileDataTableHeaderCell>

                  <styles.MobileDataTableCell>
                    <styles.DataTableText>{data.lastName}</styles.DataTableText>
                  </styles.MobileDataTableCell>
                </styles.MobileDataTableHeaderRow>

                <styles.MobileDataTableHeaderRow>
                  <styles.MobileDataTableHeaderCell>
                    <styles.MobileDataTableHeaderText>
                      {headerNames[2]}
                    </styles.MobileDataTableHeaderText>
                  </styles.MobileDataTableHeaderCell>

                  <styles.MobileDataTableCell>
                    <styles.DataTableText>
                      {data.email}
                    </styles.DataTableText>
                  </styles.MobileDataTableCell>
                </styles.MobileDataTableHeaderRow>

                <styles.MobileDataTableHeaderRow>
                  <styles.MobileDataTableHeaderCell>
                    <styles.MobileDataTableHeaderText>
                      {headerNames[3]}
                    </styles.MobileDataTableHeaderText>
                  </styles.MobileDataTableHeaderCell>

                  <styles.MobileDataTableCell>
                    <styles.DataTableText>
                      {data.repTypeName}
                    </styles.DataTableText>
                  </styles.MobileDataTableCell>
                </styles.MobileDataTableHeaderRow>

                <styles.MobileDataTableHeaderRow>
                  <styles.MobileDataTableHeaderCell>
                    <styles.MobileDataTableHeaderText>
                      {headerNames[4]}
                    </styles.MobileDataTableHeaderText>
                  </styles.MobileDataTableHeaderCell>

                  <styles.MobileDataTableCell
                    style={{
                      width: "52px",
                      paddingTop: "0px",
                      borderTop: "0px solid #b2b2b2"
                    }}
                  >
                    <styles.DataTableLinkText>
                      <Switch
                        onChange={(checked) => {
                          data.active = checked;
                          this.handleActivationClick(data);
                        }}
                        checked={data.active}
                        onColor="#003595"
                        offColor="#999999"
                        onHandleColor="#fff"
                        offHandleColor="#fff"
                        handleDiameter={15}
                        uncheckedIcon={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              fontSize: 9.5,
                              color: "#fff",
                              paddingRight: 20
                            }}
                          >
                            Inactive
                          </div>
                        }
                        checkedIcon={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              fontSize: 9.5,
                              color: "#fff",
                              paddingLeft: 20
                            }}
                          >
                            Active
                          </div>
                        }
                        height={20}
                        width={65}
                        disabled={!data.canBeEdited}
                      />
                    </styles.DataTableLinkText>
                  </styles.MobileDataTableCell>
                </styles.MobileDataTableHeaderRow>
              </styles.MobileDataTable>
            ))}
          </div>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  render() {
    const tabletSize: number = 1024;
    const mobileSize: number = 511;
    const { userAcounts, loaded, windowWidth } = this.state;
    const headerNames: any = [
      "Last Name",
      "First Name",
      "Email Address",
      "Role",
      "Status"
    ];
    if (windowWidth > tabletSize) {
      return (
        <styles.DesktopContainer>
          <styles.DesktopContainerTitle>
            User Management
          </styles.DesktopContainerTitle>
          <DesktopTable
            data={userAcounts}
            loaded={loaded}
            onActivationClick={this.handleActivationClick}
            onSort={this.handleOnSort}
          />
        </styles.DesktopContainer>
      );

    } else if (windowWidth > mobileSize) {

      return (
        <styles.TabletContainer>
          <styles.TabletContainerTitle>
            User Management
          </styles.TabletContainerTitle>
          {this.renderTabletTable(this.props, headerNames, userAcounts)}
        </styles.TabletContainer>
      );

    } else {
      let dropdownOptions: any = _.union([{ label: 'Choose User', value: null }], _.uniqWith(userAcounts.map(data => {
        return {
          label: `${data.firstName} ${data.lastName}`,
          value: data.participantUserId
        };
      }), _.isEqual));
      return (
        <div {...this.props}>
          <styles.MobileContainer>
            <styles.MobileDataTableTitle>
              User Management
            </styles.MobileDataTableTitle>
            <styles.MobileDropDownContainer>
              {userAcounts && userAcounts.length > 0 ? (
                <MobileDropDownField
                  component="bs-select"
                  options={dropdownOptions}
                  onChange={value => this.setState({ option: value })}
                />
              ) : (
                  <styles.NoDataContainer>
                    {NoDataAvailableText}
                  </styles.NoDataContainer>
                )}

            </styles.MobileDropDownContainer>
            {this.renderMobileTable(
              this.props,
              headerNames,
              userAcounts,
              () => this.state.option
            )}
          </styles.MobileContainer>
        </div>
      );
    }
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { getParticipants, createParticipantsSave },
    dispatch
  )
});

export default register("alcon-account/UserManagmentControl")(
  connect(null, mapDispatchToProps),
  withTheme()
)(UserManagmentControl);
export { UserManagmentControl };
