import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Card, HeaderThree, Text } from 'rsv8-components';
import { CustomFields } from 'rsv8-customfields';
import { Field, formSelectors, reduxForm } from 'rsv8-forms';
import { withWizard } from 'rsv8-wizard';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';
import { numberFormat } from 'xcel-util';
import PointCost from '../../components/PointCost/index';
import { individualNominationActions } from '../../redux/actions/index';
import { individualNominationSelectors } from '../../redux/selectors/index';
const { selectCustomFields, selectPoints: selectPointValue, save: saveNomination } = individualNominationActions;

const StyledForm = styled.form`
  .help-block {
    margin: 0;
  }
`;

const StyledLabel = styled.label`
  &::after {
    color: red;
    content: ' *';
  }
` as any;

const PointWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  .form-group {
    width: 50%;
    order: 1;
    &.has-error {
      width: 100%;
      .error {
        margin-top: 25px;
      }
    }
    input {
      width: 150px;
    }
    select {
      width: 200px;
      & + .error.help-block {
        margin-top: 0;
      }
    }
  }
  .point-cost-text {
    width: 50%;
    order: 2;
  }
  @media screen and (max-width: 991px) {
    flex-direction: column;
    .form-group {
      width: 100%;
      margin-bottom: 0;
      &.has-error {
        width: 100%;
        .error {
          margin-top: 10px;
        }
      }
    }
    .point-cost-text {
      width: 100%;
      order: 4;
    }
  }
`;

const StyledText = styled(Text)`
  order: 3;
`;

class DetailForm extends React.Component<any, {}> {
  state = {
    isValid: true
  };
  componentDidMount() {
    if (this.props.wizard) {
      this.props.wizard.registerIncrement(this.props.handleSubmit(this.submit));
    }
  }
  componentDidUpdate(prevState: any) {
    if (this.state.isValid !== prevState.isValid) {
      this.props.pointOptions !== null && this.props.shouldFormSave(this.state.isValid);
    }
  }
  validatePoints = (point) => {
    const found =
      this.props.pointOptions.filter((pointOption) => {
        return pointOption.value && point && pointOption.value.toString() === point.toString();
      }).length !== 0;
    if (!found) {
      this.setState({ isValid: false });
      if (this.props.currentAward.pointRange !== undefined && this.props.currentAward.pointRange !== null) {
        return (
          <Text variationName="legal-small-text" dangerouslySetInnerHTML={{ __html: this.props.pointDisclaimer }} />
        );
      }
      return 'required';
    }
    this.setState({ isValid: true });
    return undefined;
  };

  submit = (values: any) => {
    if (values.pointCount === undefined && this.props.pointOptions.length > 0) {
      this.props.actions.selectPointValue(this.props.pointOptions[0].value);
    }
    this.props.actions.selectCustomFields(values.NominationForm);
    this.props.actions.saveNomination();
  };

  onPointChange = (e, points) => this.props.actions.selectPointValue(points);

  render() {
    const {
      handleSubmit,
      pointOptions,
      currentAward,
      pointLabel,
      pointTitle,
      pointDescription,
      pointDisclaimer,
      detailTitle,
      detailDescription,
      selectPointLabel,
      formValues,
      nominee,
      parentIds
    } = this.props;
    // i didn't see a field that i could use to get this value, so i have to try it this way.
    const hasPointRange = currentAward.pointRange !== undefined && currentAward.pointRange !== null;
    const pointComponentType = hasPointRange ? 'bs-input' : 'bs-select';
    return (
      <React.Fragment>
        <StyledForm onSubmit={handleSubmit(this.submit)}>
          {pointOptions !== null && pointOptions.length >= 1 ? (
            <Card variationName="nomination-card">
              <HeaderThree>{pointTitle}</HeaderThree>
              {pointDescription && <Text>{pointDescription}</Text>}
              <PointWrapper>
                <Field
                  name="pointCount"
                  onChange={this.onPointChange}
                  component={pointComponentType}
                  label={<StyledLabel>{pointLabel}</StyledLabel>}
                  options={pointOptions}
                  defaultSelectText={selectPointLabel}
                  validate={[this.validatePoints]}
                />
                {hasPointRange && this.state.isValid && (
                  <StyledText variationName="legal-small-text" dangerouslySetInnerHTML={{ __html: pointDisclaimer }} />
                )}
                {currentAward.displayPointCostAnalysis && this.state.isValid && (
                  <div className="point-cost-text">
                    <PointCost
                      id={currentAward.id}
                      pointValue={(formValues && formValues.pointCount) || (pointOptions && pointOptions[0].value)}
                      nomineeId={nominee.id}
                    />
                  </div>
                )}
              </PointWrapper>
            </Card>
          ) : null}
          <Card variationName="nomination-card">
            <HeaderThree>{detailTitle}</HeaderThree>
            {detailDescription && <Text>{detailDescription}</Text>}
            <CustomFields formType="NominationForm" groupKey="details" appKey={currentAward.id} parentIds={parentIds} />
          </Card>
        </StyledForm>
      </React.Fragment>
    );
  }
}

const mapState = (state) => {
  const currentAward = individualNominationSelectors.getCurrentAward(state);
  const customFields = individualNominationSelectors.getCurrentCustomFields(state);
  const pointRangeMaximum = currentAward.pointRange ? numberFormat(currentAward.pointRange.maximum) : null;
  const nominationForm = individualNominationSelectors.getCurrentCustomFieldMap(state);
  const formValues = formSelectors.getFormValues('NominationDetailForm')(state);
  return {
    nominee: individualNominationSelectors.getCurrentNominee(state),
    pointOptions: individualNominationSelectors.getCurrentAwardPointOptions(state),
    formValues: formValues,
    currentAward,
    pointRangeMaximum,
    initialValues: {
      NominationForm: nominationForm,
      pointCount: individualNominationSelectors.getCurrentNominationPoints(state) || null
    },
    parentIds: customFields
  };
};

const mapDispatch = (dispatch) => ({
  actions: bindActionCreators({ selectCustomFields, selectPointValue, saveNomination }, dispatch)
});
const mapResourceToProps = (getResource) => {
  return {
    pointLabel: getResource('individualNomination.detailform.pointLabel', 'Point Value'),
    pointTitle: getResource('individualNomination.detailform.pointTitle', 'Select Award'),
    pointDescription: getResource(
      'individualNomination.detailform.pointDescription',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    ),
    pointDisclaimer: getResource(
      'individualNomination.detailform.pointDisclaimer',
      'Must be in increments of {currentAward.pointRange.increment}<br />({currentAward.pointRange.minimum} - {currentAward.pointRange.maximum} points)'
    ),
    detailTitle: getResource(
      'individualNomination.detailform.detailTitle',
      'Tell us a little bit about this nomination'
    ),
    detailDescription: getResource(
      'individualNomination.detailform.detailDescription',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. '
    ),
    selectPointLabel: getResource('nominationDetails.SelectPointLabel', 'Please Select')
  };
};
export default compose<any>(
  connect(
    mapState,
    mapDispatch
  ),
  reduxForm({ form: 'NominationDetailForm' }),
  withResource(mapResourceToProps),
  withWizard()
)(DetailForm);
