import * as React from 'react';
import { Col, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { bindActionCreators } from 'redux';
import { Link, Loader, Text } from 'rsv8-components';
import { register, withContent, withTheme } from 'xcel-react-core';
import { withResource } from 'xcel-react-core';
import { getReduxStore } from 'xcel-redux-module';
import { ROOT_STATE } from '../../../constants';
import { dispatchTimePeriod } from '../../../redux/actions';
import { getCalculations } from '../../../redux/actions/getCalculations';
import { getCalculatorGoalsCombinedByCalculatorKey } from '../../../redux/actions/getCalculatorGoalsCombinedByCalculatorKey';
import * as utils from '../../../utils';
import {
  MetricCard,
  MetricEmptyTableCell,
  MetricEmptyText,
  MetricMobileCountRow,
  MetricMobileFinalCountRow,
  MetricMobileMetricRowsIncuded,
  MetricMobileTable,
  MetricMobileTableCell,
  MetricMobileTableLabelCell,
  MetricMobileTableLabelText,
  MetricMobileTableRow,
  MetricMobileTableText,
  MetricNoDataFoundTable,
  MetricTableCell,
  MetricTableFooterCell,
  MetricTableFooterRow,
  MetricTableHeaderCell,
  MetricTableHeaderRow,
  MetricTableRow,
  MobileMetricCard
  } from './Styles/MetricsGoalsStyles';

export interface Props {
  key: any;
  GoalCountText: string;
  FinalCountText: string;
  EarnedPointsText: string;
  TotalPointsFooterText: string;
  TotalPointsFooterColor: string;
  TimePeriodKey: string;
  actions: any;
  combinedMetricGoals: any;
  ProgramKey: any;
  CalculatorKey: any;
  ChooseMetricDataToView: any;
  metricsGoals: any;
  newTimePeriodKey: any;
  SelectorKey: any;
  newGoals: any;
  LoadMoreButtonText: string;
  NoDataAvailableText: string;
  className: string;
}
export class CombinedMetricGoalsTable extends React.Component<Props, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      isLoaded: false,
      stateCalcGoals: [],
      setTotalEarnedPoints: '',
      SelectorKey: '',
      numOfMobileTableRows: 2,
      defTimePeriodKey: ''
    };
  }

  componentDidUpdate(prevProps: Props, prevState: any, snapshot: any) {
    let pSelectorKey: string = prevProps.SelectorKey;
    let rSelectorKey: string = this.getSelectedDataKey();
    let rKey: string = this.getSelectedKey();

    if (prevState === this.state && prevProps !== this.props) {
      if (pSelectorKey === rSelectorKey) {
        // empty key so stop loader
        if (rKey === '' || rKey === undefined) {
          this.setLoader(true);
        }
        if (rKey !== '' && rKey !== undefined) {
          if (this.state.timePeriodKey !== rKey) {
            this.setState({ isLoaded: false });

            this.setState({ SelectorKey: rSelectorKey });
            this.setState({ timePeriodKey: rKey });

            const { ProgramKey, CalculatorKey }: any = this.props;
            this.props.actions
              .getCalculations({ programKey: ProgramKey, calculatorKey: CalculatorKey, key: rKey })
              .then((Response) => {
                Response !== undefined
                  ? this.setState({
                      stateCalcGoals: this.showConfiguredRows(Response.data)
                    })
                  : this.setLoader(true);
              });
          }
        }
      }
    }
  }

  componentDidMount() {
    try {
      // this.setState({isLoaded true});
    } catch (error) {
      console.log('RF Error Found! In Metric Table Component API Call (Default Data): ', error);
    }
  }
  showConfiguredRows(calcGoalsCombined: any) {
    let newGoals = calcGoalsCombined;
    let arrNewGoals = [];
    let chosenGoals = this.props.ChooseMetricDataToView;
    let showGoals = [];

    if (newGoals !== undefined) {
      arrNewGoals = Object.keys(newGoals).map((key) => newGoals[key]);
      if (chosenGoals !== undefined) {
        let currKey = '';
        let chosenGoalKey = '';
        for (let i = 0; i <= arrNewGoals.length - 1; i++) {
          currKey = arrNewGoals[i].attributes.key;
          for (let n = 0; n <= chosenGoals.length - 1; n++) {
            chosenGoalKey = chosenGoals[n].key;
            if (chosenGoalKey === currKey) {
              showGoals.push(arrNewGoals[i]);
              break;
            }
          }
        }
      }
    }
    this.setLoader(true);
    return showGoals;
  }

  getSelectedDataKey() {
    let selectedDataKey;
    const store = getReduxStore();
    const rState = store.getState();
    const timep = rState[ROOT_STATE].timePeriod;
    if (timep !== undefined) {
      if (timep.data !== undefined) {
        selectedDataKey = timep.data.metricDataKey;
      }
    }
    return selectedDataKey;
  }

  getSelectedKey() {
    let selectedKey;
    const store = getReduxStore();
    const rState = store.getState();
    const timep = rState[ROOT_STATE].timePeriod;
    if (timep !== undefined) {
      if (timep.data !== undefined) {
        selectedKey = timep.data.metricKey;
      }
    }
    return selectedKey;
  }
  async getDefaultTimePCalculations(ProgramKey: any, CalculatorKey: any, DataKey: any, key: any) {
    if (key && key !== '' && key !== '' && key !== undefined) {
      try {
        await this.props.actions
          .getCalculations({ programKey: ProgramKey, calculatorKey: CalculatorKey, key: key })
          .then((Response) => {
            Response !== undefined ? this.setState({ stateCalcGoals: this.showConfiguredRows(Response.data) }) : null;
          });
      } catch (error) {
        console.log(error);
      }
    }
  }

  sortCalcGoalsCombined(calcGoalsCombined: any) {
    if (calcGoalsCombined !== undefined) {
      return calcGoalsCombined.sort(function(a: any, b: any) {
        return a.attributes.sortOrder - b.attributes.sortOrder;
      });
    } else {
      return calcGoalsCombined;
    }
  }

  getCalculatedFinalPoints(calcGoalsCombined: any) {
    let total = 0;
    if (calcGoalsCombined !== undefined) {
      total = calcGoalsCombined.reduce(
        (accumulator: any, currentValue: any) =>
          Number(accumulator) + Number(currentValue.attributes.calculatedMetricValue),
        0
      );
    }
    return total;
  }

  setNumOfMobileTableRows(numOfRows: any) {
    this.setState({ numOfMobileTableRows: numOfRows });
  }

  loadMoreMobileRows(NumOfRows: any) {
    this.setNumOfMobileTableRows(NumOfRows);
  }

  renderMobileLoadMoreButton(totalNumOfRows: any) {
    return (
      <div style={{ backgroundColor: 'transparent', paddingTop: '10px', border: '0px' }}>
        <Link themeVariation="secondary-button-full-width" onClick={() => this.loadMoreMobileRows(totalNumOfRows)}>
          <span>{this.props.LoadMoreButtonText}</span>
        </Link>
      </div>
    );
  }

  renderMobileTable = (CalcGoalsCombined: any) => {
    if (CalcGoalsCombined !== undefined && CalcGoalsCombined.length > 0) {
      const totalCalcGoalsCombined = CalcGoalsCombined.length;
      CalcGoalsCombined = this.sortCalcGoalsCombined(CalcGoalsCombined);
      const numShowRows: number = this.state.numOfMobileTableRows - 1;

      return CalcGoalsCombined.map((goals, index) =>
        index <= numShowRows ? (
          <div style={{ border: '0px', backgroundColor: 'transparent' }} {...this.state.props}>
            <MetricMobileTable>
              <tbody>
                <MetricMobileTableRow>
                  <MetricMobileTableLabelCell colSpan="3">
                    <MetricMobileTableLabelText>
                      <b>{utils.ToUpperCase(goals.attributes.label)}</b>
                    </MetricMobileTableLabelText>
                  </MetricMobileTableLabelCell>
                </MetricMobileTableRow>
                {this.getmobileGoalCountText(goals.attributes, this.props)}
                {this.getmobileFinalCountText(goals.attributes, this.props)}
                {this.getmobileEarnedPointsText(goals.attributes, this.props)}
              </tbody>
            </MetricMobileTable>
            {index === numShowRows && numShowRows + 1 < totalCalcGoalsCombined
              ? this.renderMobileLoadMoreButton(totalCalcGoalsCombined)
              : ' '}
          </div>
        ) : (
          ''
        )
      );
    } else {
      return (
        <div style={{ border: '0px', backgroundColor: 'transparent', textAlign: 'center' }} {...this.state.props}>
          {this.noDataFoundResponse()}
        </div>
      );
    }
  };

  getMobileFinalPointsTotal(CalcGoalsCombined: any) {
    let finalPoints = 0;
    try {
      finalPoints = this.getCalculatedFinalPoints(CalcGoalsCombined);
    } catch (error) {
      console.log('Error Found!', error);
    }
    return this.getTotalEarnedPoints(finalPoints);
  }

  getmobileEarnedPointsText(goalsAttributes: any, props: Props) {
    return (
      <MetricMobileTableRow style={{ borderLeft: '0px', borderBottom: 'solid 1px #f1f1f1', width: '100%' }}>
        <MetricMobileTableCell style={{ width: '100%', textAlign: 'left', verticalAlign: 'middle' }}>
          <MetricMobileTableText>{props.EarnedPointsText}</MetricMobileTableText>
        </MetricMobileTableCell>
        <MetricMobileTableCell style={{ textAlign: 'right', verticalAlign: 'middle' }}>
          <MetricMobileTableText>{utils.returnZero(goalsAttributes.calculatedMetricValue)}</MetricMobileTableText>
        </MetricMobileTableCell>
      </MetricMobileTableRow>
    );
  }

  getmobileGoalCountText(goalsAttributes: any, props: Props) {
    return (
      <MetricMobileTableRow style={{ borderLeft: '0px', borderBottom: 'solid 1px #f1f1f1', width: '100%' }}>
        <MetricMobileTableCell style={{ width: '100%', textAlign: 'left', verticalAlign: 'middle' }}>
          <MetricMobileTableText>{props.GoalCountText}</MetricMobileTableText>
        </MetricMobileTableCell>
        <MetricMobileTableCell style={{ textAlign: 'right', verticalAlign: 'middle' }}>
          <MetricMobileTableText>{utils.returnZero(goalsAttributes.goalValue)}</MetricMobileTableText>
        </MetricMobileTableCell>
      </MetricMobileTableRow>
    );
  }
  getmobileFinalCountText(goalsAttributes: any, props: Props) {
    return (
      <MetricMobileTableRow style={{ borderLeft: '0px', borderBottom: 'solid 1px #f1f1f1', width: '100%' }}>
        <MetricMobileTableCell style={{ width: '100%', textAlign: 'left', verticalAlign: 'middle' }}>
          <MetricMobileTableText>{props.FinalCountText}</MetricMobileTableText>
        </MetricMobileTableCell>
        <MetricMobileTableCell style={{ textAlign: 'right', verticalAlign: 'middle' }}>
          <MetricMobileTableText>{utils.returnZero(goalsAttributes.userMetricValue)}</MetricMobileTableText>
        </MetricMobileTableCell>
      </MetricMobileTableRow>
    );
  }

  getmobileMetricRowsIncluded(CalcGoals: any) {
    let totalCount = CalcGoals !== undefined && CalcGoals.length > 0 ? CalcGoals.length : 0;
    let getCount = CalcGoals !== undefined && CalcGoals.length > 0 ? this.state.numOfMobileTableRows : totalCount;
    getCount = getCount > totalCount ? totalCount : getCount;

    if (totalCount > 0) {
      return (
        <Table
          hover={true}
          condensed={true}
          style={{ verticalAlign: 'center', border: '0px', borderLeft: '0px', borderRight: '0px' }}
        >
          <MetricMobileCountRow>
            <MetricMobileMetricRowsIncuded
              colSpan="4"
              style={{
                textAlign: 'center',
                verticalAlign: 'middle',
                border: '0px',
                borderLeft: '0px',
                borderRight: '0px'
              }}
            >
              Showing {getCount} of {totalCount}{' '}
            </MetricMobileMetricRowsIncuded>
          </MetricMobileCountRow>
        </Table>
      );
    } else {
      return '';
    }
  }

  getTotalEarnedPoints(totalEarnedPoints: number) {
    return (
      <Table
        width={'100%'}
        striped={true}
        bordered={true}
        hover={true}
        condensed={true}
        style={{ verticalAlign: 'center' }}
      >
        <MetricMobileFinalCountRow
          style={{
            width: '100%',
            padding: '0px',
            color: 'black',
            borderColor: this.props.TotalPointsFooterColor,
            backgroundColor: this.props.TotalPointsFooterColor
          }}
        >
          <MetricMobileTableCell colSpan="3" style={{ width: '100%', align: 'left' }} key="totalearnedpointtextCell">
            <MetricMobileTableText>{this.props.TotalPointsFooterText}</MetricMobileTableText>
          </MetricMobileTableCell>
          <MetricMobileTableCell colSpan="1" style={{ width: '100%', align: 'right' }} key="totalearnedpointsCell">
            <MetricMobileTableText>{totalEarnedPoints}</MetricMobileTableText>
          </MetricMobileTableCell>
        </MetricMobileFinalCountRow>
      </Table>
    );
  }

  renderTable = (CalcGoalsCombined: any) => {
    if (CalcGoalsCombined !== undefined && CalcGoalsCombined.length > 0) {
      let finalPoints = 0;
      finalPoints = this.getCalculatedFinalPoints(CalcGoalsCombined);
      CalcGoalsCombined = this.sortCalcGoalsCombined(CalcGoalsCombined);

      return (
        <Table style={{ verticalAlign: 'middle', padding: '25px' }}>
          <MetricTableHeaderRow key="tablerowHeaderCell">
            <MetricTableHeaderCell key="emptyHeaderCell">
              <Text variationName="metric-table-text" />
            </MetricTableHeaderCell>
            <MetricTableHeaderCell key="goalcoundHeaderCell">
              <Text variationName="metric-table-text">{this.props.GoalCountText}</Text>
            </MetricTableHeaderCell>
            <MetricTableHeaderCell key="finalcountHeaderCell">
              <Text variationName="metric-table-text">{this.props.FinalCountText}</Text>
            </MetricTableHeaderCell>
            <MetricTableHeaderCell key="earnedpointsHeaderCell">
              <Text variationName="metric-table-text">{this.props.EarnedPointsText}</Text>
            </MetricTableHeaderCell>
          </MetricTableHeaderRow>
          <tbody>{this.renderRows(CalcGoalsCombined)} </tbody>
          <tfoot>{this.renderFooter(finalPoints)}</tfoot>
        </Table>
      );
    } else {
      return this.noDataFoundResponse();
    }
  };

  noDataFoundResponse() {
    let text = this.props.NoDataAvailableText;
    return (
      <MetricNoDataFoundTable>
        <MetricTableRow style={{ border: '3px' }} key="emptygoals">
          <MetricEmptyTableCell
            className="empty-table-cell"
            key="emptytablecell"
            colSpan="4"
            style={{ textAlign: 'center', verticalAlign: 'middle' }}
          >
            <MetricEmptyText themeVariation="metric-nodata-text">{text}</MetricEmptyText>
          </MetricEmptyTableCell>
        </MetricTableRow>
      </MetricNoDataFoundTable>
    );
  }

  renderRows(CalcGoals: any) {
    return CalcGoals.map((goals, index) => (
      <MetricTableRow key={'row' + index}>
        <MetricTableCell
          style={{ textAlign: 'left', verticalAlign: 'middle', paddingRight: '20px', paddingLeft: '20px' }}
        >
          <Text variationName="metric-table-text-wrap">{goals.attributes.label}</Text>
        </MetricTableCell>
        <MetricTableCell style={{ verticalAlign: 'middle', textAlign: 'center' }}>
          <Text variationName="metric-table-text">{utils.returnZero(goals.attributes.goalValue)}</Text>
        </MetricTableCell>
        <MetricTableCell style={{ verticalAlign: 'middle', textAlign: 'center' }}>
          <Text variationName="metric-table-text">{utils.returnZero(goals.attributes.userMetricValue)}</Text>
        </MetricTableCell>
        <MetricTableCell style={{ verticalAlign: 'middle', textAlign: 'center' }}>
          <Text variationName="metric-table-text">{utils.returnZero(goals.attributes.calculatedMetricValue)}</Text>
        </MetricTableCell>
      </MetricTableRow>
    ));
  }

  renderError = (error?: any) => {
    console.log('Error Found!' + error);
    const errorText = '' + ' ';
    return (
      <Col sm={20}>
        <div>
          <i>{errorText + JSON.stringify(error)}</i>
        </div>
      </Col>
    );
  };

  renderFooter(TotalEarnedPoints: number) {
    return (
      <MetricTableFooterRow style={{ color: 'black', backgroundColor: this.props.TotalPointsFooterColor }}>
        <MetricTableFooterCell
          colSpan="2"
          style={{ paddingRight: '20px', paddingLeft: '20px', textAlign: 'left', verticalAlign: 'middle' }}
          key="footerlabelCell"
        >
          <Text variationName="metric-table-text">
            <b>{this.props.TotalPointsFooterText}</b>
          </Text>
        </MetricTableFooterCell>
        <MetricTableFooterCell colSpan="1" key="totalearnedpointsEmptyCell" />
        <MetricTableFooterCell
          colSpan="1"
          style={{ textAlign: 'center', verticalAlign: 'middle' }}
          key="totalearnedpointsCell"
        >
          <Text variationName="metric-table-text">
            <b>{TotalEarnedPoints}</b>
          </Text>
        </MetricTableFooterCell>
      </MetricTableFooterRow>
    );
  }

  setLoader(Load: boolean) {
    setTimeout(() => {
      this.setState({ isLoaded: Load });
    }, 1100);
  }

  render() {
    const sCalcGoals = this.state.stateCalcGoals;
    if (this.state.isLoaded === false) {
      return <Loader width={60} strokeWidth={5} />;
    }

    return (
      <div className={this.props.className} {...this.state.props}>
        <MediaQuery query="(min-width: 768px)">
          <MetricCard className="metric-card" {...this.state.props}>
            {this.renderTable(sCalcGoals)}
          </MetricCard>
        </MediaQuery>
        <MediaQuery query="(max-width: 767px)">
          <MobileMetricCard className="mobile-metric-card" {...this.state.props}>
            {this.getMobileFinalPointsTotal(sCalcGoals)}
            {this.getmobileMetricRowsIncluded(sCalcGoals)}
            {this.renderMobileTable(sCalcGoals)}
          </MobileMetricCard>
        </MediaQuery>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  newTimePeriod: dispatchTimePeriod(state)
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ getCalculations, getCalculatorGoalsCombinedByCalculatorKey }, dispatch)
});

const mapContentToProps = (getContent) => ({
  ChooseMetricDataToView: getContent('ChooseMetricDataToView', {
    type: 'array',
    schema: [{ model: 'key', label: 'Show Metric Data Entry (enter the key):' }]
  }),
  ProgramKey: getContent('ProgramKey', {
    type: 'string',
    schema: [{ model: 'Program Key:', label: 'Enter Program Key:' }]
  }),
  CalculatorKey: getContent('CalculatorKey', {
    type: 'string',
    schema: [{ model: 'Calculator Key:', label: 'Enter Calculator Key:' }]
  }),
  TotalPointsFooterText: getContent('TotalPointsFooterText', {
    type: 'string',
    schema: [{ model: 'Total Points Text:', label: 'Enter Total Points Footer Text:' }]
  }),
  TotalPointsFooterColor: getContent('TotalPointsFooterColor', {
    type: 'string',
    schema: [{ model: 'Color for the Total Points Footer:', label: 'Enter a Color for the Table Footer:' }]
  }),
  SelectorKey: getContent('Enter Data Key', {
    type: 'string',
    schema: [{ model: 'Data Key:', label: 'Enter Data Key' }]
  }),
  LoadMoreButtonText: getContent('LoadMoreButtonText', {
    type: 'string',
    schema: [
      { model: 'Add text for the Mobile Load More Action Buttion:', label: 'Enter text when no data is available:' }
    ]
  }),
  NoDataAvailableText: getContent('NoDataAvailableText', {
    type: 'string',
    schema: [{ model: 'Add Text to show when no data is available', label: 'No Data Available:' }]
  }),
  GoalCountText: getContent('GoalCountText', {
    type: 'string',
    schema: [{ model: 'Goal Count Header:', label: 'Enter Goal Count Header' }]
  }),
  FinalCountText: getContent('FinalCountText', {
    type: 'string',
    schema: [{ model: 'Final Count Header:', label: 'Enter Final Count Header:' }]
  }),
  EarnedPointsText: getContent('EarnedPointsText', {
    type: 'string',
    schema: [{ model: 'Earned Points Header', label: 'Enter Earned Points Header:' }]
  })
});

const mapResourceToProps = (getResource: any) => ({});

export default register('rsv8-metrics/CombinedMetricGoalsTable')(
  connect(mapStateToProps, mapDispatchToProps),
  withResource(mapResourceToProps),
  withContent(mapContentToProps),
  withTheme()
)(CombinedMetricGoalsTable);
