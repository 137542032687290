import * as React from 'react';
import { Col, FormControl, Row } from 'react-bootstrap';
import { Card, CardBody, CardVertical } from 'rsv8-components';
import styled from 'styled-components';
import { Resources } from '../../types/index';
import { GcRow, ProductFormGroup, SpaceHolder } from './styles';

export interface ProductProps {
  product: Resources.Product;
  reduxOptionId: string;
  handleOption: (id: string, pointValue: number) => any;
  onClick: (event: any) => void;
}

const ProductCardVertical = styled(CardVertical)`
  margin-top: -15px;
  margin-bottom: -5px;
`;

const Product: React.SFC<ProductProps> = (props) => {
  function handleChange(e: any) {
    // capture the "selectedIndex" of the option selected (from e.currentTarget).
    const selectedIdx = (e.currentTarget as any).selectedIndex;
    // Then using the selected option based on the selected index, deconstruct out the id and the value props
    const { id: optionId, value: pointValue } = (e.currentTarget as any).options[selectedIdx];
    // parse into number
    let pointValueNum = parseInt(pointValue, 10);
    // execute the handleOption, passing option at selectedIndex' id and pointValue to redux.
    setTimeout(() => {
      props.handleOption(optionId, pointValueNum)(e), 1000;
    });
  }

  return (
    <Card variationName="product-card-selection" className="ProductCard">
      <GcRow>
        <Col>
          <div>
            <h4>{props.product.name}</h4>
          </div>
        </Col>
      </GcRow>
      <CardBody>
        <Row>
          <ProductCardVertical
            backgroundSize={'contain'}
            backgroundImage={props.product.imageUrl}
            backgroundRepeat={'no-repeat'}
            height={'205px'}
          />
        </Row>
        <Row className="text-center">
          {props.product.options.length > 0 ? (
            <React.Fragment>
              <ProductFormGroup>
                <FormControl onChange={(e) => handleChange(e)} componentClass="select" name={props.product.id}>
                  <option className="primary-text">Options Available</option>
                  {props.product.options.map((option, i) => (
                    <option
                      key={`${option.id}-${i}`}
                      selected={props.reduxOptionId === option.id}
                      className="primary-text"
                      id={option.id}
                      value={option.pointValue}
                    >
                      ${option.pointValue}
                    </option>
                  ))}
                </FormControl>
              </ProductFormGroup>
            </React.Fragment>
          ) : (
            <SpaceHolder />
          )}
        </Row>
      </CardBody>
    </Card>
  );
};

export default Product;
