import * as React from 'react';
import { ComponentType } from 'react';
import { Row } from 'react-bootstrap';
import { Drawer, GridColumn, Text } from 'rsv8-components';
import { DataTableColumn, DataTableDefault, SORT_DIRECTIONS } from 'rsv8-components';
import { ApiFilters } from 'rsv8-forms';
import { register, withContent, withResource } from 'xcel-react-core';
import { ApiDataProviderProps, withApiDataProvider } from 'xcel-redux-orm';
import { NominationStatusBlock, ViewDetailsIcon } from '../../../components/index';
import { individualNominationHistorySelectors, nominationActions } from '../../../redux/index';

const renderDate = ({ record, column }) => new Date(record[column.field]).toLocaleDateString();
const renderStatus = ({ record, column }) => <NominationStatusBlock data={record} field={column.field} />;

const renderContextMenu = ({ record, isMobile }) => (
  <React.Fragment>
    <ViewDetailsIcon detailType={'NominationHistoryIndividual'} data={record} />
    {isMobile ? (
      <Text themeVariation="nomination-name">
        {record.firstName} {record.lastName}
      </Text>
    ) : null}
  </React.Fragment>
);

const defaultSort = { field: 'createDate', direction: SORT_DIRECTIONS.desc };
const fields = ['applicationKey', 'statusId'];
const IndividualNominationHistoryTable: React.SFC<
  ApiDataProviderProps<ReturnType<typeof individualNominationHistorySelectors.selectMany>> &
    ReturnType<typeof mapResource>
> = (props) => {
  const { columnLabels, emptyText, data } = props;

  return (
    <Row>
      {data.length !== 0 && (
        <GridColumn themeVariation="data-table-filter" md={6}>
          <Drawer start="sm" buttonText="Filter">
            <ApiFilters filters={props.filters} fields={fields} onChange={props.filter} />
          </Drawer>
        </GridColumn>
      )}
      <GridColumn themeVariation="data-table-with-filter" md={data.length !== 0 ? 18 : 24}>
        <DataTableDefault {...props} defaultSort={defaultSort} emptyIcon="icon-complete" emptyText={emptyText}>
          <DataTableColumn renderCell={renderContextMenu} width={'45px'} />
          <DataTableColumn field="createDate" renderCell={renderDate}>
            {columnLabels.createDate}
          </DataTableColumn>
          <DataTableColumn field="firstName" className="hidden-xs">
            {columnLabels.firstName}
          </DataTableColumn>
          <DataTableColumn field="lastName" className="hidden-xs">
            {columnLabels.lastName}
          </DataTableColumn>
          <DataTableColumn field="status" renderCell={renderStatus}>
            {columnLabels.status}
          </DataTableColumn>
          <DataTableColumn field="applicationName">{columnLabels.applicationName}</DataTableColumn>
          <DataTableColumn field="awardType">{columnLabels.awardType}</DataTableColumn>
        </DataTableDefault>
      </GridColumn>
    </Row>
  );
};
const mapResource = (getResource) => ({
  columnLabels: {
    createDate: getResource('nominationActivity.dateCreatedTableHeader', 'Date'),
    firstName: getResource('nominationActivity.firstNameTableHeader', 'First Name'),
    lastName: getResource('nominationActivity.lastNameTableHeader', 'Last Name'),
    status: getResource('nominationActivity.awardStatusTableHeader', 'Status'),
    applicationName: getResource('nominationActivity.awardNameTableHeader', 'Award'),
    awardType: getResource('nominationActivity.awardTypeTableHeader', 'Type')
  },
  emptyText: getResource('individualHistoryTable.emptyText', 'There are no nominations in your history')
});

export default register('rsv8-nomination/IndividualNominationHistoryTable')(
  withContent(),
  withResource(mapResource),
  withApiDataProvider(nominationActions.getNominationsHistory, individualNominationHistorySelectors, {
    sort: defaultSort
  })
)(IndividualNominationHistoryTable) as ComponentType<{}>;
