import styled from 'styled-components';

export const PrimaryNavStyledWrap = styled.div`
  padding-left: 0;
  padding-right: 0;

  .more {
    visibility: hidden;
    @media screen and (max-width: 767px) {
      visibility: visible;
    }
  }
  .more-button {
    position: absolute;
    right: 0;
    background-color: white;
  }
  .navbar {
    border-radius: 0;
    margin-bottom: 0;
    background: #fff;
    min-height: auto;
    border: 0;
    overflow: hidden;
    position: static;

    .nav {
      white-space: nowrap;
      position: static;
      @media screen and (max-width: 767px) {
        white-space: normal;
      }
      .menu-item-icon {
        height: 24px;
        width: 24px;
      }
      li {
        background-size: 24px;
        background-repeat: no-repeat;
        background-position: 33px 18px;
        border-bottom: 4px solid transparent;
        /* border-top: 4px solid transparent; */
        margin: 0 15px;
        float: none;
        display: inline-block;
        position: static;
        &:first-child {
          margin-left: 0;
        }
        @media screen and (max-width: 767px) {
          padding: 0 25px !important;
        }
        @media screen and (min-width: 767px) {
          background-image: inherit !important;
        }

        &.open {
          a {
            background-color: transparent;
          }
        }

        a {
          color: #606060;
          font-size: 14px;
          padding: 15px 4px;
          background-color: transparent !important;
          @media screen and (max-width: 767px) {
            padding: 18px 4px 18px 4px;
            background-color: transparent !important;
          }
        }

        &.nav-link-selected {
          border-bottom: 4px solid transparent;
        }
        .dropdown-menu {
          -webkit-box-shadow: 0px 0px 21px -1px rgba(28, 39, 76, 0.13);
          -moz-box-shadow: 0px 0px 21px -1px rgba(28, 39, 76, 0.13);
          box-shadow: 0px 0px 21px -1px rgba(28, 39, 76, 0.13);
          border: 0;
          border-radius: 0;
          left: auto;
          padding: 0;
          li {
            background-size: 24px;
            background-repeat: no-repeat;
            background-position: 33px 18px;
            border-bottom: 0;
            margin: 0;
            padding: 0 20px;
            display: block;
            position: relative;
            @media screen and (min-width: 767px) {
              background-image: inherit !important;
            }
            &:hover {
              background-color: #f5f5f5;
            }
            a {
              background-color: transparent !important;
              padding: 10px 4px;
              @media screen and (max-width: 767px) {
                padding: 0 4px 0 20px !important;
              }
            }
            &.navTitleURL {
              border-bottom: 1px solid #ccc;
              a {
                font-size: 16px;
              }
            }
            .dropdown-menu {
              left: 100%;
              top: 0;
            }
          }
        }
      }
    }
  }
  &:after {
    clear: both;
    display: block;
    content: '';
  }
` as any;
