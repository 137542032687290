import { auth } from 'xcel-api-generator';

const authApi = {
  ...auth,
  createRefreshToken: (params, options) => {
    params = { token: { RefreshToken: params.token } };
    return auth.createRefreshToken(params, options);
  }
};

export default authApi as any;
