import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withProps } from 'recompose';
import { wizardSelectors } from 'rsv8-wizard';
import { register } from 'xcel-react-core';
import { INDIVIDUAL_NOMINATION_WIZARD_NAME } from '../../constants';
import { getCurrentAwardId } from '../../redux/selectors/individual';
import { StepHeader } from './Header/StepHeader';

const nameInstruction = 'nomination-instruction-individual';

const mapState = (state) => ({
  current: wizardSelectors.getCurrentVisibleStepIndex(state, INDIVIDUAL_NOMINATION_WIZARD_NAME) + 1,
  currentAwardId: _.toLower(getCurrentAwardId(state))
});

const reduxConnector = connect(
  mapState,
  null
);

const mapProps = (instruction) => ({
  name: nameInstruction,
  instruction: instruction,
  type: 'individual'
});

export const IndividualCriteriaInstructions = register('rsv8-nomination/IndividualCriteriaInstructions')(
  withRouter,
  reduxConnector,
  withProps(mapProps('criteria'))
)(StepHeader) as React.SFC<{}>;

export const IndividualProductSelectionInstructions = register(
  'rsv8-nomination/IndividualProductSelectionInstructions'
)(withRouter, reduxConnector, withProps(mapProps('product-selection')))(StepHeader) as React.SFC<{}>;

export const IndividualDetailInstructions = register('rsv8-nomination/IndividualDetailsInstructions')(
  withRouter,
  reduxConnector,
  withProps(mapProps('details'))
)(StepHeader) as React.SFC<{}>;

export const IndividualNomineeInstructions = register('rsv8-nomination/IndividualNomineeInstructions')(
  withRouter,
  reduxConnector,
  withProps(mapProps('nominee'))
)(StepHeader) as React.SFC<{}>;

export const IndividualAwardInstructions = register('rsv8-nomination/IndividualAwardInstructions')(
  withRouter,
  reduxConnector,
  withProps(mapProps('awards'))
)(StepHeader) as React.SFC<{}>;

export const IndividualProductInstructions = register('rsv8-nomination/IndividualProductInstructions')(
  withRouter,
  reduxConnector,
  withProps(mapProps('products'))
)(StepHeader) as React.SFC<{}>;

export const IndividualReviewInstructions = register('rsv8-nomination/IndividualReviewInstructions')(
  withRouter,
  reduxConnector,
  withProps(mapProps('review'))
)(StepHeader) as React.SFC<{}>;

export const IndividualCongratulationsInstructions = register('rsv8-nomination/IndividualNominationComplete')(
  withRouter,
  reduxConnector,
  withProps(mapProps('congratulations'))
)(StepHeader) as React.SFC<{}>;
