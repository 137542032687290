import * as React from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { register, withContent, withTheme } from 'xcel-react-core';
import ResponsiveTable from './ResponsiveTable';
import Table from './Table';
import TableCell from './TableCell';
import TableHeaderCell from './TableHeaderCell';

export interface TableProps {
  headerData: any;
  tableData: any;
  setComponentProperty: any;
  getContent: any;
  renderComponents: any;
  disableSort: boolean;
  className: any;
}

const StyledDeleteButton = styled.button`
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  border-radius: 4px !important;
  color: #fff !important;
  font-size: 12px !important;
  background-color: #ff0000;
` as any;

const TABLE_CELL_DATA = 'tableCellData';
const TABLE_HEADER_DATA = 'tableHeaderData';

const renderHtml = ({ column, record }) => {
  return <span dangerouslySetInnerHTML={{ __html: record[column.field] }} />;
};

const mapColumns = (header, index) => {
  return { field: index, label: header, renderCell: renderHtml };
};
class DynamicContentTable extends React.Component<TableProps, {}> {
  state = {
    tableData: []
  };
  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    return { tableData: nextProps.getContent(TABLE_CELL_DATA, { type: 'none', label: TABLE_CELL_DATA }) };
  }
  constructor(props: TableProps) {
    super(props);
  }

  componentDidMount() {
    this.setState(this.props.getContent(TABLE_CELL_DATA, { type: 'none', label: TABLE_CELL_DATA }));
  }

  render() {
    const { getContent, setComponentProperty, ...rest } = this.props;
    const isEditing = this.props['data-cms-editing'];
    let tableHeaderData = getContent(TABLE_HEADER_DATA, { type: 'none', label: TABLE_HEADER_DATA });
    if (!Array.isArray(tableHeaderData)) {
      tableHeaderData = [];
    }
    let tableCellData = this.state.tableData;
    if (!Array.isArray(tableCellData)) {
      tableCellData = [];
    }

    const addHeader = () => {
      setComponentProperty(TABLE_HEADER_DATA, [...tableHeaderData, null]);
    };
    const addRow = () => {
      // adds new Row Array to the data for the Rows/Tds
      setComponentProperty(TABLE_CELL_DATA, [...tableCellData, null]);
    };
    const handleClickRemoveIndCol = (index) => {
      tableHeaderData.splice(index, 1);
      setComponentProperty(TABLE_HEADER_DATA, tableHeaderData);
    };
    const handleClickRemoveIndRow = (trIndex) => {
      tableCellData.splice(trIndex, 1);
      setComponentProperty(TABLE_CELL_DATA, tableCellData);
    };

    const sortRows = (sort) => {
      return (rowA, rowB) => {
        const cellA = (rowA[sort.field] || '').toLowerCase();
        const cellB = (rowB[sort.field] || '').toLowerCase();

        if (cellA > cellB) {
          return sort.direction.toLowerCase() === 'asc' ? 1 : -1;
        }
        if (cellA < cellB) {
          return sort.direction.toLowerCase() === 'asc' ? -1 : 1;
        }
        return 0;
      };
    };

    const handleSort = (sort) => {
      const sortedData = tableCellData.sort(sortRows(sort));
      this.setState({ tableData: sortedData });
    };

    return (
      <div className={this.props.className}>
        <div>
          {isEditing && (
            <div>
              <Button bsStyle="success" onClick={addHeader}>
                Add Column
              </Button>
              <Button bsStyle="success" onClick={addRow}>
                Add Row
              </Button>
            </div>
          )}
        </div>
        <Table {...rest} onSort={handleSort} className="hidden-xs">
          <thead>
            <tr>
              {tableHeaderData &&
                tableHeaderData.map((headerData, index) => {
                  let deleteColButton = isEditing && (
                    <StyledDeleteButton
                      className="danger"
                      onClick={() => (window.confirm('Delete this Column?') ? handleClickRemoveIndCol(index) : '')}
                    >
                      X
                    </StyledDeleteButton>
                  );

                  return (
                    <TableHeaderCell
                      key={index}
                      field={isEditing ? undefined : index}
                      deleteButton={deleteColButton}
                      headerData={getContent(`${TABLE_HEADER_DATA}.${index}`)}
                      sortable={!this.props.disableSort}
                    />
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {tableCellData &&
              tableCellData.map((item, trIndex) => {
                return (
                  <tr key={trIndex} style={{ position: 'relative' }}>
                    {tableHeaderData &&
                      tableHeaderData.map((td, tdIndex) => {
                        return (
                          <TableCell key={tdIndex}>
                            {getContent(`${TABLE_CELL_DATA}.${trIndex}.${tdIndex}`, { type: 'html' })}
                          </TableCell>
                        );
                      })}
                    {isEditing && (
                      <StyledDeleteButton
                        bsStyle="danger"
                        style={{ position: 'absolute' }}
                        onClick={() => (window.confirm('Delete this Row?') ? handleClickRemoveIndRow(trIndex) : '')}
                      >
                        X
                      </StyledDeleteButton>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </Table>
        <ResponsiveTable
          className="hidden-sm hidden-md hidden-lg col-xs-24"
          columns={tableHeaderData.map(mapColumns)}
          data={tableCellData}
        />
      </div>
    );
  }
}

const mapContentToProps = (getContent) => ({
  getContent,
  disableSort: getContent('disableSort', { type: 'checkbox', label: 'Disable Sorting?' })
});

export default register('rsv8-components/DynamicContentTable')(withContent(mapContentToProps), withTheme())(
  DynamicContentTable
);
export { DynamicContentTable };
