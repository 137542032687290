import * as React from "react";
import { connect } from "react-redux";
import { ShipToLocation } from "xcel-api-generator/dist/alcon";
import * as selectors from "../../redux/selectors";
import { LocationDetail } from "./LocationDetail";
import { LocationBody, LocationContainer, LocationHeader } from "./styles";

const UnitsLocation: React.FC<Props> = ({ location, baselineYearLabel }) => {
    return (
        <LocationContainer key={location.shipToLocationId} className="location">
            <LocationHeader>
                <div className="location__title">{`${location.name} #${location.shipToLocationId}`}</div>
                <div className="location__subtitle">{location.addressOne}</div>
                {!!location.addressTwo && <div className="location__subtitle">{location.addressTwo}</div>}
            </LocationHeader>

            <LocationBody>
                <LocationDetail label={baselineYearLabel} amount={location.baseLineUnits} percentage={location.baseLinePercentage} />

                <LocationDetail label="CURRENT" amount={location.currentUnits} percentage={location.currentPercentage} />
            </LocationBody>
        </LocationContainer>
    )
}

const mapStateToProps = state => ({
    baselineYearLabel: selectors.baselineYearLabelSelector(state),
})

interface StateProps {
    baselineYearLabel: string;
}
interface DispatchProps { }
interface OwnProps {
    location: ShipToLocation;
}
type Props = StateProps & DispatchProps & OwnProps;

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps)(UnitsLocation);