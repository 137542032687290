const FPS = 1000 / 60;

/**
 * Interval - Basic function for animation base on time of execution
 *
 * @type function
 * @param duration - take a number in milliseconds
 * @param callback - action will be executed in each iterval
 */
export default function interval(callback: Function, duration: number, infinite = false) {
  const beginTransition = new Date().getTime();
  
  let timer = setInterval(() => {
    let currentTime = new Date().getTime() - beginTransition;
    if(currentTime <= duration) {
      callback(currentTime);
    } else {
      clearInterval(timer);
      if(infinite) {
        interval(callback, duration, infinite);
      }
    }
  }, FPS);
  
  return timer;
}