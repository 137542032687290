import * as React from 'react';
import { Alert } from 'react-bootstrap';

class BootstrapSuccessAlert extends React.Component {
  render() {
    return <Alert bsStyle="success">You did it!</Alert>;
  }
}

export default BootstrapSuccessAlert;
