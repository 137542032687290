import * as React from 'react';
import ParagraphField, { ParagraphFieldProps } from './ParagraphField';

export interface CustomParagraphProps extends ParagraphFieldProps {
  values: any[];
}

const CustomParagraph: React.SFC<CustomParagraphProps> = ({ values, field, ...rest }) => {
  const valueArray = values && values.values;
  const value =
    values && Array.isArray(valueArray) ? (valueArray.length > 1 ? valueArray.join(', ') : valueArray[0]) : valueArray;

  return value ? <ParagraphField value={value} field={field} {...rest} /> : null;
};

export default CustomParagraph;
