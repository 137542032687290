import { path } from 'ramda';
import { ClientCountryModel } from 'xcel-api-generator/dist/user';
import { createTypeSelector } from 'xcel-redux-orm';
import { Resource } from 'xcel-redux-orm';
import { ROOT_STATE } from '../constants';

const countrySelector = {
  ...createTypeSelector<ClientCountryModel>('clientCountryModel'),
  getAll: (state) => countrySelector.selectMany(state, null),
  getActive: (state) => path([ROOT_STATE, 'country', 'active'], state)
};
export { ClientCountryModel, Resource };
export default countrySelector;
