import * as React from 'react';
import { Field, Label } from 'rsv8-forms';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';

const FilterSelectContainer = styled.div`
  margin-bottom: 20px;
`;

const FieldSelect = styled(Field)`
  appearance: none;
  position: relative;
  width: 100%;
  padding: 7px 5px;
  border: 1px solid rgba(81, 81, 81, 0.3);
  font-size: 14px;
  color: #969696;
  border-radius: 3px;
  margin-bottom: 10px;
  cursor: pointer;
  background: #fff url('/clientassets/images/icons/dropdown.svg') no-repeat 96% center;
  background-size: 14px 14px;
` as any;

interface Props {
  awardTypeText: string;
  selectList: Array<Object>;
}

const FilterSelectType: React.SFC<Props> = ({ awardTypeText, selectList }) => (
  <FilterSelectContainer>
    <Label>{awardTypeText}</Label>
    <br />
    <FieldSelect name="awardSelectType" component="select">
      <option value="All">All </option>
      {selectList &&
        selectList.map((item: any, idx: number) => {
          return (
            <option key={`awards-filter-select-${idx}`} value={item.value}>
              {item.label} ({item.count})
            </option>
          );
        })}
    </FieldSelect>
  </FilterSelectContainer>
);

const mapResourceToProps = (getResource) => ({
  awardTypeText: getResource('FilterSelectType.awardTypeText', 'Award Type')
});

export default withResource(mapResourceToProps)(FilterSelectType);
