import { userStateSelectors } from 'rsv8-auth';
import { reduxObserver } from 'xcel-observer';
import { onSelectorChange } from 'xcel-react-core';
import { createReduxModule, ReduxModule } from 'xcel-redux-module';
import { destroy } from 'xcel-redux-orm';
import * as constants from './app/constants';
import { nominationApprovalActions } from './app/redux/actions';
import { reducers } from './app/redux/index';
const userResources = [
  constants.JSON_TYPE_INDIVIDUAL_DRAFT_NOMINATION,
  constants.JSON_TYPE_INDIVIDUAL_NOMINATION_HISTORY,
  constants.JSON_TYPE_TEAM_DRAFT_NOMINATION,
  constants.JSON_TYPE_TEAM_NOMINATION_HISTORY,
  constants.JSON_TYPE_PENDING_APPROVAL,
  constants.JSON_TYPE_APPROVAL_HISTORY
];

const nominationModule: ReduxModule = createReduxModule({
  name: 'rsv8-nomination',
  init: ({ store }) => {
    const userChanged = onSelectorChange(store, userStateSelectors.getUser);
    // this is so we can know if the user has pending approvals when the application loads
    // will almost certainly need this for navigation, so shouldn't move it to actual component using this info
    store.subscribe(() => {
      userChanged((current, next) => {
        if (next.tokenType === 'User') {
          store.dispatch(destroy(userResources));
          store.dispatch(nominationApprovalActions.getPending());
        }
      });
    });
  },
  reducers
});
reduxObserver.addModule(nominationModule);
export default nominationModule;
