import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { ReactField } from 'rsv8-forms';
import { Resource } from 'xcel-redux-orm';
import clientActions from '../../redux/actions/client';
import clientSelectors, { ClientModel } from '../../redux/selectors/client';
import { sortClients } from '../../util';
export interface CultureDropdownProps {
  dispatch: any;
  list: ClientModel[];
  active: ClientModel;
  onChange?: Function;
  label?: string;
  placeholder?: string;
  props?: any;
  component: any;
  [index: string]: any;
}
const ClientDropdown: React.SFC<CultureDropdownProps> = ({ dispatch, list, active, onChange, ...rest }) => {
  const handleChange = (value) => {
    if (onChange) onChange(value);
    const client = list.find((c) => c.id.toString() === value);
    if (client !== undefined) {
      dispatch(clientActions.setActive(client));
    }
  };
  let options =
    list &&
    list.sort(sortClients).map((client, index) => {
      return { label: client && client.name, value: client && client.id.toString() };
    });
  options.unshift({ label: 'Select Client', value: '' });
  return (
    <ReactField
      name="ClientDropdown"
      component="bs-select"
      {...rest}
      value={active && active.id}
      onChange={handleChange}
      options={options}
    />
  );
};
const mapStateToProps = (state) => ({
  list: clientSelectors.selectMany(state, null),
  active: clientSelectors.getActive(state)
});
export default connect(mapStateToProps)(ClientDropdown as any) as any;
export { DispatchProp, Resource };
