import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose, withProps } from 'recompose';
import { bindActionCreators } from 'redux';
import {
    Grid,
    Image,
    Loader,
    toast
} from 'rsv8-components';
import { nominationActions } from '../../redux/actions';
import MainForm from "./MainForm";
import RedeemedAwardForm from "./RedeemedAwardForm";
import TradedAwardForm from "./TradedAwardForm";
import { numberFormat } from "xcel-util";
import ErrorForm from "./ErrorForm";
import { withResource } from "xcel-react-core";
import RedeemedBeforeForm from "./RedeemedBefore";

export interface RedeemingGiftByEmailProps extends RouteComponentProps<{}> {
    code: string;
    getRedeem: typeof nominationActions.getRedeemCodeByCode;
    createRedeemCode: typeof nominationActions.createRedeemCode;

    resource: {
        failedToRedeemGiftToastHeader: string;
        failedToTradeGiftToastHeader: string;
    }
}

enum RedeemingState {
    Loading,
    Redeeming,
    ErrorReceivingCode,
    TradedForCredits,
    RedeemedAward,
    RedeemedBefore
}

export enum RedeemActions {
    Redeem = 'redeem',
    Trade = 'trade_for_points'
}

const failedToast = (message) => {
    return toast(
        { template: 'Default', themeVariation: 'error-text', message },
        { autoClose: false }
    );
};

class RedeemingGift extends React.Component<RedeemingGiftByEmailProps, {}> {
    state = {
        currentState: RedeemingState.Loading,
        error: false,
        actionsLoading: false,
        id: null,
        actions: [],
        awardValue: '',
        awardName: '',
        nomineeUser: {
            id: '',
            firstName: '',
            lastName: ''
        }
    };

    getRedeem = (code) => {
        const { currentState } = this.state;
        if (currentState !== RedeemingState.Loading) {
            this.setState({
                currentState: RedeemingState.Loading
            })
        }
        this.props.getRedeem({ code }).then(response => {
            const data = response.data;
            const { attributes } = data;
            this.setState({
                currentState: RedeemingState.Redeeming,
                id: data.id,
                actions: attributes.actions,
                awardValue: attributes.awardValue,
                awardName: attributes.awardName,
                nomineeUser: attributes.nomineeUser
            });
        }).catch((e) => {
            if(e && e.response && e.response.data && e.response.data.errors && e.response.data.errors[0] && e.response.data.errors[0].title === 'nomination_redeem_redeemed_before') {
                this.setState({
                    currentState: RedeemingState.RedeemedBefore,
                });
            } else {
                this.setState({
                    currentState: RedeemingState.ErrorReceivingCode,
                });
            }
        });
    };

    componentDidMount() {
        this.getRedeem(this.props.code);
    }

    componentDidUpdate(prevProps: RedeemingGiftByEmailProps, prevState: any) {
        if (prevProps.code !== this.props.code) {
            this.getRedeem(this.props.code);
        }
    }

    redeemClickHandler = () => {
        this.setState({
            actionsLoading: true
        });
        this.props.createRedeemCode({
            action: {
                id: this.state.id,
                action: RedeemActions.Redeem
            }
        }).then(() => {
            this.setState({
                actionsLoading: false,
                currentState: RedeemingState.RedeemedAward
            });
        }).catch(error => {
            this.setState({
                actionsLoading: false
            });
            failedToast(this.props.resource.failedToRedeemGiftToastHeader);
        });
    };

    tradeClickHandler = () => {
        this.setState({
            actionsLoading: true
        });
        this.props.createRedeemCode({
            action: {
                id: this.state.id,
                action: RedeemActions.Trade
            }
        }).then(() => {
            this.setState({
                actionsLoading: false,
                currentState: RedeemingState.TradedForCredits
            });
        }).catch(error => {
            this.setState({
                actionsLoading: false
            });
            failedToast(this.props.resource.failedToTradeGiftToastHeader);
        });
    };

    render() {
        const { currentState ,actionsLoading, awardValue, awardName, actions, nomineeUser } = this.state;
        const userName = nomineeUser ? nomineeUser.firstName : '';

        return (
            <React.Fragment>
                <Grid themeVariation="page-wrapper">
                    <Row>
                        <Col xs={18} xsOffset={3}>
                            <Image themeVariation="primary-program-logo1" />
                        </Col>
                    </Row>

                    {(() => {
                        switch (currentState) {
                            case RedeemingState.Loading:
                                return <Loader />;
                            case RedeemingState.ErrorReceivingCode:
                                return <ErrorForm />;
                            case RedeemingState.RedeemedBefore:
                                return <RedeemedBeforeForm/>;
                            case RedeemingState.Redeeming:
                                return <MainForm
                                    userName={userName}
                                    awardValue={awardValue ? numberFormat(awardValue) : awardName}
                                    actions={actions}
                                    actionsLoading={actionsLoading}
                                    redeemClickHandler={this.redeemClickHandler}
                                    tradeClickHandler={this.tradeClickHandler}
                                />;
                            case RedeemingState.RedeemedAward:
                                return <RedeemedAwardForm userName={userName} />;
                            case RedeemingState.TradedForCredits:
                                return <TradedAwardForm userName={userName} />;
                            default :
                               return <Loader />;
                        }
                    })()}
                </Grid>
            </React.Fragment>
        );
    }
}

const mapState = (state, ownProps) => {
    return {
    };
};

const mapProps = (props) => ({
     code: props.match.params.code
});

const mapResource = (getResource) => ({
    resource: {
        failedToRedeemGiftToastHeader: getResource('nominations.failedToRedeemGiftToastHeader', 'Failed to redeem gift'),
        failedToTradeGiftToastHeader: getResource('nominations.failedToTradeGiftToastHeader', 'Failed to trade gift'),
    }
});

const mapDispatch = (dispatch) =>
    bindActionCreators(
        {
            getRedeem: nominationActions.getRedeemCodeByCode,
            createRedeemCode: nominationActions.createRedeemCode,
        },
        dispatch
    );

export default compose(
    withRouter,
    withProps(mapProps),
    connect(
        mapState,
        mapDispatch
    ),
    withResource(mapResource)
)(RedeemingGift);
