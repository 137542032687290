import * as React from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Text } from 'rsv8-components';
import { Field, reduxForm } from 'rsv8-forms';
import { withResource } from 'xcel-react-core';
import { updateUserInfo } from '../../../redux/actions/user/updateUserInfo';
import { FieldLabel } from '../../Label/Label';
import { validateEmail } from '../util';
import { DigitalCardHeading, DigitalCardInfoContainer, HelpContainer } from './styles';
import { DigitalCardInfoProps } from './types';
// tslint:disable

class DigitalCardInfo extends React.Component<DigitalCardInfoProps, any> {
  componentDidMount() {
    this.props.actions.updateUserInfo('digitalCardEmail', this.props.userProfile.emailAddress);
  }

  handleChange = (e) => {
    this.props.actions.updateUserInfo(e.target.id || e.currentTarget.id || e.target.name || e.currentTarget.name, e.target.value);
  };

  email = (value) => validateEmail(value, this.props.invalidEmailLabel);

  render() {
    const {
      formCount,
      emailLabel,
      digitalCardInfoHelpText,
      digitalCardConfirmationLabel,
      digitalCardInfoHeading
    } = this.props;

    return (
      <div>
        <DigitalCardHeading>{`${formCount}. ${digitalCardInfoHeading}`}</DigitalCardHeading>
        <DigitalCardInfoContainer>
          <HelpContainer>
            <Text themeVariation="secondary-text">{digitalCardInfoHelpText}</Text>
          </HelpContainer>
          <Form>
            <Field
              component={'bs-input'}
              name="digitalCardEmail"
              onChange={this.handleChange}
              label={
                <FieldLabel required={true}>
                  <Text themeVariation="secondary-text">{emailLabel}</Text>
                </FieldLabel>
              }
              validate={this.email}
              required={true}
            />
            <Field
              component={'checkbox'}
              name={`digitalCardConfirmation`}
              onChange={this.handleChange}
              label={
                <FieldLabel isCheckbox={true} required={true}>
                  <Text themeVariation="secondary-text">{digitalCardConfirmationLabel}</Text>
                </FieldLabel>
              }
              type="checkbox"
            />
          </Form>
        </DigitalCardInfoContainer>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    digitalCardEmail: ownProps.userProfile.emailAddress
  }
});

const mapResourceToProps = (getResource) => ({
  emailLabel: getResource('catalog.checkout.digitalCardPrimaryEmailLabel', 'eGift Card Email'),
  digitalCardInfoHeading: getResource('catalog.checkout.digitalCardInfoHeading', 'eGift Card Account Information'),
  invalidEmailLabel: getResource('catalog.checkout.invalidEmailLabel', 'Invalid email address'),
  digitalCardInfoHelpText: getResource(
    'catalog.checkout.digitalCardInfoHelpText',
    'Please choose the email you use for your eGift Card account. '
  ),
  digitalCardConfirmationLabel: getResource(
    'catalog.checkout.digitalCardConfirmationLabel',
    'I confirm that the eGift Card e-mail address provided is where my eGift Card will be delivered to.'
  )
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      updateUserInfo
    },
    dispatch
  )
});

export default compose<any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: 'DigitalCardInfoForm' as any
  }),
  withResource(mapResourceToProps)
)(DigitalCardInfo);
