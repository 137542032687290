import * as React from 'react';
import { connect } from 'react-redux';
import { ErrorBoundaryComponent } from 'xcel-react-core';
import JsxRenderer from '../../components/JsxRenderer';
import cmsPluginSelectors from '../../redux/cms.plugin.selectors';
import { mapDispatchToActions } from '../../util';
export interface ComponentExampleListProps {
  name: string;
  examples: any;
}
const ComponentExampleList: React.SFC<ComponentExampleListProps> = ({ name, examples }) => {
  return (
    <div className="col-md-24">
      {examples[name] &&
        examples[name].map((example, index) => {
          const jsx = example.jsx;

          return <ErrorBoundaryComponent component={() => <JsxRenderer key={index} jsx={jsx} />} />;
        })}
    </div>
  );
};
const mapStateToProps = (state) => ({
  examples: cmsPluginSelectors.getExamples(state)
});
const mapDispatchToProps = mapDispatchToActions({});
export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(ComponentExampleList);
