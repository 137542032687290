import styled from 'styled-components';
import { CopyIcon } from './CopyIcon';
import { DownloadIcon } from './DownloadIcon';
import { ShareIcon } from './ShareIcon';

export const LabelText = styled.span`
  height: 100%;
  line-height: 29px;
  margin-left: 5px;
  max-width: 55px;
  display: none;
`;

export const ButtonWrapper = styled.div`
  margin: 0 auto;
  border-width: 1px;
  border-style: solid;
  height: 32px;
  border-radius: 16px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;

  &.hover-supported:hover .separator,
  &.hover-not-supported.unfolded .separator {
    display: none;
  }

  &.hover-supported:hover .label-text,
  &.hover-not-supported.unfolded .label-text {
    display: inline-block;
  }
`;

export const Separator = styled.span`
  display: block;
  width: 1px;
  height: 24px;
  position: absolute;
  top: 4px;
  left: 50%;
`;

export const Container = styled.section<any>`
  box-sizing: border-box;

  & * {
    box-sizing: inherit;
  }
`;

export const InputField = styled.input`
  width: 100%;
  display: block;
  height: 100%;
  border: none;
  outline: 0;
  font-size: 11px;

  &:disabled {
    color: currentColor;
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  height: 32px;
  border-radius: 16px;
  border-width: 2px;
  border-style: solid;
  padding: 0 50px 0 18px;
  position: relative;

  @media (max-width: 640px) {
    padding: 0 30px 0 8px;
  }
`;

export const StyledCopyIcon = styled(CopyIcon)`
  width: 10px;
  height: 12px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 18px;
  margin-top: -6px;
`;

export const StyledShareIcon = styled(ShareIcon)`
  width: 16px;
  height: 16px;
  position: relative;
  top: 7px;
  fill: currentColor;
`;

export const StyledDownloadIcon = styled(DownloadIcon)`
  width: 16px;
  height: 16px;
  position: relative;
  top: 7px;
  fill: currentColor;
`;

interface CustomIconProps {
  defaultCustomIcon: string;
}

export const CustomIcon = styled.div<CustomIconProps>`
  width: 16px;
  height: 16px;
  display: block;
  position: relative;
  top: 7px;
  overflow: hidden;
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('${(props) => props.defaultCustomIcon}');
`;

interface SaveButtonProps {
  hoverCustomIcon: string;
}
export const SaveButton = styled.div<SaveButtonProps>`
  height: 100%;
  text-align: center;
  padding: 0 12px;
  cursor: pointer;

  &.hover-supported:hover .custom-save-icon, &.hover-not-supported.selected .custom-save-icon {
    background-image: url('${(props) => props.hoverCustomIcon}');
  }
`;

interface ShareButtonProps extends SaveButtonProps {
  hoverCustomIcon: string;
}
export const ShareButton = styled.div<ShareButtonProps>`
  height: 100%;
  text-align: center;
  padding: 0 12px;
  cursor: pointer;

  &.hover-supported:hover .custom-share-icon, &.hover-not-supported.selected .custom-share-icon {
    background-image: url('${(props) => props.hoverCustomIcon}');
  }
`;

export const IconWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 100%;
`;
