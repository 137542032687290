import { stringify } from 'query-string';
import * as React from 'react';
import {
  Button,
  FormGroup,
  InputGroup,
  MenuItem
  } from 'react-bootstrap';
import * as Icon from 'react-fontawesome';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators, compose } from 'redux';
import { cultureSelector } from 'rsv8-client';
import { withResource } from 'xcel-react-core';
import { alphaStringSort } from 'xcel-util';
import { getDepartments, getNavigationMenu } from '../../redux/actions';
import { getCurrentQuery, getNavigationCategories } from '../../redux/selectors';
import {
  CatalogHeaderNavStyledWrap,
  CategoryDropdownButton,
  NavSearch,
  SearchButton,
  SearchFormControl,
  SearchInputGroup
  } from './styles';

class CatalogSearch extends React.Component<any, any> {
  state = {
    searchCategory: { id: null, name: 'All Departments' },
    searchString: ''
  };

  componentDidMount() {
    this.props.actions.getNavigationMenu();
  }

  constructQuery = () => {
    const {
      searchCategory: { id },
      searchString
    } = this.state;

    if (id) {
      return stringify({
        filterQuery: searchString,
        filterCategories: [id]
      });
    } else {
      return stringify({
        filterQuery: searchString
      });
    }
  };

  handleSearchQueryChange = (e) => {
    this.setState({ searchString: e.target.value });
  };

  handleCategoryDropdownChange = (e) => {
    this.setState({ searchCategory: e });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const searchQuery = this.constructQuery();
    this.props.history.push({
      pathname: '/catalog/search',
      search: searchQuery
    });
  };

  componentDidUpdate(prevProps: any) {
    const { currentQuery, departments, cultureId, actions } = this.props;

    if (prevProps.currentQuery.filterQuery !== currentQuery.filterQuery) {
      this.setState({ searchString: currentQuery.filterQuery || '' });
    }
    if (prevProps.currentQuery.filterCategories !== currentQuery.filterCategories) {
      const newCategoryFilter =
        departments &&
        departments.children &&
        departments.children.find(
          (category) => category.targetUrl.split('/').pop() === parseInt(currentQuery.filterCategories, 10)
        );
      if (newCategoryFilter) {
        this.setState({
          searchCategory: { id: newCategoryFilter.targetUrl.split('/').pop(), name: newCategoryFilter.label }
        });
      }
    }
    if (prevProps.cultureId !== cultureId) {
      actions.getNavigationMenu();
    }
  }

  render() {
    const { searchDropDown, departments } = this.props;

    return (
      <CatalogHeaderNavStyledWrap>
        <NavSearch onSubmit={this.handleSubmit}>
          <FormGroup style={{ border: 0, width: '100%' }}>
            <SearchInputGroup>
              <CategoryDropdownButton
                componentClass={InputGroup.Button}
                id="category-search-dropdown"
                onSelect={this.handleCategoryDropdownChange}
                title={this.state.searchCategory.name}
              >
                <MenuItem eventKey={{ id: null, name: 'All Departments' }}>{searchDropDown}</MenuItem>
                {departments &&
                  departments.children &&
                  departments.children.sort(alphaStringSort('label')).map((category) => (
                    <MenuItem
                      eventKey={{ id: category.targetUrl.split('/').pop(), name: category.label }}
                      key={category.targetUrl.split('/').pop()}
                    >
                      {category.label}
                    </MenuItem>
                  ))}
              </CategoryDropdownButton>
              <SearchFormControl
                onChange={this.handleSearchQueryChange}
                placeholder="Search"
                type="text"
                value={this.state.searchString}
              />
              <SearchButton>
                <Button type="submit">
                  <Icon name="search" />
                </Button>
              </SearchButton>
            </SearchInputGroup>
          </FormGroup>
        </NavSearch>
      </CatalogHeaderNavStyledWrap>
    );
  }
}

const mapStateToProps = (state) => ({
  currentQuery: getCurrentQuery(state),
  cultureId: cultureSelector.getActiveCultureId(state),
  departments: getNavigationCategories(state)
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getDepartments,
      getCurrentQuery,
      getNavigationMenu
    },
    dispatch
  )
});

const mapResourceToProps = (getResource) => ({
  searchDropDown: getResource('catalog.search.searchDropDown', 'All Departments')
});

export default compose(
  withRouter,
  withResource(mapResourceToProps),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CatalogSearch) as any;
