import * as React from 'react';
import { Alert, FormGroup } from 'react-bootstrap';
import { compose } from 'redux';
import { Field, reduxForm } from 'rsv8-forms';
import { withResource } from 'xcel-react-core';
import { FieldContainer } from '../../../components/FormControls';
import { SubmitButton } from '../../../components/FormControls';
import { LoginMaxWidth } from '../../../components/LoginMaxWidth';

interface UsernameReminderRequestFormProps {
  resource: {
    emailAddressLabel: any;
    submitButtonLabel: any;
  };
  handleSubmit: any;
  error: any;
}

interface UsernameReminderRequestFormState {
  emailAddress: boolean;
}

class UsernameReminderRequestForm extends React.Component<
  UsernameReminderRequestFormProps,
  UsernameReminderRequestFormState
> {
  constructor(props) {
    super(props);
    this.state = { emailAddress: false };
  }

  handleChange = (e) => {
    this.setState({ emailAddress: e.target.value });
  };

  render() {
    const {
      resource: { emailAddressLabel, submitButtonLabel }
    } = this.props;
    return (
      <LoginMaxWidth>
        <form onSubmit={this.props.handleSubmit}>
          {this.props.error && <Alert bsStyle="danger">{this.props.error}</Alert>}
          <FormGroup
            controlId="formValidationError4"
            validationState={this.props.error && !this.state.emailAddress ? 'error' : null}
          >
            <Field
              name="emailAddress"
              component={'bs-input'}
              label={emailAddressLabel}
              type="text"
              value={this.state.emailAddress}
              onChange={this.handleChange}
            />
            <FieldContainer>
              <SubmitButton
                themeVariation="full-width"
                value={submitButtonLabel}
                disabled={this.props.error && !this.state.emailAddress}
              />
            </FieldContainer>
          </FormGroup>
        </form>
      </LoginMaxWidth>
    );
  }
}

const mapResourceToProps = (getResource) => ({
  resource: {
    emailAddressLabel: getResource('usernameReminderRequest.emailAddressLabelText', 'Email*'),
    submitButtonLabel: getResource('usernameReminderRequest.submitButtonText', 'Send a Reminder')
  }
});

export default compose(
  withResource(mapResourceToProps),
  reduxForm({ form: 'usernameReminderRequest' })
)(UsernameReminderRequestForm as any) as any;
