import { ValidationObject, Validator } from './types';
import validateJS from './validateJS';

export interface ValidationProviderTypes {
  validate: {
    [index: string]: Validator;
  };
  validateMessages: {
    [index: string]: string;
  };
  alwaysValid: Validator;
}

export class ValidationProvider implements ValidationProviderTypes {
  validate: {};
  validateMessages: {};
  alwaysValid: (v: any) => any;

  constructor(initial: ValidationObject) {
    this.validate = {};
    this.validateMessages = {};
    this.alwaysValid = (v) => undefined;
    this.addMultipleValidations(initial);
  }

  setValidationMessages(obj: { [index: string]: string }) {
    this.validateMessages = { ...this.validateMessages, ...obj };
  }

  addValidation(name: string, callback: Validator) {
    if (this.hasValidation(name)) {
      console.warn(`[ValidationProvider] Warning! You're overwriting the '${name}' validation.`);
    }

    this.validate[name] = (values) => {
      const validationResult = callback(values);
      return validationResult === undefined ? undefined : this.validateMessages[name] || validationResult;
    };
  }

  addMultipleValidations(obj: ValidationObject) {
    Object.keys(obj).forEach((key) => this.addValidation(key, obj[key]));
  }

  getAllValidations() {
    return Object.keys(this.validate);
  }

  // Get a Validation function by name
  // Write to the log an return a placeholder if nothing is found
  getValidation(name: string) {
    if (this.hasValidation(name)) {
      return this.validate[name];
    } else {
      this._logErrors(name);
      return this.alwaysValid;
    }
  }

  hasValidation(name: string) {
    return this.validate.hasOwnProperty(name);
  }

  _logErrors(name: string) {
    const validTypes = this.getAllValidations().join(', ');
    console.warn(`[ValidationProvider] Cannot find the '${name} validation.`);
    console.warn(`[ValidationProvider] Currently available validations are: ${validTypes}`);
  }
}

const initialValidations: ValidationObject = {
  email: validateJS({ email: true }),
  required: validateJS({ presence: { allowEmpty: false } }),
  url: validateJS({ url: true }),
  numeric: validateJS({ numericality: { onlyInteger: true, noStrings: true } }),
  alphanumeric: validateJS({ format: { pattern: '[a-zA-Z0-9]+' } }),
  alpha: validateJS({ format: { pattern: '[a-zA-Z]+' } })
};

export default new ValidationProvider(initialValidations);
