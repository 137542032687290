import { push } from 'connected-react-router';
import social, { ActivityAddCultureModel, ActivityAddModel } from 'xcel-api-generator/dist/social';
import { destroy, destroyRequest, mapJsonApiActions } from 'xcel-redux-orm';
import { convertToPatchOperations } from 'xcel-util';
import * as events from '../events';
import * as selectors from '../selectors';

let updateTimeout;
function debounce(callback) {
  clearTimeout(updateTimeout);
  updateTimeout = setTimeout(callback, 100);
}

const {
  getAdminActivities,
  getAdminActivitiesByIdDetails,
  getAdminActivityTypes,
  createAdminActivityTypesByActivityTypeActivities,
  createAdminActivitiesById,
  patchAdminActivitiesById,
  patchAdminActivitiesByIdVersionsAndVersionId,
  deleteAdminActivitiesById,
  deleteAdminActivitiesByIdVersionsAndVersionId
} = mapJsonApiActions(social);

const setEditVersion = (version) => ({
  type: events.SET_EDIT_VERSION,
  version
});

const setEditCulture = (culture) => ({
  type: events.SET_EDIT_CULTURE,
  culture
});

const setActivityId = (activityId) => ({
  type: events.SET_ACTIVITY_ID,
  activityId
});

const setVersionAndCulture = (dispatch, state, activityId: string) => {
  const culture = state[events.ROOT_SOCIAL].culture;
  const version = state[events.ROOT_SOCIAL].version;
  const currentActivity = selectors.activitiesSelector.selectOne(state, activityId);
  const cultures = selectors.culturesByActivity(state, currentActivity);
  const currentCulture = culture ? culture : cultures[0];
  const versions = currentCulture.versions.slice().reverse();
  const currentVersion = version ? version : versions[0];

  dispatch(setEditCulture(currentCulture));
  dispatch(setEditVersion(currentVersion));
};

const socialActions = {
  setEditCulture,
  setEditVersion,
  setActivityId,
  getAdminActivities,
  getAdminActivityTypes,
  getActivitiesByIdDetails: (activityId) => async (dispatch, getState) => {
    // removing the current activity in order to bring the details
    // and inject in the redux state
    dispatch(destroy('socialDetailModal', [activityId]));
    dispatch(destroyRequest('getActivitiesByIdDetails'));
    await dispatch(getAdminActivitiesByIdDetails({ id: activityId }));
    dispatch(setActivityId(activityId));
    // setting the default culture and version for editing
    setVersionAndCulture(dispatch, getState(), activityId);
  },
  patchAdminActivitiesById,
  createActivity: (activityType: string, body: ActivityAddModel) => async (dispatch) => {
    const { data } = await dispatch(
      createAdminActivityTypesByActivityTypeActivities({
        activityType,
        body
      })
    );

    dispatch(push(`/social/edit/${data.id}`));
  },
  createNewCulture: (activityId: string, payload: ActivityAddCultureModel) => async (dispatch) => {
    await dispatch(
      createAdminActivitiesById({
        id: activityId,
        body: payload
      })
    );
    dispatch(destroyRequest('getActivitiesByIdDetails'));
    await dispatch(getAdminActivitiesByIdDetails({ id: activityId }));
  },
  createNewVersion: (activityId: string, payload: ActivityAddCultureModel) => async (dispatch) => {
    debounce(async () => {
      await dispatch(createAdminActivitiesById({ id: activityId, body: payload }));
      dispatch(destroyRequest('getActivitiesByIdDetails'));
      await dispatch(getAdminActivitiesByIdDetails({ id: activityId }));
    });
  },
  editActivityById: (id: string, activity: any) => async (dispatch) => {
    debounce(async () => {
      await dispatch(patchAdminActivitiesById({ id, body: convertToPatchOperations(activity) }));
      // call again for the edited social activity
      dispatch(destroyRequest('getActivitiesByIdDetails'));
      await dispatch(getAdminActivitiesByIdDetails({ id }));
    });
  },
  editVersion: (activityId: string, versionId: number, payload: any) => async (dispatch, getState) => {
    debounce(async () => {
      await dispatch(
        patchAdminActivitiesByIdVersionsAndVersionId({
          id: activityId,
          versionId,
          body: convertToPatchOperations(payload)
        })
      );
      dispatch(destroyRequest('getActivitiesByIdDetails'));
      await dispatch(getAdminActivitiesByIdDetails({ id: activityId }));
      setVersionAndCulture(dispatch, getState(), activityId);
    });
  },
  removeActivity: (activityId: string) => async (dispatch) => {
    try {
      await dispatch(deleteAdminActivitiesById({ id: activityId }));
      dispatch(destroy('socialDetailModal', [activityId]));
    } catch (error) {
      alert(`Cannot delete activity: ${error.message}`);
    }
  },
  removeVersion: (activityId, versionId) => async (dispatch) => {
    try {
      await dispatch(
        deleteAdminActivitiesByIdVersionsAndVersionId({
          id: activityId,
          versionId: versionId
        })
      );
      dispatch(destroy('versionModel', [versionId]));
      dispatch(destroyRequest('getActivitiesByIdDetails'));
      await dispatch(getAdminActivitiesByIdDetails({ id: activityId }));
    } catch (error) {
      alert(`Cannot delete version activity: ${error.message}`);
    }
  }
};

export default socialActions;
