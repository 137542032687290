import * as React from 'react';
import { compose } from 'recompose';
import { Button, Text } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import { Omit } from 'xcel-util';
import ApprovalForm from './ApprovalForm';
import DenialForm from './DenialForm';
import EscalationForm from './EscalationForm';
import SendBackForm from './SendBackForm';
import { FlexDiv, PaddedDiv, StyledIcon } from './styles';
import { ApprovalFormProps } from './util';

export interface FormDisplayProps extends ApprovalFormProps {
  showApprovalFields?: any;
  buttons: { approve: string; deny: string; sendback: string; escalate: string; denyMessage: string };
}
type ActiveForms = 'Approve' | 'Deny' | 'Escalate' | 'SendBack' | 'none';

class FormDisplay extends React.Component<FormDisplayProps, { active: ActiveForms; formVisibility: boolean }> {
  state = { active: 'none' as any, formVisibility: true as boolean };

  handleClick = (name: ActiveForms) => () => {
    this.setState({ active: this.state.active === name ? 'none' : name });
  };

  handleFormVisibility = () => {
    this.setState({ formVisibility: this.state.formVisibility ? false : true });
  };

  renderForm = () => {
    switch (this.state.active) {
      case 'Approve':
        return (
          <ApprovalForm
            autoSubmit={this.props.showApprovalFields ? false : true}
            changeVisibility={this.handleFormVisibility}
            {...this.props}
          />
        );
      case 'Deny':
        return (
          <DenialForm
            autoSubmit={this.props.showApprovalFields ? false : true}
            changeVisibility={this.handleFormVisibility}
            {...this.props}
          />
        );
      case 'Escalate':
        return (
          <EscalationForm
            autoSubmit={this.props.showApprovalFields ? false : true}
            changeVisibility={this.handleFormVisibility}
            {...this.props}
          />
        );
      case 'SendBack':
        return <SendBackForm changeVisibility={this.handleFormVisibility} {...this.props} />;
      default:
        return null;
    }
  };

  render() {
    const { approve, deny, escalate, sendback, denyMessage } = this.props.buttons;
    const { canApprove, canDeny, canEscalate, canSendBack } = this.props.actions;

    return (
      <React.Fragment>
        {this.state.formVisibility && (
          <React.Fragment>
            <FlexDiv>
              {canApprove && (
                <Button
                  themeVariation={this.state.active === 'Approve' ? 'left' : 'left-secondary'}
                  onClick={this.handleClick('Approve')}
                >
                  <StyledIcon icon="check-circle-o" />
                  {approve}
                </Button>
              )}
              {canEscalate && (
                <Button
                  themeVariation={this.state.active === 'Escalate' ? 'left' : 'left-secondary'}
                  onClick={this.handleClick('Escalate')}
                >
                  <StyledIcon icon="check-circle-o" />
                  {escalate}
                </Button>
              )}
              {canDeny && !canApprove && !canEscalate ? (
                <React.Fragment>
                  <Button
                    themeVariation={this.state.active === 'Deny' ? 'full-width' : 'full-width-secondary'}
                    onClick={this.handleClick('Deny')}
                  >
                    <StyledIcon icon="times-circle-o" />
                    {deny}
                  </Button>
                  <PaddedDiv>
                    <Text themeVariation="tertiary-text-sm">{denyMessage}</Text>
                  </PaddedDiv>
                </React.Fragment>
              ) : (
                <Button
                  themeVariation={this.state.active === 'Deny' ? 'right' : 'right-secondary'}
                  onClick={this.handleClick('Deny')}
                >
                  <StyledIcon icon="times-circle-o" />
                  {deny}
                </Button>
              )}
            </FlexDiv>
            <PaddedDiv>
              {canSendBack && (
                <Button
                  themeVariation={this.state.active === 'SendBack' ? 'full-width' : 'full-width-secondary'}
                  onClick={this.handleClick('SendBack')}
                >
                  <StyledIcon icon="undo" />
                  {sendback}
                </Button>
              )}
            </PaddedDiv>
          </React.Fragment>
        )}
        {this.renderForm()}
      </React.Fragment>
    );
  }
}

const mapResource = (getResource) => ({
  buttons: {
    approve: getResource('nominationDetailModal.approvalDisplayButton', 'Approve'),
    deny: getResource('nominationDetailModal.denialDisplayButton', 'Deny'),
    escalate: getResource('nominationDetailModal.escalateDisplayButton', 'Escalate'),
    sendback: getResource('nominationDetailModal.sendbackDisplayButton', 'Send Back'),
    denyMessage: getResource(
      'nominationDetailModal.denyMessage',
      'Since the original nomination was submitted, this employee has now exceeded 10,000 Points earned this year and is no longer eligible for the Three Star Award for the rest of this year. This nomination must be denied. Choose Denial Reason - Employee has met point limit from the drop down list below. Then click Deny'
    )
  }
});

export default compose<FormDisplayProps, Omit<FormDisplayProps, 'buttons'>>(withResource(mapResource))(FormDisplay);
