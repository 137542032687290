import { userStateSelectors } from 'rsv8-auth';
import { AnalyticModel } from 'xcel-api-generator/dist/analytics';
import { createTypeSelector } from 'xcel-redux-orm';

export const analyticsSelector = createTypeSelector<AnalyticModel>('analyticModel');

export const getAllAnalytics = (state) => analyticsSelector.selectMany(state, null, { eager: true });
export const getAnalytics = (state, analyticsType) => analyticsSelector.selectOne(state, analyticsType);

export const getPendingApprovalCount = (state) => {
  const nominationAnalytics = getAnalytics(state, 'nomination');
  if (nominationAnalytics && nominationAnalytics.data && nominationAnalytics.data.length > 0) {
    const pendingApprovals = nominationAnalytics.data
      .find((x) => x.id === 'personalAggregates')
      .data.find((x) => x.nominationKey === 'nom_status_pending_approval');
    if (pendingApprovals) {
      return pendingApprovals.nominationCount;
    } else {
      return 0;
    }
  } else {
    return null;
  }
};

export const getYourTeamPercentage = (state) => {
  const nominationAnalytics = getAnalytics(state, 'nomination');
  if (nominationAnalytics && nominationAnalytics.data && nominationAnalytics.data.length > 0) {
    const userAnalytics = getAnalytics(state, 'user');
    const managersTotalUsers = userAnalytics.data.find((x) => x.id === 'managerAggregates').totalUserCount;
    const teamAwarded = nominationAnalytics.data
      .find((x) => x.id === 'managerAggregates')
      .nominationStatuses.find((x) => x.nominationStatus === 'nom_status_awarded');
    let teamAwardedCount = 0;
    if (teamAwarded !== undefined) {
      teamAwardedCount = teamAwarded.nominationCount;

      if (managersTotalUsers === 0) {
        return 0;
      }
      return (teamAwardedCount / managersTotalUsers) * 100;
    } else {
      return 0;
    }
  } else {
    return null;
  }
};

export const getTotalAwardedTeamPoints = (state) => {
  const nominationAnalytics = getAnalytics(state, 'nomination');
  if (nominationAnalytics && nominationAnalytics.data && nominationAnalytics.data.length > 0) {
    const teamAwarded = nominationAnalytics.data.find((x) => x.id === 'managerAggregates');
    const personalNomStatusAwarded = nominationAnalytics.data
      .find((x) => x.id === 'personalAggregates')
      .data.find((x) => x.nominationKey === 'nom_status_awarded');
    let personalAggregatesApprovedPoints = 0;
    let managerAggregatesApprovedPoints = 0;
    if (personalNomStatusAwarded) {
      personalAggregatesApprovedPoints = personalNomStatusAwarded.nominationPoints;
    }
    if (teamAwarded) {
      const teamAwardedStatus = teamAwarded.nominationStatuses.find((x) => x.nominationStatus === 'nom_status_awarded');
      if (teamAwardedStatus) {
        managerAggregatesApprovedPoints = teamAwardedStatus.totalPoints;
      }
    }
    return personalAggregatesApprovedPoints + managerAggregatesApprovedPoints;
  } else {
    return null;
  }
};

export const getYourCountryPercentage = (state) => {
  const nominationAnalytics = getAnalytics(state, 'nomination');
  if (nominationAnalytics && nominationAnalytics.data && nominationAnalytics.data.length > 0) {
    const user = userStateSelectors.getUser(state);
    const userClientAggregates = getAnalytics(state, 'user').data.find((x) => x.id === 'clientAggregates');
    const clientAnalytics = nominationAnalytics.data.find((x) => x.id === 'clientAggregates');
    const totalCountryUsers =
      userClientAggregates && userClientAggregates.countries.find((x) => x.countryCode === user.countryCode).userCount;
    const managerCountry = clientAnalytics.countries.find((x) => x.countryCode === user.countryCode);
    if (managerCountry) {
      const managerCountryAwardedCount = managerCountry.nominationStatuses
        .filter((x) => x.nominationStatus === 'nom_status_awarded')
        .reduce((acc, curr) => acc + curr.nominationCount, 0);
      return (managerCountryAwardedCount / totalCountryUsers) * 100;
    } else {
      return 0;
    }
  } else {
    return null;
  }
};

export const getCompanyStats = (state) => {
  const nominationAnalytics = getAnalytics(state, 'nomination');
  if (nominationAnalytics && nominationAnalytics.data && nominationAnalytics.data.length > 0) {
    const userAnalytics = getAnalytics(state, 'user');
    const totalClientUsers = userAnalytics.data.find((x) => x.id === 'clientAggregates').totalUserCount;
    const clientAnalytics = nominationAnalytics.data.find((x) => x.id === 'clientAggregates');
    const totalClientAwarded = clientAnalytics.nominationStatuses.find(
      (x) => x.nominationStatus === 'nom_status_awarded'
    ).nominationCount;
    return (totalClientAwarded / totalClientUsers) * 100;
  } else {
    return null;
  }
};

export const getGoalInPercentage = (state) => {
  const nominationAnalytics = getAnalytics(state, 'nomination');
  if (nominationAnalytics && nominationAnalytics.data) {
    const config = nominationAnalytics.data.find((x) => x.type === 'Config');
    return config.values.goalInPercentage;
  } else {
    return null;
  }
};

export const getBudgetStatus = (state) => {
  const nominationAnalytics = getAnalytics(state, 'nomination');
  if (nominationAnalytics && nominationAnalytics.data) {
    const user = userStateSelectors.getUser(state);
    const budgetAnalytics = nominationAnalytics.data.find((x) => x.id === 'budget');
    return budgetAnalytics.data.find((x) => x.managerId === user.id) || 0;
  } else {
    return null;
  }
};

export const getEmployeeReceivedPercentage = (state) => {
  const nominationAnalytics = getAnalytics(state, 'nomination');
  if (nominationAnalytics && nominationAnalytics.data && nominationAnalytics.data.length > 0) {
    const userAnalytics = getAnalytics(state, 'user');
    const managersTotalUsers = userAnalytics.data.find((x) => x.id === 'managerAggregates').totalUserCount;
    const teamAwarded = nominationAnalytics.data.find((x) => x.id === 'managerAggregates');
    if (teamAwarded && teamAwarded.nominationStatuses) {
      const teamAwardedStatus = teamAwarded.nominationStatuses.find(
        (x) => x.nominationStatus === 'nom_status_received'
      );
      if (teamAwardedStatus) {
        const receivedNominations = teamAwardedStatus.nominationCount;
        if (managersTotalUsers === 0) {
          return 0;
        } else {
          return (receivedNominations / managersTotalUsers) * 100;
        }
      }
      return 0;
    }
  }
  return null;
};
