import * as React from 'react';
import { TableCell, TableRow } from '../index';
class DataTableCellRow extends React.PureComponent<any> {
  render() {
    const { columns, index, record } = this.props;
    if (record === null) return null;
    return (
      <TableRow>
        {columns &&
          columns.map((column) => {
            const { renderCell, ...rest } = column;
            return (
              <TableCell key={column.field} {...rest}>
                {column.renderCell
                  ? column.renderCell({ record, column, index, isMobile: false })
                  : record[column.field] || ''}
              </TableCell>
            );
          })}
      </TableRow>
    );
  }
}

export default DataTableCellRow;
