import * as React from 'react';
import { Alert, Col, Grid } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Loader } from 'rsv8-components';
import { FilterForm, IndividualNomineeInstructions as NomineeInstructions, NomineeSearch } from '../../components/index';
import { NarrowGrid } from '../../components/index';
import { ErrorFormContainer } from '../../components/SearchBar/SearchBar';
import { nomineeSearchSelectors } from '../../redux/selectors/index';
import { PlaceholderNoResults } from '../MyAwards/Placeholder/index';
import NomineeDisplay from './NomineeDisplay';
import { PaddedRow } from './styles';

export interface NomineeLayoutProps {
  currentNominee: any;
  hasError: boolean;
  errorText: any;
}

interface InternalProps extends NomineeLayoutProps {
  requestSuccessful: boolean;
  requestLoading: boolean;
  requestFailed: boolean;
  total: number;
  current: number;
}

const NomineeLayout: React.SFC<InternalProps> = (props) => {
  const CenterComponent = props.requestSuccessful ? (
    <PaddedRow>
      <Col xs={24} md={4}>
        <FilterForm />
      </Col>
      <Col xs={24} md={20}>
        <NomineeDisplay />
      </Col>
    </PaddedRow>
  ) : props.requestLoading ? (
    <Loader />
  ) : props.requestFailed ? (
    <PlaceholderNoResults />
  ) : null;

  return (
    <Grid>
      <PaddedRow>
        <Col xs={24} md={18} mdOffset={3} lg={12} lgOffset={6}>
          <NomineeInstructions />
        </Col>
      </PaddedRow>
      <PaddedRow>
        <NarrowGrid>
          <NomineeSearch />
          <ErrorFormContainer>
            {props.hasError && !props.currentNominee ? <Alert bsStyle="danger">{props.errorText}</Alert> : null}
          </ErrorFormContainer>
        </NarrowGrid>
      </PaddedRow>
      {CenterComponent}
    </Grid>
  );
};

const mapState = (state) => {
  const nomineeRequest = nomineeSearchSelectors.getRequest(state);
  return {
    requestSuccessful: nomineeRequest.exists && nomineeRequest.succeeded,
    requestFailed: nomineeRequest.exists && !nomineeRequest.succeeded,
    requestLoading: nomineeRequest.loading
  };
};

export default connect(
  mapState,
  null
)(NomineeLayout) as React.ComponentClass<NomineeLayoutProps>;
