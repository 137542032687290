import { SocialDetailModel } from 'xcel-api-generator/dist/social';

export default class ActivityInputs {
  // fields related with the activity
  name: string;
  activityType: object;
  countries: string[] | string;

  // fields related with the version
  title: string;
  description: string;
  isPublished: boolean;
  isSticky: boolean;
  cultureId: number;
  constructor(activity: SocialDetailModel, version: any = {}, cultureId: any = 0) {
    this.name = activity.name || '';
    this.activityType = activity.activityType || ({} as { id: number });
    this.countries = activity.countries || [];
    this.title = version.title || '';
    this.description = version.description || '';
    this.isPublished = version.isPublished || false;
    this.isSticky = activity.isSticky || false;
    this.cultureId = cultureId;
  }
}
