import { types } from '../actions';

export default (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.ADD_CUSTOM_FIELDS:
      return { ...state, ...payload.fields };
    default:
      return state;
  }
};
