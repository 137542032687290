import { Icon } from 'rsv8-components';
import styled from 'styled-components';

export const FavoritesCheckedIcon = styled(Icon as any)`
  bottom: 15px;
  color: #4192a5;
  cursor: pointer;
  display: inline-flex;
  margin: 10px 10px 10px 20px;
  right: 20px;
` as any;

export const FavoritesUncheckedIcon = styled(Icon as any)`
  bottom: 15px;
  color: #7c8180;
  cursor: pointer;
  display: inline-flex;
  margin: 10px 10px 10px 20px;
  right: 20px;
` as any;

export const FavoritesContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
` as any;
