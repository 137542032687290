import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { register, withResource, withTheme } from 'xcel-react-core';
import { navigationMenuToggle } from '../../redux/navigationMenu.actions';
import HamburgerMenuIcon from './HamburgerMenuIcon';
import HamburgerMenuSpan from './HamburgerMenuSpan';

interface HamburgerMenuBaseProps {
  open: any;
  hamburgerMenuNameText: any;
  className: any;
  onClick: any;
}

class HamburgerMenuBase extends React.Component<HamburgerMenuBaseProps, any> {
  componentDidMount() {
    document.body.style.overflow = this.props.open ? 'hidden' : 'auto';
    document.body.style.height = this.props.open ? '100vh' : 'auto';
    document.documentElement.style.overflow = this.props.open ? 'hidden' : 'auto';
    document.documentElement.style.height = this.props.open ? '100vh' : 'auto';
  }
  componentDidUpdate(prevProps: any) {
    if (this.props.open !== prevProps.open) {
      document.body.style.overflow = this.props.open ? 'hidden' : 'auto';
      document.body.style.height = this.props.open ? '100vh' : 'auto';
      document.documentElement.style.overflow = this.props.open ? 'hidden' : 'auto';
      document.documentElement.style.height = this.props.open ? '100vh' : 'auto';
    }
  }

  render() {
    const { hamburgerMenuNameText } = this.props;

    return (
      <div className={`${this.props.className} hamburger-wrapper`}>
        <div onClick={this.props.onClick} className={this.props.open ? 'hamburger-open' : 'hamburger-closed'}>
          <HamburgerMenuIcon open={this.props.open} />
          {this.props.children || (
            <HamburgerMenuSpan className="hamburger-text">{hamburgerMenuNameText}</HamburgerMenuSpan>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  open: state.navigationMenu && state.navigationMenu.isOpen
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClick: () => dispatch(navigationMenuToggle())
});

// removed variable assignment. Note to self during TS conversion --from aj

const HamburgerMenu = styled(HamburgerMenuBase)`
  position: absolute;
  width: 27px;
  z-index: 200;
  top: 20px;
  left: 10px;
`;

const mapResourceToProps = (getResource) => ({
  hamburgerMenuNameText: getResource('navigation.hamburgerMenuNameText', 'MENU')
});
export default register('rsv8-navigation/HamburgerIcon')(
  withTheme(['.hamburger-open', '.hamburger-closed']),
  withResource(mapResourceToProps),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(HamburgerMenu);
