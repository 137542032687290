import * as React from 'react';
import { connect } from 'react-redux';
import { CustomerProduct, Program } from 'xcel-api-generator/dist/alcon';
import { ProductFieldType, ProgramName } from '../../../constants';
import { baselineYearLabelSelector, currentYearLabelSelector, programSelector } from '../../../redux/selectors';
import ProductAttrBlock from './ProductAttrBlock';
import ProductNameBlock from './ProductNameBlock';
import * as styles from './styles';

// print out 1 row, which is 1 card
const ProductCard: React.FC<Props> = ({ product, program, baselineYearLabel, currentYearLabel }) => {
  if (!(product !== null && product !== undefined)) {
    return null;
  }
  return (
    <styles.ProductTable>
      <styles.ProductDataTableRow>
        <ProductNameBlock product={product} />
        {(ProgramName.match(ProgramName.Magnifeye, program) || ProgramName.match(ProgramName.Platinum, program)) && (
          <ProductAttrBlock product={product} field={ProductFieldType.BaseLine} headerText={baselineYearLabel} />
        )}
        {(ProgramName.match(ProgramName.OptiPort, program) ||
          ProgramName.match(ProgramName.VisionSource, program) ||
          ProgramName.match(ProgramName.MarketShare, program) ||
          ProgramName.match(ProgramName.TotalPartner, program)) && (
          <ProductAttrBlock product={product} field={ProductFieldType.BaseLine} headerText={'YTD ' + baselineYearLabel} />
        )}

        {(ProgramName.match(ProgramName.Magnifeye, program) || ProgramName.match(ProgramName.Platinum, program)) && (
          <ProductAttrBlock product={product} field={ProductFieldType.Current} headerText="CURRENT" />
        )}
        {(ProgramName.match(ProgramName.OptiPort, program) ||
          ProgramName.match(ProgramName.VisionSource, program) ||
          ProgramName.match(ProgramName.MarketShare, program) ||
          ProgramName.match(ProgramName.TotalPartner, program)) && (
          <ProductAttrBlock product={product} field={ProductFieldType.Current} headerText={'YTD ' + currentYearLabel} />
        )}

        <ProductAttrBlock product={product} field={ProductFieldType.RunRate} headerText="RUN RATE" />
        <ProductAttrBlock product={product} field={ProductFieldType.Target} headerText="TARGET" />
        <ProductAttrBlock product={product} field={ProductFieldType.Custom} headerText="CUSTOM" customAttributes="customunits" />
      </styles.ProductDataTableRow>
    </styles.ProductTable>
  );
};

const mapStateToProps = (state) => ({
  program: programSelector.selectMany(state)[0],
  baselineYearLabel: baselineYearLabelSelector(state),
  currentYearLabel: currentYearLabelSelector(state),
});

interface StateProps {
  program: Program;
  baselineYearLabel: string;
  currentYearLabel: number;
}
interface DispatchProps {}
interface OwnProps {
  product: CustomerProduct;
}
type Props = StateProps & DispatchProps & OwnProps;

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps)(ProductCard);
