import * as React from 'react';
import * as Icon from 'react-fontawesome';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators, compose } from 'redux';
import { Drawer, HeaderFour } from 'rsv8-components';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';
import { toggleValue } from 'xcel-util';
import {
  FilterFormControl,
  FormGroupDivider,
  PointFilterControlLabel,
  PointFilterFormGroup
  } from '../../../../components/PointRangeSearch/styles';
import { submitAllSearch } from '../../../../redux/actions';
import { DrawerButton, DrawerFooter } from '../../styles';
import BrandAlphaFilter from '../Filters/Brands/BrandAlphaFilter';
import CategoryFilter from '../Filters/Category/Category';
import FilterPanel from './FilterPanel';

const PanelContainer = styled.div`
  margin-top: 30px;
  position: absolute;
  left: 0;
  width: 100%;
  overflow-y: auto;
  max-height: calc(100% - 163px);
` as any;

const PointsContainer = styled.div`
  display: flex;
` as any;

const PointsGroup = styled(PointFilterFormGroup)`
  width: 100%;
` as any;

const PointsLabel = styled(PointFilterControlLabel)`
  font-weight: normal;
` as any;

class FilterDrawer extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      currentlyCheckedCategories: [],
      currentlyCheckedBrands: [],
      maxPoints: '',
      minPoints: ''
    };
  }

  componentDidMount() {
    this.setState({
      currentlyCheckedCategories: this.props.checkedCategories,
      currentlyCheckedBrands: this.props.checkedBrands
    });
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.checkedCategories !== prevProps.checkedCategories) {
      this.setState({ currentlyCheckedCategories: this.props.checkedCategories });
    }
    if (this.props.checkedBrands !== prevProps.checkedBrands) {
      this.setState({ currentlyCheckedBrands: this.props.checkedBrands });
    }
  }

  changeCategory = (id) => {
    this.setState({ currentlyCheckedCategories: toggleValue(id.toString(), this.state.currentlyCheckedCategories) });
  };

  changeBrand = (brands) => {
    this.setState({ currentlyCheckedBrands: brands });
  };

  hideFilterDrawer = () => {
    this.setState({
      isFilterDrawerOpen: false,
      currentlyCheckedCategories: this.props.checkedCategories,
      currentlyCheckedBrands: this.props.checkedBrands
    });
  };

  showFilterDrawer = () => {
    this.setState({ isFilterDrawerOpen: true });
  };

  handleMaxChange = (e) => {
    this.setState({ maxPoints: e.target.value });
  };

  handleMinChange = (e) => {
    this.setState({ minPoints: e.target.value });
  };

  submitFilters = () => {
    this.props.actions
      .submitAllSearch(
        this.state.currentlyCheckedCategories,
        this.state.currentlyCheckedBrands,
        this.state.minPoints,
        this.state.maxPoints,
        this.props.history
      )
      .then(() => {
        this.setState({ isFilterDrawerOpen: false });
      });
  };

  render() {
    const {
      filterButtonLabel,
      drawerTitle,
      categoryFilterTitle,
      pointValueTitle,
      minValueLabel,
      maxValueLabel,
      brandFilterTitle,
      cancelLabel,
      filterLabel,
      brandOptions,
      categoryFilters
    } = this.props;

    return (
      <Drawer
        start="md"
        buttonText={filterButtonLabel}
        buttonIcon="filter"
        onHide={this.hideFilterDrawer}
        isOpen={this.state.isFilterDrawerOpen}
        onOpen={this.showFilterDrawer}
      >
        <HeaderFour>
          <Icon name="filter" />
          {drawerTitle}
        </HeaderFour>
        <PanelContainer>
          <FilterPanel title={categoryFilterTitle}>
            <CategoryFilter
              categories={categoryFilters}
              changeCategory={this.changeCategory}
              currentlyCheckedCategories={this.state.currentlyCheckedCategories}
            />
          </FilterPanel>
          <FilterPanel title={pointValueTitle}>
            <PointsContainer>
              <PointsGroup controlId="formInlineMinVal">
                <PointsLabel>{minValueLabel}</PointsLabel>
                <FilterFormControl autoComplete="off" type="text" onChange={this.handleMinChange} placeholder="" />
              </PointsGroup>
              <FormGroupDivider>-</FormGroupDivider>
              <PointsGroup controlId="formInlineMaxVal">
                <PointsLabel>{maxValueLabel}</PointsLabel>
                <FilterFormControl autoComplete="off" type="text" onChange={this.handleMaxChange} placeholder="" />
              </PointsGroup>
            </PointsContainer>
          </FilterPanel>
          <FilterPanel title={brandFilterTitle}>
            <div>
              <BrandAlphaFilter
                handleBrandChange={this.changeBrand}
                currentlyChecked={this.state.currentlyCheckedBrands}
                brandOptions={brandOptions}
              />
            </div>
          </FilterPanel>
        </PanelContainer>
        <DrawerFooter>
          <DrawerButton onClick={this.hideFilterDrawer}>{cancelLabel}</DrawerButton>
          <DrawerButton onClick={this.submitFilters}>{filterLabel}</DrawerButton>
        </DrawerFooter>
      </Drawer>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({});

const mapResourceToProps = (getResource) => ({
  filterButtonLabel: getResource('catalog.search.mobileOpenFilterLabel', 'Filter'),
  drawerTitle: getResource('catalog.search.mobileFilterTitle', 'Filter'),
  cancelLabel: getResource('catalog.search.cancelLabel', 'Cancel'),
  filterLabel: getResource('catalog.search.filterLabel', 'Filter'),
  maxValueLabel: getResource('catalog.search.maxPointSearchLabel', 'To'),
  minValueLabel: getResource('catalog.search.minPointSearchLabel', 'From'),
  brandFilterTitle: getResource('catalog.search.brandFilterTitle', 'Brand'),
  pointValueTitle: getResource('catalog.search.pointValueTitle', 'Point Value'),
  categoryFilterTitle: getResource('catalog.search.categoryFilterTitle', 'Category')
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      submitAllSearch
    },
    dispatch
  )
});

export default withRouter(
  compose<any>(
    connect(
      mapStateToProps,
      mapDispatchToProps
    ),
    withResource(mapResourceToProps)
  )(FilterDrawer)
) as any;
