export const CONTENT_GET_REQUEST = 'rsv8-cms/CONTENT_GET_REQUEST';
export const CONTENT_GET_SUCCESS = 'rsv8-cms/CONTENT_GET_SUCCESS';
export const CONTENT_GET_FAILURE = 'rsv8-cms/CONTENT_GET_FAILURE';

export const LANDING_INTENT_PATH = 'rsv8-landing/LANDING_INTENT_PATH';
export type LANDING_INTENT_PATH = typeof LANDING_INTENT_PATH;
export const LANDING_SET_PATH = 'rsv8-landing/LANDING_SET_PATH';
export type LANDING_SET_PATH = typeof LANDING_SET_PATH;
export const LANDING_START_VALIDATION = 'rsv8-landing/LANDING_START_VALIDATION';
export type LANDING_START_VALIDATION = typeof LANDING_START_VALIDATION;
export const LANDING_FINISH_VALIDATION = 'rsv8-landing/LANDING_FINISH_VALIDATION';
export type LANDING_FINISH_VALIDATION = typeof LANDING_FINISH_VALIDATION;
export const LANDING_POSTPONE_PATH = 'rsv8-landing/LANDING_POSTPONE_PATH';
export type LANDING_POSTPONE_PATH = typeof LANDING_POSTPONE_PATH;
export const LANDING_CLEAR = 'rsv8-landing/LANDING_CLEAR';
export type LANDING_CLEAR = typeof LANDING_CLEAR;
export const SET_LOADING_DATA = 'rsv8-landing/SET_LOADING_DATA';
export type SET_LOADING_DATA = typeof SET_LOADING_DATA;
export const SET_DATA_LOADED = 'rsv8-landing/SET_DATA_LOADED';
export type SET_DATA_LOADED = typeof SET_DATA_LOADED;
export const DELETE_LAST_VISITED_LANDING = 'rsv8-landing/DELETE_LAST_VISITED_LANDING';
export type DELETE_LAST_VISITED_LANDING = typeof DELETE_LAST_VISITED_LANDING;

export const LANDING_CONFIRM_PATH = 'rsv8-landing/LANDING_CONFIRM_PATH';
export type LANDING_CONFIRM_PATH = typeof LANDING_CONFIRM_PATH;
export const LANDING_CONFIRM_CLEAR = 'rsv8-landing/LANDING_CONFIRM_CLEAR';
export type LANDING_CONFIRM_CLEAR = typeof LANDING_CONFIRM_CLEAR;
