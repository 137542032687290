import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formSelectors } from 'rsv8-forms';
import { register } from 'xcel-react-core';
import { debounce } from 'xcel-util/dist';
import { IndividualDetailInstructions as DetailInstructions } from '../../components/index';
import { NarrowGrid as Grid } from '../../components/index';
import { individualNominationActions } from '../../redux/actions/index';
import DetailForm from './DetailForm';

const { save: saveNomination, selectCustomFields } = individualNominationActions;

const SelectDetail = (props) => {
  const [validForm, setValidForm] = (React as any).useState(true);
  let delayedSaveNomination = () => undefined;
  if (props.saveNomination) {
    delayedSaveNomination = debounce(props.saveNomination, 1000, false);
  }

  const shouldFormSave = (value) => {
    setValidForm(value);
  };

  const handleBlur = () => {
    if (validForm) {
      delayedSaveNomination();
      props.selectCustomFields(props.formValues.NominationForm);
    }
  };
  return (
    <Grid onBlur={handleBlur}>
      <DetailInstructions />
      <DetailForm wizard={props.wizard} shouldFormSave={shouldFormSave} />
    </Grid>
  );
};

const mapState = (state) => {
  return {
    formValues: formSelectors.getFormValues('NominationDetailForm')(state)
  };
};

const mapDispatch = (dispatch) => bindActionCreators({ selectCustomFields, saveNomination }, dispatch);

const compose = register('rsv8-nomination/IndividualSelectDetails');

export default compose(
  connect(
    mapState,
    mapDispatch
  )
)(SelectDetail);
