import * as React from 'react';
import { InjectedFormProps } from 'redux-form';
import { BackendResource, Image } from 'rsv8-components';
import { register, withTheme } from 'xcel-react-core';

export interface SubmitMessageProps extends InjectedFormProps {
  request: any;
  messages: {
    error: string;
    success: string;
  };
  className: any;
  themeVariation: any;
}

const SubmitMessages: React.SFC<SubmitMessageProps> = (props) => {
  return (
    <React.Fragment>
      {props.submitSucceeded && (
        <div className={props.className}>
          <Image themeVariation={props.themeVariation} />
          <span>{props.messages.success}</span>
        </div>
      )}
      {props.submitFailed &&
        props.request.error &&
        props.request.error.response &&
        props.request.error.response.data &&
        props.request.error.response.data.errors &&
        Array.isArray(props.request.error.response.data.errors) && (
          <BackendResource resourceKey={props.request.error.response.data.errors[0].title} />
        )}
    </React.Fragment>
  );
};

export default register('rsv8-nomination/SubmitMessages')(withTheme())(SubmitMessages);
