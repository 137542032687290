import * as React from 'react';
import { connect } from 'react-redux';
import { Context, withWizard } from 'rsv8-wizard';
import { register } from 'xcel-react-core';
import { TeamCongratulationsInstructions } from '../../components/index';
import { teamNominationSelectors } from '../../redux/index';
import { Resources } from '../../types';
import { NarrowGrid } from './Shared/styles';
import TeamMemberPanel from './Shared/TeamMemberPanel';
import TeamSummary from './Shared/TeamSummary';

interface InternalProps {
  teamMembers: Array<Resources.Nominee>;
  wizard: Context;
}

const TeamMembers: React.SFC<InternalProps> = (props) => {
  return (
    <NarrowGrid>
      <TeamCongratulationsInstructions />
      <TeamSummary editable={false} />
      {props.teamMembers.map((id) => <TeamMemberPanel key={id.id} nominee={id} editable={false} />)}
    </NarrowGrid>
  );
};

const mapState = (state) => ({ teamMembers: teamNominationSelectors.getCurrentNominees(state) });

export default register('rsv8-nomination/TeamNominationCongratulations')(
  connect(
    mapState,
    null
  ),
  withWizard()
)(TeamMembers);
