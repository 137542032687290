import * as React from 'react';
// @ts-ignore
/* tslint:disable */

// TODO: Replace hardcoded fill.
// TODO: change ID naming scheme. Would hypothetically cause issues with 2+
export const TrophySvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="215"
    height="215"
    viewBox="0 0 215 215"
    id="trophySvg"
  >
    <defs>
      <path id="a" d="M0 0h215v215H0z" />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <path
        fill="#FBD476"
        d="M196.64 83.091c0 8.56-7.011 19.168-15.628 19.168h-8.75c.005-.162.01-.324.01-.486l-3.926-72.471h12.666c8.617 0 15.628 10.604 15.628 19.162v34.627zM33.988 102.26c-8.617 0-15.628-10.607-15.628-19.168V48.464c0-8.558 7.011-19.162 15.628-19.162h12.666l-3.925 72.471c0 .162.005.324.01.486h-8.75zm147.024-87.545h-13.456L166.76 0H48.24l-.795 14.714H33.988C15.243 14.714 0 29.855 0 48.464v34.627c0 18.611 15.243 33.756 33.988 33.756h10.538c6.256 25.894 28.29 45.69 55.44 48.814l-1.646 34.338-26.023 5.05V215h70.4v-9.305l-26.017-5.696-1.645-34.34c27.15-3.123 49.178-22.918 55.44-48.812h10.537c18.74 0 33.988-15.145 33.988-33.756V48.464c0-18.609-15.248-33.75-33.988-33.75z"
      />
    </g>
  </svg>
);
