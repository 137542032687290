import classNames from 'classnames';
import * as React from 'react';
import { BaseSocialShareablesButton, SocialShareableButtonProps } from './BaseSocialShareablesButton';
import { ShareInputText } from './ShareInputText';
import {
  ButtonWrapper,
  Container,
  IconWrapper,
  LabelText,
  SaveButton,
  Separator,
  ShareButton
  } from './styles';

enum ButtonStates {
  Buttons = 'Buttons',
  AvailableForCopying = 'AvailableForCopying'
}

interface SocialShareableButtonState {
  state: ButtonStates;
}

export class HoverSupported extends BaseSocialShareablesButton<SocialShareableButtonProps, SocialShareableButtonState> {
  constructor(props: SocialShareableButtonProps) {
    super(props);
    this.state = {
      state: ButtonStates.Buttons
    };
  }

  render() {
    return (
      <Container className={classNames(this.props.className, 'hover-supported')}>{this.renderContent()}</Container>
    );
  }

  renderContent() {
    if (this.state.state === ButtonStates.AvailableForCopying) {
      return <ShareInputText value={this.props.shareURL} onLeave={this.onLeave} />;
    }

    return (
      <ButtonWrapper className="button-wrapper hover-supported" onMouseLeave={this.onLeave}>
        <Separator className="separator" />
        <SaveButton
          onClick={this.onSave}
          className="save-button hover-supported"
          hoverCustomIcon={(this.props.saveAction && this.props.saveAction.hoverIcon) || ''}
        >
          <IconWrapper>{this.renderSaveButtonIcon()}</IconWrapper>
          <LabelText className="label-text">
            {this.props.saveLabelText || this.props.saveAction.label || 'save'}
          </LabelText>
        </SaveButton>
        <ShareButton
          className="share-button hover-supported"
          onClick={this.switchToCopyState}
          hoverCustomIcon={this.props.shareAction.hoverIcon}
        >
          <IconWrapper>{this.renderShareButtonIcon()}</IconWrapper>
          <LabelText className="label-text">
            {this.props.shareLabelText || this.props.shareAction.label || 'share'}
          </LabelText>
        </ShareButton>
      </ButtonWrapper>
    );
  }

  private onLeave = () => {
    this.setState({
      state: ButtonStates.Buttons
    });
  };

  private switchToCopyState = () => {
    this.setState(
      {
        state: ButtonStates.AvailableForCopying
      },
      () => this.props.shareAction.action()
    );
  };

  private onSave = () => {
    this.props.saveAction.action();
  };
}
