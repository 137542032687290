import { userStateSelectors } from 'rsv8-auth';
import { cacheService } from 'xcel-cache-service';
import { reduxObserver } from 'xcel-observer';
import { onSelectorChange } from 'xcel-react-core';
import { cachePlugin, createReduxModule, ReduxModule } from 'xcel-redux-module';
import { clear, confirmClear } from './redux/actions/landing';
import { ROOT_LANDING_STATE } from './redux/constants';
import { landingReducer as reducers } from './redux/reducers';
const landingModuleInit = ({ store }) => {
  const userChanged = onSelectorChange(store, userStateSelectors.getUser);
  store.subscribe(() => {
    userChanged((current, next) => {
      if (next.tokenType === 'User' && current && current.userId !== next.userId ) {
        store.dispatch(clear());
        store.dispatch(confirmClear());
      }
    });
  });
};

const landingModule: ReduxModule = cachePlugin(cacheService)(
  createReduxModule({
    name: ROOT_LANDING_STATE,
    init: landingModuleInit,
    reducers
  })
);
reduxObserver.addModule(landingModule);
export default landingModule;
