import { actions as wizardActions } from 'rsv8-wizard';
import { promiseDispatcher } from 'xcel-react-core';
import { destroy } from 'xcel-redux-orm';
import { nominationApi } from '../../../../api/index';
import { INDIVIDUAL_NOMINATION_WIZARD_NAME } from '../../../../constants';
import * as events from '../../../events';
import { individualNominationSelectors } from '../../../selectors/index';

const submitNominationCall = () => async (dispatch, getState) => {
  const state = getState();
  const isEligible = individualNominationSelectors.getCurrentAward(state).isEligible;
  const nominationId = individualNominationSelectors.getCurrentNominationId(state);

  if (!isEligible) {
    dispatch(wizardActions.setWizardStepByStepTitle(INDIVIDUAL_NOMINATION_WIZARD_NAME, 'Nominee'));
    return Promise.reject(new Error('Nominee is no longer eligible'));
  } else {
    const response = await nominationApi.createNominations({
      nominationSubmissionData: {
        ...individualNominationSelectors.getSaveNominationRequest(state),
        submit: true
      }
    } as any);

    dispatch(destroy('history', nominationId.toString()));

    return response;
  }
};

const submitNomination = promiseDispatcher(submitNominationCall, {
  success: events.SUBMIT_NOMINATION_SUCCESS,
  failure: events.SUBMIT_NOMINATION_FAILURE
});

export { submitNomination };
