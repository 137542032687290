import * as React from 'react';
import styled from 'styled-components';
import { register, withTheme } from 'xcel-react-core';
import SortIcon, { SORT_DIRECTION_VALUES } from './SortIcon';
import TableContext from './TableContext';

export interface SortStatus {
  field: string;
  direction: SORT_DIRECTION_VALUES;
}

export interface TableHeaderProps {
  field?: string;
  width?: string;
  onClick?: any;
  headerData?: any;
  deleteButton?: any;
}
export interface TableHeaderInternalProps extends TableHeaderProps {
  sort: SortStatus;
  sortable?: boolean;
  onSort: any;
}

const TableHeaderBase = styled.th`
  ${(props: TableHeaderProps) => props.width && `width:${props.width}`};
` as any;
const TableHeader: React.SFC<TableHeaderInternalProps> = ({
  deleteButton,
  field,
  headerData,
  children,
  sort,
  sortable,
  onSort,
  ...rest
}) => {
  const handleSort = (e) => {
    if (field !== undefined && sortable !== false) {
      onSort(field);
    }
    if (rest.onClick) {
      rest.onClick(e);
    }
  };
  return (
    <TableHeaderBase {...rest} onClick={handleSort}>
      {deleteButton && deleteButton}
      {children || headerData}{' '}
      {field !== undefined &&
        sortable !== false && <SortIcon direction={sort && sort.field === field ? sort.direction : 'unsorted'} />}
    </TableHeaderBase>
  );
};

const TableHeaderCell: React.SFC<TableHeaderProps> = (props) => {
  return (
    <TableContext.Consumer>
      {({ sort, onSort }) => {
        return <TableHeader {...props} sort={sort} onSort={onSort} />;
      }}
    </TableContext.Consumer>
  );
};

export { TableHeaderCell };

export default register('rsv8-components/TableHeaderCell')(withTheme())(TableHeaderCell);
