import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Text } from 'rsv8-components';
import styled from 'styled-components';

const StyledRow = styled(Row)`
  padding: 10px 0;
`;

export const TeamRow: React.SFC<any> = ({ head, children, ...rest }) => (
  <StyledRow {...rest}>
    <Col xs={24} sm={24}>
      <Text variationName="field-label">{head}</Text>
    </Col>
    <Col xs={24} sm={24}>
      {children}
    </Col>
  </StyledRow>
);
