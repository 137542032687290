import * as React from 'react';
import * as ReactDOM from 'react-dom';
import 'rsv8-achievement';
import { authMiddleware, clientMiddleware, registerAuthValidators, SessionWatcher } from 'rsv8-auth';
import { ScrollToTop } from 'rsv8-catalog';
import { activeCultureMiddleware } from 'rsv8-client';
import { BackendResourceProvider } from 'rsv8-components';
import 'rsv8-components/dist/app/components/Slider';
import { addConfigurationLoadListener } from 'xcel-config';
import 'rsv8-data-vis';
import 'rsv8-favorites';
import { ValidationConnector } from 'rsv8-forms';
import 'rsv8-metrics';
import 'rsv8-sales-entry';
import 'rsv8-metrics';
import { ThemeProvider } from 'rsv8-theme';
import 'rsv8-video';
import { apiProvider } from 'xcel-api-service';
import { reduxObserver } from 'xcel-observer';
import 'rsv8-alcon';
import { createReduxModuleStore, ReduxProvider } from 'xcel-redux-module';
import { jsonApiMiddleware } from 'xcel-util';
import '../src/app/components/ContactForm';
import { unregister } from './registerServiceWorker';

let store;
const renderApp = () => {
  const App = require('./App').default;
  ReactDOM.render(
    <ReduxProvider store={store}>
        <ScrollToTop>
          <ThemeProvider>
            <BackendResourceProvider>
              <App />
            </BackendResourceProvider>
          </ThemeProvider>
        </ScrollToTop>
        <ValidationConnector />
        <SessionWatcher ssoSuccessPath="/home" ssoFailurePath="/login-error" resetPasswordPath="/reset-password" />
    </ReduxProvider>,
    document.getElementById('root') as HTMLElement
  );
};

apiProvider.setMiddleware([authMiddleware, activeCultureMiddleware, jsonApiMiddleware, clientMiddleware]);

const renderError = (error) => {
  ReactDOM.render(<div>Failed to render: {JSON.stringify(error)}</div>, document.getElementById('root') as HTMLElement);
};

const hotReload = () => {
  console.log('application hot module reloading');
  try {
    renderApp();
  } catch (error) {
    renderError(error);
  }
};

if ((module as any).hot) {
  // Support hot reloading of components
  (module as any).hot.accept('./App', hotReload);
}

// load when configuration is ready.
addConfigurationLoadListener(() => {
  if (store === undefined) {
    store = createReduxModuleStore();
    reduxObserver.subscribe(store, (module) => module.name.toLowerCase().indexOf('admin') === -1);
  }
  registerAuthValidators();
  renderApp();
});

// remove service worker registrations
unregister();
