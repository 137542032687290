import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'rsv8-components';
import { HeaderTwo, Text ,AnimatedCircle } from 'rsv8-components';
import styled from 'styled-components';
import { NominationApplicationViewModel } from 'xcel-api-generator/dist/nomination';
import { numberFormat } from 'xcel-util';
import { nominationDetailSelectors } from '../../../redux/selectors/nomination';
import { Button } from 'react-bootstrap';
import AwardInfo from './AwardInfo';
import AwardStars from './AwardStars';
import { compose } from "redux";

const AwardHeaderContainer = styled.div`
  padding: 25px 25px 0px 25px;
  @media (max-width: 320px) {
    padding: 15px;
  }
`;

type AwardHeaderProps = {
  culture: any;
  award: any;
  awardLabel: string;
  open: boolean;
  details: any;
  id: number;
  actionsLoading: boolean;
  application?: NominationApplicationViewModel;
  redeemClickHandler: (event: any) => void;
  tradeClickHandler: (event: any) => void;
  redeemButtonTextLabel: string;
  tradeButtonTextLabel: string;
  viewCertificateLabel: string;
  pointsTextLabel: string;
  showNominationIcon: string;
};

const ButtonGroup = styled.div`
  margin-top: 45px;
`as any;

const RedeemButton = styled(Button)`
  margin-right: 10px;
` as any;

const Spinner = styled(AnimatedCircle)`
  margin-top: 35px;
` as any;

const PointsAward = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
` as any;

const TextRightContainer = styled.div`
  text-align: right;
`;

const AwardText = styled.div`
  font-family: source-sans-pro,sans-serif !important;
  font-size: 20px !important;
` as any;

enum AwardStatus {
  Approved = 'nom_status_approved',
  Awarded = 'nom_status_awarded'
}

const AwardHeader = ({ culture, award, application, awardLabel, open, details, redeemClickHandler, tradeClickHandler, actionsLoading,
                       tradeButtonTextLabel, redeemButtonTextLabel, viewCertificateLabel, pointsTextLabel, showNominationIcon }: AwardHeaderProps) => {
  const formatISODateString = (dateString: string): string => {
    return dateString ? Intl.DateTimeFormat(culture).format(new Date(dateString)) : dateString;
  };
  const awardValue = award.awardValue ? numberFormat(award.awardValue) : <span>&nbsp;</span>;
  return (
    <AwardHeaderContainer>
      <Row>
        {showNominationIcon === 'true' ? <Col xs={6} sm={4} md={4} lg={3}>
            <AwardStars award={award.application || application} />
          </Col> : null}
        <Col xs={18} sm={20} md={15} lg={13}>
          <Text>{formatISODateString(award.awardDate)}</Text>
          <HeaderTwo>{award.applicationName}</HeaderTwo>
        </Col>
        <TextRightContainer>
          <Col xs={24} sm={24} md={6} lg={8}>
            {
              award.tradedForPoints ?  <Col xs={12} sm={24}>
                <PointsAward>
                  {pointsTextLabel}
                </PointsAward>
              </Col> : null
            }
            <Col xs={12} sm={24}>
              <AwardText>
                {awardValue}
              </AwardText>
            </Col>
            <Col xs={12} sm={24}>
              {award.certificateViewUrl && (
                  <Link href={award.certificateViewUrl} target="_blank">
                    {viewCertificateLabel}
                  </Link>
              )}
            </Col>
            {
              actionsLoading ? <Spinner /> : (award.statusId !== AwardStatus.Awarded ?
                  <ButtonGroup>
                    {award.showRedeemAction ? <RedeemButton onClick={redeemClickHandler}>{redeemButtonTextLabel}</RedeemButton> : null}
                    {award.showTradeForPointsAction ?  <Button onClick={tradeClickHandler}>{tradeButtonTextLabel}</Button> : null}
                  </ButtonGroup> : null)
            }
          </Col>
        </TextRightContainer>
      </Row>
      <AwardInfo open={open} details={details} />
    </AwardHeaderContainer>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    details: nominationDetailSelectors.selectOne(state, ownProps.id)
  };
};

// @ts-ignore
export default compose(
    connect(mapStateToProps)
)(AwardHeader) as any;