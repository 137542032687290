export const ROOT_STATE = 'rsv8-catalog';
export const TRENDING_STATE = 'trending';
export const SEARCH_STATE = 'search';
export const FAVORITE_STATE = 'favorites';
export const ACCOUNT_STATE = 'account';
export const CART_STATE = 'cart';
export const ORDER_STATE = 'order';
export const AUTH_USER_STATE = 'rsv8-auth/user';

export const CATEGORY_JSON_TYPE = 'categoryMenuModel';
export const CART_ITEM_JSON_TYPE = 'cartItemModel';
export const ORDER_JSON_TYPE = 'orderModel';
export const USER_PROFILE_JSON_TYPE = 'userProfileModel';
export const PRODUCT_MENU_JSON_TYPE = 'productMenuModel';
export const PRODUCT_LIST_JSON_TYPE = 'productListSummaryModel';
export const PRODUCT_JSON_TYPE = 'productModel';
export const PRODUCT_DETAIL_JSON_TYPE = 'productDetailModel';
export const TRENDING_PRODUCT_JSON_TYPE = 'trendingProductModel';

export const GET_USER_FAVORITES_ENDPOINT = 'getProductsListsById';

export const NAV_ITEM_JSON_TYPE = 'navigationItem';
