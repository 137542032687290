import * as React from "react";
import styled, { StyledComponentClass } from "styled-components";

((_1?: StyledComponentClass<any, any>, _2?: typeof React) => 0)();

export const ProductsComponentBody = styled.div`
  ::-webkit-scrollbar {
    width: 7px;
    border-radius: 3.5px;
    background-color: #e1e1e1;
  }
  ::-webkit-scrollbar-track {
    border-radius: 3.5px;
    background-color: #ffffff;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 3.5px;
    background-color: #e1e1e1;
  }
` as any;

export const ProductContainer = styled.div`
  border: 1px solid #b2b2b2;
  border-radius: 5px;
  background-color: #fafafa;
  overflow: hidden;

  :nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }
  :nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
  }
  :nth-child(3) {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
  }
  :nth-child(4) {
    -ms-grid-row: 2;
    -ms-grid-column: 3;
  }
  :nth-child(5) {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
  }
  :nth-child(6) {
    -ms-grid-row: 3;
    -ms-grid-column: 3;
  }
  :nth-child(7) {
    -ms-grid-row: 4;
    -ms-grid-column: 1;
  }
  :nth-child(8) {
    -ms-grid-row: 4;
    -ms-grid-column: 3;
  }
  :nth-child(9) {
    -ms-grid-row: 5;
    -ms-grid-column: 1;
  }
  :nth-child(10) {
    -ms-grid-row: 5;
    -ms-grid-column: 3;
  }
  :nth-child(11) {
    -ms-grid-row: 6;
    -ms-grid-column: 1;
  }
  :nth-child(12) {
    -ms-grid-row: 6;
    -ms-grid-column: 3;
  }
  :nth-child(13) {
    -ms-grid-row: 7;
    -ms-grid-column: 1;
  }
  :nth-child(14) {
    -ms-grid-row: 7;
    -ms-grid-column: 3;
  }
  :nth-child(15) {
    -ms-grid-row: 8;
    -ms-grid-column: 1;
  }
  :nth-child(16) {
    -ms-grid-row: 8;
    -ms-grid-column: 3;
  }

  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 150px auto;
  }
`;

export const ProductHeaderContainer = styled.div`
  padding: 20px 15px;

  .product__title {
    font-size: 10px;
    font-weight: bold;
    color: #505d6f;
    line-height: 1.4;
  }

  .product__subtitle {
    font-size: 10px;
    color: #505d6f;
    line-height: 1.4;
  }

  @media screen and (max-width: 767px) {
    border-bottom: 1px solid #b2b2b2;

    .product__title {
      text-align: center;
    }
    .product__subtitle {
      text-align: center;
    }
  }

  @media screen and (min-width: 768px) {
    border-bottom-right-radius: 0;
    border-right: 1px solid #b2b2b2;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .product__title {
      padding-bottom: 10px;
    }
  }
`;

export const ProductBody = styled.div`
  background-color: #fff;
  border-radius: 5px;
  padding: 20px 15px;
  display: flex;
  justify-content: space-around;
  flex: 1;

  .product__detail-label {
    font-size: 10px;
    font-weight: bold;
    color: #505d6f;
    line-height: 1.4;
    text-align: center;
  }

  .product__detail {
    font-size: 10px;
    color: #505d6f;
    line-height: 1.4;
    text-align: center;
    padding: 0 10px;
  }

  .product__detail-values {
    display: flex;
    padding-top: 8px;
  }

  .product__detail-value {
    flex-grow: 1;
  }

  .product__detail-value + .product__detail-value {
    border-left: 1px solid #b2b2b2;
    padding-left: 15px;
    margin-left: 15px;
    color: #505d6f;
    line-height: 1.4;
    font-size: 10px;
  }

  @media screen and (min-width: 768px) {
    border-bottom-left-radius: 0px;
  }
`;

export const NoDataSpinner = styled.i`
  font-size: 24px;
`;
