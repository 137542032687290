import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Styled from 'styled-components';
import { setActiveModal } from '../../redux/actions';
import { getCurrentModal } from '../../redux/selectors';
import { CreateRouteButton } from '../CreateRoute';
import DeleteAssetRoute from '../DeleteAssetRoute';
import { EditRouteButton } from '../EditRoute';

interface Bar {
  node?: {
    id?: number;
    parentId?: any;
    routeNodeName?: any;
  };
  handleHide?: any;
  actions?: {
    setActiveModal?: typeof setActiveModal;
  };
  activeModal?: Function;
}

const StyledRow = Styled(Row)`
    background: rgba(0,169,224,0.1) !important;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    display: inline-block;
  i:hover {
    cursor: pointer;
  }
`;

class ToolBar extends React.Component<Bar> {
  state = { modalType: '' };

  handleHide = () => {
    this.props.actions.setActiveModal('');
  };

  render() {
    const { node } = this.props;

    return Object.keys(node).length > 0 ? (
      <StyledRow>
        <Col sm={3}>
          <CreateRouteButton handleHide={this.handleHide} />
        </Col>
        <Col sm={3}>
          <EditRouteButton routeId={node.id} node={node} handleHide={this.handleHide} show={this.state.modalType} />
        </Col>
        <Col sm={3}>
          <DeleteAssetRoute routeId={node.id} parentId={node.parentId} />
        </Col>
      </StyledRow>
    ) : null;
  }
}

type MapState = Pick<Bar, 'activeModal'>;
type MapDispatch = Pick<Bar, 'actions'>;

const mapStateToProps = (state): MapState => {
  return {
    activeModal: getCurrentModal(state)
  };
};

const mapDispatchToProps = (dispatch): MapDispatch => ({
  actions: bindActionCreators(
    {
      setActiveModal
    },
    dispatch
  )
});

export default connect<MapState, MapDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(ToolBar);
