import * as React from 'react';
import { Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Image, HeaderTwo, HeaderThree } from 'rsv8-components';
import { withResource, withContent } from 'xcel-react-core';
import { getUser, toggleProductDetailsModal, redeemAchievement } from '../../redux/actions';
import {
  getProgramLevel,
  getProgramLevelProduct,
  getUserInfo,
  getOrderInfo,
  getSharePreview
} from '../../redux/selectors';
import EditInfo from '../EditInfo/EditInfo';
import Modal from '../Modal/Modal';
import OrderDetailsInfo from './OrderDetailsInfo';
import {
  ModalDetailsButton,
  ModalDetailsRow,
  ModalText,
  ModalSeparator,
  ModalSocialSuccessText,
  ProductDetailsImage,
  ProductShareRedemptionImage,
  ProductSharePreviewContainer,
  ProductSharePreviewHtml,
  ButtonLoader
} from './styles';
import { ProductDetailsModalProps } from './types';
import { withRouter } from 'react-router';
import { shareRedemption } from '../../redux/actions/achievement/achievement';

const ProductDetails: React.SFC<ProductDetailsModalProps> = ({
  dispatch,
  toggleShare,
  titles,
  order,
  sharePreview,
  saveInfo,
  socialShareTitle,
  socialShareText,
  socialShareButtonText,
  socialShareSuccessText,
  congratsTitle,
  congratsTextTop,
  congratsTextBottom,
  checkoutLabel,
  checkoutConfirmText,
  placeOrderLabel,
  product,
  programLevel,
  userProfile,
  reloadLevels,
  ...rest
}) => {
  React.useEffect(() => void dispatch(getUser()), []);

  const [isWaiting, setIsWaiting] = React.useState(false);
  const [shared, setShared] = React.useState(false);
  const [isCheckout, setIsCheckout] = React.useState(false);
  const handlePlaceOrder = async (values) => {
    setIsWaiting(true);
    const {emailAddress: email, phone} = userProfile;
    await dispatch(redeemAchievement({email, phone, ...values}));
    setIsWaiting(false);
    reloadLevels();
  };
  const onClose = () => {
    dispatch(toggleProductDetailsModal());
    setIsCheckout(false);
  };
  const share = async () => {
    setIsWaiting(true);
    setShared(await dispatch(shareRedemption(programLevel.achievementId)));
    setIsWaiting(false);
  };
  const { imageLg, imageSm } = Array.isArray(product.images) ? product.images[0] : product.images;
  const title = order ? titles[2] : (isCheckout ? titles[1] : titles[0]);
  return (
    <Modal modalHeading={title} toggleModal={onClose} show={true} {...rest}>
      <ModalDetailsRow>
        <Col md={11}>
          {!order && <ProductDetailsImage src={imageLg} alt={product.productName} /> || null}
          {order && (
            <React.Fragment>
              <HeaderTwo>{ congratsTitle }</HeaderTwo>
              <ModalText themeVariation="text-black">{ congratsTextTop }</ModalText>
              <ModalSeparator />
              <OrderDetailsInfo { ...order } image={imageSm} />
              <ModalText themeVariation="text-black">{ congratsTextBottom }</ModalText>
            </React.Fragment>
          )}
        </Col>
        <Col md={11} mdOffset={2}>
          {!isCheckout && !order && (
            <React.Fragment>
              <HeaderThree>{programLevel.name}</HeaderThree>
              <HeaderTwo>{product.name || product.productName}</HeaderTwo>
              <ModalText themeVariation="text-black">{product.description}</ModalText>
              {programLevel.achievedDate && (
                <ModalDetailsButton
                  themeVariation="secondary-small-full-width"
                  onClick={() => setIsCheckout(true)}
                >
                  {checkoutLabel}
                </ModalDetailsButton>
              )}
            </React.Fragment>
          ) || null}
          {isCheckout && !order && (
            <React.Fragment>
              <HeaderTwo>{checkoutConfirmText}</HeaderTwo>
              <EditInfo
                userProfile={userProfile}
                hiddenFields={['businessName', 'addressTwo']}
                insideModal={true}
                saveInfo={handlePlaceOrder}
                saveLabel={placeOrderLabel}
                isWaiting={isWaiting}
              />
            </React.Fragment>
          ) || null}
          {order && sharePreview && (
            <React.Fragment>
              <ProductSharePreviewContainer>
                <ProductShareRedemptionImage src={sharePreview.imageUrl} alt="" />
                <ProductSharePreviewHtml dangerouslySetInnerHTML={{ __html: sharePreview.previewHtml}}/>
              </ProductSharePreviewContainer>
              <HeaderThree>{ socialShareTitle }</HeaderThree>
              <ModalText themeVariation="text-black">{ socialShareText }</ModalText>
              {shared ?
                <ModalSocialSuccessText themeVariation="small-text-bold">
                  <Image themeVariation="checkmark-small-pink" /> { socialShareSuccessText }
                </ModalSocialSuccessText> :
                <ModalDetailsButton themeVariation="secondary-small-full-width" onClick={share} disabled={isWaiting}>
                  {isWaiting ? <ButtonLoader width={20} strokeWidth={2} /> : socialShareButtonText}
                </ModalDetailsButton>
              }
            </React.Fragment>
          )}
        </Col>
      </ModalDetailsRow>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  product: getProgramLevelProduct(state),
  programLevel: getProgramLevel(state),
  userProfile: getUserInfo(state),
  order: getOrderInfo(state),
  sharePreview: getSharePreview(state)
});

const mapResourceToProps = (getResource) => ({
  titles: [
    getResource('achievement.productDetailsModal.title', 'Product Details'),
    getResource('achievement.productDetailsModal.checkoutTitle', 'Checkout'),
    getResource('achievement.productDetailsModal.confirmTitle', 'Confirmation')
  ],
  congratsTitle: getResource(
    'achievement.productDetailsModal.congratsTitle',
    'Congratulations! Your well-deserved reward is on it’s way.'
  ),
  congratsTextTop: getResource('achievement.productDetailsModal.congratsText.top', ''),
  congratsTextBottom: getResource('achievement.productDetailsModal.congratsText.bottom', ''),
  checkoutLabel: getResource('achievement.productDetailsModal.checkoutLabel', 'Proceed to checkout'),
  checkoutConfirmText: getResource(
    'achievement.productDetailsModal.checkoutConfirmText',
    'Please, confirm your mailing address.'
  ),
  placeOrderLabel: getResource('achievement.productDetailsModal.placeOrderLabel', 'Place Order'),
  socialShareTitle: getResource(
    'achievement.productDetailsModal.socialShare.title',
    'You’re awesome! Now, let everyone know.'
  ),
  socialShareText: getResource('achievement.productDetailsModal.socialShare.text', ''),
  socialShareSuccessText: getResource('achievement.productDetailsModal.socialShare.successText', ''),
  socialShareButtonText: getResource(
    'achievement.productDetailsModal.socialShare.buttonText',
    'Yes! Share to social feed'
  )
});

export default compose<any>(
  connect(mapStateToProps),
  withRouter,
  withContent(),
  withResource(mapResourceToProps)
)(ProductDetails);
