import { Card } from 'rsv8-components';
import { CardBody } from 'rsv8-components/dist/app/components/Cards/CardBody';
import styled from 'styled-components';

export const getHeight = (thesize: any) => {
  const weight = 1;
  return (112 + thesize) * weight;
};
export const getWidth = (thesize: any) => {
  return 112 + thesize;
};

export const ProductCardDiv = styled(Card)<{ cursor: string }>`
  cursor: ${(props: any) => props.cursor};
` as any;

export const ProductCardBody = styled(CardBody)`` as any;

export const RebateCardTitle = styled.h3`
  font-family: open-sans-bold;
  color: #505d6f;
  font-size: 24px;
  text-align: center;
` as any;

export const CardImage = styled.div`
  background-image: url(${(props: any) => props.backgroundImage});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 0 auto;
  height: 120px;
  display: flex;
  align-items: center;
  @media (min-width: 992px) {
    height: 160px;
  }
  /* @media (min-width: 1366px) {
    height: 160px;
  } */
` as any;
