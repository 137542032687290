import * as events from '../../events';
import { getLastUser } from '../../selectors/catalogSelectors';
import { userApiActions } from './userApiActions';

const getUser = () => async (dispatch, getState) => {
  await dispatch(userApiActions.getUser({ jsonApi: true }));
  dispatch({ type: events.SET_ORDER_USER, payload: getLastUser(getState()) });
};

export { getUser };
