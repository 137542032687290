import * as React from 'react';
import { Button } from 'rsv8-components';
import { FieldOnChangeHandler, ReactField } from 'rsv8-forms';
import { withResource } from 'xcel-react-core';
import { Div } from '../../components/index';

export interface NomineeDetailFormHeadProps {
  // hasAwardOptions: boolean;
  hasRoleOptions: boolean;
  // awardOptions: Array<{ value: any; label: any }>;
  roleOptions: Array<{ value: any; label: any }>;
  handleApplyClick: () => void;
  handleRoleChange: FieldOnChangeHandler;
  // handleAwardChange: FieldOnChangeHandler;
  handleSelectAll: FieldOnChangeHandler;
}

interface Props extends NomineeDetailFormHeadProps {
  awardLabel: any;
  roleLabel: any;
  selectAllLabel: any;
}

const NomineeDetailFormHead: React.SFC<Props> = (props) => {
  return (
    <React.Fragment>
      {props.hasRoleOptions ? (
        <ReactField
          name="allNomineeRole"
          component="bs-select"
          label="Role"
          initialValue={props.roleOptions[0].value}
          onChange={props.handleRoleChange}
          options={props.roleOptions}
        />
      ) : null}

      {props.hasRoleOptions ? (
        <React.Fragment>
          <Div display="inline-block">
            <ReactField
              name="selectAllNominees"
              component="checkbox"
              label="Select All"
              onChange={props.handleSelectAll}
            />
          </Div>
          <Div float="right">
            <Button primary={true} onClick={props.handleApplyClick}>
              Apply
            </Button>
          </Div>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

const mapResource = (getResource) => ({
  awardLabel: getResource('nominationProcess.awardValueLabel', 'Award Value'),
  roleLabel: getResource('nominationProcess.roleLabel', 'Role'),
  selectAllLabel: getResource('nominationProcess.selectAllLabel', 'Select All')
});

export default withResource(mapResource)(NomineeDetailFormHead) as React.ComponentType<NomineeDetailFormHeadProps>;
