import { promiseDispatcher } from 'xcel-react-core';
import { createJsonApiActions } from 'xcel-redux-orm';
import { productApi } from '../../../../api';

const actions = createJsonApiActions('getProducts');
const getProductsCall = () => {
  return productApi.getProductsProductlist({ jsonApi: true });
  // return productApi.getProducts({ ids: productArr }, { jsonApi: true });
};

const getProducts = promiseDispatcher(getProductsCall, actions);

export { getProducts };
