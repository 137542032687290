import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Grid } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import ContactInfo from '../../components/Checkout/ContactInfo/ContactInfo';
import ItemReview from '../../components/Checkout/ItemReview/ItemReview';
import ShippingInfo from '../../components/Checkout/ShippingInfo/ShippingInfo';
import { getOrderById } from '../../redux/actions';
import { getOrderConfirmation } from '../../redux/selectors';
import { CheckoutIcon } from '../Checkout/styles';
import ConfirmationNumber from './ConfirmationNumber';
import GyftEmailInformation from './GyftEmailInformation';
import GyftInstructions from './GyftInstructions';
import { CheckoutConfirmHeading } from './styles';
export interface CheckoutConfirmationProps {
  orderConfirmHeading: string;
  location: any;
  checkoutConfirmationIcon: any;
  order: any;
  actions: {
    getOrderById: any;
  };
}

export interface CheckoutConfirmationState {
  orders: any;
}
class CheckoutConfirmation extends React.Component<CheckoutConfirmationProps, CheckoutConfirmationState> {
  state: CheckoutConfirmationState = { orders: [] };

  componentWillMount() {
    this.setState({ orders: this.props.location.state });
    this.props.location.state.map((order) => {
      this.props.actions.getOrderById(order.id);
    });
  }

  constructOrderProducts = (orderDetails) =>
    orderDetails.reduce(
      (arr, current) => [
        ...arr,
        { ...current.product, productOptionName: current.productOptionName, quantity: current.quantity }
      ],
      []
    );

  render() {
    const { orders } = this.state;
    const { orderConfirmHeading, checkoutConfirmationIcon } = this.props;
    return (
      <Grid themeVariation="page-wrapper">
        <Row>
          <Col xs={20} xsOffset={2} sm={22} smOffset={1}>
            <CheckoutIcon src={checkoutConfirmationIcon} />
            <CheckoutConfirmHeading>{orderConfirmHeading}</CheckoutConfirmHeading>
          </Col>
        </Row>
        {orders &&
          orders.map((order) => {
            return (
              <Row key={order.id}>
                <Col xs={22} xsOffset={1} sm={16} smOffset={4} md={12} mdOffset={6} lg={8} lgOffset={8}>
                  <ConfirmationNumber orderInfo={order} />
                  <ItemReview products={this.constructOrderProducts(order.orderDetails)} confirmation={true} />
                  {order.orderDetails.some(
                    (product) => product.product.isDigitalCard && product.product.digitalCardId
                  ) && (
                    <React.Fragment>
                      <GyftEmailInformation orderInfo={order} />
                      <GyftInstructions />
                    </React.Fragment>
                  )}
                </Col>
              </Row>
            );
          })}
        <Row>
          <Col xs={22} xsOffset={1} sm={16} smOffset={4} md={12} mdOffset={6} lg={8} lgOffset={8}>
            {orders[0].orderDetails.some((product) => !product.product.isDigitalCard) && (
              <ShippingInfo userProfile={orders[0].shippingInfo} confirmation={true} />
            )}
            <ContactInfo userProfile={orders[0].shippingInfo} />
          </Col>
        </Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  order: getOrderConfirmation(state, ownProps.location.state.id)
});

const mapResourceToProps = (getResource) => ({
  checkoutConfirmationIcon: getResource(
    'catalog.checkout.confirmationIcon',
    '/clientassets/images/Desktop-ConfirmationIcon.svg'
  ),
  orderConfirmHeading: getResource('catalog.checkout.confirmationHeading', 'Order Confirmation')
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getOrderById
    },
    dispatch
  )
});
export default compose<any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withResource(mapResourceToProps)
)(CheckoutConfirmation);
