import styled from 'styled-components';

export const Container = styled.div`
  padding: 15px 20px;
  display: flex;
  flex-direction: row;
  width: 300px;
  background: white;
`;

export const LoadingContainer = styled.div`
  padding: 15px 20px;
  display: flex;
  flex-direction: row;
  width: 300px;
  background: white;
  justify-content: center;
`;

export const AvatarWrap = styled.div`
  margin-right: 20px;
`;

export const Avatar = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #b5be35;
  color: #f8f8f8;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div`
  font-size: 16px;
  color: #414141;
  text-overflow: ellipsis;
`;

export const Detail = styled.div`
  font-size: 12px;
  color: #414141;
  font-weight: 300;
  padding-top: 15px;
  align-items: center;
  display: flex;
`;

export const DetailIcon = styled.div`
  margin-right: 17px;
  display: flex;
`;

export const ContentWrap = styled.div`
  flex: 1;
  overflow: hidden;
`;
