import * as React from 'react';
import { register, withTheme } from 'xcel-react-core';
import OrderHistoryContext from '../OrderHistoryContext';
import { OrderContainer } from '../styles';
import OrderDetails from './OrderDetails';
import Product from './Product';

const Order: React.SFC<any> = ({ className }) => {
  return (
    <OrderHistoryContext.Consumer>
      {({ products }) => (
        <OrderContainer className={className}>
          <OrderDetails />
          {products &&
            products.orderDetails &&
            products.orderDetails.map((product, indexProduct) => {
              return product.id && <Product key={indexProduct} product={product} />;
            })}
        </OrderContainer>
      )}
    </OrderHistoryContext.Consumer>
  );
};

export default register('rsv8-catalog/Order')(withTheme())(Order);
