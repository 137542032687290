import Tree from '@xceleration/react-ui-tree';
import '@xceleration/react-ui-tree/dist/react-ui-tree.css';
import cx from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { destroyRequest } from 'xcel-redux-orm';
import { convertToPatchOperations } from 'xcel-util';
import { assetAdminApiActions, setActiveAssetRoute } from '../../redux/actions';
import { getActiveRoute, routeSelector, routeSelectorWithChildren } from '../../redux/selectors';
import { assetAdminToast } from '../../util';
import ToolBar from './ToolBar';

export interface TreeRoutes {
  actions?: {
    destroyRequest?: Function;
    getRoutes?: Function;
    setActiveAssetRoute?: Function;
    updateAssetRoute?: Function;
  };
  routes: any;
}

const TreeWrap = styled.div`
  .collapse {
    display: block;
  }
  .is-active {
    color: white;
    background-color: #31363f;
    border-radius: 10px;
    padding: 4px 5px;
  }
` as any;

class RouteTree extends React.Component<TreeRoutes> {
  state = {
    isActive: {}
  };

  componentDidMount() {
    this.getAssetRoutes({});
    this.props.actions.setActiveAssetRoute('root');
  }

  getAssetRoutes = (filter = {}) => {
    this.props.actions.getRoutes(filter);
  };

  mapRoutestoTree = () => {
    const { routes } = this.props;

    return this.buildTreeObject(routes);
  };

  defaultSort = (a, b) => {
    if (a.routeNodeName < b.routeNodeName) return -1;
    if (a.routeNodeName > b.routeNodeName) return 1;
    return 0;
  };

  buildTreeObject = (tree, parent: any = {}) => {
    const nodes = tree.filter((node) => node.parentId === parent.id).sort(this.defaultSort);
    return nodes.map((node) => ({ ...node, children: this.buildTreeObject(tree, node) }));
  };

  handleNodeClick = (node) => {
    this.props.actions.setActiveAssetRoute(node.id);
    this.setState({ isActive: node });
    try {
      this.props.actions.getRoutes({ filterFilter: `id = ${node.id}` });
    } catch (err) {
      assetAdminToast('admin-warning', err);
    }
  };

  handleNodeChange = (e) => {
    const { source, target } = e;
    if (source !== undefined && parent !== undefined && source.parent.id !== target.parent.id) {
      try {
        this.createPatchDocs(source.node.id, target.parent.id);
      } catch (err) {
        assetAdminToast('admin-warning', err);
      }
    }
  };

  createPatchDocs = async (nodeId, parentId) => {
    const model = {
      parentId
    };
    const request = {
      operations: convertToPatchOperations(model)
    };
    try {
      await this.props.actions.updateAssetRoute({ id: nodeId, request });
      await this.props.actions.destroyRequest('getClientAssetRoutes');
      await this.props.actions.getRoutes({ filterFilter: `id = ${parentId}` });
    } catch (err) {
      assetAdminToast('admin-warning', err);
    }
  };

  renderNode = (node) => {
    const { isActive }: any = this.state;
    return (
      <div>
        {node.routeNodeName === '' ? (
          <span
            onClick={() => this.handleNodeClick(node)}
            className={cx('node', {
              'is-active': isActive.id === node.id
            })}
          >
            Root
          </span>
        ) : (
          <span
            onClick={() => this.handleNodeClick(node)}
            className={cx('node', {
              'is-active': isActive.id === node.id
            })}
          >
            {node.routeNodeName}
          </span>
        )}
      </div>
    );
  };

  render() {
    const tree = this.mapRoutestoTree()[0];

    return this.props.routes.length > 0 ? (
      <TreeWrap>
        <ToolBar node={this.state.isActive} />
        <Tree
          paddingLeft={20}
          tree={tree}
          onNodeChange={(e) => {
            this.handleNodeChange(e);
          }}
          renderNode={this.renderNode}
        />
      </TreeWrap>
    ) : null;
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      destroyRequest,
      getRoutes: assetAdminApiActions.getClientAssetRoutes,
      setActiveAssetRoute,
      updateAssetRoute: assetAdminApiActions.patchClientAssetRoutesById
    },
    dispatch
  )
});
const getCombinedRoutes = (state) => {
  const routes = routeSelector.selectMany(state, null);
  const childRoutes = routeSelectorWithChildren.selectMany(state, null);

  return [
    ...childRoutes,
    ...routes.filter((child) => childRoutes.filter((parent) => parent.id === child.id).length === 0)
  ];
};
const mapStateToProps = (state, ownProps) => {
  return {
    routes: getCombinedRoutes(state),
    activeRoute: getActiveRoute(state)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RouteTree as any);
