import * as React from 'react';
import { ComponentProvider } from '../ComponentProvider';
import { ValidationProvider } from '../Validation';
import { Validator } from '../Validation/types';

export const ensureComponent = (
  myComponent: string | React.SFC<any> | React.ComponentClass<any>
): React.SFC<any> | React.ComponentClass<any> | string =>
  typeof myComponent === 'string' ? ComponentProvider.getComponent(myComponent) : myComponent;

const validateToFn = (validation: Validator | string): Validator =>
  typeof validation === 'function' ? validation : ValidationProvider.getValidation(validation);

export const ensureValidation = (validate) => {
  if (validate === undefined || (validate.length && validate.length === 0)) {
    return undefined;
  } else {
    return [].concat(validate).map(validateToFn);
  }
};

const transformProps = (BaseComponent) => (props) => {
  return (
    <BaseComponent
      {...props}
      component={ensureComponent(props.component)}
      validate={ensureValidation(props.validate)}
      warn={ensureValidation(props.warn)}
    />
  );
};

export default transformProps;
