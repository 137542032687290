import * as d3 from 'd3';
import * as React from 'react';

interface Props {
  id: any;
}
export const Spinner: React.FunctionComponent<Props> = ({ id }) => {
  let angle,
    offset,
    data,
    width = 175,
    height = 175,
    radius = 70,
    dispersion = 10;

  React.useEffect(() => {
    let svgContainer = d3
      .select(`#${id}`)
      .append('svg')
      .attr('width', width)
      .attr('height', height);

    data = d3.range(10).map(function(i) {
      const tau = 2 * Math.PI;
      angle = (i / 10) * tau;
      offset = 5 + radius + dispersion;
      return {
        cx: offset + Math.cos(angle) * radius,
        cy: offset + Math.sin(angle) * radius,
        r: 14
      };
    });

    let dots = svgContainer
      .selectAll('circle')
      .data(data)
      .enter()
      .append('circle');
    dots
      .attr('r', function(d) {
        return d.r;
      })
      .attr('cx', function(d) {
        return d.cx;
      })
      .attr('cy', function(d) {
        return d.cy;
      })
      .attr('fill', function(d, i) {
        return '#507f86';
      });

    const text = svgContainer
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('y', height / 2 + 16)
      .attr('x', width / 2 - 5)
      .text('14');
    text
      .style('font-weight', '300')
      .style('letter-spacing', '-3px')
      .style('fill', '#0b2265')
      .style('font-size', '58px');
  }, []);
  return <span id={id} />;
};
