import * as React from 'react';
import styled, { css } from 'styled-components';

export interface CaptionImageProps {
  component: React.SFC<any> | React.ComponentClass<any>;
  orientation?: 'bottom' | 'right';
  [index: string]: any;
}

const CaptionImageBase = styled.div`
  display: flex;
  align-items: center;
  ${(props: { orientation: 'bottom' | 'right' }) =>
    props.orientation === 'right'
      ? css``
      : css`
          flex-direction: column;
        `};
` as any;

const CaptionText = styled.div`
  padding: 10px;
` as any;

const CaptionImage: React.SFC<CaptionImageProps> = ({
  component: Component,
  style,
  orientation,
  className,
  children,
  ...rest
}) => (
  <CaptionImageBase style={style} className={className} orientation={orientation ? orientation : 'bottom'}>
    <Component {...rest} />
    {children && <CaptionText>{children}</CaptionText>}
  </CaptionImageBase>
);

export default CaptionImage;
