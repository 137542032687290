import { bindActionCreators } from "redux";

import { getUserProfile } from "../../../redux/actions/index";
// const UNCOMPLETED_COLOR = "gray";
// const COMPLETED_COLOR = "black";

/**
 * maps functions to props that we use to get metrics
 * @param dispatch
 */
const mapDispatchToProps = dispatch => ({
  // add function to grab the data
  getUserProfile: bindActionCreators(getUserProfile, dispatch)
  // getMetrics:bindActionCreators(getMetrics,dispatch),
});

/**
 * Test Data that will show if admin doesn't enter in
 * profileProgressBarContent
 */
const TestAdminData = [
  { activity: "avatar", activityText: "Personalize Profile" },
  { activity: "video", activityText: "View Video" },
  { activity: "goals", activityText: "Set your first goal" }
];
/**
 * Test Data in case there is no data about the user
 */
const testData = [
  {
    type: "userProfileModel",
    id: "avatar",
    attributes: {
      serviceKey: "avatar",
      data: {
        FileName: "75498-20181107-092457-71.png"
      }
    }
  },
  {
    type: "userProfileModel",
    id: "video",
    attributes: {
      serviceKey: "video",
      data: "HI"
    }
  },
  {
    type: "userProfileModel",
    id: "goals",
    attributes: {
      serviceKey: "goals",
      data: null
    }
  }
];
/**
 * This creates the input from cms where we get the color and status
 * @param getContent
 */
const mapContentToProps = getContent => ({
  profileProgressBarContent: getContent("profileProgressBarContent", {
    type: "array",
    schema: [
      { model: "activity", label: "The activity to check for" },
      { model: "activityText", label: "Text to display for that activity" }
    ]
  }),
  profileGoalsBarSize: getContent("GoalsBarSize", {
    type: "String",
    label: "How much space/Width your bar will take on the page"
  }),
  profileAwardText: getContent("profileAwardText", {
    type: "String",
    label: "The label for the end of the bar"
  }),
  CompletedColor: getContent("CompletedColor", {
    type: "String",
    label: "Color of circle when completed"
  }),
  UnCompletedColor: getContent("UnCompletedColor", {
    type: "String",
    label: "Color of circle when not completed"
  }),
  CircleTextColor: getContent("CircleTextColor", {
    type: "String",
    label: "Circle Text Color"
  }),
  flipped: getContent("flipped", {
    type: "String",
    label: "flip this bar expects true or false"
  })
});
/**
 * Use profile Data to create
 * an array that contains objects
 * {id:element.id,hasData:hasData}
 * @param profileData
 */
const createActivityList = (profileData: any) => {
  const ActivityArrays = [];
  profileData.forEach(element => {
    var hasData = false;
    if (element.attributes.data) {
      hasData = true;
    }
    ActivityArrays.push({ id: element.id, hasData: hasData });
  });
  return ActivityArrays;
};

/**
 * Create an object if the
 * activity is complete
 * @param colors
 */
const createCompletedActivityObject = (colors: any) => {
  var color = colors.CompletedColor;

  // alert(colors.CircleTextColor)
  var circleTextColor = "white";
  // if (colors.CompletedColor) {
  //   color = colors.CompletedColor;
  // }
  if (colors.CircleTextColor) {
    circleTextColor = colors.CircleTextColor;
  }
  return {
    type: "timeline",
    dataLabels: {
      connectorWidth: 0,
      format: "<span style='font-size:13px'>{point.label}</span>",
      fontFamily:null,
      alternate: false,
      align: "center",
      crop: true,
      distance: 0,
      inside: true,
      borderColor: "transparent",
      backgroundColor: "transparent",
      borderRadius: 24,
      width: 200,
      height: 200,
      color: circleTextColor
    },
    marker: {
      symbol: "circle",
      fillColor: color,
      width: 45,
      height: 45
    },
    data: []
  };
};

/**
 * create an uncompleted series object
 * if the activity is incomplete
 * @param colors
 */
const createUncompletedActivityObject = (colors: any) => {
  var color = colors.UnCompletedColor;
  var circleTextColor = "white";
  // if (colors.UnCompletedColor) {
  //   color = colors.UnCompletedColor;
  // }
  if (colors.CircleTextColor) {
    circleTextColor = colors.CircleTextColor;
  }
  return {
    type: "timeline",
    dataLabels: {
      useHtml:true,
      connectorWidth: 0,
      connectorColor:color,
      fontFamily:null,
      format: "<span style='font-size:13px;'>{point.label}</span>",
      alternate: false,
      align: "center",
      crop: true,
      distance: 0,
      inside: true,
      borderColor: "transparent",
      backgroundColor: "transparent",
      // borderWidth:14,
      // borderRadius:"70%",
      // borderColor:color,
      // backgroundColor: color,
      color: circleTextColor
    },
    marker: {
      symbol: "circle",
      fillColor: color,
      width: 45,
      height: 45,
      zIndex: 55,
      z:55,
    },
    data: []
  };
};
/**
 * Put all the series together using completed
 * and uncompleted Objects
 * @param Categories
 * @param props
 */
const createSeries = (Categories: any, props: any) => {
  // var completedColor = props.CompletedColor;
 // var uncompletedColor = props.UnCompletedColor;

  var seriestoreturn = [];
  Categories.map((value: any, index: any) => {
    var completedColor = props.CompletedColor;
    var uncompletedColor = props.UnCompletedColor;
    var completedActivityObject = createCompletedActivityObject({
      CompletedColor: props.CompletedColor,
      CircleTextColor: props.CircleTextColor
    });
    var uncompletedActvityObject = createUncompletedActivityObject({
      UnCompletedColor: props.UnCompletedColor,
      CircleTextColor: props.CircleTextColor
    });
    if (value.color === completedColor) {
      
      completedActivityObject.data.push({
        name: value.labelText,
        label: index + 1
      });
      seriestoreturn.push(completedActivityObject);
    }
    if (value.color === uncompletedColor) {
    uncompletedActvityObject.data.push({
      name: value.labelText,
      label: index + 1
    });

    seriestoreturn.push(uncompletedActvityObject);
  }
  });
  // seriestoreturn.push(fillme);
 // seriestoreturn.push(completedActivityObject);
 // seriestoreturn.push(completedActivityObject.data.push(uncompletedActvityObject.data));
 // seriestoreturn.push(uncompletedActvityObject);
 // seriestoreturn.push(completedActivityObject);
  return seriestoreturn;
};
/**
 *  Create the last Label
 * @param counterofhasData
 * @param ActivityList
 * @param lastCategoryLabelText
 * @param AdminData
 */
const createFinalLabel = (
  counterofhasData: any,
  ActivityList: Array<object>,
  lastCategoryLabelText: string,
  props:any,
) => {
  var uncompletedColor = props.UnCompletedColor;
  var completedColor = props.CompletedColor;
  // if (props.UnCompletedColor) {
  //   uncompletedColor = props.UnCompletedColor;
  // }
  // if (props.CompletedColor) {
  //   uncompletedColor = props.CompletedColor;
  // }

  var finalLabelObject = {
    labelText: lastCategoryLabelText,
    color: uncompletedColor
  };
  if (counterofhasData === ActivityList.length) {
    finalLabelObject = {
      labelText: lastCategoryLabelText,
      color: completedColor
    };
  }
  return finalLabelObject;
};
/**
 * Create an array of categories
 * based on the data and admin input
 * @param data
 * @param AdminData
 * @param lastCategoryLabelText
 */
const createCategories = (
  data: any,
  AdminData: any,
  lastCategoryLabelText: any,
  props:any
) => {
  const ActivityList = createActivityList(data);
  var finalLabelObject = null;
  var counterofhasData = 0;
  var uncompletedColor = props.UnCompletedColor;
  var completedColor = props.CompletedColor;
  const Categories = [];
  AdminData.forEach(adminDataElement => {
    ActivityList.forEach(activityElement => {
      if (adminDataElement.activity === activityElement.id) {
        var pushme = null
        if (!activityElement.hasData) {
        pushme = { labelText: adminDataElement.activityText,
          color: uncompletedColor
        }
        };
        if (activityElement.hasData) {
          pushme = {
            labelText: adminDataElement.activityText,
            color: completedColor
          };
          counterofhasData += 1;
        }
        Categories.push(pushme);
      }
    });
  });
  finalLabelObject = createFinalLabel(
    counterofhasData,
    ActivityList,
    lastCategoryLabelText,
    props
  );
  // console.log(Categories);

  // create series using this Categories object
  Categories.push(finalLabelObject);

  return Categories;
};

/**
 * Creates progress bar options
 * puts everything together
 * @param props
 */
const createProgressBar = (props: any, profileData: any) => {
  // Create options
  var ActivityList = createCategories(
    testData,
    TestAdminData,
    "Enjoy an extra 10 points",
    props
  );
  if (
    props.profileAwardText &&
    profileData &&
    props.profileProgressBarContent
  ) {
    ActivityList = createCategories(
      profileData,
      props.profileProgressBarContent,
      props.profileAwardText,
      props
    );
  }
  console.log("activitylist"+JSON.stringify(ActivityList))
  const options: Highcharts.Options = {
    chart: {
      type: "timeline",
      width:null,
      height:null,
    },
    rangeSelector : {
      enabled: false
  },
  navigator: {
      enabled: false
  },
    xAxis: {
      categories: ActivityList.map(value => {
        if (value != null) {
          return value.labelText;
        }
      }),
      type: "category",
      visible: true,
      lineColor: "transparent",
      gridLineWidth: 0,
      gridLineColor: "transparent",
      labels: {
        formatter: function() {
          var returnme = null;
          ActivityList.forEach((element: any) => {
            if (this.value === element.labelText) {
              if(element.color === props.CompletedColor) {
                returnme = `<span style="color:${element.color};font-Family:'HCoGothamSSm';font-weight:bold;line-height: 1.14;">${this.value}</span>`;
              }
              if(element.color === props.UnCompletedColor) {
                returnme = `<span style="color:${element.color};font-Family:'HCoGothamSSm';">${this.value}</span>`;
              }
              
            }
          });
          return returnme;
        },
        y: -130,
        useHTML:true,
        align: 'center',
        style: {
          fontSize: "14px",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "normal",
          letterSpacing: "1px",
          textAlign: "center"
        }
      }
    },
    credits: {
      enabled: false
    },
    yAxis: {
      gridLineWidth: 1,
      gridLineColor: "transparent",
      title: null,
      labels: {
        enabled: false
      }
    },
    legend: {
      enabled: false
    },
    title: {
      text: null
    },
    tooltip: {
      style: {
        width: 300
      }
    },
    series: createSeries(ActivityList, props)
  };
  return options;
};

export { mapDispatchToProps, mapContentToProps, createProgressBar, testData };
