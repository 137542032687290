import * as events from '../../events';
import { getLastUser } from '../../selectors/redeemSelectors';
import { userApiActions } from './userApiActions';

export const setUserInfo = payload => ({ type: events.SET_USER_INFO, payload });

export const getUser = () => async (dispatch, getState) => {
  await dispatch(userApiActions.getUser({ jsonApi: true }));
  dispatch(setUserInfo(getLastUser(getState())));
};
