import { promiseDispatcher } from 'xcel-react-core';
import { navigationApi } from '../../api';
import * as events from './events';

const navigationMenuToggle = () => ({
  type: events.NAVIGATION_MENU_TOGGLE
});

const navigationMenuClose = () => ({
  type: events.NAVIGATION_MENU_CLOSE
});

const navigationMenuUpdate = (navigationMenu) => ({
  type: events.NAVIGATION_MENU_UPDATE,
  navigationMenu
});

const getNavigationMenuSuccess = (response, request) => ({
  type: events.GET_NAVIGATION_MENU_SUCCESS,
  payload: response,
  request
});

const getNavigationMenu = promiseDispatcher(navigationApi.getMenuByAppKey, {
  request: events.GET_NAVIGATION_MENU_REQUEST,
  success: getNavigationMenuSuccess,
  failure: events.GET_NAVIGATION_MENU_FAILURE
});
export { getNavigationMenu, navigationMenuToggle, navigationMenuUpdate, navigationMenuClose };
