import * as React from 'react';

const warningStyle = {
  color: 'red',
  backgroundColor: 'black'
};

const DataMissing = (props) => <span style={warningStyle}>DATA MISSING</span>;

export default DataMissing;
