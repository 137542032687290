import * as React from 'react';
import styled from 'styled-components';

const MasonryContainer = styled.div`
  .masonry {
    align-content: stretch;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
    width: 100%;
  }
` as any;

const MasonryColumn = styled.div`
  align-content: stretch;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;

  &:not(:last-child) {
    padding-right: 25px;
  }
` as any;

const MasonryCard = styled.div`` as any;

class Masonry extends React.Component<any, any> {
  masonry: any;

  constructor(props: any) {
    super(props);
    this.state = { columns: 1, showColumns: null };
    this.masonry = React.createRef();
  }

  componentDidMount() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  getColumns = (w) => {
    const breakpoints = [480, 768, 992, 1336];
    const columns =
      breakpoints.reduceRight((p, c, i) => {
        return c < w ? p : i;
      }, breakpoints.length) + 1;

    return columns > 4 ? 4 : columns;
  };

  onResize = () => {
    const containerWidth = (this.masonry as any).current.offsetWidth;
    const columns = this.getColumns(containerWidth);

    if (columns !== this.state.columns) {
      this.setState({ columns });
    }
  };

  mapChildren = () => {
    if ((this.masonry as any).current) {
      let col = [];
      const numC = this.state.columns;
      let count = 0;
      let colIndex = 0;

      for (let i = 0; i < numC; i++) {
        col.push([]);
      }
      if (!this.state.showColumns) {
        this.setState({ showColumns: true });
      }

      return (this.props.children as any).reduce((p, c, i) => {
        const columnSize = Math.floor(React.Children.count(this.props.children) / numC);

        if (count >= columnSize + 1 && colIndex + 1 !== numC) {
          colIndex++;
          count = 0;
        }
        p[colIndex].push(c);
        count++;
        return p;
      }, col);
    } else {
      this.setState({ showColumns: false });
      return [];
    }
  };

  render() {
    const { ...rest } = this.props;
    const mapChildren = this.mapChildren();

    return (
      <MasonryContainer>
        <div className="masonry" ref={this.masonry}>
          {this.state.showColumns &&
            mapChildren.map((col, ci) => {
              return (
                <MasonryColumn key={ci}>
                  {col.map((child, i) => {
                    return (
                      <MasonryCard key={i} {...rest}>
                        {child}
                      </MasonryCard>
                    );
                  })}
                </MasonryColumn>
              );
            })}
        </div>
      </MasonryContainer>
    );
  }
}

export default Masonry;
