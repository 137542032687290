import * as React from 'react';
import { compose } from 'redux';
import { HeaderThree } from 'rsv8-components';
import { assetService } from 'xcel-asset-service';
import { withResource } from 'xcel-react-core';

const ProductFamilyComponent: React.FC<Props> = ({ getResource, familyKey }) => (
  <div className="product-family">
    <HeaderThree className="product-family-name" themeVariation="my-dashboard">
      {getResource(`alcon.dashboard.productBreakdown.${familyKey}Label`, '')}
    </HeaderThree>

    <img
      className="product-family-image"
      src={assetService.getImage(getResource(`alcon.dashboard.productBreakdown.${familyKey}Image`, ''))}
    />

    <div className="product-family-note">{getResource(`alcon.dashboard.productBreakdown.${familyKey}Note`, '')}</div>
  </div>
);

const mapResourceToProps = (getResource) => ({
  getResource,
});

type OwnProps = {
  familyKey: string;
};

interface ResourceProps {
  getResource: Function;
}
type Props = ResourceProps & OwnProps;

export default compose(withResource(mapResourceToProps))(ProductFamilyComponent);
