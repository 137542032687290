import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon, Loader } from 'rsv8-components';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';
import { deleteFavorite } from '../../redux/actions/product/deleteFavorite';
import { getFavoriteProducts } from '../../redux/actions/product/getFavorites';
import { postFavorite } from '../../redux/actions/product/postFavorite';
import { getPostFavoriteRequest } from '../../redux/selectors';

const FavoritesUncheckedIcon = styled(Icon as any)`
  bottom: 15px;
  color: #7c8180;
  cursor: pointer;
  position: absolute;
  right: 20px;
` as any;

const FavoritesCheckedIcon = styled(Icon as any)`
  bottom: 15px;
  color: #4192a5;
  cursor: pointer;
  position: absolute;
  right: 20px;
` as any;

const LoaderContainer = styled.div`
  bottom: 18px;
  cursor: pointer;
  position: absolute;
  right: 20px;
` as any;

interface FavoritesButtonProps {
  actions: {
    deleteFavorite: any;
    getFavoriteProducts: any;
    postFavorite: any;
  };
  favorites: any;
  postFavoriteRequest: any;
  product: any;
  activeIcon: string;
  inactiveIcon: string;
}

class FavoritesButton extends React.Component<FavoritesButtonProps, any> {
  state = {
    isDisabled: false
  };

  addFavorite = (product) => {
    if (!this.state.isDisabled) {
      this.setState({ isDisabled: true });
      this.props.actions.postFavorite(product).then(() => {
        this.setState({ isDisabled: false });
      });
    }
  };

  removeFavorite = (product) => {
    if (!this.state.isDisabled) {
      this.setState({ isDisabled: true });
      this.props.actions.deleteFavorite(product).then(() => {
        this.setState({ isDisabled: false });
      });
    }
  };

  render() {
    const { favorites, product, activeIcon, inactiveIcon } = this.props;
    if (this.state.isDisabled) {
      return (
        <LoaderContainer>
          <Loader width={20} strokeWidth={2} />
        </LoaderContainer>
      );
    } else if (favorites.includes(product.id)) {
      return (
        <FavoritesCheckedIcon
          icon={activeIcon}
          onClick={() => {
            this.removeFavorite(product);
          }}
          size="2x"
          themeVariation="catalog-favorites-icon"
        />
      );
    } else {
      return (
        <FavoritesUncheckedIcon
          icon={inactiveIcon}
          onClick={() => {
            this.addFavorite(product);
          }}
          size="2x"
          themeVariation="catalog-favorites-icon"
        />
      );
    }
  }
}

const mapResourceToProps = (getResource) => ({
  activeIcon: getResource('catalog.favoriteIcon.active', 'bookmark'),
  inactiveIcon: getResource('catalog.favoriteIcon.inactive', 'bookmark-o')
});

const mapStateToProps = (state) => ({
  postFavoriteRequest: getPostFavoriteRequest(state)
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      deleteFavorite,
      getFavoriteProducts,
      postFavorite
    },
    dispatch
  )
});

export default withResource(mapResourceToProps)(connect(mapStateToProps, mapDispatchToProps)(FavoritesButton));
