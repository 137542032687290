import * as React from 'react';
import { Col } from 'react-bootstrap';
import { register, withContent, withTheme } from 'xcel-react-core';

const Column = ({ children, content, ...props }) => {
  const { md = 12, mdOffset = 0, ...rest } = { ...content, ...props };
  return (
    <Col md={md} mdOffset={mdOffset} {...rest}>
      {children}
    </Col>
  );
};
const mapContentToProps = (getContent) => ({
  xs: getContent('xs', { type: 'none', label: 'col-xs' }),
  sm: getContent('sm', { type: 'none', label: 'col-sm' }),
  md: getContent('md', { type: 'none', label: 'col-md' }),
  lg: getContent('lg', { type: 'none', label: 'col-lg' }),
  xsOffset: getContent('xsOffset', { type: 'none', label: 'offset-xs' }),
  smOffset: getContent('smOffset', { type: 'none', label: 'offset-sm' }),
  mdOffset: getContent('mdOffset', { type: 'none', label: 'offset-md' }),
  lgOffset: getContent('lgOffset', { type: 'none', label: 'offset-lg' })
});
export default register('rsv8-components/layout/GridColumn')(withContent(mapContentToProps), withTheme())(Column);
