import { toast } from 'rsv8-components';
import { promiseDispatcher } from 'xcel-react-core';
import { createRequestActions, destroyRequest } from 'xcel-redux-orm';
import { nominationApi } from '../../../../api/index';
import * as events from '../../../events';
import { individualNominationSelectors } from '../../../selectors/index';

const saveActions = createRequestActions('saveCurrentNomination');

const saveNominationCall = () => (dispatch, getState) => {
  const state = getState();
  // dispatch(destroyRequest('getAllIndividualNominationDrafts'));
  // we have to destroy all because the createNomination doesn't return the nomination that changed
  // so we have no way to persist this in state.
  // TODO: Maybe we can manually update this? or make the data reducer more intelligent?
  // dispatch(destroy('draft', null));
  return nominationApi.createNominations({
    nominationSubmissionData: { ...individualNominationSelectors.getSaveNominationRequest(state), submit: false }
  } as any);
};

const saveNominationSuccessBase = (response) => ({
  type: events.SAVE_NOMINATION_SUCCESS,
  payload: { nominationDetailId: response.data.id }
});

const handleFailure = (e) => (dispatch, getState) => {
  if (e.response) {
    toast({
      template: 'Default',
      themeVariation: 'error-text',
      message: e.response.data.errors[0].title
    });
  }

  dispatch(saveActions.failure(e, undefined));
};

const saveNominationSuccess = (response) => (dispatch, getState) => {
  dispatch(destroyRequest('getNominationsDraft'));
  dispatch(saveNominationSuccessBase(response));
  dispatch(saveActions.success(response, undefined));
};

const saveNomination = promiseDispatcher(saveNominationCall, {
  request: saveActions.request,
  success: saveNominationSuccess,
  failure: handleFailure
});

export { saveNomination };
