import * as React from 'react';
import { Panel } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import { Header } from '../Header/index';
import NomineeItem from './NomineeItem';

export interface NomineeColumnProps {
  nominees: Array<string>;
  loadingNominees: Array<string>;
  handleIconClick: (id: string) => void;
}

interface Props extends NomineeColumnProps {
  resource: {
    headerText: string;
  };
}

class NomineeColumn extends React.Component<Props, {}> {
  handleClick = (id) => () => this.props.handleIconClick(id);

  renderItem = (id) => {
    return (
      <NomineeItem
        key={id}
        id={id}
        onClick={this.handleClick(id)}
        loading={this.props.loadingNominees.indexOf(id) !== -1}
      />
    );
  };

  render() {
    return (
      <React.Fragment>
        <Header text={this.props.resource.headerText} />
        <Panel>{this.props.nominees.map(this.renderItem)}</Panel>
      </React.Fragment>
    );
  }
}

const mapResourceToProps = (getResource) => ({
  resource: {
    headerText: getResource('teamNomination.headerText', 'Team Members')
  }
});

export default withResource(mapResourceToProps)(NomineeColumn) as React.ComponentType<NomineeColumnProps>;
