import * as React from 'react';
import { register, withResource, withTheme } from 'xcel-react-core';
import { SocialShareableButtonProps } from './BaseSocialShareablesButton';
import { HoverNotSupported } from './HoverNotSupported';
import { HoverSupported } from './HoverSupported';

const isTouchEventsSupported = () => 'ontouchstart' in window;

function SocialShareableButton(props: SocialShareableButtonProps) {
  return isTouchEventsSupported() ? <HoverNotSupported {...props} /> : <HoverSupported {...props} />;
}

const mapResourceToProps = (getResource) => ({
  resource: {
    shareLabelText: getResource('social.action.share.buttonLabelText', 'share'),
    saveLabelText: getResource('social.action.download.buttonLabelText', 'save')
  }
});

export default register('rsv8-social/SocialShareableButton')(withResource(mapResourceToProps), withTheme())(
  SocialShareableButton
);
