import { createTypeSelector, transformTypeSelector } from 'xcel-redux-orm';
import { Resource } from 'xcel-redux-orm';
import { TypeSelector } from 'xcel-redux-orm';
import { RouteList, RoutesResponseModel } from '../types/route';
import { RouteItem } from '../types/route';
import { contentSelector } from './index';

export const transformRoute = (route: RoutesResponseModel): RouteList => {
  if (!route) return null;
  let items = [];
  let { routeNodeName, childRoutes, contents, id, parentId } = route;
  if (Array.isArray(childRoutes)) {
    items = [
      ...childRoutes.map((childRoute) => ({
        ...childRoute,
        label: childRoute.routeNodeName,
        parentId: id.toString(),
        id: childRoute.id.toString(),
        type: 'routeModel'
      }))
    ];
  }
  if (Array.isArray(contents)) {
    items = [
      ...items,
      ...contents.map((content) => ({
        ...content,
        label: content.key,
        parentId: id.toString(),
        id: content.id.toString(),
        type: 'contentModel',
        isPublished: content.isPublished
      }))
    ];
  }

  return {
    id: id.toString(),
    parentId: parentId && parentId.toString(),
    label: routeNodeName,
    type: 'routeModel',
    items
  };
};
export const baseRouteSelector = transformTypeSelector(
  createTypeSelector<RoutesResponseModel>('routeModel'),
  transformRoute
);
export const childRouteSelector = transformTypeSelector(
  createTypeSelector<RoutesResponseModel>('childRouteModel'),
  transformRoute
);

const routeSelector = {
  ...baseRouteSelector,
  getActive: (state, contentTypeId) => {
    const { routeId } = contentSelector.getActiveProperty(state, contentTypeId);
    if (!routeId) return null;
    return routeSelector.selectMany(state, routeId);
  },
  getActiveBreadcrumbs: (state, contentTypeId) => {
    let routes: RouteList[] = routeSelector.getActive(state, contentTypeId);
    let route = routes && routes[0];
    if (!route) {
      const root = routeSelector.searchOne(
        state,
        (rootRoute) => rootRoute.contentType.id === (contentTypeId || 'Page')
      );
      if (!root) {
        return [];
      } else {
        route = root;
      }
    }
    const crumbs = [{ ...route }];
    while (route && route.parentId) {
      route = routeSelector.selectOne(state, route.parentId);
      crumbs.unshift({ ...route });
    }
    return crumbs;
  }
};

export default routeSelector;
export { Resource, TypeSelector, RouteItem };
