import { push } from 'connected-react-router';
import { getConfigurationProperty } from 'xcel-config';
import { promiseDispatcher } from 'xcel-react-core';
import { destroy, destroyRequest } from 'xcel-redux-orm';
import { userApi } from '../../api';
import { logger } from '../../config';
import {
  createRedirectUrl,
  inject,
  isGigyaEnabled,
  login,
  logout
  } from '../../config/gigya';
import OAuthService from '../../services/OAuthService';
import userActions from './actions/user';
import { authTokenBasicCreate, authTokenCreate, oAuthTokenCreate } from './auth.actions';
import * as events from './events';
import { getUser } from './selectors';

const userLoginStandard = (credentials, redirect, location) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    dispatch(authTokenCreate(credentials))
      .then((auth) => {
        resolve(auth);
        if (auth) {
          const referrer = location && location.state && location.state.from && location.state.from.pathname;
          dispatch(userActions.get())
            .then(async (user) => {
              await checkUserMustChangePassword(dispatch, getState);
              if (user.hasConsented || user.tokenType !== 'AdminUser' || user.clientId !== 0) {
                dispatch(push(referrer || redirect));
              }
            })
            .catch(async (e) => {
              await checkUserMustChangePassword(dispatch, getState);
              // we failed to retrieve the user, let's keep moving.
              dispatch(push(referrer || redirect));
            });
        } else {
          reject(auth);
        }
      })
      .catch(reject);
  });
};

const userLoginOAuth = (redirect) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    dispatch(oAuthTokenCreate())
      .then(async (response) => {
        const userData = OAuthService.getUserDataFromResponse(response);
        dispatch(userActions.setData(userData));
        resolve(userData);
        dispatch(userActions.get())
          .then(async (user) => {
            await checkUserMustChangePassword(dispatch, getState);
            if (user.hasConsented || user.tokenType !== 'AdminUser' || user.clientId !== 0) {
              dispatch(push(redirect));
            }
          })
          .catch((err) => {
            logger.log('userLoginOAuth', err);
            dispatch(push(redirect));
          });
      })
      .catch(reject);
  });
};

const checkUserMustChangePassword = async (dispatch, getState) => {
  const user = getUser(getState());
  const pathname = location.pathname;
  if (user.resetPassword && (pathname === '/login' || pathname === '/')) {
    await dispatch(userMustChangePassword());
  }
};
const userMustChangePassword = () => ({ type: events.USER_MUST_CHANGE_PASSWORD });

const userLoginGigya = (credentials) => {
  // need to see if we support gigya.
  if (isGigyaEnabled()) {
    return login(credentials);
  }
  return Promise.reject();
};

const userLogin = promiseDispatcher(
  (credentials, redirect, location) => async (dispatch, getState) => {
    // need to see if we support gigya.
    try {
      const clientId = getUser(getState()).clientId;
      let gigyaConfig = getConfigurationProperty(['clients', clientId, 'gigya']) as any;
      if (gigyaConfig !== undefined) {
        await inject(gigyaConfig);
      }

      const loginInfo = (await userLoginGigya(credentials)) as any;

      redirect = createRedirectUrl(gigyaConfig, loginInfo.UID);
      setTimeout(() => {
        console.log('gigya redirect', redirect);
        window.location.href = redirect;
      }, 250);
      return Promise.resolve();
    } catch (e) {
      console.log('gigya login failed, or gigya not supported ');
      return dispatch(userLoginStandard(credentials, redirect, location));
    }
  },
  {
    request: events.USER_LOGIN_REQUEST,
    success: events.USER_LOGIN_SUCCESS,
    failure: events.USER_LOGIN_FAILURE
  }
);

const userMergeData = (userData) => {
  return {
    type: events.USER_MERGE_DATA_FROM_API,
    payload: { userData }
  };
};

const userForgotPassword = promiseDispatcher(
  (params, { validatedRedirect, invalidatedRedirect, noEmailRedirect }) => (dispatch, getState) => {
    return new Promise<void>((resolve, reject) => {
      userApi
        .createPasswordForgot({ body: params.userName })
        .then(() => {
          resolve();
          dispatch(push(validatedRedirect));
        })
        .catch((responseErr) => {
          reject(responseErr);
          if (responseErr['model.EmailAddress'] && responseErr['model.EmailAddress'][0] === 'no_email_address_found') {
            dispatch(push(noEmailRedirect));
          } else {
            dispatch(push(invalidatedRedirect));
          }
        });
    });
  },
  {
    request: events.USER_FORGOT_PASSWORD_REQUEST,
    success: events.USER_FORGOT_PASSWORD_SUCCESS,
    failure: events.USER_FORGOT_PASSWORD_FAILURE
  }
);

const userPasswordChange = promiseDispatcher(userApi.createPassword, {
  request: events.USER_PASSWORD_CHANGE_REQUEST,
  success: events.USER_PASSWORD_CHANGE_SUCCESS,
  failure: events.USER_PASSWORD_CHANGE_FAILURE
});

const userForgotUsername = promiseDispatcher(userApi.createUsernameForgot, {
  request: events.USER_FORGOT_USERNAME_REQUEST,
  success: events.USER_FORGOT_USERNAME_SUCCESS,
  failure: events.USER_FORGOT_USERNAME_FAILURE
});

const userPasswordReset = promiseDispatcher(
  (params, { validatedPath, invalidatedPath }) => (dispatch, getState) => {
    return new Promise<void>((resolve, reject) => {
      userApi
        .createPasswordReset({ body: params })
        .then(() => {
          resolve();
          dispatch(push(validatedPath));
        })
        .catch((responseErr) => {
          reject(responseErr);
          dispatch(push(invalidatedPath));
        });
    });
  },
  {
    request: events.USER_PASSWORD_RESET_REQUEST,
    success: events.USER_PASSWORD_RESET_SUCCESS,
    failure: events.USER_PASSWORD_RESET_FAILURE
  }
);

const simpleLogout = async (dispatch) => {
  await dispatch(destroy(null, null));
  dispatch({ type: events.USER_LOGOUT });
  dispatch(destroyRequest(null));
};

const userLogout = () => async (dispatch, getState) => {
  const clientId = getUser(getState()).clientId;
  await simpleLogout(dispatch);
  dispatch(authTokenBasicCreate());
  if (isGigyaEnabled()) {
    let gigyaConfig = getConfigurationProperty(['clients', clientId, 'gigya']) as any;
    if (gigyaConfig !== undefined) {
      await inject(gigyaConfig);
    }
    logout();
  }
};

const userLogoutOAuth = () => async (dispatch, getState) => {
  await dispatch(destroy('userProfileItem', null));
  dispatch({ type: events.USER_LOGOUT_OAUTH_REQUEST });
  dispatch(destroyRequest(null));
};

const userGetClientName = (dispatch) => {
  return dispatch(userApi.getClientClient)
  .then((res) => {
    return res?.data[0]?.attributes?.name;
  });
};

export {
  userLogin,
  userLoginStandard,
  userLoginOAuth,
  userLogoutOAuth,
  userLogout,
  userPasswordChange,
  userForgotPassword,
  userForgotUsername,
  userPasswordReset,
  userMergeData,
  userGetClientName
};
