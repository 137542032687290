import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getRequest } from 'xcel-redux-orm';
import { nomineeScroll } from '../../redux/actions/index';
import { nomineeSearchSelectors as selectors } from '../../redux/selectors/index';
import { nomineeSearchSelectors } from '../../redux/selectors/index';
import LoadMore from './LoadMore';

const mapState = (state) => {
  const current = nomineeSearchSelectors.getVisibleNominees(state).length;
  const total = nomineeSearchSelectors.getTotalNomineeCount(state);

  const isLoading = (getRequest(state, 'nomineeScroll') || { loading: false }).loading;
  const isComplete = selectors.getVisibleNominees(state).length === selectors.getTotalNomineeCount(state);
  const noMorePages = total <= 1 || current === total;
  return {
    disabled: isLoading || isComplete || noMorePages
  };
};

const mapDispatch = (dispatch) => ({
  onClick: bindActionCreators(nomineeScroll, dispatch)
});

export default connect(
  mapState,
  mapDispatch
)(LoadMore as React.StatelessComponent);
