import * as events from './events';
export default function navigationMenuAdminReducer(state, action) {
  state = state || {
    isOpen: false,
    items: [],
    lookup: {},
    application: [],
    groupMenuItems: [],
    loadedMenuTypesDataRules: false,
    showAlert: false,
    lastForm: ''
  };
  switch (action.type) {
    case events.NAVIGATION_MENU_ADMIN_SUCCESS: {
      return {
        ...state,
        items: [...action.response],
        readOnlyItems: [...action.response.slice().map((item) => ({ ...item }))]
      };
    }

    case events.NAVIGATION_MENU_ADMIN_UPDATE: {
      return {
        ...state,
        items: [...action.navigationMenu]
      };
    }

    case events.NAVIGATION_MENU_ADMIN_LOOKUP_SUCCESS: {
      // action.response.data <-- Response from API
      // action.response.key <-- the key we looked up
      const { key, data } = action.response;

      const s = { ...state };
      s.lookup[key] = data;
      return s;
    }

    case events.NAVIGATION_MENU_ADMIN_PROVIDER_SUCCESS: {
      return {
        ...state,
        providerTypes: [...action.response]
      };
    }

    case events.NAVIGATION_MENU_ADMIN_APPLICATION_SUCCESS: {
      return {
        ...state,
        application: [...action.response]
      };
    }

    case events.NAVIGATION_MENU_ADMIN_GROUP_MENU_SUCCESS: {
      return {
        ...state,
        groupMenuItems: [...action.response]
      };
    }

    case events.SHOW_BOOTSTRAP_ALERT: {
      return {
        ...state,
        showAlert: action
      };
    }

    case events.SHOW_LAST_FORM: {
      return {
        ...state,
        lastForm: action.lastForm
      };
    }

    default: {
      return state;
    }
  }
}
