import * as React from 'react';
import {
  Button,
  Col,
  Panel,
  Row
  } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReactField } from 'rsv8-forms';
import styled from 'styled-components';
import { mediaQueries, pseudoSelectors } from '../../../config';
import SegementedControl from '../../components/Fields/Segmented';
import themePluginAction from '../../redux/cms.plugin.actions';
import { componentStyleMediaQuerySet, componentStylePseudoSelectorSet, createNewSelector } from '../../redux/component.actions';
import {
  getMediaQuery,
  getPseudoSelector,
  getStyleSelectors,
  getTheme
  } from '../../redux/theme.selectors';
import { ThemeColorSelector } from './../../components/Fields/Color';
import { FontLibraryPicker } from './../../components/Fields/Font';
import StyleField from './../../components/Fields/StyleField/index';
import ComponentCustomSelectorModal from './ComponentCustomSelectorModal';

const ColWithBorder = styled(Col)`
  border-right: 1px dashed #ddd;
`;

const RadiusLabel = styled.div`
  border: 2px solid #666;
  height: 16px;
  width: 16px;
`;

const RadiusLabelTL = styled(RadiusLabel)`
  border-right: none;
  border-bottom: none;
  border-top-left-radius: 3px;
`;

const RadiusLabelTR = styled(RadiusLabel)`
  border-left: none;
  border-bottom: none;
  border-top-right-radius: 3px;
`;

const RadiusLabelBL = styled(RadiusLabel)`
  border-right: none;
  border-top: none;
  border-bottom-left-radius: 3px;
`;

const RadiusLabelBR = styled(RadiusLabel)`
  border-left: none;
  border-top: none;
  border-bottom-right-radius: 3px;
`;

let Input = (type, component = 'bs-input') => ({ onChange, value, ...rest }) => {
  const handleChange = (changed) => {
    if (onChange) {
      onChange(changed);
    }
  };
  return (
    <ReactField type={type} component={component} onChange={handleChange} value={value} checked={value} {...rest} />
  );
};
const StyleEditContainer = styled.div`
  label {
    font-weight: bold;
  }
  .btn {
    text-transform: initial;
  }
`;

interface Props {
  name: string;
  themeComponent: any;
  activeSelectors: any;
  activeMediaQuery: string;
  actions: {
    componentStyleMediaQuerySet: Function;
    componentStylePseudoSelectorSet: Function;
    createNewSelector: Function;
    setProperty: Function;
  };
  selectors: string[];
}
interface State {
  showModal: boolean;
}
class ComponentStyleEdit extends React.Component<Props, State> {
  state = {
    showModal: false
  };
  handleChangePseudoSelectors = (value) => {
    this.props.actions.componentStylePseudoSelectorSet({
      pseudoSelector: value
    });
  };
  handleChangeMediaQuery = (value) => {
    this.props.actions.componentStyleMediaQuerySet({ mediaQuery: value });
  };
  handleShowModal = () => {
    this.setState({ showModal: true });
  };
  handleCreate = (newSelector = '') => {
    if (newSelector !== '') {
      const { selectors: styleSelectors, actions, themeComponent } = this.props;
      const selectors = [...styleSelectors, { label: newSelector, value: newSelector }];
      actions.setProperty({
        theme: {
          [themeComponent.name]: {
            selectors
          }
        }
      });
    }
    this.setState({ showModal: false });
  };
  render() {
    const { name, themeComponent, activeSelectors, activeMediaQuery, selectors: styleSelectors } = this.props;

    const { showModal } = this.state;

    let selectors = [...pseudoSelectors, ...styleSelectors];
    if (themeComponent && themeComponent.selectors) {
      selectors = [...selectors, ...themeComponent.selectors.map((key) => ({ label: key, value: key }))];
    }
    return (
      <StyleEditContainer {...this.props}>
        <Row>
          <Col md={24}>
            <label>Selectors</label>
            {activeSelectors && activeSelectors.length > 0 && (
              <pre>
                <code>{activeSelectors.join(' ')}</code>
              </pre>
            )}
            <ReactField
              component={SegementedControl}
              onChange={this.handleChangePseudoSelectors}
              multiple={true}
              value={activeSelectors}
              options={selectors}
            />
            <Button onClick={this.handleShowModal}>
              <i className="fa fa-plus" />
            </Button>
            <ComponentCustomSelectorModal show={showModal} handleCreate={this.handleCreate} />
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <label>Media Query</label>
            <ReactField
              component={SegementedControl}
              value={activeMediaQuery}
              options={mediaQueries}
              onChange={this.handleChangeMediaQuery}
            />
          </Col>
        </Row>
        <Row>
          {/* empty row for spacing */}
          <Col md={12} style={{ margin: '10px 0' }} />
        </Row>
        <Row>
          <Col md={24}>
            <Panel id="layout" defaultExpanded={true}>
              <Panel.Heading>
                <Panel.Title toggle={true}>Layout Styles</Panel.Title>
              </Panel.Heading>
              <Panel.Collapse>
                <Panel.Body>
                  <Row>
                    <Col md={6}>
                      <label>Display</label>
                      <StyleField type="text" component={Input('text')} property={'display'} name={name} />
                    </Col>
                    <Col md={6}>
                      <label>Position</label>
                      <StyleField type="text" component={Input('text')} property={'position'} name={name} />
                    </Col>
                    <Col md={6}>
                      <label>Width</label>
                      <StyleField type="text" component={Input('text')} property={'width'} name={name} />
                    </Col>
                    <Col md={6}>
                      <label>Height</label>
                      <StyleField type="text" component={Input('text')} property={'height'} name={name} />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <label>Top</label>
                      <StyleField type="text" component={Input('text')} property={'top'} name={name} />
                    </Col>
                    <Col md={6}>
                      <label>Right</label>
                      <StyleField type="text" component={Input('text')} property={'right'} name={name} />
                    </Col>
                    <Col md={6}>
                      <label>Bottom</label>
                      <StyleField type="text" component={Input('text')} property={'bottom'} name={name} />
                    </Col>
                    <Col md={6}>
                      <label>Left</label>
                      <StyleField type="text" component={Input('text')} property={'left'} name={name} />
                    </Col>
                  </Row>
                </Panel.Body>
              </Panel.Collapse>
            </Panel>
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <Panel id="layout" defaultExpanded={true}>
              <Panel.Heading>
                <Panel.Title toggle={true}>Margin Padding</Panel.Title>
              </Panel.Heading>
              <Panel.Collapse>
                <Panel.Body>
                  <Row>
                    <ColWithBorder md={12}>
                      <Row>
                        <Col md={24}>
                          <label>Padding</label>
                          <StyleField type="text" component={Input('text')} property={'padding'} name={name} />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <label>Top</label>
                          <StyleField type="text" component={Input('text')} property={'padding-top'} name={name} />
                        </Col>
                        <Col md={12}>
                          <label>Right</label>
                          <StyleField type="text" component={Input('text')} property={'padding-right'} name={name} />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <label>Left</label>
                          <StyleField type="text" component={Input('text')} property={'padding-left'} name={name} />
                        </Col>
                        <Col md={12}>
                          <label>Bottom</label>
                          <StyleField type="text" component={Input('text')} property={'padding-bottom'} name={name} />
                        </Col>
                      </Row>
                    </ColWithBorder>

                    <Col md={12}>
                      <Row>
                        <Col md={24}>
                          <label>Margin</label>
                          <StyleField type="text" component={Input('text')} property={'margin'} name={name} />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <label>Top</label>
                          <StyleField type="text" component={Input('text')} property={'margin-top'} name={name} />
                        </Col>
                        <Col md={12}>
                          <label>Right</label>
                          <StyleField type="text" component={Input('text')} property={'margin-right'} name={name} />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <label>Left</label>
                          <StyleField type="text" component={Input('text')} property={'margin-left'} name={name} />
                        </Col>
                        <Col md={12}>
                          <label>Bottom</label>
                          <StyleField type="text" component={Input('text')} property={'margin-bottom'} name={name} />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Panel.Body>
              </Panel.Collapse>
            </Panel>
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <Panel id="background" defaultExpanded={true}>
              <Panel.Heading>
                <Panel.Title toggle={true}>Background Styles</Panel.Title>
              </Panel.Heading>
              <Panel.Collapse>
                <Panel.Body>
                  <Row>
                    <Col md={12}>
                      <label>Color</label>
                      <StyleField
                        type="select"
                        component={ThemeColorSelector}
                        property={'background-color'}
                        name={name}
                      />
                    </Col>
                    <Col md={12}>
                      <label>Size</label>
                      <StyleField type="text" component={Input('text')} property={'background-size'} name={name} />
                    </Col>
                    <Col md={12}>
                      <label>Position</label>
                      <StyleField type="text" component={Input('text')} property={'background-position'} name={name} />
                    </Col>
                    <Col md={12}>
                      <label>Repeat</label>
                      <StyleField type="text" component={Input('text')} property={'background-repeat'} name={name} />
                    </Col>
                    <Col md={24}>
                      <label>Image</label>
                      <br />
                      <StyleField type="text" component={Input('text')} property={'background-image'} name={name} />
                    </Col>
                  </Row>
                </Panel.Body>
              </Panel.Collapse>
            </Panel>
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <Panel id="font">
              <Panel.Heading>
                <Panel.Title toggle={true}>Font Styles</Panel.Title>
              </Panel.Heading>
              <Panel.Collapse>
                <Panel.Body>
                  <Row>
                    <Col md={8}>
                      <label>Family</label>
                      <StyleField type="select" component={FontLibraryPicker} property={'font'} name={name} />
                    </Col>
                    <Col md={8}>
                      <label>Color</label>
                      <StyleField type="select" component={ThemeColorSelector} property={'color'} name={name} />
                    </Col>
                    <Col md={8}>
                      <label>Size</label>
                      <br />
                      <StyleField type="text" component={Input('number')} property={'font-size'} name={name} />
                    </Col>
                    <Col md={8}>
                      <label>Style</label>
                      <br />
                      <StyleField component={Input('text')} property={'font-style'} name={name} />
                    </Col>
                    <Col md={8}>
                      <label>Line Height</label>
                      <br />
                      <StyleField type="text" component={Input('number')} property={'line-height'} name={name} />
                    </Col>
                    <Col md={8}>
                      <label>Decoration</label>
                      <br />
                      <StyleField type="text" component={Input('text')} property={'text-decoration'} name={name} />
                    </Col>
                    <Col md={8}>
                      <label>Transform</label>
                      <br />
                      <StyleField type="text" component={Input('text')} property={'text-transform'} name={name} />
                    </Col>
                    <Col md={8}>
                      <label>Weight</label>
                      <br />
                      <StyleField type="text" component={Input('text')} property={'font-weight'} name={name} />
                    </Col>
                    <Col md={8}>
                      <label>Align</label>
                      <br />
                      <StyleField type="text" component={Input('text')} property={'text-align'} name={name} />
                    </Col>
                    <Col md={8}>
                      <label>Letter Spacing</label>
                      <br />
                      <StyleField type="text" component={Input('text')} property={'letter-spacing'} name={name} />
                    </Col>
                    <Col md={8}>
                      <label>Word Spacing</label>
                      <br />
                      <StyleField type="text" component={Input('text')} property={'word-spacing'} name={name} />
                    </Col>
                    <Col md={8}>
                      <label>Variant</label>
                      <br />
                      <StyleField type="text" component={Input('text')} property={'font-variant'} name={name} />
                    </Col>
                  </Row>
                </Panel.Body>
              </Panel.Collapse>
            </Panel>
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <Panel id="border">
              <Panel.Heading>
                <Panel.Title toggle={true}>Border Styles</Panel.Title>
              </Panel.Heading>
              <Panel.Collapse>
                <Panel.Body>
                  <Row>
                    <Col md={6}>
                      <label>Top</label>
                      <br />
                      <StyleField
                        type="checkbox"
                        component={Input(undefined, 'checkbox')}
                        property={'border-top'}
                        name={name}
                      />
                    </Col>
                    <Col md={6}>
                      <label>Right</label>
                      <br />
                      <StyleField
                        type="checkbox"
                        component={Input(undefined, 'checkbox')}
                        property={'border-right'}
                        name={name}
                      />
                    </Col>
                    <Col md={6}>
                      <label>Bottom</label>
                      <br />
                      <StyleField
                        type="checkbox"
                        component={Input(undefined, 'checkbox')}
                        property={'border-bottom'}
                        name={name}
                      />
                    </Col>
                    <Col md={6}>
                      <label>Left</label>
                      <br />
                      <StyleField
                        type="checkbox"
                        component={Input(undefined, 'checkbox')}
                        property={'border-left'}
                        name={name}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <label>Width</label>
                      <br />
                      <StyleField type="text" component={Input('number')} property={'border-width'} name={name} />
                    </Col>
                    <Col md={6}>
                      <label>Style</label>
                      <br />
                      <StyleField type="text" component={Input('text')} property={'border-style'} name={name} />
                    </Col>
                    <Col md={6}>
                      <label>Color</label>
                      <br />
                      <StyleField type="select" component={ThemeColorSelector} property={'border-color'} name={name} />
                    </Col>
                    <Col md={6}>
                      <label>Outline</label>
                      <br />
                      <StyleField type="text" component={Input('text')} property={'outline'} name={name} />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={8}>
                      <label>Radius</label>
                      <br />
                      <StyleField type="text" component={Input('number')} property={'border-radius'} name={name} />
                    </Col>
                    <Col md={4}>
                      <label>
                        <RadiusLabelTL />
                      </label>
                      <br />
                      <StyleField
                        type="text"
                        component={Input('number')}
                        property={'border-top-left-radius'}
                        name={name}
                      />
                    </Col>
                    <Col md={4}>
                      <label>
                        <RadiusLabelTR />
                      </label>
                      <br />
                      <StyleField
                        type="text"
                        component={Input('number')}
                        property={'border-top-right-radius'}
                        name={name}
                      />
                    </Col>
                    <Col md={4}>
                      <label>
                        <RadiusLabelBL />
                      </label>
                      <br />
                      <StyleField
                        type="text"
                        component={Input('number')}
                        property={'border-bottom-left-radius'}
                        name={name}
                      />
                    </Col>
                    <Col md={4}>
                      <label>
                        <RadiusLabelBR />
                      </label>
                      <br />
                      <StyleField
                        type="text"
                        component={Input('number')}
                        property={'border-bottom-right-radius'}
                        name={name}
                      />
                    </Col>
                  </Row>
                </Panel.Body>
              </Panel.Collapse>
            </Panel>
          </Col>
        </Row>
        <Row />
        <Row>
          <Col md={24}>
            <Panel id="boxshadow">
              <Panel.Heading>
                <Panel.Title toggle={true}>Box Shadow</Panel.Title>
              </Panel.Heading>
              <Panel.Collapse>
                <Panel.Body>
                  <Row>
                    <div className="container-fluid">
                      <Col md={8}>
                        <label>Inset</label>
                        <br />
                        <StyleField component={Input('text')} property={'box-shadow-inset'} name={name} />
                      </Col>
                      <Col md={8}>
                        <label>X</label>
                        <br />
                        <StyleField component={Input('number')} property={'box-shadow-x'} name={name} />
                      </Col>
                      <Col md={8}>
                        <label>Y</label>
                        <br />
                        <StyleField component={Input('number')} property={'box-shadow-y'} name={name} />
                      </Col>
                      <Col md={8}>
                        <label>Color</label>
                        <br />
                        <StyleField component={ThemeColorSelector} property={'box-shadow-color'} name={name} />
                      </Col>
                      <Col md={8}>
                        <label>Blur</label>
                        <br />
                        <StyleField component={Input('number')} property={'box-shadow-blur'} name={name} />
                      </Col>
                      <Col md={8}>
                        <label>Spread</label>
                        <br />
                        <StyleField component={Input('number')} property={'box-shadow-spread'} name={name} />
                      </Col>
                    </div>
                  </Row>
                </Panel.Body>
              </Panel.Collapse>
            </Panel>
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <Panel id="layout" defaultExpanded={true}>
              <Panel.Heading>
                <Panel.Title toggle={true}>Flex Styles</Panel.Title>
              </Panel.Heading>
              <Panel.Collapse>
                <Panel.Body>
                  <Row>
                    <Col md={8}>
                      <label>Flex Direction</label>
                      <StyleField type="text" component={Input('text')} property={'flex-direction'} name={name} />
                    </Col>
                    <Col md={8}>
                      <label>Flex Grow</label>
                      <StyleField type="text" component={Input('text')} property={'flex-grow'} name={name} />
                    </Col>
                    <Col md={8}>
                      <label>Flex Shrink</label>
                      <StyleField type="text" component={Input('text')} property={'flex-shrink'} name={name} />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={8}>
                      <label>Flex Wrap</label>
                      <StyleField type="text" component={Input('text')} property={'flex-wrap'} name={name} />
                    </Col>
                    <Col md={8}>
                      <label>Align Items</label>
                      <StyleField type="text" component={Input('text')} property={'align-items'} name={name} />
                    </Col>
                    <Col md={8}>
                      <label>Align Content</label>
                      <StyleField type="text" component={Input('text')} property={'align-content'} name={name} />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={8}>
                      <label>Justify Content</label>
                      <StyleField type="text" component={Input('text')} property={'justify-content'} name={name} />
                    </Col>
                    <Col md={8}>
                      <label>Align Self</label>
                      <StyleField type="text" component={Input('text')} property={'align-self'} name={name} />
                    </Col>
                    <Col md={8}>
                      <label>Order</label>
                      <StyleField type="text" component={Input('text')} property={'order'} name={name} />
                    </Col>
                  </Row>
                </Panel.Body>
              </Panel.Collapse>
            </Panel>
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <Panel id="layout" defaultExpanded={true}>
              <Panel.Heading>
                <Panel.Title toggle={true}>Misc Styles</Panel.Title>
              </Panel.Heading>
              <Panel.Collapse>
                <Panel.Body>
                  <Row>
                    <Col md={24}>
                      <label>Cursor</label>
                      <StyleField type="text" component={Input('text')} property={'cursor'} name={name} />
                    </Col>
                  </Row>
                </Panel.Body>
              </Panel.Collapse>
            </Panel>
          </Col>
        </Row>
      </StyleEditContainer>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  name: getTheme(state).component.selectedStyle,
  activeSelectors: getPseudoSelector(state),
  activeMediaQuery: getMediaQuery(state),
  selectors: getStyleSelectors(state, ownProps.themeComponent.name)
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setProperty: themePluginAction.setProperty,
      createNewSelector,
      componentStylePseudoSelectorSet,
      componentStyleMediaQuerySet
    },
    dispatch
  )
});
export default connect(mapStateToProps, mapDispatchToProps)(ComponentStyleEdit as any) as any;
