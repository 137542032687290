import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loader } from 'rsv8-components';
import { register, withContent, withTheme } from 'xcel-react-core';
import { LocalShareableFeed } from '../../api/get-shareables-activities';
import { requestShareablesActivities, trackShareablesActivityAction } from '../../redux/actions';
import { getShareablesActivityGroups, isFailedShareablesActivities, isLoadingShareablesActivities } from '../../redux/selectors';
import { SocialShareableFeed } from './SocialShareableFeed';
import { Container } from './style';

export interface SocialShareableLibraryProps {
  requestActivities: Function;
  trackActivityAction: Function;
  groups: LocalShareableFeed[];
  isLoading: boolean;
  isFailed: boolean;
  sliderSettings: any;
  className?: string;
  [key: string]: any;
}

class SocialShareableLibrary extends React.PureComponent<SocialShareableLibraryProps> {
  componentDidMount() {
    this.props.requestActivities();
  }

  render() {
    return <Container {...this.props}>{this.renderContent()}</Container>;
  }

  renderContent() {
    if (this.props.isLoading || this.props.isFailed) {
      return <Loader />;
    }
    return this.props.groups.map((feed) => (
      <SocialShareableFeed
        sliderSettings={this.props.sliderSettings}
        key={feed.feedGroupId}
        feed={feed}
        trackActivityAction={this.props.trackActivityAction}
      />
    ));
  }
}

const mapStateToProps = (state) => ({
  groups: getShareablesActivityGroups(state),
  isLoading: isLoadingShareablesActivities(state),
  isFailed: isFailedShareablesActivities(state)
});

const mapContentToProps = (getContent) => ({
  sliderSettings: getContent('sliderSettings', { type: 'object' })
});

const mapDispatchToProps = (dispatch) => ({
  requestActivities: bindActionCreators(requestShareablesActivities, dispatch),
  trackActivityAction: bindActionCreators(trackShareablesActivityAction, dispatch)
});

export default register('rsv8-social/SocialShareableLibrary')(
  withTheme(),
  withContent(mapContentToProps),
  connect(mapStateToProps, mapDispatchToProps)
)(SocialShareableLibrary);
