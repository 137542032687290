import * as React from 'react';
import TreeNode from './TreeNode';
export interface TreeProps {
  data: any;
  onClick?: any;
  active?: any;
  childrenProperty?: string;
  activeProperty?: string;
  labelProperty?: string;
  leafIcon?: string;
  branchIcon?: string;
  treeNodeRenderer?: React.SFC<any> | React.Component<any>;
  treeNodeChildRenderer?: React.SFC<any> | React.Component<any>;
  treeNodeLabelRenderer?: React.SFC<any> | React.Component<any>;
}
const Tree = (props: TreeProps) => {
  return <TreeNode {...props} isRoot={true} />;
};

export { Tree };
