import { clientassetAdmin } from 'xcel-api-generator';
import { mapJsonApiActions } from 'xcel-redux-orm';
import * as events from '../../exents';

const assetAdminApiActions = mapJsonApiActions(clientassetAdmin);

const setActiveAssetRoute = (activeRoute) => {
  return {
    type: events.SET_PARENT_ASSET_ROUTE_SUCCESS,
    activeRoute
  };
};

const setEditingRoute = (editingRoute) => {
  return {
    type: events.SET_EDITING_ROUTE_SUCCESS,
    editingRoute
  };
};

const setActiveModal = (activeModal) => {
  return {
    type: events.SET_ACTIVE_MODAL_SUCCESS,
    activeModal
  };
};

export { assetAdminApiActions, setActiveAssetRoute, setEditingRoute, setActiveModal };
