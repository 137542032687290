import * as events from './events';

const socialReducer = (state = {}, action) => {
  switch (action.type) {
    case events.SET_EDIT_CULTURE:
      return {
        ...state,
        culture: action.culture
      };
    case events.SET_EDIT_VERSION:
      return {
        ...state,
        version: action.version
      };
    case events.SET_ACTIVITY_ID:
      return {...state, activityId: action.activityId};
    default:
      return state;
  }
};

export default {
  [events.ROOT_SOCIAL]: socialReducer
};
