import { userActions, userStateSelectors } from 'rsv8-auth';
import { destroy, destroyRequest } from 'xcel-redux-orm';
import * as events from '../events';
import cultureSelector from '../selectors/culture';
import clientActions from './client';

const cultureActions = {
  set: (value: string, property: string, allowCultureChange: boolean) => async (dispatch, getState) => {
    const state = getState();
    const cultures = cultureSelector.getAll(state);
    // This is returning undefined on user change, breaking all the things
    const nextCulture = cultures.find((c) => c[property].toString() === value.toString());

    if (nextCulture !== undefined) {
      const nextCultureId = nextCulture.id;
      const activeCulture = cultureSelector.getActive(getState());
      if (allowCultureChange) {
        dispatch(destroy('clientCultureModel', null));
        dispatch(destroyRequest('getClientCulturesByCultureId'));
        await dispatch(cultureActions.getById({ cultureId: nextCultureId }, { jsonApi: true }));
      }

      const user = userStateSelectors.getUser(getState());
      const html = document.body.parentNode as any;
      if (
        user &&
        user.tokenType !== 'AdminUser' &&
        (!html.attributes.dir || html.attributes.dir.value !== nextCulture.direction)
      ) {
        html.setAttribute('dir', nextCulture.direction);
      }

      dispatch({ type: events.CULTURE_SET, payload: nextCulture });

      if (activeCulture && nextCultureId && activeCulture.id !== nextCultureId && user && user.tokenType === 'User') {
        await dispatch(userActions.update({ cultureId: nextCultureId }));
      }
    }
  },
  getById: clientActions.getClientCulturesByCultureId
};
export default cultureActions;
