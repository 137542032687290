import { adjust, clamp, evolve } from 'ramda';
import { RegisterOptions as Options, State, Step } from '../../../types';
import { WizardProps } from '../../components/Wizard/Wizard';
import * as events from '../events';

type WizardPayload = WizardProps & Options;

const defaultStep = (step: Step): Step => ({
  skip: false,
  ...step
});

const initialWizard = (payload: WizardPayload): State => ({
  name: payload.name,
  currentStep: payload.steps[payload.initialStep || 0],
  currentStepNumber: payload.initialStep || 0,
  isLastStep: payload.initialStep === payload.steps.length - 1,
  isFirstStep: (payload.initialStep || 0) === 0,
  steps: payload.steps.map(defaultStep)
});

const safeStep = (step: number, stepLength: number): number => {
  return clamp(0, stepLength - 1, step);
};

const reducer = (state: State = undefined, action: { type: string; payload: any }) => {
  const { type, payload } = action;
  switch (type) {
    case events.MODIFY_ALL_STEPS:
      return { ...state, steps: state.steps.map(evolve(payload.function)) };

    case events.MODIFY_WIZARD_STEP:
      const stepIndex = state.steps.findIndex((s) => s.title === payload.step.title);
      return {
        ...state,
        steps: adjust(evolve(payload.function), stepIndex, state.steps)
      };

    case events.REGISTER_WIZARD:
      return initialWizard(payload);

    case events.SET_WIZARD_STEP:
      const stepLength = state.steps.length;
      const nextStep = safeStep(payload.step, stepLength);
      let firstStep = 0;

      // updating first step number if any previous ones were skipped in order to properly hide the back button
      for (var i = 0; i < state.steps.length; i++) {
        if (state.steps[i].skip) {
          firstStep = firstStep + 1;
        } else {
          break;
        }
      }

      return {
        ...state,
        currentStep: { ...state.steps[nextStep] },
        currentStepNumber: nextStep,
        isLastStep: nextStep === stepLength - 1,
        isFirstStep: nextStep === firstStep
      };

    case events.REMOVE_WIZARD_STEP:
      return {
        ...state,
        steps: state.steps.filter((s) => s.title !== payload.step.title)
      };

    case events.UNREGISTER_WIZARD:
      return undefined;

    default:
      return state;
  }
};

export default reducer;
