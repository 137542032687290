import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { AddBehavior } from 'rsv8-components';
import { register, withContent } from 'xcel-react-core';
import { postponeLandingVisit } from '../../redux/actions/landing';

export interface LandingPostponeBehaviorProps extends RouteComponentProps<any> {
  dispatch: Function;
  actions: { postponeLandingVisit: Function };
}

export interface LandingPostponeBehaviorState {}

class LandingPostponeBehavior extends React.Component<LandingPostponeBehaviorProps, LandingPostponeBehaviorState> {
  constructor(props: LandingPostponeBehaviorProps) {
    super(props);

    this.state = {};
  }

  async handleClick(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();
    try {
      await this.props.actions.postponeLandingVisit(this.props.location.pathname);
    } catch (error) {
      console.log(error);
    }
  }

  public render() {
    return <AddBehavior {...this.props} onClick={(e) => this.handleClick(e)} />;
  }
}

type MappedDispatchProps = Pick<LandingPostponeBehaviorProps, 'actions'>;

const mapDispatch = (dispatch): MappedDispatchProps => ({
  actions: bindActionCreators({ postponeLandingVisit }, dispatch)
});

export default register('rsv8-app/LandingPostponeBehavior')(withContent())(
  withRouter(
    connect(
      null,
      mapDispatch
    )(LandingPostponeBehavior)
  )
);
