import * as React from 'react';
import { connect } from 'react-redux';
import { register, withContent, withTheme } from 'xcel-react-core';
import programSelector from '../../redux/selectors/programSelector';

const Content = ({ content, platinumContent, children, program, ...rest }) => {
  const isEditing = rest['data-cms-editing'];
  if (isEditing) {
    return <div {...rest}>{content}</div>;
  }
  if (program && program.isPlatinum && platinumContent && platinumContent.props.value) {
    return <div {...rest}>{platinumContent}</div>;
  } else {
    return <div {...rest}>{content}</div>;
  }
};
const mapContentToProps = (getContent) => ({
  content: getContent('content', { type: 'html', title: 'Content', width: 'full' }),
  platinumContent: getContent('platinumContent', { type: 'html', title: 'Platinum Content', width: 'full' })
});

const mapStateToProps = (state) => ({
  program: programSelector.selectMany(state) ? programSelector.selectMany(state)[0] : null
});

export default register('rsv8-components/Content')(
  connect(mapStateToProps),
  withTheme(['a', 'ol', 'ul', 'li', 'p', 'dl', 'dt', 'dd']),
  withContent(mapContentToProps)
)(Content);
