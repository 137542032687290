import * as React from 'react';
import styled from 'styled-components';
import { assetService } from 'xcel-asset-service';
import { register, withContent, withTheme } from 'xcel-react-core';
import { CardBody } from './CardBody';
export type Props = {
  backgroundImage: string;
  backgroundSize: string;
  backgroundRepeat: string;
  height: string;
};

const ImageCardWrap: any = styled.div`
  position: relative;
  margin: 0;
  min-width: 150px;
  width: 100%;
` as any;

// this needs to be chaged from backgorund inage to just an image age QUIGLEY
const ImageContainer = styled.div`
  background-repeat: ${(props: Props) =>
    props.backgroundRepeat && props.backgroundRepeat !== '' ? props.backgroundRepeat : 'no-repeat'};
  background-position: center center;
  background-size: ${(props: Props) =>
    props.backgroundSize && props.backgroundSize !== '' ? props.backgroundSize : 'auto'};
  background-image: ${(props: Props) =>
    props.backgroundImage && props.backgroundImage !== ''
      ? `url(${assetService.getImage(props.backgroundImage)})`
      : 'url(http://admin6.rewardstation-test.net/clientcontent/100088/Images/component-test.jpg)'};
  height: ${(props: Props) => (props.height && props.height !== '' ? props.height : '325px')};
  margin: 0;
  position: relative;
  width: 100%;
` as any;

const ChildContainer = styled.div`
  width: 100%;
` as any;

const CardVertical: React.SFC<Props> = ({
  height,
  backgroundRepeat,
  backgroundImage,
  backgroundSize,
  children
}) => {
  return (
    <ImageCardWrap>
      <ImageContainer
        height={height}
        backgroundRepeat={backgroundRepeat}
        backgroundImage={backgroundImage}
        backgroundSize={backgroundSize}
      />
      <ChildContainer className="card-body-container">
        <CardBody>{children}</CardBody>
      </ChildContainer>
    </ImageCardWrap>
  );
};

export default register('rsv8-components/CardVertical')(withContent(), withTheme(['.card-body-container']))(
  CardVertical
);
export { CardVertical };
