import { push } from 'connected-react-router';
import { getConfigurationProperty } from 'xcel-config';
import { promiseDispatcher } from 'xcel-react-core';
import { queryString } from 'xcel-util';
import { authApi, userApi } from '../../api';
import { cacheService, logger } from '../../config';
import {
  createRedirectUrl,
  getAccountInfo,
  getGigyaConfig,
  inject,
  isGigyaEnabled
  } from '../../config/gigya';
import OAuthService from '../../services/OAuthService';
import authPath from '../paths/Auth';
import OAuth from '../paths/OAuth';
import userActions from './actions/user';
import * as events from './events';
import {
  AUTH_STATE,
  getAuth,
  getSession,
  getUser
  } from './selectors';

const authTokenRefresh: any = promiseDispatcher(
  (token, options) => (dispatch, getState) => {
    const auth = getAuth(getState());
    const { refreshToken } = auth;
    return authApi.createRefreshToken({ token: token || refreshToken }, options);
  },
  {
    request: events.AUTH_TOKEN_REFRESH_REQUEST,
    success: events.AUTH_TOKEN_REFRESH_SUCCESS,
    failure: events.AUTH_TOKEN_REFRESH_FAILURE
  }
);

const oAuthTokenRefresh = promiseDispatcher(() => OAuthService.userManager.getUser(), {
  request: events.O_AUTH_TOKEN_CREATE_REQUEST,
  success: (response) => (dispatch, getState) => {
    if (response && response.refresh_token !== '' && typeof response.refresh_token !== 'undefined') {
      OAuthService.userManager.signinSilent();
      const data = OAuthService.getAuthInfoFromResponse(response);
      return {
        type: events.O_AUTH_TOKEN_CREATE_SUCCESS,
        payload: data
      };
    } else {
      OAuthService.userManager
        .signinPopup()
        .then((resp) => {
          const userData = OAuthService.getAuthInfoFromResponse(resp);
          dispatch({
            type: events.O_AUTH_TOKEN_REFRESH_SUCCESS,
            payload: userData
          });
        })
        .catch(() => {
          dispatch({
            type: events.O_AUTH_TOKEN_REFRESH_FAILURE
          });
        });
      return {
        type: events.O_AUTH_POPUP_OPEN,
        payload: response
      };
    }
  },
  failure: events.O_AUTH_TOKEN_CREATE_FAILURE
});

const authCodeCreate = promiseDispatcher((code) => authApi.createCodeExchange({ request: { code } } as any), {
  request: events.AUTH_CODE_CREATE_REQUEST,
  success: (payload) => ({
    type: events.AUTH_CODE_CREATE_SUCCESS,
    payload,
    isSSO: true
  }),
  failure: events.AUTH_CODE_CREATE_FAILURE
});

const authTokenCreate = promiseDispatcher((cred) => authApi.createToken({ cred }), {
  request: events.AUTH_TOKEN_CREATE_REQUEST,
  success: (payload) => {
    return {
      type: events.AUTH_TOKEN_CREATE_SUCCESS,
      payload
    };
  },
  failure: events.AUTH_TOKEN_CREATE_FAILURE
});

const authTokenBasicCreate = promiseDispatcher(
  () => {
    return authApi.getBasicToken({});
  },
  {
    request: events.AUTH_TOKEN_BASIC_CREATE_REQUEST,
    success: (payload) => {
      return {
        type: events.AUTH_TOKEN_BASIC_CREATE_SUCCESS,
        payload
      };
    },
    failure: events.AUTH_TOKEN_BASIC_CREATE_FAILURE
  }
);

const oAuthTokenCreate = promiseDispatcher(() => OAuthService.userManager.getUser(), {
  request: events.O_AUTH_TOKEN_CREATE_REQUEST,
  success: (payload) => {
    const data = OAuthService.getAuthInfoFromResponse(payload);
    return {
      type: events.O_AUTH_TOKEN_CREATE_SUCCESS,
      payload: data
    };
  },
  failure: events.O_AUTH_TOKEN_CREATE_FAILURE
});

const authCheckSSO = ({ ssoSuccessPath = '/', ssoFailurePath = '/login-error' }) => (dispatch, getState) => {
  // ={token.RefreshToken}|{apiKey}
  const { code, ...rest }: any = queryString.get();
  if (code !== undefined) {
    dispatch(push('/sso'));
    // updates query string with rest of properties, so the two are removed.
    queryString.set(rest);

    dispatch(authCodeCreate(code))
      .then(() => {
        dispatch(userActions.get())
          .then(() => {
            dispatch(push(ssoSuccessPath));
          })
          .catch((err) => {
            console.log('Error in authCheckSSO ' + err);
            dispatch(push(ssoSuccessPath));
          });
      })
      .catch((err) => {
        console.log('Error with authCodeCreate ' + err);
        dispatch(push(ssoFailurePath));
      });
  }
};

const validateAccessKey = promiseDispatcher(userApi.getAccessKeys, {
  request: events.USER_GET_ACCESS_KEY_REQUEST,
  success: events.USER_GET_ACCESS_KEY_SUCCESS,
  failure: events.USER_GET_ACCESS_KEY_FAILURE
});

const checkResetPasswordToken = ({
  resetPasswordPath = authPath.resetPassword, // '/reset-password',
  expiredRedirectPath = authPath.expiredToken // '/invalid-token'
}) => (dispatch, getState) => {
  let { accessKey } = queryString.get() as any;

  if (accessKey !== undefined) {
    const auth = getAuth(getState());
    if (!auth.accessToken || !auth.isValid) {
      dispatch(authTokenBasicCreate())
        .then(() => dispatch(validateAccessKey({ accessKey })))
        .then(() => dispatch(push(resetPasswordPath)))
        .catch((error) => {
          dispatch(push(expiredRedirectPath));
          console.warn(error);
        });
    } else {
      dispatch(validateAccessKey({ accessKey }))
        .then(() => dispatch(push(resetPasswordPath)))
        .catch((error) => {
          dispatch(push(expiredRedirectPath));
          console.warn(error);
        });
    }
  }
};
let authCheckLocalStorageTimeoutId;
const authCheckLocalStorage = ({ interval }) => (dispatch, getState) => {
  interval = interval !== undefined ? interval : 1000;
  clearTimeout(authCheckLocalStorageTimeoutId);
  // if auth isn't present, let's create a basic token.

  const auth = getAuth(getState());
  if (auth === undefined) {
    throw new Error('You must include authModule in your ReduxProvider');
  }

  let identityServerConfigData = getConfigurationProperty(['identityServerConfigData']) as any;

  if (cacheService.get(AUTH_STATE) === undefined && !auth.isPending && !identityServerConfigData) {
    logger.log('CheckSessionTimeout', 'No valid auth found, creating basic auth.');
    dispatch(authTokenBasicCreate());
  }
  authCheckLocalStorageTimeoutId = setTimeout(() => authCheckLocalStorage(interval)(dispatch, getState), interval);
};

const authDoGigyaSSOSync = promiseDispatcher(
  () => async (dispatch, getState) => {
    const user = getUser(getState());
    if (!user) {
      return Promise.reject();
    }

    if (user && user.tokenType !== 'Basic') {
      return Promise.reject();
    }

    // getting response back from STS
    if (queryString.getParam('code')) {
      return Promise.reject();
    }

    let gigyaConfig = getConfigurationProperty(['clients', user.clientId, 'gigya']) as any;

    if (gigyaConfig !== undefined) {
      await inject(gigyaConfig);
    }

    if (!gigyaConfig || isGigyaEnabled() === false) {
      console.log('gigya service', 'service not configured for client');
      return Promise.reject();
    } else {
      try {
        // first we need to verify the user if the user is already logged in.
        // try to get account information
        const accountInfo = await getAccountInfo();
        console.log('gigya service', 'authorization already exists.');

        console.log('gigya redirect', createRedirectUrl(getGigyaConfig(), accountInfo.UID));
        if (accountInfo.UID !== undefined && accountInfo.errorCode === 0) {
          window.location.href = createRedirectUrl(getGigyaConfig(), accountInfo.UID);
          return Promise.resolve();
        }
      } catch (accountError) {
        console.log('gigya authorization required');
        throw accountError;
      }
    }
    return Promise.reject();
  },
  {
    request: events.AUTH_GIGYA_SSO_SYNC_REQUEST,
    success: events.AUTH_GIGYA_SSO_SYNC_SUCCESS,
    failure: events.AUTH_GIGYA_SSO_SYNC_FAILURE
  }
);

let authCheckSessionTimeoutId;

const closeModalAction = () => ({ type: events.AUTH_TOKEN_EXPIRE_MODAL_CLOSE });
const openModal = () => ({ type: events.AUTH_TOKEN_EXPIRE_MODAL_OPEN });
const closeModal = () => (dispatch) => {
  dispatch(closeModalAction());
};
const forceLogout = () => (dispatch) => {
  dispatch(authTokenBasicCreate());
  dispatch(closeModal());
};
const forceRefresh = () => (dispatch) => {
  dispatch(authTokenRefresh());
  dispatch(closeModal());
};

const oAuthForceLogout = () => (dispatch) => {
  dispatch(closeModal());
  dispatch(push('/logout'));
};

const oAuthForceRefresh = () => (dispatch) => {
  dispatch(oAuthTokenRefresh());
  dispatch(closeModal());
};

const changeFirstVisitState = (value) => ({
  type: events.AUTH_FIRST_VISIT_CHANGE,
  payload: value
});

const authCheckSessionTimeout = ({
  interval,
  sessionRefreshTokenBuffer,
  inActiveTimeout,
  onTimeout,
  onRefreshToken,
  ...rest
}) => (dispatch, getState) => {
  const state = getState();
  const auth = getAuth(state);
  const { code }: any = queryString.get();
  if (auth.isSSO || (code !== undefined && state.routing.location.pathname !== OAuth.loginRedirectCallback)) {
    dispatch(authCheckSSO(rest));
  }
  dispatch(checkResetPasswordToken(rest));
  clearTimeout(authCheckSessionTimeoutId);
  const session = getSession(state);
  const authTimeLeft = auth && auth.expireDate ? +new Date(auth.expireDate) - +new Date() : 0;
  const inactiveTime = +new Date() - +new Date(session.lastUpdate);
  logger.log('CheckSessionTimeout', { authTimeLeft, inactiveTime });
  const { isOAuth, isPending, isExpiring, refreshToken, isValid } = auth;
  if (auth && authTimeLeft < sessionRefreshTokenBuffer && !isPending) {
    if (refreshToken && isValid) {
      dispatch(isOAuth ? oAuthForceRefresh() : authTokenRefresh());
      if (onRefreshToken) {
        onRefreshToken(auth);
      }
    } else {
      let identityServerConfigData = getConfigurationProperty(['identityServerConfigData']) as any;
      if (!identityServerConfigData) {
        dispatch(authTokenBasicCreate());
      }
    }
  } else if (auth && authTimeLeft - 60 * 5 * 1000 < sessionRefreshTokenBuffer && !isPending) {
    if (inactiveTime > sessionRefreshTokenBuffer) {
      dispatch(openModal());
    } else {
      if (refreshToken && isValid) {
        dispatch(isOAuth ? oAuthForceRefresh() : authTokenRefresh());
        if (onRefreshToken) {
          onRefreshToken(auth);
        }
      }
    }
  } else if (isExpiring) {
    dispatch(isOAuth ? oAuthForceLogout() : forceLogout());
  }
  if (inactiveTime > inActiveTimeout) {
    if (onTimeout) {
      onTimeout(auth);
    }
  }
  authCheckSessionTimeoutId = setTimeout(() => {
    authCheckSessionTimeout({
      interval,
      sessionRefreshTokenBuffer,
      inActiveTimeout,
      onTimeout,
      onRefreshToken
    })(dispatch, getState);
  }, interval);
};

export {
  authTokenCreate,
  oAuthTokenCreate,
  openModal,
  closeModal,
  authTokenRefresh,
  oAuthTokenRefresh,
  authTokenBasicCreate,
  authCheckSessionTimeout,
  authCheckLocalStorage,
  forceLogout,
  oAuthForceLogout,
  forceRefresh,
  oAuthForceRefresh,
  validateAccessKey,
  authDoGigyaSSOSync,
  changeFirstVisitState
};
