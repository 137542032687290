import styled from 'styled-components';
import { ClickedLikeIcon, DefaultLikeIcon } from './LikeIcon';

export const StyledDefaultLikeIcon = styled(DefaultLikeIcon)`
  fill: currentColor;
  width: 15px;
  height: 15px;
  color: #000;
`;

export const StyledClickedLikeIcon = styled(ClickedLikeIcon)`
  fill: currentColor;
  stroke: currentColor;
  width: 15px;
  height: 15px;
  color: #000;
`;

interface CustomIconProps {
  url: string;
}
export const CustomIcon = styled.div<CustomIconProps>`
  width: 15px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  background-image: url('${(props) => props.url}');
  background-size: contain;
  background-repeat: no-repeat;
`;

export const LabelText = styled.span`
  color: var(--mascara);
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  font-weight: bold;
  margin-left: 5px;
`;

interface LikeActionWrapperProps {
  hoverUrl: string;
}
export const LikeActionWrapper = styled.div<LikeActionWrapperProps>`
  cursor: pointer;

  &:hover ${CustomIcon} {
    background-image: url('${(props) => props.hoverUrl}');
  }
`;
