import { userStateSelectors } from 'rsv8-auth';
import { getCart } from 'rsv8-catalog';
import { reduxObserver } from 'xcel-observer';
import { onSelectorChange } from 'xcel-react-core';
import { createReduxModule, ReduxModule } from 'xcel-redux-module';
import { destroyRequest } from 'xcel-redux-orm';
import { getAccountSummary } from './app/redux/actions';

const accountModule: ReduxModule = createReduxModule({
  name: 'Account',
  init: ({ store }) => {
    const userChanged = onSelectorChange(store, userStateSelectors.getUser);

    store.subscribe(() => {
      userChanged((current, next) => {
        if (next.tokenType === 'User') {
          // this should be moved to catalog....
          store.dispatch(destroyRequest('getCart'));
          store.dispatch(getCart());

          // need to destroy cart/account fetch
          store.dispatch(destroyRequest('getSummary'));
          store.dispatch(getAccountSummary());

               // need to destroy cart/account fetch
        }
      });
    });
  },
  reducers: {}
});
reduxObserver.addModule(accountModule);
export {accountModule};

export {
  AccountBalanceTables,
  OrdersTable,
  PointEarningsTable,
  AdjustmentsTable,
  AccountBalanceBreakdown,
  UserPoints,
  PointsLongHand,
  PointsShortHand,
  UserStory,
} from "./app/components";
