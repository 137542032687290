import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withErrorBoundary } from 'xcel-react-core';
import { emptyArray } from 'xcel-util';
import { contentTypeSelector, routeSelector } from '../../index';
import CodeEditor from '../../components/CodeEditor';
import ContentPromotionButton from '../../components/ContentPromotionButton';
import PublishButton from '../../components/PublishButton';
import HoverProvider from '../../hoc/withHover';
import { contentSelector } from '../../redux';
import { contentActions } from '../../redux/actions';
import ContentProperties from '../ContentEditSidebar/ContentProperties';

class ContentEdit extends React.Component<any> {
  state = {
    sourceOpen: false
  };
  componentDidMount() {
    const { dispatch, match, contentTypeId } = this.props;
    dispatch(contentActions.getById(contentTypeId, match.params.id));
  }
  handleRawChange = (changed) => {
    const { dispatch, contentTypeId } = this.props;
    dispatch(contentActions.setRaw(contentTypeId, changed));
  };
  getPathsToPromote = () => {
    const { activeRoutes, match, breadcrumbs } = this.props;
    let activePath = breadcrumbs.reduce((accumulator, route) => {
      return route.label.trim() !== '' ? `${accumulator}/${route.label}` : accumulator;
    }, '');
    return [
      (activePath +=
        Array.isArray(activeRoutes) && activeRoutes.length
          ? `/${activeRoutes[0].items.find((i) => i.id === match.params.id).label}`
          : '')
    ];
  };
  render() {
    const { content } = this.props;
    return (
      <HoverProvider property={'model'}>
        <Row>
          <Col md={18}>
            <CodeEditor height="calc(100vh - 75px)" language="json" value={content} onChange={this.handleRawChange} />
          </Col>
          <Col md={6}>
            <ContentProperties />
            <PublishButton />
            <ContentPromotionButton index={1} pathsToPromote={this.getPathsToPromote()} />
          </Col>
        </Row>
      </HoverProvider>
    );
  }
}

const mapStateToProps = (state) => {
  const contentTypeId = contentTypeSelector.getActive(state);
  return {
    contentTypeId,
    activeRoutes: routeSelector.getActive(state, contentTypeId),
    breadcrumbs: routeSelector.getActiveBreadcrumbs(state, contentTypeId) || emptyArray,
    content: contentSelector.getRaw(state, contentTypeId)
  };
};
export default connect(mapStateToProps)(withErrorBoundary()(ContentEdit));
