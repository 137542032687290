import * as React from 'react';
import { CaptionImage, HeaderOne, InitialsImage } from 'rsv8-components';
import { CaptionImageProps } from 'rsv8-components';
import styled from 'styled-components';

const LargeCaption = styled(HeaderOne)`
  margin-top: 15px !important;
` as any;

export interface NomineeAttributes {
  firstName: any;
  lastName: any;
}

export type NomineeImageProps<N extends NomineeAttributes> = {
  nominee: N;
  caption: boolean;
  width?: string;
  separate?: boolean;
} & Partial<CaptionImageProps>;

export const NomineeImage = <N extends NomineeAttributes>({
  nominee,
  width,
  caption,
  separate,
  ...rest
}: NomineeImageProps<N>) => {
  if (!nominee) {
    console.error('Calling NomineeImage w/o a nominee.');
    return null;
  }
  if (separate) {
    return (
      <React.Fragment>
        <CaptionImage
          {...rest}
          component={InitialsImage}
          firstName={nominee.firstName}
          lastName={nominee.lastName}
          width={width || '60px'}
        />
        {caption ? (
          <LargeCaption>
            {nominee.firstName} {nominee.lastName}
          </LargeCaption>
        ) : null}
      </React.Fragment>
    );
  }
  return (
    <CaptionImage
      {...rest}
      component={InitialsImage}
      firstName={nominee.firstName}
      lastName={nominee.lastName}
      width={width || '60px'}
    >
      {caption ? (
        <React.Fragment>
          {nominee.firstName} {nominee.lastName}
        </React.Fragment>
      ) : null}
    </CaptionImage>
  );
};

export default NomineeImage;
