import {
  getFormAsyncErrors,
  getFormInitialValues,
  getFormMeta,
  getFormNames,
  getFormSubmitErrors,
  getFormSyncErrors,
  getFormSyncWarnings,
  getFormValues,
  hasSubmitFailed,
  hasSubmitSucceeded,
  isDirty,
  isInvalid,
  isPristine,
  isSubmitting,
  isValid
  } from 'redux-form';

const modifyFormSelectors = (name) => (selectorFn) => (...args) => selectorFn(...args, (state) => state[name]);

const selectorService = (name) => {
  const addFormState = modifyFormSelectors(name);
  return {
    getFormValues: addFormState(getFormValues) as (n: string) => (state: any) => any,
    getFormInitialValues: addFormState(getFormInitialValues) as (n: string) => (state: any) => any,
    getFormSyncErrors: addFormState(getFormSyncErrors) as (n: string) => (state: any) => any,
    getFormMeta: addFormState(getFormMeta) as (n: string) => (state: any) => any,
    getFormAsyncErrors: addFormState(getFormAsyncErrors) as (n: string) => (state: any) => any,
    getFormSyncWarnings: addFormState(getFormSyncWarnings) as (n: string) => (state: any) => any,
    getFormSubmitErrors: addFormState(getFormSubmitErrors) as (n: string) => (state: any) => any,
    getFormNames: addFormState(getFormNames) as () => (state: any) => Array<string>,
    isDirty: addFormState(isDirty) as (n: string) => (state: any) => boolean,
    isPristine: addFormState(isPristine) as (n: string) => (state: any) => boolean,
    isValid: addFormState(isValid) as (n: string) => (state: any) => boolean,
    isInvalid: addFormState(isInvalid) as (n: string) => (state: any) => boolean,
    isSubmitting: addFormState(isSubmitting) as (n: string) => (state: any) => boolean,
    hasSubmitSucceeded: addFormState(hasSubmitSucceeded) as (n: string) => (state: any) => boolean,
    hasSubmitFailed: addFormState(hasSubmitFailed) as (n: string) => (state: any) => boolean
  };
};

export default selectorService;
