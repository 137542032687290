import { Text } from 'rsv8-components';
import styled from 'styled-components';

export const ProductInputField = styled.input`
  width: 100%;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #505d6f;
  outline: none;
  vertical-align: bottom;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0 solid black;
  text-decoration-line: underline;
  padding: 0;
` as any;



export const InputFieldContainer = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  border: solid 1px #b2b2b2;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  align-content: center;
  align-items: center;
  align-self: center;
  vertical-align: bottom;
  padding: 1px;
  margin: 0px;
  color: #505d6f;
`;

export const ProductCardDiv = styled.div`
  background: transparent;
  padding: 0;
  text-align: center;
  align-content: center;
  align-items: center;
  align-self: center;
  width: 100%;
  display: flex;
  justify-content: space-around;

  @media (max-width: 425px) {
    width: 100%;
  }

  .product__attribute-value {
    flex-grow: 1;
    max-width: 80px;

    @media (max-width: 425px) {
      max-width: 110px;
    }
  }

  .product__attribute-value + .product__attribute-value {
    border-left: 1px solid #b2b2b2;
    margin-left: 0;
    color: #505d6f;
    line-height: 1.4;
    font-size: 10px;
  }
` as any;

export const CenteredCell = styled.div`
  justify-content: center;
` as any;

export const ProductTable = styled.div`
  width: 100%;
  background-color: #ffffff;
  vertical-align: middle;
  text-align: center;
  align-content: center;
  align-items: center;
  align-self: center;
  border-radius: 5px;
  border-collapse: separate;
  border: solid 1px #b2b2b2;
  margin-bottom: 20px;
  overflow: hidden;
` as any;

export const ProductDataTableRow = styled.div`
  display: flex;
  justify-content: space-between;
  vertical-align: top;
  text-align: center;
  padding: 0;
  border: 0 solid;
  width: 100%;
  @media (max-width: 1024px) {
    flex-wrap: wrap;
    justify-content: center;
  };
` as any;

export const ProductTableCell = styled.div`
  text-align: center;
  vertical-align: middle;
  align-self: center;
  align-content: center;
  align-items: center;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.5px;
  padding: 5px 10px;
` as any;

export const ProductTableColumn = styled(ProductTableCell) <{ isSingleLine: boolean }>`
  font-family: open-sans;
  font-size: 10px;
  line-height: 14px;
  width: 16%;

  @media (min-width: 768px) and (max-width: 1024px) {
    width: ${props => props.isSingleLine ? 'auto' : '30%'};
    flex-grow: ${props => props.isSingleLine ? 1 : 'unset'};
  }
  @media only screen and (max-width: 767px) {
    width: 34%;
  }
  @media only screen and (max-width: 600px) {
    width: 50%;
  }
  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`;

export const ProductTableCellWithInput = styled(ProductTableCell)`
  padding: 3px 10px;
` as any;

export const ProductNameTableCell = styled.div`
  background-color: #fafafa;
  text-align: left;
  vertical-align: top;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  padding: 10px;
  display: flex;

  @media only screen and (max-width: 1024px) {
    width: 100%;
    border-bottom: solid 1px #b2b2b2;
    flex-direction: row;
    justify-content: space-between;
  }

  @media only screen and (min-width: 1025px) {
    width: 180px;
    border-right: solid 1px #b2b2b2;
    flex-direction: column;
  }
` as any;

export const ProductAttrHeaderText = styled(Text)`
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  align-self: center;
  align-content: center;
  align-items: center;
  vertical-align: middle;
  color: #505d6f;
  padding-top: 5px;
  padding-bottom: 5px;
  white-space: nowrap;
` as any;

export const ProductAttrText = styled(Text)`
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 14px;
  letter-spacing: normal;
  text-align: center;
  color: #505d6f;
` as any;

export const ProductNameText = styled(Text)`
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 14px;
  letter-spacing: normal;
  color: #505d6f;
  text-align: left;

  @media only screen and (max-width: 1365px) {
    flex-grow: 1;
  }
` as any;

export const ProductNameValueText = styled(Text)`
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 14px;
  letter-spacing: normal;
  color: #505d6f;

  @media only screen and (max-width: 1365px) {
    text-align: right;
    margin: 0 0 0 0.5rem;
    white-space: nowrap;
  };
  
  @media only screen and (min-width: 1366px) {
    text-align: left;
  };
` as any;
