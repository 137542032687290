import { BillNetwork, EnrollmentSummary } from "xcel-api-generator/dist/alcon";
import { createTypeSelector } from "xcel-redux-orm";
import * as constants from "../../constants";

export const enrollmentStatusSelector = createTypeSelector<EnrollmentSummary>(constants.DIRECT_DEPOSIT_REDUCER_KEY);
export const enrollmentUserSelector = createTypeSelector<EnrollmentSummary>(constants.ENROLLED_USER_KEY);
export const achSearchInformation = createTypeSelector<BillNetwork>(constants.ACH_SEARCH_INFORMATION);

export const getAlconProductsSearch = (state: any) => state[constants.ROOT_STATE][constants.DIRECT_DEPOSIT_REDUCER_KEY];
export const getAchSearchFromStore = (state: any) => state[constants.ROOT_STATE][constants.ACH_SEARCH_INFORMATION];

export const getEnrollmentStatus = (state: any): string => getAlconProductsSearch(state).enrollmentStatus;
export const getEnrollmentUserInfo = (state: any): string => getAlconProductsSearch(state).enrolledUser;

export const getAchSearchResults = (state: any) => getAlconProductsSearch(state).setAchSearchInformation;
