import * as React from 'react';
import styled from 'styled-components';
import { AnimatedCircle } from '../Loader';

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
`;

const Container = styled.div`
  position: relative;
`;

const LoadingOverlay = (props) => (
  <Container>
    {props.children}
    {props.loading ? (
      <Overlay>
        <AnimatedCircle />
      </Overlay>
    ) : null}
  </Container>
);

export default LoadingOverlay;
