import * as React from 'react';

export const Movie = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 47">
      <g fill="#E98300" fill-rule="nonzero">
        <path d="M46.327 0H4.6A4.1 4.1 0 0 0 .5 4.1v41.961h49.927V4.101A4.1 4.1 0 0 0 46.327 0zM35.592 4.7h5.146v3.803h-5.146V4.7zm-8.355 0h5.147v3.803h-5.147V4.7zm-7.98 0h5.147v3.803h-5.147V4.7zm-7.757 0h5.147v3.803H11.5V4.7zm-8.353 0h5.146v3.803H3.147V4.7zm5.146 38.712H3.147v-3.807h5.146v3.807zm8.354 0H11.5v-3.807h5.147v3.807zm7.757 0h-5.147v-3.807h5.147v3.807zm7.98 0h-5.147v-3.807h5.147v3.807zm8.354 0h-5.146v-3.807h5.146v3.807zm7.757 0h-5.147v-3.807h5.148l-.001 3.807zm0-6.414H3.147V11.039h45.349l-.001 25.959zm0-28.492h-5.147V4.7h5.148l-.001 3.807z" />
        <path d="M30.681 25.03l-8.61 5.046a1.681 1.681 0 0 1-2.53-1.44l-.066-9.98a1.682 1.682 0 0 1 2.512-1.477l8.675 4.934a1.688 1.688 0 0 1 .02 2.917z" />
      </g>
    </svg>
  );
};
