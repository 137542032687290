import { NominationActivity, Resources } from '../../../types';
import CriteriaAndDetails from './CriteriaAndDetails';
import DataMissing from './DataMissing';
import InfoArrayBlock from './InfoArrayBlock';
import NomineePanel, { NominationStatusPanel } from './NomineePanel';
import TeamIcon from './TeamIcon';
import withModalResources, { WithModalResourcesProps } from './withModalResources';

export const isApprover = (
  n: Resources.NominationDetail | Resources.TeamHistoryDetails,
  type: NominationActivity.ActivityTables
): n is Resources.ApproverDetail =>
  type === 'ApprovalHistoryIndividual' ||
  type === 'ApprovalHistoryTeam' ||
  type === 'PendingApprovalIndividualTable' ||
  type === 'PendingApprovalTeamTable';

export const isTeam = (
  n: Resources.NominationDetail | Resources.TeamHistoryDetails,
  type: NominationActivity.ActivityTables
): n is Resources.TeamHistoryDetails => type === 'NominationHistoryTeam';

export const isPendingApproval = (
  n: Resources.NominationDetail | Resources.TeamHistoryDetails,
  type: NominationActivity.ActivityTables
): n is Resources.ApproverDetail => type === 'PendingApprovalIndividualTable' || type === 'PendingApprovalTeamTable';

export {
  InfoArrayBlock,
  CriteriaAndDetails,
  withModalResources,
  NominationStatusPanel,
  WithModalResourcesProps,
  NomineePanel,
  TeamIcon,
  DataMissing
};
