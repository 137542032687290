import { ReduxState, State, Step } from '../../../types';

export const getWizard = (state, name: string): State => state.wizard[name] || {};

export const getWizardCurrentStep = (state: ReduxState, name: string): Step => getWizard(state, name).currentStep;

export const getWizardCurrentStepNumber = (state: ReduxState, name: string): number =>
  getWizard(state, name).currentStepNumber;

export const getWizardSteps = (state: ReduxState, name: string): Array<Step> => getWizard(state, name).steps || [];

export const getWizardNames = (state: ReduxState): Array<string> => Object.keys(state.wizard);

export const getWizardStepCount = (state: ReduxState, name: string): number => getWizardSteps(state, name).length;

export const getHumanReadableStepNumber = (state: ReduxState, name: string): number =>
  getWizardSteps(state, name)
    .filter((s) => !s.skip)
    .findIndex((v) => v.title === getWizardCurrentStep(state, name).title);

export const getHumanReadableStepCount = (state: ReduxState, name: string): number =>
  getWizardSteps(state, name).filter((s) => !s.skip).length;

export const getCurrentVisibleStepIndex = getHumanReadableStepNumber;
