import { Text } from 'rsv8-components';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 25px;
  @media (min-width: 991px) {
    padding: 50px;
  }
  border: solid 1px #dddddd;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
` as any;

export const Dashboard = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
` as any;

export const ActivityHeader = styled(Text)`
  font-size: 16px;
  color: #1474a4;
  line-height: 1.63;
` as any;

export const TargetText = styled(Text)`
  font-size: 28px;
  line-height: 1.36;
  letter-spacing: normal;
  color: #1474a4;
` as any;

export const PendingText = styled(Text)`
  font-size: 9px;
  line-height: 1.22;
  color: #5d5d5d;
` as any;

export const ErrorText = styled(Text)`
  font-size: 11px;
  color: #dddddd;
  line-height: 1.91;
`;

export const ActivityContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  align-items: flex-start;

  @media (min-width: 991px) and (max-width: 1366px) {
    flex-basis: 50%;
  }
  @media (min-width: 1366px) {
    flex-basis: 33.3%;
  }
` as any;

export const BudgetContainer = styled(ActivityContainer)`
  @media (min-width: 991px) and (max-width: 1366px) {
    flex-grow: 4;
    margin-bottom: 25px;
  }
` as any;

export const ApprovalContainer = styled(ActivityContainer)`
  @media (min-width: 991px) {
    align-items: center;
  }
  @media (min-width: 991px) and (max-width: 1366px) {
    flex-grow: 1;
  }
  @media (max-width: 990px) {
    margin: 20px 0;
  }
` as any;

export const ChartContainer = styled(ActivityContainer)`
  flex-grow: 2;
  flex-shrink: 2;
  @media (min-width: 991px) and (max-width: 1366px) {
    flex-basis: 100%;
  }
` as any;

export const PendingApprovals = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 768px) {
    max-width: 160px;
  }
` as any;
