import { path } from 'ramda';
import { compose } from 'redux';
import { Image } from 'rsv8-components';
import { assetService } from 'xcel-asset-service';
import { withProps } from 'xcel-react-core';

const mapProps = (props) => {
  const icon = path(['object', 'icon'], props) || undefined;
  return {
    imgSrc: assetService.getImage(`awards/${icon}`)
  };
};

export default compose<any>(withProps(mapProps))(Image);
