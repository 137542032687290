import * as React from "react";
import { ProductHeaderContainer } from "./styles";

export const ProductHeader: React.FC<{
    baselineYearLabel: string | number;
    name: string;
    unitLabel?: string;
    amount?: number;
    baselineProductAmount?: number;
}> = ({ baselineYearLabel, name, unitLabel, amount, baselineProductAmount }) => (
    <ProductHeaderContainer>
        <div className="product__title">
            {name}
        </div>

        {!!amount &&
            <div className="product__subtitle">
                {amount}-{unitLabel}
            </div>
        }

        {!!baselineProductAmount &&
            <div className="product__subtitle">
                {baselineProductAmount}-{unitLabel} ({baselineYearLabel})
            </div>
        }
    </ProductHeaderContainer>
)
