import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { destroy, destroyRequest } from 'xcel-redux-orm';
import { assetAdminApiActions } from '../../redux/actions';
import { assetAdminToast } from '../../util';


export interface DeleteRoutes {
  routeId: number;
  parentId: number;
}

const deleteRoute = async (routeId, props, parentId) => {
  const { actions } = props;
  try {
    await actions.deleteAssetRoute({ id: routeId });
    await actions.destroyRequest('getClientAssetRoutes');
    await actions.destroy('clientAssetChildRouteDto', routeId);
    await actions.destroy('clientAssetRouteDto', routeId);
    await actions.getRoutes({ filterFilter: `id = ${parentId}` });
  } catch (err) {
    assetAdminToast('admin-warning', err);
  }
};

const DeleteAssetRoute: React.SFC<DeleteRoutes> = (props) => {
  const { routeId, parentId } = props;
  return (
    <i
      className={`fa fa-trash`}
      onClick={() => deleteRoute(routeId, props, parentId)}
      title="Delete"
      style={{ color: 'red' }}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getRoutes: assetAdminApiActions.getClientAssetRoutes,
      deleteAssetRoute: assetAdminApiActions.deleteClientAssetRoutesById,
      destroyRequest,
      destroy
    },
    dispatch
  )
});

export default connect<React.SFC<DeleteRoutes>>(
  null,
  mapDispatchToProps
)(DeleteAssetRoute);
