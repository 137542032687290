import * as React from 'react';
import { Route } from 'react-router-dom';
import authPath from '../../paths/Auth';
import userPath from '../../paths/User';
import ChangePassword from '../ChangePassword/ChangePassword';
import ChangePasswordConfirmation from '../ChangePassword/ChangePasswordConfirmation';
import EmailNotFound from '../ForgotUsername/EmailNotFound';
import ForgotUsername from '../ForgotUsername/ForgotUsername';
import NoEmailAddress from '../ForgotUsername/NoEmailAddress';
import UsernameReminderSent from '../ForgotUsername/UsernameReminderSent';
import Confirmation from '../ResetPassword/Confirmation';
import ExpiredToken from '../ResetPassword/ExpiredToken';
import MultipleUsersFound from '../ResetPassword/MultipleUsersFound';
import ResetPassword from '../ResetPassword/ResetPassword';
import ResetPasswordSent from '../ResetPassword/ResetPasswordSent';
import UserHasNoEmail from '../ResetPassword/UserHasNoEmail';
import UserNotFound from '../ResetPassword/UserNotFound';

export const ResetPasswordRoutes: any = ({ path, loginPath, hrEmailAddress }) => (
  <React.Fragment>
    <Route
      exact
      path={authPath.expiredToken}
      render={(props) => <ExpiredToken {...props} loginHelpLinkPath={loginPath} />}
    />
    <Route
      exact
      path={authPath.resetPassword}
      render={(props) => (
        <ResetPassword
          {...props}
          successRedirectPath={authPath.resetPasswordConfirmation}
          failureRedirectPath={authPath.resetPassword}
          expiredRedirectPath={authPath.expiredToken}
        />
      )}
    />
    <Route
      exact
      path={authPath.changePassword}
      render={(props) => <ChangePassword {...props} successRedirectPath={authPath.changePasswordConfirmation} />}
    />
    <Route
      exact
      path={authPath.confirmPassword}
      render={(props) => <ChangePassword {...props} successRedirectPath={authPath.changePasswordConfirmation} />}
    />
    <Route
      exact
      path={authPath.resetPasswordSent}
      render={(props) => <ResetPasswordSent {...props} loginHelpLinkPath={loginPath} />}
    />
    <Route
      exact
      path={authPath.resetPasswordConfirmation}
      render={(props) => <Confirmation redirectPath={loginPath} {...props} />}
    />
    <Route
      exact
      path={authPath.changePasswordConfirmation}
      render={(props) => <ChangePasswordConfirmation {...props} />}
    />
    <Route
      exact
      path={authPath.userNotFound}
      render={(props) => <UserNotFound {...props} hrEmailAddress={hrEmailAddress} loginHelpLinkPath={loginPath} />}
    />
    <Route
      exact
      path={authPath.userHasNoEmail}
      render={(props) => <UserHasNoEmail {...props} hrEmailAddress={hrEmailAddress} loginHelpLinkPath={loginPath} />}
    />
  </React.Fragment>
);

export const ForgotUsernameRoutes: any = ({ loginPath, hrEmailAddress }) => (
  <React.Fragment>
    <Route
      exact
      path={userPath.forgotUsername}
      render={(props) => (
        <ForgotUsername
          {...props}
          successRedirectPath={userPath.usernameReminderSent}
          failureRedirectPath={userPath.emailNotFound}
          unprocessableRedirectPath={userPath.multipleUsersFound}
          noEmailAddressRedirectPath={userPath.noEmailAddress}
          loginHelpLinkPath={loginPath}
          hrEmailAddress={hrEmailAddress}
        />
      )}
    />
    <Route
      exact
      path={userPath.emailNotFound}
      render={(props) => <EmailNotFound {...props} hrEmailAddress={hrEmailAddress} loginHelpLinkPath={loginPath} />}
    />
    <Route
      exact
      path={userPath.multipleUsersFound}
      render={(props) => (
        <MultipleUsersFound {...props} hrEmailAddress={hrEmailAddress} loginHelpLinkPath={loginPath} />
      )}
    />
    <Route
      exact
      path={userPath.noEmailAddress}
      render={(props) => <NoEmailAddress {...props} hrEmailAddress={hrEmailAddress} loginHelpLinkPath={loginPath} />}
    />
    <Route
      exact
      path={userPath.usernameReminderSent}
      render={(props) => (
        <UsernameReminderSent {...props} hrEmailAddress={hrEmailAddress} loginHelpLinkPath={loginPath} />
      )}
    />
  </React.Fragment>
);
