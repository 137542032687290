import styled from 'styled-components';
import { Table } from '../components/Table';
import { Text } from '../components/Typography';

export const DesktopTableContainer = styled.div`
  tr {
    cursor: pointer;
  }
` as any;

export const NoDataContainer = styled.div`
  font-family: open-sans;
  color: #505d6f;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  padding-bottom: 30px;
  @media only screen and (min-width: 512px) and (max-width: 1024px) {
    padding: 20px 0;
  }
` as any;

export const DesktopContainer = styled.div`
  margin: 0 auto;
  border: 0px;
  border-radius: 5px;
  width: 100%;
  max-width: 1376px;
  box-shadow: 0 2px 17px 2px rgba(28, 39, 76, 0.09);
  background-color: #ffffff;
  font-family: open-sans;
  padding: 0 35px 15px 35px;

  input {
    width: 100%;
    border-radius: 6px;
    border: 2px solid #b2b2b2;
    color: #505d6f;
    line-height: 28px;
    letter-spacing: 0.5px;
    font-size: 14px;
    padding-left: 20px;
    margin-top: 20px;
  }

  .header {
    color: #505d6f;
    font-size: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .search-bar {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  table,
  th,
  td {
    color: #505d6f;
    font-size: 14px;
  }

  table {
    border-collapse: collapse !important;
    thead {
      th {
        border-bottom: 0;
        text-align: center;
      }
    }

    tbody {
      td {
        text-align: center;
      }
    }
  }
` as any;

export const DesktopDataTable = styled.table`
  width: 96%;
  height: 100%;
  background-color: transparent;
  text-align: center;
  align-self: center;
  vertical-align: middle;
  padding: 15px;
  padding-left: 35px;
  padding-right: 35px;
  border-collapse: separate;
  border: solid 0px #b2b2b2;
  border-radius: 5px;
` as any;

export const DesktopTableRow = styled.tr`
  height: 15px;
  padding: 15px;
` as any;

export const DesktopTableHeader = styled.thead`
  width: 1275px;
  height: 1px;
  padding: 25px;
` as any;

export const DesktopContainerTitle = styled.h3`
  width: 376px;
  font-family: open-sans-extra-bold;
  font-size: 28px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: #657386;
  padding-top: 50px;
  padding-bottom: 30px;
` as any;

export const DesktopTableHeaderCell = styled.th`
  text-align: right;
  vertical-align: top;
  width: 169px;
  height: 24px;
  padding: 25px;
` as any;

export const DesktopTableHeaderText = styled.text`
  width: 169px;
  height: 24px;
  font-family: open-sans-bold;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.5px;
  color: #657386;
  white-space: nowrap;
` as any;

export const DesktopTableCell = styled.td`
  text-align: right;
  vertical-align: middle;
  padding: 10px;
  width: 150px;
  height: 24px;
  font-family: open-sans;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.5px;
  text-align: right;
  border-top: solid 1px #d8d8d8;
  padding: 25px;
` as any;

export const DesktopNoDataTableCell = styled.td`
  text-align: center;
  vertical-align: middle;
  padding: 10px;
  width: 100%;
  height: 48px;
  font-family: open-sans;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.5px;
  border-top: solid 1px #d8d8d8;
  padding: 25px;
` as any;

export const DesktopTableText = styled.text`
  width: 169px;
  height: 24px;
  font-family: open-sans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.5px;
  text-align: right;
  color: #5a687a;
` as any;

export const DesktopTableLinkText = styled.a`
  width: 169px;
  height: 24px;
  font-family: open-sans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.5px;
  text-align: right;
  text-decoration: underline;
  color: #00aeef;
  cursor: pointer;
` as any;

export const TabletContainer = styled.div`
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 0 2px 0 rgba(80, 93, 111, 0.25);
  padding: 20px;
  background-color: #ffffff;
  text-align: center;
  align-content: center;
  align-self: center;
  align-self: center;
  vertical-align: middle;
` as any;

export const TabletDataTable = styled.table`
  width: 100%;
  border-radius: 5px;
  border-collapse: separate;
  padding: 1px;
  border: solid 0.5px #b2b2b2;
  background-color: #ffffff;
  text-align: center;
  align-content: center;
  align-self: center;
  align-self: center;
  vertical-align: middle;
` as any;

export const TabletTableRow = styled.tr`` as any;

export const TabletTableHeader = styled.thead`
  width: 539px;
  height: 64px;
  border-radius: 5px;
  background-color: #003595;
` as any;

export const TabletTableHeaderCell = styled.th`
  width: 25px;
  height: 64px;
  padding: 0 5px;
  font-family: open-sans;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 1.5px;
  text-align: center;
  color: #ffffff;
  white-space: pre-line;
` as any;

export const TabletTableHeaderText = styled.text`
  width: 25px;
  font-family: open-sans;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 1.5px;
  text-align: center;
  color: #ffffff;
  white-space: pre-line;
` as any;

export const TabletTableCell = styled.td`
  height: 63px;
  font-family: open-sans;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: center;
  color: #505d6f;
  white-space: normal;
` as any;

export const TabletTableLinkText = styled.a`
  width: 91px;
  height: 63px;
  font-family: open-sans;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: center;
  text-decoration: underline;
  color: #00aeef;
  cursor: pointer;
` as any;

export const TabletTableText = styled.text`
  width: 91px;
  height: 63px;
  font-family: open-sans;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: center;
  color: #505d6f;
` as any;

export const TabletContainerTitle = styled.h3`
  font-family: open-sans-bold;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  align-content: center;
  align-self: center;
  align-items: center;
  vertical-align: middle;
  color: #505d6f;
  padding-bottom: 30px;
` as any;

export const MobileContainer = styled.div`
  width: 100%;
  height: 99%;
  margin-bottom: 3%;
  vertical-align: middle;
  text-align: center;
  align-content: center;
  align-self: center;
  box-shadow: 0px 2px 17px 2px rgba(28, 39, 76, 0.08);
  background-color: #ffffff;
  border: 0px;
  padding: 5px;
  border-radius: 5px;
` as any;

export const MobileDropDownContainer = styled.div`
  width: 90%;
  text-align: center;
  align-content: center;
  margin-left: 21px;
  margin-top: 25px;
` as any;

export const MobileDataTable = styled(Table)`
  width: 90%;
  text-align: center;
  align-content: center;
  align-self: center;
  vertical-align: middle;
  border-collapse: separate;
  padding-left: 0px;
  padding-right: 1px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-left: 1px solid #003595;
  border: 1px solid #b2b2b2;
  margin: 0px;
  margin-bottom: 20px;
  margin-left: 21px;
  border-radius: 4px;
` as any;

export const MobileDataTableHeaderCell = styled.td`
  width: 84px;
  text-align: center;
  align-content: center;
  align-self: center;
  vertical-align: middle;
  white-space: normal;
  padding: 0px;
  background-color: #003595;
  border: solid 1px #003595;
` as any;

export const MobileDataTableCell = styled.td`
  width: 84px;
  height: 50px;
  background-color: #ffffff;
` as any;

export const MobileDataTableTitle = styled.div`
  width: 100%;
  height: 54px;
  font-family: open-sans-bold;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #505d6f;
  margin: 0px;
  padding-top: 20px;
  white-space: pre-wrap;
` as any;

export const DataTableRow = styled.tr`
  background-color: #ffffff;
  vertical-align: middle;
  text-align: center;
  padding: 10px;
` as any;

export const DataTableHeaderRow = styled.tr`
  background-color: #003595;
  border: solid 1px #003595;
  border-radius: 5px;
  width: 455px;
  height: 64px;
  text-align: center;
  vertical-align: middle;
` as any;

export const MobileDataTableHeaderRow = styled.tr`
  border-color: #003595;
  background-color: #003595;
  margin: 0px;
  padding: 0px;
  text-align: center;
  vertical-align: middle;
` as any;

export const DataTableTierHeaderText = styled(Text)`
  width: 80px;
  height: 64px;
  font-family: open-sans;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 1.5px;
  text-align: center;
  vertical-align: middle;
  color: #ffffff;
` as any;

export const MobileDataTableHeaderText = styled(Text)`
  width: 110px;
  height: 50px;
  font-family: open-sans;
  font-size: 8px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 1px;
  text-align: center;
  color: #ffffff;
` as any;

export const DataTableLinkText = styled.a`
  width: 70px;
  height: 63px;
  font-family: open-sans;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  vertical-align: middle;
  text-decoration: underline;
  color: #00aeef;
  cursor: pointer;
` as any;

export const DataTableText = styled(Text)`
  width: 70px;
  height: 63px;
  font-family: open-sans;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  vertical-align: middle;
  color: #505d6f;
  @media only screen and (max-width: 800px) {
  }
` as any;
