import * as React from 'react';
import { FabButton } from 'rsv8-components';

const ContentPromotionButtonComponent = ({ onClick, index }) => {
  return (
    <FabButton title="Promote Content" index={index} className="btn btn-success" onClick={onClick}>
      <i className="fa fa-rocket" aria-hidden="true" />
    </FabButton>
  );
};

export default ContentPromotionButtonComponent as any;
