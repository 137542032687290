import styled from 'styled-components';

export const FormContainer = styled.div`
  max-width: 600px;
  margin: 50px auto;
  padding: 20px 58px 55px;
  background-color: rgba(255, 255, 255, 0.9);
  @media screen and (max-width: 767px) {
    padding: 30px;
    width: 90%;
  }
` as any;

export const CodeInput = styled.input`
  padding: 17px;
  margin-right: 20px;
  border: 1px solid #cccccc;
  border-radius: 10px;
  font-size: 12px;
  line-height: 14px;
  flex-grow: 1;
  @media screen and (max-width: 767px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
` as any;

export const FlexWrapper = styled.div`
  display: flex;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
` as any;
