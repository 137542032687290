import * as React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'rsv8-forms';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';
import { FieldContainer, SubmitButton } from '../../../components/FormControls';
import { LoginMaxWidth } from '../../../components/LoginMaxWidth';
import { StyledFormGroup } from '../styles/index';

const CalloutBox = styled.div.attrs({
  style: (props: any) => ({
    background: props.background,
    border: props.border
  })
})`
  border: 1px solid;
  border-radius: 3px;
  font-family: helvetica;
  font-size: 13px;
  margin: 20px 0;
  padding: 10px;
` as any;

function ErrorList(props: any) {
  return (
    <ul>
      {props.passwordRules.map((item, index) => {
        return <li key={index}>{item}</li>;
      })}
    </ul>
  );
}

function ErrorListValidation(props: any) {
  return (
    <ul>
      {props.error.map((item, index) => {
        return <li key={index}>{item}</li>;
      })}
    </ul>
  );
}

interface ResetPasswordFormProps {
  resource: {
    submitButtonLabel: any;
    tooltipHeader: string;
    tooltipRequirement1: string;
    tooltipRequirement2: string;
    tooltipRequirement3: string;
    showPasswordLabel: string;
    newPasswordLabel: string;
    confirmPasswordLabel: string;
  };
  disableShowPassword: string;
  handleSubmit: any;
  error: any;
}

interface ResetPasswordFormState {
  showPassword: boolean;
  passwordValue: boolean;
  passwordConfirmationValue: string;
}

class ResetPasswordForm extends React.Component<ResetPasswordFormProps, ResetPasswordFormState> {
  constructor(props: any) {
    super(props);
    this.state = { showPassword: false, passwordValue: false, passwordConfirmationValue: '' };
  }

  togglePassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleChange = (e) => {
    this.setState({ passwordValue: e.target.value });
  };

  handleChangeConfirmation = (e) => {
    this.setState({ passwordConfirmationValue: e.target.value });
  };

  render() {
    const {
      disableShowPassword,
      resource: {
        submitButtonLabel,
        tooltipHeader,
        tooltipRequirement1,
        tooltipRequirement2,
        tooltipRequirement3,
        showPasswordLabel,
        newPasswordLabel,
        confirmPasswordLabel
      }
    } = this.props;
    const passwordRules = [tooltipRequirement1, tooltipRequirement2, tooltipRequirement3];
    return (
      <LoginMaxWidth>
        <form onSubmit={this.props.handleSubmit}>
          <CalloutBox
            background={this.props.error ? '#f2dede' : '#ebebeb'}
            border={this.props.error ? '1px solid #a94442' : '1px solid #c8c8c8'}
          >
            <p>{tooltipHeader}</p>
            {!this.props.error ? (
              <ErrorList passwordRules={passwordRules} />
            ) : (
              <ErrorListValidation error={this.props.error} />
            )}
          </CalloutBox>
          <StyledFormGroup
            controlId="formResetPasswordValidationError"
            validationState={
              this.props.error && (!this.state.passwordValue || !this.state.passwordConfirmationValue) ? 'error' : null
            }
          >
            <Field
              name="password"
              type={this.state.showPassword ? 'text' : 'password'}
              component={'bs-input'}
              label={newPasswordLabel}
              value={this.state.passwordValue}
              onChange={this.handleChange}
            />
            <Field
              name="passwordConfirmation"
              type={this.state.showPassword ? 'text' : 'password'}
              component={'bs-input'}
              value={this.state.passwordConfirmationValue}
              onChange={this.handleChangeConfirmation}
              label={confirmPasswordLabel}
            />
          </StyledFormGroup>
          {disableShowPassword === 'true' && (
            <Field
              name="showPassword"
              type="checkbox"
              component={'checkbox'}
              label={showPasswordLabel}
              onChange={this.togglePassword}
            />
          )}
          <FieldContainer>
            <SubmitButton
              value={submitButtonLabel}
              disabled={this.props.error && (!this.state.passwordValue || !this.state.passwordConfirmationValue)}
            />
          </FieldContainer>
        </form>
      </LoginMaxWidth>
    );
  }
}

const mapResourceToProps = (getResource) => ({
  resource: {
    submitButtonLabel: getResource('requestPasswordReset.submitButtonText', 'Reset Password'),
    newPasswordLabel: getResource('changePassword.newPasswordText', 'New Password'),
    confirmPasswordLabel: getResource('changePassword.confirmPasswordText', 'Confirm Password'),
    tooltipHeader: getResource('login.tooltipHeader', 'Password requirements:'),
    tooltipRequirement1: getResource('login.tooltipRequirement1', '8 characters or longer'),
    tooltipRequirement2: getResource('login.tooltipRequirement2', 'At least one number'),
    tooltipRequirement3: getResource('login.tooltipRequirement3', 'At least one uppercase letter'),
    showPasswordLabel: getResource('login.showPasswordLabelText', 'Show Password'),
    disableShowPassword: getResource('login.disableShowPassword', 'true')
  }
});

export default compose(
  withResource(mapResourceToProps),
  reduxForm({ form: 'resetPassword' })
)(ResetPasswordForm as any) as any;
