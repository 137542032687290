import * as React from 'react';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { register, withContent, withTheme } from 'xcel-react-core';
import { getCalculatedGoals } from '../../redux/actions/metric';
import { numberFormat } from 'xcel-util';

interface CalculatedGoalValueProps {
  dispatch: any,
  calculatorKey: string,
  programKey: string,
  periodStatus: any,
  goalKey: string,
  EmptyHolder:string,
}

const CalculatedGoalValue: React.SFC<CalculatedGoalValueProps> = ({dispatch, children, periodStatus, goalKey, programKey, calculatorKey,EmptyHolder, ...rest}) => {
   var temp='-';
  if(EmptyHolder) {
    temp =EmptyHolder;
   }
  const [goalValue, setgoalValue] = useState(temp);

  useEffect(() => {
    dispatch(
        getCalculatedGoals(programKey, calculatorKey, periodStatus)
    ).then(result => {
      if (result != null) {
        const value = result.data.find(dataItem => dataItem.id === goalKey);
        const formattedTotal = numberFormat(value.attributes.value);
        setgoalValue(formattedTotal);
      }
    }).catch(err => console.log("failed to fetch GoalValue for ", programKey, calculatorKey, goalKey, periodStatus));
  }, []);

  return <div {...rest}>{goalValue}</div>;
};

const mapContentToProps = (getContent) => ({
  programKey: getContent('programKey', { type: 'none', label: 'Enter the Program Key' }),
  calculatorKey: getContent('calculatorKey', { type: 'none', label: 'Enter the Calculator Key' }),
  goalKey: getContent('goalKey', { type: 'none', label: 'Enter the Goal Key' }),
  periodStatus: getContent('periodStatus', { type: 'none', label: 'Enter the Period Status' }),
  EmptyHolder: getContent('EmptyHolder', { type: 'none', label: 'Enter a number or letter to use if empty' })
});

export default register('rsv8-metrics/calculatedgoalvalue')(
  connect(),
  withContent(mapContentToProps),
  withTheme()
)(CalculatedGoalValue);
export { CalculatedGoalValue };
