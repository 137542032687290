import { ACTIVITIES_INITIAL, SHAREABLES_ACTIVITIES_INITIAL } from './constants';
import * as events from './events';

export const initialState = {
  activities: {
    state: ACTIVITIES_INITIAL,
    actionInProcess: false,
    data: []
  },
  shareablesActivities: {
    state: SHAREABLES_ACTIVITIES_INITIAL,
    data: []
  }
};
const socialReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case events.SET_ACTIVITIES:
      return {
        ...state,
        activities: {
          ...state.activities,
          ...action.payload
        }
      };
    case events.SET_SHAREABLES_ACTIVITIES:
      return {
        ...state,
        shareablesActivities: {
          ...state.shareablesActivities,
          ...action.payload
        }
      };

    default:
      return state;
  }
};

export default {
  [events.ROOT_SOCIAL]: socialReducer
};
