import { Patient, Program } from 'xcel-api-generator/dist/alcon';

export const DATA_ROOT_STATE = 'xcel-redux-orm/data';
export const ROOT_STATE = 'rsv8-alcon';
export const AUTH_USER_STATE = 'rsv8-auth/user';
export const PATIENTS_STATE = 'patients';

export const DASHBOARD_REDUCER_KEY = 'dashboard';
export const SUMMARY_REDUCER_KEY = 'summary';
export const DIRECT_DEPOSIT_REDUCER_KEY = 'directDeposit';
export const PATIENTS_REDUCER_KEY = 'rsv8-alcon/SET_PATIENTS';
export const PATIENT_REDUCER_KEY = 'rsv8-alcon/SET_PATIENT';
export const KEYSTONE_PATIENT_REDUCER_KEY = 'rsv8-alcon/SET_KEYSTONE_PATIENT';
export const ENROLLED_USER_KEY = 'setEnrolledUser';
export const ACH_SEARCH_INFORMATION = 'achSearchInformation';

export enum GoalType {
  Points = 'point',
  Sales = 'sales',
  Units = 'units',
  MarketShare = 'marketshare',
}

export type GoalContent = {
  id: string;
  goalTitle: string;
  goalTitleColor: string;
};

export enum ProgramName {
  Magnifeye = 'magnifeye',
  Platinum = 'platinum',
  OptiPort = 'optiport',
  VisionSource = 'visionsource',
  MarketShare = 'market share',
  TotalPartner = 'total partner',
}
export namespace ProgramName {
  export function match(programName: ProgramName, program: Program) {
    return !!programName && program?.name?.toLowerCase() === programName;
  }
}

export enum ProgramType {
  Point = 'point',
  Sales = 'sales',
  Units = 'units',
  MarketShare = 'marketshare',
  TotalPartner = 'totalpartner',
}
export namespace ProgramType {
  export function match(programType: ProgramType, program: Program) {
    return !!programType && program?.programKey?.toLowerCase() === programType;
  }
}

export enum ProductFieldType {
  BaseLine = 'baseline',
  Current = 'current',
  RunRate = 'runrate',
  Target = 'target',
  Custom = 'custom',
}

export type PatientsStateProps = {
  patients: Patient[];
  keystonePatients: number;
};
