import myAwardActions from '../../redux/actions/myAwards';

/**
 * FORMAT QUERY DATE
 */
const formatQueryDate = (date) => {
  let newDate = String(date).replace(/-/g, ', ');
  return newDate;
};

/**
 * CLICK APPLY
 */
export const clickApply = (props) => {
  let awardType, startDate, endDate;
  let queryFilter, queryType, queryDate;

  // TYPE
  if (props.values && props.values.awardSelectType) {
    awardType = props.values.awardSelectType;
  }

  // ATES
  if (props.values && props.values.awardDateStart) {
    startDate = props.values.awardDateStart.format('YYYY-MM-DD');
    startDate = formatQueryDate(startDate);
  }

  if (props.values && props.values.awardDateEnd) {
    endDate = props.values.awardDateEnd.format('YYYY-MM-DD');
    endDate = formatQueryDate(endDate);
  }

  // SET TYPE FILTER
  if (awardType && awardType !== 'All') {
    queryType = `applicationKey = "${awardType}"`;
  }

  // SET DATE FILTER
  if (startDate && endDate) {
    queryDate = `awardDate >= DateTime(${startDate}) && awardDate <= DateTime(${endDate})`;
  } else if (startDate) {
    queryDate = `awardDate >= DateTime(${startDate})`;
  } else if (endDate) {
    queryDate = `awardDate <= DateTime(${endDate})`;
  } else {
    // DO NOTHING
  }

  // COMBINE FILTERS?
  if (queryType && queryDate) {
    queryFilter = `${queryType} && ${queryDate}`;
  } else if (queryType && !queryDate) {
    queryFilter = queryType;
  } else if (queryDate && !queryType) {
    queryFilter = queryDate;
  } else {
    // DO NOTHING
  }
  props.dispatch(
    myAwardActions.get({
      filter: queryFilter
    })
  );
};
