import * as React from 'react';
import { connect } from 'react-redux';
import { Amount, Program } from 'xcel-api-generator/dist/alcon';
import { register, withContent, withTheme } from 'xcel-react-core';
import { withApiDataProvider } from 'xcel-redux-orm';
import { ProgramName } from '../../constants';
import { getProgram, getSummary } from '../../redux/actions';
import * as selectors from '../../redux/selectors';
import { programSelector } from '../../redux/selectors';
import MarketShareGauge from './MarketShareGauge';
import UnitsGauge from './UnitsGauge';
import YTDUnitsGauge from './YTDUnitsGauge';
import { defaultGauges } from './defaultGauges';
import * as styles from './styles/style';

const Gauge: React.FC<Props> = ({
  chartWidgetSize = 100,
  labelColor = '#505d6f',
  gaugeColor = '#fafafa',
  completedGaugeColor = '#00aeef',
  valueLabelSize = '16px',
  dialMiddleText,
  bottomLabelSize = '12px',
  labelFont = 'open-sans-bold',
  gaugeContent = defaultGauges,
  amounts,
  program,
}) => {
  if (!amounts || amounts.length === 0 || !program) {
    return <styles.NoDataSpinner className="fa fa-circle-o-notch fa-spin" />;
  }
  return (
    <styles.ComponentWrapper className="row">
      {(ProgramName.match(ProgramName.OptiPort, program) || ProgramName.match(ProgramName.VisionSource, program)) && (
        <YTDUnitsGauge
          labelColor={labelColor}
          gaugeColor={gaugeColor}
          completedGaugeColor={completedGaugeColor}
          valueLabelSize={valueLabelSize}
          bottomLabelSize={bottomLabelSize}
          dialMiddleText={dialMiddleText}
          chartWidgetSize={chartWidgetSize}
          labelFont={labelFont}
          gaugeContent={gaugeContent}
        />
      )}

      {(ProgramName.match(ProgramName.Magnifeye, program) || ProgramName.match(ProgramName.Platinum, program)) && (
        <UnitsGauge
          labelColor={labelColor}
          gaugeColor={gaugeColor}
          completedGaugeColor={completedGaugeColor}
          valueLabelSize={valueLabelSize}
          bottomLabelSize={bottomLabelSize}
          dialMiddleText={dialMiddleText}
          chartWidgetSize={chartWidgetSize}
          labelFont={labelFont}
          gaugeContent={gaugeContent}
        />
      )}

      {(ProgramName.match(ProgramName.MarketShare, program) || ProgramName.match(ProgramName.TotalPartner, program)) && (
        <MarketShareGauge
          labelColor={labelColor}
          gaugeColor={gaugeColor}
          completedGaugeColor={completedGaugeColor}
          valueLabelSize={valueLabelSize}
          bottomLabelSize={bottomLabelSize}
          dialMiddleText={dialMiddleText}
          chartWidgetSize={chartWidgetSize}
          labelFont={labelFont}
          gaugeContent={gaugeContent}
        />
      )}
    </styles.ComponentWrapper>
  );
};

/**
 * Content that the admin can enter to change this component
 * @param getContent
 */
const mapContentToProps = (getContent) => ({
  labelColor: getContent('labelColor', { type: 'string', label: 'label in center color' }),
  gaugeColor: getContent('gaugeColor', { type: 'string', label: 'area to be completed on gauge' }),
  completedGaugeColor: getContent('gaugeColor', { type: 'string', label: 'color of completed gauge' }),
  valueLabelSize: getContent('valueLabelSize', { type: 'string', label: 'size for the text' }),
  bottomLabelSize: getContent('bottomLabelSize', { type: 'string', label: 'size for the text' }),
  dialMiddleText: getContent('dialMiddleText', { type: 'html', label: 'Dial middle scale (points, value etc)' }),
  valuePrefix: getContent('valuePrefix', { type: 'string', label: 'Value prefix' }),
  valueSuffix: getContent('valueSuffix', { type: 'string', label: 'Value suffix' }),
  chartWidgetSize: getContent('widget size', { type: 'string', label: 'Chart Size (value in px)' }),
  labelFont: getContent('labelFont', { type: 'string', label: 'labelfont' }),
  gaugeContent: getContent('gaugeContent', {
    type: 'array',
    schema: [
      { model: 'gaugeid', label: 'gauge ids' },
      { model: 'gaugetitle', label: 'goal title' },
    ],
  }),
});

export const mapStateToProps = (state) => ({
  amounts: selectors.amountSelector.selectMany(state),
  program: programSelector.selectMany(state)[0],
});

interface StateProps {
  amounts: Amount[];
  program?: Program;
}
interface ContentProps {
  labelColor: string;
  gaugeColor: string;
  completedGaugeColor: string;
  valueLabelSize: string;
  bottomLabelSize: string;
  dialMiddleText: string;
  chartWidgetSize: number;
  labelFont: string;
  valuePrefix: string;
  valueSuffix: string;
  gaugeContent: Array<any>;
}
type Props = React.HTMLAttributes<any> & StateProps & ContentProps;

export default register('alcon-data-vis/Gauge')(
  connect(mapStateToProps),
  withApiDataProvider(getSummary, selectors.amountSelector),
  withApiDataProvider(getProgram, selectors.programSelector),
  withTheme(),
  withContent(mapContentToProps)
)(Gauge);
