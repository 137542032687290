import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { cultureSelector } from 'rsv8-client';
import { withWizard } from 'rsv8-wizard';
import { register, withResource } from 'xcel-react-core';
import { AwardSelection } from '../../components/index';
import { teamNominationActions } from '../../redux/actions/index';
import { nominationSelectors, teamNominationSelectors } from '../../redux/selectors/index';
import { Resources } from '../../types';

const { selectAward, getAwardDetails, getAwards } = teamNominationActions;

const mapState = (state) => {
  return {
    awards: teamNominationSelectors.getAwards(state),
    nominee: null,
    selectedAward: teamNominationSelectors.getCurrentAwardId(state),
    requestData: nominationSelectors.getNomineeAwardsRequest(state),
    cultureId: cultureSelector.getActiveCultureId(state)
  };
};

const mapDispatch = (dispatch) => ({
  actions: bindActionCreators({ getAwards, selectAward, getAwardDetails }, dispatch)
});

const mapResource = (getResource) => ({
  confirmAwardChangeText: getResource(
    'teamNomination.confirmAwardChangeText',
    'Changing award at this point will clear data from later steps.'
  )
});

const compose = register('rsv8-nomination/TeamNominationAwardSelection');

export default compose(
  connect(
    mapState,
    mapDispatch
  ),
  withResource(mapResource),
  withWizard()
)(AwardSelection) as React.SFC<{ awards: Array<Resources.Award> }>;
