import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Link, Text } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import { CheckoutModuleHeading } from '../styles';
import {
  RemoveCartButton,
  ReviewItem,
  ReviewItemImage,
  ReviewItemInfo,
  ReviewItemLabel,
  ReviewItemManufacturer,
  ReviewItemProduct,
  ReviewItemsContainer,
  ReviewItemShip,
  ReviewItemTable,
  ReviewItemValue
  } from './styles';
export interface ItemReviewProps {
  formCount: number;
  itemReviewHeading: string;
  optionsLabel: string;
  pointsAbbrevLabel: string;
  pointValueLabel: string;
  products: any;
  quantityLabel: string;
  removeFromCart: (product: any) => any;
  removeLabel: string;
  removeDisabled?: boolean;
  confirmation?: boolean;
}

class ItemReview extends React.Component<ItemReviewProps, any> {
  render() {
    const {
      formCount,
      itemReviewHeading,
      optionsLabel,
      pointsAbbrevLabel,
      pointValueLabel,
      products,
      quantityLabel,
      removeFromCart,
      removeLabel,
      removeDisabled,
      confirmation
    } = this.props;

    if (products && products.length < 1) {
      return <Redirect to="/catalog/cart" />;
    }

    return (
      <div>
        <CheckoutModuleHeading>{formCount && `${formCount}. ${itemReviewHeading}`}</CheckoutModuleHeading>
        <ReviewItemsContainer>
          {products.map((product, index) => (
            <ReviewItem key={index} confirmation={confirmation}>
              <ReviewItemProduct>
                {/* Weird logic for demo. Will improve after */}
                <ReviewItemImage src={product.imageLg} />
                <ReviewItemInfo>
                  <ReviewItemManufacturer to={`/catalog/search?filterBrands=${product.manufacturerId}`}>
                    {product.manufacturer}
                  </ReviewItemManufacturer>
                  <Link
                    themeVariation="text-link"
                    to={`/catalog/product/${encodeURIComponent(product.encodedProductId || btoa(product.productId))}`}
                    dangerouslySetInnerHTML={{ __html: product.name || product.productName }}
                  />
                </ReviewItemInfo>
              </ReviewItemProduct>
              <ReviewItemTable>
                {product.productOptionName && (
                  <React.Fragment>
                    <ReviewItemLabel>
                      <Text themeVariation="secondary-text-bold">{optionsLabel}</Text>
                    </ReviewItemLabel>
                    <ReviewItemValue>
                      <Text themeVariation="secondary-text">{product.productOptionName}</Text>
                    </ReviewItemValue>
                  </React.Fragment>
                )}

                <ReviewItemLabel>
                  <Text themeVariation="secondary-text-bold">{quantityLabel}</Text>
                </ReviewItemLabel>
                <ReviewItemValue>
                  <Text themeVariation="secondary-text">{product.quantity}</Text>
                </ReviewItemValue>

                <ReviewItemLabel>
                  <Text themeVariation="secondary-text-bold">{pointValueLabel}</Text>
                </ReviewItemLabel>
                <ReviewItemValue>
                  <Text themeVariation="secondary-text">{`${product.totalPoints ||
                    product.pointValue} ${pointsAbbrevLabel}`}</Text>
                </ReviewItemValue>
              </ReviewItemTable>
              <ReviewItemShip>
                <Text themeVariation="legal-text">{product.shipMethod}</Text>
              </ReviewItemShip>
              {removeFromCart && (
                <RemoveCartButton
                  themeVariation="primary-link"
                  onClick={removeFromCart(product)}
                  disabled={removeDisabled ? true : false}
                >
                  {removeLabel}
                </RemoveCartButton>
              )}
            </ReviewItem>
          ))}
        </ReviewItemsContainer>
      </div>
    );
  }
}

const mapResourceToProps = (getResource) => ({
  itemReviewHeading: getResource('catalog.checkout.itemReviewHeading', 'Review Items'),
  optionsLabel: getResource('catalog.checkout.productOptionsLabel', 'Options'),
  pointsAbbrevLabel: getResource('catalog.checkout.pointsAbbrevLabel', 'pts.'),
  pointValueLabel: getResource('catalog.checkout.pointValueLabel', 'Point Value'),
  quantityLabel: getResource('catalog.checkout.productQuantityLabel', 'Quantity'),
  removeLabel: getResource('catalog.checkout.removeProductLabel', 'Remove')
});

export default withResource(mapResourceToProps)(ItemReview);
