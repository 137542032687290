import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { compose } from 'redux';
import {
  HeaderDecoration,
  HeaderOne,
  Image,
  Link
  } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import { LayoutContainer } from '../../../components/Layout';

const ChangePasswordConfirmation = ({ resource: { confirmationHeaderText, goBackHome, goBackHomeUrl } }) => (
  <div>
    <HeaderDecoration />
    <Col xs={22} xsOffset={1} lg={22} lgOffset={1} md={22} mdOffset={1}>
      <Image themeVariation="primary-company-logo1" />
    </Col>
    <LayoutContainer>
      <Row>
        <Col xs={22} xsOffset={1} lg={8} lgOffset={8} md={7} mdOffset={8}>
          <Image themeVariation="icon-confirmation" />
        </Col>
      </Row>
      <Row>
        <Col xs={22} xsOffset={1} lg={12} lgOffset={6} md={22}>
          <HeaderOne textalign="center">{confirmationHeaderText}</HeaderOne>
        </Col>
      </Row>
      <Row>
        <Col xs={10} xsOffset={7} lg={8} lgOffset={8} md={7} mdOffset={8}>
          <Link textalign="center" width="100%" href={goBackHomeUrl}>
            {goBackHome}
          </Link>
        </Col>
      </Row>
    </LayoutContainer>
  </div>
);

const mapResourceToProps = (getResource) => ({
  resource: {
    confirmationHeaderText: getResource(
      'changePasswordConfirmation.confirmationHeaderText',
      'You’ve successfully changed your password'
    ),
    goBackHome: getResource('changePasswordConfirmation.goBackHome', 'Back Home'),
    goBackHomeUrl: getResource('changePasswordConfirmation.goBackHomeUrl', 'http://fd8.rewardstation-test.net/')
  }
});

export default compose(withResource(mapResourceToProps))(ChangePasswordConfirmation);
