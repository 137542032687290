import oauthPath from "../app/paths/OAuth";

export const IDENTITY_CONFIG = {
  redirect_uri: window.location.origin + oauthPath.loginRedirectCallback,
  popupWindowFeatures:
    "menubar=yes,location=yes,toolbar=yes,width=1200,height=800,left=100,top=100;resizable=yes",
  response_type: "code",
  post_logout_redirect_uri:
    window.location.origin + oauthPath.logoutRedirectCallback,
  silent_redirect_uri: window.location.origin + oauthPath.renewCallback
};