import { singleContentPlugin } from 'rsv8-cms';
import { authApiProvider } from 'rsv8-auth';
import { cacheService } from 'xcel-cache-service';
import { registry } from 'xcel-react-core';
import { setValue } from 'xcel-util';

const cmsAdminService = authApiProvider({ route: 'cms', isAdmin: true });
const getThemeComponents = () => {
  let { theme, components } = registry.get();
  const themeComponents = Object.keys(theme)
    .sort()
    .reduce((obj: any, current: string) => {
      const namespace = current.replace(/\//g, '.');
      return setValue(obj, namespace, { name: current, namespace, component: components[current], ...theme[current] });
    }, {});
  return themeComponents;
};
const pseudoSelectors = [
  { label: ':hover', value: ':hover' },
  { label: ':focus', value: ':focus' },
  { label: ':active', value: ':active' },
  { label: ':disabled', value: ':disabled' }
];
const mediaQueries = [
  { label: 'xs', value: '@media(max-width:767px)' },
  { label: 'sm', value: '@media(min-width:768px)' },
  { label: 'md', value: '@media(min-width:992px)' },
  { label: 'lg', value: '@media(min-width:1366px)' }
];

const themePlugin = singleContentPlugin('Theme', 0);

export { cacheService, cmsAdminService, getThemeComponents, pseudoSelectors, mediaQueries, themePlugin };
