import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Loader, Text, toast } from 'rsv8-components';
import { Field, reduxForm } from 'rsv8-forms';
import { Amount, Dashboard, RebateHistoryItem } from 'xcel-api-generator/dist/alcon';
import { assetService } from 'xcel-asset-service';
import { register, withResource, withTheme } from 'xcel-react-core';
import { withApiDataProvider } from 'xcel-redux-orm';
import { convertToPercent, getCurrencyValue, getGoalValues, getMarketSharePercent, getRoundedValue, getValueOrZero } from '../../../utils';
import { createProductCalculate, createProductSave, getRebate, getSummary } from '../../redux/actions';
import { dashboardSelector, marketShareAmountSelector, performanceAmountSelector, rebateSelector } from '../../redux/selectors';
import { OverlayPopover } from '../Overlay';
import {
  CalculateButton,
  CalculatorForm,
  CalculatorWrapper,
  HowRetabesNote,
  InfoGraphic,
  InputLabel,
  InputLabelWide,
  InputValueContainer,
  MarketShareRebate,
  PlusEquals,
  Result,
  SaveForecast,
  SaveForecastTitle,
  Timeline,
  Title,
  ValueContainer,
  ValueWrapper,
  WrapperPadding,
  YearDateRebate,
} from './styles';

const MarketShareCalculator: React.FC<Props> = ({
  actions,
  handleSubmit,

  dashboard,
  baseline,
  current,
  target,
  rebates,

  calculatorTitle,
  baseMarketShareLabel,
  currentMarketShareLabel,
  currentSalesLabel,
  shareGoalLabel,
  salesGoalLabel,
  totalEstimatedRebateNoteNotEligible,
  totalEstimatedRebateNote,
  totalEstimatedBaseRebateLabel,
  totalEstimatedGrowthRebateLabel,
  calculateButtonLabel,
  saveForecastTitle,
  saveForecastDescription,
  calculateForecastToastMessage,
  calculateForecastFailedMessage,
  saveForecastToastMessage,
  saveForecastFailedMessage,
  saveButtonLabel,
  howRebateIsCalculatedTitle,
  howRebateIsCalculatedNote,
  baseMarketRebateTitle,
  baseMarketRebateInfo,
  growthMarketRebateTitle,
  growthMarketRebateInfo,
  baseMarketRebateDescription,
  growthMarketRebateDescription,
  yearToDateRebateTitle,
}) => {
  if (!dashboard || !baseline || !current || !target) return <Loader />;

  const handleCalculate = async (values) => {
    return actions
      .createProductCalculate({
        summary: {
          target: getValueOrZero(values.salesGoal),
          marketShareGoal: getValueOrZero(values.shareGoal) / 100,
        },
        details: [],
      })
      .then(() => {
        toast({
          template: 'CatalogBody',
          productImage: assetService.getImage('icons/blue-calculator.png'),
          productName: '',
          productManufacturer: 'Re-Calculate Rebate',
          actionText: calculateForecastToastMessage,
        });
      })
      .catch((error) => {
        console.log(error);
        toast({
          template: 'CatalogBody',
          productImage: '',
          productName: '',
          productManufacturer: '',
          actionText: calculateForecastFailedMessage,
        });
      });
  };

  const handleSave = async () => {
    return actions
      .createProductSave({
        summary: {
          target: target.salesGoal,
          marketShareGoal: target.shareGoal,
        },
        details: [],
      })
      .then(() => {
        toast({
          template: 'CatalogBody',
          productImage: assetService.getImage('icons/save-calculation.png'),
          productName: '',
          productManufacturer: 'Save Forecast',
          actionText: saveForecastToastMessage,
        });
      })
      .catch((error) => {
        console.log(error);
        toast({
          template: 'CatalogBody',
          productImage: '',
          productName: '',
          productManufacturer: '',
          actionText: saveForecastFailedMessage,
        });
      });
  };

  const baseRebate = React.useMemo(() => Number(target?.baseRebate) || 0, [target]);
  const growthRebate = React.useMemo(() => Number(target?.growthRebate) || 0, [target]);
  const currentRebate = React.useMemo(() => Math.max(baseRebate, growthRebate || 0), [baseRebate, growthRebate]);

  const estimatedRebates = React.useMemo(() => {
    if (!rebates || !Array.isArray(rebates)) {
      return [];
    }

    const res = rebates
      ?.filter((rebate) => !!rebate && rebate.quarter.includes(dashboard.currentTimePeriod.year))
      .filter((rebate) => rebate.quarter < dashboard.currentTimePeriod.name)
      .sort((a, b) => {
        return a.quarter > b.quarter ? 1 : -1;
      })
      .map((rebate) => {
        return { amount: rebate.amount, status: 'EARNED', estimated: false };
      });

    if (!!currentRebate) {
      res[res.length] = { amount: currentRebate, status: 'ESTIMATED', estimated: true };
    }
    return res;
  }, [rebates, currentRebate, dashboard.currentTimePeriod.name]);

  const totalRebate = React.useMemo(() => {
    if (!estimatedRebates) return 0;

    return estimatedRebates
      .map((r) => r.amount)
      .reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      }, 0);
  }, [estimatedRebates]);

  return (
    <CalculatorWrapper>
      <WrapperPadding>
        <CalculatorForm onSubmit={handleSubmit(handleCalculate)}>
          <Title>{calculatorTitle}</Title>

          <ValueWrapper>
            <div className="base-market-share">
              <InputLabel themeVariation="primary-text-bold">{baseMarketShareLabel}</InputLabel>
              <ValueContainer>{getMarketSharePercent(baseline, '-')}</ValueContainer>
            </div>
            <div className="current-market-share">
              <InputLabel themeVariation="primary-text-bold">
                {dashboard.currentTimePeriod.name}
                <br />
                {currentMarketShareLabel}
              </InputLabel>
              <ValueContainer>{getMarketSharePercent(current, '-')}</ValueContainer>
            </div>
            <div className="current-sales">
              <InputLabel themeVariation="primary-text-bold">
                {dashboard.currentTimePeriod.name}
                <br />
                {currentSalesLabel}
              </InputLabel>
              <ValueContainer>{getCurrencyValue(current.sales)}</ValueContainer>
            </div>
          </ValueWrapper>

          <ValueWrapper>
            <div className="share-goal">
              <InputLabel themeVariation="primary-text-bold">
                {dashboard.currentTimePeriod.name}
                <br />
                {shareGoalLabel}
              </InputLabel>
              <InputValueContainer>
                <Field name="shareGoal" component="bs-input" type="number" />
              </InputValueContainer>
            </div>
            <div className="sales-goal">
              <InputLabel themeVariation="primary-text-bold">
                {dashboard.currentTimePeriod.name}
                <br />
                {salesGoalLabel}
              </InputLabel>
              <InputValueContainer>
                <Field name="salesGoal" component="bs-input" type="number" />
              </InputValueContainer>
            </div>
          </ValueWrapper>

          <CalculateButton onClick={handleSubmit(handleCalculate)} themeVariation="secondary">
            {calculateButtonLabel}
          </CalculateButton>

          {baseRebate == 0 && growthRebate == 0 && <Text>{totalEstimatedRebateNoteNotEligible}</Text>}

          {(baseRebate != 0 || growthRebate != 0) && (
            <>
              <Text>{totalEstimatedRebateNote}</Text>

              <div className="total-estimated-quarterly-rebate">
                {growthRebate <= baseRebate && (
                  <InputLabelWide themeVariation="primary-text-bold">{totalEstimatedBaseRebateLabel}</InputLabelWide>
                )}
                {growthRebate > baseRebate && (
                  <InputLabelWide themeVariation="primary-text-bold">{totalEstimatedGrowthRebateLabel}</InputLabelWide>
                )}
                <Result themeVariation="primary-text-bold">{(currentRebate && getCurrencyValue(currentRebate, 2)) || '-'}</Result>
              </div>
            </>
          )}
        </CalculatorForm>

        <Title>{howRebateIsCalculatedTitle}</Title>

        <HowRetabesNote themeVariation="default" content={{ content: howRebateIsCalculatedNote }} />

        <InfoGraphic themeVariation="calculation-info-graphic">
          <MarketShareRebate className={baseRebate == 0 || growthRebate > baseRebate ? 'base-market-share' : 'growth-market-share'}>
            <div className="title">
              {baseMarketRebateTitle}
              <OverlayPopover id="base-market-share-popover">{baseMarketRebateInfo}</OverlayPopover>
            </div>
            <div className="description">{baseMarketRebateDescription}</div>
            <div className="calculation">
              {((target.baseRebatePercent != null && getRoundedValue(convertToPercent(target.baseRebatePercent), 0)) || '') + '%'} x{' '}
              {(target.sales != null && getCurrencyValue(target.sales, 2)) || '$'} = <span>{getCurrencyValue(baseRebate, 2) || '$'}</span>
            </div>
          </MarketShareRebate>

          <PlusEquals>— OR —</PlusEquals>

          <MarketShareRebate className={growthRebate == 0 || growthRebate <= baseRebate ? 'base-market-share' : 'growth-market-share'}>
            <div className="title">
              {growthMarketRebateTitle}
              <OverlayPopover id="growth-market-share-popover">{growthMarketRebateInfo}</OverlayPopover>
            </div>
            <div className="description">{growthMarketRebateDescription}</div>
            <div className="calculation">
              {((target.growthRebatePercent != null && getRoundedValue(convertToPercent(target.growthRebatePercent), 0)) || '') + '%'} x{' '}
              {(target.salesGrowth != null && getCurrencyValue(target.salesGrowth, 2)) || '$'} ={' '}
              <span>{getCurrencyValue(growthRebate, 2) || '$'}</span>
            </div>
          </MarketShareRebate>
          <PlusEquals>
            {dashboard.currentTimePeriod && dashboard.currentTimePeriod.year && (
              <span>Your {dashboard.currentTimePeriod.year} Rebates</span>
            )}
          </PlusEquals>
          <YearDateRebate>
            <div className="title">{yearToDateRebateTitle}</div>
            <div className="summary">{(totalRebate && getCurrencyValue(totalRebate, 2)) || '-'}</div>
            <Timeline themeVariation="calculator-timeline" />
            <div className="quarters">
              {Array.from(Array(4).keys()).map((i) => (
                <div key={'quarter-' + i} className="quarter">
                  <div className="name">Q{i + 1}</div>
                  <div className={estimatedRebates[i] && estimatedRebates[i].estimated ? 'calculation estimated' : 'calculation'}>
                    {estimatedRebates[i] && getCurrencyValue(estimatedRebates[i].amount, 2)}
                  </div>
                  <div className="status">{estimatedRebates[i] && estimatedRebates[i].status}</div>
                </div>
              ))}
            </div>
          </YearDateRebate>
        </InfoGraphic>
      </WrapperPadding>
      <SaveForecast>
        <SaveForecastTitle>{saveForecastTitle}</SaveForecastTitle>
        <Text themeVariation="large-text">{saveForecastDescription}</Text>
        <Button onClick={handleSave}>{saveButtonLabel}</Button>
      </SaveForecast>
    </CalculatorWrapper>
  );
};

const mapStateToProps = (state) => ({
  dashboard: dashboardSelector.selectMany(state)[0],
  baseline: performanceAmountSelector(state, 'Baseline'),
  current: performanceAmountSelector(state, 'Current'),
  target: performanceAmountSelector(state, 'Target'),
  rebates: rebateSelector.selectMany(state),
  initialValues: getGoalValues(marketShareAmountSelector.selectOne(state, 'Target')),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ createProductCalculate, createProductSave }, dispatch),
});

const mapResourceToProps = (getResource) => ({
  calculatorTitle: getResource('marketShareCalculator.title', 'Enter Goal Metrics to Calculate Your Estimated Quarterly Rebate'),

  baseMarketShareLabel: getResource('marketShareCalculator.baseMarketShareLabel', 'Base Market Share'),
  currentMarketShareLabel: getResource('marketShareCalculator.currentMarketShareLabel', 'Current Market Share'),
  currentSalesLabel: getResource('marketShareCalculator.currentSalesLabel', 'Current Sales'),
  shareGoalLabel: getResource('marketShareCalculator.shareGoalLabel', 'Share Goal'),
  salesGoalLabel: getResource('marketShareCalculator.salesGoalLabel', 'Sales Goal'),
  totalEstimatedRebateNoteNotEligible: getResource(
    'marketShareCalculator.totalEstimatedRebateNoteNotEligible',
    'Based on your current metrics and the goal metrics you entered you are not eligible to receive a rebate'
  ),
  totalEstimatedRebateNote: getResource(
    'marketShareCalculator.totalEstimatedRebateNote',
    'Based on your current metrics and the goal metrics you have entered you would eligible to receive a'
  ),
  totalEstimatedBaseRebateLabel: getResource('marketShareCalculator.totalEstimatedBaseRebateLabel', 'BASE MARKET SHARE REBATE OF'),
  totalEstimatedGrowthRebateLabel: getResource('marketShareCalculator.totalEstimatedGrowthRebateLabel', 'GROWTH MARKET SHARE REBATE OF'),

  calculateButtonLabel: getResource('marketShareCalculator.calculateButtonLabel', 'Calculate'),
  saveForecastTitle: getResource('marketShareCalculator.saveForecastTitle', 'Save Your Forecast'),
  saveForecastDescription: getResource(
    'marketShareCalculator.saveForecastDescription',
    'Save your forecast to populate the dashboard or review in the calculator at a later date.'
  ),
  calculateForecastToastMessage: getResource(
    'marketShareCalculator.calculateForecastToastMessage',
    'Re-calculation successful! Remember to save your forecasts!'
  ),
  calculateForecastFailedMessage: getResource(
    'marketShareCalculator.calculateForecastFailedMessage',
    'There was an error completing your request. Please contact the portal support team for assistance.'
  ),

  saveForecastToastMessage: getResource('marketShareCalculator.saveForecastToastMessage', 'Forecast save successful!'),
  saveForecastFailedMessage: getResource(
    'marketShareCalculator.saveForecastFailedMessage',
    'There was an error completing your request. Please contact the portal support team for assistance.'
  ),

  saveButtonLabel: getResource('marketShareCalculator.saveButtonLabel', 'SAVE FORECAST'),

  howRebateIsCalculatedTitle: getResource(
    'marketShareCalculator.howRebateIsCalculatedTitle',
    'How Your Estimated Quarterly Rebate is Calculated'
  ),
  howRebateIsCalculatedNote: getResource(
    'marketShareCalculator.howRebateIsCalculatedNote',
    'Practices are eligible to earn a <b>Base Market Share Rebate</b> or a <b>Growth Market Share Rebate</b>. At the end of the quarter, your practice will earn whichever rebate is higher. See below for an explanation of each calculation based on the numbers you entered above.'
  ),
  baseMarketRebateTitle: getResource('marketShareCalculator.baseMarketRebateTitle', 'BASE MARKET SHARE REBATE'),
  baseMarketRebateInfo: getResource(
    'marketShareCalculator.baseMarketRebateInfo',
    'This Base Market Share Rebate is determined by the Share Goal you entered above and your sales last year for the corresponding current quarter. Sales volume this year has no impact on this type of rebate.'
  ),
  growthMarketRebateTitle: getResource('marketShareCalculator.growthMarketRebateTitle', 'GROWTH MARKET SHARE REBATE'),
  growthMarketRebateInfo: getResource(
    'marketShareCalculator.growthMarketRebateInfo',
    "Your Growth Rebate is determined by your share growth and your sales growth. Once you achieve your share growth goal, you'll want to focus on growing sales volume to maximize this portion of your rebate."
  ),
  baseMarketRebateDescription: getResource(
    'marketShareCalculator.baseMarketRebateDescription',
    'BASE MARKET SHARE REBATE PERCENTAGE X CURRENT SALES'
  ),
  growthMarketRebateDescription: getResource(
    'marketShareCalculator.growthMarketRebateDescription',
    'GROWTH MARKET SHARE REBATE PERCENTAGE X GROWTH DOLLARS'
  ),
  yearToDateRebateTitle: getResource('marketShareCalculator.yearToDateRebateTitle', 'ESTIMATED YEAR-TO-DATE (YTD) REBATE'),
});

interface StateProps {
  dashboard: Dashboard;
  baseline: Amount;
  current: Amount;
  target: Amount;
  rebates: RebateHistoryItem;
}
interface DispatchProps {
  actions: {
    createProductCalculate: Function;
    createProductSave: Function;
  };
  handleSubmit: any;
}
interface ResourceProps {
  calculatorTitle: string;
  baseMarketShareLabel: string;
  currentMarketShareLabel: string;
  currentSalesLabel: string;
  shareGoalLabel: string;
  salesGoalLabel: string;
  totalEstimatedRebateNoteNotEligible: string;
  totalEstimatedRebateNote: string;
  totalEstimatedBaseRebateLabel: string;
  totalEstimatedGrowthRebateLabel: string;
  calculateButtonLabel: string;
  saveForecastTitle: string;
  saveForecastDescription: string;
  calculateForecastToastMessage: string;
  calculateForecastFailedMessage: string;
  saveForecastToastMessage: string;
  saveForecastFailedMessage: string;
  saveButtonLabel: string;
  howRebateIsCalculatedTitle: string;
  howRebateIsCalculatedNote: any;
  stepByStepLink: string;
  baseMarketRebateTitle: string;
  baseMarketRebateInfo: string;
  growthMarketRebateTitle: string;
  growthMarketRebateInfo: string;
  baseMarketRebateDescription: string;
  growthMarketRebateDescription: string;
  yearToDateRebateTitle: string;
}

type Props = StateProps & DispatchProps & ResourceProps;

export default register('alcon-calculator/MarketShareCalculator')(
  connect(mapStateToProps, mapDispatchToProps),
  withApiDataProvider(getSummary, dashboardSelector),
  withApiDataProvider(getRebate, rebateSelector),
  withResource(mapResourceToProps),
  withTheme(),
  reduxForm({
    form: 'UpdateGoals',
    enableReinitialize: true,
  })
)(MarketShareCalculator);
