import * as React from 'react';
import { Image } from 'rsv8-components';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';

const NoResultsContainer = styled.div`
  background-color: #ffffff;
  border: 1px solid #d4d4d4;
  padding: 100px 25px;
  margin-top: 50px;
  font-size: 16px;
  color: #7c8180;
  font-weight: bold;
  text-align: center;
  border-radius: 3px;
  @media (max-width: 767px) {
    margin-top: 30px;
    padding: 50px 25px;
    h1 {
      font-size: 19px;
    }
  }
`;

const SorryText = styled.h1`
  margin: 0px;
`;

interface Props {
  sorryTextA: string;
  sorryTextB: string;
}

const PlaceholderNoResults: React.SFC<Props> = ({ sorryTextA, sorryTextB }) => (
  <NoResultsContainer>
    <Image themeVariation="icon-oops" />
    <SorryText>{sorryTextA}</SorryText>
    <SorryText>{sorryTextB}</SorryText>
  </NoResultsContainer>
);

const mapResourceToProps = (getResource): Props => ({
  sorryTextA: getResource('placeHolderNoResult.sorryTextA', 'Sorry, no results found.'),
  sorryTextB: getResource('placeHolderNoResult.sorryTextB', 'Try broadening your filter criteria')
});

export default withResource(mapResourceToProps)(PlaceholderNoResults);
