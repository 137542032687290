import { ProductListSummaryModel } from 'xcel-api-generator/dist/catalog';
import { createTypeSelector, getRequest } from 'xcel-redux-orm';
import {
  FAVORITE_STATE,
  GET_USER_FAVORITES_ENDPOINT,
  PRODUCT_LIST_JSON_TYPE,
  ROOT_STATE
  } from '../../constants';

const favoriteSelector = createTypeSelector<ProductListSummaryModel>(PRODUCT_LIST_JSON_TYPE);
const getFavoriteList = (state) => {
  const favoriteProducts =
    state &&
    state[ROOT_STATE] &&
    state[ROOT_STATE][FAVORITE_STATE] &&
    state[ROOT_STATE][FAVORITE_STATE].favoriteProducts;
  const currentFavorites = favoriteProducts && favoriteProducts.currentFavorites;

  return currentFavorites || [];
};
const getFavoritesLoaded = (state) =>
  state[ROOT_STATE] && state[ROOT_STATE][FAVORITE_STATE].favoriteProducts.favoritesLoaded;

const getFavoriteRequest = (state) => getRequest(state, GET_USER_FAVORITES_ENDPOINT);
const getPostFavoriteRequest = (state) => getRequest(state, 'createProductlist');

export { favoriteSelector, getFavoriteList, getFavoriteRequest, getFavoritesLoaded, getPostFavoriteRequest };
