import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { AuthLinkBehavior } from 'rsv8-auth';
import { Card, Link, Text } from 'rsv8-components';
import styled from 'styled-components';
import { register, withContent } from 'xcel-react-core';
import { BarChart } from '../../components/BarChart';
import { Legend } from '../../components/Legend';

const Container = styled.div`
  background: #e4f1f3;
  height: 425px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
// interface Props {}
const Heading = styled(Text)`
  font-size: 21px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.24;
  letter-spacing: normal;
  color: #394568;
  align-self: flex-start;
`;
const KeyPerformanceIndicators: React.FunctionComponent = (props) => {
  return (
    <Card>
      <Container>
        <Heading>Key Performance Indicators - 2019</Heading>
        <div style={{ display: 'flex' }}>
          <BarChart id="barchart1" chartData={[29, 31, 30, 40]} title="Employees Rewarded" bgColor="#cbe3e9" />
          <BarChart id="barchart2" chartData={[61, 69, 64, 90]} title="Employees Logged In" bgColor="#fad37c" />
          <BarChart id="barchart3" chartData={[48, 51, 42, 60]} title="Submitted Nominations" bgColor="#9ccad9" />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              justifyContent: 'center',
              margin: '10px 0 5px 0'
            }}
          >
            <div style={{ padding: '50px 50px 0 50px', height: '100%', display: 'flex', flexDirection: 'column' }}>
              <Legend
                id="barchartlegend"
                colorRange={['#158ac2', '#fff', '#5fbbb6', '#073da3']}
                legendVals={['Your Team Stats', 'Your Country Stats', 'Company Stats', 'Company Goal']}
              />
              <AuthLinkBehavior>
                <Link
                  to="https://fd6.rewardstation.net/sso/oauthentry.aspx?target=reports/default.aspx&auth="
                  themeVariation="secondary-alternate-button"
                  style={{ width: '100%', alignSelf: 'center', textAlign: 'center', marginTop: 'auto' }}
                >
                  View More Reporting
                </Link>
              </AuthLinkBehavior>
            </div>
          </div>
        </div>
      </Container>
    </Card>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({}, dispatch)
});

export default register('rsv8-data-vis/KeyPerformanceIndicators')(
  withContent(),
  connect<any, any>(
    mapStateToProps,
    mapDispatchToProps
  )
)(KeyPerformanceIndicators);
