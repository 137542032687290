import { ensureArray } from 'xcel-util';
import { TeamNomination } from '../../../../types';
import * as events from '../../../events';

const setAllTeamMemberValues = <K extends keyof TeamNomination.TeamMember>(key: K) => (
  value: any,
  member: string | string[]
) => ({
  type: events.TRANSFORM_TEAM_MEMBERS,
  payload: { key: key, value, member: member != null ? ensureArray(member) : undefined }
});

export const setTeamMemberCustomFields = (fields: Record<string, any>, teamMemberId?: string) => ({
  type: events.SELECT_TEAM_MEMBER_CUSTOM_FIELDS,
  payload: { id: teamMemberId, fields: Object.keys(fields).map((k) => ({ id: k, values: fields[k] })) }
});

export const setTeamMemberRole = setAllTeamMemberValues('roleId');
export const setTeamMemberAward = setAllTeamMemberValues('awardValue');
