import * as React from "react";
import { connect } from "react-redux";
import OAuthService from "../../services/OAuthService";
import { oAuthTokenRefresh, openModal } from "../redux/auth.actions";
import { authActions } from "../../authModule";
import { getConfigurationProperty } from "xcel-config";
import OAuth from "../paths/OAuth";

export interface AuthProviderDispatchProps {
    userManager: any;
    dispatch: (action: any) => {};
    routing: any;
}

type AllAuthProviderProps = AuthProviderDispatchProps;

export const AuthProvider: React.FunctionComponent<AllAuthProviderProps> = ({
                                                                                ...props
                                                                            }) => {
    let identityServerConfigData = getConfigurationProperty([
        "identityServerConfigData"
    ]) as any;
    const [isSessionValid, setIsSessionValid] = (React as any).useState(false);
    const [isLoading, setIsLoading] = (React as any).useState(false);
    (React as any).useEffect(() => {
        const { pathname } = props.routing.location;
        if (
            props.userManager &&
            identityServerConfigData &&
            pathname !== OAuth.loginRedirectCallback && pathname !== OAuth.logoutRedirectCallback && pathname !== '/logout'
        ) {
            const checkSession = async () => {
                try {
                    setIsLoading(true);
                    await props.userManager.removeUser();
                    await props.userManager.signinRedirect({ state: OAuthService.getState()});
                    setIsSessionValid(true);
                    setIsLoading(false);
                } catch (e) {
                    setIsLoading(false);
                    await props.userManager.clearStaleState();
                    setIsSessionValid(true);
                    await props.dispatch(authActions.user.userLogoutOAuth());
                }
            };

            if (!isSessionValid && !isLoading) {
                checkSession();
            }

            let userEvents = props.userManager.events;
            userEvents.addSilentRenewError(onSilentRenewError);
            userEvents.addAccessTokenExpired(onAccessTokenExpired);
            userEvents.addAccessTokenExpiring(onAccessTokenExpiring);
            return () => {
                userEvents.removeSilentRenewError(onSilentRenewError);
                userEvents.removeAccessTokenExpired(onAccessTokenExpired);
                userEvents.removeAccessTokenExpiring(onAccessTokenExpiring);
            };
        } else {
            setIsSessionValid(true);
        }
        return () => {};
    }, []);

    const onSilentRenewError = (error: Error) => {
        console.log(error);
    };
    const onAccessTokenExpired = () => {
        props.dispatch(oAuthTokenRefresh());
    };
    const onAccessTokenExpiring = () => {
        props.dispatch(openModal());
    };

    return <>{isSessionValid ? props.children : null}</>;
};

const mapDispatch = dispatch => ({
    dispatch: dispatch
});

const mapStateToProps = (state, ownProps) => {
    return {
        routing: state.routing,
        userManager: OAuthService.userManager
    };
};

export { React };

export default connect<any>(
    mapStateToProps,
    mapDispatch
)(AuthProvider) as any;