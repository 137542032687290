import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  HeaderTwo,
  HeadingSpacer,
  HorizontalRule,
  Text
  } from 'rsv8-components';
import { register, withContent, withResource } from 'xcel-react-core';
import EnterSalesButton from './EnterSalesButton';

interface Props {
  resource: {
    headerText: string;
    newSalesEntryImagePath: string;
    newSalesEntryText: string;
    headerDescriptionText: string;
  };
  qs: { [index: string]: any };
}

const EnterSales: React.FunctionComponent<Props> = (props) => (
  <React.Fragment>
    <Row>
      <Col xs={24}>
        <Row>
          <Col xs={24}>
            <HeadingSpacer>
              <HeaderTwo textalign="center">{props.resource.headerText}</HeaderTwo>
            </HeadingSpacer>
          </Col>
        </Row>
        <Row>
          <Col xs={12} xsOffset={6}>
            <Text themeVariation={'center'}>{props.resource.headerDescriptionText}</Text>
            <EnterSalesButton qs={props.qs} imageURI={props.resource.newSalesEntryImagePath}>
              {props.resource.newSalesEntryText}
            </EnterSalesButton>
          </Col>
        </Row>
      </Col>
    </Row>
    <HorizontalRule />
  </React.Fragment>
);

const mapResourceToProps = (getResource) => ({
  resource: {
    headerText: getResource('salesEntry.enterSalesHeader', 'Enter Sales'),
    headerDescriptionText: getResource(
      'salesEntry.enterSalesHeaderDescription',
      'Use your RewardStation® account to enter your Hardware Sales Purchased From a Distributor, have them validated, and watch your point balance grow! '
    ),
    newSalesEntryText: getResource('salesEntry.newSalesEntryText', 'New Sales Entry'),
    newSalesEntryImagePath: getResource(
      'salesEntry.newSalesEntryImagePath',
      '/clientassets/images/icons/sales-icon.svg'
    )
  }
});

export default register('rsv8-sales-entry/EnterSales')(
  withContent(),
  withResource(mapResourceToProps),
  connect(null, null)
)(EnterSales);
