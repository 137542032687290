import { reduxObserver } from 'xcel-observer';
import { createReduxModule, ReduxModule } from 'xcel-redux-module';
import { reducers } from './app/redux/reducers/';

import { onSelectorChange } from 'xcel-react-core';
import { getTimePeriodKey } from './app/redux/selectors';
import { dispatchTimePeriod } from './app/redux/actions';

const metricsModule: ReduxModule = createReduxModule({
  name: 'rsv8-metrics',
 init: ({ store }) => {    
    const timePeriodChanged = onSelectorChange(store, getTimePeriodKey);
    store.subscribe(() => {
      timePeriodChanged(async (current, next) => {
      let tmpDataKey: any;
      let tmpKey: any;

      let currDataKey: any;
      let currKey: any;

      if (current !== undefined) {
        if (current !== next) {
       if ( current.data !== undefined) {
         tmpKey = current.data.key;
         tmpDataKey = current.data.dataKey;
         if (tmpDataKey !== undefined && tmpKey !== undefined) {
           if (currDataKey !== tmpDataKey && currKey !== tmpKey) {
              currDataKey= current.data.dataKey;
              currKey = current.data.key;
              store.dispatch(dispatchTimePeriod({metricDataKey: currDataKey,
                 metricKey: currKey }));
         }
         }
       }  }} 

       let nextDataKey: any;
       let nextKey: any;
       tmpKey = '';
       tmpDataKey = '';
      
      if (next !== undefined) {
        if (next !== current) {
          if ( next.data !== undefined) {
            tmpKey = next.data.key;
            tmpDataKey = next.data.dataKey;
            if (tmpDataKey !== undefined && tmpKey !== undefined) {
              if (nextDataKey !== tmpDataKey && nextKey !== tmpKey) {
                nextDataKey= next.data.dataKey;
                nextKey = next.data.key;
                 if (nextDataKey !== currDataKey && nextKey !== currKey) {
                  store.dispatch(dispatchTimePeriod({metricDataKey: nextDataKey, metricKey: nextKey})); 
                 }  
                 
            }
         }
       }   
      }
      } 
 })
})
},
  reducers
});
reduxObserver.addModule(metricsModule);
export default metricsModule;