import * as React from 'react';
import { LoadNominees } from '../LoadMore/index';
import { NomineeSearchResultHeader } from '../SearchBar/index';
import NomineeList, { NomineeListProps } from './NomineeList';

const NomineeColumn: React.SFC<NomineeListProps> = (props) => (
  <React.Fragment>
    <NomineeSearchResultHeader />
    <NomineeList {...props} />
    <LoadNominees />
  </React.Fragment>
);

export default NomineeColumn;
