import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text
  } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import { authStateSelectors } from '../../../module';
import { closeModal, forceLogout, forceRefresh, oAuthForceRefresh, oAuthForceLogout } from '../../redux/auth.actions';

interface SessionExpireWarningProps {
  auth: any;
  actions: {
    closeModal: Function;
    forceRefresh: Function;
    forceLogout: Function;
    oAuthForceRefresh: Function;
    oAuthForceLogout: Function;
  };
  inactivityText: string;
  okLabel: string;
  cancelLabel: string;
}

class SessionExpireWarning extends React.Component<SessionExpireWarningProps, any> {
  componentWillMount() {
    const { auth, actions } = this.props;
    if (auth.isExpiring) {
      auth.isOAuth ? actions.oAuthForceLogout : actions.forceLogout();
    }
  }

  render() {
    const { actions, auth, inactivityText, okLabel, cancelLabel } = this.props;
    return (
      <Modal onHide={actions.closeModal} show={auth.isExpiring} backdrop={false}>
        <ModalHeader />
        <ModalBody>
          <Text>{inactivityText}</Text>
        </ModalBody>
        <ModalFooter>
          <Button themeVariation="primary" style={{ marginRight: '15px' }} onClick={auth.isOAuth ? actions.oAuthForceRefresh : actions.forceRefresh}>
            {okLabel}
          </Button>
          <Button themeVariation="secondary" onClick={auth.isOAuth ? actions.oAuthForceLogout : actions.forceLogout}>
            {cancelLabel}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapState = (state) => ({
  auth: authStateSelectors.getAuth(state)
});

const mapDispatch = (dispatch) => ({
  actions: bindActionCreators({ closeModal, forceRefresh, forceLogout, oAuthForceRefresh, oAuthForceLogout }, dispatch)
});

const mapResourceToProps = (getResource) => ({
  inactivityText: getResource(
    'SessionExpireWarning.inactivityText',
    'Your session will expire in 5 minutes due to inactivity. Would you like to extend your session and continue working?'
  ),
  okLabel: getResource('SessionExpireWarning.okLabel', 'OK'),
  cancelLabel: getResource('SessionExpireWarning.cancelLabel', 'Cancel')
});

export default compose(
  withResource(mapResourceToProps),
  connect(
    mapState,
    mapDispatch
  )
)(SessionExpireWarning as any) as any;
