import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as Tree from 'react-ui-tree';
import * as Node from 'react-ui-tree/dist/node';

type State = {
  dropped: boolean;
  tree: any;
  dragging: any;
};

type Props = {
  onChange: Function;
  renderNode: Function;
  tree: any;
  context?: any,
  forceUpdate?: any,
  refs?: any
  paddingLeft?: any
};
export default class ComponentTree extends Tree {
  state: State;
  props: Props;
  init: Function;
  setState: any;
  dragStart: Function;
  getDraggingDom: Function;
  toggleCollapse: Function;
  context: any;
  forceUpdate: any;
  refs: any;

  constructor(props: Props) {
    super(props);
    this.state = this.init(props);
    this.state.dropped = false;
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.dropped && prevState.tree !== this.state.tree) {
      this.launchDragging();
    }
  };

  getNewNodeElementId = () => {
    return Object.keys(this.state.tree.indexes)[1];
  };

  launchDragging = () => {
    this.setState({ dropped: false }, () => {
      const nodeID = this.getNewNodeElementId();
      const domNewNodeElement = ReactDOM.findDOMNode(this as any).childNodes[0].childNodes[1].childNodes[0].childNodes[0] as any;
      const BoundingClientRect = domNewNodeElement.getBoundingClientRect();
      const customEvent = new MouseEvent('mousedown', {
        clientX: BoundingClientRect.left,
        clientY: BoundingClientRect.top
      });
      this.dragStart(nodeID, domNewNodeElement, customEvent);
    });
  };

  addNewNodeElement = (event) => {
    if (event.dataTransfer) {
      const newNodeName = event.dataTransfer.getData('draggingElementTitle');
      if (newNodeName) {
        const childrenCopy = [
          {
            component: newNodeName
          },
          ...this.props.tree.children
        ];
        this.props.onChange({ ...this.props.tree, children: childrenCopy });
      }
    }
  };

  onDrop = (event) => {
    event.persist();
    this.setState({ dropped: true }, () => {
      this.addNewNodeElement(event);
    });
  };

  onDragOver = (event) => {
    event.preventDefault();
  };

  render() {
    const tree = this.state.tree;
    const dragging = this.state.dragging;
    const draggingDom = this.getDraggingDom();

    return (
      <div className="m-tree" onDrop={this.onDrop} onDragOver={this.onDragOver}>
        {draggingDom}
        <Node
          tree={tree}
          index={tree.getIndex(1)}
          key={1}
          paddingLeft={this.props.paddingLeft}
          onDragStart={this.dragStart}
          onCollapse={this.toggleCollapse}
          dragging={dragging && dragging.id}
        />
      </div>
    );
  }
}
