import * as React from "react";
import { CommonOptions, toast as reactToastify } from "react-toastify";
import { CloseIcon } from "./CloseIcon";
import { CatalogBody, ContentToast, DefaultTemplate } from "./templates";

class ToastTemplate extends React.Component<any> {
  render() {
    if (this.props.template === "CatalogBody") {
      return <CatalogBody {...this.props} />;
    }
    if (this.props.template === "ContentToast") {
      return <ContentToast {...this.props} />;
    }
    if (this.props.template === "Default") {
      return <DefaultTemplate {...this.props} />;
    }
    return null;
  }
}

const toast = (props, options: CommonOptions = { className: "default-toast-body", closeButton: <CloseIcon /> }) => {
  return reactToastify(<ToastTemplate {...props} />, options);
};

export { toast };
