import * as React from 'react';
import styled from 'styled-components';

const Label = styled.span`
  color: ${(props: any) => (props.color ? props.color : '#4c4f4f')};
  font-weight: normal;
  ${(props: any) => props.isBold && `font-weight: bold`};
  ${(props: any) => props.isRadio && `padding-left: 6px`};
` as any;

const Required = styled.span`
  color: #db504d;
` as any;

const Checkmark = styled.span`` as any;

export interface FieldLabelProps {
  labelName?: string;
  isBold?: boolean;
  required?: boolean;
  isCheckbox?: boolean;
  isRadio?: boolean;
  color?: string;
  children?: any;
}

export const FieldLabel: React.SFC<FieldLabelProps> = ({ children, isCheckbox, labelName, required, ...rest }) => (
  <React.Fragment>
    <Label isCheckbox={isCheckbox} {...rest}>
      {labelName || children}
      {required && <Required>*</Required>}
    </Label>
    {isCheckbox && <Checkmark className="catalog-checkmark" />}
  </React.Fragment>
);
