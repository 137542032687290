// import { promiseDispatcher } from "xcel-react-core";
import {destroyRequest } from "xcel-redux-orm";
import { metricsApiActions } from "./metricsApiActions";
import * as constants from "../../constants";
import { getReduxStore } from "xcel-redux-module";

// const actions = createJsonApiActions("getTimePeriod");
const getTimePeriodApiCall = (ProgramKey: any) => async (dispatch) => {
    dispatch(destroyRequest("getTimePeriod"));
    return dispatch(metricsApiActions.getTimePeriod(
        { programKey: ProgramKey },
        { jsonApi: true }
    ));
};

const getTimePeriodAction = response => ({
    type: constants.TIME_PERIOD_REDUCER_KEY,
    payload: response
});

const dispatchTimePeriod = (key: any) => async (dispatch, getState) => {
    try {
        const store = getReduxStore();
        const response = store.dispatch(getTimePeriodAction(key));
        return response;
    } catch (error) {
        console.log("Error Found!: ", error);
    }
};

const getTimePeriod = getTimePeriodApiCall;
export { getTimePeriod, dispatchTimePeriod };