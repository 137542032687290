const updateNodes = (nodes) => {
  return modifyNodeArray(null, nodes);
};

const modifyNodeArray = (parent, nodeArray) => nodeArray.map(updateNode(parent));

const updateNode = (parent = null) => (node, i, nodes) => ({
  ...node,
  children: node.children.length ? modifyNodeArray(node.id, node.children) : [],
  parentMenuId: parent,
  sequence: node.sequence !== null ? i : node.sequence
});

export default updateNodes;
