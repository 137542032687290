import * as React from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { register, withContent, withTheme } from 'xcel-react-core';
import Collapse from './Collapse';

let ComponentWrapper = styled.div`
  position: relative;
`;
let StyledCollapse: any = styled.div`
  width: 100%;
  position: relative;
`;

const StyledDeleteButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0.2;
  &:hover {
    opacity: 1;
  }
`;

export interface CollapseProps {
  collapseData: any;
  setComponentProperty: any;
  getContent: any;
  renderComponents: any;
  className: any;
  openCollapseInitially: any;
}

class DynamicContentCollapse extends React.Component<CollapseProps> {
  constructor(props: CollapseProps) {
    super(props);
  }
  render() {
    const isEditing = this.props['data-cms-editing'];
    const { setComponentProperty, getContent, renderComponents, openCollapseInitially } = this.props;
    const collapseData = getContent('collapseData', { type: 'none', label: 'collapseData' }) || [];
    const handleClick = () => {
      setComponentProperty('collapseData', [...collapseData, {}]);
    };

    const handleClickRemoveInd = (index) => {
      collapseData.splice(index, 1);
      setComponentProperty('collapseData', collapseData);
    };
    return (
      <div>
        {isEditing && (
          <div>
            <Button bsStyle="success" onClick={handleClick}>
              Add Collapse
            </Button>
          </div>
        )}

        {collapseData &&
          Array.isArray(collapseData) &&
          collapseData.map((item, index) => (
            <ComponentWrapper>
              <StyledCollapse className={this.props.className}>
                <Collapse
                  openCollapseInitially={openCollapseInitially}
                  title={getContent(`collapseData.${index}.title`, { type: 'html' })}
                >
                  {renderComponents(`collapseData.${index}.children`)}
                </Collapse>
              </StyledCollapse>
              {isEditing && (
                <StyledDeleteButton
                  className="btn btn-danger"
                  onClick={() => (window.confirm('Delete this?') ? handleClickRemoveInd(index) : '')}
                >
                  X
                </StyledDeleteButton>
              )}
            </ComponentWrapper>
          ))}
      </div>
    );
  }
}

export { DynamicContentCollapse };

const mapContentToProps = (getContent) => ({
  openCollapseInitially: getContent('openCollapseInitially', {
    type: 'checkbox',
    label: 'Open Collapse Initially?'
  }),
  getContent
});

export default register('rsv8-components/DynamicContentCollapse')(
  withContent(mapContentToProps),
  withTheme(['.collapse-button'])
)(DynamicContentCollapse);
