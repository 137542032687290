import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Card, HeaderThree, Text } from 'rsv8-components';
import { CustomFields } from 'rsv8-customfields';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';
import { DetailsLayout } from '../../../components/DashboardModal/Body/TeamDetails';
import { TeamRow } from '../../../components/DashboardModal/Body/TeamRow';
import { Div } from '../../../components/index';
import { teamNominationSelectors } from '../../../redux/index';

export interface TeamSummaryProps {
  editable: boolean;
}

interface InternalProps extends TeamSummaryProps {
  teamName: string;
  count: number;
  awardHasPoints: boolean;
  award: any;
  totalPoints: number;
  totalPointsLabel: any;
  awardNameLabel: any;
  nomineeCountLabel: any;
  nomination: any;
  teamMembers: any[];
  customFields: any[];
}
const PointSummary = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const PointSummaryBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const TeamSummary: React.SFC<InternalProps> = (props) => {
  const { award, nomination, totalPointsLabel, teamMembers } = props;
  const points = (nomination && nomination.points) || 0;
  const totalPoints = points * teamMembers.length;
  return (
    <Card variationName="nomination-card">
      <Div align="center">
        <HeaderThree>{props.teamName}</HeaderThree>
        <Text themeVariation="primary-text-strong">{props.nomineeCountLabel}</Text>
      </Div>
      <hr />
      {props.awardNameLabel && props.award.name && <TeamRow head={props.awardNameLabel}>{props.award.name}</TeamRow>}

      {award.points &&
        (award.points.length > 0 || (award.pointRange !== undefined && award.pointRange !== null)) && (
          <PointSummary>
            <PointSummaryBlock>
              <Text variationName="field-label">{totalPointsLabel}</Text>
              {totalPoints}
            </PointSummaryBlock>
          </PointSummary>
        )}
      <CustomFields
        formType="TeamNomination"
        groupKey="criteria"
        appKey={props.award.id as any}
        component={DetailsLayout}
        customFieldValues={props.customFields}
      />
      <CustomFields
        formType="TeamNomination"
        groupKey="details"
        appKey={props.award.id as any}
        component={DetailsLayout}
        customFieldValues={props.customFields}
      />
    </Card>
  );
};

const mapState = (state) => ({
  teamMembers: teamNominationSelectors.getCurrentNominees(state),
  nomination: teamNominationSelectors.getCurrentNomination(state),
  awardHasPoints: teamNominationSelectors.getAwardHasPoints(state),
  teamName: teamNominationSelectors.getCurrentTeamName(state),
  count: teamNominationSelectors.getCurrentNomineeIds(state).length,
  award: teamNominationSelectors.getCurrentAward(state),
  totalPoints: teamNominationSelectors.getTotalNominationPoints(state),
  customFields: teamNominationSelectors.getCurrentCustomFieldArray(state)
});

const mapResource = (getResource) => ({
  totalPointsLabel: getResource('teamNomination.totalPointsLabel', 'AwardValue'),
  awardNameLabel: getResource('teamNomination.awardNameLabel', 'Award'),
  nomineeCountLabel: getResource('teamNomination.nomineeCountLabel', '{count} nominees')
});

export default compose<any>(
  connect(
    mapState,
    null
  ),
  withResource(mapResource)
)(TeamSummary) as React.ComponentType<TeamSummaryProps>;
