import { FormControl, FormGroup } from 'react-bootstrap';
import styled from 'styled-components';

export const OptionsText = styled.div`
  color: #4c4f4f;
  line-height: 30px;
` as any;

export const OptionsFormGroup = styled(FormGroup)`
  margin-bottom: 25px;
` as any;

export const DetailFormControl = styled(FormControl)`
  width: 209px;

  @media (max-width: 992px) {
    width: 100%;
  }
` as any;
