import Login from './app/auth/Login/Login';
import LoginForm from './app/auth/Login/LoginForm';
import LoginRedirectCallback from "./oauth/LoginRedirectCallback";
import LogoutRedirectCallback from "./oauth/LogoutRedirectCallback";
import OAuth from "./app/auth/Login/OAuth";
import ExpiredToken from './app/auth/ResetPassword/ExpiredToken';
import Confirmation from './app/auth/ResetPassword/Confirmation';
import RequestPasswordResetForm from './app/auth/ResetPassword/RequestPasswordResetForm';
import ResetPassword from './app/auth/ResetPassword/ResetPassword';
import ResetPasswordForm from './app/auth/ResetPassword/ResetPasswordForm';
import ResetPasswordSent from './app/auth/ResetPassword/ResetPasswordSent';
import RequestPasswordReset from './app/auth/ResetPassword/RequestPasswordReset';
import ForgotUsername from './app/auth/ForgotUsername/ForgotUsername';
import UsernameReminderRequestForm from './app/auth/ForgotUsername/UsernameReminderRequestForm';
import UsernameReminderSent from './app/auth/ForgotUsername/UsernameReminderSent';
import { ResetPasswordRoutes, ForgotUsernameRoutes } from './app/auth/Routes';
import authPath from './app/paths/Auth';
import userPath from './app/paths/User';
import oauthPath from './app/paths/OAuth';
import { FieldContainer, ErrorSpan, Label, Input, SubmitButton } from './components/FormControls';
// TODO: Move the following components to a more appropriate module.
import { LayoutContainer, ForgotUserPass } from './components/Layout';
import SSOLoadingPage from './app/auth/SingleSignOn/Loading';
import SessionExpireWarning from './app/auth/SessionExpireWarning/SessionExpireWarning';

export {
  Login,
  LoginForm,
  OAuth,
  ExpiredToken,
  SessionExpireWarning,
  Confirmation,
  RequestPasswordResetForm,
  ResetPassword,
  ResetPasswordForm,
  ResetPasswordSent,
  RequestPasswordReset,
  ForgotUsername,
  UsernameReminderRequestForm,
  UsernameReminderSent,
  FieldContainer,
  ErrorSpan,
  Label,
  Input,
  SubmitButton,
  LayoutContainer,
  ForgotUserPass,
  SSOLoadingPage,
  authPath,
  userPath,
  oauthPath,
  ResetPasswordRoutes,
  ForgotUsernameRoutes,
  LogoutRedirectCallback,
  LoginRedirectCallback
};
