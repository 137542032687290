import * as React from 'react';
import { connect } from 'react-redux';
import { register, withResource, withTheme } from 'xcel-react-core';
import { ReduxContext } from 'xcel-redux-module';
import { destroyRequest } from 'xcel-redux-orm';
import imageApiActions from '../../redux/imageApiActions';
import imageSelector from '../../redux/imageSelectors';
import AvatarModal from './AvatarModal/AvatarModal';
import ColoredCircle from './ColoredCircle';
import { getConfigurationProperty } from 'xcel-config';
import { userStateSelectors } from '../../../module'

export const InitialsImage = (props) => {
  const { firstName, lastName, component: Component, userImage, clientId, ...rest } = props;
  const { dispatch } = React.useContext(ReduxContext) as any;
  // Make call to get large userImage
  const getNewImage = () => {
    dispatch(destroyRequest('getTypeByImageType'));
    dispatch(imageApiActions.getTypeByImageType({ imageType: 'small' }));
    dispatch(imageApiActions.getTypeByImageType({ imageType: 'large' }));
  };

  // Check if client has avatar enabled
  const avatarUploaderEnabled = getConfigurationProperty(['clients', clientId, 'enableProfileImage']) as any;

  React.useEffect(() => {
    getNewImage();
  }, [firstName, lastName, userImage && userImage.url]);

  return (
    <ColoredCircle {...rest}>
      {Component ? (
        <Component>
          {firstName && firstName.charAt(0)}
          {lastName && lastName.charAt(0)}
        </Component>
      ) : (
        <div className={avatarUploaderEnabled === true ? 'initialCircle' : ''}>
          {userImage === null ? (
            <div>
              {firstName && firstName.charAt(0)}
              {lastName && lastName.charAt(0)}
            </div>
          ) : (
            <img src={userImage.url} />
          )}
        </div>
      )}
      {avatarUploaderEnabled === true  
        ? <AvatarModal {...rest} onClick={true} getNewImage={getNewImage} />
        : null
      }
    </ColoredCircle>
  );
};

const mapResource = (getResource) => ({
  labels: {
    dialogLabel: getResource('AvatarEditor.dialogLabel', 'Profile Image Upload'),
    imageRequirements: getResource('Avatar.Upload.Requirements', 'Recommended image size is 600 x 600'),
    addPhoto: getResource('AvatarEditor.addPhoto', 'Add a Photo'),
    selectFile: getResource('AvatarEditor.selectFile', 'Select a File'),
    iconCameraClassName: getResource('AvatarEditor.iconCameraClassName', 'fa fa-camera')
  }
});
const mapStateToProps = (state) => ({
  userImage: imageSelector.selectOne(state, 'large'),
  clientId: userStateSelectors.getUser(state).clientId
});
export default register('rsv8-components/InitialsImage')(
  withResource(mapResource),
  connect(mapStateToProps),
  withTheme()
)(InitialsImage);
