import * as events from '../events';
import content from './content';
const contentTypeReducer = (state: any = { contentTypeId: 'Page' }, action: any) => {
  let { type, payload, contentTypeId } = action;
  switch (type) {
    case events.CONTENT_TYPE_ACTIVE_SET:
      return { ...state, contentTypeId: payload };
    default:
      contentTypeId = contentTypeId || state.contentTypeId;
      if (contentTypeId) {
        state[contentTypeId] = content(state[contentTypeId], action);
      }
      return state;
  }
};
export default contentTypeReducer;
