import { Resource } from 'xcel-redux-orm';
import { ContentVersionModel } from '../../redux/selectors/contentVersion';
import singleContentActions from './actions';
import * as events from './events';
import singleContentSelector from './selectors';
const singleContentPlugin = (contentTypeId: string, forceCultureId?: number) => {
  const contentKey = `default-${contentTypeId}`;
  return {
    actions: singleContentActions(contentTypeId, contentKey, forceCultureId),
    selector: singleContentSelector(contentTypeId, contentKey),
    events
  };
};
export default singleContentPlugin;
export { ContentVersionModel, Resource };
