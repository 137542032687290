import styled from 'styled-components';
export interface DropDownContainerProps {
  open: boolean;
}
const DropDownContainer = styled.div`
  position: relative;
  display: block;
  ${(props: DropDownContainerProps) => {
    if (props.open) {
      return `
        .dropdown-menu {
              display: block;
          }
        `;
    }
    return '';
  }};
` as any;

export default DropDownContainer;
