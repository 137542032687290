import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { HeaderThree, Text } from 'rsv8-components';
import { ReactField } from 'rsv8-forms';
import { CustomerProduct, RebateTimePeriod } from 'xcel-api-generator/dist/alcon';
import { register, withContent, withResource, withTheme } from 'xcel-react-core';
import { withApiDataProvider } from 'xcel-redux-orm';
import { convertCustomerProductToPatient, getRoundedValue, getValueOrNull, getValueOrZero, isNumber } from '../../../utils';
import { getMagnifeyeV2Summary, updateKeystonePatient } from '../../redux/actions';
import * as selectors from '../../redux/selectors';
import NoDataSpinner from '../NoDataSpinner';
import { OverlayPopover } from '../Overlay';
import { AlignedCol, ContentRow, HeaderRow, ProjectionValue, StyledNames } from './styles';

const KeystoneProducts: React.FC<Props> = ({
  className,
  actions: { updateKeystonePatient },
  resource: { headerNote, projectedPatientsLabel, baselinePatientsLabel },
  title = 'Keystone Products',
  infoIconText,

  product,
  baseTimePeriod,
}) => {
  const [value, setValue] = React.useState<string>();

  React.useEffect(() => {
    const patient = convertCustomerProductToPatient(product);
    setValue(patient.patients.toFixed(1));

    updateKeystonePatient(patient.patients);
  }, [product]);

  const onChange = (value: string) => {
    setValue(value);

    const patient = getValueOrZero(value);
    updateKeystonePatient(patient);
  };

  if (!product) {
    return <NoDataSpinner />;
  }

  return (
    <div className={className}>
      <HeaderRow color="#FFFFFF">
        <AlignedCol xs={24}>
          <HeaderThree themeVariation="my-dashboard">
            {title + ' '}
            <OverlayPopover id="popover-keystone-products" placement="bottom">
              {infoIconText}
            </OverlayPopover>
          </HeaderThree>
        </AlignedCol>
      </HeaderRow>

      <ContentRow padding="0 0 40px">
        <AlignedCol xs={24}>
          <Text dangerouslySetInnerHTML={{ __html: headerNote }} />
        </AlignedCol>

        <AlignedCol xs={24} sm={20} smOffset={2} md={16} mdOffset={4} lg={10} lgOffset={7}>
          <AlignedCol xs={16} xsOffset={4} sm={10} smOffset={1}>
            <StyledNames
              themeVariation="my-dashboard"
              fontSize={16}
              lineHeight={18}
              dangerouslySetInnerHTML={{ __html: projectedPatientsLabel }}
            />
            <ProjectionValue>
              <ReactField
                component="bs-input"
                value={value}
                validate={(value) => (!isNumber(value) ? 'should be a number' : null)}
                onChange={onChange}
              />
            </ProjectionValue>
          </AlignedCol>

          <AlignedCol xs={16} xsOffset={4} sm={10} smOffset={2}>
            <StyledNames
              themeVariation="my-dashboard"
              fontSize={16}
              lineHeight={18}
              dangerouslySetInnerHTML={{ __html: baselinePatientsLabel.replace('{}', baseTimePeriod.description) }}
            />
            <ProjectionValue>{getRoundedValue(getValueOrNull(product.baselinePatients), 1) || '-'}</ProjectionValue>
          </AlignedCol>
        </AlignedCol>
      </ContentRow>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ updateKeystonePatient }, dispatch),
});
const mapContentToProps = (getContent) => ({
  title: getContent('title', { type: 'string', label: 'Title' }),
  infoIconText: getContent('infoIconText', { type: 'string', label: 'Info Icon Text' }),
});
const mapResourceToProps = (getResource) => ({
  resource: {
    headerNote: getResource(
      'alcon.dashboard.myGoals.headerNote',
      '<p>Keystone products are not reflected in any calculation made above.</p>'
    ),
    projectedPatientsLabel: getResource('alcon.dashboard.myGoals.totalValueLabel', '<br/>Projected Patients'),
    baselinePatientsLabel: getResource('alcon.dashboard.myGoals.totalPatientsLabel', 'Baseline<br/>(Total Patients {})'),
  },
});

export const mapStateToProps = (state) => ({
  product: selectors.customerProductsSelector.selectOne(state, 0),
  baseTimePeriod: selectors.baseTimePeriodSelector(state),
});

interface StateProps {
  product: CustomerProduct;
  baseTimePeriod: RebateTimePeriod;
}
interface DispatchProps {
  actions: {
    updateKeystonePatient: Function;
  };
}
interface ContentProps {
  title: string;
  infoIconText: string;
}
interface ResourceProps {
  resource: {
    headerNote: string;
    projectedPatientsLabel: string;
    baselinePatientsLabel: string;
  };
}
type Props = StateProps & DispatchProps & ContentProps & ResourceProps & React.HtmlHTMLAttributes<any>;

export default register('rsv8-alcon/KeystoneProducts')(
  withApiDataProvider(getMagnifeyeV2Summary, selectors.performanceSelector),
  connect<StateProps, DispatchProps>(mapStateToProps, mapDispatchToProps),
  withContent(mapContentToProps),
  withResource(mapResourceToProps),
  withTheme()
)(KeystoneProducts);
