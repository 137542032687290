import * as React from 'react';
import {
  DataTableColumn,
  DataTableDefault,
  HeaderTwo,
  renderDate
  } from 'rsv8-components';
import { SORT_DIRECTIONS } from 'rsv8-components';
import {
  register,
  withContent,
  withResource,
  withTheme
  } from 'xcel-react-core';
import { withApiDataProvider } from 'xcel-redux-orm';
import { numberFormat } from 'xcel-util';
import { getAccountCredits } from '../../redux/actions/getAccountCredits';
import creditSelectors from '../../redux/selectors/creditSelectors';

export interface AdjustmentsTableProps {
  dispatch: Function;
  pointsShorthand: string;
  pointsLabel: string;
  dateTableHeader: string;
  confirmationTableHeader: string;
  productTableHeader: string;
  adjustmentsEmptyText: string;
  disableTaxType: string;
}

const defaultSort = { field: 'creditDate', direction: SORT_DIRECTIONS.desc };
export class AdjustmentsTable extends React.PureComponent<any, any> {
  renderPoints = ({ record }) => {
    return numberFormat(record.points) + ' ' + this.props.pointsShorthand;
  };
  renderTaxType = ({ record }) => {
    if (record.taxationType === 'non_taxable') {
      return this.props.pointTypeNonTaxable;
    }
    return record.taxationType === 'grossup_taxable' ? this.props.pointTypeGrossupTaxable : this.props.pointTypeTaxable;
  };
  render() {
    return (
      <React.Fragment>
        <HeaderTwo>{this.props.adjustmentsLabel}</HeaderTwo>
        <DataTableDefault {...this.props} defaultSort={defaultSort} emptyText={this.props.adjustmentsEmptyText}>
          <DataTableColumn field="creditDate" renderCell={renderDate}>
            {this.props.dateTableHeader}
          </DataTableColumn>
          <DataTableColumn field="orderId" sortable={false}>
            {this.props.confirmationTableHeader}
          </DataTableColumn>
          <DataTableColumn field="productName">{this.props.productTableHeader}</DataTableColumn>
          <DataTableColumn field="points" renderCell={this.renderPoints}>
            {this.props.pointsLabel}
          </DataTableColumn>
          {this.props.disableTaxType !== 'true' && (
            <DataTableColumn field="taxType" renderCell={this.renderTaxType}>
              {this.props.taxTypeLabel}
            </DataTableColumn>
          )}
        </DataTableDefault>
      </React.Fragment>
    );
  }
}

const mapResourceToProps = (getResource) => ({
  adjustmentsLabel: getResource('accountBalance.adjustmentsLabel', 'Adjustments'),
  pointsShorthand: getResource('account.pointsShorthand', 'pts.'),
  pointsLabel: getResource('account.pointsTableHeader', 'Points'),
  taxTypeLabel: getResource('account.taxTypeTableHeader', 'Tax Type'),
  dateTableHeader: getResource('account.dateTableHeader', 'Date'),
  pointTypeTaxable: getResource('account.pointType.taxable', 'Non-service Points'),
  pointTypeNonTaxable: getResource('account.pointType.nonTaxable', 'Service Points'),
  pointTypeGrossupTaxable: getResource('account.pointType.grossupTaxable', 'Gross-up Taxable Points'),
  confirmationTableHeader: getResource('account.confirmationTableHeader', 'Confirmation'),
  productTableHeader: getResource('account.productTableHeader', 'Product'),
  adjustmentsEmptyText: getResource('accountBalance.adjustmentsEmptyText', 'You have no adjustments'),
  disableTaxType: getResource('account.disableTaxType', 'false')
});

export default register('rsv8-account/AdjustmentsTable')(
  withResource(mapResourceToProps),
  withApiDataProvider(getAccountCredits, creditSelectors, { sort: defaultSort }),
  withTheme(),
  withContent()
)(AdjustmentsTable);
