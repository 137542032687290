import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { userStateSelectors } from 'rsv8-auth';
import { PageRenderer } from 'rsv8-cms';
import { Grid } from 'rsv8-components';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { withResource } from 'xcel-react-core';
import { FavoritesCarousel, TrendingCarousel } from '../../components/CarouselWrappers';

interface ShopLandingProps {
  accountSummary: any;
  actions: {
    getFavoriteProducts: any;
  };
  match?: any;
  computedMatch?: any;
  favoritesList: any;
  routing: any;
  trendingCarouselTitle: string;
  trendingCarouselCardText: string;
  favoritesCarouselCardText: string;
  favoritesCarouselTitle: string;
  countryCode: string;
  showComponents: string;
}

class ShopLanding extends React.Component<ShopLandingProps, any> {
  renderComponents = () => {
    let showComponentsResource = this.props.showComponents.split(',');
    const componentList = {
      trending: <TrendingCarousel cardPath={'/catalog/category'} />,
      favorites: <FavoritesCarousel cardPath={'/catalog/favorites'} allLink={true} />
    };
    return showComponentsResource.map((key) => componentList[key]);
  };
  render() {
    const { countryCode } = this.props;
    return (
      <Grid themeVariation="page-wrapper">
        <PageRenderer
          path={`/catalog/home/${countryCode}`}
          onNotFound={() => <PageRenderer path={`/catalog/home/default`} />}
        />
        {this.renderComponents()}
      </Grid>
    );
  }
}

const mapResourceToProps = (getResource) => ({
  showComponents: getResource('catalog.showComponentsShopLanding', 'trending,favorites')
});

const mapStateToProps = (state) => ({
  countryCode: userStateSelectors.getUser(state).countryCode
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch)
});

export default compose<any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withResource(mapResourceToProps)
)(ShopLanding);
