import * as React from 'react';

interface Image {
  imageSrc: string;
}

const ImageDisplay: React.SFC<Image> = (props) => {
  const { imageSrc } = props;
  const newTime = Date.now();
  const imageFile = `${imageSrc}?${newTime}`;
  return <img src={imageFile} />;
};

export default ImageDisplay;
