import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Text } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import { deleteFavorite, getFavoriteProducts, postFavorite } from '../../../redux/actions';
import { getFavoriteList } from '../../../redux/selectors';
import { FavoritesCheckedIcon, FavoritesContainer, FavoritesUncheckedIcon } from './styles';

export interface DetailFavoritesButtonProps {
  actions: {
    getFavoriteProducts: any;
    postFavorite: any;
    deleteFavorite: any;
  };
  favoriteProducts: any;
  removeFavoriteLabel: string;
  addFavoriteLabel: string;
  product: any;
  productGroupProduct?: any;
  activeIcon: string;
  inactiveIcon: string;
}

class DetailFavoritesButton extends React.Component<DetailFavoritesButtonProps, any> {
  state = {
    isDisabled: false
  };

  componentDidMount() {
    this.props.actions.getFavoriteProducts({ id: 'favorite' });
  }

  isGroup = () => {
    const { productGroupProduct } = this.props;
    return !!(
      productGroupProduct &&
      typeof productGroupProduct === 'object' &&
      productGroupProduct !== null &&
      Object.keys(productGroupProduct).length > 0
    );
  };

  addFavorite = (product) => {
    const { productGroupProduct, actions } = this.props;
    if (!this.state.isDisabled) {
      this.setState({ isDisabled: true });
      let favoriteProduct = Object.assign({}, product);

      if (this.isGroup()) {
        favoriteProduct.name = productGroupProduct.productName;
        favoriteProduct.productName = productGroupProduct.productName;
      }

      actions.postFavorite(favoriteProduct).then((resp) => {
        actions.getFavoriteProducts({ id: 'favorite' }).then((res) => {
          this.setState({ isDisabled: false });
        });
      });
    }
  };

  removeFavorite = (product) => {
    const { productGroupProduct, actions } = this.props;
    if (!this.state.isDisabled) {
      this.setState({ isDisabled: true });
      let favoriteProduct = Object.assign({}, product);

      if (this.isGroup()) {
        favoriteProduct.name = productGroupProduct.productName;
        favoriteProduct.productName = productGroupProduct.productName;
      }

      actions.deleteFavorite(favoriteProduct).then(() => {
        actions.getFavoriteProducts({ id: 'favorite' }).then(() => {
          this.setState({ isDisabled: false });
        });
      });
    }
  };

  render() {
    const { favoriteProducts, product, addFavoriteLabel, removeFavoriteLabel, activeIcon, inactiveIcon } = this.props;
    if (favoriteProducts.includes(product.id)) {
      return (
        <FavoritesContainer
          onClick={() => {
            this.removeFavorite(product);
          }}
        >
          <FavoritesCheckedIcon icon={activeIcon} size="2x" themeVariation="catalog-favorites-icon" />
          <Text themeVariation="secondary-text">{removeFavoriteLabel}</Text>
        </FavoritesContainer>
      );
    }
    return (
      <FavoritesContainer
        onClick={() => {
          this.addFavorite(product);
        }}
      >
        <FavoritesUncheckedIcon icon={inactiveIcon} size="2x" themeVariation="catalog-favorites-icon" />
        <Text themeVariation="secondary-text">{addFavoriteLabel}</Text>
      </FavoritesContainer>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  favoriteProducts: getFavoriteList(state)
});

const mapResourceToProps = (getResource) => ({
  addFavoriteLabel: getResource('catalog.productDetail.addFavoriteLabel', 'Add to Favorites'),
  removeFavoriteLabel: getResource('catalog.productDetail.removeFavoriteLabel', 'Added to your Favorites'),
  activeIcon: getResource('catalog.favoriteIcon.active', 'bookmark'),
  inactiveIcon: getResource('catalog.favoriteIcon.inactive', 'bookmark-o')
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getFavoriteProducts,
      postFavorite,
      deleteFavorite
    },
    dispatch
  )
});

export default compose<any>(
  withResource(mapResourceToProps),
  connect(mapStateToProps, mapDispatchToProps)
)(DetailFavoritesButton);
