import * as React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { connect } from 'react-redux';
import { destroyRequest } from 'xcel-redux-orm';
import { ReduxContext } from 'xcel-redux-module';
import imageApiActions from 'rsv8-components/dist/app/redux/imageApiActions';
import imageSelector from 'rsv8-components/dist/app/redux/imageSelectors';
import { assetService } from 'xcel-asset-service';
import { MenuItem, NavItem } from '../MenuItem';
import NavDropdown from './NavDropdown';
import { PrimaryNavStyledWrap } from './styles/HeaderNavigationStyles';

export const HeaderNavigation = (props) => {
  let { menuItems, userImage } = props;
  const { dispatch } = React.useContext(ReduxContext) as any;

  const getNewImage = () => {
    dispatch(destroyRequest('getTypeByImageType'));
    dispatch(imageApiActions.getTypeByImageType({ imageType: 'small' }));
    dispatch(imageApiActions.getTypeByImageType({ imageType: 'large' }));
  };

  React.useEffect(() => {
    getNewImage();
  }, [userImage && userImage.url]);

  return (
    <div>
      <PrimaryNavStyledWrap>
        <Navbar fluid={false}>
          <Nav>
            {menuItems.map((item, i) => {
              let parsedMeta = JSON.parse(item.meta);

              let imageURL = '';

              if (parsedMeta !== null) {
                imageURL = parsedMeta.menuItemImage;
              }
              
              if (item.children.length > 0) {
                return (
                  <NavDropdown
                    key={i}
                    cultureId={props.cultureId}
                    id={item.id}
                    title={
                      <img
                        src={
                          item.label === 'Log Out' && userImage !== null
                            ? userImage.url
                            : assetService.getImage(imageURL)
                        }
                        className="userImageIcon"
                      />
                    }
                    targetUrl={item.targetUrl}
                  >
                    {item.children.map((child, i) => {
                      return (
                        <MenuItem id={child.id} key={i} cultureId={props.cultureId} href={child.targetUrl}>
                          {item.children[i].label}
                        </MenuItem>
                      );
                    })}
                  </NavDropdown>
                );
              } else {
                return (
                  <NavItem key={i} cultureId={props.cultureId} href={item.targetUrl}>
                    <img src={assetService.getImage(imageURL)} />
                  </NavItem>
                );
              }
            })}
          </Nav>
        </Navbar>
      </PrimaryNavStyledWrap>
    </div>
  );
};
const mapStateToProps = (state) => ({
  userImage: imageSelector.selectOne(state, 'small')
});
export default connect(mapStateToProps)(HeaderNavigation) as any;
