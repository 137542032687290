import * as React from 'react';
import { Context } from '../../../types';
import { WizardContext } from '../WizardContext';

export interface WithWizardProps {
  wizard: Context;
}

const withWizard = <P extends WithWizardProps>() => (BaseComponent: React.SFC<P> | React.ComponentClass<P>) => {
  return (props) => (
    <WizardContext.Consumer>{(val: Context) => <BaseComponent wizard={val} {...props} />}</WizardContext.Consumer>
  );
};

export { withWizard };
