import { Col } from 'react-bootstrap';
import { Button as BaseButton, Text } from 'rsv8-components';
import styled, { keyframes } from 'styled-components';

const WINNER_SECTION_HEIGHT = 160;

export interface BgProps {
  bgImage?: string;
  bgMobileImage?: string;
}

export interface WinnerContainerProps {
  time?: number;
}

const translation = keyframes`
  0% {transform: translate(0, 0)}
  100% {transform: translate(0, -100%)}
`;

export const Background = styled.div`
  overflow: hidden;
  margin-bottom: 50px;
  width: 100%;
  max-height: 416px;
  padding: 0 24px 24px 24px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('${(props: BgProps) => (props.bgImage ? props.bgImage : '')}');
  @media (max-width: 992px) {
    background-image: url('${(props: BgProps) => (props.bgMobileImage ? props.bgMobileImage : props.bgImage)}');
    background-position: top center;
    max-height: 613px;
  }
`;

export const StarAwardHeader = styled.div`
  text-align: center;
  margin-top: 20px;
`;

export const StarAwardHeaderImg = styled.img`
  height: 140px;
  margin: 0 auto;
  display: block;
`;

export const AsideButtons = styled(Col)`
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  align-content: center;
  height: ${WINNER_SECTION_HEIGHT}px;
  justify-content: center;
  button {
    margin: 5px;
  }
  @media (max-width: 992px) {
    height: auto;
    margin-top: 24px;
    padding: 0;
  }
`;

export const Button = styled(BaseButton)`
  padding: 7px;
  width: 100%;
`;

export const WinnersSection = styled(Col)`
  padding: 0 20px;
  overflow: hidden;
  height: ${WINNER_SECTION_HEIGHT}px;
  position: relative;
  @media (max-width: 992px) {
    height: calc(${WINNER_SECTION_HEIGHT}px + (${WINNER_SECTION_HEIGHT}px / 3));
  }
`;

export const WinnersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: ${WINNER_SECTION_HEIGHT}px 0;
  ${(props: WinnerContainerProps) => (props.time ? `animation: ${translation} ${props.time}s linear infinite;` : '')}
`;

export const WinnerWrapper = styled(Col)`
  padding: 0 5px;
  margin-top: 15px;
  margin-bottom: 5px;
  text-align: center;
`;

export const WinnerName = styled(Text)`
  font-size: 18px;
  font-weight: 500;
  display: block;
  line-height: 1.56;
`;

export const WinnerCaption = styled(Text)`
  font-size: 14px;
  font-weight: 300;
  line-height: 1.14;
  letter-spacing: normal;
  display: block;
`;
