import { metric } from 'xcel-api-generator';

import { mapJsonApiActions } from 'xcel-redux-orm';

export type PromiseFunction = (...args: any[]) => Promise<any>
// const convertApiToJsonApi = <T extends PromiseFunction>(api:T, idProperty, type) => {
//     const jsonApi = (...args: any[]): any => {
//         return api.apply(null, args).then(items => ({
//           data: items.map(item => ({
//             attributes: item,
//             id: item[idProperty],
//             type
//           }))
//         }))
//     }
//     return jsonApi as T
// }

// const metricApi = {
//   ...metric,  
//   createCalculatorGoalsCalculateByCalculatorKey:  convertApiToJsonApi(metric.createCalculatorGoalsCalculateByCalculatorKey, "key", "calculatorResult") 
// }
const getCalculatorGoalsByCalculatorKey = (({programKey, calculatorKey, timePeriodKey}: {
  programKey: string;
  calculatorKey: string;
  timePeriodKey?: string;
}) => metric.getCalculatorGoalsByCalculatorKey({programKey, calculatorKey, timePeriodKey}).then(response => {
  timePeriodKey = timePeriodKey ? timePeriodKey : 'TOTAL'
  return {data: response.data.map(item => ({...item, id: `${item.id}_${timePeriodKey}`, attributes: {...item.attributes, timePeriodKey}}))}
}))

const createCalculatorCalculateByCalculatorKey = (({programKey, calculatorKey, timePeriodKey, body}: {
  programKey: string;
  calculatorKey: string;
  timePeriodKey?: string;
  body?: any
}) => metric.createCalculatorCalculateByCalculatorKey({programKey, calculatorKey, timePeriodKey, body}).then(response => {
  timePeriodKey = timePeriodKey ? timePeriodKey : 'TOTAL'
  return {data: response.data.map(item => ({...item, id: `${item.id}_${timePeriodKey}`, attributes: {...item.attributes, timePeriodKey}}))}
}))

const createCalculatorGoalsCalculateByCalculatorKey = (({programKey, calculatorKey, body}: {
  programKey: string;
  calculatorKey: string;
  body?: any
}) => metric.createCalculatorGoalsCalculateByCalculatorKey({programKey, calculatorKey, body}).then(response => {
  return {data: response.data.map(item => ({...item, id: `${item.id}_TOTAL`, attributes: {...item.attributes, timePeriodKey: 'TOTAL'}}))}
}))
export default mapJsonApiActions({...metric, getCalculatorGoalsByCalculatorKey, createCalculatorCalculateByCalculatorKey, createCalculatorGoalsCalculateByCalculatorKey}, true);