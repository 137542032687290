import styled from 'styled-components';
import DragContainer from '../../../../components/DragAndDrop/styles/DragContainer';
const Toolbar = styled.div`
  position: absolute;
  top: ${(props: any) => `${props.position.top + props.scrollTop}px`};
  left: ${(props: any) => `${props.position.left}px`};
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  vertical-align: middle;
  transform: translateY(-100%);
  cursor: pointer;
  z-index: 5000;
  color: white;
  line-height: 1px;
  letter-spacing: 1px;
  padding: 4px;
  white-space: nowrap;
  transition: opacity 200ms;
  z-index: 2000;
  ${DragContainer} {
    cursor: move;
  }
` as any;
export default Toolbar;
