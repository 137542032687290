import styled from 'styled-components';
import { CheckoutModuleHeading } from '../styles';

export const DigitalCardInfoContainer = styled.div`
  margin-top: 10px;

  @media (min-width: 769px) {
    margin-left: 20px;
  }
` as any;

export const DigitalCardHeading = styled(CheckoutModuleHeading)`
  margin-bottom: 10px !important;
` as any;

export const HelpContainer = styled.div`
  margin-bottom: 10px;
` as any;
