import * as React from 'react';
import { connect } from 'react-redux';
import { ReactField } from 'rsv8-forms';
import cmsPluginSelectors from '../../../redux/cms.plugin.selectors';
import FontOption from './styles/FontOption';
const FontOptionRenderer = ({ options: fonts }) => {
  return (
    <React.Fragment>
      <option value="None">None</option>
      {fonts.map((font) => (
        <FontOption key={font.name} value={font.name} font={font} size={'18px'}>
          {font.name}
        </FontOption>
      ))}
    </React.Fragment>
  );
};
let FontLibraryPicker = ({ label, fonts, value, onChange, ...rest }) => {
  const handleChange = (changedValue) => {
    if (onChange) {
      onChange(changedValue);
    }
  };
  const options = Object.keys(fonts).map((name, index) => fonts[name]);
  return (
    <ReactField
      {...rest}
      label={label}
      component={'bs-select'}
      optionRenderer={FontOptionRenderer}
      onChange={handleChange}
      options={options}
      value={value}
    />
  );
};

const mapStateToProps = (state) => ({
  fonts: cmsPluginSelectors.getFonts(state)
});
const mapDispatchToProps = (dispatch) => ({});

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(FontLibraryPicker);
