import * as React from 'react';
// @ts-ignore
/* tslint:disable */

// TODO: Replace hardcoded fill.
// TODO: change ID naming scheme. Would hypothetically cause issues with 2+
export const RibbonSvg = (props) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="14"
    height="20"
    viewBox="0 0 13.9 19.9"
    fill="#004165"
    xmlSpace="preserve"
  >
    <title>recognition</title>
    <path
      d="M7,4.2L7,4.2C6.2,4.2,5.5,4.5,5,5C3.9,6.1,3.9,7.8,5,8.9C5.5,9.4,6.2,9.7,7,9.7l0,0c1.5,0,2.8-1.2,2.8-2.8S8.5,4.2,7,4.2z
	 M8.2,8.2C7.8,8.5,7.4,8.7,6.9,8.7l0,0C6,8.7,5.2,7.9,5.2,7c0-1,0.8-1.7,1.7-1.8c0,0,0,0,0,0c1,0,1.7,0.8,1.8,1.8
	C8.7,7.4,8.5,7.9,8.2,8.2L8.2,8.2z"
    />
    <path
      d="M13.9,6.7L13,5.4L13,3.8c0-0.2-0.1-0.4-0.3-0.5l-1.4-0.7l-0.7-1.5c-0.1-0.2-0.3-0.3-0.5-0.3L8.6,1L7.2,0.1
	C7.1,0,6.9,0,6.7,0.1L5.4,0.9L3.8,0.8c-0.2,0-0.4,0.1-0.5,0.3L2.6,2.5L1.1,3.2C0.9,3.3,0.8,3.5,0.8,3.7L1,5.3L0.1,6.6
	C0,6.8,0,7,0.1,7.2L1,8.5l-0.1,1.6c0,0.2,0.1,0.4,0.3,0.5l1.4,0.7v8.1c0,0.2,0.1,0.3,0.2,0.4c0.2,0.1,0.3,0.1,0.5,0l3.7-2l3.8,2
	c0.2,0.1,0.3,0.1,0.5,0c0.2-0.1,0.2-0.3,0.2-0.4v-8.1l1.4-0.7c0.2-0.1,0.3-0.3,0.3-0.5L13,8.6l0.9-1.3C14,7.1,14,6.9,13.9,6.7z
	 M7.4,17c-0.2-0.2-0.5-0.2-0.7,0l-3.1,1.6V13c0.1,0,0.2,0.1,0.3,0.1L5.3,13l1.3,0.9C6.8,13.9,6.9,13.9,7,14c0.1,0,0.2,0,0.3-0.1
	L8.6,13l1.6,0.1c0.1,0,0.2,0,0.2-0.1v5.6L7.4,17z M12.1,8.1c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.1,0,0.2L12,10l-1.2,0.6
	c-0.2,0.1-0.3,0.2-0.4,0.4L10,12.1L8.6,12c-0.2,0-0.4,0-0.5,0.1L7,12.9l-1.2-0.8C5.8,12,5.7,12,5.6,12H5.4l-1.4,0.1l-0.6-1.2
	c-0.1-0.1-0.2-0.3-0.3-0.3L1.9,9.9l0-1.3c0-0.2,0-0.3-0.1-0.5L1.1,7l0.8-1.1c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.1,0-0.2L1.9,4l1.2-0.6
	C3.2,3.3,3.3,3.1,3.4,3L4,1.8l1.3,0.1c0.2,0,0.4,0,0.5-0.1L7,1.1l1.2,0.8l0.3,0.1c0.1,0,0.1,0,0.2,0L10,1.9l0.6,1.3
	c0,0.1,0.1,0.1,0.2,0.2l0.1,0.1L12.1,4L12,5.4c0,0.2,0,0.3,0.1,0.5L12.8,7L12.1,8.1z"
    />
  </svg>
);
