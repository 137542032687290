import * as React from 'react';
import { FormGroup, Radio } from 'react-bootstrap';
import { Forms } from '../../types';
import Label from './Label';
import { TooltipContainer } from './TooltipContainer';
const RadioGroup: Forms.Inputs.RadioGroup = ({ input, meta, options, label, helpIconText, ...rest }) => {
  const $options = options.map((opt, i) => (
    <Radio
      key={i}
      {...input}
      name={`${input.name}[${i}]`}
      checked={input.value.toString() === opt.value.toString()}
      value={opt.value}
    >
      {opt.label}
    </Radio>
  ));
  return (
    <FormGroup>
      <Label>{label}</Label>
      {helpIconText && <TooltipContainer tooltipChildren={helpIconText} />}
      {$options}
    </FormGroup>
  );
};

export default RadioGroup;
