import * as React from 'react';
import { register, withResource } from 'xcel-react-core';
import ValidationProvider from './ValidationProvider';

export interface ValidationConnectorProps {}

interface InternalProps extends ValidationConnectorProps {
  validation: {
    email: any;
    required: any;
  };
}

const ValidationConnector: React.SFC<InternalProps> = (props) => {
  ValidationProvider.setValidationMessages({ ...props.validation });
  return null;
};

const mapResource = (getResource) => ({
  validation: {
    email: getResource('validation.email', 'must be a valid email'),
    required: getResource('validation.required', 'is required')
  }
});

const compose = register('rsv8-forms/ValidationConnector');

export default compose(withResource(mapResource))(ValidationConnector) as React.SFC<ValidationConnectorProps>;
