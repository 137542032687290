import styled from 'styled-components';

export const ProductDetailImage = styled.div`
  display: flex;
  margin-top: 30px;
` as any;

export const ThumbnailViewer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
` as any;

export const ThumbnailImageContainer = styled.div`
  border: 1px solid #ddd;
  box-shadow: ${(props: any) => (props.image === props.currentImage ? '0 0 9px 2px #00a9e0' : 0)};
  /* box-shadow: ${(props: any) => (props.image !== props.currentImage && '0')} !important; */
  cursor: pointer;
  margin-bottom: 10px;
  padding: 3px;
` as any;

export const ThumbnailImage = styled.img`
  max-width: 40px;
` as any;

export const SelectedImageContainer = styled.div`
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0 15px 15px 15px;
` as any;

export const SelectedImage = styled.img`
  width: 100%;
  display: ${(props:any) => props.loaded ? 'block' : 'none'}
` as any;
