import * as React from 'react';
import { connect } from 'react-redux';
import { CustomerProduct, Program } from 'xcel-api-generator/dist/alcon';
import { getCurrencyValue, getDecimalValue } from '../../../../utils';
import { ProductFieldType, ProgramName } from '../../../constants';
import { programSelector, unitLabelSelector } from '../../../redux/selectors';
import { AttributeValue } from './AttributeValue';
import { CustomInput } from './CustomInput';
import * as styles from './styles';

const getProductAttributesByHeader = (product: CustomerProduct, program: Program, field: ProductFieldType) => {
  if (ProgramName.match(ProgramName.OptiPort, program) || ProgramName.match(ProgramName.VisionSource, program)) {
    switch (field) {
      case ProductFieldType.BaseLine:
        return { units: product.ytdBaseLineUnits, data: product.ytdBaseLineUnits };
      case ProductFieldType.Current:
        return { units: product.ytdCurrentUnits, data: product.ytdCurrentUnits };
      case ProductFieldType.RunRate:
        return { units: product.ytdRunRateUnits, data: product.ytdRunRateUnits };
      case ProductFieldType.Target:
        return { units: product.targetUnits, data: product.targetUnits };
      case ProductFieldType.Custom:
        return { units: product.customUnits, data: product.customUnits };
      default:
        console.error('Unexpected field type: ', field, product);
    }
  }

  if (ProgramName.match(ProgramName.Magnifeye, program) || ProgramName.match(ProgramName.Platinum, program)) {
    switch (field) {
      case ProductFieldType.BaseLine:
        return { units: product.baseLineUnits, data: product.baseLineUnits };
      case ProductFieldType.Current:
        return { units: product.currentUnits, data: product.currentUnits };
      case ProductFieldType.RunRate:
        return { units: product.runRateUnits, data: product.runRateUnits };
      case ProductFieldType.Target:
        return { units: product.targetUnits, data: product.targetUnits };
      case ProductFieldType.Custom:
        return { units: product.customUnits, data: product.customUnits };
      default:
        console.error('Unexpected field type: ', field, product);
    }
  }

  if (ProgramName.match(ProgramName.MarketShare, program) || ProgramName.match(ProgramName.TotalPartner, program)) {
    switch (field) {
      case ProductFieldType.BaseLine:
        return { units: product.ytdBaseLineUnits, data: product.ytdBaseLineSaleAmount };
      case ProductFieldType.Current:
        return { units: product.ytdCurrentUnits, data: product.ytdCurrentSaleAmount };
      case ProductFieldType.RunRate:
        return { units: product.ytdRunRateUnits, data: product.ytdRunRateSaleAmount };
      case ProductFieldType.Target:
        return { units: product.targetUnits, data: product.targetSaleAmount };
      case ProductFieldType.Custom:
        return { units: product.customUnits, data: product.customSaleAmount };
      default:
        console.error('Unexpected field type: ', field, product);
    }
  }

  return { units: undefined, data: undefined };
};

const ProductAttrBlock: React.FC<Props> = ({ program, unitLabel, product, field, headerText, customAttributes = '' }) => {
  const productId = product.id;
  const { units, data } = React.useMemo(() => getProductAttributesByHeader(product, program, field), [product, program, headerText]);

  return (
    <styles.ProductTableColumn
      isSingleLine={ProgramName.match(ProgramName.Magnifeye, program) || ProgramName.match(ProgramName.Platinum, program)}
    >
      <styles.CenteredCell>
        <styles.ProductTableCell>
          <styles.ProductAttrHeaderText>{headerText}</styles.ProductAttrHeaderText>
        </styles.ProductTableCell>
      </styles.CenteredCell>

      <styles.ProductCardDiv>
        {(ProgramName.match(ProgramName.Magnifeye, program) ||
          ProgramName.match(ProgramName.Platinum, program) ||
          ProgramName.match(ProgramName.OptiPort, program) ||
          ProgramName.match(ProgramName.VisionSource, program)) && (
          <>
            {!customAttributes && <AttributeValue unitLabel="units" unitData={units} />}
            {!!customAttributes && <CustomInput productId={productId} unitLabel="units" customData={getDecimalValue(units)} />}
          </>
        )}

        {(ProgramName.match(ProgramName.MarketShare, program) || ProgramName.match(ProgramName.TotalPartner, program)) && (
          <>
            {customAttributes !== 'customunits' && <AttributeValue unitLabel="units" unitData={units} />}
            {customAttributes === 'customunits' && (
              <CustomInput unitLabel="units" customData={getDecimalValue(units)} productId={productId} />
            )}

            {customAttributes === 'custompoints' && (
              <CustomInput unitLabel={unitLabel} customData={getDecimalValue(data)} productId={productId} />
            )}
            {customAttributes !== 'custompoints' && <AttributeValue unitLabel={unitLabel} unitData={getCurrencyValue(data)} />}
          </>
        )}
      </styles.ProductCardDiv>
    </styles.ProductTableColumn>
  );
};

const mapStateToProps = (state) => ({
  program: programSelector.selectMany(state)[0],
  unitLabel: unitLabelSelector(state),
});

interface StateProps {
  program: Program;
  unitLabel: string;
}
interface DispatchProps {}
interface OwnProps {
  product: CustomerProduct;
  field: ProductFieldType;
  headerText: string;
  customAttributes?: string;
}
type Props = StateProps & DispatchProps & OwnProps;

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps)(ProductAttrBlock);
