import { DragSource } from 'react-dnd';
import * as types from './types';

const ComponentDragSource = () => {
  let config = {
    component: {
      beginDrag(props: any) {
        const data = props.data || {};
        return { ...data, model: props.model };
      }
    },
    mapSourceToProps: (connector: any, monitor: any) => ({
      dragSource: connector.dragSource(),
      dragPreview: connector.dragPreview(),
      isDragging: monitor.isDragging()
    })
  };
  return DragSource(types.COMPONENT, config.component, config.mapSourceToProps);
};

export default ComponentDragSource;
