// ContentType Events
export const CONTENT_TYPE_ACTIVE_SET = 'rsv8-cms-admin/CONTENT_TYPE_ACTIVE_SET';

// Content Interactive Events
export const COMPONENT_PROPERTY_SET = 'rsv8-cms-admin/COMPONENT_PROPERTY_SET';
export const COMPONENT_MOVE = 'rsv8-cms-admin/COMPONENT_MOVE';
export const COMPONENT_ADD = 'rsv8-cms-admin/COMPONENT_ADD';
export const COMPONENT_REMOVE = 'rsv8-cms-admin/COMPONENT_REMOVE';
export const COMPONENT_DUPLICATE = 'rsv8-cms-admin/COMPONENT_DUPLICATE';

export const CONTENT_CHANGE = 'rsv8-cms-admin/CONTENT_CHANGE';

// Content Event
export const CONTENT_ACTIVE_PROPERTY_SET = 'rsv8-cms-admin/CONTENT_ACTIVE_PROPERTY_SET';
export const CONTENT_EDIT_PROPERTY_SET = 'rsv8-cms-admin/CONTENT_EDIT_PROPERTY_SET';
export const CONTENT_EDIT_SET = 'rsv8-cms-admin/CONTENT_EDIT_SET';
export const CONTENT_RAW_SET = 'rsv8-cms-admin/CONTENT_RAW_SET';

export const HOVER_ACTIVE_SET = 'xcel-react-core/HOVER_ACTIVE_SET';

// Social Activities Events
export const SET_ACTIVITY_ID = `rsv8-cms-admin/SET_ACTIVITY_ID`;
export const SET_EDIT_CULTURE = `rsv8-cms-admin/SET_EDIT_CULTURE`;
export const SET_EDIT_VERSION = `rsv8-cms-admin/SET_EDIT_VERSION`;
