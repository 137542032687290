import * as React from 'react';
import { compose } from 'redux';
import ComponentDragSource from './DragSource';
import ComponentDropTarget from './DropTarget';
import { DragContainer } from './styles';
class DragAndDropContainer extends React.Component<any> {
  shouldComponentUpdate(nextProps: any) {
    return ['hover', 'isDroppable', 'className', 'isOverCurrent', 'children', 'content'].reduce((shouldUpdate, key) => {
      if (shouldUpdate) return shouldUpdate;
      return this.props[key] !== nextProps[key];
    }, false);
  }
  render() {
    let {
      isDragging,
      dragSource,
      dragPreview,
      dropTarget,
      isOverCurrent,
      hover,
      isDroppable,
      toolbarRenderer = undefined,
      canDrop = true,
      canDrag = true,
      canEdit,
      className = '',
      children,
      ...rest
    } = this.props;
    className += isOverCurrent && isDroppable ? ' over' : '';
    // TODO: need to move toolbar out of here. after demo.
    let component = (
      <span className={className} {...rest}>
        <DragContainer canDrag={canDrag} over={isOverCurrent && isDroppable}>
          {dragPreview(<span>{children}</span>)}
        </DragContainer>
      </span>
    );
    component = canDrag ? dragSource(component) : component;
    component = canDrop ? dropTarget(component) : component;
    return component;
  }
}

export default compose<any>(
  ComponentDragSource(),
  ComponentDropTarget()
)(DragAndDropContainer);
