import { uniq } from 'ramda';
import { AnyAction, combineReducers } from 'redux';
import { types } from '../actions';

export { AnyAction };
const formTreeReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GET_FORM_TYPES_SUCCESS:
      // We're just instantiating the keys here, don't want to overwrite anything that's already in state
      return { ...payload, ...state };
    case types.GET_FORM_KEYS_SUCCESS:
      const myState = { ...state[payload.key] };
      payload.response.forEach((k) => (myState[k] = myState[k] || []));
      return { ...state, [payload.key]: { ...myState } };
    default:
      return state;
  }
};

const formFieldsReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GET_FORM_CUSTOM_FIELDS_REQUEST:
      return { ...state, [payload.path.join('-')]: state[payload.path.join('-')] || [] };
    case types.ADD_CUSTOM_FIELDS_TO_FORM:
      let copiedState2 = { ...state };
      copiedState2[payload.path.join('-')] = uniq([...(state[payload.path.join('-')] || []), ...payload.fields]);
      return copiedState2;
    default:
      return state;
  }
};

export default combineReducers({ tree: formTreeReducer, fields: formFieldsReducer });
