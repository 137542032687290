import * as React from 'react';

export const Support = () => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1500 1500" preserveAspectRatio="xMidYMid meet">
      <g id="layer101" fill="#e98300" stroke="none">
        <path d="M20 1443 c-6 -32 -14 -130 -17 -218 -6 -188 13 -307 68 -426 59 -128 104 -139 201 -53 153 137 234 126 269 -36 l11 -50 50 0 c75 0 126 -19 177 -67 28 -27 55 -43 70 -43 96 0 248 165 296 320 20 67 31 339 20 513 l-7 117 -563 0 -562 0 -13 -57z" />
        <path d="M1137 755 c-9 -20 -17 -39 -17 -43 0 -4 66 -6 146 -4 l147 3 -5 -104 c-4 -80 -10 -114 -27 -149 -27 -57 -44 -64 -86 -38 -39 24 -135 29 -135 6 0 -59 11 -73 80 -100 37 -14 75 -26 84 -26 49 0 127 72 152 140 18 50 31 255 20 314 l-7 36 -168 0 -167 0 -17 -35z" />
        <path d="M383 750 c-12 -5 -27 -18 -33 -30 -6 -11 -44 -53 -84 -93 -69 -67 -126 -155 -126 -193 0 -9 -9 -18 -20 -21 -19 -5 -20 -14 -20 -124 l0 -119 75 0 75 0 0 120 c0 100 -2 120 -15 120 -19 0 -19 13 1 53 54 105 109 152 172 148 35 -2 82 34 82 63 0 28 -22 64 -47 75 -28 13 -30 13 -60 1z" />
        <path d="M472 559 c-70 -35 -105 -71 -140 -148 -21 -46 -24 -64 -20 -130 5 -84 20 -121 75 -182 59 -65 124 -93 213 -94 122 0 213 56 272 168 31 58 32 182 2 247 -71 151 -252 214 -402 139z" />
        <path d="M923 534 c-26 -11 -33 -20 -33 -42 0 -25 43 -132 54 -132 2 0 19 14 36 32 27 27 30 35 21 50 -6 10 -14 39 -18 63 -6 49 -11 51 -60 29z" />
        <path d="M1018 335 c-32 -36 -60 -76 -64 -90 -3 -14 -10 -25 -15 -25 -5 0 -9 -29 -9 -65 l0 -65 41 0 40 0 -2 78 c-2 102 22 146 85 154 57 6 61 70 5 75 -18 2 -37 -13 -81 -62z" />
        <path d="M1122 283 c-70 -34 -102 -132 -65 -203 72 -139 283 -87 283 71 0 110 -118 182 -218 132z m121 -95 c9 -11 17 -29 17 -40 0 -23 -43 -68 -65 -68 -52 0 -84 53 -60 100 23 44 73 48 108 8z" />
      </g>
    </svg>
  );
};
