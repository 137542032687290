import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { HeaderThree, Text } from 'rsv8-components';
import styled from 'styled-components';
import { Resources } from '../../types';
import { AwardImage } from '../index';

const AwardRow = styled(Row)`
  margin: auto;
`;

const AwardCol = styled(Col)``;

const AwardImageCol = styled(AwardCol)`
  text-align: center;
`;

export interface AwardProps {
  award: Resources.Award;
  imageWidth?: string;
}

const Award: React.SFC<AwardProps> = (props) => {
  return (
    <AwardRow>
      <AwardImageCol xs={10} sm={4}>
        <AwardImage width={props.imageWidth || 'auto'} object={props.award} />
      </AwardImageCol>
      <AwardCol xs={14} sm={20}>
        <HeaderThree>{props.award.name}</HeaderThree>
        <Text dangerouslySetInnerHTML={{ __html: props.award.description }} />
      </AwardCol>
    </AwardRow>
  );
};

export default Award;
