import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Program } from 'xcel-api-generator/dist/alcon';
import { register, withContent, withTheme } from 'xcel-react-core';
import { getProgram } from '../../redux/actions';
import { programSelector } from '../../redux/selectors';

const RequireContentProgram: React.FC<Props> = ({ actions, children, program, programName = '' }) => {
  React.useEffect(() => {
    if (!program) {
      actions.getProgram();
    }
  }, []);

  const programMatch = React.useMemo(() => {
    return !!program && programName.split(',').some((key) => program.name.toUpperCase() === key.toUpperCase());
  }, [programName, program]);

  return <>{programMatch && children}</>;
};

const mapStateToProps = (state) => ({
  program: programSelector.selectMany(state)[0],
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ getProgram }, dispatch),
});

const mapContentToProps = (getContent) => ({
  programName: getContent('programName', { type: 'boolean', label: 'Alcon Program Name' }),
});

interface StateProps {
  program?: Program;
}
interface DispatchProps {
  actions: {
    getProgram: Function;
  };
}
interface ContentProps {
  programName: string;
}

type Props = StateProps & DispatchProps & ContentProps;

export default register('rsv8-account/RequireContentProgram')(
  connect(mapStateToProps, mapDispatchToProps),
  withContent(mapContentToProps),
  withTheme()
)(RequireContentProgram);
