import styled from 'styled-components';
import { CatalogTable, CatalogTableHeading, LinkButton } from '../../../components/ProductTable/ProductTableStyles';

export const ShoppingCartTable = styled(CatalogTable)`
  table-layout: fixed;
` as any;

export const ProductTableHeading = styled(CatalogTableHeading)`
  width: 40%;
` as any;

export const OptionsTableHeading = styled(CatalogTableHeading)`
  width: 20%;
` as any;

export const PointTableHeading = styled(CatalogTableHeading)`
  text-align: center;
  width: 15%;
` as any;

export const QuantityTableHeading = styled(CatalogTableHeading)`
  width: 10%;
` as any;

export const RemoveTableHeading = styled(CatalogTableHeading)`
  width: 15%;
` as any;

export const TableRemoveButton = styled(LinkButton)`
  display: block;
  margin: 0 auto !important;
` as any;
