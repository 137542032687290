import * as React from 'react';
import styled from 'styled-components';
import * as ReactDOM from 'react-dom';
import { withResource } from 'xcel-react-core';
import { UserPreview } from 'rsv8-user';

const DefaultView = styled.div`
    position: relative;
`;

const InfoView = styled.div<InfoViewProps>`
    position: absolute;
    background: #fff;
    box-shadow: 0 2px 17px 2px rgba(28, 39, 76, 0.09);
    padding: 15px;
    left: ${props => props.x}px;
    top: ${props => props.y}px;
`;

const ProfileMail = styled.div`
    display: flex;
    align-items: center;
`;

const EmailIcon = styled.div`
  width: 16px;
  height: 12px;
  background-image: url(${(props: any) => props.iconURI});
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 15px 0 0;
` as any;

interface InfoViewProps {
    x: number;
    y: number;
}

interface UserProfileRendererProps {
    value?: string,
    api: {
        selectAll: () => {},
    },
    data: object,
    emailImagePath: string
}

interface UserProfileState {
    hovered: boolean,
    touched:boolean,
    x: number,
    y: number,
}

interface UserProfileComponent {
    interval: number
}

class UserProfile extends React.Component <UserProfileRendererProps, UserProfileState, UserProfileComponent> {

    interval: number

    constructor(props: UserProfileRendererProps) {
        super(props);
        this.state = {
            hovered: false,
            touched:false,
            x: 0,
            y: 0
        }
    }

    showInfo = (e) => {
        const rect = e.currentTarget.getBoundingClientRect();
        this.interval = window.setTimeout(() =>
          this.setState({
              hovered: true,
              touched:true,
              x: rect.x,
              y: 25 + rect.top + window.scrollY
          }), 200)

    };

    hideInfo = () => this.setState({ hovered: false,touched:false }, () => window.clearInterval(this.interval));

    render() {
        const { state, props } = this
        const { value, emailImagePath, data} = props;
        return (
          <DefaultView onMouseEnter={this.showInfo} onTouchStart={this.showInfo} onTouchEnd={this.hideInfo} onMouseLeave={this.hideInfo}>
              {value && emailImagePath &&
              <ProfileMail>
                  <EmailIcon iconURI={emailImagePath}/>{value}
              </ProfileMail>
              }

              {(state.hovered ||state.touched) && ReactDOM.createPortal(
                <InfoView x={state.x} y={state.y}>
                    {data && data[ 'userid' ] && <UserPreview userId={data[ 'userid' ]}/>}
                </InfoView>,
                document.getElementsByTagName('body')[ 0 ]

              )

            }

          </DefaultView>
        )
    }
}

const mapResource = (getResource) => ({
    emailImagePath: getResource('stickyMenu.emailImagePath', '/clientassets/images/icons/email.svg')
});

export default withResource(mapResource)(UserProfile);
