import * as React from 'react';
import { Text } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import { CheckoutConfirmSubHeading, ConfirmationNumberContainer } from './styles';

export interface ConfirmationNumberProps {
  orderInfo: any;
  confirmationNumberHeading: string;
}
class ConfirmationNumber extends React.Component<ConfirmationNumberProps, any> {
  render() {
    const { confirmationNumberHeading, orderInfo } = this.props;

    return (
      <ConfirmationNumberContainer>
        <CheckoutConfirmSubHeading themeVariation="default-sm-margin">
          {confirmationNumberHeading}
        </CheckoutConfirmSubHeading>
        <Text themeVariation="secondary-text">#{orderInfo.id}</Text>
      </ConfirmationNumberContainer>
    );
  }
}

const mapResourceToProps = (getResource) => ({
  confirmationNumberHeading: getResource(
    'catalog.checkoutConfirmation.confirmationNumberHeading',
    'Confirmation Number'
  )
});

export default withResource(mapResourceToProps)(ConfirmationNumber);
