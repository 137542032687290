import { push } from 'connected-react-router';
import * as React from 'react';
import { NavDropdown as BSNavDropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { touchDetect } from 'xcel-util';
import { MenuContext } from '../ModularMenus/NavigationContext';

interface NavDropdownProps {
  id: any;
  dispatch: any;
  className: any;
  title: any;
  targetUrl: any;
}

interface NavDropdownState {
  isTouchWidth: any;
}

class NavDropdown extends React.Component<NavDropdownProps, NavDropdownState> {
  state = {
    isTouchWidth: false
  };

  checkWindowWidth = () => {
    let windowWidth = window.innerWidth;

    if (windowWidth > 992 && this.state.isTouchWidth === true) {
      this.setState({ isTouchWidth: false });
    } else if (windowWidth < 992 && this.state.isTouchWidth === false) {
      this.setState({ isTouchWidth: true });
    } else {
      return;
    }
  };

  componentDidMount() {
    window.addEventListener('resize', this.checkWindowWidth);
    this.checkWindowWidth();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.checkWindowWidth);
  }

  render() {
    return (
      <MenuContext.Consumer {...this.props}>
        {({ isOpen, open, close, closeAll }: any) => {
          const handleClick = (href) => (e) => {
            if (touchDetect() === false && this.state.isTouchWidth === false) {
              this.props.dispatch(push(href));
              closeAll();
            } else {
              if (isDropdownDropped() === false) {
                open(this.props.id);
              } else {
                closeAll();
              }
            }
          };

          const handleMouseEnter = () => {
            touchDetect() || this.state.isTouchWidth === true ? null : open(this.props.id);
          };

          const handleMouseLeave = () => {
            close(this.props.id);
          };

          const isDropdownDropped = () => {
            return isOpen(this.props.id);
          };
          const stopOnTouchPropagation = (e) => {
            e.stopPropagation();
          };

          return (
            <BSNavDropdown
              id={this.props.id.toString()}
              className={this.props.className && this.props.className}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              open={isDropdownDropped()}
              title={this.props.title}
              onClick={handleClick(this.props.targetUrl)}
              onTouchStart={stopOnTouchPropagation}
            >
              {this.props.children}
            </BSNavDropdown>
          );
        }}
      </MenuContext.Consumer>
    );
  }
}

export default connect()(NavDropdown as any) as any;
