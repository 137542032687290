import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { NominationsHeaderNav } from 'rsv8-navigation';
import { register, withContent } from 'xcel-react-core';
import { salesActions } from '../../redux/actions';
import { salesNavigationSelectors } from '../../redux/selectors';
import { NavItems } from './SalesNavItems';
import { DispatchProps, SalesNavProps, StateProps } from './types';

const SalesNav: React.FunctionComponent<SalesNavProps> = (props) => {
  React.useEffect(() => {
    props.actions.getApplications();
  }, []);

  return <NominationsHeaderNav title="Sales Entry" customNavItems={NavItems(props)} />;
};

const mapStateToProps = (state: any): StateProps => ({
  applications: salesNavigationSelectors.selectMany(state, null)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: bindActionCreators(
    {
      getApplications: salesActions.getApplication,
      setSalesApp: salesActions.setSalesApp
    },
    dispatch
  )
});

export default register('rsv8-sales-entry/SalesNav')(
  withContent(),
  connect<StateProps, DispatchProps>(
    mapStateToProps,
    mapDispatchToProps
  )
)(SalesNav);
