import { always } from 'ramda';
import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import AccountIcon from '../Icons/AccountIcon';
import CaretIcon from '../Icons/CaretIcon';

const AccountButtonBase = styled.button`
  height: 30px;
  width: 60px;
  margin-top: 20px;
  margin-left: 15px;
  background-color: rgba(255, 255, 255, 0);
  float: right;
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  border-radius: 50%;
  padding: 0 0 0 0;
  border: none;
` as any;

const AccountButtonAccountIcon = styled(AccountIcon)`
  width: 30px;
  float: left;
` as any;

const AccountCaretIcon = styled(CaretIcon)`
  width: 15px;
  height: 15px;
  transform: rotate(90deg);
  position: relative;
  top: 10px;
  left: -10px;
` as any;

const AccountButton = (props) => (
  <AccountButtonBase onClick={props.onAccountClick}>
    <AccountButtonAccountIcon />
    <AccountCaretIcon />
  </AccountButtonBase>
);

const mapDispatch = (dispatch) => ({
  onAccountClick: (e) => console.log('clicked account', e)
});

export default connect(
  always,
  mapDispatch
)(AccountButton) as any;
