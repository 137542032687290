import { push } from 'connected-react-router';
import { toast } from 'rsv8-components';
import { destroyRequest } from 'xcel-redux-orm';
import { getAccountSummary } from '../../actions/account/getSummary';
import { cartApiActions } from './cartApiActions';
import { getCart } from './getCart';

interface ProductGroupProps {
  productId: string;
  productName: string;
}

const postCart = (product: any, productOption?: string, productGroup?: ProductGroupProps) => async (dispatch) => {
  try {
    dispatch(destroyRequest('getCart'));

    const productId =
      product.groupedProducts && product.groupedProducts.length > 0
        ? productGroup.productId
        : product.productId
        ? product.productId
        : atob(product.id);

    const response = await dispatch(
      cartApiActions.createCart(
        {
          body: {
            productId,
            productOptionId: productOption || '',
            quantity: 1
          }
        },
        { jsonApi: true }
      )
    );

    dispatch(getCart());
    toast({
      template: 'CatalogBody',
      productImage: product.imageSm || product.productLargeImageUrl,
      productName:
        (product.groupedProducts && product.groupedProducts.length > 0 && productGroup.productName) ||
        product.name ||
        product.productName,
      productManufacturer: product.manufacturer || product.manufacturerName,
      actionText: `has been added to cart`,
      toCart: () => dispatch(push('/catalog/cart')),
      addToCart: true
    });
    dispatch(destroyRequest('getSummary'));
    dispatch(getAccountSummary());
    return response;
  } catch (e) {
    dispatch(getCart());
    throw e;
  }
};

export { postCart };
