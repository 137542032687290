import styled from 'styled-components';

import Slider from 'react-slick';

export const CircleButtonSlider = styled(Slider)`
    margin: 2%;
    width: 100%;
    padding: 0px;    
    background-color: transparent;
  ` as any;

// change hover color whatever the color is?
export const NewCircleButton = styled.button`
  border-radius: 100% !important;
  height: 50px;
  width: 50px;
  /*font-family: GothamSSm-Medium;*/
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  vertical-align: middle;
  align-content: left;
  align-items: left;
  align-self: left;
  padding: 5px;
  margin: 0px;
  white-space: normal;
  background-color: #ffffff;

   /* 
    -webkit-box-shadow: 0px 6px 0px transparent, 0px 3px 15px rgba(0,0,0,.4), inset 0px 1px 0px rgba(255,255,255,.3), inset 0px 0px 3px rgba(255,255,255,.5);
-moz-box-shadow: 0px 6px 0px transparent, 0px 3px 15px rgba(0,0,0,.4), inset 0px 1px 0px rgba(255,255,255,.3), inset 0px 0px 3px rgba(255,255,255,.5);
box-shadow: 0px 6px 0px transparent, 0px 3px 15px rgba(0,0,0,.4), inset 0px 1px 0px rgba(255,255,255,.3), inset 0px 0px 3px rgba(255,255,255,.5);
*/

-webkit-box-shadow: 0px 6px 0px transparent, 0px 3px 15px transparent, inset 0px 1px 0px transparent, inset 0px 0px 3px transparent;
-moz-box-shadow: 0px 6px 0px transparent, 0px 3px 15px transparent, inset 0px 1px 0px transparent, inset 0px 0px 3px transparent;
box-shadow: 0px 6px 0px transparent, 0px 3px 15px transparent, inset 0px 1px 0px transparent, inset 0px 0px 3px transparent;

/*
  display: inline-block;
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    background-color: #538fbe;
    padding: 20px 70px;
    font-size: 24px;
    border: 1px solid #2d6898;
    background-image: linear-gradient(bottom, rgb(73,132,180) 0%, rgb(97,155,203) 100%);
    background-image: -o-linear-gradient(bottom, rgb(73,132,180) 0%, rgb(97,155,203) 100%);
    background-image: -moz-linear-gradient(bottom, rgb(73,132,180) 0%, rgb(97,155,203) 100%);
    background-image: -webkit-linear-gradient(bottom, rgb(73,132,180) 0%, rgb(97,155,203) 100%);
    background-image: -ms-linear-gradient(bottom, rgb(73,132,180) 0%, rgb(97,155,203) 100%);
 
    background-image: -webkit-gradient(
        linear,
        left bottom,
        left top,
        color-stop(0, rgb(73,132,180)),
        color-stop(1, rgb(97,155,203))
    );
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    text-shadow: 0px -1px 0px rgba(0,0,0,.5);
    -webkit-box-shadow: 0px 6px 0px transparent, 0px 3px 15px rgba(0,0,0,.4), inset 0px 1px 0px rgba(255,255,255,.3), inset 0px 0px 3px rgba(255,255,255,.5);
    -moz-box-shadow: 0px 6px 0px transparent, 0px 3px 15px rgba(0,0,0,.4), inset 0px 1px 0px rgba(255,255,255,.3), inset 0px 0px 3px rgba(255,255,255,.5);
    box-shadow: 0px 6px 0px transparent, 0px 3px 15px rgba(0,0,0,.4), inset 0px 1px 0px rgba(255,255,255,.3), inset 0px 0px 3px rgba(255,255,255,.5);
*/
  @media only screen and (max-width: 1300px) {
    width: 48px;
    height: 48px;
    font-size: 10px;
    font-weight: 300;
  }
  @media only screen and (max-width: 850px) {
    width: 47px;
    height: 47x;
    font-size: 10px;
    font-weight: 200;
  }
    @media only screen and (max-width: 770px) {
    width: 43px;
    height: 43px;
    font-size: 9px;
    font-weight: 200;
  }
    @media only screen and (max-width: 350px) {
    width: 43px;
    height: 43px;
    font-size: 9px;
    font-weight: 200;
  }
` as any;