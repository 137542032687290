import * as React from 'react';
import { connect } from 'react-redux';
import { ReactField } from 'rsv8-forms';
import { contentTypeSelector, contentVersionActions, contentVersionSelector } from '../../index';
import { withResource } from 'xcel-react-core';
import styled from 'styled-components';

const CanPublishMessage = styled.div`
  color: red;
`;

const PublishCheckbox = ({ version, canPublishVersion, dispatch, contentTypeId, cannotPublishVersionMessage }) => {
  const handleChangePublishStatus = (isChecked) => {
      dispatch(contentVersionActions.updatePublishStatus(contentTypeId, version.id, isChecked));
  };

  return (canPublishVersion &&
      (
          <ReactField
              component="checkbox"
              type="text"
              label="Publish?"
              checked={version.isPublished}
              onChange={handleChangePublishStatus}
          />
      )
      || (
          <CanPublishMessage>
              {cannotPublishVersionMessage}
          </CanPublishMessage>
      )
  );
};
const mapStateToProps = (state) => {
  const contentTypeId = contentTypeSelector.getActive(state);
  return {
    contentTypeId,
      version: contentVersionSelector.getActiveVersion(state, contentTypeId) || {},
      canPublishVersion: contentVersionSelector.canPublishVersion(
          state,
          contentTypeSelector.getActive(state)
      )
  };
};

const mapResourceToProps = (getResource) => ({
    cannotPublishVersionMessage: getResource('cms.publishCheckbox.cannotPublishVersionMessage', 'This page was just updated by someone else.  Your changes cannot be saved'),
});

export default withResource(mapResourceToProps)(connect(mapStateToProps, null)(PublishCheckbox));
