import * as React from 'react';
import { connect } from 'react-redux';
import { userStateSelectors } from 'rsv8-auth';
import { SORT_DIRECTIONS } from 'rsv8-components';
import {
  DataTableColumn,
  DataTableDefault,
  HeaderTwo,
  Link,
  renderDate
  } from 'rsv8-components';
import { myAwardActions } from 'rsv8-nomination';
import {
  register,
  withContent,
  withResource,
  withTheme
  } from 'xcel-react-core';
import { withApiDataProvider } from 'xcel-redux-orm';
import { numberFormat } from 'xcel-util';
import { getNominationApplication } from '../../redux/actions';
import { accountApiActions } from '../../redux/actions/accountApiActions';
import earningsSelectors from '../../redux/selectors/earningsSelectors';
import { NominationDetailModal } from './components';
import EarningDetailModal from './components/EarningDetailModal';

export interface PointEarningsTableProps {
  dispatch: Function;
  pointsShorthand: string;
  pointsLabel: string;
  pointTypeTaxable: string;
  pointTypeNonTaxable: string;
  pointReasonTableHeader: string;
  dateTableHeader: string;
  pointTypeTableHeader: string;
  pointEarningsLabel: string;
  emptyText: string;
  columns: string;
  programNameHeader: string;
  countryCode: string;
  disablePointReasonLink: string;
  pointTypeGrossupTaxable: string;
}

export interface PointEarningsTableState {
  earningDetailModalRecord: object | undefined;
  nominationDetailId: number | undefined;
}

const defaultSort = { field: 'awardDate', direction: SORT_DIRECTIONS.desc };
export class PointEarningsTable extends React.PureComponent<PointEarningsTableProps, PointEarningsTableState> {
  state = {
    earningDetailModalRecord: undefined,
    nominationDetailId: undefined
  };

  componentDidMount() {
    // Need award info for the NominationDetailModal
    this.props.dispatch(getNominationApplication({}));
  }

  fetchNominationDetail() {
    this.props.dispatch(myAwardActions.getDetails(this.state.nominationDetailId));
  }

  // No typedefs for earnings atm :(
  renderPointReason = ({ record: r }) => {
    if (
      r.nominationDetailId !== undefined &&
      r.nominationDetailId !== null &&
      this.props.disablePointReasonLink !== 'true'
    ) {
      return <Link onClick={() => this.showNominationDetailModal(r)}>{r.pointReasonCodeName}</Link>;
    } else {
      return r.pointReasonCodeName;
    }
  };
  renderPoints = ({ record: r }) => {
    return (
      <Link
        onClick={() => {
          this.showEarningDetailModal(r);
        }}
      >
        {numberFormat(r.points)} {this.props.pointsShorthand}
      </Link>
    );
  };

  showEarningDetailModal = (r) => {
    this.setState({ earningDetailModalRecord: r });
  };

  hideEarningDetailModal = (r) => {
    this.setState({ earningDetailModalRecord: undefined });
  };

  showNominationDetailModal = (r) => {
    this.setState({ nominationDetailId: r.nominationDetailId }, () => {
      this.fetchNominationDetail();
    });
  };

  hideNominationDetailModal = () => {
    this.setState({ nominationDetailId: undefined });
  };

  renderTaxType = ({ record }) => {
    if (record.taxationType === 'non_taxable') {
      return this.props.pointTypeNonTaxable;
    }
    return record.taxationType === 'grossup_taxable' ? this.props.pointTypeGrossupTaxable : this.props.pointTypeTaxable;
  };

  renderColumns = () => {
    let columnsResource = this.props.columns.split(',');
    // nonTaxable below should eventually be taxType
    const columns = {
      programName: <DataTableColumn field="programName">{this.props.programNameHeader}</DataTableColumn>,
      awardDate: (
        <DataTableColumn field="awardDate" renderCell={renderDate}>
          {this.props.dateTableHeader}
        </DataTableColumn>
      ),
      pointReasonCodeName: (
        <DataTableColumn field="pointReasonCodeName" renderCell={this.renderPointReason}>
          {this.props.pointReasonTableHeader}
        </DataTableColumn>
      ),
      nonTaxable: (
        <DataTableColumn field="nonTaxable" renderCell={this.renderTaxType} sortable={false}>
          {this.props.pointTypeTableHeader}
        </DataTableColumn>
      ),
      points: (
        <DataTableColumn field="points" renderCell={this.renderPoints}>
          {this.props.pointsLabel}
        </DataTableColumn>
      )
    };

    if (this.props.countryCode !== 'USA') {
      columnsResource = columnsResource.filter((item) => item !== 'nonTaxable');
    }

    return columnsResource.map((key) => columns[key]);
  };

  render() {
    return (
      <React.Fragment>
        <HeaderTwo>{this.props.pointEarningsLabel}</HeaderTwo>
        <DataTableDefault {...this.props} defaultSort={defaultSort} emptyText={this.props.emptyText}>
          {this.renderColumns()}
        </DataTableDefault>
        <NominationDetailModal
          nominationDetailId={this.state.nominationDetailId}
          handleClose={this.hideNominationDetailModal}
        />
        <EarningDetailModal earning={this.state.earningDetailModalRecord} handleClose={this.hideEarningDetailModal} />
      </React.Fragment>
    );
  }
}

const mapResource = (getResource) => ({
  pointEarningsLabel: getResource('accountBalance.pointEarningsLabel', 'Point Earnings'),
  pointsShorthand: getResource('account.pointsShorthand', 'pts.'),
  pointsLabel: getResource('account.pointsTableHeader', 'Points'),
  pointTypeTableHeader: getResource('account.pointTypeTableHeader', 'Point Type'),
  pointTypeTaxable: getResource('account.pointType.taxable', 'Non-service Points'),
  pointTypeNonTaxable: getResource('account.pointType.nonTaxable', 'Service Points'),
  pointTypeGrossupTaxable: getResource('account.pointType.grossupTaxable', 'Gross-up Taxable Points'),
  pointReasonTableHeader: getResource('account.pointReasonTableHeader', 'Reason'),
  dateTableHeader: getResource('account.dateTableHeader', 'Date'),
  programNameHeader: getResource('account.programNameHeader', 'Program Name'),
  emptyText: getResource('accountAdjustmentsTable.emptyText', 'You have no point earnings'),
  columns: getResource('account.pointTableColumns', 'awardDate,programName,pointReasonCodeName,nonTaxable,points'),
  disablePointReasonLink: getResource('account.disablePointReasonLink', 'false')
});
const getEarnings: any = (args) => {
  return accountApiActions.getAccountEarnings(args, { jsonApi: true });
};
getEarnings.endpoint = 'getEarnings';

const mapStateToProps = (state) => ({
  countryCode: userStateSelectors.getUser(state).countryCode
});

export default register('rsv8-account/PointEarningsTable')(
  connect(mapStateToProps),
  withResource(mapResource),
  withApiDataProvider(getEarnings, earningsSelectors, { sort: defaultSort }),
  withContent(),
  withTheme()
)(PointEarningsTable);
