import * as React from 'react';
import { ControlLabel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { formSelectors } from 'rsv8-forms';
import { FontText } from './styles';
const FontAddPreview = ({ font }) => {
  return (
    <div>
      <ControlLabel>Preview</ControlLabel>
      <FontText font={font} size={'42px'}>
        Aa
      </FontText>
    </div>
  );
};

const mapStateToProps = (state) => {
  const values = formSelectors.getFormValues('fontAdd')(state);
  return {
    font: values || {}
  };
};
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FontAddPreview);
