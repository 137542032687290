import * as React from 'react';
import { HeaderTwo } from 'rsv8-components';
import styled, { StyledComponentClass } from 'styled-components';

((_1?: StyledComponentClass<any, any>, _2?: typeof React) => 0)();

export const StyledHeader = styled(HeaderTwo)`
  position: relative;

  .overlay-popover-icon {
    margin-left: 10px;
    position: static;
  }
`;

export const LocationsComponentBody = styled.div`
  ::-webkit-scrollbar {
    width: 7px;
    border-radius: 3.5px;
    background-color: #e1e1e1;
  }

  ::-webkit-scrollbar-track {
    border-radius: 3.5px;
    background-color: #ffffff;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 3.5px;
    background-color: #e1e1e1;
  }
` as any;

export const LocationContainer = styled.div`
  border: 1px solid #b2b2b2;
  border-radius: 5px;
  overflow: hidden;
  @media screen and (min-width: 376px) and (max-width: 767px) {
    display: flex;
    flex-direction: row;
  }
  @media screen and (min-width: 768px) and (max-width: 1365px) {
    display: flex;
    flex-direction: row;
    flex: 1 50%;
  }
`;

export const LocationHeader = styled.div`
  background-color: #fafafa;
  padding: 10px 15px;
  flex: 1;
  border-bottom: 1px solid #b2b2b2;

  .location__title {
    font-size: 10px;
    font-weight: bold;
    color: #505d6f;
    line-height: 1.4;
    text-align: center;
  }

  .location__subtitle {
    font-size: 10px;
    color: #505d6f;
    line-height: 1.4;
    text-align: center;
  }

  @media screen and (min-width: 376px) and (max-width: 1365px) {
    border-bottom-right-radius: 0;
    border-right: 1px solid #b2b2b2;
    border-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 260px;
    padding: 15px;

    .location__title {
      padding-bottom: 10px;
    }
  }
`;

export const LocationBody = styled.div`
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 15px;
  display: flex;
  justify-content: space-around;
  flex: 1;

  .location__detail-label {
    font-size: 10px;
    font-weight: bold;
    color: #505d6f;
    line-height: 1.4;
    text-align: center;
  }

  .location__detail {
    font-size: 10px;
    color: #505d6f;
    line-height: 1.4;
    text-align: center;
    padding: 0 10px;
  }

  .location__detail-values {
    padding-top: 8px;
  }

  .location__detail-value + .location__detail-value {
    border-left: 1px solid #b2b2b2;
    padding-left: 15px;
    margin-left: 15px;
    color: #505d6f;
    line-height: 1.4;
    font-size: 10px;
  }

  @media screen and (min-width: 376px) {
    border-bottom-left-radius: 0;
  }
  @media screen and (min-width: 376px) and (max-width: 991px) {
    padding: 15px;
  }
`;
