import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon, Loader } from 'rsv8-components';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';
import { deleteFavorite } from '../../redux/actions/product/deleteFavorite';
import { getFavoriteProducts } from '../../redux/actions/product/getFavorites';
import { postFavorite } from '../../redux/actions/product/postFavorite';
import { getPostFavoriteRequest } from '../../redux/selectors';
import { ProgramLevelProduct } from 'xcel-api-generator/dist/achievement';

const FavoritesIcon = styled(Icon as any)`
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 15px;
`;

const FavoritesUncheckedIcon = styled(FavoritesIcon)`
  color: #7c8180;
`;
const FavoritesCheckedIcon = styled(FavoritesIcon)`
  color: #4192a5;
`;

const LoaderContainer = styled.div`
  top: 18px;
  cursor: pointer;
  position: absolute;
  right: 20px;
` as any;

interface FavoritesButtonProps {
  actions: {
    deleteFavorite: any;
    getFavoriteProducts: any;
    postFavorite: any;
  };
  favorites: any;
  postFavoriteRequest: any;
  product: ProgramLevelProduct;
  activeIcon: string;
  inactiveIcon: string;
}

class FavoritesButton extends React.Component<FavoritesButtonProps, any> {
  state = {
    isDisabled: false
  };

  addFavorite = (product) => {
    if (!this.state.isDisabled) {
      this.setState({ isDisabled: true });
      this.props.actions.postFavorite(product).then(() => {
        this.setState({ isDisabled: false });
      });
    }
  };

  removeFavorite = (product) => {
    if (!this.state.isDisabled) {
      this.setState({ isDisabled: true });
      this.props.actions.deleteFavorite(product).then(() => {
        this.setState({ isDisabled: false });
      });
    }
  };

  render() {
    const { favorites, product, activeIcon, inactiveIcon } = this.props;
    if (this.state.isDisabled) {
      return (
        <LoaderContainer>
          <Loader width={20} strokeWidth={2} />
        </LoaderContainer>
      );
    }
    if (favorites.includes(product.productId)) {
      return (
        <FavoritesCheckedIcon
          icon={activeIcon}
          onClick={() => this.removeFavorite(product)}
          themeVariation="catalog-favorites-icon"
        />
      );
    }
    return (
      <FavoritesUncheckedIcon
        icon={inactiveIcon}
        onClick={() => this.addFavorite(product)}
        themeVariation="catalog-favorites-icon"
      />
    );
  }
}

const mapResourceToProps = (getResource) => ({
  activeIcon: getResource('catalog.favoriteIcon.active', 'heart'),
  inactiveIcon: getResource('catalog.favoriteIcon.inactive', 'heart-o'),
});

const mapStateToProps = (state) => ({
  postFavoriteRequest: getPostFavoriteRequest(state)
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      deleteFavorite,
      getFavoriteProducts,
      postFavorite
    },
    dispatch
  )
});

export default withResource(mapResourceToProps)(
  connect(mapStateToProps, mapDispatchToProps
)(FavoritesButton));
