import { Table } from 'react-bootstrap';
import { Button, Link } from 'rsv8-components';
import styled from 'styled-components';

export const CatalogTable = styled(Table)`
  color: #4c4f4f;
` as any;

export const CatalogTableHead = styled.thead`` as any;

export const CatalogTableHeadRow = styled.tr`` as any;

export const CatalogTableHeading = styled.th`
  border-bottom: double #ededed !important;
  color: #7c8180;
` as any;

export const CatalogTableBody = styled.tbody`` as any;

export const CatalogTableRow = styled.tr`
  &:first-of-type {
    td {
      border-top: 0 !important;
    }
  }
  &:last-of-type {
    td {
      border-bottom: 1px solid #ededed !important;
    }
  }
` as any;

export const CatalogTableData = styled.td`
  border-top: 1px solid #ededed !important;
  padding: 18px;
  vertical-align: middle !important;
` as any;

export const CatalogTableDataPoints = styled(CatalogTableData)`
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
` as any;

export const CatalogPointValueData = styled(CatalogTableData)`
  font-weight: bold;
  padding-right: 25px;
` as any;

export const CatalogTableInfoLink = styled(Link)`
  color: #4192a5;
  width: fit-content;

  &:active,
  &:focus,
  &:hover {
    color: #4192a5;
    outline: none;
    text-decoration: none;
  }
` as any;

export const CatalogTableInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 15px;
  max-width: 320px;
` as any;

export const CatalogTableInfoProduct = styled(Link)`
  color: #4c4f4f;

  @media (min-width: 769px) {
    font-size: 16px;
  }
  &:active,
  &:focus,
  &:hover {
    color: #4c4f4f;
    outline: none;
    text-decoration: none;
  }
` as any;

export const CatalogTableInfoImage = styled.img`
  height: 97px;
  width: auto;
` as any;

export const CatalogTableInfo = styled.td`
  border-top: 1px solid #ededed !important;
  display: flex;
  flex-direction: row;
` as any;

export const LinkButton = styled(Button)`
  color: #4192a5;
  padding: 0;

  &:active,
  &:focus,
  &:hover {
    box-shadow: none !important;
    color: #4192a5;
    outline: none !important;
    text-decoration: none;
  }
` as any;

export const AddToCartButton = styled(Button)`
  border-radius: 3px;
  max-width: 156px;
  width: 100%;

  &:active,
  &:focus,
  &:hover {
    background: #fff;
    box-shadow: none !important;
    outline: none !important;
    text-decoration: none;
  }
` as any;

export const CartButton = styled(Button)`
  border-radius: 3px;
  border: solid 1px #c8c8c8;
  color: #4c4f4f;

  &:active,
  &:focus,
  &:hover {
    background: #fff;
    border: solid 1px #c8c8c8;
    box-shadow: none !important;
    color: #4c4f4f;
    outline: none !important;
    text-decoration: none;
  }
` as any;
