import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { userActions } from 'rsv8-auth';
import { AddBehavior } from 'rsv8-components';
import { register, withContent } from 'xcel-react-core';

const externalLinkRegex = /^https?:\/\//;

const ConsentBehavior = ({ dispatch, hasConsented, onClick, ...props }) => {
  const handleClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const href = e.target.attributes.href ? e.target.attributes.href.value : '/home';
    try {
      await dispatch(userActions.setConsent(hasConsented));
      if (href) {
        if (externalLinkRegex.test(href)) {
          window.location.replace(href);
        } else {
          dispatch(push(href));
        }
      }
    } catch (e) {
      console.error('Failed to consent.', e);
      alert('Failed to consent.');
    }
    // fire parent onClick
    if (onClick) {
      onClick(e);
    }
  };

  return <AddBehavior {...props} onClick={handleClick} />;
};

const mapContentToProps = (getContent) => ({
  hasConsented: getContent('hasConsented', { type: 'checkbox', label: 'Is User Consenting?' })
});

export default register('rsv8-app/ConsentBehavior')(connect(), withContent(mapContentToProps))(ConsentBehavior);
