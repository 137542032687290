import * as React from 'react';
import { Panel } from 'react-bootstrap';
import { Text } from 'rsv8-components';
import { CustomFields } from 'rsv8-customfields';
import styled from 'styled-components';
import BetterParagraph from '../../../components/CustomFieldValue/BetterParagraph';
import { LoaderGhost } from '../Loader/index';

const PanelInfo = styled(Panel)`
  border: 0px;
  box-shadow: none;
  padding-top: 20px;
  padding-left: 30px;
  padding-bottom: 10px;
  @media (max-width: 767px) {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 10px;
  }
  p {
    color: #4c4f4f;
    font-size: 14px;
  }
`;

const ParagraphPadding = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
`;

type AwardInfoProps = {
  open: boolean;
  details: any;
};

const doNothing = () => ({});

const AwardInfo = ({ open, details }: AwardInfoProps) => {
  const nominatorInfo = details && details.nominatorInfo;

  let firstName = nominatorInfo && nominatorInfo[0].value;
  let lastName = nominatorInfo && nominatorInfo[1].value;
  let detailInfo =
    details &&
    details.customFields &&
    details.customFields.reduce((acc, field) => ({ ...acc, [field.customFieldId]: field.value }), {});

  return (
    <PanelInfo expanded={open} onToggle={doNothing}>
      <Panel.Collapse>
        {detailInfo && (
          <React.Fragment>
            <ParagraphPadding>
              <Text variationName="field-label">Nominated by</Text>
              <br />
              <Text>{`${firstName} ${lastName}`}</Text>
            </ParagraphPadding>
            <CustomFields
              formType={'NominationForm'}
              groupKey={['criteria', 'details']}
              appKey={details.applicationId}
              component={BetterParagraph}
              customFieldValues={detailInfo}
            />
          </React.Fragment>
        )}
        {!detailInfo ? <LoaderGhost /> : null}
      </Panel.Collapse>
    </PanelInfo>
  );
};

export default AwardInfo;
