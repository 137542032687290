import * as React from 'react';
import { Text } from 'rsv8-components';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';
import { ShippingInfoLabel, ShippingInfoValue } from '../ShippingInfo/styles';
import { CheckoutModuleHeading } from '../styles';

export const ContactInfoTable = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
` as any;

const ContactInfoWrapper = styled.div`
  margin-top: 20px;
` as any;

class ContactInfo extends React.Component<any, any> {
  render() {
    const { userProfile, contactInfoHeading, emailLabel, altEmailLabel, phoneLabel } = this.props;
    return (
      <ContactInfoWrapper>
        <CheckoutModuleHeading themeVariation="default-sm-margin">{contactInfoHeading}</CheckoutModuleHeading>
        <ContactInfoTable>
          <ShippingInfoLabel>
            <Text themeVariation="secondary-text">{emailLabel}</Text>
          </ShippingInfoLabel>
          <ShippingInfoValue>
            <Text themeVariation="secondary-text">{userProfile.email}</Text>
          </ShippingInfoValue>

          <ShippingInfoLabel>
            <Text themeVariation="secondary-text">{altEmailLabel}</Text>
          </ShippingInfoLabel>
          <ShippingInfoValue>
            <Text themeVariation="secondary-text">{userProfile.alternateEmail || `-`}</Text>
          </ShippingInfoValue>

          <ShippingInfoLabel>
            <Text themeVariation="secondary-text">{phoneLabel}</Text>
          </ShippingInfoLabel>
          <ShippingInfoValue>
            <Text themeVariation="secondary-text">{userProfile.phone}</Text>
          </ShippingInfoValue>
        </ContactInfoTable>
      </ContactInfoWrapper>
    );
  }
}

const mapResourceToProps = (getResource) => ({
  contactInfoHeading: getResource('catalog.checkout.contactInfoHeading', 'Contact Information'),
  emailLabel: getResource('catalog.checkout.emailLabel', 'Email'),
  altEmailLabel: getResource('catalog.checkout.altEmailLabel', 'Alternative Email'),
  phoneLabel: getResource('catalog.checkout.phoneLabel', 'Phone')
});

export default withResource(mapResourceToProps)(ContactInfo);
