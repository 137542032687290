import styled from 'styled-components';

export const ModalBodyContainer = styled.div`
  font-family: open-sans,sans-serif;
  font-size: 15px;
  padding: 0 10px 50px 10px;
  text-align: center;
` as any;

export const OrderSummaryHeader = styled.div`
  font-size: 28px;
  font-family: 'open-sans-bold';
  margin-bottom: 10px;

  @media only screen and (max-width: 760px) {
    font-size: 25px;
  }
` as any;

export const RedemptionImage = styled.img`
  margin: 0 auto;
  text-align: center;
  width: 350px;
  margin: 5px 0 15px;

  @media only screen and (max-width: 760px) {
    width: 230px;
  }
` as any;

export const AmountValue = styled.div`
  font-family: 'open-sans-bold';
  margin: 5px 0 15px;
` as any;

export const QuantityValue = styled.div`
  font-family: 'open-sans-bold';
  margin: 5px 0 15px;
` as any;

export const OrderTotalValue = styled.div`
  font-family: 'open-sans-bold';
  margin: 5px 0 15px;
` as any;

export const ConfirmationNumberValue = styled.div`
  font-family: 'open-sans-bold';
  margin: 5px 0 25px;
` as any;

export const RebateOptionsButton = styled.a`
  background-color: #003595;
  border-radius: 30px;
  border: 2px solid #003595;
  color: #ffffff;
  display: block;
  font-family: open-sans;
  font-size: 15px;
  margin: 0 auto 15px;
  padding: 11px 0;
  text-transform: uppercase;
  width: 275px;

  :hover {
    background-color: #ffffff;
    border: 2px solid #003595;;
    color: #003595;
    cursor: pointer;
    text-decoration: none;
  }

  @media only screen and (max-width: 760px) {
    margin: 0 auto 10px;
    width: 215px;
  }
` as any;

export const DashboardButton = styled.a`
  background-color: #ffffff;
  border-radius: 30px;
  border: 2px solid #00aeef;;
  color: #00aeef;
  display: block;
  font-family: open-sans;
  font-size: 15px;
  margin: 0 auto 15px;
  padding: 11px 0;
  text-transform: uppercase;
  width: 275px;

  :hover {
    background-color: #00aeef;
    border: 2px solid #00aeef;
    color: #ffffff;
    cursor: pointer;
    text-decoration: none;
  }

  @media only screen and (max-width: 760px) {
    margin: 0 auto 10px;
    width: 215px;
  }
` as any;
