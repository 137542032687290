import cn from 'classnames';
import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Image } from 'rsv8-components';
import { ProgramLevel, ProgramLevelProduct } from 'xcel-api-generator/dist/achievement';
import {
  register,
  withContent,
  withResource,
  withTheme
  } from 'xcel-react-core';
import { getFavoriteList } from '../../redux/selectors';
import CarouselRewardCard from '../CarouselCard/CarouselCard';
import ItemCarousel from '../ItemCarousel/ItemCarousel';
import {
  AchievementCarouselWrapper,
  AchievementLevelBlock,
  AchievementLevelCardWrapper,
  AchievementLevelHeading,
  AchievementRewardsContainer,
  AchievementRewardsContent,
  AchievementRewardsHeading,
  AchievementRewardsHeadingText,
  AchievementRewardsHeadingTitle,
  AchievementRewardsLevelCheckIcon,
  AlertHeading
  } from './styles';

interface Props {
  dispatch: any;
  title: string;
  text: string;
  levels: ProgramLevel[];
  redeemed?: boolean;
  achieved?: boolean;
  favoritesList: any;
  className: string;
  dontDelay: string;
  daysLeft: string;
}

const AchievementRewards: React.FC<Props> = ({
  title,
  text,
  levels,
  favoritesList,
  redeemed,
  achieved,
  className,
  dontDelay,
  daysLeft
}) => {
  if (!levels.some(l => l.products.length > 0)) {
    return null;
  }

  const renderDaysLeft = (level) => {
    const todaysDate = moment();
    const redeemByDate = moment(level.mustRedeemByDate);
    return redeemByDate.diff(todaysDate, 'days');
  };

  const renderLevel = (level: ProgramLevel, index: number) => {
    const productsList = Array.isArray(level.products) ? level.products : [level.products];
    if (!productsList.length) {
      return null;
    }

    if (redeemed) {
      return (
        <AchievementLevelBlock key={index} className={'redeemed'}>
          <AchievementLevelHeading>{level.name}</AchievementLevelHeading>
          <AchievementLevelCardWrapper>
            <CarouselRewardCard
              product={productsList.find((p: ProgramLevelProduct) => p.redeemedDate && p.orderId)}
              level={level}
              favoritesList={[]}
            />
          </AchievementLevelCardWrapper>
        </AchievementLevelBlock>
      );
    }

    return (
      <AchievementLevelBlock key={index} className={cn({ row: !redeemed && !achieved, achieved })}>
        {achieved && (
          <AchievementRewardsLevelCheckIcon>
            <Image themeVariation="checkmark-pink" />
          </AchievementRewardsLevelCheckIcon>
        )}
        <AchievementLevelHeading themeVariation="equal-margins">{level.name}</AchievementLevelHeading>
        {achieved && !redeemed && <AlertHeading
          themeVariation="black"
          dangerouslySetInnerHTML={{ __html: `${dontDelay} <span>${renderDaysLeft(level)}</span> ${daysLeft}` }}
        />}
        <AchievementCarouselWrapper>
          <ItemCarousel favoritesList={favoritesList} level={level} products={productsList} dataLoaded={true} />
        </AchievementCarouselWrapper>
      </AchievementLevelBlock>
    );
  };

  return (
    <AchievementRewardsContainer className={className}>
      <AchievementRewardsHeading>
        <AchievementRewardsHeadingTitle className="rewards-heading-title">{title}</AchievementRewardsHeadingTitle>
        {text && <AchievementRewardsHeadingText>{text}</AchievementRewardsHeadingText>}
      </AchievementRewardsHeading>
      <AchievementRewardsContent className={cn({ redeemed })}>
        {levels.map(renderLevel)}
      </AchievementRewardsContent>
    </AchievementRewardsContainer>
  );
};

const mapStateToProps = (state) => ({
  favoritesList: getFavoriteList(state)
});

const mapResourceToProps = (getResource) => ({
  title: getResource('achievement.programLanding.levels.listTitle', 'Your achievement levels'),
  dontDelay: getResource('achievement.programLanding.levels.daysLeft', 'Don’t delay! You have'),
  daysLeft: getResource('achievement.programLanding.levels.daysLeft', 'days to make your selection.')
});

export default register('rsv8-achievement/AchievementRewards')(
  connect(mapStateToProps),
  withRouter,
  withContent(),
  withTheme(),
  withResource(mapResourceToProps)
)(AchievementRewards) as any;
