export const RandomRange = (min, max) => {
  return Math.random() * (max - min) + min;
};
export const generateDelay = () => {
  const min = 0;
  const max = 3;
  return {
    delay: RandomRange(min, max),
    min,
    max
  };
};
export const defaultDuration = 3.5;
