import { contentCultureActions, contentVersionActions, routeActions } from '.';
import { destroyRequest } from 'xcel-redux-orm';
import { convertToPatchOperations } from 'xcel-util';
import * as events from '../events';
import { contentDetailSelector, routeSelector } from '../selectors';
import { ContentDetailModel } from '../selectors/contentDetail';
import cmsAdminApi from './cmsAdminApi';
const {
  getAdminContentsById,
  getAdminContents,
  getAdminContentculture,
  getAdminContentversionsByContentCultureId,
  createAdminRoutesByIdContent,
  patchAdminContentsById,
  deleteAdminContentsById
} = cmsAdminApi;
const contentActions = {
  create: (contentTypeId, routeId: any, key: string) => async (dispatch, getState) => {
    await dispatch(createAdminRoutesByIdContent({ id: routeId, body: { isPublished: true, key, name: 'Undefined' } }));
    dispatch(destroyRequest('getAdminRoutes'));
    dispatch(routeActions.getById(contentTypeId, routeId));
  },
  delete: (contentTypeId, id: any) => async (dispatch, getState) => {
    try {
      await dispatch(deleteAdminContentsById({ id }));
    } catch (e) {
      alert(`Cannot delete content: ${e.message}`);
    }
    dispatch(destroyRequest('getAdminRoutes'));
    let activeRoute = routeSelector.getActive(getState(), contentTypeId)[0];
    dispatch(routeActions.getById(contentTypeId, activeRoute.id));
  },
  patch: (contentTypeId, id: any, content: Partial<ContentDetailModel>) => async (dispatch, getState) => {
    const operations = convertToPatchOperations(content);
    const response = await dispatch(patchAdminContentsById({ id, body: operations }));
    dispatch(destroyRequest('getAdminContentsById'));
    dispatch(contentActions.getById(contentTypeId, id));
    return response;
  },
  getById: (contentTypeId: string, id: any, contentCultureId?: number) => async (dispatch, getState) => {
    const response = await dispatch(getAdminContentsById({ id }));
    const state = getState();
    dispatch(contentActions.setActive(contentTypeId, id.toString()));
    const content = contentDetailSelector.selectOne(state, id.toString(), { includeTypes: true, eager: true });
    const hasCultures = Array.isArray(content.cultures);
    const hasAuthenticate = content.cultures.find((contentCulture) => {
      return contentCulture.provider.id === 'Authenticate';
    });
    const culture =
      hasCultures &&
      (contentCultureId
        ? content.cultures.find((contentCulture) => contentCulture.id === contentCultureId)
        : hasAuthenticate !== undefined
        ? hasAuthenticate
        : content.cultures.find((contentCulture) => contentCulture.cultureId === 0));
    if (culture) {
      dispatch(contentCultureActions.setActive(contentTypeId, culture.id));
      await dispatch(contentVersionActions.get(contentTypeId, culture.id));
    } else {
      // need to create the content culture and set a provider for it
      // if newly created page is the login page, set the provider to Default
      const key = response.data.attributes.key;
      const provider = key === 'login' ? 'Default' : 'Authenticate';
      await dispatch(contentCultureActions.create(contentTypeId, id, 0, provider)).then(({ data }) => {
        dispatch(contentCultureActions.setActive(contentTypeId, data.id));
        dispatch(contentVersionActions.get(contentTypeId, data.id));
      });
    }
    return response;
  },
  getByTypeAndKeyAndRoute: (type: string, key: string, parentRouteName: string) => async (dispatch, getState) => {
    const response: any = await dispatch(
      getAdminContents({
        filter: `route.contentType.id = "${type}"  && key = "${key}" && route.routeNodeName = "${parentRouteName}"`
      })
    );

    const contentId = response && response.data && response.data[0] && response.data[0].id;
    if (contentId !== undefined) {
      const cultures = await dispatch(getAdminContentculture({ filter: `contentId = ${contentId}` }));
      const cultureId = cultures && cultures.data && cultures.data[0] && cultures.data[0].id;
      if (cultureId !== undefined) {
        const publishedVersion = await dispatch(
          getAdminContentversionsByContentCultureId({
            contentCultureId: cultureId,
            offset: 0,
            limit: 1,
            sort: 'publishedDateTimeUtc-'
          })
        );
        const publishedVersionId =
          publishedVersion && publishedVersion.data && publishedVersion.data[0] && publishedVersion.data[0].id;
        return publishedVersionId;
      }
    }
  },
  setActiveProperty: (contentTypeId: string, property: string, value: any) => {
    return {
      type: events.CONTENT_ACTIVE_PROPERTY_SET,
      payload: { property, value },
      contentTypeId
    };
  },
  setEditProperty: (contentTypeId: string, data: any) => ({
    type: events.CONTENT_EDIT_PROPERTY_SET,
    payload: { data },
    contentTypeId
  }),
  setEditContentProperty: (contentTypeId: string, data: any) =>
    contentActions.setEditProperty(contentTypeId, { content: data }),
  setEdit: (contentTypeId: string, content: any) => ({
    type: events.CONTENT_EDIT_SET,
    payload: content,
    contentTypeId
  }),
  setRaw: (contentTypeId: string, raw: any) => ({
    type: events.CONTENT_RAW_SET,
    payload: raw,
    contentTypeId
  }),
  setActive: (contentTypeId: string, contentId: string) =>
    contentActions.setActiveProperty(contentTypeId, 'contentId', contentId)
};
export default contentActions;
