import * as React from 'react';
import { NarrowGrid as Grid, NominationSummary, IndividualCongratulationsInstructions } from '../../components/index';
import { register } from 'xcel-react-core';

const CompleteNomination = () => (
  <Grid>
    <IndividualCongratulationsInstructions />
    <NominationSummary editable={false} />
  </Grid>
);

const compose = register('rsv8-nomination/IndividualNominationComplete');

export default compose()(CompleteNomination);
