import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  HeaderDecoration,
  HeaderOne,
  Image,
  Text
  } from 'rsv8-components';
import { SubmissionError } from 'rsv8-forms';
import {
  register,
  withContent,
  withResource,
  withTheme
  } from 'xcel-react-core';
import { LayoutContainer } from '../../../components/Layout';
import { LoginMaxWidth } from '../../../components/LoginMaxWidth';
import { userForgotPassword } from '../../redux/user.actions';
import RequestPasswordResetForm from './RequestPasswordResetForm';

const RequestPasswordReset = ({
  actions,
  resource: {
    resetPasswordHeaderText,
    resetPasswordParagraphText,
    errorMessageText,
    resetPasswordSent,
    userNotFound,
    userHasNoEmail
  }
}) => {
  let handleSubmit = (params) => {
    if (!params.userName) {
      throw new SubmissionError({
        username: '',
        _error: errorMessageText
      });
    }
    return actions.userForgotPassword(params, {
      validatedRedirect: resetPasswordSent,
      invalidatedRedirect: userNotFound,
      noEmailRedirect: userHasNoEmail
    });
  };

  return (
    <div>
      <HeaderDecoration />
      <Col xs={22} xsOffset={1} lg={22} lgOffset={1} md={22} mdOffset={1}>
        <Image themeVariation="primary-company-logo1" />
      </Col>
      <LayoutContainer>
        <Row>
          <Col xs={22} xsOffset={1} lg={8} lgOffset={8} md={8} mdOffset={8}>
            <LoginMaxWidth>
              <Image themeVariation="icon-reset-password" />
            </LoginMaxWidth>
          </Col>
        </Row>
        <Row>
          <Col xs={22} xsOffset={1} lg={8} lgOffset={8} md={10} mdOffset={7}>
            <LoginMaxWidth>
              <HeaderOne textalign="center" className="text-nowrap">
                {resetPasswordHeaderText}
              </HeaderOne>
            </LoginMaxWidth>
          </Col>
        </Row>
        <Row>
          <Col xs={22} xsOffset={1} lg={6} lgOffset={9} md={8} mdOffset={8}>
            <LoginMaxWidth>
              <Text>{resetPasswordParagraphText}</Text>
            </LoginMaxWidth>
          </Col>
        </Row>
        <Row>
          <Col xs={22} xsOffset={1} lg={6} lgOffset={9} md={8} mdOffset={8}>
            <RequestPasswordResetForm onSubmit={handleSubmit} />
          </Col>
        </Row>
      </LayoutContainer>
    </div>
  );
};

const mapState = (state) => ({});

const mapDispatch = (dispatch) => ({
  actions: bindActionCreators({ userForgotPassword }, dispatch)
});

const mapResourceToProps = (getResource) => ({
  resource: {
    resetPasswordHeaderText: getResource('requestPasswordReset.resetPasswordHeaderText', 'Reset Your Password'),
    resetPasswordParagraphText: getResource(
      'requestPasswordReset.resetPasswordParagraphText',
      `Enter your username and we will email you a link to reset your password. We will send it to the email associated
      to your account.`
    ),
    errorMessageText: getResource(
      'requestPasswordReset.errorMessageText',
      'A username is required to reset your password.'
    ),
    resetPasswordSent: getResource('requestPasswordReset.resetPasswordSent', '/reset-password/sent'),
    userNotFound: getResource('requestPasswordReset.userNotFound', '/user-not-found'),
    userHasNoEmail: getResource('requestPasswordReset.userHasNoEmail', '/user-has-no-email')
  }
});

export default register('rsv8-auth/RequestPasswordReset')(
  withResource(mapResourceToProps),
  withContent(),
  withTheme(),
  connect(mapState, mapDispatch)
)(RequestPasswordReset);
