import * as R from 'ramda';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { cultureSelector } from 'rsv8-client';
import { PageRenderer } from 'rsv8-cms';
import { Grid } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import { TrendingCarousel } from '../../components/CarouselWrappers';
import CategoryBrowse from '../../components/CategoryBrowse/CategoryBrowse';
import { getDepartments } from '../../redux/actions';
import { getDepartment } from '../../redux/selectors';
import { Resources } from '../../redux/types';
import { CategoryPageHeading } from './styles';
import { excludedCategories } from './util';

interface CategoryLandingProps {
  actions: {
    getDepartments: any;
  };
  cultureId: number;
  department: Resources.Department;
  trendingCarouselCardPreText: string;
  trendingCarouselTitlePreText: string;
}

class CategoryLanding extends React.Component<CategoryLandingProps, any> {
  componentDidMount() {
    this.props.actions.getDepartments();
  }

  componentDidUpdate(prevProps: CategoryLandingProps) {
    if (prevProps.cultureId !== this.props.cultureId) {
      this.props.actions.getDepartments();
    }
  }

  categoryExists = (categoryId) => categoryId === this.props.department.id;

  render() {
    const { department, trendingCarouselTitlePreText, trendingCarouselCardPreText } = this.props;

    return (
      <Grid themeVariation="page-wrapper">
        <CategoryPageHeading>{department && department.name}</CategoryPageHeading>
        {department && (
          <PageRenderer
            path={`/catalog/category/${department.id}`}
            onNotFound={() => (
              <PageRenderer
                path={`/catalog/category/${department.id}`}
                cultureId="0"
                onNotFound={() => <PageRenderer path={`/catalog/category/default`} cultureId="0" />}
              />
            )}
          />
        )}
        <Row>
          <Col xs={24}>{department && <CategoryBrowse childCategories={department.childCategories} />}</Col>
        </Row>

        {department &&
          R.filter(this.categoryExists, excludedCategories).length <= 0 && (
            <TrendingCarousel
              cardText={`${trendingCarouselCardPreText} ${department.name}`}
              cardPath={`/catalog/search?filterCategories=${department.id}`}
              title={`${trendingCarouselTitlePreText} ${(department && department.name) || ''}`}
              titleAlign={true}
              filterDepartment={department.id}
            />
          )}
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  department: getDepartment(state, ownProps),
  cultureId: cultureSelector.getActiveCultureId(state)
});

const mapResourceToProps = (getResource) => ({
  trendingCarouselCardPreText: getResource('catalog.categoryLanding.trendingCarouselCardPreText', 'Shop All'),
  trendingCarouselTitlePreText: getResource(
    'catalog.categoryLanding.trendingCarouselTitlePreText',
    'Trending Rewards in'
  )
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getDepartments
    },
    dispatch
  )
});
export default compose<any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withResource(mapResourceToProps)
)(CategoryLanding);
