import * as React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { destroyRequest } from 'xcel-redux-orm';
import { convertToPatchOperations } from 'xcel-util';
import { assetAdminApiActions } from '../../../redux/actions';
import { getActiveRoute } from '../../../redux/selectors';
import { assetAdminToast } from '../../../util';
import DeleteAsset from '../../DeleteAsset';
import { UploadInput } from '../../FileUpload';
import { ImageDisplay, VideoDisplay } from '../AssetDisplays';

interface EditAsset {
  newFile?: {
    lastModified: Date;
    lastModifiedDate: Date;
    name: string | any;
    size: Number;
    type: String;
    webkitRelativePath: String;
  };
  file?: {
    createdBy?: string;
    createdDateTimeUtc?: string;
    downloadUrl?: string;
    fileExtension?: string;
    fileName?: string;
    id?: string;
    isPrivate?: string;
    meta?: [{}];
    mimeType?: string;
    updatedBy?: string;
    updatedDateTimeUtc?: string;
  };
  actions?: {
    updateAsset?: typeof assetAdminApiActions.patchAssetsById;
    destroyRequest?: typeof destroyRequest;
    getRoutes?: typeof assetAdminApiActions.getClientAssetRoutes;
    uploadNewFile?: typeof assetAdminApiActions.updateClientAssetRoutesByIdAssetsAndAssetId;
  };
  activeRoute?: number;
  handleDelete?: Function;
}

interface States {
  isPrivate?: string;
  newFileName?: string;
  upLoading?: boolean;
}

const AssetModalContentWrap = styled.div`
  display: flex;
`;

const LeftSide = styled.div`
  display: inline-block;
  text-align: center;
  width: 70%;
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
const RightSide = styled.div`
  background: #f3f3f3;
  padding: 15px;
  width: 29%;
  display: inline-block;
  word-break: break-all;
`;

const StyledHr = styled.hr`
  border-top: 1px solid #ccc;
`;

const StyledSelect = styled.select`
  width: 100px;
  padding: 10px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border: 0px solid #000000;
  margin-bottom: 18px;
  margin-left: 10px;
`;

const FileChangeForm = styled.form`
  margin: 15px auto;
`;

class EditAssetModal extends React.Component<EditAsset, States> {
  state = { isPrivate: '', newFileName: '', upLoading: false };

  componentDidMount() {
    const { isPrivate } = this.props.file;
    isPrivate !== undefined && this.setState({ isPrivate: isPrivate.toString() });
  }

  onUploadChange = (e) => {
    this.setState({ newFileName: e.target.files[0].name });
  };

  onFileChangeSubmit = async (e) => {
    e.preventDefault();
    this.setState({ upLoading: true }); // hide buttons, show spinner

    const {
      activeRoute,
      file: { id }
    } = this.props;

    const formData: any = new FormData();
    const uploadedFile = e.target[0].files[0];
    formData.append('file', uploadedFile);

    try {
      await this.props.actions.uploadNewFile(
        { id: activeRoute, assetId: id.toString(), model: formData },
        {
          config: {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        }
      );

      this.setState({
        newFileName: '',
        upLoading: false
      }); // reset ui, ready for next upload
    } catch (err) {
      let errorResponse;

      if (err.response === undefined) {
        errorResponse = err; // catch code errors
      } else {
        errorResponse =
          err.response.data.errors[0].title !== ''
            ? err.response.data.errors[0].title
            : err.response.data.errors[0].meta.id; // catch server errors
      }
      this.setState({
        newFileName: '',
        upLoading: false
      });
      assetAdminToast('admin-warning', errorResponse);
    }
  };

  onPrivateChange = (e) => {
    e.preventDefault();
    this.setState({ isPrivate: e.target.value }, () => {
      this.onFileOptionsSubmit();
    });
  };

  onFileOptionsSubmit = async () => {
    const {
      actions,
      file: { id }
    } = this.props;

    const model = {
      isPrivate: this.state.isPrivate === 'true'
    };

    const request = {
      operations: convertToPatchOperations(model)
    };

    try {
      await actions.updateAsset({ id, request });
      await actions.destroyRequest('getClientAssetRoutes');
      await actions.getRoutes({ filterFilter: `id = ${this.props.activeRoute}` });
    } catch (err) {
      let errorResponse;

      if (err.response === undefined) {
        errorResponse = err; // catch code errors
      } else {
        errorResponse =
          err.response.data.errors[0].title !== ''
            ? err.response.data.errors[0].title
            : err.response.data.errors[0].meta.id; // catch server errors
      }

      assetAdminToast('admin-warning', errorResponse);
    }
  };

  render() {
    const {
      file: { id, fileName, downloadUrl, createdBy, createdDateTimeUtc, fileExtension },
      handleDelete
    } = this.props;

    const { isPrivate, newFileName } = this.state;

    const imageType = ['jpg', 'png', 'gif', 'jpeg', 'svg'] as any;
    const videoType = ['mp4', 'mov', 'ogg', 'wmv', 'flv'] as any;

    return (
      <AssetModalContentWrap>
        <LeftSide>
          {imageType.includes(fileExtension) && <ImageDisplay imageSrc={downloadUrl} />}
          {videoType.includes(fileExtension) && <VideoDisplay videoSrc={downloadUrl} />}
        </LeftSide>
        <RightSide>
          <h5>File Details</h5>
          <strong>File name: </strong>
          {fileName} <br />
          <strong>File URL: </strong>
          {downloadUrl} <br />
          <strong>Uploaded by: </strong>
          {createdBy} <br />
          <strong>Uploaded date: </strong>
          {createdDateTimeUtc} <br />
          <StyledHr />
          <h5>Edit File</h5>
          <form>
            <strong>isPrivate:</strong>
            <StyledSelect value={isPrivate} onChange={this.onPrivateChange}>
              <option value="true">true</option>
              <option value="false">false</option>
            </StyledSelect>

            <br />
          </form>
          <FileChangeForm onSubmit={this.onFileChangeSubmit}>
            <UploadInput fileName={newFileName} handleChange={this.onUploadChange} inputName="Replace File" />
            {newFileName !== '' &&
              this.state.upLoading !== true && (
                <Button type="submit" bsStyle="success">
                  Submit
                </Button>
              )}
          </FileChangeForm>
          <DeleteAsset handleDelete={handleDelete} assetId={id && id.toString()} />
        </RightSide>
      </AssetModalContentWrap>
    );
  }
}

type MapState = Pick<EditAsset, 'activeRoute'>;
type MapDispatch = Pick<EditAsset, 'actions'>;

const mapStateToProps = (state: any): MapState => {
  return {
    activeRoute: getActiveRoute(state)
  };
};

const mapDispatchToProps = (dispatch): MapDispatch => ({
  actions: bindActionCreators(
    {
      getRoutes: assetAdminApiActions.getClientAssetRoutes,
      updateAsset: assetAdminApiActions.patchAssetsById,
      uploadNewFile: assetAdminApiActions.updateClientAssetRoutesByIdAssetsAndAssetId,
      destroyRequest
    },
    dispatch
  )
});

export default connect<MapState, MapDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(EditAssetModal);
