import styled from 'styled-components';

export const getHeight = (thesize: any) => {
    const weight = 1;
    return (112 + thesize) * weight;
};

export const getWidth = (thesize: any) => {
    return 112 + thesize;
};

const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px'
}

export const device = {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktop})`
};

export const ComponentWrapper = styled.div`
` as any;

export const ChartContainer = styled.div<{ size: number }>`
  position: relative;
  display: inline-block;
  width: ${(props: any) => getWidth(props.size)}px;
  height: ${(props: any) => getHeight(props.size)}px;
  @media ${device.mobileS} {
    width: ${(props: any) => getWidth(props.size) - 120}px;
    height: ${(props: any) => getHeight(props.size) - 100}px;
  };
  @media ${device.mobileM} {
    width: ${(props: any) => getWidth(props.size) - 100}px;
    height: ${(props: any) => getHeight(props.size) - 90}px;
  };
  @media ${device.mobileL} {
    width: ${(props: any) => getWidth(props.size) - 90}px;
    height: ${(props: any) => getHeight(props.size) - 90}px;
  };
` as any;

export const GaugeTitle = styled.h1<{ size: number, font: string }>`
  font-family: ${(props: any) => props.font};
  font-size: ${(props: any) => props.size}px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 1.5px;
  text-align: center;
  margin-top: -10px;
  @media ${device.mobileS} {
    font-size: ${(props: any) => props.size - 5}px;
  };
` as any;

export const Rectangle = styled.div`
  width: 361px;
  height: 38px;
  border-radius: 18px;
  background-image: linear-gradient(to right, var(--light-gray) 0%, #f6f6f6 100%);
` as any;

export const Shape = styled.div`
  width: 262px;
  height: 265px;
  transform: rotate(-52deg);
  border-radius: 200;
  background-image: linear-gradient(142deg, #fcfcfc 1%, #f8f8f8 99%);
` as any;

export const Oval = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 40;
  background-image: linear-gradient(142deg, #fafafa 0%, #f6f6f6 100%);
` as any;

export const NoDataSpinner = styled.i`
  font-size: 24px;
` as any;