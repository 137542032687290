export default {
  expiredToken: '/invalid-token',
  resetPassword: '/reset-password',
  changePassword: '/change-password',
  confirmPassword: '/confirm-password',
  changePasswordConfirmation: '/change-password/confirmation',
  requestPasswordReset: '/reset-password/request',
  resetPasswordSent: '/reset-password/sent',
  resetPasswordConfirmation: '/reset-password/confirmation',
  userNotFound: '/user-not-found',
  userHasNoEmail: '/user-has-no-email'
};
