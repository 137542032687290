import * as React from 'react';
import { FormControl as BSFormControl } from 'react-bootstrap';
import styled from 'styled-components';
import { register, withTheme } from 'xcel-react-core';

const StyledFormControl = styled(BSFormControl)`
  border: 1px solid #dddddd !important;
  box-shadow: none !important;
  padding: 10px !important;
` as any;

const FormControl = (props) => {
  if (props.componentClass === 'textarea') {
    return <StyledFormControl {...props} />;
  }
  return <BSFormControl {...props} />;
};

export default register('rsv8-forms/FormControl')(withTheme())(FormControl);
