import * as events from '../../events';
import { getAwardsOnce } from '../getAwards/getApplications';
import { getAwardDetails } from '../getAwards/index';
import { getProductDetails, getProducts } from '../getProducts/index';
import { saveNomination, setClearNominationData, setNomination, submitNomination } from './saveDraft';
import selectPointValue from './selectPoints';
import selectProduct from './selectProduct';
import selectProductOptionId from './selectProductOptionId';
import selectProductOptionPointValue from './selectProductOptionPointValue';
import {
  selectAward,
  selectCustomFields,
  selectNominee,
  setDefaultTeamMemberAwardValue,
  setDefaultTeamMemberProductValue,
  setDefaultTeamMemberRoleId
  } from './setActive';
import teamMemberActions from './teamMember/index';
// import { getProductsOnce } from '../getProducts/getApplications';

const teamNominationActions = {
  save: saveNomination,
  getAwards: getAwardsOnce,
  getProducts: getProducts,
  submit: submitNomination,
  setTeamName: (value) => ({ type: events.SET_TEAM_NAME, payload: value }),
  getAwardDetails: (award: string, nominee: string) => (dispatch, getState) => {
    dispatch(getAwardDetails(award, nominee, { team: true }));
  },
  getProductDetails: (product: string, nominee: string) => (dispatch, getState) => {
    dispatch(getProductDetails(product, nominee, { team: true }));
  },
  selectCustomFields,
  selectAward,
  selectProduct,
  selectProductOptionPointValue,
  selectProductOptionId,
  setNomination,
  selectNominee,
  selectPointValue,
  setDefaultRole: setDefaultTeamMemberRoleId,
  setDefaultAward: setDefaultTeamMemberAwardValue,
  setDefaultProduct: setDefaultTeamMemberProductValue,
  setClearNominationData: setClearNominationData
};

export { teamNominationActions, teamMemberActions };
