import { promiseDispatcher } from 'xcel-react-core';
import { customFieldApi } from '../../api';
import { getFieldValidations } from '../selectors/customFields';
import getFormTypes from './getFormTypes.actions';
import * as types from './types';

const getLookupPromise = (name) => {
  if (name === undefined) {
    return customFieldApi.getLookup();
  }
  return customFieldApi.getLookupByKey({ key: name }, {});
};

const getValidationsPromise = () => getLookupPromise('FieldValidation');

const getFieldTypesPromise = () => getLookupPromise('CustomFieldTypes');

const getValidations = promiseDispatcher(getValidationsPromise, {
  success: types.GET_VALIDATIONS_SUCCESS,
  failure: types.GET_VALIDATIONS_FAILURE
});

const getFieldTypes = promiseDispatcher(getFieldTypesPromise, {
  success: types.GET_FIELD_TYPES_SUCCESS,
  failure: types.GET_FIELD_TYPES_FAILURE
});

const getLookup = promiseDispatcher(getLookupPromise, {
  success: types.GET_CUSTOM_FIELD_LOOKUP_KEY_SUCCESS,
  failure: types.GET_CUSTOM_FIELD_LOOKUP_KEY_FAILURE
});

const getValidationsAndTypes = () => (dispatch, getState) => {
  const validations = getFieldValidations(getState());
  dispatch(getFormTypes());
  if (!validations.data.length || validations.data.length === 0) {
    dispatch(getValidations());
  }
};

export { getLookup, getValidations, getFieldTypes, getValidationsAndTypes };
