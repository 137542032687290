import * as events from '../../events';
import { ProgramLevelProduct, ProgramLevel } from 'xcel-api-generator/dist/achievement';


export const toggleProductDetailsModal = (product?: ProgramLevelProduct, programLevel?: ProgramLevel) => ({
  type: events.ACHIEVEMENT_PRODUCT_DETAILS_TOGGLE_MODAL,
  payload: { product, programLevel }
});

export const toggleOrderDetailsModal = (product?: ProgramLevelProduct, programLevel?: ProgramLevel) => ({
  type: events.ACHIEVEMENT_ORDER_DETAILS_TOGGLE_MODAL,
  payload: { product, programLevel }
});
