import styled from 'styled-components';
import { register, withTheme } from 'xcel-react-core';

const LoginFormBody = styled.div`
  margin: 0px auto;
  padding: 20px 58px 55px;
  background-color: rgba(255, 255, 255, 0.9);
  @media screen and (max-width: 767px) {
    padding: 30px;
  }
`;

export default register('rsv8-auth/LoginFormBody')(withTheme())(LoginFormBody);
