import * as events from '../../../events';
import { individualNominationSelectors } from '../../../selectors/index';
import { IndividualNomination } from '../../../../types';
import { clearCurrentAward, clearCurrentNomination } from '../nomineeSave/index';

const selectNomineeBase = (nominee: string): IndividualNomination.Action => ({
  type: events.SELECT_NOMINEE,
  payload: nominee
});

const selectNominee = (value: string, confirmText = 'Changing nominee now will clear later steps.') => (
  dispatch,
  getState
) => {
  const state = getState();
  if (individualNominationSelectors.getCurrentNomineeId(state) !== value) {
    if (individualNominationSelectors.hasAwardOrCustomFields(state)) {
      if (confirm(confirmText)) {
        dispatch(clearCurrentNomination());
        dispatch(clearCurrentAward());
        dispatch(selectNomineeBase(value));
      }
    } else {
      dispatch(selectNomineeBase(value));
    }
  }
};

export default selectNominee;
