import * as React from 'react';
import styled from 'styled-components';
import { assetService } from 'xcel-asset-service';
import { register, withContent, withTheme } from 'xcel-react-core';

export type Props = {
  backgroundImage: string;
  backgroundColor: string;
};
const BannerContainer: any = styled.div`
  background-image: ${(props: Props) =>
    props.backgroundImage ? `url(${assetService.getImage(props.backgroundImage)})` : ''};
  background-color: ${(props: Props) => props.backgroundColor};
  padding: 30px 10px;
`;
const Banner: React.SFC<Props> = ({ children, backgroundImage, backgroundColor, ...rest }) => {
  return (
    <BannerContainer {...rest} backgroundColor={backgroundColor} backgroundImage={backgroundImage}>
      {children}
    </BannerContainer>
  );
};

const mapContentToProps = (getContent) => ({
  backgroundColor: getContent('backgroundColor', { type: 'none' })
});
export default register('rsv8-components/Banner')(withContent(mapContentToProps), withTheme())(Banner);
export { Banner };
