import { promiseDispatcher } from 'xcel-react-core';
import { createJsonApiActions } from 'xcel-redux-orm';
import { userApi } from '../../api/';

const actions = createJsonApiActions('getUser');
const getUserCall = async () => {
  return await userApi.getProfileUsers();
};
const getUserProfile = promiseDispatcher(getUserCall, actions);
export { getUserProfile };
