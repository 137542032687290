import styled from 'styled-components';

export interface EnterSalesImageProps {
  imageURI: any;
}

const EnterSalesImage = styled.div`
  height: 130px;
  background-image: url(${(props: EnterSalesImageProps) => props.imageURI});
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 15px;
`as any;

export { EnterSalesImage };
