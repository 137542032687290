import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Modal, ModalBody, ModalHeader } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import { updateProductPopulateBySource } from '../../../redux/actions';
import * as styles from './styles';

const options = {
  quater: {
    priorQuarter: 'prior-quarter',
    runRate: 'run-rate',
    target: 'target',
  },
  ytd: {
    priorYear: 'prior-year-ytd',
    runRate: 'ytd-run-rate',
    target: 'ytd-target',
  }
}

const AutoPopulateModal: React.FC<Props> = ({
  resource: {
    modalHeader,
    autoPopulateButtonText,
    priorQuarter,
    priorQuarterDescription,
    priorYearYTD,
    priorYearDescriptionYTD,
    runRate,
    runRateDescription,
    runRateYTD,
    runRateDescriptionYTD,
    targetOption,
    targetOptionDescription,
    targetOptionYTD,
    targetOptionDescriptionYTD,
  },

  actions: {
    updateProductPopulateBySource
  },

  show,
  handleHide,
  target,
  activeOptions,
}) => {
  const [selected, setSelected] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const onRadioChange = (e) => {
    setSelected(e.target.value);
  }

  const handlePopulate = async () => {
    setIsLoading(true);
    await updateProductPopulateBySource(selected, target);
    setIsLoading(false);
    handleHide();
  }

  if (!activeOptions) return null;

  return (
    <Modal show={show} onHide={handleHide}>
      <ModalHeader closeButton={true} />

      <ModalBody>
        <styles.ModalBodyContainer>
          <styles.PopulateOptionsHeader>{modalHeader}</styles.PopulateOptionsHeader>

          {activeOptions.includes(options.quater.priorQuarter) && (
            <styles.PopulateOptionsContainer>
              <styles.PopulateOptionsTitle>{priorQuarter}</styles.PopulateOptionsTitle>
              <styles.StyledRadioButton
                type="radio"
                value={options.quater.priorQuarter}
                checked={selected === options.quater.priorQuarter}
                onChange={onRadioChange}
              >
                <styles.Checked />
                {priorQuarterDescription}
              </styles.StyledRadioButton>
            </styles.PopulateOptionsContainer>
          )}

          {activeOptions.includes(options.quater.runRate) && (
            <styles.PopulateOptionsContainer>
              <styles.PopulateOptionsTitle>{runRate}</styles.PopulateOptionsTitle>
              <styles.StyledRadioButton
                type="radio"
                value={options.quater.runRate}
                checked={selected === options.quater.runRate}
                onChange={onRadioChange}
              >
                <styles.Checked />
                {runRateDescription}
              </styles.StyledRadioButton>
            </styles.PopulateOptionsContainer>
          )}

          {activeOptions.includes(options.quater.target) && (
            <styles.PopulateOptionsContainer>
              <styles.PopulateOptionsTitle>{targetOption}</styles.PopulateOptionsTitle>
              <styles.StyledRadioButton
                type="radio"
                value={options.quater.target}
                checked={selected === options.quater.target}
                onChange={onRadioChange}
              >
                <styles.Checked />
                {targetOptionDescription}
              </styles.StyledRadioButton>
            </styles.PopulateOptionsContainer>
          )}

          {activeOptions.includes(options.ytd.priorYear) && (
            <styles.PopulateOptionsContainer>
              <styles.PopulateOptionsTitle>{priorYearYTD}</styles.PopulateOptionsTitle>
              <styles.StyledRadioButton
                type="radio"
                value={options.ytd.priorYear}
                checked={selected === options.ytd.priorYear}
                onChange={onRadioChange}
              >
                <styles.Checked />
                {priorYearDescriptionYTD}
              </styles.StyledRadioButton>
            </styles.PopulateOptionsContainer>
          )}

          {activeOptions.includes(options.ytd.runRate) && (
            <styles.PopulateOptionsContainer>
              <styles.PopulateOptionsTitle>{runRateYTD}</styles.PopulateOptionsTitle>
              <styles.StyledRadioButton
                type="radio"
                value={options.ytd.runRate}
                checked={selected === options.ytd.runRate}
                onChange={onRadioChange}
              >
                <styles.Checked />
                {runRateDescriptionYTD}
              </styles.StyledRadioButton>
            </styles.PopulateOptionsContainer>
          )}

          {activeOptions.includes(options.ytd.target) && (
            <styles.PopulateOptionsContainer>
              <styles.PopulateOptionsTitle>{targetOptionYTD}</styles.PopulateOptionsTitle>
              <styles.StyledRadioButton
                type="radio"
                value={options.ytd.target}
                checked={selected === options.ytd.target}
                onChange={onRadioChange}
              >
                <styles.Checked />
                {targetOptionDescriptionYTD}
              </styles.StyledRadioButton>
            </styles.PopulateOptionsContainer>
          )}

          {isLoading ? <styles.Spinner /> : <styles.AutoPopulateButton onClick={handlePopulate}>{autoPopulateButtonText}</styles.AutoPopulateButton>}
        </styles.ModalBodyContainer>
      </ModalBody>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    updateProductPopulateBySource: bindActionCreators(updateProductPopulateBySource, dispatch)
  }
});

const mapResourceToProps = (getResource) => ({
  resource: {
    modalHeader: getResource('autoPopulateModal.modalHeader', 'Auto-Populate Options'),
    autoPopulateButtonText: getResource('autoPopulateModal.autoPopulateButtonText', 'AUTO-POPULATE'),

    priorQuarter: getResource('autoPopulateModal.priorQuarter', 'Prior Quarter'),
    priorQuarterDescription: getResource('autoPopulateModal.priorQuarterDescription', 'Populate the Custom section with your sales volume for each product last quarter.'),

    runRate: getResource('autoPopulateModal.runRate', 'Run Rate'),
    runRateDescription: getResource('autoPopulateModal.runRateDescription', 'Apply your current Run Rate forecast for all products to the Custom section.'),

    targetOption: getResource('autoPopulateModal.targetOption', 'Target'),
    targetOptionDescription: getResource('autoPopulateModal.targetOptionDescription', 'Apply your current Target forecast for all products to the Custom section. Choosing this option will automatically save the current forecasts and overwrite the previous forecasts.'),

    priorYearYTD: getResource('autoPopulateModal.priorYearYTD', 'Prior Year YTD'),
    priorYearDescriptionYTD: getResource('autoPopulateModal.priorYearDescriptionYTD', 'Populate the Custom section with your sales volume for each product last year.'),

    runRateYTD: getResource('autoPopulateModal.runRateYTD', 'YTD Run Rate'),
    runRateDescriptionYTD: getResource('autoPopulateModal.runRateDescriptionYTD', 'Apply your current YTD Run Rate forecast for all products to the Custom section.'),

    targetOptionYTD: getResource('autoPopulateModal.targetOptionYTD', 'YTD Target'),
    targetOptionDescriptionYTD: getResource('autoPopulateModal.targetOptionDescriptionYTD', 'Apply your current YTD Target forecast for all products to the Custom section. choosing this option will automatically save the current forecasts and overwrite the previous forecasts.'),
  }
});

interface StateProps { }
interface DispatchProps {
  actions: {
    updateProductPopulateBySource: typeof updateProductPopulateBySource,
  }
}
interface ResourceProps {
  resource: {
    [index: string]: string;
  }
}
interface OwnProps {
  show: boolean;
  handleHide: Function;
  target: number;
  activeOptions: string[];
}
type Props = StateProps & DispatchProps & ResourceProps & OwnProps;

export default compose<React.ComponentType<OwnProps>>(
  withResource(mapResourceToProps),
  connect<StateProps, DispatchProps, OwnProps>(null, mapDispatchToProps)
)(AutoPopulateModal);