import * as React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AnimatedCircle } from 'rsv8-components';
import styled from 'styled-components';
import { destroyRequest } from 'xcel-redux-orm';
import { assetAdminApiActions } from '../../redux/actions';
import { getActiveRoute } from '../../redux/selectors';
import { assetAdminToast } from '../../util';
import UploadInput from './UploadInput';

const StyledWrap = styled.div`
  display: inline-block;
  position: relative;
`;

const StyledAnimatedCircle = styled(AnimatedCircle)`
  position: absolute;
  top: 0;
`;

const StyledForm = styled.form`
  margin: 0 0 10px 10px;
`;
class FileUpload extends React.Component<any> {
  state = {
    fileName: '',
    upLoading: false
  };
  onChange = (e) => {
    this.setState({ fileName: e.target.files[0].name });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({ upLoading: true }); // hide buttons, show spinner
    const { activeRoute } = this.props;
    let formData = new FormData();
    const uploadedFile = e.target[0].files[0];

    formData.append('file', uploadedFile);

    try {
      await this.props.actions.uploadFile(
        { id: activeRoute, model: formData },
        {
          config: {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        }
      );
      await this.props.actions.destroyRequest('getClientAssetRoutes');
      this.props.actions.getRoutes({ filterFilter: `id = ${activeRoute}` });

      this.setState({
        fileName: '',
        upLoading: false
      }); // reset ui, ready for next upload
    } catch (err) {
      let errorResponse;

      if (err.response === undefined) {
        errorResponse = err; // catch code errors
      } else {
        errorResponse =
          err.response.data.errors[0].title !== ''
            ? err.response.data.errors[0].title
            : err.response.data.errors[0].meta.id; // catch server errors
      }
      this.setState({
        fileName: '',
        upLoading: false
      });
      assetAdminToast('admin-warning', errorResponse);
    }
  };

  render() {
    let { fileName } = this.state;
    let { activeRoute } = this.props;

    {
      return (
        activeRoute !== 'root' && (
          <StyledWrap>
            <StyledForm onSubmit={this.onSubmit}>
              {this.state.upLoading !== true && (
                <UploadInput fileName={fileName} handleChange={this.onChange} inputName="Upload File" />
              )}
              {/*hide this when we are in the process of uploading the image*/}
              {fileName !== '' &&
                this.state.upLoading !== true && (
                  <Button type="submit" className="btn btn-success">
                    Submit
                  </Button>
                )}
              {this.state.upLoading ? <StyledAnimatedCircle /> : null}{' '}
              {/*hide this when we are in the process of uploading the image*/}
            </StyledForm>
          </StyledWrap>
        )
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    activeRoute: getActiveRoute(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      destroyRequest,
      getRoutes: assetAdminApiActions.getClientAssetRoutes,
      uploadFile: assetAdminApiActions.createClientAssetRoutesByIdAssets
    },
    dispatch
  )
});

export default connect<any>(
  mapStateToProps,
  mapDispatchToProps
)(FileUpload);
