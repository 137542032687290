import * as React from 'react';
import { getValidationState } from '../util';
// Wraps an Input-style component, converting some information from the
// meta prop into validationState and adding that to the top-level props
// Also copy over any special attributes I attach to the components
const addValidationState = (BaseComponent) => {
  const ValidatedFormField = (props) => <BaseComponent {...props} validationState={getValidationState(props.meta)} />;
  ['providerKey'].forEach((key) => {
    if (BaseComponent.hasOwnProperty(key)) {
      ValidatedFormField[key] = BaseComponent[key];
    }
  });

  return ValidatedFormField;
};

export default addValidationState;
