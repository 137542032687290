import { register, withContent, withResource, withTheme } from 'xcel-react-core';
import { connect } from 'react-redux';
import { withApiDataProvider } from 'xcel-redux-orm';
import { fetchRedeemOption } from '../../redux/actions';
import { redeemOptionSelector } from '../../redux/selectors';
import { mapContentToProps, mapDispatchToProps, mapStateToProps, PrepaidCardContent } from './PrepaidCard';

const mapResourceToProps = (getResource) => ({
  title: getResource('VirtualPrepaidCard.title', 'Virtual Prepaid Mastercard<sup>®</sup>'),
  amountTitle: getResource('VirtualPrepaidCard.amountTitle', 'Amount'),
  quantityTitle: getResource('VirtualPrepaidCard.quantityTitle', 'Quantity'),
  submit: getResource('VirtualPrepaidCard.submit', 'Submit'),
  loadingDropdown: getResource('VirtualPrepaidCard.loading', 'Loading...'),
  selectOption: getResource('VirtualPrepaidCard.selectOption', 'Select Amount'),
  currencySymbol: getResource('VirtualPrepaidCard.currencySymbol', '$'),
  currencyAbbreviation: getResource('VirtualPrepaidCard.currencyAbbreviation', 'USD'),
});

export default register('alcon-products/VirtualPrepaidCard')(
  connect(mapStateToProps, mapDispatchToProps),
  withApiDataProvider(() => fetchRedeemOption('product-virtual'), redeemOptionSelector),
  withTheme(),
  withContent(mapContentToProps),
  withResource(mapResourceToProps)
)(PrepaidCardContent);
