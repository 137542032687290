import { reduxObserver } from 'xcel-observer';
import { createReduxModule, ReduxModule } from 'xcel-redux-module';
import { reducers } from './app/redux';
const formModule: ReduxModule = createReduxModule({
  name: 'rsv8-forms',
  reducers
});
reduxObserver.addModule(formModule);

export default formModule;
