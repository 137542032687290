import { connect } from 'react-redux';
import { authCheckLocalStorage, authCheckSessionTimeout } from '../redux/auth.actions';

interface SessionWatcherProps {
  dispatch?: any;
  watchInterval?: any;
  onTimeout?: any;
  onRefreshToken?: any;
  ssoSuccessPath?: any;
  ssoFailurePath?: any;
  resetPasswordPath?: any;
  expiredRedirectPath?: any;
}

const SessionWatcher: any = ({
  dispatch,
  expiredRedirectPath,
  watchInterval,
  onTimeout,
  onRefreshToken,
  ssoSuccessPath,
  ssoFailurePath,
  resetPasswordPath
}: SessionWatcherProps) => {
  let localStorageInterval = 1000;
  let sessionInterval = watchInterval !== undefined ? watchInterval : 60000;
  let sessionRefreshTokenBuffer = 5 * 60 * 1000;
  let inActiveTimeout = 5 * 60 * 1000;
  dispatch(authCheckLocalStorage({ interval: localStorageInterval }));
  dispatch(
    authCheckSessionTimeout({
      interval: sessionInterval,
      sessionRefreshTokenBuffer,
      inActiveTimeout,
      onTimeout,
      onRefreshToken,
      ssoSuccessPath,
      ssoFailurePath,
      resetPasswordPath,
      expiredRedirectPath
    })
  );
  return null;
};

export default connect()(SessionWatcher as any) as any;
