import * as React from 'react';
import { connect } from 'react-redux';
import { Card, Container } from 'rsv8-components';
import { Program, ShipToLocation } from 'xcel-api-generator/dist/alcon';
import { register, withContent, withTheme } from 'xcel-react-core';
import { withApiDataProvider } from 'xcel-redux-orm';
import { ProgramName } from '../../constants';
import { getProgram, getSummary } from '../../redux/actions/';
import * as selectors from '../../redux/selectors';
import { OverlayPopover } from '../Overlay';
import MarketShareLocation from './MarketShareLocations';
import UnitsLocation from './UnitsLocations';
import YTDUnitsLocation from './YTDUnitsLocation';
import { LocationsComponentBody, StyledHeader } from './styles';

const ShipToLocations: React.FC<Props> = ({ className, shipToLocations, program, title, infoButtonText }) => {
  const show = React.useMemo(
    () =>
      !program ||
      !Array.isArray(shipToLocations) ||
      (shipToLocations.length === 0 &&
        (ProgramName.match(ProgramName.OptiPort, program) || ProgramName.match(ProgramName.VisionSource, program))) ||
      (shipToLocations.length <= 1 &&
        !(ProgramName.match(ProgramName.OptiPort, program) || ProgramName.match(ProgramName.VisionSource, program))),
    [program, shipToLocations]
  );
  if (show) {
    return null;
  }

  return (
    <Container className={className} themeVariation="dashboard-shiptolocation-card">
      <Card themeVariation="product-shipto-cards">
        <div className="location-header">
          <StyledHeader themeVariation="home-card-header">
            {title}

            <OverlayPopover id="ship-to-location-popover" placement="bottom">
              {infoButtonText}
            </OverlayPopover>
          </StyledHeader>
        </div>

        <LocationsComponentBody className="location-body">
          {shipToLocations.map((location) => {
            if (ProgramName.match(ProgramName.OptiPort, program) || ProgramName.match(ProgramName.VisionSource, program))
              return <YTDUnitsLocation key={'sales-location' + location.shipToLocationId} location={location} />;
            if (ProgramName.match(ProgramName.Magnifeye, program) || ProgramName.match(ProgramName.Platinum, program))
              return <UnitsLocation key={'units-location' + location.shipToLocationId} location={location} />;
            if (ProgramName.match(ProgramName.MarketShare, program) || ProgramName.match(ProgramName.TotalPartner, program))
              return <MarketShareLocation key={'market-share-location' + location.shipToLocationId} location={location} />;
            return null;
          })}
        </LocationsComponentBody>
      </Card>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  shipToLocations: selectors.shipToLocationSelector(state),
  program: selectors.programSelector.selectMany(state)[0],
});

const mapContentToProps = (getContent) => ({
  title: getContent('title', {
    type: 'string',
    schema: [{ model: 'Title', label: 'Title:' }],
  }),
  infoButtonText: getContent('infoButtonText', {
    type: 'string',
    schema: [{ model: 'Add info button text', label: 'add info button text' }],
  }),
});

interface StateProps {
  shipToLocations: ShipToLocation[];
  program: Program;
}
interface ContentProps {
  title: string;
  infoButtonText: string;
}
type Props = React.HTMLAttributes<any> & StateProps & ContentProps;

export default register('alcon-data-vis/ShipToLocations')(
  connect<StateProps>(mapStateToProps),
  withApiDataProvider(getProgram, selectors.programSelector),
  withApiDataProvider(getSummary, selectors.dashboardSelector),
  withContent(mapContentToProps),
  withTheme()
)(ShipToLocations);
