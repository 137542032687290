import * as React from 'react';
import Zendesk from 'react-zendesk';
import { withResource } from 'xcel-react-core';

const ZendeskWidget = ({ showZendesk }) => {
  return showZendesk.toLowerCase() === 'true' && <Zendesk defer={true} zendeskKey='ce5bcc86-0226-4434-9127-644214e0484d' />;
};

const mapResourceToProps = (getResource) => ({
  showZendesk: getResource('zendesk.showZendesk', '')
});

export default withResource(mapResourceToProps)(ZendeskWidget);