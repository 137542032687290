import * as React from 'react';
// @ts-ignore
/* tslint:disable */

export const EmailSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="12"
    viewBox="0 0 16 12"
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M15.2 10.03l-4.411-4.446L15.2 1.17v8.86zm-13.617.37l4.231-4.23.748.748 1.15 1.167a.403.403 0 0 0 .578.004l1.152-1.155.763-.764 4.231 4.23H1.583zM.8 1.156L5.214 5.57.8 10.04V1.155zM14.43.8L8.002 7.226 6.38 5.604l.003-.004h-.006L1.576.8H14.43zM15.6 0H.4a.4.4 0 0 0-.4.4v10.4c0 .22.18.4.4.4h15.2a.4.4 0 0 0 .4-.4V.4a.4.4 0 0 0-.4-.4z"
    />
  </svg>
);

export const PhoneSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="17"
    viewBox="0 0 18 17"
   >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M15.287 16.087c-.02.053-.12.12-.176.12 0 0-1.19.005-1.728-.02-.93-.047-2.027-.313-2.554-.455a12.986 12.986 0 0 1-.816-.256c-.097-.033-.194-.06-.288-.095a13.773 13.773 0 0 1-.926-.378c-.06-.027-.119-.058-.179-.086a13.324 13.324 0 0 1-1.737-.971c-2.704-1.784-4.73-4.498-5.605-7.685-.142-.523-.41-1.61-.458-2.533C.795 3.195.8 2.015.8 2.015c0-.056.068-.155.12-.175L3.548.818l.78 3.838a.333.333 0 0 1-.09.248l-.738.59a.919.919 0 0 0-.252 1.083l.146.31.096.202.187.379a13.584 13.584 0 0 0 6.66 6.224l.175.088a.936.936 0 0 0 1.092-.25l.595-.732a.336.336 0 0 1 .25-.09l3.87.775-1.03 2.604zm1.77-3.001a.737.737 0 0 0-.506-.364l-3.945-.79a1.13 1.13 0 0 0-1.031.369l-.593.731c-.023.027-.1.045-.131.03l-.441-.204a12.822 12.822 0 0 1-6.064-5.823L3.97 6.24c-.014-.032.004-.108.031-.13l.737-.589c.286-.227.446-.667.373-1.022L4.314.587a.733.733 0 0 0-.366-.501.743.743 0 0 0-.622-.033L.628 1.103A1.029 1.029 0 0 0 0 2.01S-.005 3.22.023 3.77c.043.853.253 1.809.408 2.416H.428c.008.034.02.067.029.1.077.295.137.497.144.52l.01-.003c1.381 4.604 5.034 8.227 9.672 9.595l-.002.007.233.065c.006.001.013.005.02.006l.271.072c.034.008.067.02.101.029v-.004c.613.154 1.577.362 2.436.405.554.028 1.774.023 1.774.023.383-.003.776-.27.915-.623l1.06-2.675a.724.724 0 0 0-.035-.616z"
    />
  </svg>
);
