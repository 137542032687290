import * as React from 'react';
import { Forms } from '../../types';
import addValidationState from '../HOC/addValidationState';
import { StatusText } from './helpers';
import Label from './Label';
import { TooltipContainer } from './TooltipContainer';
const Input: Forms.Inputs.BasicInput = ({ label, input, meta, helpText, helpIconText, validationState, ...rest }) => {
  return (
    <div className={validationState || ''}>
      {label ? <Label>{label}</Label> : null}
      {helpIconText && <TooltipContainer tooltipChildren={helpIconText} />}
      <input {...input} {...rest} />
      <StatusText meta={meta} helpText={helpText} spanComponent="span" />
    </div>
  );
};

export default addValidationState(Input);
