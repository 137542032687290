import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setActiveModal, setEditingRoute } from '../../redux/actions';
import { EditRouteInput } from '../EditRoute';
import AssetAdminModal from '../Modal';

export interface EditButtonProps {
  node?: {
    id?: number;
    routeNodeName?: string;
    parentId?: number;
  };
  routeId?: any;
  actions?: {
    setEditingRoute?: typeof setEditingRoute;
    setActiveModal?: typeof setActiveModal;
  };

  parentId?: number;
  handleHide?: any;
  handleClick?: any;
  show?: any;
  activeModal?: string;
}

class EditRouteButton extends React.Component<EditButtonProps> {
  state = { type: '', activeModal: '' };

  editRoute = () => {
    this.props.actions.setEditingRoute(this.props.routeId);
  };

  handleClick = () => {
    this.props.actions.setEditingRoute(this.props.routeId);
    this.setState({ activeModal: 'edit-button-modal' });
  };

  handleHide = () => {
    this.setState({ activeModal: '' });
  };

  render() {
    const { node } = this.props;

    return (
      <AssetAdminModal
        handleHide={this.handleHide}
        handleClick={this.handleClick}
        show={this.state.activeModal === 'edit-button-modal'}
        modaleTitle="Edit Route"
        icon="fa fa-edit"
        color="#2196f3"
      >
        <EditRouteInput routeId={node.id} nodeName={node.routeNodeName} parentId={node.parentId} />
      </AssetAdminModal>
    );
  }
}

type MapDispatch = Pick<EditButtonProps, 'actions'>;

const mapDispatchToProps = (dispatch): MapDispatch => ({
  actions: bindActionCreators(
    {
      setEditingRoute,
      setActiveModal
    },
    dispatch
  )
});

export default connect<null, MapDispatch>(
  null,
  mapDispatchToProps
)(EditRouteButton);
