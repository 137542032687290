import { toast } from 'rsv8-components';
import { destroyRequest } from 'xcel-redux-orm';
import { GET_USER_FAVORITES_ENDPOINT } from '../../../constants';
import { removeUndoFavorite } from './deleteFavorite';
import { getFavoriteProducts } from './getFavorites';
import { productApiActions } from './productApiActions';

const postFavorite = (product, options?) => async (dispatch, getState) => {
  dispatch(destroyRequest(GET_USER_FAVORITES_ENDPOINT));
  await dispatch(
    productApiActions.createProductsLists(
      {
        body: {
          id: 'favorite',
          productId: product.id
        }
      },
      { jsonApi: true }
    )
  );
  await dispatch(getFavoriteProducts({ id: 'favorite' }));

  if (options && options.undo) {
    dispatch(removeUndoFavorite(product.id));
  }

  toast({
    template: 'CatalogBody',
    productImage: product.imageSm,
    productName: product.name || product.productName,
    productManufacturer: product.manufacturer,
    actionText: `has been added to Favorites`
  });
};
export { postFavorite };
