import * as React from 'react';
import { Button } from 'rsv8-components';
import styled from 'styled-components';
import { RecognitionImage } from './RecognitionImage';

export interface RecognitionButtonProps {
  onClick: (e: any) => any;
  className?: string;
  imageURI: string;
}

const RecognitionButtonBase = styled.div`
  margin: auto;
  button {
    background-color: #a2aa2f;
    color: white;
    border-radius: 3px;
    padding: 11px 22px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    border: none;
    margin: auto;
    width: 45%;
    margin-bottom: 30px;
    display: block;
  }
` as any;

const RecognitionButton: React.StatelessComponent<RecognitionButtonProps> = (props) => (
  <RecognitionButtonBase className={props.className}>
    <RecognitionImage imageURI={props.imageURI} />
    <Button onClick={props.onClick}>{props.children}</Button>
  </RecognitionButtonBase>
);

export default RecognitionButton;
