import * as React from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { CustomerProduct } from 'xcel-api-generator/dist/alcon';
import { productDataSelector } from '../../redux/selectors';
import * as ProductCard from './Products';
import * as styles from './styles';

export const ScrollableProductsPanel: React.FC<Props> = ({
    handleCalcButton,
    reCalculateButton,
    productData
}) => {
    if (productData === undefined || productData.length === 0) {
        return null;
    }

    return (
        <>
            <MediaQuery query="(max-width: 1024px)">
                <div>
                    <styles.ScrollableDataCard>
                        {productData.map((product) => <div key={product.id}><ProductCard.ProductCard product={product} /></div>)}
                    </styles.ScrollableDataCard>
                    <styles.MobileReCalculateButton
                        id={'calculateButton'}
                        key={'calculateButton'}
                        onClick={handleCalcButton}
                    >
                        {reCalculateButton}
                    </styles.MobileReCalculateButton>
                </div>
            </MediaQuery>

            <MediaQuery query="(min-width: 1025px)">
                <styles.ScrollableDataCard>
                    {productData.map((product) => <ProductCard.ProductCard key={product.id} product={product} />)}
                </styles.ScrollableDataCard>
            </MediaQuery>
        </>
    );
}

const mapStateToProps = (state) => ({
    productData: productDataSelector(state)
})

interface StateProps {
    productData: CustomerProduct[],
}
interface DispatchProps { }
interface OwnProps {
    handleCalcButton: Function;
    reCalculateButton: string;
}
type Props = StateProps & DispatchProps & OwnProps;

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps)(ScrollableProductsPanel);