import * as React from 'react';
import ParagraphField, { ParagraphFieldProps }  from './ParagraphField';

export interface BetterParagraphProps extends ParagraphFieldProps {
  customFieldValues: object
};

const BetterParagraph: React.SFC<BetterParagraphProps> = ({ field, customFieldValues, ...rest }) => {
  const customFieldValue = customFieldValues && customFieldValues[field.id];
  
  return customFieldValue ? (
    <ParagraphField value={customFieldValue} field={field} {...rest}/>
  ) : null;
};

export default BetterParagraph as any;
