import * as React from 'react';
import { connect } from 'react-redux';
import { withProps } from 'recompose';
import { CultureDropdown } from 'rsv8-client';
import { Field, formSelectors, reduxForm } from 'rsv8-forms';
import { BootstrapSuccessAlert } from '../BootstrapAlert';

const CultureDropdownStatic = withProps({ allowCultureChange: false, property: 'id' })(CultureDropdown);
const MenuItemForm = ({
  handleSubmit,
  children,
  reset,
  navigationMenuAdmin,
  navigationMenuAdmin: {
    items,
    application,
    lookup: { culture, menuType, provider },
    providerTypes
  },
  pristine,
  submitting,
  setCurrentForm,
  actions
}: any) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label>
          Application <sup>*</sup>
        </label>
        <div>
          <Field name="appKey" required="true" component="select" className="form-control">
            {application.map((items) => (
              <option value={items.id} key={items.id}>
                {items.id}
              </option>
            ))}
          </Field>
        </div>
      </div>

      <div className="form-group">
        <label>
          Label <sup>*</sup>
        </label>
        <div>
          <Field
            required="true"
            name="nodeLabel"
            component="input"
            type="text"
            placeholder="Label"
            className="form-control"
          />
        </div>
      </div>
      <div className="form-group">
        <label>
          Menu Type <sup>*</sup>
        </label>
        <div>
          <Field name="menuType" required="true" component="select" className="form-control">
            {menuType.map((items) => (
              <option value={items.id} key={items.id}>
                {items.key}
              </option>
            ))}
          </Field>
        </div>
      </div>

      <div className="form-group">
        <label>
          Target URL
          <sup>*</sup>
        </label>
        <div>
          <Field
            required="true"
            name="targetURL"
            component="bs-input"
            type="text"
            placeholder="Target URL"
            className="form-control"
          />
        </div>
      </div>
      <div className="form-group">
        <label>Culture</label>
        <div>
          <Field name="cultureId" component={CultureDropdownStatic} />
        </div>
      </div>
      <div className="form-group">
        <label>Menu Image</label>
        <div>
          <Field name="menuItemImage" component="input" type="text" placeholder="Menu Icon" className="form-control" />
        </div>
      </div>

      <div className="form-group">
        <label>Is Menu Title</label>
        <div>
          <Field name="menuTitle" component="select" className="form-control" required="true">
            <option value="false">False</option>
            <option value="true">True</option>
          </Field>
        </div>
      </div>

      <div className="form-group">
        <label>Parent Menu ID</label>
        <div>
          <Field name="parentMenuId" component="select" className="form-control">
            <option value="">Optional</option>
            {items.map((items) => (
              <option value={items.id} key={items.id}>
                {items.label}
              </option>
            ))}
          </Field>
        </div>
      </div>

      <div className="form-group">
        <label>Provider Type</label>
        <div>
          <Field name="provider" component="select" className="form-control">
            {providerTypes.map((providerItem) => (
              <option value={providerItem.id} key={providerItem.id}>
                {providerItem.id}
              </option>
            ))}
          </Field>
        </div>
      </div>

      <div className="form-group">
        <label>Add Auth Token?</label>
        <div>
          <Field name="addAuthToken" component="select" className="form-control">
            <option value="none">None</option>
            <option value="legacy">Legacy (v6)</option>
            <option value="v8">v8</option>
          </Field>
        </div>
      </div>

      <div className="form-group">
        <label>Link Target</label>
        <div>
          <Field name="linkTarget" component="select" className="form-control">
            <option value="_self">_self (default)</option>
            <option value="_blank">_blank</option>
            <option value="_parent">_parent</option>
            <option value="_parent">_top</option>
          </Field>
        </div>
      </div>

      <div id="success-message_save" style={{ display: 'none' }} />

      <div className="form-group">{children({ pristine, submitting })}</div>
      <span>
        <button
          type="submit"
          className="btn btn-sm btn-primary"
          style={{ marginRight: '15px' }}
          disabled={pristine || submitting}
          onClick={(values) => {
            setCurrentForm('MenuItemForm', actions);
            handleSubmit('MenuItemForm');
          }}
        >
          Submit
        </button>
        <button type="button" className="btn btn-sm btn-primary" disabled={pristine || submitting} onClick={reset}>
          Add Another Item (Clear Fields)
        </button>
        {navigationMenuAdmin.showAlert.show && navigationMenuAdmin.showAlert.formName === 'MenuItemForm' ? (
          <BootstrapSuccessAlert />
        ) : (
          ''
        )}
      </span>
      <div className="error-report" />
    </form>
  );
};

const selector = formSelectors.getFormValues('SortForm');

const mapStateToProps = (state) => {
  let { cultureId = 0, menuType = 0, appKey = 'main', provider = 'Default' } = selector(state) || {};

  return {
    cultureId,
    menuType,
    appKey,
    provider,
    initialValues: { cultureId, menuType, appKey, provider }
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'MenuItemForm',
    enableReinitialize: true
  })(MenuItemForm) as any
) as any;
