import * as React from 'react';
import { ConfigProps, DecoratedComponentClass } from 'redux-form';
import { reduxForm } from '../redux';
import FieldBuilder from './FieldBuilder';

const renderField = (field, key): any => <FieldBuilder key={key} field={field} />;

const FormBuilder = ({ json, handleSubmit, children, ...rest }: any) => {
  return (
    <form onSubmit={handleSubmit}>
      {json.fields.map(renderField)}
      {children && React.cloneElement(children, rest)}
    </form>
  );
};

export { ConfigProps, DecoratedComponentClass };
export default reduxForm({} as any)(FormBuilder);
