import { reduxObserver } from 'xcel-observer';
import { createReduxModule, ReduxModule } from 'xcel-redux-module';
import { reducers } from './app/redux/reducers';

const favoritesModule: ReduxModule = createReduxModule({
  name: 'rsv8-favorites',
  reducers
});

reduxObserver.addModule(favoritesModule);
export default favoritesModule;
