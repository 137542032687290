import * as React from 'react';
import { connect } from 'react-redux';
import { RebateTimePeriod, ShipToLocation } from 'xcel-api-generator/dist/alcon';
import { baselineYearLabelSelector, currentTimePeriodSelector } from '../../redux/selectors';
import { LocationDetail } from './LocationDetail';
import { LocationBody, LocationContainer, LocationHeader } from './styles';

const YTDUnitsLocation: React.FC<Props> = ({ location, baselineYearLabel, currentTimePeriod }) => {
  return (
    <LocationContainer key={location.shipToLocationId} className="location">
      <LocationHeader>
        <div className="location__title">{`${location.name} #${location.shipToLocationId}`}</div>
        <div className="location__subtitle">{location.addressOne}</div>
        {!!location.addressTwo && <div className="location__subtitle">{location.addressTwo}</div>}
      </LocationHeader>

      <LocationBody>
        <LocationDetail
          label={'YTD ' + baselineYearLabel}
          amount={location.ytdBaseLineUnits || 0}
          percentage={location.baseLinePercentage}
        />

        <LocationDetail
          label={'YTD ' + currentTimePeriod.year}
          amount={location.ytdCurrentUnits || 0}
          percentage={location.currentPercentage}
        />
      </LocationBody>
    </LocationContainer>
  );
};

const mapStateToProps = (state) => ({
  baselineYearLabel: baselineYearLabelSelector(state),
  currentTimePeriod: currentTimePeriodSelector(state),
});

interface StateProps {
  baselineYearLabel: string;
  currentTimePeriod: RebateTimePeriod;
}
interface DispatchProps {}
interface OwnProps {
  location: ShipToLocation;
}
type Props = StateProps & DispatchProps & OwnProps;

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps)(YTDUnitsLocation);
