import { HeaderThree } from 'rsv8-components';
import styled from 'styled-components';

export const CheckoutModuleHeading = styled(HeaderThree)`
  color: #4c4f4f;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0;

  @media (min-height: 769px) {
    font-size: 20px;
  }
` as any;
