import { promiseDispatcher } from 'xcel-react-core';
import { createJsonApiActions, getRequest } from 'xcel-redux-orm';
import { nominationApi } from '../../../api/index';

const detailString = (...args) => `getAwardDetails${args.join('-')}`;

export type getCurrentApplication<P> = (awardId: string, nomineeId: string, options?: { team?: boolean }) => P;

const getCurrentApplication: getCurrentApplication<Promise<any>> = (
  awardId: string,
  nomineeId: string,
  { team = false }
) => {
  const apiCall = team ? nominationApi.getApplicationTeamById : nominationApi.getApplicationById;
  return apiCall(
    {
      id: awardId,
      nomineeId: nomineeId ? parseInt(nomineeId, 10) : undefined
    },
    { jsonApi: true }
  );
};

const getAwardsRequest = (award: string, nominee: string) => (dispatch, getState) =>
  dispatch(createJsonApiActions(detailString(award)).request({ award, nominee }));

const getAwardsSuccess = (response, award: string, nominee: string) => (dispatch, getState) => {
  dispatch(createJsonApiActions(detailString(award)).success(response, { award, nominee }));
};

const getAwardsFailure = (error, award: string, nominee: string) => (dispatch, getState) =>
  dispatch(createJsonApiActions(detailString(award)).failure(error, { award, nominee }));

const getAwardDetails = promiseDispatcher(getCurrentApplication, {
  request: getAwardsRequest,
  success: getAwardsSuccess,
  failure: getAwardsFailure
});

const lazyGetDetails: getCurrentApplication<any> = (award: string, nominee: string, options) => (
  dispatch,
  getState
) => {
  const status = getRequest(getState(), detailString(award));
  if (!status.loading) {
    dispatch(getAwardDetails(award, nominee, options));
  }
};

export default lazyGetDetails;
