import * as React from 'react';
import styled from 'styled-components';
import { register, withTheme, withResource } from 'xcel-react-core';
import { Button } from '../../../components/Button';
import { Text } from '../../Typography';

const ToastImage = styled.img`
  height: auto;
  width: 50px;
` as any;

const Container = styled.div`
  align-items: center;
  display: flex;
  max-height: 90px;
  padding: 5px 5px 5px 10px;
` as any;

const ImageContainer = styled.div`
  margin-right: 15px;
` as any;

const Manufacturer = styled.div`
  color: #7c8180;
  font-size: 10px;
` as any;

const Name = styled.div`
  color: #4c4f4f;
  font-size: 10px;
` as any;

const ButtonRow = styled.div`
  float: right;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
` as any;

export interface Props {
  productImage: string;
  productManufacturer: string;
  productName: string;
  actionText: string;
  toCart?: any;
  addToCart?: boolean;
  toastText?: string;
}

const CatalogBody: React.FC<Props> = ({
  productImage,
  productManufacturer,
  productName,
  actionText,
  toCart,
  addToCart,
  toastText,
  ...rest
}) => {
  return (
    <React.Fragment>
      <Container {...rest}>
        <ImageContainer>
          {/* FOR DEMO PURPOSES */}
          <ToastImage
            src={
              productImage && productImage.includes('http')
                ? productImage
                : `https://xcel.rewardstation.com/catalogimages/${productImage}`
            }
          />
        </ImageContainer>
        <div>
          <Manufacturer>
            <Text themeVariation="small-secondary-text">{productManufacturer}</Text>
          </Manufacturer>
          <Name>
            <Text
              themeVariation="small-primary-text"
              dangerouslySetInnerHTML={{
                __html: `${productName && productName.length > 40 ? `${productName.substring(0, 40)}...` : productName
                  } ${actionText}`
              }}
            />
          </Name>
        </div>
      </Container>
      {addToCart && (
        <ButtonRow>
          {/* Probably temporary. Good for a demo */}
          <Button themeVariation="primary-link-xs" onClick={toCart}>
            {toastText}
          </Button>
        </ButtonRow>
      )}
    </React.Fragment>
  )
};

const mapResource = (getResource) => ({
  toastText: getResource('catalogBody.toastText', 'Go to Shopping Cart')
})

export default register('rsv8-components/Toastify/templates/CatalogBody')(
  withResource(mapResource),
  withTheme()
)(CatalogBody);
