import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { CaptionImage, Card, Text } from 'rsv8-components';
import { CustomFields } from 'rsv8-customfields';
import { WizardLink as EditLink } from 'rsv8-wizard';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';
import { individualNominationSelectors } from '../../redux/selectors/index';
import { FieldSummary } from '../CustomFieldValue/index';
import { NomineeImage } from '../NomineeImage/index';
import PointCost from '../PointCost/index';
import { AwardImage, NomineeContainer } from './styles';

export interface NominationSummaryProps {
  editable: boolean;
}

interface InternalProps extends NominationSummaryProps {
  award: any;
  nominee: any;
  editText: {
    nominee: any;
    award: any;
    criteria: any;
    details: any;
    points: any;
  };
  customFields: Array<{ id: any; value: any }>;
  nomination: any;
  formValues: any;
  showNominationIcon: string;
}

const PointSummary = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const PointSummaryBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const NominationSummary = ({
  nominee,
  award,
  editable,
  customFields,
  editText,
  nomination,
  showNominationIcon
}: InternalProps) => {
  return (
    <Card variationName="nomination-card">
      <NomineeContainer>
        <NomineeImage nominee={nominee} orientation="right" width="60px" caption={true} />
        <EditLink visible={editable} stepName="Nominee">
          {editText.nominee}
        </EditLink>
      </NomineeContainer>
      {showNominationIcon === 'true' ? (
        <NomineeContainer>
          <CaptionImage component={AwardImage} orientation="right" object={award} width="60px" height="auto">
            {award.name}
          </CaptionImage>
          <EditLink visible={editable} stepName="Awards">
            {editText.award}
          </EditLink>
        </NomineeContainer>
      ) : null}
      <hr />
      <EditLink visible={editable} stepName="Criteria">
        {editText.criteria}
      </EditLink>
      <CustomFields
        formType="NominationForm"
        groupKey="criteria"
        appKey={award.id}
        component={FieldSummary}
        customFieldValues={customFields}
      />
      <EditLink visible={editable} stepName="Details">
        {editText.details}
      </EditLink>
      {award.points && (award.points.length > 0 || (award.pointRange !== undefined && award.pointRange !== null)) && (
        <PointSummary>
          <PointSummaryBlock>
            <Text variationName="field-label">{editText.points}</Text>
            <Text>{nomination && nomination.points}</Text>
          </PointSummaryBlock>
          {award.displayPointCostAnalysis && (
            <PointCost
              flexDirection={'column'}
              padding={'0px'}
              id={award.id}
              pointValue={nomination && nomination.points}
              nomineeId={nominee.id}
            />
          )}
        </PointSummary>
      )}
      <CustomFields
        formType="NominationForm"
        groupKey="details"
        appKey={award.id}
        component={FieldSummary}
        customFieldValues={customFields}
      />
    </Card>
  );
};

const mapState = (state) => ({
  nominee: individualNominationSelectors.getCurrentNominee(state),
  award: individualNominationSelectors.getCurrentAward(state),
  customFields: individualNominationSelectors.getCurrentCustomFields(state),
  nomination: individualNominationSelectors.getCurrentNomination(state)
});

const mapResource = (getResource) => ({
  editText: {
    points: getResource('nominationProcess.awardLabel', 'Award Value'),
    nominee: getResource('nominationProcess.editNomineeLinkText', 'Edit Nominee'),
    award: getResource('nominationProcess.editAwardLinkText', 'Edit Award'),
    criteria: getResource('nominationProcess.editCriteriaLinkText', 'Edit Criteria'),
    details: getResource('nominationProcess.editDetailLinkText', 'Edit Details')
  },
  showNominationIcon: getResource('nominations.showNominationIcon', 'true')
});

export default compose<any>(
  connect(
    mapState,
    null
  ),
  withResource(mapResource)
)(NominationSummary) as React.SFC<NominationSummaryProps>;
