import * as React from 'react';
import { compose } from 'redux';
import { PageRenderer } from 'rsv8-cms';
import NominationCollapse from '../NominationCollapse/index';

export interface Props {
  type: string;
  buttonTitle?: any;
  page: string;
}

class CriteriaDefinitions extends React.Component<Props, {}> {
  render() {
    const { type, buttonTitle, page } = this.props;

    return (
      <NominationCollapse title={buttonTitle}>
        <PageRenderer path={`partial/nomination/${type}/${page}`} />
      </NominationCollapse>
    );
  }
}

export default compose<any>(CriteriaDefinitions) as React.ComponentClass<Props>;
