import { getActivityTypes, patchActivitiesById } from './mapActions';
import * as activityActions from './activities';
import ActivityStatus from './ActivityStatus';
import * as cultureActions from './cultures';

export default {
  ActivityStatus,
  getActivityTypes,
  patchActivitiesById,
  ...activityActions,
  ...cultureActions
} as any;