import * as React from 'react';
import { connect } from 'react-redux';
import { getValue } from 'xcel-util';
import cmsPluginSelectors from '../../../redux/cms.plugin.selectors';
import { componentStylePropertySet } from '../../../redux/component.actions';
import { getMediaQuery, getPseudoSelector } from '../../../redux/theme.selectors';
import { getTheme } from '../../../redux/theme.selectors';
import { mapDispatchToActions } from '../../../util';
const getName = (props) => {
  return props.name.replace(/\./gm, '/').replace(/\=\>/gm, '.');
};
const getNameSpace = (props) => {
  return [
    ...getName(props).split('.'),
    'styles',
    props.currentStyle,
    ...props.activeSelectors,
    ...props.activeMediaQuery,
    props.property
  ].join('|');
};
const getPropertyValue = (props) => {
  const value = getValue(props.theme, getNameSpace(props), '|') || '';
  return value;
};

class StyleField extends React.Component<any> {
  state = { value: undefined };
  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      this.props.name !== prevProps.name ||
      this.props.theme !== prevProps.theme ||
      this.props.currentStyle !== prevProps.currentStyle ||
      this.props.activeSelectors !== prevProps.activeSelectors ||
      this.props.activeMediaQuery !== prevProps.activeMediaQuery
    ) {
      this.setState({ value: getPropertyValue(this.props) });
    }
  }

  render() {
    const { component, property, className, actions, onChange, type } = this.props;
    const handleChange = (componentProperty: any) => (value) => {
      this.setState({ value });
      if (this.props.type !== 'text') {
        actions.componentStylePropertySet({
          name: getName(this.props),
          property: componentProperty,
          value
        });
        if (onChange) {
          onChange(value);
        }
      }
    };
    const handleBlur = (componentProperty: any) => (e) => {
      // for checkboxes, set value to false if unchecked instead of an empty string.
      const value = type === 'checkbox' && this.state.value === '' ? false : this.state.value;

      // before we save the property we need to convert it back to original name.
      actions.componentStylePropertySet({
        name: getName(this.props),
        property: componentProperty,
        value
      });
      if (onChange) {
        onChange(this.state.value);
      }
    };
    const Component = component;
    return (
      <Component
        className={className}
        onChange={handleChange(property)}
        onBlur={handleBlur(property)}
        value={this.state.value}
      />
    );
  }
}
const mapStateToProps = (state) => ({
  currentStyle: getTheme(state).currentStyle,
  activeSelectors: getPseudoSelector(state),
  activeMediaQuery: getMediaQuery(state),
  theme: cmsPluginSelectors.getTheme(state)
});
const mapActionsToProps = mapDispatchToActions({ componentStylePropertySet });

export default connect(
  mapStateToProps,
  mapActionsToProps
)(StyleField) as any;
