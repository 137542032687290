import classNames from 'classnames';
import * as React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { BaseSocialShareablesButton, SocialShareableButtonProps } from './BaseSocialShareablesButton';
import { ShareInputText } from './ShareInputText';
import {
  ButtonWrapper,
  Container,
  IconWrapper,
  LabelText,
  SaveButton,
  Separator,
  ShareButton
  } from './styles';

enum ButtonStates {
  Initial = 'Initial',
  SelectedSaveAction = 'SelectedSaveAction',
  SelectedShareAction = 'SelectedShareAction',
  Unfolded = 'Unfolded',
  AvailableForCopying = 'AvailableForCopying'
}

interface SocialShareableButtonState {
  state: ButtonStates;
}

export class HoverNotSupported extends BaseSocialShareablesButton<
  SocialShareableButtonProps,
  SocialShareableButtonState
> {
  constructor(props: SocialShareableButtonProps) {
    super(props);
    this.state = {
      state: ButtonStates.Initial
    };
  }

  render() {
    return (
      <Container className={classNames(this.props.className, 'hover-not-supported')}>
        <OutsideClickHandler onOutsideClick={this.onLeave}>{this.renderContent()}</OutsideClickHandler>
      </Container>
    );
  }

  renderContent() {
    if (this.state.state === ButtonStates.AvailableForCopying) {
      return <ShareInputText value={this.props.shareURL} onLeave={this.onLeave} />;
    }

    return (
      <ButtonWrapper
        className={classNames('button-wrapper', 'hover-not-supported', { unfolded: this.isUnfoldedState })}
      >
        <Separator className="separator" />
        <SaveButton
          onClick={this.onSave}
          className={classNames('save-button', 'hover-not-supported', {
            selected: this.state.state === ButtonStates.SelectedSaveAction,
            unfolded: this.isUnfoldedState
          })}
          hoverCustomIcon={(this.props.saveAction && this.props.saveAction.hoverIcon) || ''}
        >
          <IconWrapper>{this.renderSaveButtonIcon()}</IconWrapper>
          <LabelText className="label-text">
            {this.props.saveLabelText || this.props.saveAction.label || 'save'}
          </LabelText>
        </SaveButton>
        <ShareButton
          onClick={this.onShare}
          className={classNames('share-button', 'hover-not-supported', {
            selected: this.state.state === ButtonStates.SelectedShareAction,
            unfolded: this.isUnfoldedState
          })}
          hoverCustomIcon={this.props.shareAction.hoverIcon}
        >
          <IconWrapper>{this.renderShareButtonIcon()}</IconWrapper>
          <LabelText className="label-text">
            {this.props.shareLabelText || this.props.shareAction.label || 'share'}
          </LabelText>
        </ShareButton>
      </ButtonWrapper>
    );
  }

  private get isUnfoldedState(): boolean {
    return [ButtonStates.SelectedSaveAction, ButtonStates.SelectedShareAction].includes(this.state.state);
  }

  private onLeave = () => {
    this.setState({
      state: ButtonStates.Initial
    });
  };

  private onShare = () => {
    if (this.isUnfoldedState) {
      this.setState(
        {
          state: ButtonStates.AvailableForCopying
        },
        () => this.props.shareAction.action()
      );
    } else {
      this.setState({
        state: ButtonStates.SelectedShareAction
      });
    }
  };

  private onSave = () => {
    if (this.isUnfoldedState) {
      this.props.saveAction.action();
    } else {
      this.setState({
        state: ButtonStates.SelectedSaveAction
      });
    }
  };
}
