import * as Icon from 'react-fontawesome';
import {
  Button,
  HeaderThree,
  Image,
  Link
  } from 'rsv8-components';
import { ImageSlider } from 'rsv8-components';
import styled from 'styled-components';

export const PRODUCT_DESCRIPTION_HEIGHT = 250;

export const AddToCartButton = styled(Button)`
  align-items: center;
  background-color: #b5be35;
  border-radius: 3px;
  border: 0;
  color: #fff;
  display: flex !important;
  padding: 5px 48px;

  &[disabled] {
    background-color: #e6e6e6;
    cursor: not-allowed !important;
    color: #fff;

    g {
      fill: #fff;
    }

    path {
      fill: #fff;
    }

    &:active,
    &:focus,
    &:hover {
      background-color: #e6e6e6;
      color: #fff;
    }
  }

  &:active,
  &:focus,
  &:hover {
    background-color: #b5be35;
    color: #fff;
    text-decoration: none;
  }

  @media (max-width: 992px) {
    justify-content: center;
    margin-bottom: 10px;
    padding-left: 40px;
    width: 100%;
  }
` as any;

export const CartIcon = styled.img`
  color: #fff;
  height: 22px;
  width: 20px;

  g {
    fill: #fff;
  }
` as any;

export const ShippingDescription = styled.div`
  margin-bottom: 15px;
  margin-top: 15px;
` as any;

export const MobileImage = styled.img`
  display: flex;
  margin: 0 auto;
  max-width: 50%;

  @media (min-width: 993px) {
    display: none;
  }
` as any;

export const MobileSlider = styled(ImageSlider)`
  margin-top: 15px;
  @media (min-width: 993px) {
    display: none;
  }
  img {
    max-width: 40%;
    margin: 0 auto;
  }
  div {
    outline: none;
  }
` as any;

export const ButtonContainer = styled.div`
  align-items: center;
  display: flex;

  @media (max-width: 992px) {
    flex-direction: column;
    justify-content: center;
  }
` as any;

export const ContentContainer = styled.div`` as any;

export const DetailLink = styled(Link)`
  color: #4192a5;

  &:active,
  &:focus,
  &:hover {
    color: #4192a5;
    outline: none !important;
    text-decoration: none;
  }
` as any;
export const ContactUsLink = styled(Link)`
  margin-bottom: 20px;
` as any;
export const ProductDescriptionHeading = styled(HeaderThree)`
  color: #4c4f4f;
` as any;

export const ProductDescription = styled.div`
  color: #4c4f4f;
  max-height: 2000px;
  transition: max-height 1.5s ease-out;
  ${(props: any) =>
    props.hideDetail &&
    !props.detailExpanded &&
    `
      height: ${PRODUCT_DESCRIPTION_HEIGHT}px;
      max-height: ${PRODUCT_DESCRIPTION_HEIGHT}px !important;
      overflow: hidden;
      position: relative;
      
      &:before {
        content: '';
        height: 75%;
        left: 0;
        position: absolute;
        bottom: 0;
        width: 100%;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
      }
    `};
` as any;

export const ResultsIcon = styled(Icon)`
  margin-right: 4px;
` as any;

export const SingleImage = styled.img`
  display: ${(props: any) => (props.loaded ? 'flex' : 'none')};
  margin: 30px auto 0 auto;
  max-width: 75%;
` as any;

export const DescriptionToggle = styled(Button)`
  color: #4192a5;
  cursor: pointer;
  text-align: center;
  outline: none;
` as any;

export const DescriptionToggleIcon = styled(Icon)`
  margin-right: 4px;
` as any;

export const TrendingContainer = styled.div`
  margin-top: 125px;
` as any;

export const ProductName = styled(HeaderThree)`
  margin-top: 0;
` as any;

export const ShopCartIcon = styled(Image)`
  filter: none;
  height: auto;
  mix-blend-mode: normal;
  overflow: visible;
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
` as any;

export const CartIconContainer = styled.div`
  margin-right: 8px;
` as any;

export const HiddenProductId = styled.div`
  color: #fff;
` as any;
