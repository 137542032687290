import { accountApiActions } from './accountApiActions';

export type GetAccountCreditsParams = {
  pageRequestFilter?: string;
  pageRequestSelect?: string;
  pageRequestOffset?: number;
  pageRequestLimit?: number;
  pageRequestSort?: string;
};

export const getAccountCredits = (params: any) => async (dispatch, getState) => {
  params = ['sort', 'filter', 'offset', 'limit', 'select'].reduce((obj, key) => {
    if (obj[key]) {
      obj[`pageRequest${key.substr(0, 1).toUpperCase() + key.substr(1)}`] = obj[key];
    }
    return obj;
  }, params);
  await dispatch(accountApiActions.getAccountCredits(params, { jsonApi: true }));
};
(getAccountCredits as any).endpoint = 'getCredits';
export default getAccountCredits;
