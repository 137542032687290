import * as React from 'react';
import styled, { keyframes } from 'styled-components';

const LoaderTextContainer = styled.div`
  width: 20%;
  margin: 0px auto;
`;

const LoaderKeyFrames = keyframes`
    0%   { content: '.';}
    25%  { content: '..';}
    50%  { content: '...';}
    75%  { content: '....';}
    100% { content: '.....';}
`;

const LoadText = styled.div`
  font-size: 18px;
  color: #7c8180;
`;

const LoadDots = styled.div`
  &:after {
    color: #7c8180;
    font-size: 18px;
    content: '.';
    animation: ${LoaderKeyFrames} 0.55s linear infinite alternate;
  }
`;

type LoaderTextProps = {
  displayText?: string;
};

const LoaderText = ({ displayText = 'Loading' }: LoaderTextProps) => {
  return (
    <LoaderTextContainer>
      <LoadText>{displayText}</LoadText>
      <LoadDots />
    </LoaderTextContainer>
  );
};

export default LoaderText;
