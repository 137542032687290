import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { ConfigProps, InjectedFormProps } from 'redux-form';
import {
  Card,
  Grid,
  GridColumn,
  HeaderThree,
  Text
  } from 'rsv8-components';
import { CustomFields } from 'rsv8-customfields';
import { Field, formSelectors, reduxForm } from 'rsv8-forms';
import { Context, withWizard } from 'rsv8-wizard';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';
import { numberFormat } from 'xcel-util';
import { Row } from '../../components/Award/styles';
import PointCost from '../../components/PointCost/index';
import { teamNominationActions } from '../../redux/actions/index';
import { teamNominationSelectors } from '../../redux/selectors/index';
// import PointCost from '../../components/PointCost/index';

export interface TeamDetailFormProps extends Partial<ConfigProps> {}

const StyledForm = styled.form`
  .help-block {
    margin: 0;
  }
  .points-column {
    .form-group {
      margin-bottom: 0;
    }
  }
`;

const StyledLabel = styled.label`
  &::after {
    color: red;
    content: ' *';
  }
` as any;

interface InternalProps extends TeamDetailFormProps {
  award: any;
  actions: {
    saveNomination: Function;
    selectPointValue: Function;
    selectCustomFields: Function;
  };
  wizard?: Context;
  hasAwardOptions: boolean;
  pointOptions: any[];
  teamNameLabel: string;
  pointLabel: string;
  pointTitle: string;
  pointDescription: string;
  pointDisclaimer: string;
  detailTitle: string;
  detailDescription: string;
  formValues: any;
  selectPointLabel?: string;
  parentIds: any;
}

class TeamDetailForm extends React.Component<InternalProps & InjectedFormProps, {}> {
  state = {
    isValid: true
  };

  componentDidMount() {
    if (this.props.wizard) {
      this.props.wizard.registerIncrement(this.props.handleSubmit(this.submit));
    }
  }

  submit = (values: any) => {
    const { actions, pointOptions } = this.props;
    if (values.pointCount === undefined && pointOptions.length > 0) {
      actions.selectPointValue(pointOptions[0].value);
    }
    actions.selectCustomFields(values.TeamNomination);
    actions.saveNomination();
  };

  onPointChange = (e, points) => this.props.actions.selectPointValue(points);

  validatePoints = (point) => {
    const found =
      this.props.pointOptions.filter((pointOption) => {
        return pointOption.value && point && pointOption.value.toString() === point.toString();
      }).length !== 0;
    if (!found) {
      this.setState({ isValid: false });
      return <Text variationName="legal-small-text" dangerouslySetInnerHTML={{ __html: this.props.pointDisclaimer }} />;
    }
    this.setState({ isValid: true });
    return undefined;
  };

  render() {
    const {
      teamNameLabel,
      pointOptions,
      pointLabel,
      pointTitle,
      pointDescription,
      pointDisclaimer,
      selectPointLabel,
      detailTitle,
      detailDescription,
      formValues,
      award,
      parentIds
    } = this.props;
    const hasPointRange = award && award.pointRange !== undefined && award.pointRange !== null;
    const pointComponentType = hasPointRange ? 'bs-input' : 'bs-select';
    return (
      <StyledForm onSubmit={this.props.handleSubmit(this.submit)}>
        {pointOptions !== undefined && pointOptions !== null && pointOptions.length > 0 ? (
          <Card variationName="nomination-card">
            <HeaderThree>{pointTitle}</HeaderThree>
            {pointDescription && <Text>{pointDescription}</Text>}
            <Grid fluid={true}>
              <Row>
                <GridColumn className={'points-column'} md={12}>
                  <Field
                    name="pointCount"
                    component={pointComponentType}
                    label={<StyledLabel>{pointLabel}</StyledLabel>}
                    initialValue={pointOptions[0].value}
                    options={pointOptions}
                    defaultSelectText={selectPointLabel}
                    validate={hasPointRange ? [this.validatePoints] : ['required']}
                    onChange={this.onPointChange}
                  />
                  {hasPointRange && this.state.isValid && (
                    <Text variationName="legal-small-text">{pointDisclaimer}</Text>
                  )}
                </GridColumn>
                <GridColumn md={12}>
                  <PointCost
                    id={award ? award.id : null}
                    pointValue={formValues && formValues.pointCount}
                    nomineeId={0}
                    padding={'35px 0 0 20px'}
                    showValue={false}
                  />
                </GridColumn>
              </Row>
            </Grid>
          </Card>
        ) : null}
        <Card variationName="nomination-card">
          <HeaderThree>{detailTitle}</HeaderThree>
          {detailDescription && <Text>{detailDescription}</Text>}
          <Field
            name="teamName"
            component="bs-input"
            validate={['required']}
            label={<StyledLabel>{teamNameLabel}</StyledLabel>}
          />
          <CustomFields formType="TeamNomination" groupKey="details" appKey={award.id} parentIds={parentIds}/>
        </Card>
      </StyledForm>
    );
  }
}

const mapState = (state) => {
  const award = teamNominationSelectors.getCurrentAward(state);
  const customFields = teamNominationSelectors.getCurrentCustomFields(state);
  const pointRangeMaximum = award && award.pointRange ? numberFormat(award.pointRange.maximum) : null;
  return {
    initialValues: {
      teamName: teamNominationSelectors.getCurrentTeamName(state),
      TeamNomination: teamNominationSelectors.getCurrentCustomFieldMap(state),
      pointCount: teamNominationSelectors.getCurrentNominationPoints(state)
    },
    pointOptions: teamNominationSelectors.getNomineeAwardOptions(state),
    award,
    pointRangeMaximum,
    formValues: formSelectors.getFormValues('TeamDetailForm')(state),
    parentIds: customFields
  };
};

const mapDispatch = (dispatch) => ({
  actions: bindActionCreators(
    { saveNomination: teamNominationActions.save, selectPointValue: teamNominationActions.selectPointValue, selectCustomFields: teamNominationActions.selectCustomFields },
    dispatch
  )
});

const mapResource = (getResource) => ({
  teamNameLabel: getResource('teamNomination.teamNameLabel', 'Team Name'),
  pointLabel: getResource('teamNomination.detailform.pointTitle', 'Point Value'),
  pointTitle: getResource('teamNomination.detailform.pointTitle', 'Select Award'),
  pointDescription: getResource(
    'teamNomination.detailform.pointDescription',
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
  ),
  pointDisclaimer: getResource(
    'teamNomination.detailform.pointDisclaimer',
    'Must be in increments of {award.pointRange.increment} ({award.pointRange.minimum} - {award.pointRange.maximum} points)'
  ),
  detailTitle: getResource('teamNomination.detailform.detailTitle', 'Tell us a little bit about this nomination'),
  detailDescription: getResource(
    'teamNomination.detailform.detailDescription',
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. '
  ),
  selectPointLabel: getResource('nominationDetails.SelectPointLabel', 'Please Select')
});

export default compose<any>(
  connect(
    mapState,
    mapDispatch
  ) as any,
  reduxForm({ form: 'TeamDetailForm' }) as any,
  withWizard(),
  withResource(mapResource)
)(TeamDetailForm as any) as React.SFC<TeamDetailFormProps>;
