import * as React from 'react';
import * as moment from 'moment';
import { connect } from 'react-redux';
import { register, withContent, withTheme } from 'xcel-react-core';
import { getUser } from '../../redux/selectors';

interface CurrentDateProps {
  dispatch: any,
  format: string,
  user: any,
}

const CurrentDate: React.SFC<CurrentDateProps> = ({dispatch, children, format, user, ...rest}) => {
  const currentDateLabel = () => {
    if (!user) return '';
    const { cultureLanguage } = user;
    const dateFormat = format || "MMMM DD YYYY";

    moment.locale(cultureLanguage);
    const dateString = moment().format(dateFormat);

    return (
      <div>
        {dateString}
      </div>
    )
  };

  return <div {...rest}>{currentDateLabel()}</div>;
};

const mapStateToProps = (state) => ({
  user: getUser(state),
});

const mapContentToProps = (getContent) => ({
  format: getContent('format', { type: 'none', label: 'Date Format' }),
});

export default register('rsv8-components/CurrentDate')(
  connect(mapStateToProps),
  withContent(mapContentToProps),
  withTheme()
)(CurrentDate);
