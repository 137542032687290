import * as React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { destroyRequest } from 'xcel-redux-orm';
import { assetAdminApiActions, setActiveModal } from '../../redux/actions';
import { getActiveRoute } from '../../redux/selectors';
import { assetAdminToast } from '../../util';

interface Delete {
  assetId: string;
  actions?: {
    deleteAsset: typeof assetAdminApiActions.deleteAssetsById;
    destroyRequest: typeof destroyRequest;
    getRoutes: typeof assetAdminApiActions.getClientAssetRoutes;
    setActiveModal: typeof setActiveModal;
  };
  activeRoute: number;
  handleDelete: Function;
}

class DeleteAsset extends React.Component<Delete> {
  handleClick = async () => {
    if (!confirm('Are you sure you want to delete this file?')) return;

    const {
      assetId,
      activeRoute,
      actions: { deleteAsset },
      handleDelete
    } = this.props;

    try {
      await deleteAsset({ id: assetId });
      await this.props.actions.destroyRequest('getClientAssetRoutes');
      await this.props.actions.getRoutes({ filterFilter: `id = ${activeRoute}` });
      handleDelete();
    } catch (err) {
      let errorResponse;

      if (err.response === undefined) {
        errorResponse = err; // catch code errors
      } else {
        errorResponse =
          err.response.data.errors[0].title !== ''
            ? err.response.data.errors[0].title
            : err.response.data.errors[0].meta.id; // catch server errors
      }
      assetAdminToast('admin-warning', errorResponse);
    }
  };
  render() {
    return (
      <form>
        <Button onClick={this.handleClick} bsStyle="danger">
          Delete File
        </Button>
      </form>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      destroyRequest,
      setActiveModal,
      deleteAsset: assetAdminApiActions.deleteAssetsById,
      getRoutes: assetAdminApiActions.getClientAssetRoutes
    },
    dispatch
  )
});

const mapStateToProps = (state, ownProps) => {
  return {
    activeRoute: getActiveRoute(state)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteAsset as any);
