import { userStateSelectors } from 'rsv8-auth';
import { getReduxStore } from 'xcel-redux-module';
import cultureSelector from '../redux/selectors/culture';
const activeCultureMiddleware = {
  request: ({ request }) => ({ reject, next, resolve }) => {
    const store = getReduxStore();
    if (store) {
      const state = store.getState();
      const user = userStateSelectors.getUser(state);
      const culture = cultureSelector.getActive(state);
      let cultureId = culture ? culture.id : (user && user.cultureId) || 0;
      if (request.options && request.options.forceCultureId !== undefined) {
        cultureId = request.options.forceCultureId;
      }
      if (cultureId !== undefined) {
        request.headers = { ...request.headers, cultureId };
      }
    }
    next();
  }
};

export default activeCultureMiddleware;
