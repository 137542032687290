import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { bindActionCreators } from 'redux';
import { HoverProvider } from 'rsv8-cms';
import {
  AdminPageRenderer,
  contentTypeActions,
  EditToolbar,
  PublishButtonComponent,
  socialActions,
  socialSelector,
  SourceButton,
  SourceEditor
  } from 'rsv8-cms';
import { ContentManager } from 'rsv8-cms';
import { ContentCultureModel, ContentVersionModel, SocialDetailModel } from 'xcel-api-generator/dist/social';
import { withErrorBoundary } from 'xcel-react-core';

const { activityTypes, activitiesSelector, culturesByActivity, currentVersion } = socialSelector;

export interface Props extends RouteComponentProps<any> {
  activityTypes: Array<any>;
  activity: SocialDetailModel;
  cultures: Array<ContentCultureModel>;
  version: ContentVersionModel;
  getActivityDetails: any;
  getActivityTypes: any;
  setContentType: any;
  editVersion: any;
}

class ActivityEdit extends React.Component<Props> {
  state = {
    sourceOpen: false
  };

  componentDidMount() {
    const {
      match: { params },
      getActivityDetails,
      getActivityTypes,
      setContentType
    } = this.props;

    getActivityTypes({});
    getActivityDetails(params.id, {});
    setContentType('Activity');
  }

  handleOpenSource = () => {
    this.setState({ sourceOpen: !this.state.sourceOpen });
  };

  handlePublish = () => {
    const { editVersion, activity, version } = this.props;
    editVersion(activity.id, version.id, { content: version.content, isPublished: true, title: version.title });
  };

  render() {
    return (
      <HoverProvider property={'model'}>
        <EditToolbar />
        <Row>
          <Col md={18}>
            <SourceEditor visible={this.state.sourceOpen} />
            <AdminPageRenderer />
          </Col>
          <Col md={6}>
            <ContentManager {...this.props} />
            <PublishButtonComponent version={this.props.version} onClick={this.handlePublish} />
            <SourceButton onClick={this.handleOpenSource} />
          </Col>
        </Row>
      </HoverProvider>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: { params }
  } = ownProps;
  const activity = activitiesSelector.selectOne(state, params.id) || {};
  const cultures = culturesByActivity(state, activity);

  return {
    activityTypes: activityTypes(state),
    version: currentVersion(state),
    activity,
    cultures
  };
};

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      getActivityTypes: socialActions.getActivityTypes,
      getActivityDetails: socialActions.getActivitiesByIdDetails,
      setContentType: contentTypeActions.setActive,
      editVersion: socialActions.editVersion
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatch
)(withErrorBoundary()(ActivityEdit));
