import { mapJsonApiActions } from 'xcel-redux-orm';
import { metricApi } from '../../api';

const metricApiActions = mapJsonApiActions(metricApi);

const getProgramByKey = (programKey: string) => async (dispatch, getState) => {
  try {
    const response = await dispatch(
      metricApiActions.getProgramByProgramKey(
        {
          programKey,
        },
        { jsonApi: true }
      )
    );

    return response;
  } catch (e) {
    throw e
  }
};

export { getProgramByKey };
