import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { contentActions, contentSelector, contentTypeSelector } from '../../index';
import CodeEditor from '../CodeEditor';
const SourceEditorBase = styled(CodeEditor)`
  margin: 0 !important;
  text-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.85) !important;
` as any;
const OverlaySourceEditor = styled.div`
  left: 16px;
  top: 0;
  position: absolute;
  visibility: hidden;
  transition: opacity 500ms;
  opacity: 0;
  height: calc(100vh - 80px);
  width: calc(100vw - 32px);
  overflow: auto;
  z-index: 1000;
  ${(props: any) =>
    props.visible &&
    `
    visibility: visible; 
    opacity: 1;
  `};
` as any;
const options = { folding: true, foldingStrategy: 'auto' };
const SourceEditor = ({ value, raw, dispatch, visible, contentTypeId, ...rest }) => {
  const handleRawChange = (changed) => {
    dispatch(contentActions.setRaw(contentTypeId, changed));
  };
  return (
    <OverlaySourceEditor visible={visible}>
      <SourceEditorBase
        {...rest}
        onChange={handleRawChange}
        value={value || raw || ''}
        isEditable={true}
        options={options}
        language={'json'}
      />
    </OverlaySourceEditor>
  );
};
const mapStateToProps = (state) => {
  const contentTypeId = contentTypeSelector.getActive(state);
  return {
    contentTypeId,
    raw: contentSelector.getRaw(state, contentTypeId)
  };
};
export default connect(mapStateToProps)(SourceEditor as any) as any;
