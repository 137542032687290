import * as React from 'react';
import { animations, Easing } from 'xcel-react-animations';
import { Circle, LoadingBox, LoadingContainer } from './styles';

// RGB colors (HEX = '#008744', '#0057e7', '#d62d20', '#ffa700')
const defaultColors = [[0, 135, 68], [0, 87, 231], [214, 45, 32], [255, 167, 0]];
export type AnimatedCircleProps = { colors?: Array<string>; width?: number; strokeWidth?: any; className?: string };

class AnimatedCircle extends React.Component<AnimatedCircleProps, any> {
  canvas: any;
  state = {};
  prevColorTime = 0;
  indexColor = 0;
  firstColor = defaultColors[0];
  secondColor = defaultColors[1];

  componentDidMount() {
    this.spinAnimation();
  }

  colorByIndex(index: number) {
    let color;

    if (index > defaultColors.length - 1) {
      color = defaultColors[0];
    } else {
      color = defaultColors[index];
    }

    return color;
  }

  colorTransition(beginAnimation: number) {
    const COLOR_TIME = 3000;
    const colorTime = (new Date().getTime() - beginAnimation) % COLOR_TIME;

    // Detecting when the animation has finished based on its time
    // in order to change the colors
    if (this.prevColorTime > colorTime) {
      this.indexColor++;
      if (this.indexColor > defaultColors.length - 1) {
        this.indexColor = 0;
      }

      this.firstColor = this.colorByIndex(this.indexColor);
      this.secondColor = this.colorByIndex(this.indexColor + 1);
    }
    this.prevColorTime = colorTime;

    // transition between to colors
    let r = Easing.linear(this.firstColor[0], this.secondColor[0], colorTime, COLOR_TIME);
    let g = Easing.linear(this.firstColor[1], this.secondColor[1], colorTime, COLOR_TIME);
    let b = Easing.linear(this.firstColor[2], this.secondColor[2], colorTime, COLOR_TIME);

    return `rgb(${r}, ${g}, ${b})`;
  }

  spinAnimation() {
    const { width, height } = this.canvas;
    const cxt = this.canvas.getContext('2d');
    const LINE_WIDTH = 3.5;
    // Antialias value: in order to remove additional pixels and jaggies
    const TOLERANCE = 0.5;
    // radius value based on the container size and the antialias value
    // in order to fit perfectly in the canvas
    const radius = width / 2 - (LINE_WIDTH * 0.5 + TOLERANCE);

    const beginColorAnimation = new Date().getTime();
    const { PI } = Math;
    const TAU = PI * 2;
    const ANIMATION_TIME = 1500;
    const HALF_ANIMATION_TIME = ANIMATION_TIME * 0.5;
    animations.interval(
      (theta) => {
        cxt.clearRect(0, 0, width, height);
        let tailStroke = 2 * Math.PI,
          baseStroke = 0;

        // the animation is splitted
        if (theta >= HALF_ANIMATION_TIME) {
          tailStroke = Easing.quadInOut(TAU, 0, theta, HALF_ANIMATION_TIME);
        } else if (theta < HALF_ANIMATION_TIME) {
          baseStroke = Easing.quadInOut(0, TAU, theta, HALF_ANIMATION_TIME);
        }

        cxt.beginPath();
        cxt.save();
        cxt.translate(TOLERANCE, TOLERANCE); // Apply antialiase
        cxt.lineCap = 'round';
        cxt.lineWidth = this.props.strokeWidth === undefined ? LINE_WIDTH : this.props.strokeWidth;
        cxt.arc(width / 2, height / 2, radius, baseStroke, tailStroke, false);
        // colors work in another animation time
        cxt.strokeStyle = this.colorTransition(beginColorAnimation);
        cxt.stroke();
        cxt.restore();
        cxt.closePath();
      },
      ANIMATION_TIME,
      true
    );
  }

  getCanvas = (element: any) => {
    this.canvas = element;
  };

  render() {
    const { width = 50, className } = this.props;

    return (
      <LoadingBox width={width} className={className}>
        <Circle>
          <canvas width={width * 2} height={width * 2} ref={this.getCanvas} style={{ width: width, height: width }} />
        </Circle>
      </LoadingBox>
    );
  }
}

const Loader: React.SFC<AnimatedCircleProps> = ({ className, ...rest }) => {
  return (
    <LoadingContainer className={className}>
      <AnimatedCircle {...rest} />
    </LoadingContainer>
  );
};

export { Loader, AnimatedCircle };
