import styled from 'styled-components';
import { AwardImage as AwardImageBase, NomineeImage as NomineeImageBase } from '../NomineeImage/index';

export const NomineeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
` as any;

export const AwardImage = AwardImageBase;

export const NomineeImage = NomineeImageBase;

export const NomineeNameContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const NomineeName = styled.span`
  padding-left: 15px;
` as any;
