import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button } from 'rsv8-components';
import styled from 'styled-components';
const NavigationButtons = ({ previous, lastPage, className, previousPage }) => (
  <Row className={className}>
    <Col xs={2} />
    <Col xs={16} className="center-block">
      {previous && (
        <Button type="button" className="previous" themeVariation={'secondary'} onClick={previousPage}>
          Previous
        </Button>
      )}{' '}
    </Col>
    <Col xs={6} className="center-block">
      {lastPage ? (
        <Button type="submit">Done</Button>
      ) : (
        <Button type="submit" className="next">
          Next
        </Button>
      )}
    </Col>
  </Row>
);
export const StyledNavigationButtons = styled(NavigationButtons)`
  .col {
    align-content: center !important;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
` as any;
