import styled, { keyframes } from 'styled-components';

const circleAnimation = keyframes`
  100% {
    transform: rotate(360deg);
  }
` as any;
const LoadingContainer = styled.div`
  position: relative;
  padding: 5%;
` as any;
const LoadingBox = styled.div`
  position: relative;
  margin: 0 auto;
  width: ${(props: { width: any }) => props.width || 50}px;
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
` as any;
const Circle = styled.div`
  animation: ${circleAnimation} 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
` as any;

export { Circle, LoadingBox, LoadingContainer };
