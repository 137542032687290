import * as React from 'react';
import { shouldUpdate, withProps } from 'recompose';
import { withLayeredContext } from 'xcel-react-core';

const doNothing = () => false;

const mapProps = (props) => {
  const { value, id } = props;
  const { isOpen } = value;
  return { open: isOpen ? isOpen(id) : false };
};
const componentShouldUpate = (props, nextProps) => {
  return (
    props['open'] !== nextProps['open'] ||
    props.className !== nextProps.className ||
    props['open'] === true ||
    props.id !== nextProps.id ||
    props.cultureId !== nextProps.cultureId ||
    props.children !== nextProps.children
  );
};

export const MenuContext = withLayeredContext(withProps(mapProps), shouldUpdate(componentShouldUpate))(
  React.createContext({
    isOpen: doNothing,
    open: doNothing,
    close: doNothing,
    closeAll: doNothing
  })
) as any;
