import { alcon } from 'xcel-api-generator';
import {
  CalculateByPatientsRequest,
  ConsumerProductsUpdate,
  ForecastRequestMailData,
  Patient,
  PatientsUpdate,
} from 'xcel-api-generator/dist/alcon';
import { destroyRequest, getRequest, mapJsonApiActions } from 'xcel-redux-orm';
import * as constants from '../../constants';

const alconApiActions: typeof alcon = mapJsonApiActions(alcon);

// #region Account
export const getAccount = () => async (dispatch, getState) => {
  const endpoint = 'getMagnifeyeAccount';
  const request = getRequest(getState(), endpoint);
  if (!request.loading) {
    dispatch(destroyRequest(endpoint));
  }
  return dispatch(alconApiActions[endpoint]({ jsonApi: true }));
};

export const getAccountPointSummary = () => async (dispatch, getState) => {
  const endpoint = 'getMagnifeyeAccountPointsummary';
  const request = getRequest(getState(), endpoint);
  if (!request.loading) {
    dispatch(destroyRequest(endpoint));
  }
  return dispatch(alconApiActions[endpoint]({ jsonApi: true }));
};
// #endregion

// #region AchEnrollment
export const getEnrolledStatus = () => async (dispatch, getState) => {
  try {
    const response = await dispatch(alconApiActions.getMagnifeyeRedeemAchenrollment({ jsonApi: true }));
    dispatch(
      ((response) => ({
        type: constants.DIRECT_DEPOSIT_REDUCER_KEY,
        payload: response.enrollmentStatus,
      }))(response)
    );
    dispatch(((response) => ({ type: constants.ENROLLED_USER_KEY, payload: response.vendor.data.attributes }))(response));
  } catch (error) {
    console.log('Alcon.getMagnifeyeRedeemAchenrollment failed ', error);
  }
};

export const getAchSearch = () => async (dispatch, getState) => {
  try {
    const response = await dispatch(alconApiActions.getMagnifeyeRedeemAchenrollmentSearch({ jsonApi: true }));
    dispatch(
      ((response) => ({
        type: constants.ACH_SEARCH_INFORMATION,
        payload: response.data.map((i: any) => i.attributes),
      }))(response)
    );
  } catch (error) {
    console.log('Alcon.getMagnifeyeRedeemAchenrollmentSearch failed ', error);
  }
};

export const achSendInvite = (networkId: string) => async (dispatch, getState) => {
  try {
    dispatch(alconApiActions.createMagnifeyeRedeemAchenrollmentSendinvite({ networkId }, { jsonApi: true }));
  } catch (error) {
    console.log('Alcon.createMagnifeyeRedeemAchenrollmentSendinvite failed ', error);
  }
};

export const achResendInvite = () => async (dispatch, getState) => {
  try {
    dispatch(alconApiActions.createMagnifeyeRedeemAchenrollmentResendinvite({ jsonApi: true }));
  } catch (error) {
    console.log('Alcon.createMagnifeyeRedeemAchenrollmentResendinvite failed ', error);
  }
};
// #endregion

// #region Dashboard
export const getSummary = () => async (dispatch, getState) => {
  const endpoint = 'getMagnifeyeSummary';
  const request = getRequest(getState(), endpoint);
  if (!request.loading) {
    dispatch(destroyRequest(endpoint));
  }
  return dispatch(alconApiActions[endpoint]({ jsonApi: true }));
};

export const getMagnifeyeV2Summary = () => async (dispatch, getState) => {
  const endpoint = 'getMagnifeyeV2Summary';
  const request = getRequest(getState(), endpoint);
  if (!request.loading) {
    dispatch(destroyRequest(endpoint));
  }
  return dispatch(alconApiActions[endpoint]({ jsonApi: true }));
};
// #endregion

// #region Organization
export const getOrganization = () => async (dispatch, getState) => {
  const endpoint = 'getMagnifeyeOrganization';
  const request = getRequest(getState(), endpoint);
  if (!request.loading) {
    dispatch(destroyRequest(endpoint));
  }
  return dispatch(alconApiActions[endpoint]({ jsonApi: true }));
};
// #endregion

// #region Participant
export const getParticipants = () => async (dispatch, getState) => {
  try {
    dispatch(destroyRequest('getMagnifeyeParticipants'));
    const response = await dispatch(alconApiActions.getMagnifeyeParticipants({ jsonApi: true }));

    return response;
  } catch (error) {
    console.log('getMagnifeyeParticipants Error: ', error);
  }
};
(getParticipants as any).endpoint = 'getMagnifeyeParticipants';

export const createParticipantsSave = (body) => async (dispatch, getState) => {
  try {
    dispatch(destroyRequest('createMagnifeyeParticipantsSave'));
    const response = await dispatch(alconApiActions.createMagnifeyeParticipantsSave({ body } as any, { jsonApi: true }));
    return response;
  } catch (error) {
    console.log('Alcon.createMagnifeyeParticipantsSave failed', error);
  }
};
// #endregion

// #region Permission
export const getPermissions = () => async (dispatch, getState) => {
  const endpoint = 'getMagnifeyePermission';
  const request = getRequest(getState(), endpoint);
  if (!request.loading) {
    dispatch(destroyRequest(endpoint));
  }
  return dispatch(alconApiActions[endpoint]({ jsonApi: true }));
};
// #endregion

// #region Product
export const getProductSummary = () => async (dispatch, getState) => {
  const endpoint = 'getMagnifeyeProduct';
  const request = getRequest(getState(), endpoint);
  if (!request.loading) {
    dispatch(destroyRequest(endpoint));
  }
  return dispatch(alconApiActions[endpoint]({ jsonApi: true }));
};

export const updateProductPopulateBySource = (source: string, target?: number, data?: any) => async (dispatch, getState) => {
  try {
    dispatch(destroyRequest('updateMagnifeyeProductPopulateBySource'));
    const response = await dispatch(alconApiActions.updateMagnifeyeProductPopulateBySource({ source, target, data }));
    return response;
  } catch (error) {
    console.log('Alcon.updateMagnifeyeProductPopulateBySource failed', error);
  }
};

export const createProductCalculate = (body: ConsumerProductsUpdate) => async (dispatch, getState) => {
  try {
    dispatch(destroyRequest('createMagnifeyeProductCalculate'));
    const response = await dispatch(alconApiActions.createMagnifeyeProductCalculate({ body }, { jsonApi: true }));
    return response;
  } catch (error) {
    console.log('Alcon.createMagnifeyeProductCalculate failed', error);
  }
};

export const createProductSave = (body: ConsumerProductsUpdate) => async (dispatch, getState) => {
  try {
    dispatch(destroyRequest('createMagnifeyeProductSave'));
    const response = await dispatch(alconApiActions.createMagnifeyeProductSave({ body }, { jsonApi: true }));
    return response;
  } catch (error) {
    console.log('Alcon.createMagnifeyeProductSave failed', error);
  }
};

export const createMagnifeyeProductV2Save = (body: PatientsUpdate) => async (dispatch, getState) => {
  const endpoint = 'createMagnifeyeProductV2Save';
  const request = getRequest(getState(), endpoint);
  if (!request.loading) {
    dispatch(destroyRequest(endpoint));
  }
  const response = await dispatch(alconApiActions[endpoint]({ body }, { jsonApi: true }));
  return response;
};

export const createMagnifeyeProductClearCustomValues = (data?: any) => async (dispatch, getState) => {
  const endpoint = 'createMagnifeyeProductClearCustomValues';
  const request = getRequest(getState(), endpoint);
  if (!request.loading) {
    dispatch(destroyRequest(endpoint));
  }
  const response = await dispatch(alconApiActions[endpoint](data, { jsonApi: true }));
  return response;
};

export const createProductForecast = (body: ForecastRequestMailData) => async (dispatch, getState) => {
  try {
    dispatch(destroyRequest('createMagnifeyeForecast'));
    const response = await dispatch(alconApiActions.createMagnifeyeForecast({ body }, { jsonApi: true }));
    return response;
  } catch (error) {
    console.log('createMagnifeyeForecast call finished with an error:', error);
    return null;
  }
};
// #endregion

// #region Program
export const getProgram = () => async (dispatch, getState) => {
  const endpoint = 'getMagnifeyeProgram';
  const request = getRequest(getState(), endpoint);
  if (!request.loading) {
    dispatch(destroyRequest(endpoint));
  }
  return dispatch(alconApiActions[endpoint]({ jsonApi: true }));
};
// #endregion

// #region Rebate
export const getRebate = () => async (dispatch, getState) => {
  const endpoint = 'getMagnifeyeRebate';
  const request = getRequest(getState(), endpoint);
  if (!request.loading) {
    dispatch(destroyRequest(endpoint));
  }
  return dispatch(alconApiActions[endpoint]({ jsonApi: true }));
};
// #endregion

// #region Redeem
export const getRedeemHistory = () => async (dispatch, getState) => {
  const endpoint = 'getMagnifeyeRedeem';
  const request = getRequest(getState(), endpoint);
  if (!request.loading) {
    dispatch(destroyRequest(endpoint));
  }
  return dispatch(alconApiActions[endpoint]({}, { jsonApi: true }));
};

export const getRedeemOptions = () => async (dispatch, getState) => {
  const endpoint = 'getMagnifeyeAccount';
  const request = getRequest(getState(), endpoint);
  if (!request.loading) {
    dispatch(destroyRequest(endpoint));
  }
  return dispatch(alconApiActions.getMagnifeyeRedeemOptions({ jsonApi: true }));
};

export const fetchRedeemOptions = () => async (dispatch, getState) => {
  try {
    dispatch(destroyRequest('getMagnifeyeRedeemOptions'));
    return dispatch(alconApiActions.getMagnifeyeRedeemOptions({ jsonApi: true }));
  } catch (error) {
    console.log('Alcon.getMagnifeyeRedeemOptions failed ', error);
  }
};

export const fetchRedeemCheckOptionsById = (paramid: any) => async (dispatch, getState) => {
  try {
    console.log('my actions reddem options by id:', paramid);
    const id = 'check';
    dispatch(destroyRequest('getMagnifeyeRedeemOptionsById'));
    const response = await dispatch(
      alconApiActions.getMagnifeyeRedeemOptionsById({ id } as any, {
        jsonApi: true,
      })
    );
    return response;
  } catch (error) {
    console.log('Alcon.getMagnifeyeRedeemOptionsById failed', error);
  }
};

export const fetchRedeemProductOptionsById = (id: any) => async (dispatch, getState) => {
  try {
    const id = 'product';
    dispatch(destroyRequest('getMagnifeyeRedeemOptionsById'));
    const response = await dispatch(
      alconApiActions.getMagnifeyeRedeemOptionsById({ id } as any, {
        jsonApi: true,
      })
    );
    return response;
  } catch (error) {
    console.log('Alcon.getMagnifeyeRedeemOptionsById failed', error);
  }
};

export const fetchRedeemOption = (id: any) => async (dispatch, getState) => {
  try {
    dispatch(destroyRequest('getMagnifeyeRedeemOptionsById'));

    const response = await dispatch(
      alconApiActions.getMagnifeyeRedeemOptionsById({ id } as any, {
        jsonApi: true,
      })
    );
    return response;
  } catch (error) {
    console.log('Alcon.getMagnifeyeRedeemOptionsById failed', error);
  }
};

export const saveMagnifeyeRedeem = (body) => async (dispatch, getState) => {
  try {
    dispatch(destroyRequest('createMagnifeyeRedeem'));
    const response = await dispatch(alconApiActions.createMagnifeyeRedeem({ body } as any, { jsonApi: true }));
    return response;
  } catch (error) {
    console.log('Alcon.createMagnifeyeRedeem failed', error);
  }
};
// #endregion

// #region ShipToLocation
export const getShipTolocation = () => async (dispatch, getState) => {
  const endpoint = 'getMagnifeyeShiptolocation';
  const request = getRequest(getState(), endpoint);
  if (!request.loading) {
    dispatch(destroyRequest(endpoint));
  }
  return dispatch(alconApiActions[endpoint]({ jsonApi: true }));
};
// #endregion

// #region Goal
export const createMagnifeyeGoalCalculate = (body: CalculateByPatientsRequest) => async (dispatch, getState) => {
  // const endpoint = 'createMagnifeyeGoalCalculate';
  // const request = getRequest(getState(), endpoint);
  // if (!request.loading) {
  //   dispatch(destroyRequest(endpoint));
  // }
  // const response = await dispatch(alconApiActions[endpoint]({ body }, { jsonApi: true }));
  const response = await alcon.createMagnifeyeGoalCalculate({ body });
  return response;
};

export const createMagnifeyeGoalSave = (body: CalculateByPatientsRequest) => async (dispatch, getState) => {
  const endpoint = 'createMagnifeyeGoalSave';
  const request = getRequest(getState(), endpoint);
  if (!request.loading) {
    dispatch(destroyRequest(endpoint));
  }
  const response = await dispatch(alconApiActions[endpoint]({ body }, { jsonApi: true }));
  return response;
};
// #endregion

// #region Alcon Patient
export const updatePatients = (patients: Patient[]) => async (dispatch, getState) => {
  dispatch({
    type: constants.PATIENTS_REDUCER_KEY,
    payload: patients,
  });
};

export const updatePatient = (patient: Patient) => async (dispatch, getState) => {
  dispatch({
    type: constants.PATIENT_REDUCER_KEY,
    payload: patient,
  });
};

export const updateKeystonePatient = (patient: number) => async (dispatch, getState) => {
  dispatch({
    type: constants.KEYSTONE_PATIENT_REDUCER_KEY,
    payload: patient,
  });
};
// #endregion
