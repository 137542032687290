import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { HeaderDecoration, HeaderOne, Image, Link } from "rsv8-components";
import { LayoutContainer } from "../../../components/Layout";

const ExpiredToken = ({ loginHelpLinkPath }) => (
  <div>
    <HeaderDecoration />
    <Col xs={22} xsOffset={1} lg={22} lgOffset={1} md={22} mdOffset={1}>
      <Image themeVariation="primary-company-logo1" />
    </Col>
    <LayoutContainer>
      <Row>
        <Col xs={22} xsOffset={1} lg={12} lgOffset={6} md={12} mdOffset={6}>
          <HeaderOne textalign="center">Your password request has expired</HeaderOne>
        </Col>
      </Row>
      <Row>
        <Link href={loginHelpLinkPath} themeVariation="centered-link">
          Back to the login page {">"}
        </Link>
      </Row>
    </LayoutContainer>
  </div>
);

export default ExpiredToken;
