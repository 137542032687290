import * as React from 'react';
import { connect } from 'react-redux';
import { Panel } from 'rsv8-components';
import { customFieldSelectors } from 'rsv8-customfields';
import { register } from 'xcel-react-core';
import PointCost from '../../../components/PointCost/index';
import { teamNominationSelectors } from '../../../redux/index';
import DetailPanelDetails from './DetailPanelDetails';
import DetailPanelHead from './DetailPanelHead';
export type HandleNominee = (v: string) => void;
export type HandleValueChange = (v: string, id: string | string[]) => void;
export type HandleCustomFieldChange = (v: object, id: string) => void;

export interface DetailPanelProps {
  teamMemberId: string;
  awardId: string;
  handleSelect: HandleValueChange;
  handleDelete: HandleNominee;
  handleChangeRole: HandleValueChange;
  handleChangeAward: HandleValueChange;
  handleChangeCustomField: HandleCustomFieldChange;
  teamMemberCustomFields: Record<string, any>;
  teamMemberAward: any;
  teamMemberRole: any;
  hasAwardOptions: boolean;
  hasRoleOptions: boolean;
  awardOptions: Array<{ value: any; label: any }>;
  roleOptions: Array<{ id: any; name: string; description: string }>;
  selected: boolean;
  loading: boolean;
  customFieldFormType?: string;
  customFieldGroupKey?: string;
}

interface Props extends DetailPanelProps {
  awardDetails: any;
  nominee: any;
  awardLabel: any;
  roleLabel: any;
  displayCustomFields: boolean;
  customFieldFormType: string;
  customFieldGroupKey: string;
  teamMemberCustomFieldsInitialValues: any;
  openOnError: boolean;
  currentNomination: any;
}

class DetailPanel extends React.Component<Props, { open: boolean }> {
  constructor(props: Props) {
    super(props);
    this.state = { open: false };
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.openOnError !== this.props.openOnError && this.props.openOnError && !this.state.open) {
      this.toggleOpen();
    }
  }

  deleteNominee = () => this.props.handleDelete(this.props.teamMemberId);

  changeAward = (val) => {
    this.props.handleChangeAward(val, this.props.teamMemberId);
  };

  changeRole = (val) => {
    this.props.handleChangeRole(val, this.props.teamMemberId);
  };

  changeCustomField = (values) => {
    const fields = values[this.props.customFieldFormType] || {};
    this.props.handleChangeCustomField(fields, this.props.teamMemberId);
  };

  selectNominee = (val) => this.props.handleSelect(val, this.props.teamMemberId);

  toggleOpen = () => this.setState({ open: !this.state.open });

  getRoleOptions = () => {
    return this.props.roleOptions.map((role) => ({ value: role.id, label: role.name }));
  };
  render() {
    return (
      <Panel expanded={this.state.open} themeVariation="card">
        <DetailPanelHead
          teamMemberId={this.props.teamMemberId}
          handleEdit={this.toggleOpen}
          displayEdit={this.props.displayCustomFields && this.props.awardDetails.processType !== 'Grouped'}
          changeAward={this.changeAward}
          changeRole={this.changeRole}
          selectNominee={this.selectNominee}
          selected={this.props.selected}
          teamMemberAward={this.props.teamMemberAward}
          teamMemberRole={this.props.teamMemberRole}
          hasAwardOptions={this.props.hasAwardOptions}
          hasRoleOptions={this.props.hasRoleOptions}
          awardOptions={this.props.awardOptions}
          roleOptions={this.getRoleOptions()}
          deleteNominee={this.deleteNominee}
        />
        {this.props.displayCustomFields &&
        this.props.awardDetails &&
        this.props.awardDetails.processType !== 'Grouped' ? (
          <DetailPanelDetails
            customFieldFormType={this.props.customFieldFormType}
            customFieldGroupKey={this.props.customFieldGroupKey}
            onChange={this.changeCustomField}
            awardId={this.props.awardId}
            initialValues={this.props.teamMemberCustomFieldsInitialValues}
            teamMemberId={this.props.teamMemberId}
          />
        ) : null}
        <PointCost
          id={this.props.awardId}
          pointValue={this.props.currentNomination.points}
          nomineeId={this.props.teamMemberId}
        />
      </Panel>
    );
  }
}

const mapState = (state, ownProps: DetailPanelProps) => {
  const formType = ownProps.customFieldFormType || 'NominationForm';
  const groupKey = ownProps.customFieldGroupKey || 'details';
  return {
    awardDetails: teamNominationSelectors.getCurrentAward(state),
    displayCustomFields: customFieldSelectors.getFieldsWereFound(state, formType, ownProps.awardId, groupKey),
    customFieldFormType: formType,
    customFieldGroupKey: groupKey,
    teamMemberCustomFieldsInitialValues: { [formType]: ownProps.teamMemberCustomFields },
    currentNomination: teamNominationSelectors.getCurrentNomination(state)
  };
};

export default register('rsv8-nomination/TeamMemberDetailPanel')(connect(mapState))(DetailPanel) as React.ComponentType<
  DetailPanelProps
>;
