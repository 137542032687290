import * as React from 'react';
// @ts-ignore
/* tslint:disable */

// TODO: Replace hardcoded fill.
export const BullseyeSvg = (props) => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
    width="30"
    height="30"
  >
    <g>
      <path
        fill="#fbd476"
        d="M426.737,168.939c-3.912,2.054-5.418,6.89-3.364,10.802C439.484,210.427,448,245.096,448,280
		c0,57.695-22.468,111.938-63.265,152.735C343.938,473.533,289.696,496,232,496s-111.938-22.468-152.735-63.265
		C38.468,391.939,16,337.696,16,280s22.468-111.938,63.265-152.735C120.062,86.468,174.304,64,232,64
		c34.916,0,69.595,8.521,100.288,24.642c3.911,2.057,8.748,0.55,10.802-3.362c2.054-3.911,0.549-8.748-3.363-10.802
		C306.749,57.156,269.498,48,232,48c-61.97,0-120.23,24.132-164.049,67.951S0,218.031,0,280S24.132,400.23,67.951,444.049
		S170.03,512,232,512s120.23-24.132,164.049-67.951S464,341.97,464,280c0-37.485-9.15-74.727-26.461-107.697
		C435.485,168.391,430.649,166.885,426.737,168.939z"
      />
      <path
        fill="#fbd476"
        d="M334.708,136.837c2.413-3.701,1.37-8.658-2.332-11.071C302.513,106.293,267.804,96,232,96C130.542,96,48,178.542,48,280
		s82.542,184,184,184s184-82.542,184-184c0-35.79-10.286-70.488-29.745-100.344c-2.413-3.701-7.369-4.748-11.07-2.334
		c-3.702,2.412-4.747,7.369-2.334,11.07C390.612,215.644,400,247.321,400,280c0,92.636-75.364,168-168,168S64,372.636,64,280
		s75.364-168,168-168c32.692,0,64.38,9.395,91.637,27.168C327.337,141.581,332.294,140.537,334.708,136.837z"
      />
      <path
        fill="#fbd476"
        d="M306.644,175.699c2.346-3.744,1.212-8.681-2.532-11.026C282.525,151.149,257.589,144,232,144c-74.991,0-136,61.01-136,136
		s61.009,136,136,136s136-61.01,136-136c0-25.589-7.147-50.523-20.67-72.108c-2.346-3.744-7.283-4.879-11.026-2.532
		c-3.744,2.346-4.878,7.282-2.532,11.026C345.696,235.422,352,257.419,352,280c0,66.168-53.832,120-120,120s-120-53.832-120-120
		s53.832-120,120-120c22.582,0,44.58,6.304,63.617,18.23C299.362,180.577,304.298,179.443,306.644,175.699z"
      />
      <path
        fill="#fbd476"
        d="M274.128,211.508c1.932-3.974,0.277-8.761-3.697-10.692C258.4,194.966,245.47,192,232,192c-48.523,0-88,39.477-88,88
		s39.477,88,88,88s88-39.477,88-88c0-13.457-2.961-26.377-8.8-38.401c-1.931-3.975-6.717-5.634-10.691-3.701
		c-3.974,1.931-5.631,6.717-3.701,10.691C301.58,258.416,304,268.985,304,280c0,39.701-32.299,72-72,72s-72-32.299-72-72
		s32.299-72,72-72c11.026,0,21.603,2.424,31.435,7.204C267.407,217.136,272.195,215.481,274.128,211.508z"
      />
      <path
        fill="#fbd476"
        d="M226.975,256.526c4.321-0.92,7.08-5.168,6.16-9.489c-0.919-4.32-5.165-7.077-9.489-6.161
		C205.309,244.776,192,261.231,192,280c0,22.056,17.944,40,40,40c18.919,0,35.4-13.436,39.188-31.947
		c0.886-4.328-1.905-8.556-6.233-9.441c-4.331-0.881-8.556,1.905-9.441,6.234C253.241,295.945,243.353,304,232,304
		c-13.233,0-24-10.767-24-24C208,268.737,215.98,258.865,226.975,256.526z"
      />
      <path
        fill="#fbd476"
        d="M511.391,52.939C510.153,49.95,507.236,48,504,48h-40V8c0-3.235-1.949-6.152-4.938-7.391
		c-2.989-1.239-6.431-0.554-8.718,1.733l-100,100c-1.5,1.501-2.343,3.535-2.343,5.657v44.686L226.343,274.343
		c-3.125,3.125-3.125,8.189,0,11.314c1.562,1.562,3.609,2.343,5.657,2.343s4.095-0.781,5.657-2.343L359.314,164H404
		c2.122,0,4.157-0.843,5.657-2.343l100-100C511.945,59.369,512.629,55.928,511.391,52.939z M400.687,148h-25.373l62.343-62.343
		c3.125-3.125,3.125-8.189,0-11.314c-3.124-3.123-8.189-3.123-11.313,0L364,136.686v-25.373l84-84V56c0,4.418,3.582,8,8,8h28.687
		L400.687,148z"
      />
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);
