import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { HeaderThree } from 'rsv8-components';
import styled from 'styled-components';
import { Amount } from 'xcel-api-generator/dist/alcon';
import { register, withContent, withResource, withTheme } from 'xcel-react-core';
import { withApiDataProvider } from 'xcel-redux-orm';
import { getCurrencyValue, getValueOrNull } from '../../../utils';
import { getMagnifeyeV2Summary } from '../../redux/actions';
import * as selectors from '../../redux/selectors';
import NoDataSpinner from '../NoDataSpinner';
import { OverlayPopover } from '../Overlay';
import { ContentRow, HeaderRow, StyledNames, StyledNote } from './styles';

const AlignedCol = styled(Col)<{ align?: string }>`
  text-align: ${(props: any) => (props.align ? props.align : 'center')};

  @media (max-width: 767px) {
    text-align: left;
  }
`;

export const CurrentValue = styled.div<{ fontSize?: number; lineHeight?: number; color?: string }>`
  padding: 10px 0;
  font-weight: bold;
  font-size: ${(props: any) => (props.fontSize ? props.fontSize + 'px' : '20px')};
  line-height: ${(props: any) => (props.lineHeight ? props.lineHeight + 'px' : '24px')};
  color: ${(props: any) => (props.color ? props.color : '#505d6f')};
  margin-bottom: 10px;
`;

const CurrentProgress: React.FC<Props> = ({
  className,

  resource: {
    totalValueColor = '#003595',
    marginColor = '#2C9D9D',
    rebateColor = '#00AEEF',
    costSavingsColor = '#AE6CD8',

    totalValueLabel,
    totalValueNote,
    marginLabel,
    marginNote,
    rebateLabel,
    rebateNote,
    priceDiscountsLabel,
    costSavingsNote,
  },

  title,
  infoIconText,

  current,
}) => {
  if (!current) {
    return <NoDataSpinner />;
  }

  return (
    <div className={className}>
      <HeaderRow>
        <AlignedCol xs={24}>
          <HeaderThree themeVariation="my-dashboard">
            {title + ' '}
            <OverlayPopover id="popover-current-progress" placement="bottom">
              {infoIconText}
            </OverlayPopover>
          </HeaderThree>
        </AlignedCol>
      </HeaderRow>

      <ContentRow className="content-row">
        <AlignedCol xs={16} xsOffset={4} sm={18} smOffset={3}>
          <Row>
            <AlignedCol align="left" xs={24} sm={12}>
              <StyledNames themeVariation="my-dashboard">{totalValueLabel}</StyledNames>
              <StyledNote dangerouslySetInnerHTML={{ __html: totalValueNote }} />
            </AlignedCol>
            <AlignedCol align="right" xs={24} sm={12}>
              <CurrentValue color={totalValueColor} fontSize={26} lineHeight={26}>
                {getCurrencyValue(getValueOrNull(current.totalValue)) || '-'}
              </CurrentValue>
            </AlignedCol>
          </Row>

          <Row>
            <AlignedCol align="left" xs={24} sm={12}>
              <StyledNames themeVariation="my-dashboard">{marginLabel}</StyledNames>
              <StyledNote dangerouslySetInnerHTML={{ __html: marginNote }} />
            </AlignedCol>
            <AlignedCol align="right" xs={24} sm={12}>
              <CurrentValue color={marginColor}>{getCurrencyValue(getValueOrNull(current.margin)) || '-'}</CurrentValue>
            </AlignedCol>
          </Row>

          <Row>
            <AlignedCol align="left" xs={24} sm={12}>
              <StyledNames themeVariation="my-dashboard">{rebateLabel}</StyledNames>
              <StyledNote dangerouslySetInnerHTML={{ __html: rebateNote }} />
            </AlignedCol>
            <AlignedCol align="right" xs={24} sm={12}>
              <CurrentValue color={rebateColor}>{getCurrencyValue(getValueOrNull(current.rebate)) || '-'}</CurrentValue>
            </AlignedCol>
          </Row>

          <Row>
            <AlignedCol align="left" xs={24} sm={12}>
              <StyledNames themeVariation="my-dashboard">{priceDiscountsLabel}</StyledNames>
              <StyledNote dangerouslySetInnerHTML={{ __html: costSavingsNote }} />
            </AlignedCol>
            <AlignedCol align="right" xs={24} sm={12}>
              <CurrentValue color={costSavingsColor}>{getCurrencyValue(getValueOrNull(current.discount)) || '-'}</CurrentValue>
            </AlignedCol>
          </Row>
        </AlignedCol>
      </ContentRow>
    </div>
  );
};

const mapContentToProps = (getContent) => ({
  title: getContent('title', { type: 'string', label: 'Title' }),
  infoIconText: getContent('infoIconText', { type: 'string', label: 'Info Icon Text' }),
});

const mapResourceToProps = (getResource) => ({
  resource: {
    totalValueColor: getResource('alcon.dashboard.currentProgress.totalValueColor', '#003595'),
    marginColor: getResource('alcon.dashboard.currentProgress.marginColor', '#2C9D9D'),
    rebateColor: getResource('alcon.dashboard.currentProgress.rebateColor', '#00AEEF'),
    costSavingsColor: getResource('alcon.dashboard.currentProgress.costSavingsColor', '#AE6CD8'),

    totalValueLabel: getResource('alcon.dashboard.currentProgress.totalValueLabel', 'Current Total Value'),
    totalValueNote: getResource(
      'alcon.dashboard.currentProgress.totalValueNote',
      '(Sum of your <b>current</b> margin, rebate, and price discounts)'
    ),
    marginLabel: getResource('alcon.dashboard.currentProgress.marginLabel', 'Margin'),
    marginNote: getResource('alcon.dashboard.currentProgress.marginNote', '(Current Anticipated Margin is MSRP - List Price)'),
    rebateLabel: getResource('alcon.dashboard.currentProgress.rebateLabel', 'Rebate'),
    rebateNote: getResource('alcon.dashboard.currentProgress.rebateNote', '(Current Practice Rebate)'),
    priceDiscountsLabel: getResource('alcon.dashboard.createGoals.priceDiscountsLabel', 'Price Discounts'),
    costSavingsNote: getResource('alcon.dashboard.currentProgress.costSavingsNote', '(Current Product Price Discounts)'),
  },
});

export const mapStateToProps = (state) => ({
  current: selectors.performanceAmountSelector(state, 'Current'),
});

interface StateProps {
  current: Amount;
}
interface ContentProps {
  title: string;
  infoIconText: string;
}
interface ResourceProps {
  resource: {
    totalValueColor: string;
    marginColor: string;
    rebateColor: string;
    costSavingsColor: string;

    totalValueLabel: string;
    totalValueNote: string;
    marginLabel: string;
    marginNote: string;
    rebateLabel: string;
    rebateNote: string;
    priceDiscountsLabel: string;
    costSavingsNote: string;
  };
}
type Props = StateProps & ContentProps & ResourceProps & React.HtmlHTMLAttributes<any>;

export default register('rsv8-alcon/CurrentProgress')(
  withApiDataProvider(getMagnifeyeV2Summary, selectors.performanceSelector),
  connect<StateProps>(mapStateToProps),
  withContent(mapContentToProps),
  withResource(mapResourceToProps),
  withTheme()
)(CurrentProgress);
