import * as React from 'react';
import { connect } from 'react-redux';
import { register, withTheme } from 'xcel-react-core';
import { withApiDataProvider } from 'xcel-redux-orm';
import {
  pointsSelector
} from "../../redux/selectors/";
import { useState, useEffect } from 'react';
import { UpdatePendo, getClientId } from "rsv8-auth";
import { getConfigurationProperty } from 'xcel-config';
import { alconActions } from '../../redux/actions/alconActions';
// const isReady = (request) => request && !request.loading && request.exists;

// const calculatePointTotal = ({ userPoints }) => {
//   console.log(userPoints)
//   const cartPoints = userPoints && userPoints.available && userPoints.available.total/100;
//   if (!cartPoints) {
//     return 0;
//   }
//   return (cartPoints).toFixed(2);
// };
const getPoints: any = (args) => {
  return alconActions.getMagnifeyeAccountPointsummary({ jsonApi: true });
};
getPoints.endpoint = 'getMagnifeyeAccountPointsummary';
export const UserPoints: React.SFC<any> = ({Points, dispatch, clientId, ...rest }) => {
  const [loading, setLoading]= useState(true);
  useEffect(() => {
    if (!Points) {
      return;
    }

    setLoading(false);
  }, [Points]);

  if(!loading && Points) {
    const pendoEnabled = getConfigurationProperty(['clients', clientId, 'enablePendo'])
    if (pendoEnabled) {
      UpdatePendo('user_Points', `${(Points.available/100).toFixed(2)}`)
    }
  
    return(
      <span {...rest}>$ {(Points.available/100).toFixed(2)}</span>
    )
  } else {
    return null
  }
}
const mapStateToProps = state => ({
  Points: pointsSelector.selectMany(state)[0],
  clientId: getClientId(state)
});

export default register('rsv8-account/UserPoints')(
  connect(mapStateToProps),
  withApiDataProvider(getPoints, pointsSelector),  
   withTheme())
   (UserPoints);
