import { emptyArray, getValue } from 'xcel-util';
export const THEME_STATE = 'rsv8-theme/theme';

export const getTheme = (state) => state[THEME_STATE];
export const getPseudoSelector = (state) => {
  let theme = getTheme(state);
  return theme.pseudoSelector;
};
export const getMediaQuery = (state) => {
  let theme = getTheme(state);
  return theme.mediaQuery;
};
export const getStyleSelectors = (state, component) => {
  let selectors = getValue(state, `rsv8-cms-admin.content.Theme.edit.content.theme.${component}.selectors`);
  if (typeof selectors !== 'undefined') {
    return selectors;
  } else {
    return emptyArray;
  }
};
