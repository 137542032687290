import * as events from '../../events';
import { teamNominationSelectors } from '../../selectors/index';
import { nominationActivityActions } from '../nominationActivity';
import { clearTeamNomination } from './clearCurrent';

export const selectProductBase = (appKey) => {
  return {
    type: events.SELECT_TEAM_PRODUCT,
    payload: appKey
  };
};

// export const setIndividualNominationProductId = (value) => ({
//   type: events.SET_INDIVIDUAL_NOMINATION_PRODUCT,
//   payload: value
// });

const selectProduct = (value, confirmText = 'Changing product now will clear later steps.') => (dispatch, getState) => {
  const state = getState();
  if (teamNominationSelectors.getCurrentProductId(state) !== value) {
    const clearNominationData = teamNominationSelectors.getClearCurrentNominationData(getState());
    if (clearNominationData) {
      dispatch(selectProductBase(value));
    } else if (teamNominationSelectors.hasCustomFieldsOrNominees(state)) {
      if (confirm(confirmText)) {
        dispatch(clearTeamNomination());
        const currentNominationId = teamNominationSelectors.getCurrentNominationId(state);
        if (currentNominationId !== null) {
          dispatch(nominationActivityActions.destroyNomination(currentNominationId.toString()));
        }
        dispatch(selectProductBase(value));
      }
    } else {
      dispatch(selectProductBase(value));
    }
  }
};

export default selectProduct;
