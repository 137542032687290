import * as React from 'react';
import { connect } from 'react-redux';
import { withProps } from 'recompose';
import { compose } from 'redux';
import { CaptionImage, Thumbnail } from 'rsv8-components';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';
import { AwardImage } from '../../components/index';
import { teamNominationSelectors } from '../../redux/selectors/index';

const DisplayContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
` as any;

const OffsetContainer = styled.div`
  margin-top: 30px;
` as any;
const ImageWidth = '80px';

const NoNomineeImage = (props) => <Thumbnail width={ImageWidth} src="/clientassets/images/individual.svg" />;

const NominationDisplay = ({ award, selectAwardText, currentStep, showNominationIcon }: any) => {
  return (
    <DisplayContainer>
      {award && showNominationIcon === 'true' ? (
              <CaptionImage component={AwardImage} width={ImageWidth} object={award}>
                {award.name}
              </CaptionImage>
          ) : (!currentStep || currentStep.title !== 'Product' ? <OffsetContainer/> :
          <CaptionImage component={NoNomineeImage} width={ImageWidth}>
            {selectAwardText}
          </CaptionImage>)
      }
    </DisplayContainer>
  );
};

const mapProps = (props) => ({ currentStep: props.currentStep });

const mapState = (state) => {
  const award = teamNominationSelectors.getCurrentAward(state);
  return {
    award: award !== null ? award : false
  };
};

const mapResource = (getResource) => ({
  selectAwardText: getResource('nominationProcess.selectAwardText', 'Select an Award'),
  showNominationIcon: getResource('nominations.showNominationIcon', 'true')
});

export default compose<any>(
  withProps(mapProps),
  connect(
    mapState,
    null
  ),
  withResource(mapResource)
)(NominationDisplay);
