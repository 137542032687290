import * as React from 'react';
import { ActivityAction } from '../../../../api/delete-user-action';
import { AvailableUserAction } from '../../../../api/get-shareables-activities';
import { saveFile } from '../../../../utils';
import SocialShareableButton from '../../../SocialShareableButton';
import { LikeAction } from './LikeAction';
import { Container, LikeActionWrapper } from './styles';

interface ActionPanelProps {
  postPath: string;
  imageSrc: string;
  userActions: any[];
  actionInProcess: boolean;
  cultureId: number;
  availableUserActions: AvailableUserAction[];

  unlikeActivity: (path: string, params?: any) => void;
  likeActivity: (path: string, params?: any) => void;
  shareActivity: (path: string, params?: any) => void;
  downloadActivity: (path: string, params?: any) => void;
}

export class ActionPanel extends React.PureComponent<ActionPanelProps> {
  render() {
    const shareAction = this.props.availableUserActions.find((action) => action.action === ActivityAction.Share);
    const saveAction = this.props.availableUserActions.find((action) => action.action === ActivityAction.Save);

    const shareLocalAction =
      shareAction && (shareAction.localAction || []).find((i) => i.cultureId === this.props.cultureId);
    const saveLocalAction =
      saveAction && (saveAction.localAction || []).find((i) => i.cultureId === this.props.cultureId);

    return (
      <Container>
        <LikeActionWrapper>
          <LikeAction
            postPath={this.props.postPath}
            actionInProcess={this.props.actionInProcess}
            userActions={this.props.userActions}
            cultureId={this.props.cultureId}
            availableUserActions={this.props.availableUserActions}
            unlikeActivity={this.props.unlikeActivity}
            likeActivity={this.props.likeActivity}
          />
        </LikeActionWrapper>
        <div>
          {!!shareAction && !!this.props.imageSrc && (
            <SocialShareableButton
              themeVariation="only-icons"
              shareURL={this.props.imageSrc}
              saveAction={{
                useOnlyDefaultIcon: true,
                defaultIcon: (saveLocalAction && saveLocalAction.iconDefault) || '',
                hoverIcon: (saveLocalAction && saveLocalAction.iconHover) || '',
                action: () => {
                  saveFile(this.props.imageSrc);
                  this.props.downloadActivity(this.props.postPath);
                },
                label: saveLocalAction && saveLocalAction.label
              }}
              shareAction={{
                useOnlyDefaultIcon: true,
                defaultIcon: (shareLocalAction && shareLocalAction.iconDefault) || '',
                hoverIcon: (shareLocalAction && shareLocalAction.iconHover) || '',
                action: () => {
                  this.props.shareActivity(this.props.postPath);
                },
                label: shareLocalAction && shareLocalAction.label
              }}
            />
          )}
        </div>
      </Container>
    );
  }
}
