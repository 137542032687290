import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Card, Link, Text } from 'rsv8-components';
import styled from 'styled-components';
import { register, withContent } from 'xcel-react-core';
import { Trophy, TrophySvg } from '../../components/Trophy';
const Container = styled.div`
  background: #0a5b89;
  padding: 30px;
  height: 415px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
// interface Props {}

const Heading = styled(Text)`
  font-size: 21px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.24;
  letter-spacing: normal;
  color: #cae3e9;
  text-align: left;
`;

const NominationActivity: React.FunctionComponent = (props) => {
  return (
    <Card>
      <Container>
        <Heading>Your Nomination Activity</Heading>
        <div style={{ alignSelf: 'center', display: 'flex' }}>
          <TrophySvg />
          <Trophy anchorId="trophySvg" />
        </div>
        <Link
          to="/nominations/activity"
          themeVariation="secondary-alternate-button"
          style={{ width: '80%', alignSelf: 'center', textAlign: 'center' }}
        >
          View Nomination History
        </Link>
      </Container>
    </Card>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({}, dispatch)
});

export default register('rsv8-data-vis/NominationActivity')(
  withContent(),
  connect<any, any>(
    mapStateToProps,
    mapDispatchToProps
  )
)(NominationActivity);
