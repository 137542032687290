import { nominationActions } from '../nomination';

const nominationApprovalActions = {
  getPending: () => async (dispatch) => {
    return await dispatch(nominationActions.getNominationsPendingapproval({}, { jsonApi: true }));
  },
  getHistory: () => async (dispatch) => {
    return await dispatch(nominationActions.getNominationsApprovalhistory({}, { jsonApi: true }));
  }
};

export { nominationApprovalActions };
