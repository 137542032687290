import {
  filter,
  map,
  pipe,
  values
  } from 'ramda';
import { getRequest } from 'xcel-redux-orm';
import { ensureArray } from 'xcel-util';
import {
  ADD_TEAM_MEMBER_ENDPOINT_NAME,
  CURRENT_NOMINATION_KEY,
  REMOVE_TEAM_MEMBER_ENDPOINT_NAME,
  TEAM_NOMINATION_REDUCER_KEY
  } from '../../constants';
import { ReduxState as State, Resources } from '../../types';
import {
  awardSelectors,
  getProductsWithName as getProductsBase,
  nomineeSelectors,
  productSelectors
  } from './nomination';
import { getPointRange } from './util';
import { teamNominationSelectors } from "./index";
// import { max } from 'moment';

// @ts-ignore
// @ts-ignore

export const getCurrentNomination = (state: State) => state[TEAM_NOMINATION_REDUCER_KEY][CURRENT_NOMINATION_KEY];

export const getCurrentAwardId = (state: State) => getCurrentNomination(state).award;

export const getCurrentProductId = (state: State) => getCurrentNomination(state).product;

export const getCurrentOptionId = (state: State): string => getCurrentNomination(state).optionId;

export const getCurrentOptionPointValue = (state: State): number => getCurrentNomination(state).optionPointValue;

export const getCurrentNominationId = (state: State) => getCurrentNomination(state).id;

export const getCurrentAward = (state: State) => awardSelectors.selectOne(state, getCurrentAwardId(state));

export const getCurrentProduct = (state: State) => productSelectors.selectOne(state, getCurrentProductId(state));
export const getAwards = (state: State) => {
  const awards = awardSelectors.searchMany(state, (a) => !!a.name && a.allowTeamNominations);
  return ensureArray(awards);
};

export const getProducts = getProductsBase;

export const getCurrentNominationPoints = (state: State): number => getCurrentNomination(state).points;

export const getCurrentCustomFieldArray = (state: State) => getCurrentNomination(state).customFields || [];

export const getCurrentCustomFields = (state: State): Array<{ id: string; values: any[] }> =>
  getCurrentNomination(state).customFields;

export const getCurrentTeamName = (state: State) => getCurrentNomination(state).teamName;

export const getCurrentCustomFieldMap = (state: State): object =>
  getCurrentCustomFieldArray(state).reduce((acc, val) => ({ ...acc, [val.id]: val.values }), {});

export const getCurrentFieldsByTeamFields = (state: State) => {
  const teamCurrentFields = getCurrentCustomFieldArray(state);

  return teamCurrentFields.reduce(function(result: any[], {  }: /* id, values */ any) {
    // const TEAM_KEY_FIELD = '_team';
    // if (id && id.indexOf(TEAM_KEY_FIELD) !== -1) {
    //   result.push({
    //     id: id.replace(TEAM_KEY_FIELD, ''),
    //     values
    //   });
    // }

    return result;
  }, []);
};

export const getCurrentNomineeIds = (state: State): string[] =>
  Object.keys(getCurrentNomination(state).teamMembers).sort();

export const hasCustomFieldsOrNominees = (state: State) =>
  getCurrentCustomFieldArray(state).length !== 0 || getCurrentNomineeIds(state).length !== 0;

export const getCurrentNominees = (state: State) => nomineeSelectors.selectMany(state, getCurrentNomineeIds(state));

export const getCurrentTeamMembers = (state: State) => getCurrentNomination(state).teamMembers;

export const getNomineeDetails = (state: State, id: string) => getCurrentTeamMembers(state)[id];

export const getNomineeAwardDetail = (state: State, id: string) => getNomineeDetails(state, id).awardValue;

export const getNomineeProductDetail = (state: State, id: string) => getNomineeDetails(state, id).productValue;

export const getNomineeRoleDetail = (state: State, id: string) => getNomineeDetails(state, id).roleId;

export const getNomineeCustomFields = (state: State, id: string) => getNomineeDetails(state, id).customFields;

export const getNomineeCustomFieldsMap = (state: State, id: string) =>
  getNomineeCustomFields(state, id).reduce((acc, val) => ({ ...acc, [val.id]: val.values }), {});

const calcLoading = pipe(
  values,
  filter((v: any) => v.loading) as any,
  map((v: any) => v.request.userIds)
);

export const getLoadingNominees = (state): Array<string> => {
  const addedNominees = calcLoading(getRequest(state, ADD_TEAM_MEMBER_ENDPOINT_NAME).requests);
  const removedNominees = calcLoading(getRequest(state, REMOVE_TEAM_MEMBER_ENDPOINT_NAME).requests);
  return addedNominees.concat(...removedNominees);
};

export const transformAwardToOptions = (award: Resources.Award): Array<{ value: any; label: string }> => {
  if (award) {
    const points = (award.points || []).map((p) => ({ value: p.pointValue, label: p.pointValue.toString() }));
    if (points.length > 0) return points;
    return getPointRange(award);
  }
  return null;
};

export const transformProductToOptions = (
  product: Resources.Product
): Array<{ value: any; label: string; disabled?: boolean; selected?: boolean; hidden?: boolean }> =>
  (product.points || []).map((p) => ({ value: p.pointValue, label: p.pointValue.toString() }));

export const getNomineeAwardOptions = (state) => {
  let points = transformAwardToOptions(getCurrentAward(state));
  return points;
  // let points = []; // transformAwardToOptions(getCurrentAward(state));
  // if (points.length === 0) {
  //   // check for point range
  //   const { minimum, maximum, increment } = {
  //     minimum: 100,
  //     maximum: 25000,
  //     increment: 100
  //   };
  //   return new Array(maximum / increment).fill(0).map((_, i) => {
  //     const value = i * increment;
  //     return { label: value.toString(), value };
  //   });
  // }
};

export const getNomineeProductOptions = (state) => {
  let points = transformProductToOptions(getCurrentProduct(state));
  return points;
};

export const getNomineeRoleOptions = (state: State) => {
  const award = getCurrentAward(state);
  if (!award) return [];
  return award.roles || [];
};

export const getAwardHasRoles = (state: State) => getNomineeRoleOptions(state).length > 0;
export const getProductHasRoles = (state: State) => getNomineeRoleOptions(state).length > 0;

export const getAwardHasPoints = (state: State) => {
  const options = getNomineeAwardOptions(state);
  return options && options.length > 0;
};

export const getProductHasPoints = (state: State) => {
  const options = getNomineeProductOptions(state);
  return options && options.length > 0;
};
export const getTotalNominationPoints = (state: State) => {
  return map((val) => Number(val.awardValue) || 0, values(getCurrentTeamMembers(state))).reduce((a, b) => a + b);
};

export const getCurrentIncentive = (state: State): any => {
  const incentivePayload = {
    pointValue: getCurrentOptionPointValue(state),
    product: {
      id: getCurrentProductId(state),
      optionId: getCurrentOptionId(state)
    }
  };
  return incentivePayload;
};

export const getProductInfoFromDraft = (state: State) => {
  const incentive = getCurrentNomination(state).incentive;
  if(incentive) {
    return {
      product: incentive.product,
      productId: incentive.product ? incentive.product.id : null,
      optionId: incentive.product && incentive.product ? incentive.product.optionId : null
    };
  }
  return null;
};

export const getSaveTeamNominationRequest = (state: State) => {
  let incentive = getCurrentIncentive(state);
  if (!incentive || !incentive.product || !incentive.product.id) {
    const info = getProductInfoFromDraft(state);
    let products = teamNominationSelectors.getProducts(state);
    products = !Array.isArray(products) ? [products] : products;
    const product = products.find(item => item.id === info.productId || item.name === info.productId);
    if (product) {
      incentive = {
        product: {
          id: product.id,
          optionId: info.optionId
        },
      }
    }
  }
  return {
    teamName: getCurrentTeamName(state),
    applicationKey: getCurrentAwardId(state),
    customFields: getCurrentCustomFieldArray(state),
    incentiveValue: getCurrentNominationPoints(state),
    incentive: incentive
  }
};

export const getDefaultTeamMemberRole = (state: State) => getCurrentNomination(state).defaultRoleId;

export const getDefaultTeamMemberAward = (state: State) => getCurrentNomination(state).defaultAwardValue;

export const getDefaultTeamMemberProduct = (state: State) => getCurrentNomination(state).defaultProductValue;

export const getClearCurrentNominationData = (state: State): boolean => getCurrentNomination(state).clearCurrentNominationData;
