import { orderApiActions } from './orderApiActions';

export type GetOrdersRequestType = {
  startDate?: string;
  endDate?: string;
  offset?: number;
  limit?: number;
  filter?: string;
  select?: string;
  sort?: string;
};

export const getOrders = (params: GetOrdersRequestType) => async (dispatch, getState) => {
  return dispatch(orderApiActions.getOrders(params, { jsonApi: true }));
};
(getOrders as any).endpoint = 'getOrder';
export default getOrders;
