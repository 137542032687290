import * as React from 'react';
import { Text } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import { Error } from './styles';

class PointRangeError extends React.Component<any, any> {
  render() {
    const { rangeErrors, errorHeading, invalidNumberError, invalidRangeError } = this.props;
    return (
      <Error bsStyle="danger">
        <Text themeVariation="secondary-text">{errorHeading}</Text>
        <ul>
          {rangeErrors.invalidNumberError && (
            <li>
              <Text themeVariation="secondary-text">{invalidNumberError}</Text>
            </li>
          )}
          {rangeErrors.invalidRangeError && (
            <li>
              <Text themeVariation="secondary-text">{invalidRangeError}</Text>
            </li>
          )}
        </ul>
      </Error>
    );
  }
}

const mapResourceToProps = (getResource) => ({
  errorHeading: getResource(
    'catalog.browseByPoints.errorHeading',
    'The following is required to browse by point value:'
  ),
  invalidNumberError: getResource(
    'catalog.browseByPoints.invalidNumberError',
    'Please enter a MIN and MAX value that is greater than zero.'
  ),
  invalidRangeError: getResource(
    'catalog.browseByPoints.invalidRangeError',
    'Please enter a MAX value that is greater than the MIN value to search by points.'
  )
});

export default withResource(mapResourceToProps)(PointRangeError);
