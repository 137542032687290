import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Context, withWizard } from 'rsv8-wizard';
import { register } from 'xcel-react-core';
import { NarrowGrid, TeamDetailInstructions } from '../../components/index';
import { teamNominationActions } from '../../redux/actions/index';
import { teamNominationSelectors } from '../../redux/selectors/index';
import DetailForm from './DetailForm';

export interface DetailPageProps {
  [index: string]: any;
}

interface InternalProps extends DetailPageProps {
  wizard?: Context;
  setTeamName: (s: string) => void;
  selectCustomFields: (s: any) => void;
  awardId: string;
  saveNomination: Function;
}

class DetailPage extends React.Component<InternalProps, any> {
  handleChange = ({ teamName, costCenter, TeamNomination }) => {
    if (TeamNomination) {
      this.props.selectCustomFields(TeamNomination);
    }
    this.props.setTeamName(teamName);
  };

  render() {
    const { selectCustomFields, setTeamName, awardId, wizard, saveNomination, ...rest } = this.props;
    return (
      <NarrowGrid {...rest}>
        <TeamDetailInstructions />
        <DetailForm onChange={this.handleChange} />
      </NarrowGrid>
    );
  }
}

const compose = register('rsv8-nomination/TeamDetails');

const mapState = (state) => ({ awardId: teamNominationSelectors.getCurrentAwardId(state) });

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      setTeamName: teamNominationActions.setTeamName,
      selectCustomFields: teamNominationActions.selectCustomFields,
      saveNomination: teamNominationActions.save
    },
    dispatch
  );

export default compose(
  connect(
    mapState,
    mapDispatch
  ),
  withWizard()
)(DetailPage);
