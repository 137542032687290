import * as React from 'react';
import { Image } from 'rsv8-components';
import styled from 'styled-components';
import { LoaderText } from '../Loader/index';

const AwardsLoadingContainer = styled.div`
  background-color: #ffffff;
  border: 1px solid #d4d4d4;
  padding: 100px 25px;
  margin-top: 50px;
  font-size: 16px;
  color: #7c8180;
  font-weight: bold;
  text-align: center;
  border-radius: 3px;
`;

type LoadingProps = {
  isDisplayed: boolean;
};

const PlaceholderLoading = ({ isDisplayed }: LoadingProps) =>
  isDisplayed ? (
    <AwardsLoadingContainer>
      <Image themeVariation="nomination-ribbon" width="40px" height="40px" />
      <LoaderText displayText="Loading Awards" />
    </AwardsLoadingContainer>
  ) : null;

export default PlaceholderLoading;
