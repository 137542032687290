import styled from 'styled-components';

export const CartPanel = styled.div`
  box-shadow: 0 2px 17px 2px rgba(28, 39, 76, 0.09);
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  padding: 15px;
` as any;

export const PanelInformation = styled.div`
  display: flex;
` as any;

export const PanelImage = styled.div`
  height: 97px;
  margin-right: 15px;
  width: 97px;
  background-image: url(${(props: any) => props.imageurl});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
` as any;

export const PanelName = styled.div`` as any;

export const PanelValue = styled.div`
  font-weight: bold;
  margin: 8px 0;
` as any;

export const PanelButtons = styled.div`
  display: flex;
` as any;

export const PanelShipMethod = styled.div`
  color: #7c8180;
  font-size: 12px;
  margin-bottom: 20px;
  margin-top: 10px;
` as any;

export const PanelContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
` as any;

export const PanelsContainer = styled.div`
  order: 2;
` as any;
