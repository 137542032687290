import styled from 'styled-components';
import { register, withTheme } from 'xcel-react-core';

const FieldContainer = styled.div`
  label {
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    cursor: pointer;
    display: block;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    justify-content: center;
    margin-left: 4px;
    padding-left: 30px;
    position: relative;
    user-select: none;
  }
  label input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  input[type='radio'] {
    margin: 0 0 0 -20px;
  }

  .radio > label {
    padding-left: 17px;
  }

  /* Create a custom checkbox */
  .catalog-checkmark {
    border-radius: 2px;
    border: solid 1px #bababa;
    height: 20px;
    left: 0;
    position: absolute;
    width: 20px;
  }

  /* When the checkbox is checked, add a blue background */
  label input:checked ~ .catalog-checkmark,
  label input:checked ~ span .catalog-checkmark {
    background-color: #00a9e0;
    border-radius: 2px;
    border: solid 1px #00a9e0;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .catalog-checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  label input:checked ~ .catalog-checkmark:after,
  label input:checked ~ span .catalog-checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  label .catalog-checkmark:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
` as any;

export default register('rsv8-catalog/FieldContainer')(withTheme())(FieldContainer);
