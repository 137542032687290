import * as React from 'react';
import styled from 'styled-components';
import { register, withTheme } from 'xcel-react-core';
export interface ResponsiveTableProps {
  columns: any;
  data: any;
  className?: any;
}
const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
  padding: 10px;
  border-top: 1px solid #ddd;
  &:first-child {
    margin-top: 0px;
  }
  &:last-child {
    margin-bottom: 50px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
  }
`;
const Cell = styled.div`
  flex: 1 1 100%;
  padding: 10px 0 0 0;
  overflow: initial !important;
`;
const Header = styled.div`
  display: inline-block;
  width: 50%;
  vertical-align: top;
  box-sizing: border-box;
  padding-right: 5%;
`;
const Value = styled.div`
  display: inline-block;
  width: 50%;
  vertical-align: top;
`;
const FullWidthValue = styled.div`
  width: 100%;
`;
class ResponsiveTable extends React.Component<ResponsiveTableProps, {}> {
  shouldComponentUpdate(nextProps: any) {
    return ['columns', 'data', 'className'].filter((key) => nextProps[key] !== this.props[key]).length !== 0;
  }
  render() {
    let { columns, data, className } = this.props;
    return (
      <div className={className}>
        {data &&
          data.map((record, index) => {
            if (record === null) {
              return null;
            }
            return (
              <Row key={index}>
                {columns.map((column) => {
                  const { renderCell, renderHeader, ...rest } = column;
                  const label =
                    column.label && renderHeader
                      ? renderHeader({ record, column, index, isMobile: true })
                      : column.label;
                  const value = column.renderCell
                    ? column.renderCell({ record, column, index, isMobile: true })
                    : record[column.field] || '';
                  return (
                    <Cell key={column.field + index} {...rest}>
                      {label && <Header>{label}</Header>}
                      {label ? <Value>{value}</Value> : <FullWidthValue>{value}</FullWidthValue>}
                    </Cell>
                  );
                })}
              </Row>
            );
          })}
      </div>
    );
  }
}

export { ResponsiveTable };

export default register('rsv8-components/ResponsiveTable')(withTheme())(ResponsiveTable);
