import { UserManager, WebStorageStateStore } from 'oidc-client';
import { getConfigurationProperty } from 'xcel-config';
import { queryString } from 'xcel-util';
import OAuth from '../app/paths/OAuth';
import { renameToCamelCaseObject } from '../app/util';
import { IDENTITY_CONFIG } from '../utils/authConstants';
import Public from "../app/paths/Public";

const localStorageWithLogs = {
  setItem: (key, value) => {
    console.log('localStorageWithLogs.setItem', key, value);
    localStorage.setItem(key, value);
  },
  getItem: (key) => {
    console.log('localStorageWithLogs.getItem', key);
    return localStorage.getItem(key);
  },
  removeItem: (key) => {
    console.log('localStorageWithLogs.removeItem', key);
    localStorage.removeItem(key);
  },
  clear: () => {
    console.log('localStorageWithLogs.clear');
    localStorage.clear();
  },
  key: (index) => {
    console.log('localStorageWithLogs.key', index);
    return localStorage.key(index);
  }
}

const sessionStorageWithLogs = {
  setItem: (key, value) => {
    console.log('sessionStorageWithLogs.setItem', key, value);
    sessionStorage.setItem(key, value);
  },
  getItem: (key) => {
    console.log('sessionStorageWithLogs.getItem', key);
    return sessionStorage.getItem(key);
  },
  removeItem: (key) => {
    console.log('sessionStorageWithLogs.removeItem', key);
    sessionStorage.removeItem(key);
  },
  clear: () => {
    console.log('sessionStorageWithLogs.clear');
    sessionStorage.clear();
  },
  key: (index) => {
    console.log('sessionStorageWithLogs.key', index);
    return sessionStorage.key(index);
  }
}

class OAuthService {
  private static _instance: OAuthService | null = null;
  private _userManager: UserManager;
  private _state: { path?: string } | null = {};

  private _identityServerConfigData: any = null;
  private _configFetched: boolean;

  private _authority: any = null;
  private _authorityFetched: boolean;

  static getInstance(): OAuthService {
    if (OAuthService._instance === null) {
      OAuthService._instance = new OAuthService();
    }
    return OAuthService._instance;
  }

  getAuthority = (): any => {
    if (this._authority) {
      return this._authority;
    } else {
      if (this._authorityFetched) {
        return null;
      } else {
        const authority = getConfigurationProperty(['authority']) as any;
        this._authorityFetched = true;
        this._authority = authority;
        return this._authority;
      }
    }
  };

  getIdentityServerConfigData = (): any => {
    if (this._identityServerConfigData) {
      return this._identityServerConfigData;
    } else {
      if (this._configFetched) {
        return null;
      } else {
        const configData = getConfigurationProperty(['identityServerConfigData']) as any;
        this._configFetched = true;
        this._identityServerConfigData = configData;
        return this._identityServerConfigData;
      }
    }
  };

  getClientId = (): any => {
    const configData = this.getIdentityServerConfigData();
    if (configData) {
      return configData.apiHost.clientId;
    }
    return null;
  };

  initializeUserManager = () => {
    let authority = this.getAuthority();
    let identityServerConfigData = this.getIdentityServerConfigData() as any;
    if (authority && identityServerConfigData) {
      const { apiHost } = identityServerConfigData;
      const scope = apiHost.clientScopes.join(' ');
      const qs = queryString.get();
      const config = {
        ...IDENTITY_CONFIG,
        ...{
          authority,
          client_id: apiHost.oauthClientId,
          scope
        },
        extraQueryParams: qs,
        stateStore: new WebStorageStateStore({ store: localStorageWithLogs }),
        userStore: new WebStorageStateStore({ store: sessionStorageWithLogs }),
        response_mode: 'query'
      };
      this._userManager = new UserManager(config);
      const { pathname, search } = location;
      let isValidPath = pathname !== OAuth.loginRedirectCallback && pathname !== '/oauth';
      if (isValidPath && pathname === '/' && (search.includes('&') || search.includes('='))) {
        isValidPath = false;
      }
      this._state = { path: isValidPath ? pathname + search : '' };
    }
  };

  get userManager() {
    if (this._userManager) {
      return this._userManager;
    } else {
      this.initializeUserManager();
      return this._userManager;
    }
  }

  getState(): {} | null {
    return this._state;
  }

  getUserDataFromResponse = (response) => {
    return response.profile ? renameToCamelCaseObject(response.profile) : response;
  };

  getAuthInfoFromResponse = (response) => {
    const data = renameToCamelCaseObject(response);
    const date = data.expiresAt;
    const expireDate = new Date(0);
    expireDate.setUTCSeconds(date);
    const result = {
      ...data,
      expireDate: expireDate
    };
    delete result.profile;
    return result;
  };

  isCurrentOAuthRoute = (pathname: string): boolean => {
    return Object.values(OAuth).includes(pathname ? pathname : window.location.pathname);
  };

  isPublicRoute = (pathname: string): boolean => {
    const urlPath = pathname ? pathname : window.location.pathname;
    return !!Object.values(Public).find(path => urlPath.includes(path));
  }

  canProcessRequest = (auth: any, pathname: string): boolean => {
    return this.getIdentityServerConfigData() ? !this.isCurrentOAuthRoute(pathname) : auth.accessToken && auth.isValid;
  }

  usePublicEndpoint = (auth: any, clientId: string): boolean => {
    return (!auth || (auth.isOAuth && !auth.accessToken) || !auth.isValid) && !!clientId;
  }
}

export default OAuthService.getInstance();
