import { createTypeSelector } from 'xcel-redux-orm';
import { NAV_ITEM_JSON_TYPE } from '../../constants';

export const navigationSelector = createTypeSelector<any>(NAV_ITEM_JSON_TYPE);
export const getNavigationCategories = (state) =>
  navigationSelector.searchOne(state, (a) => {
    try {
      const metaJson = JSON.parse(a.meta);
      return metaJson.IsCategoryList;
    } catch (e) {
      return false;
    }
  });
