import * as moment from 'moment';
import * as React from 'react';
import { Image } from 'rsv8-components';
import {
  FeedCardCaption,
  FeedCardHeaderStyle,
  FeedCardImage,
  FeedCardTitle
  } from './styles';

// Get the timezone offset in minutes on the load
const TIMEZONE_OFFSET = new Date().getTimezoneOffset();

const FeedCardHeader = ({ title, publishedDateTimeUtc }) => {
  // normilize the created date based on the OS timezone
  const normalizedTime = moment(publishedDateTimeUtc)
    .subtract(TIMEZONE_OFFSET, 'minutes')
    .fromNow();

  return (
    <FeedCardHeaderStyle>
      <FeedCardImage className="image-container">
        <Image themeVariation="primary-program-logo1-small" />
      </FeedCardImage>
      <span>
        <FeedCardTitle className="title">{title}</FeedCardTitle>
        <FeedCardCaption className="caption">{normalizedTime}</FeedCardCaption>
      </span>
    </FeedCardHeaderStyle>
  );
};

export default FeedCardHeader;
