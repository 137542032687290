import * as React from 'react';
import { Collapse as BootstrapCollapse } from 'react-bootstrap';
import { isClassComponent, renameProp } from 'recompose';
import { DivProps } from 'xcel-react-core';
import { PanelBody as PaddingWrapper } from '../Panel';

export type InternalCollapseComponent = React.ComponentType<{ open: boolean; onClick: (e?: any) => void }>;

export interface BaseCollapseProps extends DivProps {
  heading: string | InternalCollapseComponent;
  headComponent?: string | InternalCollapseComponent;
  tailComponent?: InternalCollapseComponent;
  tailCollapses?: boolean;
  openCollapseInitially?: boolean;
}

// Don't ask me why this works and a div doesn't.
const Body = (PaddingWrapper as any).extend`
  padding: 0px;
`;

const isComponent = (val) => typeof val === 'function' || isClassComponent(val);

class BaseCollapse extends React.Component<BaseCollapseProps, { visible: boolean }> {
  state = { visible: false };

  componentWillMount() {
    if (this.props.openCollapseInitially) {
      this.setState({ visible: true });
    }
  }

  handleClick = () => this.setState({ visible: !this.state.visible });

  render() {
    const { children, headComponent: Header, tailComponent: Tail, tailCollapses = true, ...rest } = this.props;

    return (
      <div {...rest}>
        {typeof Header === 'string' ? (
          <div onClick={this.handleClick}>{Header}</div>
        ) : (
          <Header onClick={this.handleClick} open={this.state.visible} />
        )}
        <BootstrapCollapse in={this.state.visible}>
          <Body>
            {children}
            {isComponent(Tail) && tailCollapses ? <Tail onClick={this.handleClick} open={this.state.visible} /> : null}
          </Body>
        </BootstrapCollapse>
        {isComponent(Tail) && !tailCollapses ? <Tail onClick={this.handleClick} open={this.state.visible} /> : null}
      </div>
    );
  }
}

export default renameProp('heading', 'headComponent')(BaseCollapse);
