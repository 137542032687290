import * as React from 'react';
import {
  CaptionImage,
  ColoredCircle,
  Image,
  Text
  } from 'rsv8-components';
import { CaptionImageProps } from 'rsv8-components';
import { Omit } from 'xcel-util';

const TeamIcon = (props) => (
  <ColoredCircle width={'60px'} height={'60px'} backgroundColor={'#00A9E0'}>
    <Image themeVariation="icon-team" />
  </ColoredCircle>
);

const TeamIconImage: React.SFC<Omit<CaptionImageProps, 'component'>> = ({ children, ...props }) => {
  return (
    <CaptionImage component={TeamIcon} {...props}>
      <Text themeVariation="primary-text">{children}</Text>
    </CaptionImage>
  );
};

export default TeamIconImage;
