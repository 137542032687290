import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { bindActionCreators } from 'redux';
import { authStateSelectors } from 'rsv8-auth';
import { cultureSelector } from 'rsv8-client';
import { emptyObject, queryString } from 'xcel-util';
import { cmsPageGet } from '../../../redux/actions';
import { getContent } from '../../../redux/selectors';
import ComponentRenderer from '../ComponentRenderer';

export interface PageRendererProps extends Partial<RouteComponentProps<any>> {
  path: string;
  onNotFound?: Function;
  [index: string]: any;
}
export interface PageRendererInternalProps extends PageRendererProps {
  actions?: {
    cmsPageGet: Function;
  };
  prefix?: string;
  cultureId?: string;
  content?: any;
  auth?: any;
}
class PageRenderer extends React.Component<PageRendererInternalProps> {
  loadPage = (props: PageRendererInternalProps) => {
    let { prefix, actions, path, cultureId, location } = props;
    let contentType = location.state ? (location.state as any).contentType : this.props.nextLandingRedirect ? 'Landing' : 'Page';

    if (!this.isContentLoaded(props)) {
      actions.cmsPageGet({ name, prefix, path, contentType }, cultureId);
    }
  };
  componentDidMount() {
    this.loadPage(this.props);
  }
  getContent = (props) => {
    const { content } = props;
    return content && content.page && content.page.content;
  };
  isContentLoaded = (props) => {
    return props.content.path === props.path || false;
  };
  componentDidUpdate(nextProps: any) {
    if (
      (!this.isContentLoaded(this.props) ||
        this.props.path !== nextProps.path ||
        this.props.cultureId !== nextProps.cultureId) && !this.props.auth.doingGigyaSSOSync
    ) {
      this.loadPage(this.props);
    }
  }
  render() {
    const { prefix, cultureId, path, onNotFound, ...rest } = this.props;
    const data = this.getContent(this.props);
    return <ComponentRenderer data={data} onNotFound={onNotFound} qs={queryString.get()} {...rest} />;
  }
}

type StateProps = Pick<PageRendererInternalProps, 'content' | 'cultureId' | 'auth'>;
type DispatchProps = Pick<PageRendererInternalProps, 'actions'>;

const mapState = (state, { path, cultureId: ownCultureId }): StateProps => {
  const page = getContent(state);
  const cultureId = ownCultureId ? ownCultureId.toString() : cultureSelector.getActiveCultureId(state).toString();
  const auth = authStateSelectors.getAuth(state);
  return {
    content: (page[cultureId] && page[cultureId][path] && page[cultureId][path]) || emptyObject,
    cultureId,
    auth
  };
};

const mapDispatch = (dispatch): DispatchProps => ({
  actions: bindActionCreators(
    {
      cmsPageGet
    },
    dispatch
  )
});

export default compose<React.SFC<PageRendererProps>>(
  withRouter,
  connect(
    mapState,
    mapDispatch
  )
)(PageRenderer);
