import { push } from 'connected-react-router';
import { destroy, destroyRequest, selectOne } from 'xcel-redux-orm';
import { JSON_TYPE_INDIVIDUAL_DRAFT_NOMINATION, JSON_TYPE_TEAM_DRAFT_NOMINATION } from '../../../constants';
import { NominationActivity, Resources } from '../../../types';
import * as events from '../../events';
import { nominationSelectors, teamNominationSelectors } from '../../selectors/index';
import individualNominationActions from '../individual/index';
import { nominationActions } from '../nomination';
import { teamNominationActions } from '../team/index';
import {
  deleteNomination,
  deleteTeamDraft,
  getIndividualDraftDetails,
  getNomineeById,
  getTeamDraftDetails
  } from './calls';

export const setSort = (key: keyof NominationActivity.State) => ({ field, direction }: NominationActivity.Sort) => ({
  type: events.SET_NOMINATION_ACTIVITY_SORT,
  payload: { field, direction, key }
});

const hideDetailModal = () => ({ type: events.HIDE_NOMINATION_DETAIL_MODAL });
const showDetailModal = () => ({ type: events.SHOW_NOMINATION_DETAIL_MODAL });

const setModalId = (id: number, type: string): NominationActivity.Action => ({
  type: events.SET_NOMINATION_ACTIVITY_MODAL_ID,
  payload: { id, type }
});

const activateModal = (id: number, type: string) => (dispatch) => {
  dispatch(showDetailModal());
  dispatch(setModalId(id, type));
};

const destroyNomination = (id: string) => async (dispatch, getState) => {
  try {
    // we need to figure out what we are trying to delete.
    const individualNomination = nominationSelectors.individualDraftSelectors.selectOne(getState(), id);
    if (individualNomination && individualNomination.id !== undefined) {
      await dispatch(deleteNomination({ id: Number(id) }));
      dispatch(destroy(JSON_TYPE_INDIVIDUAL_DRAFT_NOMINATION, id));
    } else {
      await dispatch(deleteTeamDraft({ id: Number(id) }));
      dispatch(destroy(JSON_TYPE_TEAM_DRAFT_NOMINATION, id));
    }
  } catch (e) {
    throw e;
  }
};

const approveNomination = (obj: {
  id: number;
  action: 'Approve' | 'Deny' | 'SendBack';
  scheduledDate: string;
  sendBackComments: string;
  customFields: Array<{ id: string; values: string[] }>;
}) => (dispatch) => dispatch(nominationActions.createProcess({ model: obj }, { jsonApi: true }));

const destroyDrafts = () => (dispatch) => {
  return dispatch(destroy(JSON_TYPE_INDIVIDUAL_DRAFT_NOMINATION, null));
};

const getDraftDetails = (nomination: Resources.IndividualDraftNomination | Resources.TeamDraftNomination) => async (
  dispatch,
  getState
) => {
  if (nomination.awardType === 'Individual') {
    await dispatch(getIndividualDraftDetails({ id: Number(nomination.id) }));
    const draftObject = selectOne<Resources.DraftSubmissionAttributes>(
      getState(),
      'nominationSubmission',
      nomination.id.toString()
    );
    await Promise.all([
      dispatch(individualNominationActions.setNomination(draftObject)),
      dispatch(
        individualNominationActions.getAwardDetails(
          draftObject.nominationApplicationKey,
          draftObject.nomineeId.toString()
        )
      ),
      dispatch(getNomineeById({ id: draftObject.nomineeId }))
    ]);

    dispatch(push('/nominations/individual'));
  } else if (nomination.awardType === 'Team') {
    const response = await getTeamDraftDetails({ id: nomination.id });
    await dispatch(teamNominationActions.setNomination(response));
    teamNominationSelectors
      .getCurrentNomineeIds(getState())
      .forEach(async (val) => await dispatch(getNomineeById({ id: parseInt(val, 10) })));
    await dispatch(teamNominationActions.getAwardDetails(teamNominationSelectors.getCurrentAwardId(getState()), null));
    dispatch(push('/nominations/team'));
  }
};

const nominationActivityActions = {
  getIndividualHistory: () => (dispatch) =>
    dispatch(nominationActions.getNominationsHistory({ limit: 10 }, { jsonApi: true })),
  getTeamHistory: () => (dispatch) => dispatch(nominationActions.getTeam({ limit: 10 }, { jsonApi: true })),
  getIndividualDrafts: () => (dispatch) =>
    dispatch(nominationActions.getNominationsDraft({ limit: 10 }, { jsonApi: true })),
  getTeamDrafts: () => (dispatch) => dispatch(nominationActions.getTeamDraft({ limit: 10 }, { jsonApi: true })),
  // getPendingApprovals: () => (dispatch) =>
  //   dispatch(nominationActions.getNominationsPendingapproval({ limit: 10 }, { jsonApi: true })),
  // getApprovalHistory: () => (dispatch) =>
  //   dispatch(nominationActions.getNominationsApprovalhistory({ limit: 10 }, { jsonApi: true })),
  showModal: showDetailModal,
  hideModal: hideDetailModal,
  approveNomination,
  getNominationDetails: (id) => (dispatch, getState) => {
    dispatch(destroyRequest('getNominationsDetailsById'))
    return dispatch(nominationActions.getNominationsDetailsById({ id }, { jsonApi: true }));
  },
  setModalId: setModalId,
  getDraft: getDraftDetails,
  getTeamHistoryMemberDetails: (ids: string[]) => (dispatch) =>
    ids.forEach((id) => dispatch(nominationActions.getNominationsDetailsById({ id: Number(id) }))),
  activateModal,
  delete: destroyNomination,
  destroyDrafts,
  destroyNomination
};

export { nominationActivityActions };
