import * as _ from 'lodash';
import * as React from 'react';
import {Col, Row} from 'react-bootstrap';
import {compose} from 'redux';
import {HeaderThree, Modal, ModalBody, ModalHeader} from 'rsv8-components';
import styled from 'styled-components';
import { ForecastRequestMailData } from 'xcel-api-generator/dist/alcon';
import {withResource} from 'xcel-react-core';

const SubmitButton = styled.button`
  width: 60%;
  height: 50px;
  border-radius: 25px;
  background-color: #003595;
  font-family: open-sans;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 1.5px;
  text-align: center;
  color: #ffffff;
  margin-left: 20%;
  margin-right: 20%;
  margin-bottom: 50px;
  margin-top: 20px;
  outline: none;
  border: 0;
` as any;

const ModaHeaderText = styled.div`
  margin-top: 50px;
` as any;

const ModalUserInstructionsText = styled.div`
  font-family: open-sans, sans-serif;
  width: 70%;
  margin: auto;
  text-align: center;
  font-size: 10px;
  color: #505d6f;
` as any;

const ModaBodyContainer = styled.div`
  font-family: open-sans, sans-serif;
  input,
  textarea {
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
    border-radius: 6px;
    border: 2px solid #b2b2b2;
    color: #505d6f;
    line-height: 28px;
    letter-spacing: 0.5px;
    font-size: 12px;
    padding-left: 20px;
    margin-top: 20px;
    min-height: 30px;
  }
  input.error {
    border-color: #e2231a;
  }
  .error-message {
    border: 2px solid #e2231a;
    background-color: #fae3e2;
    border-radius: 6px;
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
    text-align: center;
    font-size: 10px;
    color: #e2231a;
    padding: 10px;
  }
  .info-message {
    border: 2px solid #039005;
    border-radius: 6px;
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
    text-align: center;
    font-size: 10px;
    color: #039005;
    background-color: #e3fae2;
    padding: 10px;
  }
  textarea {
    resize: none;
  }
` as any;

const ErrorList = styled.ul`
text-align: left;
padding-left: 15px;
margin-bottom: 0;
li {
  padding: 4px 0;
}
`;
export interface Props {
  show: boolean;
  handleShare: Function;
  handleHide: Function;
}

class RebateCalculatorSharingModal extends React.Component<Props> {
  controlNames = {
    email: 'email',
    subjectLine: 'subjectLine',
    message: 'message'
  };

  state = {
    validationContext: [
      {
        name: this.controlNames.email,
        displayName: 'Email',
        required: true,
        value: undefined
      },
      {
        name: this.controlNames.subjectLine,
        displayName: 'Subject Line',
        required: true,
        value: undefined
      },
      {
        name: this.controlNames.message,
        displayName: 'Message',
        required: false,
        value: undefined
      }
    ],
    emailValidation: true,
    subjectLineValidation: true,
    messageValidation: true,
    errorMessage: undefined,
    apiCallFailed: undefined,
    readonlyMode: false
  };

  handleChanges = (e) => {
    this.setState({ [`${e.target.name}Touched`]: true });
    const control = this.state.validationContext.find((it) => it.name === e.target.name);
    control.value = (e.target.value || '').trim();
  };

  validate = () => {
    const validateItem = (item) => {
      let isValid = true;

      if (item.name === this.controlNames.subjectLine) {
        isValid = !!item.value && item.value.trim() !== '';

        this.setState({
          subjectLineValidation: isValid
        });
      } else if (item.name === this.controlNames.email) {
        isValid = !!item.value && item.value.trim() !== '' && _.split(item.value, ';').map(e => this.validateEmail(e)).every(val => val);

        this.setState({
          emailValidation: isValid
        });
      }
      return isValid;
    };

    return this.state.validationContext.map(item => validateItem(item)).every(val => val);
  };

  validateEmail(value: string): boolean {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return value && regex.test(value);
  }

  getDialogData = (): ForecastRequestMailData => {
    const address = this.state.validationContext.filter((item) => item.name === this.controlNames.email);
    const emailList =
      address && address[0].value && address[0].value !== '' ? _.split(address[0].value, ';') : [];
    return {
      ccAddress: emailList,
      subject: this.state.validationContext.filter((item) => item.name === this.controlNames.subjectLine)[0].value,
      message: this.state.validationContext.filter((item) => item.name === this.controlNames.message)[0].value
    };
  };

  handleShare = async (e) => {
    if (!this.validate()) return;

    const response = await this.props.handleShare(this.getDialogData());
    const apiCallFailedErrorMessage = 'We are sorry, but we encountered an error during processing of your request. Please try again or contact with an administrator.';
    const errorMessage = response && !!response.error ? response.error : apiCallFailedErrorMessage;
    const resultStatus = response && response.success === true;

    this.setState({
      errorMessage: errorMessage,
      apiCallFailed: !resultStatus,
      readonlyMode: resultStatus
    });
  };

  render() {
    const showError = this.state.apiCallFailed === true;
    const showInputErrors = this.state.emailValidation === false || this.state.subjectLineValidation === false;
    const showInfo = this.state.apiCallFailed === false && this.state.readonlyMode;

    return (
      <Modal onHide={this.props.handleHide} show={this.props.show} width={'400px'}>
        <ModalHeader closeButton={true}>
          <ModaHeaderText>
            <HeaderThree>Email Forecasts</HeaderThree>

            <ModalUserInstructionsText>
              The current forecast will be saved when you send this email. Multiple recipient email addresses should be
              separated with a semicolon.
            </ModalUserInstructionsText>
          </ModaHeaderText>
        </ModalHeader>

        <ModalBody>
          <ModaBodyContainer>
            <Row>
              {showError &&
              <Col md={24}>
                <div className="error-message">{this.state.errorMessage}</div>
              </Col>
              }

              {showInputErrors &&
              <Col md={24}>
                <div className="error-message">
                  <ErrorList>
                    {!this.state.subjectLineValidation && <li>Subject line is required.</li>}
                    {!this.state.emailValidation && <li>One or more of the email addresses you entered has an invalid format.</li>}
                  </ErrorList>
                </div>
              </Col>
              }

              {showInfo &&
              <Col md={24}>
                <div className="info-message">Your request was sent successfully.</div>
              </Col>
              }
            </Row>

            <Row>
              <Col md={24}>
                <input
                  name={this.controlNames.subjectLine}
                  type="text"
                  placeholder="Subject Line*"
                  onChange={this.handleChanges}
                  className={!this.state.subjectLineValidation ? 'error' : ''}
                  disabled={this.state.readonlyMode}
                />
              </Col>
            </Row>

            <Row>
              <Col md={24}>
                <input
                  name={this.controlNames.email}
                  type="text"
                  placeholder="Recipient Email*"
                  onChange={this.handleChanges}
                  className={!this.state.emailValidation ? 'error' : ''}
                  disabled={this.state.readonlyMode}
                />
              </Col>
            </Row>

            <Row>
              <Col md={24}>
                <textarea
                  name={this.controlNames.message}
                  placeholder="Message"
                  onChange={this.handleChanges}
                  disabled={this.state.readonlyMode}
                />
              </Col>
            </Row>

            <Row>
              <Col md={24}>
                {!this.state.readonlyMode ? (
                  <SubmitButton
                    onClick={this.handleShare}
                  >
                    Share
                  </SubmitButton>
                ) : null}
              </Col>
            </Row>
          </ModaBodyContainer>
        </ModalBody>
      </Modal>
    );
  }
}
export default compose(withResource())(RebateCalculatorSharingModal as any);
