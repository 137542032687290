import * as React from 'react';
import * as Icon from 'react-fontawesome';
import { connect } from 'react-redux';
import { Dashboard } from 'xcel-api-generator/dist/alcon';
import { register, withContent, withTheme } from 'xcel-react-core';
import { withApiDataProvider } from 'xcel-redux-orm';
import { getCurrencyValue } from '../../../utils';
import { getSummary } from '../../redux/actions';
import { dashboardSelector } from '../../redux/selectors';
import { OverlayPopover } from '../Overlay';
import { PlaceHolderBar } from './PlaceHolderBar';
import {
  BarCellLabel,
  BarCellValue,
  BarToggle,
  MarketShareBarCell,
  MarketShareRebateBarContainer,
  ProjectedRebateBarWrapper,
  RebateBarCell,
} from './styles';

const MarketShareRebateBar: React.FC<{
  dashboard: Dashboard;
}> = ({ dashboard }) => (
  <>
    <MarketShareBarCell className="rebate-bar-cell">
      <BarCellLabel>{dashboard.baselineTimePeriod.name} SALES</BarCellLabel>
      <BarCellValue>{getCurrencyValue(dashboard.performance.baseline.sales)}</BarCellValue>
    </MarketShareBarCell>

    <MarketShareBarCell className="rebate-bar-cell">
      <BarCellLabel>{dashboard.currentTimePeriod.name} SALES</BarCellLabel>
      <BarCellValue>{getCurrencyValue(dashboard.performance.current.sales)}</BarCellValue>
    </MarketShareBarCell>

    <MarketShareBarCell className="rebate-bar-cell">
      <BarCellLabel>PROJECTED SALES</BarCellLabel>
      <BarCellValue>{getCurrencyValue(dashboard.performance.runrate.sales)}</BarCellValue>
    </MarketShareBarCell>

    <MarketShareBarCell className="rebate-bar-cell">
      <BarCellLabel>PROJECTED REBATE</BarCellLabel>
      <BarCellValue>{getCurrencyValue(dashboard.performance.runrate.rebate)}</BarCellValue>
    </MarketShareBarCell>
  </>
);

const ProjectedRebateBar: React.FC<Props> = ({ dashboard, infoButtonText, className }) => {
  const [collapsed, setCollapsed] = React.useState(true);

  if (!dashboard) {
    return <PlaceHolderBar collapsed={collapsed} className={className} />;
  }

  return (
    <div className={className}>
      <MarketShareRebateBarContainer collapsed={collapsed}>
        <ProjectedRebateBarWrapper>
          <OverlayPopover id="popover-dashboard-title" placement="bottom">
            {infoButtonText}
          </OverlayPopover>

          <div className="rebate-bar__content">
            <RebateBarCell className="rebate-bar-cell">
              <BarCellLabel>QUARTER</BarCellLabel>
              <BarCellValue>{dashboard.currentTimePeriod.name}</BarCellValue>
            </RebateBarCell>
            <MarketShareRebateBar dashboard={dashboard} />
          </div>
          <div className="rebate-bar__content projected">
            <RebateBarCell className="rebate-bar-cell projected">
              <BarCellLabel>CURRENT REBATE VALUE</BarCellLabel>
              <BarCellValue>{getCurrencyValue(dashboard.performance.current.rebate)}</BarCellValue>
            </RebateBarCell>
          </div>
        </ProjectedRebateBarWrapper>

        <BarToggle collapsed={collapsed} onClick={() => setCollapsed(!collapsed)}>
          {collapsed && <div className="dashboard-bar__toggle-label">QUICKSTATS</div>}

          <div className="dashboard-bar__toggle-icon">
            {collapsed && <Icon name="angle-down" />}
            {!collapsed && <Icon name="angle-up" />}
          </div>
        </BarToggle>
      </MarketShareRebateBarContainer>
    </div>
  );
};

const mapContentToProps = (getContent) => ({
  infoButtonText: getContent('infoButtonText', {
    type: 'string',
    schema: [{ model: 'Add info button text', label: 'add info button text' }],
  }),
});

const mapStateToProps = (state) => ({
  dashboard: dashboardSelector.selectMany(state)[0],
});

interface StateProps {
  dashboard: Dashboard;
}
interface ContentProps {
  infoButtonText: string;
}
type Props = React.HTMLAttributes<any> & StateProps & ContentProps;

export default register('alcon-information/ProjectedRebateBar')(
  connect<StateProps>(mapStateToProps),
  withApiDataProvider(getSummary, dashboardSelector),
  withTheme(),
  withContent(mapContentToProps)
)(ProjectedRebateBar);
