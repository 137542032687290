import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageRenderer } from 'rsv8-cms';
import { ModularMenu } from 'rsv8-navigation';
import { Wizard } from 'rsv8-wizard';
import { register, withResource } from 'xcel-react-core';
import { TEAM_NOMINATION_WIZARD_NAME } from '../../constants';
import { clearTeamNomination } from '../../redux/actions/team/clearCurrent';
import ProgressHeader from './ProgressHeader';

class TeamNominationWizard extends React.Component<any, any> {
  mySteps = [
    {
      title: 'Awards',
      component: 'rsv8-nomination/TeamSelectAward',
      hideBackButton: true
    },
    { title: 'Product', component: 'rsv8-nomination/TeamSelectProduct' },
    { title: 'Criteria', component: 'rsv8-nomination/TeamSelectCriteria' },
    { title: 'Details', component: 'rsv8-nomination/TeamDetails' },
    { title: 'Nominee', component: 'rsv8-nomination/TeamSelectNominee' },
    {
      title: 'NomineeDetails',
      component: 'rsv8-nomination/TeamNominationNomineeDetails'
    },
    {
      title: 'Review',
      component: 'rsv8-nomination/TeamNominationConfirmation',
      nextText: this.props.saveText
    },
    {
      title: 'Success',
      component: 'rsv8-nomination/TeamNominationCongratulations',
      hideBackButton: true,
      hideNextButton: true
    }
  ];

  componentWillUnmount() {
    this.props.dispatch(clearTeamNomination());
  }

  filterSteps = (steps) => {
    if (this.props.skipCriteria === 'true') {
      const filteredSteps = steps.filter((step) => {
        return step.title !== 'Criteria';
      });
      return filteredSteps;
    }
    return steps;
  };

  render() {
    const { clearTeamNomination: test, saveText, currentStep, ...rest } = this.props;

    return (
      <React.Fragment>
        <PageRenderer path={`partial/nomination/eligibility`} onNotFound={() => null} />
        <ModularMenu appKey="nominationNav" title="Recognition" template="NominationsHeaderNav" />
        <ProgressHeader currentStep={currentStep}/>
        <Wizard {...rest} name={TEAM_NOMINATION_WIZARD_NAME} steps={this.filterSteps(this.mySteps)} />
      </React.Fragment>
    );
  }
}

const mapResource = (getResource) => ({ saveText: getResource('nominees.submitButtonText', 'Submit') });
const compose = register('rsv8-nomination/TeamNominationWizard');
const mapState = (state, ownProps: any) => {
  return { currentStep: state.wizard && state.wizard.TeamNominationWizard && state.wizard.TeamNominationWizard.currentStep ? state.wizard.TeamNominationWizard.currentStep : null };
};

export default compose(
  withRouter,
  connect(mapState),
  withResource(mapResource)
)(TeamNominationWizard);
