import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose, withProps } from 'recompose';
import { bindActionCreators } from 'redux';
import { cultureActions, cultureSelector } from 'rsv8-client';
import { PageRenderer } from 'rsv8-cms';
import {
  Grid,
  Image,
  Link,
  Loader,
  Text
  } from 'rsv8-components';
import { actions, getFieldsByGroup } from 'rsv8-customfields';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';
import { selectOne } from 'xcel-redux-orm';
import { FormDisplay } from '../../components';
import { nominationActions } from '../../redux/actions';

const PaddedRow = styled(Row)`
  padding-bottom: 20px;
`;

export const Certificate = styled.div`
  display: flex;
  justify-content: center;
` as any;

export interface ApproveByEmailProps extends RouteComponentProps<{}> {
  approvalCode: string;
  getCode: typeof nominationActions.getCodeById;
  approveNomination: typeof nominationActions.createProcess;
  setCultureId: any;
  approval: any;
  cultureId: any;
  thankYouMessage: string;
  alreadyTakenActionMessage: string;
  downloadCertificate: string;
  certificateUrl?: string;
  getCustomFields: any;
  showApprovalFields: boolean;
}

const GROUP_KEY = ['approve', 'deny'];
class ApproveByEmailForm extends React.Component<ApproveByEmailProps, {}> {
  state = { error: false };
  getCode = (id) => {
    this.setState({ error: false });
    this.props.getCode({ id }).catch((e) => {
      this.setState({ error: true });
    });
  };

  componentDidMount() {
    this.getCode(this.props.approvalCode);
  }

  componentDidUpdate(prevProps: ApproveByEmailProps, prevState: any) {
    if (prevProps.approval !== this.props.approval) {
      this.props.getCustomFields({
        formType: 'NominationProcess',
        groupKey: GROUP_KEY,
        appKey: this.props.approval && this.props.approval.applicationId
      } as any);
    }
    if (prevProps.approvalCode !== this.props.approvalCode) {
      this.getCode(this.props.approvalCode);
    }
    if (this.props.approval && this.props.cultureId !== this.props.approval.approverCultureId) {
      this.props.setCultureId(this.props.approval.approverCultureId.toString(), 'id', true);
    }
  }

  render() {
    const { approval, thankYouMessage, alreadyTakenActionMessage, certificateUrl, downloadCertificate } = this.props;

    if (!approval && !this.state.error) {
      return <Loader />;
    }

    return (
      <React.Fragment>
        <Grid themeVariation="page-wrapper">
          <Row>
            <Col xs={18} xsOffset={3}>
              <Image themeVariation="primary-program-logo1" />
            </Col>
          </Row>

          {!this.state.error ? (
            <React.Fragment>
              <PaddedRow>
                <Col xs={18} xsOffset={3}>
                  <PageRenderer path={`/nominations/take-action`} />
                </Col>
              </PaddedRow>
              <PaddedRow className="text-center">
                <Col xs={20} xsOffset={2} md={12} mdOffset={6}>
                  <FormDisplay
                    approveNomination={this.props.approveNomination}
                    processId={this.props.approvalCode as any}
                    applicationId={approval.applicationId}
                    actions={approval.actions}
                    showApprovalFields={this.props.showApprovalFields}
                  />
                </Col>
              </PaddedRow>
              {certificateUrl && (
                <PaddedRow>
                  <Col xs={24}>
                    <Certificate>
                      <Link href={certificateUrl} themeVariation="primary-button" target="_blank">
                        {downloadCertificate}
                      </Link>
                    </Certificate>
                  </Col>
                </PaddedRow>
              )}
              <PaddedRow>
                <Col xs={24}>
                  <Text themeVariation="center">{thankYouMessage}</Text>
                </Col>
              </PaddedRow>
            </React.Fragment>
          ) : (
            <Row>
              <Col xs={24}>
                <Text themeVariation="center">{alreadyTakenActionMessage}</Text>
              </Col>
            </Row>
          )}
        </Grid>
      </React.Fragment>
    );
  }
}

const mapResource = (getResource) => ({
  thankYouMessage: getResource('nominees.thankYouMessage', 'Thank you for supporting the Own It Honors Program!'),
  alreadyTakenActionMessage: getResource(
    'nominees.alreadyTakenActionMessage',
    'You have already successfully taken action on this nomination.'
  ),
  downloadCertificate: getResource('nominees.downloadCertificateLabel', 'Download Certificate')
});

const mapState = (state, ownProps) => {
  const approvalProcess: any = selectOne(state, 'processCodeForm', ownProps.approvalCode);
  const process: any = approvalProcess && selectOne(state, 'process', approvalProcess.processId);

  const showApprovalFields =
    approvalProcess && getFieldsByGroup(state, GROUP_KEY, 'NominationProcess', approvalProcess.applicationId).length > 0
      ? true
      : false;

  return {
    approval: approvalProcess,
    certificateUrl: process && process.certificateViewUrl,
    cultureId: cultureSelector.getActiveCultureId(state),
    showApprovalFields
  };
};

const mapProps = (props) => ({
  approvalCode: props.match.params.code
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      getCode: nominationActions.getCodeById,
      approveNomination: nominationActions.createCode,
      setCultureId: cultureActions.set,
      getCustomFields: actions.getCustomFields
    },
    dispatch
  );

export default compose(
  withRouter,
  withProps(mapProps),
  connect(
    mapState,
    mapDispatch
  ),
  withResource(mapResource)
)(ApproveByEmailForm);
