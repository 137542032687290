import { mapJsonApiActions, destroyRequest } from 'xcel-redux-orm';
import { userApi } from '../../api';

const userApiActions = mapJsonApiActions(userApi);

const fetchUser = (userId: number) => async (dispatch, getState) => {
  try {
    dispatch(destroyRequest('getDownlineByDownlineUserId'));
    const response = await dispatch(
      userApiActions.getDownlineByDownlineUserId(
        {
          downlineUserId: userId,
        },
        { jsonApi: true }
      )
    );

    return response;
  } catch (e) {
    throw e
  }
};

export { fetchUser };
