import * as React from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import ComponentVariationSelect from './components/ComponentVariationSelect';
import ComponentVariationModal from './modals/CreateVariation';
const VariationField = styled.div`
  > div {
    display: inline-block;
    width: calc(100% - 65px);
  }
  button {
    margin: 0 10px;
  }
`;

class ComponentVariations extends React.Component<any> {
  state = { createVariationOpen: false };
  handleClick = () => {
    this.setState({ createVariationOpen: true });
  };
  handleHide = () => {
    this.setState({ createVariationOpen: false });
  };
  render() {
    const { name } = this.props;
    return (
      <VariationField>
        <ComponentVariationSelect name={name} />
        <Button onClick={this.handleClick}>
          <i className="fa fa-plus" />
        </Button>
        <ComponentVariationModal
          name={name}
          show={this.state.createVariationOpen}
          onHide={this.handleHide}
          onCreate={this.handleHide}
        />
      </VariationField>
    );
  }
}
export default ComponentVariations;
