import { Button } from 'rsv8-components';
import styled from 'styled-components';

export const ShippingInfoWrapper = styled.div`
  margin-top: 20px;
` as any;

export const ShippingInfoTable = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0;

  @media (min-width: 769px) {
    margin-left: ${(props: any) => (props.nopadding ? '0' : '20px')};
  }
` as any;

export const ShippingInfoLabel = styled.div`
  color: #4c4f4f;
  flex-grow: 1;
  margin-bottom: 10px;
  overflow: hidden;
  width: 40%;

  @media (min-width: 769px) {
    width: 33%;
  }
` as any;

export const ShippingInfoValue = styled.div`
  color: #4c4f4f;
  flex-grow: 1;
  margin-bottom: 10px;
  overflow: hidden;
  width: 60%;

  @media (min-width: 769px) {
    width: 67%;
  }
` as any;

export const EditInfoContainer = styled.span`
  color: #4192a5;
  font-size: 14px;
  font-weight: normal;
` as any;

export const EditInfoButton = styled(Button)`
  color: #4192a5;
  padding-top: 0;
  margin-left: 10px !important;

  &:active,
  &:focus,
  &:hover {
    color: #4192a5;
    outline: none !important;
    text-decoration: none;
  }
` as any;
