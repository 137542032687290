import * as React from 'react';
import * as utils from '../../../../utils';
import * as styles from './styles';

interface CustomInputProps {
  productId: number;
  unitLabel: string;
  customData: string | number;
}

export const CustomInput: React.FC<CustomInputProps> = ({
  productId,
  unitLabel,
  customData
}) => {
  const [getData, setGetData] = React.useState(customData);

  React.useEffect(() => {
    setGetData(customData);
  }, [customData]);

  return (
    <styles.ProductTableCellWithInput className="product__attribute-value">
      <styles.InputFieldContainer>
        <div>
          <styles.ProductAttrText>
            {unitLabel.toUpperCase()}
          </styles.ProductAttrText>
        </div>

        <styles.ProductInputField
          component="bs-input"
          type="input"
          className="product-input-field"
          key={`product-${productId}`}
          name={`product-${productId}`}
          value={getData === '0.0' ? '' : getData}
          onChange={(e) => setGetData(utils.allowDollarChars(e.target.value))}
        />
      </styles.InputFieldContainer>
    </styles.ProductTableCellWithInput>
  );
}
