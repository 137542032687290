import { push } from 'connected-react-router';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { userStateSelectors } from 'rsv8-auth';
import { Button } from 'rsv8-components';
import { Field, formSelectors, reduxForm } from 'rsv8-forms';
import {
  register,
  withContent,
  withResource,
  withTheme
  } from 'xcel-react-core';
import contactUsActions from '../../redux/actions/contactUs';
interface State {
  submitError: string;
}

const validate = (values) => {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  let errors = {} as any;

  if (!values.message) {
    errors.message = 'please enter a comment';
  }
  if (!values.phone) {
    errors.phone = 'please enter a phone number';
  }
  if (!values.email) {
    errors.email = 'please enter an email';
  } else if (!regex.test(values.email)) {
    errors.email = 'email address is invalid'
  }
  return errors;
};

class ContactForm extends React.Component<any, State> {

  state = { submitError: '' };

  submitForm = async ({ phone, message, email }) => {
    const { dispatch, successRedirectPath, submitErrorMessage } = this.props;
    let infoObject = { phone, message, email };
    try {
      await dispatch(contactUsActions.create({ body: infoObject }));
      dispatch(push(successRedirectPath));
    } catch (e) {
      this.setState({ submitError: `${submitErrorMessage} ${(e as any).response.status}` });
    }
  };

  render() {
    const { handleSubmit, initialValues, phone, comments, submitButton, firstName, lastName, email } = this.props;

    return (
      <form onSubmit={handleSubmit(this.submitForm)}>

        {this.state.submitError !== '' && <Alert bsStyle="danger">{this.state.submitError}</Alert>}

        <Row>
          <Col sm={12}>
            <Field component={'bs-input'} name="firstName" disabled="true" label={firstName} />
          </Col>
          <Col sm={12}>
            <Field component={'bs-input'} name="lastName" disabled="true" label={lastName} />
          </Col>
        </Row>
        <Row>
          <Col sm={24}>
            <Field
              component={'bs-input'}
              name="email"
              disabled={initialValues.email !== '' && initialValues.email !== null}
              label={email}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={24}>
            <Field component={'bs-input'} name="phone" type="tel" label={phone} />
          </Col>
        </Row>
        <Row>
          <Col sm={24}>
            <Field component={'bs-textarea'} name="message" label={comments} />
          </Col>
        </Row>
        <Button type="submit">
          {submitButton}
        </Button>
      </form>
    );
  }
}

const mapState = (state) => {
  const user = userStateSelectors.getUser(state);
  return {
    formErrors: formSelectors.getFormSyncErrors('ContactForm')(state),
    hasSubmitFailed: formSelectors.hasSubmitFailed('ContactForm')(state),
    initialValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.emailAddress
    }
  };
};

const mapContentToProps = (getContent) => ({
  successRedirectPath: getContent('successRedirectPath', { type: 'none', label: 'URL to direct to after success' })
});

const mapResourceToProps = (getResource) => ({
  firstName: getResource('contactUs.firstName', 'First Name'),
  lastName: getResource('contactUs.lastName', 'Last Name'),
  email: getResource('contactUs.email', 'Email'),
  phone: getResource('contactUs.phone', 'Phone'),
  comments: getResource('contactUs.comments', 'Comments'),
  submitButton: getResource('contactUs.submitButton', 'Submit'),
  submitErrorMessage: getResource('contactUs.submitErrorMessage', 'Something went wrong. error code:'),
});

export default register('rsv8-app/ContactForm')(
  connect(mapState),
  withResource(mapResourceToProps),
  withContent(mapContentToProps),
  withTheme(),
  reduxForm({
    form: 'ContactForm',
    validate
  })
)(withRouter(ContactForm));
