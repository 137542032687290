import { IndividualNomination } from '../../../../types/index';
import * as events from '../../../events';
import { individualNominationSelectors } from '../../../selectors/index';
import { clearCurrentNomination } from '../nomineeSave/index';

export const selectProductOptionPointValueBase = (appKey): IndividualNomination.Action => {
  return {
    type: events.SELECT_PRODUCT_OPTION_POINT_VALUE,
    payload: appKey
  };
};

export const setIndividualNominationPointValueId = (value) => ({
  type: events.SET_INDIVIDUAL_NOMINATION_PRODUCT_VALUE,
  payload: value
});

const selectProductOptionPointValue = (value) => (dispatch, getState) => {
  const state = getState();
  if (individualNominationSelectors.getCurrentProductId(state) !== value) {
    dispatch(clearCurrentNomination());
    dispatch(selectProductOptionPointValueBase(value));
  }
};

export default selectProductOptionPointValue;
