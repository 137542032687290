import { flatten } from 'ramda';
import { flatToHierarchy, toggleValue } from 'xcel-util';
import * as events from '../../events';

const initialState = {
  currentResults: [],
  currentQuery: {},
  showBrandsModal: false,
  showCategoriesModal: false
};

export default function setCurrentProductsSearchReducer(state: any = initialState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case events.SET_CURRENT_PRODUCT_SEARCH:
      const categories =
        payload.included.filter((obj) => Object.keys(obj).indexOf('categories') > -1)[0].categories || [];
      return {
        ...state,
        ...payload.meta,
        currentResults: payload.data.map((result) => result.id),
        categoryFilters: flatten(flatToHierarchy(categories).map((category) => category.children)),
        brandFilters: payload.included.reduce((key) => {
          if (key && key.hasOwnProperty('manufacturers')) {
            return key;
          }
        }).manufacturers
      };
    case events.SET_CURRENT_SEARCH_QUERY:
      return {
        ...state,
        currentQuery: {
          ...state.currentQuery,
          ...payload
        }
      };
    case events.UPDATE_SEARCH_RESULTS:
      return {
        ...state,
        ...payload.meta,
        currentResults: state.currentResults.concat(payload.data.map((result) => result.id))
      };
    case events.SET_BRAND_CHANGE:
      let brandArray = toggleValue(payload, state.currentQuery.filterBrands || []).slice(-1)[0];
      if (Array.isArray(payload)) {
        brandArray = payload.length > 0 ? payload : undefined;
      } else {
        brandArray = brandArray.length > 0 ? brandArray : undefined;
      }

      return {
        ...state,
        currentQuery: {
          ...state.currentQuery,
          filterBrands: brandArray
        }
      };
    case events.SET_CATEGORY_CHANGE:
      return {
        ...state,
        currentQuery: {
          ...state.currentQuery,
          filterCategories: payload
        }
      };
    case events.SET_POINT_RANGE_CHANGE:
      return {
        ...state,
        currentQuery: {
          ...state.currentQuery,
          ...payload
        }
      };
    case events.RESET_POINT_RANGE_FILTER:
      let resetQuery = state.currentQuery;
      delete resetQuery.filterMinPoints;
      delete resetQuery.filterMaxPoints;

      return {
        ...state,
        currentQuery: resetQuery
      };
    case events.SET_SEARCH_SORT:
      return {
        ...state,
        currentQuery: {
          ...state.currentQuery,
          pagingSortOrder: 'PointValue',
          pagingSortDirection: payload
        }
      };
    case events.RESET_SEARCH_SORT:
      let newQuery = state.currentQuery;
      delete newQuery.pagingSortOrder;
      delete newQuery.pagingSortDirection;

      return {
        ...state,
        currentQuery: newQuery
      };
    case events.RESET_SEARCH_RESULTS:
      return {
        ...state,
        currentResults: [],
        currentQuery: {},
        totalResults: 0
      };
    case events.RESET_SEARCH_FILTERS:
      return {
        ...state,
        currentQuery: {
          filterQuery: state.currentQuery.filterQuery
        }
      };

    case events.RESET_SEARCH_TOTAL:
      return {
        ...state,
        totalProducts: undefined
      };
    case events.BRANDS_TOGGLE:
      return {
        ...state,
        showBrandsModal: !state.showBrandsModal
      };
    case events.CATEGORIES_TOGGLE:
      return {
        ...state,
        showCategoriesModal: !state.showCategoriesModal
      };
    default:
      return {
        ...state
      };
  }
}
