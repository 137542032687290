import { ClientCountryModel, ClientCultureModel, ClientModel } from 'xcel-api-generator/dist/user';
import { createTypeSelector } from 'xcel-redux-orm';
import { Resource } from 'xcel-redux-orm';
const clientSelector = {
  ...createTypeSelector<ClientModel>('clientModel'),
  getActive: (state) => clientSelector.selectMany(state, null)[0]
};

export { ClientModel, ClientCountryModel, ClientCultureModel, Resource };
export default clientSelector;
