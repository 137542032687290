import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { Drawer } from 'rsv8-components';
import { Form, formSelectors, reduxForm } from 'rsv8-forms';
import styled from 'styled-components';
import myAwardFilterSelector from '../../../redux/selectors/myAwardFilter';
import FilterButtons from './FilterButtons';
import FilterDateRange from './FilterDateRange';
import FilterSelectType from './FilterSelectType';
import FilterTitle from './FilterTitle';

const FILTER_FORM_NAME = 'awardsFilter';
const StyledForm = styled(Form)`
  padding: 0px 10px;
`;
type FilterProps = {
  values: any;
  reset: any;
  actions: any;
  clickClear: any;
  clickApply: any;
  selectList: any;
};

type FilterState = {
  displayModal: boolean;
  startDate: any;
  endDate: any;
  hasErr: FilterErr;
};

type FilterErr = {
  err: boolean;
  msg?: string;
};

class Filter extends React.Component<FilterProps, FilterState> {
  constructor(props: FilterProps) {
    super(props);
    this.state = {
      displayModal: false,
      startDate: undefined,
      endDate: undefined,
      hasErr: { err: false }
    };
  }

  /**
   * CHECK FORM
   */
  checkForm = (name, value) => {
    // DATES
    if (name === 'awardDateStart') {
      this.setState(
        {
          startDate: value
        },
        () => {
          this.isValidDates();
        }
      );
    }

    if (name === 'awardDateEnd') {
      this.setState(
        {
          endDate: value
        },
        () => {
          this.isValidDates();
        }
      );
    }
  };

  /**
   * IS VALID DATES
   */
  isValidDates = () => {
    let theStartDateGreaterThanEndDate =
      this.state.startDate > this.state.endDate
        ? { err: true, msg: 'Start Date is after End Date', field: 'start' }
        : { err: false, msg: 'All fields are valid', field: 'none' };

    let isStartDateValid =
      this.state.startDate === undefined
        ? { err: false, msg: 'Start Date not filled yet', field: 'none' }
        : moment(this.state.startDate, 'MM/DD/YYYY', true).isValid()
          ? { err: false, msg: 'All fields are valid', field: 'none' }
          : { err: true, msg: 'Start Date is Invalid', field: 'start' };

    let isEndDateValid =
      this.state.endDate === undefined
        ? { err: false, msg: 'End Date not filled yet', field: 'none' }
        : moment(this.state.endDate, 'MM/DD/YYYY', true).isValid()
          ? { err: false, msg: 'All fields are valid', field: 'none' }
          : { err: true, msg: 'End Date is Invalid', field: 'end' };

    let validationErr;
    let allAreValid = { err: false, msg: 'All fields are valid', field: 'none' };

    switch (true) {
      case theStartDateGreaterThanEndDate.err:
        validationErr = theStartDateGreaterThanEndDate;
        break;
      case isStartDateValid.err:
        validationErr = isStartDateValid;
        break;
      case isEndDateValid.err:
        validationErr = isEndDateValid;
        break;
      default:
        validationErr = allAreValid;
        break;
    }

    this.setState({
      hasErr: validationErr
    });
  };

  /**
   * HANDLE FORM RESET
   */
  handleReset = (formProps) => {
    this.setState(
      {
        startDate: undefined,
        endDate: undefined,
        hasErr: { err: false }
      },
      () => {
        formProps.reset(FILTER_FORM_NAME);
      }
    );
    document.body.style.overflowY = 'visible';
  };

  closeDrawer = () => {
    this.setState({
      displayModal: false
    });
  };

  openDrawer = () => {
    this.setState({
      displayModal: true
    });
  };

  render() {
    return (
      <Drawer
        onOpen={this.openDrawer}
        isOpen={this.state.displayModal}
        start={767}
        buttonText="Filter"
        buttonIcon="filter"
      >
        <StyledForm onSubmit={() => ({})}>
          <FilterTitle />
          <FilterDateRange hasErr={this.state.hasErr} checkForm={this.checkForm} />
          <FilterSelectType selectList={this.props.selectList} />
          <FilterButtons
            disabled={this.state.hasErr.err}
            actions={this.props.actions}
            clickApply={this.props.clickApply}
            clickClear={this.props.clickClear}
            handleReset={this.handleReset}
            handleClose={this.closeDrawer}
            reset={this.props.reset}
            values={this.props.values}
          />
        </StyledForm>
      </Drawer>
    );
  }
}

const mapStateToProps = (state) => {
  const filter = myAwardFilterSelector.selectOne(state, 'applicationKey');
  return {
    values: formSelectors.getFormValues(FILTER_FORM_NAME)(state),
    selectList: filter && filter.data
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default reduxForm({ form: FILTER_FORM_NAME })(connect(
  mapStateToProps,
  mapDispatchToProps
)(Filter) as any) as any;
