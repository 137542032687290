import {
  ChildRouteInputModel,
  ContentCultureInputModel,
  ContentInputModel,
  ContentTypeModel,
  DataRuleGroupModel,
  DataRuleModel,
  VersionInputModel,
} from 'xcel-api-generator/dist/cms';
import { mapJsonApiActions } from 'xcel-redux-orm';
import { cmsAdminApi } from '../../api';
const cmsAdminApiActions = mapJsonApiActions(cmsAdminApi);
export {
  ChildRouteInputModel,
  ContentCultureInputModel,
  ContentInputModel,
  ContentTypeModel,
  DataRuleModel,
  DataRuleGroupModel,
  VersionInputModel,
};
export default cmsAdminApiActions;
