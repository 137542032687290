import { push } from 'connected-react-router';
import * as React from 'react';
import { NavDropdown as BSNavDropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { touchDetect } from 'xcel-util';
import { MenuContext } from '../ModularMenus/NavigationContext';

const StyledBSNavDropDown = styled(BSNavDropdown)`
  ${(props: any) => props.backgroundImage && `background-image: url("${props.backgroundImage}");`};
  display: none;
` as any;

interface NavDropdownProps {
  dispatch: any;
  id: any;
  className: any;
  title: any;
  targetUrl: any;
}

interface NavDropdownState {
  isOpen: boolean;
}

class NavDropdown extends React.Component<NavDropdownProps, NavDropdownState> {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }

  render() {
    return (
      <MenuContext.Consumer {...this.props}>
        {({ isOpen, open, close, closeAll }: any) => {
          const handleClick = (href) => (e) => {
            if (touchDetect() === false) {
              this.props.dispatch(push(href));
              closeAll();
            } else {
              if (isDropdownDropped() === false) {
                open(this.props.id);
              } else {
                closeAll();
              }
            }
          };

          const handleMouseEnter = () => {
            touchDetect() ? null : open(this.props.id);
          };

          const handleMouseLeave = () => {
            touchDetect() ? null : close(this.props.id);
          };

          const isDropdownDropped = () => {
            return isOpen(this.props.id);
          };

          return (
            <StyledBSNavDropDown
              className={this.props.className && this.props.className}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              open={isDropdownDropped()}
              title={this.props.title}
              onClick={handleClick(this.props.targetUrl)}
            >
              {this.props.children}
            </StyledBSNavDropDown>
          );
        }}
      </MenuContext.Consumer>
    );
  }
}

export default connect()(NavDropdown) as any;
