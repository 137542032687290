import { getConfigurationProperty } from 'xcel-config';
import { cacheService } from 'xcel-cache-service';
import { getEnvironment } from 'xcel-env';
import { parseTokens } from 'xcel-util';

const user = cacheService.get('rsv8-auth/user');
const client = cacheService.get('rsv8-client');

let clientId = undefined;
if (user && parseInt(user.clientId, 10) !== 0) {
  clientId = user.clientId;
} else if (client && client.id) {
  clientId = client.id;
}

export const setClient = (activeClientId) => {
  console.log('setClient', activeClientId);
  clientId = activeClientId;
};

const getBaseUrl = () => {
  const baseUrl = getConfigurationProperty(['assetUrl']);
  return baseUrl && parseTokens(baseUrl, window.location);
};

const getAssetPath = (url, path) => {
  const baseUrl = getBaseUrl();
  if (!clientId || !url) {
    return url;
  }
  if (shouldUpdate(url)) {
    const assetRelativePath = `/clientassets/${clientId}/${path}/`;
    const assetRelativeRegex = new RegExp(`\/clientassets\/\\d+\/${path}\/`, 'g');
    const assetDoubleSlashRegex = /\/\//g;
    return `${baseUrl}${(assetRelativePath + url.replace(assetRelativeRegex, '')).replace(assetDoubleSlashRegex, '/')}`;
  }
  return url;
};

const getAssetService = (guid) => {
  const environment = getEnvironment(location.host);
  switch (environment) {
    case 'local':
      return `http://api.rewardstation-test.net/clientasset/assets/${guid}`;
    case 'qa':
      return `http://api.rewardstation-test.net/clientasset/assets/${guid}`;
    case 'uat':
      return `http://api.rewardstation.net/clientasset/assets/${guid}`;
    case 'live':
      return `http://api.rewardstation.com/clientasset/assets/${guid}`;
    default:
      return `http://api.rewardstation.com/clientasset/assets/${guid}`;
  }
};

const shouldUpdate = (url) => {
  const externalLinkRegex = /^(https?:\/\/|mailto?:|\/clientassets\/images\/)/;
  return !externalLinkRegex.test(url);
};

const assetService = {
  getFont: (url: string) => {
    return getAssetPath(url, 'fonts');
  },
  getImage: (url: string) => {
    return getAssetPath(url, 'images');
  },
  getAsset: (guid: string) => {
    return getAssetService(guid);
  },
};

export default assetService;
