import * as React from 'react';
import {
  Alert,
  Col,
  Grid,
  Row
  } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PageRenderer } from 'rsv8-cms';
import { toast } from 'rsv8-components';
import { withWizard, WithWizardProps } from 'rsv8-wizard';
import styled from 'styled-components';
import { register, withResource } from 'xcel-react-core';
import NominationCollapse from '../../components/NominationCollapse/index';
import { ErrorFormContainer } from '../../components/SearchBar/SearchBar';
import { individualNominationActions } from '../../redux/actions/index';
import { setClearNominationData } from '../../redux/actions/individual/nomineeSave/index';
import { individualNominationSelectors } from '../../redux/selectors/index';
import { Resources } from '../../types';
import ProductSelection from './ProductSelection';

const { selectProduct, getProductDetails, selectProductOptionPointValue } = individualNominationActions;

const ProductWrapper = styled.div`
  padding-top: 50px;
` as any;

const ColFlexCentered = styled(Row)`
  display: block;
  width: 100%;
  text-align: center;
` as any;

export interface ProductPageProps extends WithWizardProps {
  currentProduct: string;
  products: Array<Resources.Product>;
  errorText: string;
  buttonTitle: string;
  product: any;
  selectedOptionId: string;
  award: {
    incentiveType: string;
  };
  actions: {
    selectProduct: (productId: string | number, confirmText: string) => void;
    getProductDetails: (productId: string, nominee: number) => void;
    selectProductOptionId: (optionId: string) => void;
    selectProductValue: (optionPointValue: number) => void;
    setClearNominationData: (value: boolean) => void;
  };
  nominee: any;
  productFromDraft: boolean;
  clearNominationData: string;
}

interface ProductPageState {
  hasError: boolean;
  showModal: boolean;
}
class ProductPage extends React.Component<ProductPageProps, ProductPageState> {
  constructor(props: ProductPageProps) {
    super(props);
    this.state = { hasError: false, showModal: false };
  }

  componentDidMount() {
    const { wizard, award, actions, clearNominationData } = this.props;
    if (wizard) {
      wizard.registerIncrement(this.checkValid);
    }

    if (award && award.incentiveType !== 'Product') {
      this.skipWizardStep();
    }

    actions.setClearNominationData(clearNominationData === 'true');
  }

  skipWizardStep = () => {
    const { wizard } = this.props;
    if (wizard) {
      wizard.removeWizardStep();
      wizard.increment();
    }
  };

  shouldComponentUpdate(nextProps: ProductPageProps, nextState: ProductPageState) {
    return (
      this.props.products.length !== nextProps.products.length ||
      (nextState.hasError && !this.state.hasError) ||
      nextState.showModal !== this.state.showModal
    );
  }

  /**
   * checkValid validates inputs to continue to next step in the Wizard
   * @return {Promise}
   */
  checkValid = () => {
    const { product, selectedOptionId, productFromDraft, award } = this.props;
    if (award && award.incentiveType !== 'Product') {
      this.setState({ hasError: false });
      return Promise.resolve();
    }

    if (!product) {
      this.setState({ hasError: true });
      return Promise.reject('Please select a product!');
    }

    if (product && (productFromDraft ? false : product.options && product.options.length > 0 && !selectedOptionId)) {
      toast({
        template: 'ContentToast',
        contentTemplate: (
          <PageRenderer
            path={'toast/nomination/individual/optionError'}
            onNotFound={() => <PageRenderer path={`toast/nomination/individual/error`} cultureId="0" />}
          />
        )
      });
      return Promise.reject('Please select a product!');
    }
    this.setState({ hasError: false });
    return Promise.resolve();
  };

  render() {
    const { products, nominee, buttonTitle, errorText, currentProduct } = this.props;
    return (
      <Grid>
        <Row>
          <Col lgOffset={2} mdOffset={2} lg={20} md={20} xs={24}>
            <ColFlexCentered>
              <PageRenderer path={`partial/nomination/individual/recognition/header/awardSelectionWizard`} />
            </ColFlexCentered>
          </Col>
        </Row>
        <Row>
          <Col>
            {this.state.hasError && !currentProduct ? (
              <ErrorFormContainer>
                <Alert bsStyle="danger">{errorText}</Alert>
              </ErrorFormContainer>
            ) : null}
            <ProductWrapper>
              <ProductSelection products={products} nominee={nominee} />
              <NominationCollapse title={buttonTitle}>
                <PageRenderer path={`partial/nomination/individual/productApprovalDetails`} />
              </NominationCollapse>
            </ProductWrapper>
          </Col>
        </Row>
      </Grid>
    );
  }
}

/**
 * products assigned by Redux Selector to pass products from the backend to ProductSelection component above.
 * @param {STATE} arg
 */
const mapState = (state) => {
  const products = individualNominationSelectors.getProducts(state);
  const award = individualNominationSelectors.getCurrentAward(state);

  let currentProductOptionId = individualNominationSelectors.getCurrentOptionId(state);
  let currentProductId = individualNominationSelectors.getCurrentProductId(state);
  let product = individualNominationSelectors.getCurrentProduct(state);
  let productFromDraft = null;
  if (!currentProductId) {
    const info = individualNominationSelectors.getProductInfoFromDraft(state);
    if (info && info.product) {
      productFromDraft = info.product;
      product = productFromDraft;
      currentProductId = info.productId;
      currentProductOptionId = info.optionId;
    }
  }

  return {
    selectedOptionId: currentProductOptionId,
    selectedProduct: currentProductId,
    product: product,
    productFromDraft: !!productFromDraft,
    nominee: individualNominationSelectors.getCurrentNomineeId(state),
    products: !Array.isArray(products) ? [products] : products,
    award: Array.isArray(award) ? award[0] : award
  };
};

const mapDispatch = (dispatch) => ({
  actions: bindActionCreators(
    { selectProduct, getProductDetails, selectProductOptionPointValue, setClearNominationData },
    dispatch
  )
});

const mapResource = (getResource) => ({
  errorText: getResource('individualNomination.selectProductError', 'Please Select a Product'),
  buttonTitle: getResource('nominationProcess.productDetailsButtonTitle', 'Product Approval Details'),
  clearNominationData: getResource('nominationProcess.clearNominationData', 'false')
});
/**
 * Registers IndividualSelectProduct to be used on this page.
 */
const compose = register('rsv8-nomination/IndividualSelectProduct');

export default compose(connect(mapState, mapDispatch), withResource(mapResource), withWizard())(ProductPage);
