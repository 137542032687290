import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, toast } from 'rsv8-components';
import { ConsumerProductsUpdate, CustomerProduct, ForecastRequestMailData, Performance, Program } from 'xcel-api-generator/dist/alcon';
import { assetService } from 'xcel-asset-service';
import { register, withContent, withResource, withTheme } from 'xcel-react-core';
import { withApiDataProvider } from 'xcel-redux-orm';
import * as utils from '../../../utils';
import { getMarketSharePercent } from '../../../utils';
import { ProgramName } from '../../constants';
import {
  createProductCalculate,
  createProductForecast,
  createProductSave,
  getProductSummary,
  getProgram,
  getSummary,
} from '../../redux/actions';
import { customerProductSelector, dashboardSelector, performanceSelector, programSelector } from '../../redux/selectors';
import AutoPopulateModal from './AutoPopulateModal';
import ForecastButtonPanel from './ForecastButtonPanel';
import RebateCalculatorSharingModal from './RebateCalculatorSharingModal';
import ScrollableProductsPanel from './ScrollableProductsPanel';
import ShareGoalInput from './ShareGoalInput';
import SummaryStatisticsPanel from './SummaryStatisticsPanel';
import * as styles from './styles';

const SaveButtonConstant = 'SAVE';
const CalcButtonConstant = 'CALC';
const ShareButtonConstant = 'SHARE';
const SaveAutoPopulateConstant = 'AUTO';
let buttonClicked = null;

function handleCalcButton() {
  buttonClicked = CalcButtonConstant;
}

function handleShareButton() {
  buttonClicked = ShareButtonConstant;
}

function handleSaveButton() {
  buttonClicked = SaveButtonConstant;
}

function handleAutoPopulate() {
  buttonClicked = SaveAutoPopulateConstant;
}

const RebateCalculator: React.FC<Props> = ({
  actions,

  customerProducts,
  program,
  performance,

  autoPopulateCalculator,

  reCalculateButton,
  saveForecastButton,
  shareForecastButton,
  saveOrshareForecastTitle,
  saveOrshareForecastDescription,
  saveForecastTitle,
  saveForecastDescription,
  saveForecastNote,
  infoButtonText,
  autoPopulateModalOptions = ['prior-quarter', 'run-rate', 'target'],
}) => {
  const [showSharingModal, setshowSharingModal] = React.useState<boolean>(false);
  const [showAutoPopulateModal, setShowAutoPopulateModal] = React.useState<boolean>(false);
  const [calculatorValues, setCalculatorValues] = React.useState<ConsumerProductsUpdate>(null);
  const [target, setTarget] = React.useState<number>(undefined);
  const [marketShare, setMarketShare] = React.useState<string>(getMarketSharePercent(performance.target, '0.0'));

  React.useEffect(() => {
    actions.getProductSummary();
  }, []);

  React.useEffect(() => {
    setMarketShare(getMarketSharePercent(performance.target, '0.0'));
  }, [performance]);

  async function handleHideAutoPopulateModal() {
    await actions.getProductSummary();
    setShowAutoPopulateModal(false);
  }

  async function handleSharing(modalData: ForecastRequestMailData) {
    await actions.createProductSave(calculatorValues);
    return await actions.createProductForecast(modalData);
  }

  function handleEnter(e: any) {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleCalcButton();
      handleSubmit(e);
    }
  }

  async function handleSubmit(event: any) {
    event.preventDefault();

    const productsUpdate: ConsumerProductsUpdate = {
      summary: {
        target: undefined,
        marketShareGoal: undefined,
      },
      details: [],
    };

    const formEl = document.querySelector<HTMLFormElement>('form.calculatorForm');
    if (formEl == null || formEl.elements == null) return;

    for (let i = 0; i <= formEl.elements.length - 1; i++) {
      const formElement = formEl.elements[i] as HTMLInputElement;
      if (formElement == null || formElement.tagName.toLowerCase() != 'input') continue;

      const productInputId = utils.toLowerCase(formElement.name).replace('product-', '');
      if (productInputId == null || productInputId == '') continue;

      const productId = parseInt(productInputId, 10);
      const productInputValue = Number(formElement.value);
      if (productId === 0) {
        // if id = 0 then this is for summary
        productsUpdate.summary.target = productInputValue;
      } else if (productInputId === 'ytd-share-goal') {
        productsUpdate.summary.marketShareGoal = productInputValue;
      } else if (productId > 0) {
        productsUpdate.details.push({
          id: productId,
          customUnits: productInputValue,
        });
      }
    }

    let actionText: string,
      actionName: string,
      actionManufacturer: string,
      actionImage: string = '';

    try {
      switch (buttonClicked) {
        case CalcButtonConstant:
          await actions.createProductCalculate(productsUpdate);
          actionImage = assetService.getImage('icons/blue-calculator.png');
          actionText = 'Re-calculation successful! Remember to save your forecasts!';
          actionName = '';
          actionManufacturer = 'Re-Calculate Rebate';
          break;
        case SaveButtonConstant:
          await actions.createProductSave(productsUpdate);
          actionImage = assetService.getImage('icons/save-calculation.png');
          actionText = 'Forecast save successful!';
          actionName = '';
          actionManufacturer = 'Save Forecast';
          break;
        case ShareButtonConstant:
          setCalculatorValues(productsUpdate);
          setshowSharingModal(true);
          break;
        case SaveAutoPopulateConstant:
          setTarget(productsUpdate.summary.target);
          setShowAutoPopulateModal(true);
          break;
        default:
          break;
      }
    } catch (error) {
      actionText = 'Error performing action. Please contact the administrator.';
    }

    if (!!actionText) {
      toast({
        template: 'CatalogBody',
        productImage: actionImage,
        productName: actionName,
        productManufacturer: actionManufacturer,
        actionText: actionText,
      });
    }

    buttonClicked = null;
  }

  if (!program || !customerProducts || customerProducts.length === 0) {
    return null;
  }

  const summaryData = customerProducts.filter((item) => item.id === 0);
  const productsData = customerProducts.filter((item) => item.id !== 0);
  if (summaryData.length === 0 || productsData.length === 0) {
    return null;
  }

  return (
    <styles.CalculatorMarginTop>
      <styles.CalculatorContainer>
        <styles.RebateCalculatorForm className="calculatorForm" onSubmit={handleSubmit} onKeyPress={handleEnter}>
          <styles.HiddenReCalculateButton onClick={handleCalcButton} />

          {autoPopulateModalOptions && (
            <styles.AutoPopulateContainer>
              <Button themeVariation="primary" onClick={handleAutoPopulate}>
                {autoPopulateCalculator}
              </Button>

              {(ProgramName.match(ProgramName.MarketShare, program) || ProgramName.match(ProgramName.TotalPartner, program)) && (
                <ShareGoalInput marketShare={marketShare} setMarketShare={setMarketShare} />
              )}

              <AutoPopulateModal
                show={showAutoPopulateModal}
                handleHide={handleHideAutoPopulateModal}
                target={target}
                activeOptions={autoPopulateModalOptions}
              />
            </styles.AutoPopulateContainer>
          )}

          <SummaryStatisticsPanel
            infoButtonText={infoButtonText}
            reCalculateButton={reCalculateButton}
            handleCalcButton={handleCalcButton}
          />

          <ScrollableProductsPanel reCalculateButton={reCalculateButton} handleCalcButton={handleCalcButton} />

          <ForecastButtonPanel
            saveForecastButton={saveForecastButton}
            shareForecastButton={shareForecastButton}
            saveOrshareForecastTitle={saveOrshareForecastTitle}
            saveOrshareForecastDescription={saveOrshareForecastDescription}
            saveForecastTitle={saveForecastTitle}
            saveForecastDescription={saveForecastDescription}
            saveForecastNote={saveForecastNote}
            handleSaveButton={handleSaveButton}
            handleShareButton={handleShareButton}
          />
        </styles.RebateCalculatorForm>
      </styles.CalculatorContainer>

      {showSharingModal && (
        <RebateCalculatorSharingModal show={showSharingModal} handleShare={handleSharing} handleHide={(e) => setshowSharingModal(false)} />
      )}
    </styles.CalculatorMarginTop>
  );
};

const mapStateToProps = (state) => ({
  customerProducts: customerProductSelector.selectMany(state),
  program: programSelector.selectMany(state)[0],
  performance: performanceSelector.selectMany(state)[0],
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ createProductCalculate, createProductSave, createProductForecast, getProductSummary }, dispatch),
});

const mapResourceToProps = (getResource) => ({
  autoPopulateCalculator: getResource('rebateCalculator.autoPopulateCalculator', 'AUTO-POPULATE CUSTOM'),
});

const mapContentToProps = (getContent) => ({
  saveForecastButton: getContent('saveForecastButton', { type: 'string', label: 'Save for Forecast Button Title' }),
  shareForecastButton: getContent('shareForecastButton', { type: 'string', label: 'Share for Forecast Button Title' }),
  reCalculateButton: getContent('reCalculateButton', { type: 'string', label: 'Re-Calculate Button Title' }),
  saveOrshareForecastTitle: getContent('saveOrshareForecastTitle', { type: 'string', label: 'Save Or Share Forecasts Title' }),
  saveForecastTitle: getContent('saveForecastTitle', { type: 'string', label: 'Save Forecasts Title' }),
  infoButtonText: getContent('infoButtonText', { type: 'string', label: 'Info button text' }),
  saveOrshareForecastDescription: getContent('saveOrshareForecastDescription', {
    type: 'string',
    label: 'Save Or Share Forecasts Description',
  }),
  saveForecastDescription: getContent('saveForecastDescription', { type: 'string', label: 'Save Forecasts Description' }),
  saveForecastNote: getContent('saveForecastNote', { type: 'string', label: 'Save Forecasts Note' }),
  autoPopulateModalOptions: getContent('autoPopulateModalOptions', { type: 'array', label: 'Auto Populate Modal Options' }),
});

interface StateProps {
  customerProducts: CustomerProduct[];
  program: Program;
  performance: Performance;
}
interface DispatchProps {
  actions: {
    createProductCalculate: typeof createProductCalculate;
    createProductSave: typeof createProductSave;
    createProductForecast: typeof createProductForecast;
    getProductSummary: typeof getProductSummary;
  };
}
interface ResourceProps {
  autoPopulateCalculator: string;
}

interface ContentProps {
  reCalculateButton: string;
  saveForecastButton: string;
  shareForecastButton: string;
  saveOrshareForecastTitle: string;
  saveOrshareForecastDescription: string;
  saveForecastTitle: string;
  saveForecastDescription: string;
  saveForecastNote: string;
  infoButtonText: string;
  autoPopulateModalOptions: string[];
}

type Props = StateProps & DispatchProps & ResourceProps & ContentProps;

export default register('alcon-calculator/RebateCalculator')(
  connect(mapStateToProps, mapDispatchToProps),
  withApiDataProvider(getProgram, programSelector),
  withApiDataProvider(getSummary, dashboardSelector),
  withContent(mapContentToProps),
  withResource(mapResourceToProps),
  withTheme()
)(RebateCalculator);
export { RebateCalculator };

