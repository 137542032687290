import {
  ConfigProps,
  DecoratedComponentClass,
  InjectedFormProps,
  reducer as formReducer,
  reduxForm as originalReduxForm
  } from 'redux-form';

import * as actions from './actions';
import formSelectorService from './selectors';

const REDUCER_NAME = 'rsv8-forms/form';
const getFormState = (state) => state[REDUCER_NAME];

// Create the reducers
const reducers = {
  [REDUCER_NAME]: formReducer
} as any;

export type ReduxFormHOC = (
  config: ConfigProps
) => (
  component: React.ComponentClass<InjectedFormProps<{}, {}>> | React.StatelessComponent<InjectedFormProps<{}, {}>>
) => DecoratedComponentClass<{}, Partial<ConfigProps<{}, {}>>,{}>;

// Modified reduxForm HOC
const reduxForm: ReduxFormHOC = (config) => originalReduxForm({ ...config, getFormState });

// Create Selectors
const formSelectors = formSelectorService(REDUCER_NAME);

export { reducers, formSelectors, reduxForm, actions };
