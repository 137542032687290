import * as React from 'react';
import { connect } from 'react-redux';
import { ReactField } from 'rsv8-forms';
import { getValue } from 'xcel-util';
import cmsPluginSelectors from '../../../redux/cms.plugin.selectors';
import { componentVariationSelect } from '../../../redux/component.actions';
import { getTheme } from '../../../redux/theme.selectors';

export interface ComponentVariationSelectProps {
  name: string;
  theme: any;
  currentStyle: any;
  onChange?: any;
  dispatch: any;
}
const ComponentVariationSelect = ({
  name,
  theme,
  currentStyle,
  onChange,
  dispatch,
  ...props
}: ComponentVariationSelectProps) => {
  name = name.replace(/\./gm, '/').replace(/\=\>/gm, '.');
  const themeComponentStyles = getValue(theme, `${name}.styles`);
  const options = themeComponentStyles
    ? Object.keys(themeComponentStyles)
        .sort()
        .map((key) => ({ value: key, label: key }))
    : [];
  const handleChange = (value) => {
    dispatch(componentVariationSelect(value));
    if (onChange) {
      onChange(value);
    }
  };
  return <ReactField value={currentStyle} options={options} component="bs-select" onChange={handleChange} />;
};
const mapStateToProps = (state) => ({
  currentStyle: getTheme(state).currentStyle,
  theme: cmsPluginSelectors.getTheme(state)
});

export default connect(mapStateToProps)(ComponentVariationSelect);
