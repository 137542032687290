import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { ReactField } from 'rsv8-forms';
import { CustomerProduct, Patient } from 'xcel-api-generator/dist/alcon';
import { withResource } from 'xcel-react-core';
import { convertCustomerProductToPatient, getValueOrZero, isNumber } from '../../../utils';
import { updatePatient } from '../../redux/actions';
import { ProjectionValue, StyledNames } from './styles';

const ProductComponent: React.FC<Props> = ({ actions: { updatePatient }, resource: { patientsLabel }, product }) => {
  const [value, setValue] = React.useState<string>();

  React.useEffect(() => {
    const patient = convertCustomerProductToPatient(product);
    setValue(patient.patients.toFixed(1));
  }, [product]);

  const onChange = (value: string) => {
    setValue(value);

    const patient: Patient = {
      productFamilyKey: product.familyKey,
      productTypeKey: product.productTypeKey,
      patients: getValueOrZero(value),
    };
    updatePatient(patient);
  };

  return (
    <div className="product-type">
      <div className="product-type-name">{product.productTypeKey}</div>

      <div className="product-type-value">
        <StyledNames className="patients-label" themeVariation="my-dashboard" fontSize={16} lineHeight={18}>
          {patientsLabel}
        </StyledNames>

        <ProjectionValue className="patients-value">
          <ReactField
            component="bs-input"
            value={value}
            validate={(value) => (!isNumber(value) ? 'should be a number' : null)}
            onChange={onChange}
          />
        </ProjectionValue>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ updatePatient }, dispatch),
});
const mapResourceToProps = (getResource) => ({
  resource: {
    patientsLabel: getResource('alcon.dashboard.productBreakdown.patientsLabel', 'Patients'),
  },
});

type OwnProps = {
  product: CustomerProduct;
};
interface DispatchProps {
  actions: {
    updatePatient: Function;
  };
}
interface ResourceProps {
  resource: {
    patientsLabel: string;
  };
}
type Props = DispatchProps & ResourceProps & OwnProps;

export default compose<React.ComponentType<OwnProps>>(
  withResource(mapResourceToProps),
  connect<null, DispatchProps, OwnProps>(null, mapDispatchToProps)
)(ProductComponent);
