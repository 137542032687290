import { clientStateSelectors } from 'rsv8-client';
import { ActivityTypeDto, ContentVersionModel, SocialDetailModel } from 'xcel-api-generator/dist/social';
import { createTypeSelector, getLastRequestMeta, getRequestEndpoint } from 'xcel-redux-orm';
import ActivityStatus from '../actions/social/ActivityStatus';
import { ROOT_STATE } from '../constants';
import contentCultureSelector from './contentCulture';

const SOCIAL_ACTIVITIES = 'socialDetailModal';
const SOCIAL_ACTIVITY_TYPES = 'activityTypeDto';
const ACTIVITY_CULTURE_VERSION = 'contentVersionDto';

interface DropdownOption {
  label: string;
  value: any;
}

/**
 * Helper function for converting arrays or objects in dropdown options
 *
 * @param collection Array | Object
 * @param mapProperties Object - It's an object which is label key has
 *                               name of the property that it will be taken,
 *                               same for its value key
 */
function mapOptions(collection: any, mapProperties: DropdownOption) {
  let result: Array<DropdownOption> = [];

  if (collection.isArray) {
    result = collection.map((item) => convertToOption(item, mapProperties));
  } else {
    result = Object.keys(collection).map((index) => convertToOption(collection[index], mapProperties));
  }

  return result;
}

function convertToOption(item: object, mapProperties: DropdownOption) {
  return {
    label: item[mapProperties.label],
    value: item[mapProperties.value]
  };
}

export const socialRoot: any = (state) => state[ROOT_STATE].social;

export const activitiesSelector: any = createTypeSelector<SocialDetailModel>(SOCIAL_ACTIVITIES);
export const activityTypesSelector: any = createTypeSelector<ActivityTypeDto>(SOCIAL_ACTIVITY_TYPES);
export const contentVersionSelector: any = createTypeSelector<ContentVersionModel>(ACTIVITY_CULTURE_VERSION);
export const activityId: any = (state) => socialRoot(state).activityId;

/**
 * Get all activities in a dictionary where key is the activity ID and the value
 * is the activity attributes, and this function grathers those an Array
 */
export const activities: any = (state) => {
  const request: any = getRequestEndpoint(state, 'getActivities') || {};
  const meta: any = getLastRequestMeta(state, 'getActivities') || {};
  const allActivities: any = activitiesSelector.selectMany(state, null) || {};

  // validate if we got an empty response or it's loading at least
  // if it's so, just send a empty array
  // this validation remove a flicking effect and cache issues
  // when the activities are loading
  return !request.loading && meta.totalRecords !== 0 ? Object.keys(allActivities).map((id) => allActivities[id]) : [];
};

/**
 * Make sure if the activities are already loaded from api
 */
export const activitiesLoaded: any = (state) => {
  const request = getRequestEndpoint(state, 'getActivities') || ({} as any);

  return !!request.succeeded;
};

/**
 * Parsing the incoming activityType data in order to make it component friendly
 */
export const activityTypes: any = (state) => {
  const allActivitiesTypes = activityTypesSelector.selectMany(state, null) || {};
  return mapOptions(allActivitiesTypes, { label: 'name', value: 'id' });
};

export const culturesByActivity: any = (state: any, { cultures = [] }: any) => {
  const cultureIds = cultures.map(({ id }) => id);
  return contentCultureSelector.selectMany(state, cultureIds) || [];
};

export const cultureByCultureId: any = (state: any, id: string) => {
  const currentActId = activityId(state);
  const { cultures } = activitiesSelector.selectOne(state, currentActId);
  const culture = cultures && cultures.find(({ cultureId }) => cultureId === Number(id));

  return culture;
};

export const getLastCulture: any = (state: any, id: string) => {
  const { cultures } = activitiesSelector.selectOne(state, id);
  const culturesLength = cultures.length;

  return culturesLength ? cultures[culturesLength - 1] : {};
};

export const versions: any = (state) => {
  const { culture = {} } = socialRoot(state);
  const cultureVersions = culture.versions || [];

  return cultureVersions
    .slice()
    .reverse()
    .sort((version1, version2) => {
      // sorting based in the creation date: new ones first
      const date1 = Date.parse(version1.createdDateTimeUtc);
      const date2 = Date.parse(version2.createdDateTimeUtc);

      return date1 < date2;
    });
};

export const currentVersion: any = (state) => {
  const { version } = socialRoot(state);
  return version || {};
};

export const currentCulture: any = (state) => {
  const { culture = {} } = socialRoot(state);
  return culture;
};

export const countries: any = (state) => {
  const countryList = clientStateSelectors.getCountry(state) || [];
  const countryOptions = mapOptions(countryList, {
    label: 'name',
    value: 'countryCode'
  });

  return countryOptions;
};

export const cultureList: any = (state) => {
  const cultures = clientStateSelectors.getCultureList(state) || [];
  const cultureOptions = mapOptions(cultures, { label: 'name', value: 'id' });

  return cultureOptions;
};

export const selectedVersion: any = (state) => socialRoot(state).selectedVersion || 0;

export const validVersionToRemove: any = ({ publishStatus }) => !(publishStatus !== ActivityStatus.DRAFT);
