import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { cultureSelector } from 'rsv8-client';
import { Grid, Loader } from 'rsv8-components';
import { register, withResource } from 'xcel-react-core';
import { alphaStringSort } from 'xcel-util';
import Masonry from '../../components/Masonry/Masonry';
import { getNavigationMenu } from '../../redux/actions';
import { getNavigationCategories } from '../../redux/selectors';
import {
  Department,
  DepartmentCard,
  DepartmentHeading,
  DepartmentLink,
  SubCategory,
  SubCategoryLink,
  SubCategoryList
  } from './styles';

interface AllDepartmentsProps {
  actions: {
    getNavigationMenu: any;
  };
  newDepartments: any;
  allDepartmentsHeading: string;
  cultureId: number;
}

class AllDepartments extends React.Component<AllDepartmentsProps, any> {
  componentDidMount() {
    this.props.actions.getNavigationMenu();
  }

  componentDidUpdate(prevProps: AllDepartmentsProps) {
    if (prevProps.cultureId !== this.props.cultureId) {
      this.props.actions.getNavigationMenu();
    }
  }

  showDepartments = () => {
    return (
      <Row>
        <Col xs={22} xsOffset={1} md={24} mdOffset={0}>
          {this.props.newDepartments && (
            <Masonry>
              {this.props.newDepartments.children.map((category, j) => {
                return (
                  <DepartmentCard key={j}>
                    <Department>
                      <DepartmentLink themeVariation="text-link-3xl-bold" to={category.targetUrl}>
                        {category.label}
                      </DepartmentLink>
                    </Department>
                    <SubCategoryList>
                      {category.children.sort(alphaStringSort('label')).map((childCategory, k) => {
                        return (
                          <SubCategory key={k}>
                            <SubCategoryLink themeVariation="text-link" to={childCategory.targetUrl}>
                              {childCategory.label}
                            </SubCategoryLink>
                          </SubCategory>
                        );
                      })}
                    </SubCategoryList>
                  </DepartmentCard>
                );
              })}
            </Masonry>
          )}
        </Col>
      </Row>
    );
  };
  render() {
    const { allDepartmentsHeading, newDepartments } = this.props;

    return (
      <Grid themeVariation="page-wrapper">
        <Row>
          <Col xs={22} xsOffset={1} md={24} mdOffset={0}>
            <DepartmentHeading>{allDepartmentsHeading}</DepartmentHeading>
          </Col>
        </Row>
        {newDepartments && newDepartments.children.length > 0 ? this.showDepartments() : <Loader />}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  cultureId: cultureSelector.getActiveCultureId(state),
  newDepartments: getNavigationCategories(state)
});

const mapResourceToProps = (getResource) => ({
  allDepartmentsHeading: getResource('catalog.allDepartmentsHeading', 'All Departments')
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getNavigationMenu
    },
    dispatch
  )
});

const compose = register('rsv8-catalog/AllDepartments');

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withResource(mapResourceToProps)
)(AllDepartments);
