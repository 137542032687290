import { createJsonApiActions } from 'xcel-redux-orm';
import { NOMINEE_SCROLL_ENDPOINT_NAME, NOMINEE_SEARCH_ENDPOINT_NAME } from '../../../constants';
import { IndividualNomination } from '../../../types';
import * as events from '../../events';
import { nomineeSearchSelectors as selectors } from '../../selectors/index';
import { setIndividualNominationProp } from '../genericActions';
type Search = IndividualNomination.NomineeSearch;

const isAll = (v) => v === undefined || v === '0';

const wrapFilterValue = (v) => (isAll(v) ? undefined : v);

export const createSearch = (partialSearch: Partial<Search>): Search => ({
  page: partialSearch.page,
  pageSize: partialSearch.pageSize || 12,
  query: partialSearch.query,
  sort: partialSearch.sort || {},
  applicationId: partialSearch.applicationId || undefined,
  region: wrapFilterValue(partialSearch.region),
  repType: wrapFilterValue(partialSearch.repType),
  customData1: wrapFilterValue(partialSearch.customData1),
  customData2: wrapFilterValue(partialSearch.customData2),
  customData3: wrapFilterValue(partialSearch.customData3),
  organization: wrapFilterValue(partialSearch.organization),
  limit: partialSearch.limit || undefined,
  offset: partialSearch.offset || undefined
});

export const setScrollId = setIndividualNominationProp('nomineeScroll');

export const addVisibleNominees = (nominees: Array<any>) => ({
  type: events.ADD_VISIBLE_NOMINEES,
  payload: nominees
});

export const setVisibleNominees = (nominees) => ({ type: events.SET_VISIBLE_NOMINEES, payload: nominees });

export const setLatestSearch = setIndividualNominationProp('latestSearch', createSearch);

export const modifyLatestSearch = (search: Partial<Search>) => (dispatch, getState) => {
  const newSearch = { ...selectors.getLatestNomineeSearch(getState()), ...search };
  dispatch(setLatestSearch(newSearch));
};

export const setTotalSearchResults = setIndividualNominationProp('totalRecords');

export const scrollActions = createJsonApiActions(NOMINEE_SCROLL_ENDPOINT_NAME);

export const searchActions = createJsonApiActions(NOMINEE_SEARCH_ENDPOINT_NAME);

export const getId = (i) => i.id;
