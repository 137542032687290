import { pipe } from '../helpers';

const transformOptions = (field, {value, displayText, parentValue}) => {
  return {
    // considers default value as empty value
    value: field.defaultValue === value ? '' : value,
    label: displayText || value,
    parentValue: parentValue
  }
};

// Remap some fields to more useful names
const renameFields = (field) => ({
  ...field,
  id: field.id,
  name: field.id,
  label: field.label,
  instructions: field.instructions,
  readOnly: !field.isEditable,
  placeholder: field.defaultValue || '',
  options: field.customFieldValues.map(transformOptions.bind(null, field)),
  fieldType: field.customFieldType,
  validate: (field.validations || []).map((v) => v.name.toLowerCase()),
  defaultSelectText : "Please Select " + field.label ,
  autoHideDefaultSelectText:field.required
});

const addRequiredToValidations = (field) => ({
  ...field,
  validate: field.required ? [...field.validate, 'required'] : field.validate
});

// Strip unnecessary fields (save for last)
const removeFields = (field) => {
  const newField = { ...field };
  [
    'customFieldValues',
    'customFieldKey',
    'clientId',
    'customFieldName',
    'relationTypeId',
    'uniqueId',
    'regularExpression',
    'defaultValue',
    'customFieldType',
    'relationId',
    'validations',
    'isEditable'
  ].forEach((key) => delete newField[key]);
  return newField;
};

// Just an identity function. Logs if name isn't present.
// Name (originally customFieldKey) is what I normalize by in the store
const logErrors = (field) => {
  if (!field.name) {
    console.log(`[CustomFields] Why does the following field not have a name?`, field);
  }
  return field;
};

export default pipe(
  renameFields,
  addRequiredToValidations,
  removeFields,
  logErrors
);
