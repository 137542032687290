import * as React from 'react';

export const LocationDetail: React.FC<{
  label: string;
  amount: string | number;
  percentage: string | number;
}> = ({ label, amount, percentage }) => (
  <div className="location__detail">
    <div className="location__detail-label">{label}</div>

    <div className="location__detail-values">
      <div className="location__detail-value">
        <div>{Number(amount)}</div>
        <div>{Number(percentage)}%</div>
      </div>
    </div>
  </div>
);
