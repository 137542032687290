import { props } from 'ramda';
import { getRequest, RequestTypes } from 'xcel-redux-orm';
import { containsAll as isSubsetOf } from './util';

export type Endpoint = RequestTypes.Endpoint;

export const getCustomField = (state, id: string) => state.customfield.field[id];

export const getCustomFields = (state, ids: string[]) => props(ids, state.customfield.field);

export const getFieldGroup = (state, ...params: string[]): Array<string> => {
  const retval = state.customfield.form.fields[params.join('-')];
  return retval || [];
};

export const getFieldsByGroup = (state, group: string[], ...params: string[]) => {
  const fields = getFieldGroup(state, ...[...params, group.join('-')]);
  let result = [];
  if (fields) {
    result = group
      ? getCustomFields(state, fields).filter((x: any) => x && group.indexOf(x.groupKey) !== -1)
      : getCustomFields(state, fields);
  }
  return result;
};

export const getFieldsWereFound = (state, ...params: string[]) => {
  const fieldGroup = getFieldGroup(state, ...params);
  const fieldStatus = getCustomFieldsRequestStatus(state, ...params);
  return !fieldStatus.exists || fieldStatus.loading || fieldGroup.length !== 0;
};

export const getFieldsNotFound = (state, ...params: string[]) => !getFieldsWereFound(state, ...params);

export const getFieldGroupIsFetched = (state, ...params: string[]): boolean => {
  const fieldStatus = getCustomFieldsRequestStatus(state, ...params);
  if (fieldStatus.exists && getFieldsNotFound(state, ...params)) {
    return true;
  }
  const allGroupFields = getFieldGroup(state, ...params).map((v) => v.toString());
  const availableFields = getAllCustomFieldKeys(state);
  if (allGroupFields.length !== 0 && isSubsetOf(allGroupFields, availableFields)) {
    return true;
  }
  return false;
};

export const getFieldValidations = (state: any) => state.customfield.validations;

export const getAllCustomFieldKeys = (state) => Object.keys(state.customfield.field || {});

export const getCustomFieldsRequestStatus = (state, ...params: string[]) => {
  return getRequest(state, `getCustomFieldsByGroup(${params.join('-')})`);
};
