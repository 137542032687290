import { createTypeSelector } from 'xcel-redux-orm';
import { Resource } from 'xcel-redux-orm';
import { ContentModel } from '../types/content';
import { getContentTypeState } from './state';
const emptyObject = {};
const contentSelector = {
  ...createTypeSelector<ContentModel>('contentModel'),
  getActiveProperty: (state, contentTypeId: string, property: string = null) => {
    const data = getContentTypeState(state, contentTypeId);
    return data && property === null ? data.active || emptyObject : data.active && data.active[property];
  },
  getEditProperties: (state, contentTypeId) => getContentTypeState(state, contentTypeId).edit,
  getEditContentProperties: (state, contentTypeId) => {
    const data = contentSelector.getEditProperties(state, contentTypeId);
    return (data && data.content) || emptyObject;
  },
  getEdit: (state, contentTypeId) => getContentTypeState(state, contentTypeId).edit,
  getRaw: (state, contentTypeId) => getContentTypeState(state, contentTypeId).raw
};

export default contentSelector;
export { Resource };
