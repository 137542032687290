import { promiseDispatcher } from 'xcel-react-core';
import { createJsonApiActions, getRequest } from 'xcel-redux-orm';
import { nominationApi } from '../../../api/index';
import { setIndividualNominationProp } from '../genericActions';
import { getId } from '../nomineeSearch/miscActions';

const getAwardsActions = createJsonApiActions(`getAwards`);

const getApplications = (id) => {
  // Don't confuse the backend by sending nomineeIds of 0, empty string, or null
  if (id) {
    return nominationApi.getApplication({ nomineeId: id }, { jsonApi: true });
  } else {
    return nominationApi.getApplication({}, { jsonApi: true });
  }
};

const setSelectableAwards = setIndividualNominationProp('visibleAwards');

const getAwardsRequest = (id) => (dispatch, getState) => dispatch(getAwardsActions.request(id));

const getAwardsSuccess = (response, id) => (dispatch, getState) => {
  dispatch(getAwardsActions.success(response, id));
  dispatch(setSelectableAwards(response.data.map(getId)));
};

const getAwardsFailure = (error, id) => (dispatch, getState) => dispatch(getAwardsActions.failure(error, id));

export const getAwards = promiseDispatcher(getApplications, {
  request: getAwardsRequest,
  success: getAwardsSuccess,
  failure: getAwardsFailure
});

const lazyGetAwards = (nomineeId) => (dispatch, getState) => {
  const status = getRequest(getState(), 'getAwards');
  if (!status.loading) {
    dispatch(getAwards(nomineeId));
  }
};

export const getAwardsOnce = () => (dispatch, getState) => {
  getRequest(getState(), 'getAwards');
  dispatch(getAwards(undefined));
};

export default lazyGetAwards;
