import { splitEvery } from 'ramda';
import * as React from 'react';
import { connect } from 'react-redux';
import { cultureSelector } from 'rsv8-client';
import { FabButton } from 'rsv8-components';
import { registry } from 'xcel-react-core';
import { getValue, tokenizedString } from 'xcel-util';
import { translate } from '../../api/google';
import {
  componentActions,
  contentActions,
  contentCultureSelector,
  contentSelector,
  contentTypeSelector
  } from '../../redux';
import TranslationIcon from './TranslateIcon';
const TranslateButton = ({ contentCulture, cultures, content, dispatch, contentTypeId }) => {
  const publishButtonProps = contentCulture && contentCulture.cultureId === 0 ? { disabled: 'disabled' } : {};
  const getTarget = () => {
    const culture = cultures.find((c) => c.id === contentCulture.cultureId);
    return culture.language.replace(/-.+/, '');
  };
  const translatePage = () => {
    const registryData = registry.get();
    const propertyData = registryData['content-properties'];
    const target = getTarget();
    const propertyList = splitEvery(
      10,
      Object.keys(propertyData).map((key) => {
        return {
          ...propertyData[key],
          value: getValue(content, `${propertyData[key].model}.content.${propertyData[key].property}`)
        };
      })
    );
    propertyList.forEach(async (properties) => {
      let request = {
        target,
        q: properties.map((data) => tokenizedString.serialize(data.value))
      };
      let response = await translate(request);
      let translations = response.data.translations;
      properties.forEach((data, index) => {
        let value = translations[index] && translations[index].translatedText;
        if (value) {
          value = tokenizedString.deserialize(data.value, value);
          dispatch(componentActions.propertySet({ ...data, value }));
        }
      });
    });
  };
  const translateResource = () => {
    const keys = Object.keys(content);
    // exclude things with file extensions and underscores
    const propertyList = splitEvery(10, keys.filter((key) => !/(\.\w{3}|\w+_\w+)/.test(content[key].value)));
    const target = getTarget();
    propertyList.forEach(async (properties) => {
      let request = {
        target,
        q: properties.map((key) => tokenizedString.serialize(content[key].value))
      };
      let response = await translate(request);
      let translations = response.data.translations;
      properties.forEach((key, index) => {
        let value = translations[index] && translations[index].translatedText;
        if (value) {
          value = tokenizedString.deserialize(content[key].value, value);
          dispatch(contentActions.setEditContentProperty(contentTypeId, { [key]: { value } }));
        }
      });
    });
  };
  const handleTranslate = () => {
    switch (contentTypeId) {
      case 'Page':
        translatePage();
        break;
      case 'Resource':
        translateResource();
        break;
      default:
        break;
    }
  };
  return (
    <FabButton
      index={2}
      className="btn btn-primary"
      onClick={handleTranslate}
      {...publishButtonProps}
      title="Translate"
    >
      <TranslationIcon />
    </FabButton>
  );
};
const mapStateToProps = (state) => {
  const contentTypeId = contentTypeSelector.getActive(state);
  return {
    contentTypeId,
    contentCulture: contentCultureSelector.getActive(state, contentTypeId),
    content: contentSelector.getEditContentProperties(state, contentTypeId),
    cultures: cultureSelector.getAll(state)
  };
};
export default connect(mapStateToProps)(TranslateButton);
