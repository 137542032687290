import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { ReactField, reduxForm } from 'rsv8-forms';
import styled from 'styled-components';
import { ColorPicker } from '../../components/Fields/Color';
import cmsPluginSelectors from '../../redux/cms.plugin.selectors';
import { colorRemove, colorUpdate } from '../../redux/color.actions';
import { mapDispatchToActions } from '../../util';
const RemoveColor = styled.div`
  cursor: pointer;
  color: black;
`;
const ColorContainer = styled.div`
  display: inline-block;
  position: relative;
  margin: 5px;
  vertical-align: top;
`;
const ColorManage = ({ actions, colors, preview, handleSubmit }) => {
  const handleChange = (color, property) => (e) => {
    color = { ...color, [property]: e.target ? e.target.value : e };
    actions.colorUpdate(color);
  };
  const handleClick = (color) => (e) => {
    e.stopPropagation();
    actions.colorRemove(color);
  };
  if (Object.keys(colors).length === 0) return null;
  const colorList = Object.keys(colors).map((name, index) => colors[name].color);
  const colorMap = Object.keys(colors).reduce((obj, key) => {
    let item = colors[key];
    let group = item.group || 'Ungrouped';
    if (obj[group] === undefined) obj[group] = [];
    obj[group].push(item);
    return obj;
  }, {});
  const sortColors = (a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0);
  return (
    <form onSubmit={handleSubmit(colorUpdate)}>
      <h4>Manage Colors</h4>
      <Row>
        <Col md={24}>
          {Object.keys(colorMap)
            .sort()
            .map((group, gindex) => {
              return (
                <div>
                  <h6>{group}</h6>
                  {colorMap[group].sort(sortColors).map((color, index) => {
                    const content = (
                      <span>
                        <ReactField
                          label={'Group'}
                          component={'bs-input'}
                          value={color.group}
                          name={'group'}
                          onBlur={handleChange(color, 'group')}
                        />
                        <RemoveColor onClick={handleClick(color)}>
                          Remove <i className={'fa fa-close'} />
                        </RemoveColor>
                      </span>
                    );
                    return (
                      <ColorContainer>
                        <ReactField
                          label={color.name}
                          colorContent={content}
                          component={ColorPicker}
                          value={color.color}
                          colors={colorList}
                          height={'180px'}
                          width={'180px'}
                          name={'color'}
                          onChange={handleChange(color, 'color')}
                          onClick={handleClick(color)}
                        />
                      </ColorContainer>
                    );
                  })}
                </div>
              );
            })}
        </Col>
      </Row>
    </form>
  );
};

const mapStateToProps = (state) => ({
  colors: cmsPluginSelectors.getColors(state)
});

const mapDispatchToProps = mapDispatchToActions({ colorRemove, colorUpdate });

export default reduxForm({ form: 'colorManage' })(connect(
  mapStateToProps,
  mapDispatchToProps
)(ColorManage) as any);
