import * as React from 'react';
import { FabButton } from 'rsv8-components';

const PublishButtonComponent = ({ version, canPublishVersion = true, onClick }) => {
  const disabledButtonProp =
    !version || version.isPublished || !canPublishVersion
      ? {
          disabled: 'disabled',
          title: `This page was just updated by someone else. Your changes cannot be saved`
        }
      : {};

  return (
    <FabButton className="btn btn-primary" onClick={onClick} {...disabledButtonProp}>
      <i className="fa fa-cloud-upload" aria-hidden="true" />
    </FabButton>
  );
};

export default PublishButtonComponent;
