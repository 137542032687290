import * as React from 'react';
import styled from 'styled-components';
import { DEFAULT_WIDTH } from './constants';
import { DEFAULT_HEIGHT } from './constants';

export type ThumbnailProps = {
  src: string;
  radius?: string;
  height?: string;
  width?: string;
};

const ThumbnailBase = styled.img`
  border-radius: 0;
  height: ${(props: ThumbnailProps) => props.height || DEFAULT_HEIGHT};
  width: ${(props: ThumbnailProps) => props.width || DEFAULT_WIDTH};
` as any;

const Thumbnail = (props: ThumbnailProps) => <ThumbnailBase {...props} />;

export default Thumbnail;
