import * as React from 'react';
import { connect } from 'react-redux';
import { Field, formSelectors, reduxForm } from 'rsv8-forms';

let DuplicateALanguageMenuItemForm = (props) => {
  const { handleSubmit, pristine, submitting, navigationMenuAdmin } = props;
  const { lookup, items } = navigationMenuAdmin;

  const menuProviderPopulate = lookup.provider;
  const menuTypePopulate = lookup.menuType;
  const culturePopulate = lookup.culture;

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label>
          Node ID <sup>*</sup>
        </label>
        <div>
          <Field required="true" name="nodeID" component="input" type="text" className="form-control" readOnly="true" />
        </div>
      </div>

      <div className="form-group">
        <label>
          Label <sup>*</sup>
        </label>
        <div>
          <Field required="true" name="nodeLabel" component="input" type="text" className="form-control" />
        </div>
      </div>

      <div className="form-group">
        <label>
          Culture <sup>*</sup>
        </label>
        <div>
          <Field name="cultureId" component="select" className="form-control" required="true">
            required="true"
            {culturePopulate.map((items) => (
              <option value={items.id} key={items.id}>
                {items.key}
              </option>
            ))}
          </Field>
        </div>
      </div>

      <div className="form-group">
        <label>
          Menu Type <sup>*</sup>
        </label>
        <div>
          <Field name="menuType" required="true" component="select" className="form-control" readOnly="true">
            {menuTypePopulate.map((items) => (
              <option value={items.id} key={items.id}>
                {items.key}
              </option>
            ))}
          </Field>
        </div>
      </div>

      <div className="form-group">
        <label>
          Target URL <sup>*</sup>
        </label>
        <div>
          <Field
            required="true"
            name="targetUrl"
            component="input"
            type="text"
            className="form-control"
            readOnly="true"
          />
        </div>
      </div>
      <div className="form-group">
        <label>
          Menu Provider <sup>*</sup>
        </label>
        <div>
          <Field name="providerId" component="select" className="form-control" required="true" readOnly="true">
            {menuProviderPopulate.map((item) => (
              <option value={item.id} key={item.id}>
                {item.key}
              </option>
            ))}
          </Field>
        </div>
      </div>

      <div className="form-group">
        <label>Parent Menu ID</label>
        <div>
          <Field name="parentMenuId" component="select" className="form-control" readOnly="true">
            <option value="">Optional</option>
            {items.map((items) => (
              <option value={items.id} key={items.id}>
                {items.label}
              </option>
            ))}
          </Field>
        </div>
      </div>

      <div className="form-group">
        <label>
          Direction <sup>*</sup>
        </label>
        <div>
          <Field readOnly="true" name="direction" component="input" type="text" className="form-control" />
        </div>
      </div>

      <div className="form-group">
        <button
          type="submit"
          className="btn btn-sm btn-primary"
          style={{ marginRight: '15px' }}
          disabled={pristine || submitting}
        >
          Save
        </button>
        {/*<button type="button" className="btn btn-sm btn-primary" disabled={pristine || submitting} onClick={reset}>
          Edit Menu Item (Clear Fields)
          </button>*/}
      </div>
      <div className="error-report" />
    </form>
  );
};

DuplicateALanguageMenuItemForm = reduxForm({
  form: 'DuplicateALanguageMenuItemForm',
  enableReinitialize: true
})(DuplicateALanguageMenuItemForm) as any;

const mapState = (state, ownProps) => {
  // can select values individually
  const cultureId = selector(state, 'cultureId');
  const menuType = selector(state, 'menuType');
  const providerId = selector(state, 'providerId');
  console.log('ownProps', ownProps);
  //const nodeProps = ownProps.node;
  return {
    cultureId,
    menuType,
    providerId,
    initialValues: {
      nodeID: ownProps.node.id,
      clientId: ownProps.node.clientId,
      nodeLabel: ownProps.node.label,
      targetUrl: ownProps.node.targetUrl,
      cultureId: ownProps.node.cultureId,
      menuType: ownProps.node.menuTypeId,
      sequence: ownProps.node.sequence,
      parentMenuId: ownProps.node.parentMenuId,
      providerId: ownProps.node.providerId,
      direction: ownProps.node.direction,
      applicationId: ownProps.node.applicationId
    }
  };
};

// Decorate with connect to read form values
const selector: any = formSelectors.getFormValues('SortForm'); // <-- same as form name
DuplicateALanguageMenuItemForm = connect(
  mapState,
  null
)(DuplicateALanguageMenuItemForm) as any;

export default DuplicateALanguageMenuItemForm;

//export default reduxForm({ form: 'AddMenuItemForm', })(AddMenuItemForm);
