import * as React from 'react';
import { Alert } from 'react-bootstrap';
import { Col, Row } from 'react-bootstrap';
import { compose } from 'redux';
import { Field, reduxForm } from 'rsv8-forms';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';
import { FieldContainer, SubmitButton } from '../../../components/FormControls';
import { StyledFormGroup } from '../styles/index';
import { StyledCheckbox } from './styles';

const StyledSubmitButton = styled(SubmitButton)`
  margin-top: 15px;
` as any;

interface LoginFormProps {
  resource: {
    usernameLabel: any;
    passwordLabel: any;
    showPasswordLabel: any;
    submitButtonLabel: any;
    tooltipHeader: string;
    tooltipRequirement1: string;
    tooltipRequirement2: string;
    tooltipRequirement3: string;
    usernameTooltipContent: string;
  };
  handleSubmit: any;
  error: any;
  disableShowPassword: boolean;
  hideInputs: boolean;
}

interface LoginFormState {
  showPassword: boolean;
}

class LoginForm extends React.Component<LoginFormProps, LoginFormState> {
  constructor(props: any) {
    super(props);
    this.state = { showPassword: false };
  }

  toggleShowPassword = (e) => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleChange = () => {};

  render() {
    const {
      disableShowPassword,
      hideInputs,
      handleSubmit,
      error,
      resource: {
        usernameLabel,
        passwordLabel,
        showPasswordLabel,
        submitButtonLabel,
        tooltipHeader,
        tooltipRequirement1,
        tooltipRequirement2,
        tooltipRequirement3,
        usernameTooltipContent
      }
    } = this.props;
    const tooltipText = `
      <span>${tooltipHeader}</span>
      <ul style="margin-left: -1.5rem;" className="text-left">
        <li>${tooltipRequirement1}</li>
        <li>${tooltipRequirement2}</li>
        <li>${tooltipRequirement3}</li>
      </ul>`;

    return (
      <form onSubmit={handleSubmit}>
        <StyledFormGroup controlId="formValidationErrorLogin" validationState={error ? 'error' : null}>
          {error && <Alert bsStyle="danger">{error}</Alert>}
          {hideInputs ? null : (
            <React.Fragment>
              <Field
                name="username"
                label={usernameLabel}
                helpIconText={usernameTooltipContent}
                component={'bs-input'}
                type="text"
                onChange={this.handleChange}
              />
              <Field
                name="password"
                label={passwordLabel}
                helpIconText={tooltipText}
                component={'bs-input'}
                type={this.state.showPassword ? 'text' : 'password'}
              />
              {!disableShowPassword && (
                <Field
                  name="showPassword"
                  label={showPasswordLabel}
                  component={'checkbox'}
                  checkboxRenderer={StyledCheckbox}
                  onChange={this.toggleShowPassword}
                />
              )}
            </React.Fragment>
          )}
          <Row>
            <Col>
              <FieldContainer>
                <StyledSubmitButton themeVariation="full-width" value={submitButtonLabel} />
              </FieldContainer>
            </Col>
          </Row>
        </StyledFormGroup>
      </form>
    );
  }
}
const mapResourceToProps = (getResource) => ({
  resource: {
    usernameLabel: getResource('login.usernameLabelText', 'Username*'),
    passwordLabel: getResource('login.passwordLabelText', 'Password*'),
    showPasswordLabel: getResource('login.showPasswordLabelText', 'Show Password'),
    submitButtonLabel: getResource('login.submitButtonText', 'Sign In'),
    tooltipHeader: getResource('login.tooltipHeader', 'Password requirements:'),
    tooltipRequirement1: getResource('login.tooltipRequirement1', '8 characters or longer'),
    tooltipRequirement2: getResource('login.tooltipRequirement2', 'At least one number'),
    tooltipRequirement3: getResource('login.tooltipRequirement3', 'At least one uppercase letter'),
    usernameTooltipContent: getResource('login.tooltipUsernameContent', '')
  }
});
export default compose(withResource(mapResourceToProps), reduxForm({ form: 'login' }))(LoginForm as any) as any;
