import { actions } from 'rsv8-forms';
import { promiseDispatcher } from 'xcel-react-core';
import { nominationApi } from '../../../api/index';
import { NOMINEE_FILTER_FORM_NAME } from '../../../constants';
import { IndividualNomination } from '../../../types';
import { setIndividualNominationProp } from '../genericActions';
import {
  createSearch,
  getId,
  searchActions,
  setLatestSearch,
  setScrollId,
  setTotalSearchResults,
  setVisibleNominees
  } from './miscActions';

type NomineeSearch = IndividualNomination.NomineeSearch;

export const nomineeSearchCall = (mySearch: NomineeSearch, options?: any) => {
  const { query, page, pageSize, sort, limit, offset, ...rest }: NomineeSearch = createSearch(mySearch);
  return nominationApi.getNomineeSearch(
    {
      query: query,
      pagePage: page,
      pagePageSize: pageSize,
      pageLimit: limit,
      pageOffset: offset,
      pageSortDirection: sort.direction,
      pageSortOrder: sort.field,
      ...rest
    } as any,
    { jsonApi: true }
  );
};

const getFilterIds = (list) => list.filter((v) => v.type === 'filter').map(getId);

const setNomineeSearchFilters = setIndividualNominationProp('filters', getFilterIds);

export const nomineeSearchSuccess = (response, search: NomineeSearch, options?: { clearFilters?: boolean }) => (
  dispatch,
  getState
) => {
  dispatch(searchActions.success(response, search));
  dispatch(setScrollId(response.meta.scrollId));
  dispatch(setVisibleNominees(response.data.map(getId)));
  dispatch(setTotalSearchResults(response.meta.totalNominees));
  dispatch(setLatestSearch(search));
  if (options && options.clearFilters) {
    dispatch(actions.reset(NOMINEE_FILTER_FORM_NAME));
  }
  dispatch(setNomineeSearchFilters(response.included));
};

const nomineeSearch = promiseDispatcher(nomineeSearchCall, {
  request: searchActions.request,
  success: nomineeSearchSuccess,
  failure: searchActions.failure
});

export default nomineeSearch;
