import { reduxObserver } from 'xcel-observer';
import { createReduxModule, ReduxModule } from 'xcel-redux-module';
import { ROOT_CMS_STATE } from './redux/constants';
import reducers from './redux/reducers';
const cmsModule: ReduxModule = createReduxModule({
  name: ROOT_CMS_STATE,
  reducers
});
reduxObserver.addModule(cmsModule);
export default cmsModule;
