import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators, compose } from 'redux';
import { PageRenderer } from 'rsv8-cms';
import { withResource } from 'xcel-react-core';
import { landingActions } from '../../redux/actions';
import { getLandingState } from '../../redux/selectors/state';
import {
  FooterButtonContainer,
  LandingBody,
  LandingFooter,
  LandingModal,
  LandingProgressBar,
  MobileProgressBar,
  NextButton,
  PostponedButton,
  PostponedButtonContainer,
  ProgressContainer,
  RightButton,
  RightButtonContainer
  } from './styles';

export interface LandingPageRedirectProps extends RouteComponentProps<any> {
  currentPath: string;
  loading: boolean;
  nextLandingRedirect: string;
  reportIntentPath: Function;
  nextLandingPath: string;
  nextLanding: Function;
  prevLanding: Function;
  postponeLandingVisit: Function;
  postponed: object;
  confirmLandingVisit: Function;
  notVisitedLandings: string[];
  previousLabel: string;
  nextLabel: string;
  doneLabel: string;
  remindMeLaterLabel: string;
  confirmedPaths: object;
}

interface LandingPageRedirectState {
  currentPath: string;
  currentLanding: number;
  totalLanding: number;
  currentTotal: number;
}
const shouldDispatchAction = (state: LandingPageRedirectState, props: LandingPageRedirectProps): boolean => {
  return state.currentPath !== props.location.pathname;
};
class LandingPageRedirect extends React.Component<LandingPageRedirectProps, LandingPageRedirectState> {
  static getDerivedStateFromProps(props: LandingPageRedirectProps, state: LandingPageRedirectState) {
    if (shouldDispatchAction(state, props)) {
      return {
        currentPath: props.currentPath
      };
    }

    return null;
  }

  constructor(props: LandingPageRedirectProps) {
    super(props);
    this.state = {
      currentPath: '',
      currentLanding: 0,
      totalLanding: 0,
      currentTotal: 0
    };
  }

  calculateCurrentTotal = (totalLanding: number) => {
    const confirmed = this.props.confirmedPaths[this.props.currentPath]
      ? this.props.confirmedPaths[this.props.currentPath].length
      : 0;
    return totalLanding - confirmed;
  };

  initializePathCounts = () => {
    const confirmed = this.props.confirmedPaths[this.props.location.pathname] || [];
    const postponedPaths = this.props.postponed[this.props.location.pathname] || [];
    const totalLanding = confirmed.length + postponedPaths.length + this.props.notVisitedLandings.length;
    this.setState({
      currentLanding: confirmed.length + postponedPaths.length + 1,
      currentTotal: this.calculateCurrentTotal(totalLanding),
      totalLanding
    });
  };

  componentDidMount() {
    if (shouldDispatchAction(this.state, this.props)) {
      this.props.reportIntentPath(this.props.location.pathname);
      this.setState({ currentPath: this.props.location.pathname });
    }
    this.initializePathCounts();
  }

  componentDidUpdate(prevProps: LandingPageRedirectProps) {
    if (shouldDispatchAction(this.state, this.props)) {
      this.setState({ currentPath: this.props.location.pathname });
      this.handleReportIntent();
    } else if (this.state.currentLanding === 0 && this.state.currentPath === '/home') {
      this.handleReportIntent();
    }
    if (this.props.location.pathname === '/logout' && this.state.currentLanding > 0) {
      this.setState({
        currentPath: '',
        currentLanding: 0,
        totalLanding: 0,
        currentTotal: 0
      });
    }
  }

  handleReportIntent = () => {
    this.props.reportIntentPath(location.pathname).then(() => {
      this.initializePathCounts();
    });
  };

  previous = () => {
    this.setState({ currentLanding: this.state.currentLanding - 1 });
    this.props.prevLanding(this.props.nextLandingRedirect);
  };

  next = () => {
    this.props.confirmLandingVisit(this.props.nextLandingRedirect);
    this.setState({ currentLanding: this.state.currentLanding + 1 });
  };

  postpone = () => {
    this.props.postponeLandingVisit(this.props.nextLandingRedirect);
    this.setState({ currentLanding: this.state.currentLanding + 1 });
  };

  public render() {
    const { nextLandingRedirect: pathname, postponed, notVisitedLandings } = this.props;
    if (
      pathname &&
      pathname !== this.state.currentPath &&
      pathname.match(/^\/[^\/]*/)[0] === this.props.location.pathname.match(/^\/[^\/]*/)[0] &&
      notVisitedLandings &&
      notVisitedLandings.length > 0
    ) {
      document.documentElement.style.overflow = 'hidden';
      return (
        <LandingModal themeVariation="landing-page" show={true}>
          <LandingBody>
            <PageRenderer
              {...this.props}
              path={this.props.nextLandingPath}
              location={{ state: { contentType: 'Landing' } } as any}
            />
          </LandingBody>
          <LandingFooter>
            <MobileProgressBar
              now={Math.round((this.state.currentLanding / this.state.totalLanding) * 100)}
              current={this.state.currentTotal}
            />
            <FooterButtonContainer>
              <PostponedButtonContainer>
                <PostponedButton
                  themeVariation="primary"
                  onClick={this.previous}
                  show={
                    postponed[this.props.location.pathname] &&
                    postponed[this.props.location.pathname].length > 0 &&
                    this.state.totalLanding > 1
                  }
                >
                  {this.props.previousLabel}
                </PostponedButton>
              </PostponedButtonContainer>
              <ProgressContainer>
                <LandingProgressBar
                  now={Math.round((this.state.currentLanding / this.state.totalLanding) * 100)}
                  current={this.state.currentTotal}
                />
              </ProgressContainer>
              <RightButtonContainer>
                <RightButton themeVariation="secondary" onClick={this.postpone}>
                  {this.props.remindMeLaterLabel}
                </RightButton>
                <NextButton themeVariation="primary" onClick={this.next}>
                  {notVisitedLandings.length === 1 ? this.props.doneLabel : this.props.nextLabel}
                </NextButton>
              </RightButtonContainer>
            </FooterButtonContainer>
          </LandingFooter>
        </LandingModal>
      );
    } else {
      document.documentElement.style.overflow = 'auto';
      return null;
    }
  }
}

type StateProps = Pick<
  LandingPageRedirectProps,
  | 'nextLandingRedirect'
  | 'currentPath'
  | 'loading'
  | 'nextLandingPath'
  | 'postponed'
  | 'notVisitedLandings'
  | 'confirmedPaths'
>;
type DispatchProps = Pick<LandingPageRedirectProps, 'reportIntentPath'>;

const mapStateToProps = (state, ownProps: LandingPageRedirectProps): StateProps => {
  const nextLandingRedirect = getLandingState(state).nextLandingRedirect
    ? getLandingState(state).nextLandingRedirect
    : getLandingState(state).intentedPath;
  return {
    nextLandingRedirect,
    currentPath: getLandingState(state).intentedPath,
    loading: getLandingState(state).loading,
    nextLandingPath: getLandingState(state).nextLandingPath,
    postponed: getLandingState(state).postponed,
    notVisitedLandings: getLandingState(state).notVisitedLandings,
    confirmedPaths: getLandingState(state).confirmedPaths
  };
};

const mapDispatchToProps = (dispatch): DispatchProps =>
  bindActionCreators(
    {
      reportIntentPath: landingActions.reportIntentPath,
      nextLanding: landingActions.navigateNextLanding,
      prevLanding: landingActions.navigatePrevLanding,
      postponeLandingVisit: landingActions.postponeLandingVisit,
      confirmLandingVisit: landingActions.confirmLandingVisit
    },
    dispatch
  );

const mapResourceToProps = (getResource) => ({
  previousLabel: getResource('cms.landingModal.previousLabel', 'Previous'),
  doneLabel: getResource('cms.landingModal.doneLabel', 'Done'),
  nextLabel: getResource('cms.landingModal.nextLabel', 'Next'),
  remindMeLaterLabel: getResource('cms.landingModal.remindMeLaterLabel', 'Remind Me Later')
});

export default withRouter(
  compose<any>(
    connect(
      mapStateToProps,
      mapDispatchToProps
    ),
    withResource(mapResourceToProps)
  )(LandingPageRedirect)
) as any;
