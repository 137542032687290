import * as React from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
  display: none !important;
`;

const StyledLabel = styled.label`
  margin-right: 15px;
`;

const UploadInput = (props) => {
  const { fileName, inputName } = props;
  return (
    <StyledLabel className="btn btn-primary">
      {fileName !== '' ? fileName : inputName}
      <StyledInput type="file" name="selectedFile" onChange={props.handleChange} />
    </StyledLabel>
  );
};

export default UploadInput;
