import * as React from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Paragraph, Text } from 'rsv8-components';
import { Field, reduxForm } from 'rsv8-forms';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';
import { updateUserInfo } from '../../../redux/actions/user/updateUserInfo';
import { FieldLabel } from '../../Label/Label';
import { CheckoutModuleHeading } from '../styles';
import { validateEmail } from '../util';

// tslint:disable

const GyftInfoContainer = styled.div`
  margin-top: 20px;

  @media (min-width: 769px) {
    margin-left: 20px;
  }
` as any;

export interface GyftInfoProps {
  actions: {
    updateUserInfo: any;
  };
  formCount: number;
  gyftInfoHeading: string;
  emailLabel: string;
  invalidEmailLabel: string;
  gyftInfoHelpText: string;
  gyftConfirmationLabel: string;
}

class GyftInfo extends React.Component<GyftInfoProps, any> {
  handleChange = (e) => { 
    this.props.actions.updateUserInfo(e.target.id || e.currentTarget.id || e.target.name ||e.currentTarget.name, e.target.value); 
  };

  email = (value) => validateEmail(value, this.props.invalidEmailLabel);

  render() {
    const { formCount, emailLabel, gyftInfoHelpText, gyftConfirmationLabel, gyftInfoHeading } = this.props;
 
    return (
      <div>
        <CheckoutModuleHeading>{`${formCount}. ${gyftInfoHeading}`}</CheckoutModuleHeading>
        <GyftInfoContainer>
          <Paragraph>{gyftInfoHelpText}</Paragraph>
          <Form>
            <Field
              component={'bs-input'}
              name="gyftEmail"
              onChange={this.handleChange}
              label={ 
                <FieldLabel required={true}>
                  <Text themeVariation="secondary-text">{emailLabel}</Text>
                </FieldLabel>
              }
              validate={this.email}
              required={true}
            />
            <Field
              component={'checkbox'} 
              name={`gyftConfirmation`}
              onChange={this.handleChange}
              label={
                <FieldLabel isCheckbox={true} required={true}>
                  <Text themeVariation="secondary-text">{gyftConfirmationLabel}</Text>
                </FieldLabel>
              }
              type="checkbox"
            />
          </Form>
        </GyftInfoContainer>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    gyftEmail: ownProps.userProfile.gyftEmail || ownProps.userProfile.emailAddress
  }
});

const mapResourceToProps = (getResource) => ({
  emailLabel: getResource('catalog.checkout.gyftPrimaryEmailLabel', 'Gyft Email'),
  gyftInfoHeading: getResource('catalog.checkout.gyftInfoHeading', 'Gyft Account Information'),
  invalidEmailLabel: getResource('catalog.checkout.invalidEmailLabel', 'Invalid email address'),
  gyftInfoHelpText: getResource(
    'catalog.checkout.gyftInfoHelpText',
    'Please choose the email you use for your Gyft account. This will become your default address for all future Gyft orders.'
  ),
  gyftConfirmationLabel: getResource(
    'catalog.checkout.gyftConfirmationLabel',
    'I confirm that the Gyft e-mail address provided is where my Gyft e-Gift Card will be delivered to.'
  )
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      updateUserInfo
    },
    dispatch
  )
});

export default compose<any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: 'GyftInfoForm' as any
  }),
  withResource(mapResourceToProps)
)(GyftInfo);
