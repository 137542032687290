import { NominationActivity } from '../../types';
import * as events from '../events';

const defaultState: NominationActivity.State = {
  detailsModalVisible: false,
  detailsModalId: null,
  detailsModalType: '',
  teamMembers: []
};

const nominationActivityReducer = (state: NominationActivity.State = defaultState, action) => {
  switch (action.type) {
    case events.SHOW_NOMINATION_DETAIL_MODAL:
      return { ...state, detailsModalVisible: true };
    case events.HIDE_NOMINATION_DETAIL_MODAL:
      return { ...state, detailsModalVisible: false };
    case events.SET_NOMINATION_ACTIVITY_MODAL_VISIBILITY:
      return { ...state, detailsModalVisible: action.payload };
    case events.SET_NOMINATION_ACTIVITY_MODAL_ID:
      return { ...state, detailsModalId: action.payload.id, detailsModalType: action.payload.type };
    case events.SET_NOMINATION_TEAM_MEMBER_IDS:
      return { ...state, teamMembers: action.payload};
    case events.CLEAR_NOMINATION_TEAM_MEMBER_IDS:
      return { ...state, teamMembers: []};
    default:
      return state;
  }
};

export default nominationActivityReducer;
