import { FormGroup } from 'react-bootstrap';
import styled from 'styled-components';
import { CatalogTableData, CatalogTableHeading, LinkButton } from '../../../components/ProductTable/ProductTableStyles';

export const FavProduct = styled(CatalogTableHeading)`
  width: 42%;
` as any;

export const FavOptions = styled(CatalogTableHeading)`
  width: 34%;
  @media (min-width: 993px) and (max-width: 1336px) {
    width: 25%;
  }
  @media (min-width: 1337px) {
    width: 20%;
  }
` as any;

export const FavValue = styled(CatalogTableHeading)`
  width: 20%;
  @media (min-width: 993px) {
    width: 12%;
  }
` as any;

export const UndoTableData = styled.td`
  padding: 25px !important;
` as any;

export const UndoTableContent = styled.span`
  margin-right: 20px;
` as any;

export const UndoTableRow = styled.tr`` as any;

export const UndoButton = styled(LinkButton)`
  padding-bottom: 1px;
` as any;

export const OptionFormGroup = styled(FormGroup)`
  margin-bottom: 0;
` as any;

export const CatalogTableButton = styled(CatalogTableData)`
  text-align: center;
` as any;
 
export const GroupProductFormGroup = styled(FormGroup)`
  margin-bottom: 0;
` as any;