import * as React from 'react';
import { connect } from 'react-redux';
import { CustomerProduct } from 'xcel-api-generator/dist/alcon';
import { baselineYearLabelSelector } from '../../redux/selectors';
import { ProductDetail } from './ProductDetail';
import { ProductHeader } from './ProductHeader';
import { ProductBody, ProductContainer } from './styles';

const UnitsProduct: React.FC<Props> = ({ baselineYearLabel, product }) => (
  <ProductContainer className="product">
    <ProductHeader baselineYearLabel={baselineYearLabel} name={product.name} unitLabel="units" />

    <ProductBody>
      <ProductDetail label={baselineYearLabel} units={product.baseLineUnits} />
      <ProductDetail label="CURRENT" units={product.currentUnits} />
    </ProductBody>
  </ProductContainer>
);

const mapStateToProps = (state) => ({
  baselineYearLabel: baselineYearLabelSelector(state),
});

interface StateProps {
  baselineYearLabel: string;
}
interface DispatchProps {}
interface OwnProps {
  product: CustomerProduct;
}
type Props = StateProps & DispatchProps & OwnProps;

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps)(UnitsProduct);
