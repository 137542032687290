import * as React from 'react';
import { ModularMenu } from 'rsv8-navigation';
import { MyAwards } from 'rsv8-nomination';
import { withResource } from 'xcel-react-core';

type Props = {
  modularMenuTitleText: string;
};

const MyAwardsComposite: React.SFC<Props> = ({ modularMenuTitleText }) => (
  <React.Fragment>
    <ModularMenu appKey="nominationNav" title={modularMenuTitleText} template="NominationsHeaderNav" />
    <MyAwards view="NomineeView" />
  </React.Fragment>
);

const mapResourceToProps = (getResource) => ({
  modularMenuTitleText: getResource('headerMenu.headingText', 'Recognition')
});

export default withResource(mapResourceToProps)(MyAwardsComposite);
