import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { cultureSelector } from 'rsv8-client';
import { register, withContent, withTheme } from 'xcel-react-core';
import * as Icon from 'react-fontawesome';
import { getAchievementPrograms } from '../../redux/actions';
import { getAchievementPrograms as getPrograms } from "../../redux/selectors";
import AchievementsHomepageLevel from "./AchievementsHomepageLevel";
import {
    AchievementHomepageContainer,
    AchievementHomepageList,
    AchievementHomepageItem,
    AchievementHomepageTitle,
    CarouselArrow
} from './styles';

export interface AchievementsHomepageProps {
    cultureId: string;
    className?: string;
    programs: Array<any>;
    getAchievementPrograms: (params?: any) => void;
}

const CarouselPrevArrow = (props) => {
    const { className, onClick } = props;

    return (
        <CarouselArrow className={className} onClick={onClick}>
            <Icon name="angle-left" size="3x" />
        </CarouselArrow>
    );
};

const CarouselNextArrow = (props) => {
    const { className, onClick } = props;

    return (
        <CarouselArrow className={className} onClick={onClick}>
            <Icon name="angle-right" size="3x" />
        </CarouselArrow>
    );
};

interface CarouselProps extends React.RefObject<{}> {
    scrollLeft: any;
    scrollWidth: any;
}

class AchievementsHomepage extends React.PureComponent<AchievementsHomepageProps> {
    container: Partial<CarouselProps> = React.createRef();
    carousel: Partial<CarouselProps> = React.createRef();
    state = {
        scrollLeftVisible: false,
        scrollRightVisible: false
    };
    containerRef = (component) => (this.container = component);
    carouselRef = (component) => (this.carousel = component);

    checkScroller = () => {
        if (this.carousel && this.container) {
            setTimeout(() => {
                const width = this.carousel.scrollWidth;
                const scrollLeft = this.carousel.scrollLeft;
                const containerWidth = this.container.scrollWidth;

                this.setState({
                    scrollLeftVisible: (width > 0) && (scrollLeft > 0),
                    scrollRightVisible: (width > 0) && (width > containerWidth) && ((scrollLeft + containerWidth) < width)
                });
            }, 50);
        }
    };

    componentDidMount() {
        this.props.getAchievementPrograms({ returnLevels: true });
        window.addEventListener('resize', this.checkScroller);
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        this.triggerResize();
    }

    triggerResize() {
        let event: any;

        if (document.createEvent) {
            event = document.createEvent('Event');
            event.initEvent('resize', false, true);
        } else {
            event = new Event('resize');
        }
        window.dispatchEvent(event);
    }

    scroll(direction: number = 1) {
        if (this.carousel) {
            const containerWidth = this.container.scrollWidth;
            this.carousel.scrollLeft += Math.round((containerWidth * 0.75) * direction);
            setTimeout(() => {
                this.triggerResize();
            }, 500);
        }
    }

    hasPrograms() {
        if (!Array.isArray(this.props.programs) || !this.props.programs.length) {
            return null;
        }
    }

    render() {
        const { programs } = this.props;

        if (this.hasPrograms()) {
            return null;
        }

        return (
            <div ref={this.containerRef} style={{ position: 'relative' }}>
                <AchievementHomepageContainer>
                    {this.state.scrollLeftVisible && (
                        <CarouselPrevArrow className="prev" onClick={() => this.scroll(-1)}/>
                    )}
                    <div ref={this.carouselRef} className="scroll-container">
                        <AchievementHomepageList>
                            {programs.map(program => (
                                <AchievementHomepageItem key={program.id}>
                                    <AchievementHomepageTitle>
                                        <span>{program.name}</span>
                                    </AchievementHomepageTitle>

                                    <AchievementsHomepageLevel program={program} />
                                </AchievementHomepageItem>
                            ))}
                        </AchievementHomepageList>
                    </div>
                    {this.state.scrollRightVisible && (
                        <CarouselNextArrow className="next" onClick={() => this.scroll(1)} />
                    )}
                </AchievementHomepageContainer>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    cultureId: cultureSelector.getActiveCultureId(state),
    programs: getPrograms(state)
});

const mapContentToProps = (getContent) => ({
});

const mapDispatchToProps = (dispatch) => ({
    getAchievementPrograms: bindActionCreators(getAchievementPrograms, dispatch)
});

export default register('rsv8-achievements/AchievementsHomepage')(
    withTheme(),
    withContent(mapContentToProps),
    connect(mapStateToProps, mapDispatchToProps)
)(AchievementsHomepage);
