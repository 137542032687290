import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { ReactField } from 'rsv8-forms';
import { contentTypeActions } from '../../../../../redux/actions';
class CreateContentTypeModal extends React.Component<{
  onHide: Function;
  show?: boolean;
  dispatch?: any;
  onCreate?: any;
}> {
  state = { id: null, description: null };
  handleCreate = () => {
    const { dispatch, onCreate } = this.props;
    if (this.state.id !== null) {
      dispatch(contentTypeActions.create(this.state.id, this.state.description));
    } else {
      console.warn('Invalid id', this.state.id);
    }
    if (onCreate) {
      onCreate(this.state);
    }
  };
  handleChange = (property) => (value) => {
    this.setState({ ...this.state, [property]: value });
  };
  handleEnter = (e) => {
    if (e.keyCode === 13) {
      this.handleCreate();
    }
  };
  render() {
    const { onCreate, dispatch, ...rest } = this.props;
    return (
      <Modal {...rest} bsSize="small">
        <Modal.Header closeButton={true}>
          <Modal.Title>Create Content Type</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ReactField
            label="Key"
            placeholder="Type key"
            component="bs-input"
            autoFocus={true}
            onChange={this.handleChange('id')}
            onKeyDown={this.handleEnter}
          />
          <ReactField
            label="Description"
            placeholder="Type description"
            component="bs-input"
            autoFocus={true}
            onChange={this.handleChange('description')}
            onKeyDown={this.handleEnter}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button bsStyle="primary" onClick={this.handleCreate}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
export default connect()(CreateContentTypeModal);
