import * as React from 'react';
import { Route } from 'react-router-dom';
import { DefaultLayout } from '../layouts';
const ThemeAdminRoute = (props) => {
  const { path } = props.match;
  return (
    <React.Fragment>
      <Route path={`${path}`} component={DefaultLayout} />
    </React.Fragment>
  );
};
export default ThemeAdminRoute;
