import * as React from 'react';
import { Button } from 'react-bootstrap';
import { Loader } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import CarouselCard from '../../../components/CarouselCard/CarouselCard';
import { LoadMoreContainer, SearchCard, SearchResultsContainer } from '../styles';

export interface ResultsProps {
  searchResults: any;
  favoritesList: any;
  location: any;
  totalResults: any;
  loadMore: any;
  loadMoreLabel: string;
}

class Results extends React.Component<ResultsProps, any> {
  render() {
    const { searchResults, favoritesList, location, totalResults, loadMore, loadMoreLabel } = this.props;

    return (
      <React.Fragment>
        {Array.isArray(searchResults) && searchResults.length > 0 ? (
          <SearchResultsContainer>
            {searchResults.map((result, index) => (
              <SearchCard key={index}>
                <CarouselCard favoritesList={favoritesList} product={result} searchParams={location.search} />
              </SearchCard>
            ))}
          </SearchResultsContainer>
        ) : (
          <Loader />
        )}
        {searchResults &&
          totalResults !== searchResults.length && (
            <LoadMoreContainer>
              <Button onClick={loadMore}>{loadMoreLabel}</Button>
            </LoadMoreContainer>
          )}
      </React.Fragment>
    );
  }
}

const mapResourceToProps = (getResource) => ({
  loadMoreLabel: getResource('catalog.search.loadMoreLabel', 'Load More')
});

export default withResource(mapResourceToProps)(Results);
