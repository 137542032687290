import * as React from 'react';
import styled from 'styled-components';

const CodeEditorContainer = styled.div`
  width: ${(props: any) => props.width || '100%'};
  height: ${(props: any) => props.height || '100%'};
`;

class CodeEditor extends React.Component<any> {
  static defaultProps = {
    language: 'json',
    theme: 'vs-dark'
  };
  editorElement: any;
  editor: any;
  componentDidMount() {
    this.init();
  }
  componentDidUpdate(prevProps: any) {
    if (this.props.value !== prevProps.value) {
      if (this.editor) {
        this.editor.setValue(this.props.value);
      }
    }
  }
  init = () => {
    if (!(window as any).require) {
      const loaderScript = window.document.createElement('script');
      loaderScript.type = 'text/javascript';
      loaderScript.src = 'https://unpkg.com/monaco-editor@0.16.0/min/vs/loader.js';
      loaderScript.addEventListener('load', this.load);
      window.document.body.appendChild(loaderScript);
    } else {
      this.load();
    }
  };
  create = () => {
    const { language, theme, value } = this.props;
    const win = window as any;
    this.editor = win.monaco.editor.create(this.editorElement, {
      value,
      language,
      theme
    });
    this.editor.onDidChangeModelContent((e) => {
      if (this.props.onChange) {
        this.props.onChange(this.editor.getModel().getValue(), e);
      }
    });
    return this.editor;
  };
  load = (e?: any) => {
    const win = window as any;
    win.require.config({
      paths: { vs: 'https://unpkg.com/monaco-editor@0.16.0/min/vs' }
    });
    win.require(['vs/editor/editor.main'], () => {
      this.create();
    });
    // clean up
    if (e) {
      e.target.removeEventListener('load', this.load);
    }
  };
  didMount = (ref) => {
    this.editorElement = ref;
  };
  render() {
    return <CodeEditorContainer {...this.props} innerRef={this.didMount} />;
  }
}

export { CodeEditor };

export default CodeEditor;
