import cn from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Image } from 'rsv8-components';
import { ProgramLevel } from 'xcel-api-generator/dist/achievement';
import {
  register,
  withContent,
  withResource,
  withTheme
  } from 'xcel-react-core';
import {
  AchievementLevelCheckIcon,
  AchievementLevelCircle,
  AchievementLevelCircleContent,
  AchievementLevelCircles,
  AchievementLevelsContent,
  AchievementLevelsHeading
  } from './styles';
interface Props {
  title: string;
  levels: ProgramLevel[];
  className: string;
}

const AchievementLevelsList: React.FC<Props> = ({ title, levels, className }) => {
  if (!levels.some((level) => level.products.length > 0)) {
    return null;
  }
  const renderLevel = (level: ProgramLevel) => {
    const hasAchievedDate = !!level.achievedDate;
    const hasRedeemedDate = !!level.redeemedDate;
    const isAchieved = level.redeemedDate || level.achievedDate;

    const classNames = cn({
      achieved_and_redeemed: hasAchievedDate && hasRedeemedDate,
      achieved_not_redeemed: hasAchievedDate && !hasRedeemedDate,
      next_to_be_achieved: !hasAchievedDate && !hasRedeemedDate,
      //not_yet_achieved: !hasAchievedDate && !hasRedeemedDate,
    });

    return (
      <AchievementLevelCircle key={level.id} className={classNames}>
        {isAchieved && (
          <AchievementLevelCheckIcon>
            <Image themeVariation="checkmark-white" />
          </AchievementLevelCheckIcon>
        )}
        <AchievementLevelCircleContent className="level-circle-content">{level.name}</AchievementLevelCircleContent>
      </AchievementLevelCircle>
    );
  };
  return (
    <AchievementLevelsContent className={className}>
      <AchievementLevelsHeading themeVariation="levels-header">{title}</AchievementLevelsHeading>
      <AchievementLevelCircles>{levels.map(renderLevel)}</AchievementLevelCircles>
    </AchievementLevelsContent>
  );
};

const mapResourceToProps = (getResource) => ({
  title: getResource('achievement.programLanding.level.listTitle', 'Your achievement levels')
});

export default register('rsv8-achievement/AchievementLevelsList')(
  connect(),
  withRouter,
  withContent(),
  withTheme(),
  withResource(mapResourceToProps)
)(AchievementLevelsList) as any;
