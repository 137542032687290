import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AuthRoute, OAuth, OAuthProvider } from 'rsv8-auth';
import { ForgotUsernameRoutes, ResetPasswordRoutes, SSOLoadingPage } from 'rsv8-auth/dist/component';
import {
  authPath,
  LoginRedirectCallback,
  LogoutRedirectCallback,
  oauthPath,
  userPath
  } from 'rsv8-auth/dist/component';
import { PageRenderer } from 'rsv8-cms';
import {
  ApproveByEmailForm,
  IndividualNominationWizard,
  RedeemingGiftWizard,
  TeamNominationWizard
  } from 'rsv8-nomination';
import { RouteSwitch } from 'xcel-react-core';
import { AchievementRoutes } from './Achievement';
import { ApplicationGuard } from './app/components/ApplicationGuard';
import LandingPageRedirect from './app/components/LandingPageRedirect';
import PageIcon from './app/components/PageIcon';
import PageTitle from './app/components/PageTitle';
import {
  BlankLayout,
  DefaultLayout,
  FooterLayout,
  NoFooterLayout
  } from './app/layouts';
import { CatalogRoutes } from './Catalog';
import { LogOut, MyAwards, NominationDashboard } from './pages';
import ContentPage from './pages/ContentPage';
import LayoutWrapper from './app/components/LayoutWrapper';

const renderPage = (path) => (props) => {
  return <PageRenderer {...props} path={path} />;
};

const App = () => {
  return (
    <LayoutWrapper>
      <PageTitle />
      <PageIcon />
      <OAuthProvider>
        <RouteSwitch>
          <BlankLayout>
            <Route exact={true} path="/" component={renderPage('/login')} />
            <Route exact={true} path="/login" component={renderPage('/login')} />
            <Route exact={true} path="/internallogin" component={renderPage('/internallogin')} />
            <Route exact={true} path="/register-confirm" component={renderPage('/register-confirm')} />
            <Route exact={true} path="/register-by-code" component={renderPage('/register-by-code')} />
            <Route exact={true} path="/oauth" component={OAuth} />
            <Route exact={true} path="/reset-password/request" component={renderPage('/reset-password/request')} />

            <Route exact={true} path={oauthPath.loginRedirectCallback} component={LoginRedirectCallback} />
            <Route exact={true} path={oauthPath.logoutRedirectCallback} component={LogoutRedirectCallback} />

            <Route exact={true} path="/consent" component={renderPage('/consent')} />
            <Route exact={true} path="/consentdecline" component={renderPage('/consentdecline')} />
            <Route exact={true} path="/sso" component={SSOLoadingPage} />
            <Route exact={true} path="/logout" component={LogOut} />
            <Route
              exact={true}
              path={`(${Object.keys(authPath)
                .map((key) => authPath[key])
                .join('|')})`}
              component={() => <ResetPasswordRoutes loginPath="/login" hrEmailAddress="support@rewardstation-live.net" />}
            />

            <Route
              exact={true}
              path={`(${Object.keys(userPath)
                .map((key) => userPath[key])
                .join('|')})`}
              component={() => <ForgotUsernameRoutes loginPath="/login" hrEmailAddress="support@rewardstation-live.net" />}
            />
            <Route path="/nominations/process/:code" component={ApproveByEmailForm} />
            <Route path="/nominations/redeem/:code" component={RedeemingGiftWizard} />
          </BlankLayout>
          <FooterLayout>
            <Route exact={true} path="/login-error" component={renderPage('/login-error')} />
          </FooterLayout>
          <NoFooterLayout>
            <AuthRoute exact={true} path="/nominations/individual" component={IndividualNominationWizard} />
            <AuthRoute exact={true} path="/nominations/team" component={TeamNominationWizard} />
          </NoFooterLayout>
          <DefaultLayout>
            <Switch>
              <AuthRoute path="/nominations/dashboard" component={NominationDashboard} />
              <AuthRoute exact={true} path="/nominations/home" component={NominationDashboard} />
              <AuthRoute exact={true} path="/nominations/shop" component={NominationDashboard} />
              <AuthRoute exact={true} path="/nominations/awards" component={MyAwards} />
              <AuthRoute exact={true} path="/nominations" component={NominationDashboard} />
              <AuthRoute path="/achievement" component={AchievementRoutes} />
              <AuthRoute path="/catalog" component={CatalogRoutes} />
              <AuthRoute path="*" component={ContentPage} />
            </Switch>
          </DefaultLayout>
        </RouteSwitch>
        <LandingPageRedirect />
        {/* <PageRenderer
          onNotFound={() => null}
          path={`/modal/catalog/zero-point`}
          search={window.location.search.length > 0 ? window.location.search : window.location.pathname}
        /> */}
      </OAuthProvider>
    </LayoutWrapper>
  );
};
export default ApplicationGuard(App);
