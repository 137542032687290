import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Image, Text } from 'rsv8-components';
import { getConfigurationProperty } from 'xcel-config';
import { SubmissionError } from 'rsv8-forms';
import styled from 'styled-components';
import {
  register,
  withContent,
  withResource,
  withTheme
  } from 'xcel-react-core';
import { LoginMaxWidth } from '../../../components/LoginMaxWidth';
import { authStateSelectors } from '../../../module';
import { userLogin } from '../../redux/user.actions';
import LoginForm from './LoginForm';
import { LoginBackground, LoginFormBody } from './styles/index';

const FormContainer = styled.div`
  max-width: ${(props: any) => (props.formMaxWidth ? '0' : '450px')};
  margin: 0 auto;
` as any;

const RegistrationText = styled(Text)`
  padding: 0 0 14px 0;
  display: block;
`;
const Login = ({
  auth,
  actions: { userLogin },
  redirectPath,
  disableShowPassword,
  match,
  location,
  children,
  resource: { errorMessageText, registrationText, registrationLink, registrationLinkText },
  className,
  dispatch,
  showRegisterLink,
  ...rest
}) => {
  let identityServerConfigData = getConfigurationProperty([
    "identityServerConfigData"
  ]);
  if (identityServerConfigData) {
    if (auth.isFirstVisit) {
      dispatch(push("/oauth"));
      return null;
    } else if (auth.isValid && auth.isOAuth && new Date(auth.expireDate) > new Date()) {
      dispatch(push("/home"));
      return null;
    }
  }
  let handleSubmit = creds => {
    const newCreds = { username: creds.username, password: creds.password };
    return userLogin(newCreds, redirectPath, location).catch((error) => {
      throw new SubmissionError({
        username: ' ',
        password: ' ',
        _error: errorMessageText
      });
    })
  };

  return (
    <LoginBackground className={`login-background ${className || ''}`} {...rest}>
      <FormContainer {...rest}>
        <LoginFormBody className="login-form-body">
          <Image themeVariation="primary-program-logo1" />
          {showRegisterLink && (
            <RegistrationText>
              {registrationText} <a href={registrationLink}>{registrationLinkText}</a>
            </RegistrationText>
          )}
          <LoginMaxWidth>
            <LoginForm
              disableShowPassword={disableShowPassword}
              onSubmit={handleSubmit}
            />
          </LoginMaxWidth>

          {children}
        </LoginFormBody>
      </FormContainer>
    </LoginBackground>
  );
};

const mapState = (state, ownProps) => {
  const auth = authStateSelectors.getAuth(state);
  return {
    auth: auth
  };
};

const mapDispatch = (dispatch) => ({
  actions: bindActionCreators({ userLogin }, dispatch),
  dispatch: dispatch
});

const mapResourceToProps = (getResource) => ({
  resource: {
    errorMessageText: getResource(
      'login.errorMessageText',
      'The username or password you have entered is incorrect. Please try again or click the forgot username or password link.'
    ),
    registrationText: getResource('login.registrationText', 'Don’t have an account?'),
    registrationLink: getResource('login.registrationLink', '#'),
    registrationLinkText: getResource('login.registrationLinkText', 'Register now.')
  }
});

const mapContentToProps = (getContent) => ({
  redirectPath: getContent('redirectPath', {
    type: 'none',
    label: 'Redirect Path'
  }),
  disableShowPassword: getContent('disable', {
    type: 'checkbox',
    label: 'Show Password'
  }),
  formMaxWidth: getContent('formMaxWidth', { type: 'none', label: 'Max Width' }),
  showRegisterLink: getContent('showRegisterLink', { type: 'checkbox', label: 'Show Registration Link?' })
});

export default register('rsv8-auth/Login')(
  withContent(mapContentToProps),
  withResource(mapResourceToProps),
  withTheme(),
  connect(mapState, mapDispatch)
)(Login);
