import { promiseDispatcher } from 'xcel-react-core';
import { createJsonApiActions } from 'xcel-redux-orm';
import { metricsApiActions } from './metricsApiActions';

const actions = createJsonApiActions('getCalculatorGoalsCombinedByCalculatorKey');
const getCalculatorGoalsCombinedByCalculatorKeyApiCall = (ProgramKey: any, CalculatorKey: any, TimePeriodKey: any) => {return metricsApiActions.getCalculatorGoalsCombinedByCalculatorKey(
      {programKey: ProgramKey, 
      calculatorKey: CalculatorKey,
      timePeriodKey: TimePeriodKey}, 
      {jsonApi: true})
};

const getCalculatorGoalsCombinedByCalculatorKey = promiseDispatcher(getCalculatorGoalsCombinedByCalculatorKeyApiCall, actions);
export { getCalculatorGoalsCombinedByCalculatorKey };