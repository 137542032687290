import * as React from 'react';
import { Button } from 'rsv8-components';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';

export interface EditDeleteButtonProps {
  displayEdit: boolean;
  handleDelete: (e: any) => void;
  handleEdit: (e: any) => void;
  className?: string;
}

interface InternalProps extends EditDeleteButtonProps {
  editText: any;
  deleteText: any;
}

const ButtonContainer = styled.div`
  float: right;
`;

const EditDeleteButtons: React.SFC<InternalProps> = (props) => (
  <ButtonContainer className={props.className}>
    {props.displayEdit ? <Button onClick={props.handleEdit}>{props.editText}</Button> : null}
    <Button onClick={props.handleDelete}>{props.deleteText}</Button>
  </ButtonContainer>
);

const mapResource = (getResource) => ({
  editText: getResource('nominationProcess.editDetailsLabel', 'Edit Details'),
  deleteText: getResource('nominationProcess.deleteLabel', 'Delete')
});

export default withResource(mapResource)(EditDeleteButtons) as React.ComponentType<EditDeleteButtonProps>;
