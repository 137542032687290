import * as React from 'react';
import { Badge } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { register, withResource, withTheme } from 'xcel-react-core';
import { getCart } from '../../redux/actions';
import { cartSelectors } from '../../redux/selectors';

export type Props = {
  desktopShoppingCartIcon?: string;
  mobileShoppingCartIcon?: string;
};

const CartBadgeBase = styled(Badge)`
  position: absolute;
  top: 5px;
  right: 0;
  z-index: 11;
`;

const CartIcon = styled.div`
  float: right;
  height: 52px;
  width: 50px;
  position: relative;
  z-index: 10;
  background-image: url(${(props: Props) => props.mobileShoppingCartIcon});
  background-position: center center;
  background-size: 48%;
  background-repeat: no-repeat;

  @media (min-width: 768px) {
    float: none;
    background-image: url(${(props: Props) => props.desktopShoppingCartIcon});
  }
`;

const CartBadge = ({ products }: any) => (products ? <CartBadgeBase>{products}</CartBadgeBase> : null);

class CartIconWithProductsCount extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.actions.getCart();
  }

  productsQuantity = (products) => {
    return products.reduce((total, product) => product && total + product.quantity, 0);
  };

  render() {
    const { className, products } = this.props;

    return (
      <Link to="/catalog/cart">
        <CartIcon className={className} {...this.props}>
          {products && <CartBadge className="badge" products={this.productsQuantity(products)} />}
        </CartIcon>
      </Link>
    );
  }
}

const mapStateToProps = (state) => ({
  products: cartSelectors.getCartProducts(state)
});

const mapResourceToProps = (getResource) => ({
  desktopShoppingCartIcon: getResource('catalog.desktopCartIcon', '/clientassets/images/icons/icon-cart.svg'),
  mobileShoppingCartIcon: getResource('catalog.mobileCartIcon', '/clientassets/images/icons/icon-cart.svg')
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getCart
    },
    dispatch
  )
});

export default register('rsv8-app/CartIconWithProductsCount')(
  connect(mapStateToProps, mapDispatchToProps),
  withResource(mapResourceToProps),
  withTheme(['.badge'])
)(CartIconWithProductsCount);
