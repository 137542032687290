import { teamNominationSelectors } from '../../../selectors/index';
import { addTeamMember } from './addTeamMember';
import { removeTeamMember } from './removeTeamMember';

export const selectTeamMember = ({ nominationId, id }) => (dispatch, getState) => {
  const teamMembers = teamNominationSelectors.getCurrentNomineeIds(getState());
  if (teamMembers.indexOf(id) !== -1) {
    return dispatch(removeTeamMember({ nominationId, userIds: id }));
  } else {
    return dispatch(addTeamMember({ nominationId, userIds: id }));
  }
};
