import styled from 'styled-components';

export const ActivityEditFormStyles = styled.div`
  margin-bottom: 100px;
` as any;

export const BreakLineForm = styled.div`
  width: 100%;
  height: 3px;
  background-color: #dddddd;
  margin: 30px 0;
` as any;
