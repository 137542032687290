import styled from 'styled-components';

/**
 * This provides support for IE11
 */
export const DropdownItem = styled.span`
  display: block;
  width: 100%;
` as any;

export default DropdownItem;