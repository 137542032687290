import * as React from 'react';
import { BackendResource } from 'rsv8-components';
import { TextRow } from '../../TextRow';

export interface InfoArrayBlockProps {
  content: Array<{ label: string; value: string; isLocalized: boolean }>;
}

const InfoArrayBlock: React.SFC<InfoArrayBlockProps> = (props) => {
  return props.content && Array.isArray(props.content) && props.content.length > 0 ? (
    <React.Fragment>
      {props.content.map((data) => (
        <TextRow
          key={data.label}
          head={
            data.isLocalized ? (
              <strong>{data.label}</strong>
            ) : (
              <strong>
                <BackendResource resourceKey={data.label} />
              </strong>
            )
          }
        >
          {data.value}
        </TextRow>
      ))}
    </React.Fragment>
  ) : null;
};

export default InfoArrayBlock;
