import * as React from 'react';
import { ModalProps } from 'react-bootstrap';
import {
  Button,
  HeaderThree,
  Modal as ModalCmp,
  ModalBody,
  ModalFooter,
  ModalHeader
  } from 'rsv8-components';
import styled from 'styled-components';
import {
  getOrderDetailsModalVisibility,
  getProductDetailsModalVisibility
} from "../../redux/selectors";
import {getWindowScrollY} from "../../redux/selectors/achievementSelectors";
import {connect} from "react-redux";
import {compose} from "redux";

const StyledModal = styled(ModalCmp as any)`
  .modal-dialog {
    width: 100%;
    max-width: 1200px;
  }
  .modal-content {
    margin: 0 auto;
    max-width: calc(100% - 1.5rem);
  }
`;

const StyledHeader = styled(ModalHeader as any)`
  margin: 0 5rem;
  padding: 4rem 0 2rem;
`;

const StyledBody = styled(ModalBody as any)`
  margin: 0 30px;
  padding: 4px;
  @media (min-width: 768px) {
    padding-bottom: 8rem;
  }
`;

export interface Props extends Partial<ModalProps> {
  toggleModal: any;
  modalHeading: string;
  footer?: boolean;
  closeLabel?: string;
  showProductDetails?: boolean;
  showOrderDetails?: boolean;
  windowScrollY?: number;
}

function onInitModal() {
  document.body.style.position = 'fixed';
  document.body.style.top = `-${window.scrollY}px`;
}

function onDestroyingModal(windowScrollY) {
  document.body.style.position = '';
  document.body.style.top = '';

  if (!!window && !!windowScrollY && windowScrollY > 0) {
    setTimeout(()=> window.scrollTo(0, windowScrollY), 50);
  }
}

function toggleModalState(toggleAction, windowScrollY, showProductDetails, showOrderDetails) {
  if (showOrderDetails || showProductDetails) {
    onDestroyingModal(windowScrollY);
  }

  toggleAction();
}

const Modal: React.SFC<Props> = ({ toggleModal, modalHeading, footer, closeLabel, windowScrollY, showProductDetails, showOrderDetails, children, ...rest }) => (
  <StyledModal onHide={() => toggleModalState(toggleModal, windowScrollY, showProductDetails, showOrderDetails)} {...rest}>
    {onInitModal()}
    <StyledHeader closeButton={true}>
      <HeaderThree>{modalHeading}</HeaderThree>
    </StyledHeader>
    <StyledBody>{children}</StyledBody>
    {footer && (
      <ModalFooter>
        {closeLabel && (
          <Button themeVariation="secondary" onClick={toggleModal}>
            {closeLabel}
          </Button>
        )}
      </ModalFooter>
    )}
  </StyledModal>
);

const mapStateToProps = (state) => ({
  windowScrollY: getWindowScrollY(state),
  showProductDetails: getProductDetailsModalVisibility(state),
  showOrderDetails: getOrderDetailsModalVisibility(state),
});

export default compose<any>(
  connect(mapStateToProps),
)(Modal);