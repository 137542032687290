import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  HeaderDecoration,
  HeaderOne,
  Image,
  Link,
  Text
  } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import { LayoutContainer } from '../../../components/Layout';

const NoEmailAddress = ({
  hrEmailAddress,
  loginHelpLinkPath,
  resource: { headerText, paragraphText, backToLoginPageText }
}) => (
  <div>
    <HeaderDecoration />
    <Col xs={22} xsOffset={1} lg={22} lgOffset={1} md={22} mdOffset={1}>
      <Image themeVariation="primary-company-logo1" />
    </Col>
    <LayoutContainer>
      <Row>
        <Col xs={22} xsOffset={1} lg={7} lgOffset={8} md={7} mdOffset={8}>
          <Image themeVariation="icon-oops" />
        </Col>
      </Row>
      <Row>
        <Col xs={22} xsOffset={1} lg={12} lgOffset={6} md={12} mdOffset={6}>
          <HeaderOne textalign="center">{headerText}</HeaderOne>
        </Col>
      </Row>
      <Row>
        <Col xs={22} xsOffset={1} lg={12} lgOffset={6} md={12} mdOffset={6}>
          <Text textalign="center" display="block">
            {paragraphText} <Link href={`mailto:${hrEmailAddress}`}>{hrEmailAddress}</Link>
          </Text>
        </Col>
      </Row>
      <br />
      <Row>
        <Link href={loginHelpLinkPath || '/'} textalign="center" display="block">
          {backToLoginPageText}
        </Link>
      </Row>
    </LayoutContainer>
  </div>
);

const mapState = () => ({});

const mapDispatch = (dispatch) => ({});

const mapResourceToProps = (getResource) => ({
  resource: {
    headerText: getResource(
      'NoEmailAddress.headerText',
      'We’re sorry but there is no email address associated to your account.'
    ),
    paragraphText: getResource('NoEmailAddress.paragraphText', 'Contact your HR Service Center at '),
    backToLoginPageText: getResource('NoEmailAddress.backToLoginPageText', 'Back to the login page >')
  }
});

export default compose(
  withResource(mapResourceToProps),
  connect(
    mapState,
    mapDispatch
  )
)(NoEmailAddress) as any;
