import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ConfigProps, InjectedFormProps } from 'redux-form';
import { CustomFields, customFieldSelectors } from 'rsv8-customfields';
import { reduxForm } from 'rsv8-forms';
import { Context, withWizard } from 'rsv8-wizard';
import { NarrowGrid as Grid } from '../index';
import CriteriaInput from './CriteriaInput';

export interface CriteriaFormProps extends ConfigProps {
  award: string;
  wizard?: Context;
  form: string;
  type: string;
  initialValues?: { NominationForm: { criteria: object } };
  showPage?: Function;
}

interface InternalProps extends CriteriaFormProps {
  foundFields: boolean;
}

class NominationCriteria extends React.Component<InternalProps & InjectedFormProps, any> {
  componentWillMount() {
    if (this.props.foundFields === false) {
      this.skipWizardStep();
    }
  }

  componentDidMount() {
    if (this.props.wizard && this.props.foundFields === true) {
      this.props.wizard.registerIncrement(this.props.handleSubmit(this.submit));
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.foundFields === true && this.props.foundFields === false) {
      this.skipWizardStep();
    }
  }

  skipWizardStep = () => {
    if (this.props.wizard) {
      this.props.wizard.removeWizardStep();
      this.props.wizard.increment();
    }
  };

  // We want to use the CriteriaInput for our fields
  mapField = (field) => CriteriaInput;

  // Empty submission function. onChange and redux takes care of us here
  submit = (values) => undefined;

  render() {
    const { award, handleSubmit, type, showPage } = this.props;
    return (
      <Grid>
        <form onSubmit={handleSubmit(this.submit)}>
          <CustomFields
            formType={type === 'team' && award === 'FourStarAward' ? 'TeamNomination' : 'NominationForm'}
            groupKey="criteria"
            appKey={award}
            mapFieldToInput={this.mapField}
            showPage={showPage}
          />
        </form>
      </Grid>
    );
  }
}

const mapState = (state, ownProps) => {
  return {
    foundFields: customFieldSelectors.getFieldsWereFound(state, 'NominationForm', ownProps.award, 'criteria')
  };
};

export default compose<any>(
  connect(
    mapState,
    null
  ),
  reduxForm({} as any),
  withWizard()
)(NominationCriteria) as React.ComponentClass<CriteriaFormProps>;
