import * as React from 'react';
import { connect } from 'react-redux';
import { CustomerProduct } from 'xcel-api-generator/dist/alcon';
import * as utils from '../../../../utils';
import { ProgramType } from '../../../constants';
import { baselineYearLabelSelector, programKeySelector, unitLabelSelector } from '../../../redux/selectors';
import * as styles from './styles';

const ProductNameBlock: React.FC<Props> = ({
  product: { name, points, baselineProductPoints },
  unitLabel,
  baselineYearLabel,
  programKey
}) => {

  return (
    <styles.ProductNameTableCell>
      <styles.ProductNameText>
        <b>{name}</b>
      </styles.ProductNameText>

      {!!points && programKey === ProgramType.Point && <styles.ProductNameValueText>{`${utils.getDecimalValue(points)} - ${unitLabel}`}</styles.ProductNameValueText>}
      {!!baselineProductPoints && programKey === ProgramType.Point && <styles.ProductNameValueText>{`${baselineProductPoints} - ${unitLabel} (${baselineYearLabel})`}</styles.ProductNameValueText>}
    </styles.ProductNameTableCell>
  );
};

const mapStateToProps = (state) => ({
  programKey: programKeySelector(state),
  baselineYearLabel: baselineYearLabelSelector(state),
  unitLabel: unitLabelSelector(state)
});

interface StateProps {
  programKey: string;
  baselineYearLabel: string;
  unitLabel: string;
}
interface DispatchProps { }
interface OwnProps {
  product: CustomerProduct;
}
type Props = StateProps & DispatchProps & OwnProps;

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps)(ProductNameBlock);