import * as React from 'react';
import { Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
    Text
} from 'rsv8-components';
import { register, withResource } from 'xcel-react-core';
import AwardStars from "../MyAwards/AwardCard/AwardStars";
import { HeaderOne } from 'rsv8-components';
import { Spinner, PaddedRow, CentredContainer, ButtonGroup, BigButton, RedeemButton } from './Elements';
import {RedeemActions} from "./RedeemingGift";

const DESCRIPTION_DEFAULT_TEXT = 'Use the buttons below to select whether you’d like to keep your current award, or trade it.\n' +
    'If you select Redeem Now, you will be issued a redemption code for your digital gift card\n' +
    'via email. If you select Trade for Credits, you can redeem those credits for any digital or\n' +
    'physical gift card of your choice in the Reward Catalog. This action cannot be undone. ';

interface InternalProps {
    userName: string,
    actionsLoading: boolean,
    awardValue: string,
    actions: Array<string>,
    redeemClickHandler: (e: any) => any,
    tradeClickHandler: (e: any) => any,

    resource: {
        redeemingViaEmailHeader: string;
        redeemViaEmailButtonLabelText: string;
        tradeViaEmailButtonLabelText: string;
        selectAwardViaEmailDescriptionText: string;
    };
}

const MainForm: React.SFC<InternalProps> = (props) => {

    return (
        <React.Fragment>
                <PaddedRow>
                    <Col>
                        <CentredContainer>
                            <HeaderOne themeVariation="primary-link-3xl-bold-text">
                                {props.resource.redeemingViaEmailHeader}
                            </HeaderOne>
                        </CentredContainer>
                    </Col>
                </PaddedRow>

                <PaddedRow>
                    <Col mdOffset={3} lgOffset={4} xs={24} md={18} lg={16}>
                        <Text themeVariation="center">{props.resource.selectAwardViaEmailDescriptionText}</Text>
                    </Col>
                </PaddedRow>

                <PaddedRow>
                    <Col>
                        <Text themeVariation="primary-link-3xl-bold-text">
                            <CentredContainer>
                                {props.awardValue}
                            </CentredContainer>
                        </Text>
                    </Col>
                    <Col>
                        <CentredContainer>
                            <AwardStars award={''} />
                        </CentredContainer>
                    </Col>
                </PaddedRow>

                <PaddedRow>
                    { props.actionsLoading ? <Spinner/> :
                        <ButtonGroup>
                            {props.actions.find(item => item === RedeemActions.Redeem) ? <RedeemButton onClick={props.redeemClickHandler}>
                                {props.resource.redeemViaEmailButtonLabelText}
                            </RedeemButton> : null}
                            {props.actions.find(item => item === RedeemActions.Trade) ? <BigButton onClick={props.tradeClickHandler}>
                                {props.resource.tradeViaEmailButtonLabelText}
                            </BigButton> : null}
                        </ButtonGroup>
                    }
                </PaddedRow>
        </React.Fragment>
    );
};

const mapState = (state) => ({
});

const mapResource = (getResource) => ({
    resource: {
        redeemingViaEmailHeader: getResource('nominations.redeemingViaEmailHeader', '{userName}, how would you like your award?'),
        redeemViaEmailButtonLabelText: getResource('nominations.redeemViaEmailButtonLabelText', 'Redeem now'),
        tradeViaEmailButtonLabelText: getResource('nominations.tradeViaEmailButtonLabelText', 'Trade for Credits'),
        selectAwardViaEmailDescriptionText: getResource('nominations.selectAwardViaEmailDescriptionText', DESCRIPTION_DEFAULT_TEXT),
    }
});

export default register('rsv8-nomination/RedeemingGiftMainForm')(
    connect(
        mapState,
        null
    ),
    withResource(mapResource),
)(MainForm);
