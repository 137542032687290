import * as React from 'react';
import styled from 'styled-components';
import Text from './Text';

const HeadingSpacerBase = styled.div`
  padding-top: ${(props: any) => props.top || 40}px;
`;

const ParagraphBase = styled.p`
  color: #4c4f4f;
  font-family: Helvetica;
  font-size: 14px;
  padding: 8px 0 8px;
  text-align: ${(props: any) => props.textalign};
`;

const Paragraph: React.SFC<{ textalign?: string }> = (props) => <ParagraphBase {...props} />;

const HeadingSpacer: React.SFC<{ top?: string | number }> = (props) => <HeadingSpacerBase {...props} />;

export { HeadingSpacer, Paragraph };
export { HeaderFive, HeaderFour, HeaderOne, HeaderSix, HeaderThree, HeaderTwo } from './Heading';

export { Text };
