import * as React from 'react';
import { Col, Grid } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { register, withContent } from 'xcel-react-core';
import { nominationActions } from '../../redux/actions/getApplication';
import { DashboardModal } from '../../components/DashboardModal';

const StyledGrid = styled(Grid)`
  margin-top: 40px;
` as any;

interface InternalProps {
  actions: {
    getNominationApplication: Function;
  };
}

class NominationActivity extends React.Component<InternalProps> {
  componentDidMount() {
    this.props.actions.getNominationApplication({});
  }

  render() {
    // children content comes from cms page: nominations/activity
    return (
      <React.Fragment>
        <StyledGrid>
          <Col xs={22} xsOffset={1} sm={24} smOffset={0} md={24} lg={24}>
            {this.props.children}
          </Col>
        </StyledGrid>
        <DashboardModal />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ getNominationApplication: nominationActions.getApplication }, dispatch)
});

export default register('rsv8-nomination/NominationActivityWrapper')(
  connect(
    null,
    mapDispatchToProps
  ),
  withContent()
)(NominationActivity);
