export const wrapInPromise = (obj: any): Promise<{}> => {
  if (obj === undefined) {
    return Promise.resolve(undefined);
  } else if (obj.then) {
    return obj;
  } else {
    return Promise.reject(undefined);
  }
};

const doNothing = () => undefined;

export const moveWizard = (promiseFns: Array<Function>, success, failure = doNothing): Promise<{}> => {
  const promises = promiseFns.map((p) => wrapInPromise(p()));
  return Promise.all(promises)
    .then((results: Array<any>) => {
      if (results.find((val) => val !== undefined) !== undefined) {
        failure();
      } else {
        success();
      }
    })
    .catch(failure);
};
