import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { componentObserver } from 'xcel-observer';
import { registry, withErrorBoundary } from 'xcel-react-core';
import { getValue } from 'xcel-util';
import { componentActions } from '../../../index';
import withLegacyContent from '../../../../components/withLegacyContent';
import { contentSelector, contentTypeSelector, socialSelector } from '../../../redux/selectors';
import ComponentEditing from '../../Component/ComponentEditing';

const FadedFont = styled.span`
  color: rgba(0, 0, 0, 0.35);
  font-style: italic;
  font-weight: bold;
  ${(props: any) => props.size && `font-size: ${props.size};`};
` as any;

class AdminPageRenderer extends React.Component<any> {
  setComponentProperty = (model: string) => (property: string, value: any) => {
    const { dispatch } = this.props;
    dispatch(componentActions.propertySet({ model, property, value }));
  };
  renderComponents = (model: string, content: any) => (property: string) => {
    const childrenProperty = property.replace(/.+\./, '');
    const children = getValue(content, property);
    return (
      <AdminPageRenderer
        {...this.props}
        key={model}
        childrenProperty={childrenProperty}
        components={children}
        model={`${model}.content.${property}`}
      />
    );
  };
  render() {
    const { components, model, childrenProperty = 'children' } = this.props;
    const data = components || [];
    const registryData = registry.get();
    if (data && Array.isArray(data) && data.length > 0) {
      return data.map((config, index) => {
        let { component: name, content } = config;
        let children = config[childrenProperty];
        let Component = registryData.components[name];
        let contentSchema = registryData.content;
        const namespace = model === undefined ? index.toString() : `${model}.${index}`;
        if (Component !== undefined && contentSchema !== undefined && contentSchema[name] === undefined) {
          Component = withLegacyContent(name)(Component);
          componentObserver.addComponent(name, Component);
        }

        if (Component === undefined || registryData.components[name] === undefined) {
          return (
            <div key={index}>
              No Component Found ({name}) [{namespace}]
            </div>
          );
        }
        return (
          <ComponentEditing
            key={namespace}
            setComponentProperty={this.setComponentProperty(namespace)}
            renderComponents={this.renderComponents(namespace, content)}
            name={name}
            model={namespace}
            content={content}
            component={Component}
          >
            {children && children.length > 0 ? (
              <AdminPageRenderer
                {...this.props}
                key={namespace}
                childrenProperty={childrenProperty}
                components={children}
                model={`${namespace}.${childrenProperty}`}
              />
            ) : (
              <FadedFont>[add {childrenProperty}]</FadedFont>
            )}
          </ComponentEditing>
        );
      });
    }
    if (model === undefined) {
      return (
        <FadedFont size="36px">
          [Your page is empty <i className="fa fa-frown-o" /> drag a component here]
        </FadedFont>
      );
    } else {
      return <FadedFont>[add {childrenProperty}]</FadedFont>;
    }
  }
}
const mapStateToProps = (state) => {
  const contentTypeId = contentTypeSelector.getActive(state);
  let version;

  if (contentTypeId === 'Activity') {
    version = socialSelector.currentVersion(state);
  } else {
    version = contentSelector.getEdit(state, contentTypeId);
  }

  return {
    contentTypeId,
    components: version && version.content
  };
};
export default connect(mapStateToProps)(withErrorBoundary()(AdminPageRenderer));
