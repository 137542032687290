import { authApi } from '../../api';
import { CodeRequest } from 'xcel-api-generator/dist/auth';

const authGetCode = (user, redirectUri):any  => async (dispatch) => {
    const codeRequest: CodeRequest = {
      clientId: user.aud,
      redirectUri: redirectUri,
      scope: null,
      state: null,
      codeChallenge: null,
      codeChallengeMethod: null,
      request: {
        clientId: user.clientId,
        userId: user.userId,
        username: user.userName,
        loginAsParticipant: false,
        redirectUri: redirectUri
      }};

const response = await authApi.createCodeGenerate({
   request: codeRequest
   },
   { jsonApi: true }
   )
 .then((resp) => {
   return resp;
 }).catch((e) => {
   console.error(e);
 });
    return response;
};

export { authGetCode };
