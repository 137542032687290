import { promiseDispatcher } from 'xcel-react-core';
import { createJsonApiActions, getRequest } from 'xcel-redux-orm';
import { nominationApi } from '../../../api/index';

const detailString = (...args) => `getProductDetails${args.join('-')}`;

export type getCurrentApplication<P> = (productId: string, nomineeId: string, options?: { team?: boolean }) => P;

const getCurrentApplication: getCurrentApplication<Promise<any>> = (
  productId: string,
  nomineeId: string,
  { team = false }
) => {
  const apiCall = team ? nominationApi.getApplicationTeamById : nominationApi.getApplicationById;

  return apiCall(
    {
      id: productId,
      nomineeId: nomineeId ? parseInt(nomineeId, 10) : undefined
    },
    { jsonApi: true }
  );
};

const getProductsRequest = (product: string, nominee: string) => (dispatch, getState) =>
  dispatch(createJsonApiActions(detailString(product)).request({ product, nominee }));

const getProductsSuccess = (response, product: string, nominee: string) => (dispatch, getState) => {
  dispatch(createJsonApiActions(detailString(product)).success(response, { product, nominee }));
};

const getProductsFailure = (error, product: string, nominee: string) => (dispatch, getState) =>
  dispatch(createJsonApiActions(detailString(product)).failure(error, { product, nominee }));

const getProductDetails = promiseDispatcher(getCurrentApplication, {
  request: getProductsRequest,
  success: getProductsSuccess,
  failure: getProductsFailure
});

const lazyGetDetails: getCurrentApplication<any> = (product: string, nominee: string, options) => (
  dispatch,
  getState
) => {
  const status = getRequest(getState(), detailString(product));
  if (!status.loading) {
    dispatch(getProductDetails(product, nominee, options));
  }
};

export default lazyGetDetails;
