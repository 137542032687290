import * as React from 'react';
import * as Icon from 'react-fontawesome';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { register, withContent, withResource, withTheme } from 'xcel-react-core';
import { withApiDataProvider } from 'xcel-redux-orm';
import * as utils from '../../../utils';
import {
  achResendInvite,
  achSendInvite,
  fetchRedeemOptions,
  getAchSearch,
  getEnrolledStatus,
  saveMagnifeyeRedeem,
} from '../../redux/actions';
import {
  achSearchInformation,
  enrollmentStatusSelector,
  getAchSearchResults,
  getEnrollmentStatus,
  getEnrollmentUserInfo,
  pointSummarySelector,
  redeemOptionSelector,
  redemptionHistorySelector,
} from '../../redux/selectors';
import EnrollmentModal from '../EnrollmentModal';
import RebateModal from '../RebateModal';
import SubmissionModal from '../SubmissionModal';
import * as styles from './styles';

interface DirectDepositProps {
  actions: any;
  directDepositOption: any;
  directDepositDescription: string;
  pointSummary: any;
  enrollmentStatus: string;
  enrollmentUserInfo: any;
  redemptionHistory: any;
  enrollTodayIntroTextp1: string;
  enrollTodayIntroTextp2: string;
  productDetailType: any;
  insufficientAmountText: any;
  accountHasBillDotComAccountText: string;
  accountDoesNotHaveBillDotComAccountText: string;
  directDepositNotSetupText: string;
  resendInvitationText: string;
  resendInvitationClickedText: string;
  confirmationText: string;
  submissionModalText: string;
  submit: string;
  subTitle: string;
  enroll: string;
  learnAboutText: string;
  clickHereLinkText: string;
  learnAboutEnrollmentText: string;
  clickHereEnrollmentLink: string;
  clickHereEnrollmentLinkText: string;
  resendInviteText: string;
  currencySymbol: string;
  currencyAbbreviation: string;
  cantFindEnrollmentStatus: string;
  achSearch: any;
  userInfoHeader: string;
  categoryName: string;
  categoryId: string;
  categoryLocation: string;
  categoryEmail: string;
  modalHeader: string;
  modalConfirmButtonText: string;
  modalCancelButtonText: string;
  modalContactCustomerServiceButtonText: string;
  modalContinueButtonText: string;
  modalDoneButtonText: string;
  modalNoneMatchButtonText: string;
  modalSendInvitationButtonText: string;
}

const getWarning = (text) => {
  return (
    <p>
      <Icon name="fal fa-exclamation-triangle" />
      {' ' + text}
    </p>
  );
};

const setCheckAmount = (
  getCheckData,
  setCheckData,
  redemptionId,
  redemptionValue,
  redemptionDescription,
  userPoints,
  insuffAmountText,
  submit,
  currencySymbol,
  currencyAbbreviation,
  showSubmissionModal: boolean,
  setShowSubmissionModal: Function,
  image: string,
  submissionModalText: string,
  enrollmentUserInfo: Object,
  redemptionCall: Function,
  userInfoHeader: string,
  categoryName: string,
  categoryId: string,
  categoryLocation: string,
  categoryEmail: string,
  modalHeader: string,
  modalConfirmButtonText: string,
  modalCancelButtonText: string,
  modalContactCustomerServiceButtonText: string,
  isMobile?: boolean
) => {
  const countDecimals = function(value) {
    if (Math.floor(value) === value) return 0;
    const splitNumber = value.toString().split('.')[1].length;
    return splitNumber;
  };

  const checkInputValueDecimalCount = getCheckData && getCheckData !== '' ? countDecimals(parseFloat(getCheckData)) : '';
  const amountToSubmit = checkInputValueDecimalCount > 2 ? parseFloat(getCheckData).toFixed(2) : getCheckData;

  if (isMobile) {
    return (
      <div>
        <styles.TableRow>
          <styles.MobileRedeemInfoCell>
            <styles.AmountInput
              disabled={false}
              type="text"
              key={`redeem_` + redemptionId}
              name={`redeem_` + redemptionId}
              defaultValue={getCheckData}
              value={amountToSubmit}
              onChange={(e) => setCheckData(utils.allowDollarChars(e.target.value))}
              id="directDepositInput"
            />
          </styles.MobileRedeemInfoCell>
        </styles.TableRow>
        <styles.TableRow>
          <styles.MobileRedeemInfoCell>
            {amountToSubmit === '' ? (
              <div />
            ) : amountToSubmit <= userPoints ? (
              <styles.AmountText>
                {currencySymbol}
                {amountToSubmit} {currencyAbbreviation}
              </styles.AmountText>
            ) : (
              <styles.InsufficientAmountText>{getWarning(insuffAmountText)}</styles.InsufficientAmountText>
            )}
          </styles.MobileRedeemInfoCell>
        </styles.TableRow>
        <styles.TableRow>
          <styles.MobileRedeemInfoCell>
            <styles.MobileRedeemTypeDescription>{redemptionDescription}</styles.MobileRedeemTypeDescription>
          </styles.MobileRedeemInfoCell>
        </styles.TableRow>
        <styles.TableRow>
          <styles.MobileRedeemInfoCell>
            {amountToSubmit > userPoints || amountToSubmit === '' ? (
              <styles.MobileSubmitButtonDisabled id={submit} key={submit} disabled={true} onClick={() => setShowSubmissionModal(false)}>
                {submit}
              </styles.MobileSubmitButtonDisabled>
            ) : (
              <styles.MobileSubmitButton id={submit} key={submit} disabled={false} onClick={() => setShowSubmissionModal(true)}>
                {submit}
              </styles.MobileSubmitButton>
            )}
            <SubmissionModal
              show={showSubmissionModal}
              onHide={() => setShowSubmissionModal(false)}
              image={image}
              submissionModalText={submissionModalText}
              enrollmentUserInfo={enrollmentUserInfo}
              redemptionId={redemptionId}
              redemptionValue={redemptionValue}
              redemptionCall={redemptionCall}
              userInfoHeader={userInfoHeader}
              categoryName={categoryName}
              categoryId={categoryId}
              categoryLocation={categoryLocation}
              categoryEmail={categoryEmail}
              modalHeader={modalHeader}
              modalConfirmButtonText={modalConfirmButtonText}
              modalCancelButtonText={modalCancelButtonText}
              modalContactCustomerServiceButtonText={modalContactCustomerServiceButtonText}
            />
          </styles.MobileRedeemInfoCell>
        </styles.TableRow>
      </div>
    );
  } else {
    return (
      <div>
        <styles.TableRow>
          <styles.RedeemInfoCell>
            <styles.AmountInput
              disabled={false}
              type="text"
              key={`redeem_` + redemptionId}
              name={`redeem_` + redemptionId}
              defaultValue={getCheckData}
              value={amountToSubmit}
              onChange={(e) => setCheckData(utils.allowDollarChars(e.target.value))}
              id="directDepositInput"
            />
          </styles.RedeemInfoCell>
        </styles.TableRow>
        <styles.TableRow>
          <styles.RedeemInfoCell>
            {amountToSubmit === '' ? (
              <div />
            ) : amountToSubmit <= userPoints ? (
              <styles.AmountText>
                {currencySymbol}
                {amountToSubmit} {currencyAbbreviation}
              </styles.AmountText>
            ) : (
              <styles.InsufficientAmountText>{getWarning(insuffAmountText)}</styles.InsufficientAmountText>
            )}
          </styles.RedeemInfoCell>
        </styles.TableRow>
        <styles.TableRow>
          <styles.RedeemInfoCell>
            <styles.RedeemTypeDescription>{redemptionDescription}</styles.RedeemTypeDescription>
          </styles.RedeemInfoCell>
        </styles.TableRow>
        <styles.TableRow>
          <styles.RedeemInfoCell>
            {amountToSubmit > userPoints || amountToSubmit === '' ? (
              <styles.SubmitButtonDisabled id={submit} key={submit} disabled={true} onClick={() => setShowSubmissionModal(false)}>
                {submit}
              </styles.SubmitButtonDisabled>
            ) : (
              <styles.SubmitButton id={submit} key={submit} disabled={false} onClick={() => setShowSubmissionModal(true)}>
                {submit}
              </styles.SubmitButton>
            )}
            <SubmissionModal
              show={showSubmissionModal}
              onHide={() => setShowSubmissionModal(false)}
              image={image}
              submissionModalText={submissionModalText}
              enrollmentUserInfo={enrollmentUserInfo}
              redemptionId={redemptionId}
              redemptionValue={redemptionValue}
              redemptionCall={redemptionCall}
              userInfoHeader={userInfoHeader}
              categoryName={categoryName}
              categoryId={categoryId}
              categoryLocation={categoryLocation}
              categoryEmail={categoryEmail}
              modalHeader={modalHeader}
              modalConfirmButtonText={modalConfirmButtonText}
              modalCancelButtonText={modalCancelButtonText}
              modalContactCustomerServiceButtonText={modalContactCustomerServiceButtonText}
            />
          </styles.RedeemInfoCell>
        </styles.TableRow>
      </div>
    );
  }
};

const userNotEnrolled = (
  buttonText: string,
  enrollTodayTextp1: string,
  enrollTodayTextp2: string,
  image: string,
  accountHasBillDotComAccountText: string,
  accountDoesNotHaveBillDotComAccountText: string,
  confirmationText: string,
  achSearch: any,
  modalStatus: boolean,
  setModalStatus: any,
  sendInviteCall: Function,
  modalContinueButtonText: string,
  modalDoneButtonText: string,
  modalNoneMatchButtonText: string,
  modalSendInvitationButtonText: string,
  modalHeader: string,
  modalContactCustomerServiceButtonText: string,
  categoryId: string,
  categoryLocation: string,
  categoryEmail: string,
  learnAboutText: string,
  clickHereLinkText: string,
  learnAboutEnrollmentText: string,
  clickHereEnrollmentLink: string,
  clickHereEnrollmentLinkText: string
) => {
  return (
    <styles.UserNotEnrolled>
      <styles.TableRow>
        <styles.RedeemInfoCell>
          <styles.EnrollTodayDescription>
            <p>{enrollTodayTextp1}</p>
            <p>{enrollTodayTextp2}</p>
          </styles.EnrollTodayDescription>
        </styles.RedeemInfoCell>
      </styles.TableRow>
      <styles.TableRow>
        <styles.RedeemInfoCell>
          <styles.SubmitButton id={buttonText} key={buttonText} disabled={false} onClick={() => setModalStatus(true)}>
            {buttonText}
          </styles.SubmitButton>
          <EnrollmentModal
            show={modalStatus}
            onHide={() => setModalStatus(false)}
            image={image}
            selectAccountText={accountHasBillDotComAccountText}
            missingAccountText={accountDoesNotHaveBillDotComAccountText}
            confirmationText={confirmationText}
            achSearch={achSearch}
            sendInvitation={sendInviteCall}
            modalContinueButtonText={modalContinueButtonText}
            modalDoneButtonText={modalDoneButtonText}
            modalNoneMatchButtonText={modalNoneMatchButtonText}
            modalSendInvitationButtonText={modalSendInvitationButtonText}
            modalHeader={modalHeader}
            modalContactCustomerServiceButtonText={modalContactCustomerServiceButtonText}
            categoryId={categoryId}
            categoryLocation={categoryLocation}
            categoryEmail={categoryEmail}
          />
        </styles.RedeemInfoCell>
      </styles.TableRow>
      <styles.TableRow>
        <styles.RedeemInfoCell>
          <styles.learnMoreLink>
            <a href="https://www.bill.com/banks" target="_blank">
              {clickHereLinkText}
            </a>{' '}
            {learnAboutText}
          </styles.learnMoreLink>
        </styles.RedeemInfoCell>
      </styles.TableRow>
      <styles.TableRow>
        <styles.RedeemInfoCell>
          <styles.learnMoreLink>
            <a href={clickHereEnrollmentLink} target="_blank">
              {clickHereEnrollmentLinkText}
            </a>{' '}
            {learnAboutEnrollmentText}
          </styles.learnMoreLink>
        </styles.RedeemInfoCell>
      </styles.TableRow>
    </styles.UserNotEnrolled>
  );
};

const userEnrolledNotActivated = (
  buttonText: string,
  resendInvitationText: string,
  resendInvitationClickedText: string,
  resendInviteCall: Function,
  resendInviteClicked: boolean,
  setResendInviteClicked: Function
) => {
  return (
    <styles.ResendInvitation>
      <styles.TableRow>
        <styles.RedeemInfoCell>
          <styles.RedeemTypeDescription>
            {resendInviteClicked === false ? resendInvitationText : resendInvitationClickedText}
          </styles.RedeemTypeDescription>
        </styles.RedeemInfoCell>
      </styles.TableRow>
      <styles.TableRow>
        <styles.RedeemInfoCell>
          {resendInviteClicked === false ? (
            <styles.SubmitButton
              id={buttonText}
              key={buttonText}
              disabled={false}
              onClick={() => makeResendInviteCall(resendInviteCall, setResendInviteClicked)}
            >
              {buttonText}
            </styles.SubmitButton>
          ) : null}
        </styles.RedeemInfoCell>
      </styles.TableRow>
    </styles.ResendInvitation>
  );
};

const userEnrolledDirectDepositNotSetup = (directDepositNotSetupText: string) => {
  return (
    <styles.ResendInvitation>
      <styles.TableRow>
        <styles.RedeemInfoCell>
          <styles.RedeemTypeDescription>{directDepositNotSetupText}</styles.RedeemTypeDescription>
        </styles.RedeemInfoCell>
      </styles.TableRow>
    </styles.ResendInvitation>
  );
};

const userEnrolledAndActivated = (subTitle: string) => {
  return (
    <styles.EnrolledAndActivated>
      <styles.RedeemInfoCell>
        <styles.RedeemTypeDescription>{subTitle}</styles.RedeemTypeDescription>
      </styles.RedeemInfoCell>
    </styles.EnrolledAndActivated>
  );
};

const makeResendInviteCall = (resendInviteCall: Function, setResendInviteClicked: Function) => {
  resendInviteCall();
  setResendInviteClicked(true);
};

const desktopSize: number = 1025;
const tabletSize: number = 1024;
const mobileSize: number = 761;
const smallMobileSize: number = 400;

const DirectDeposit: React.SFC<DirectDepositProps> = ({
  directDepositOption,
  directDepositDescription,
  pointSummary,
  enrollmentStatus,
  enrollmentUserInfo,
  redemptionHistory,
  enrollTodayIntroTextp1,
  enrollTodayIntroTextp2,
  productDetailType = directDepositOption.id,
  submit,
  subTitle,
  enroll,
  resendInviteText,
  currencySymbol,
  currencyAbbreviation,
  cantFindEnrollmentStatus,
  achSearch,
  userInfoHeader,
  categoryName,
  categoryId,
  categoryLocation,
  categoryEmail,
  modalHeader,
  modalConfirmButtonText,
  modalCancelButtonText,
  modalContactCustomerServiceButtonText,
  modalContinueButtonText,
  modalDoneButtonText,
  modalNoneMatchButtonText,
  modalSendInvitationButtonText,
  directDepositNotSetupText,
  ...props
}) => {
  function getWindowSize() {
    const [getWindowSize, setWindowSize] = React.useState(window.innerWidth);
    React.useLayoutEffect(() => {
      const windowReSize = () => setWindowSize(window.innerWidth);
      window.addEventListener('resize', windowReSize);
      return () => {
        window.removeEventListener('resize', windowReSize);
      };
    }, []);
    return getWindowSize;
  }

  async function handleSubmit(event) {
    event.preventDefault();
  }

  let directDeposit = directDepositOption.filter((item) => item.id === productDetailType);

  if (directDeposit !== undefined && directDeposit.length > 0) {
    directDeposit = directDeposit[0];

    if (directDeposit.canRedeem) {
      const [getCheckData, setCheckData] = React.useState('');
      const [showEnrollmentModal, setShowEnrollmentModal] = React.useState(false);
      const [showSubmissionModal, setShowSubmissionModal] = React.useState(false);
      const [resendInviteClicked, setResendInviteClicked] = React.useState(false);
      const imageDir = '/clientassets/images/products/';
      const mobileImageDir = imageDir + 'mobile/';
      const tabletImageDir = imageDir + 'tablet/';
      const id = directDeposit.id;
      const name = directDeposit.name;
      const description = directDepositDescription;
      const imgUrl = imageDir + directDeposit.imageUrl;
      const tabletImgUrl = tabletImageDir + directDeposit.imageUrl;
      const mobileImgUrl = mobileImageDir + directDeposit.imageUrl;
      const smallMobileImgUrl = imageDir + directDeposit.imageUrl;
      const userPoints = pointSummary === undefined ? undefined : utils.calculateUserPoints(pointSummary.available);
      const inputAmount = document.getElementById('directDepositInput') as HTMLInputElement;
      const inputValue = inputAmount === null ? null : inputAmount.value;
      const resendInviteCall = props.actions.achResendInvite;
      const sendInviteCall = props.actions.achSendInvite;
      const redemptionCall = props.actions.saveMagnifeyeRedeem;
      const enrolled = 'Enrolled';
      const notEnrolled = 'NotEnrolled';
      const pendingInvitation = 'PendingInvitation';
      const directDepositNotSetup = 'DirectDepositNotSetup';
      const enrollTodayTextp1 = enrollTodayIntroTextp1;
      const enrollTodayTextp2 = enrollTodayIntroTextp2;
      const {
        insufficientAmountText,
        accountHasBillDotComAccountText,
        accountDoesNotHaveBillDotComAccountText,
        resendInvitationText,
        resendInvitationClickedText,
        confirmationText,
        submissionModalText,
        learnAboutText,
        clickHereLinkText,
        clickHereEnrollmentLink,
        clickHereEnrollmentLinkText,
        learnAboutEnrollmentText,
      } = props;

      const windowSize = getWindowSize();

      if (windowSize > desktopSize) {
        return (
          <div {...props}>
            <styles.RedeemContainer>
              <styles.RedeemForm {...props} onSubmit={handleSubmit}>
                <styles.RedeemTable>
                  <styles.TableRow>
                    <styles.ImageCell>
                      <img src={imgUrl} />
                    </styles.ImageCell>

                    <styles.RedeemInfoCellFrame>
                      <styles.RedeemInfoTable>
                        {enrollmentStatus === enrolled ? (
                          <styles.TableRow>
                            <styles.AmountHeader>{name}</styles.AmountHeader>
                          </styles.TableRow>
                        ) : null}
                        <styles.TableRow>
                          {enrollmentStatus === notEnrolled
                            ? userNotEnrolled(
                                enroll,
                                enrollTodayTextp1,
                                enrollTodayTextp2,
                                imgUrl,
                                accountHasBillDotComAccountText,
                                accountDoesNotHaveBillDotComAccountText,
                                confirmationText,
                                achSearch,
                                showEnrollmentModal,
                                setShowEnrollmentModal,
                                sendInviteCall,
                                modalContinueButtonText,
                                modalDoneButtonText,
                                modalNoneMatchButtonText,
                                modalSendInvitationButtonText,
                                modalHeader,
                                modalContactCustomerServiceButtonText,
                                categoryId,
                                categoryLocation,
                                categoryEmail,
                                learnAboutText,
                                clickHereLinkText,
                                learnAboutEnrollmentText,
                                clickHereEnrollmentLink,
                                clickHereEnrollmentLinkText
                              )
                            : enrollmentStatus === pendingInvitation
                            ? userEnrolledNotActivated(
                                resendInviteText,
                                resendInvitationText,
                                resendInvitationClickedText,
                                resendInviteCall,
                                resendInviteClicked,
                                setResendInviteClicked
                              )
                            : enrollmentStatus === directDepositNotSetup
                            ? userEnrolledDirectDepositNotSetup(directDepositNotSetupText)
                            : enrollmentStatus === enrolled
                            ? userEnrolledAndActivated(subTitle)
                            : cantFindEnrollmentStatus}
                        </styles.TableRow>
                        {enrollmentStatus === enrolled
                          ? setCheckAmount(
                              getCheckData,
                              setCheckData,
                              id,
                              inputValue,
                              description,
                              userPoints,
                              insufficientAmountText,
                              submit,
                              currencySymbol,
                              currencyAbbreviation,
                              showSubmissionModal,
                              setShowSubmissionModal,
                              imgUrl,
                              submissionModalText,
                              enrollmentUserInfo,
                              redemptionCall,
                              userInfoHeader,
                              categoryName,
                              categoryId,
                              categoryLocation,
                              categoryEmail,
                              modalHeader,
                              modalConfirmButtonText,
                              modalCancelButtonText,
                              modalContactCustomerServiceButtonText,
                              false
                            )
                          : null}
                      </styles.RedeemInfoTable>
                    </styles.RedeemInfoCellFrame>
                  </styles.TableRow>
                </styles.RedeemTable>
              </styles.RedeemForm>
              {redemptionHistory !== undefined ? (
                <RebateModal
                  image={imgUrl}
                  value={null}
                  quantity={null}
                  orderTotal={redemptionHistory.amount}
                  confirmationNumber={redemptionHistory.id}
                />
              ) : null}
            </styles.RedeemContainer>
          </div>
        );
      } else if (windowSize <= desktopSize && windowSize <= tabletSize && windowSize > mobileSize) {
        return (
          <styles.RedeemContainer>
            <styles.RedeemForm {...props} onSubmit={handleSubmit}>
              <styles.RedeemTable>
                <styles.TableRow>
                  <styles.ImageCell>
                    <img src={tabletImgUrl} />
                  </styles.ImageCell>

                  <styles.RedeemInfoCellFrame>
                    <styles.RedeemInfoTable>
                      <styles.TableRow>
                        <styles.AmountHeader>{name}</styles.AmountHeader>
                      </styles.TableRow>

                      <styles.TableRow>
                        {enrollmentStatus === notEnrolled
                          ? userNotEnrolled(
                              enroll,
                              enrollTodayTextp1,
                              enrollTodayTextp2,
                              imgUrl,
                              accountHasBillDotComAccountText,
                              accountDoesNotHaveBillDotComAccountText,
                              confirmationText,
                              achSearch,
                              showEnrollmentModal,
                              setShowEnrollmentModal,
                              sendInviteCall,
                              modalContinueButtonText,
                              modalDoneButtonText,
                              modalNoneMatchButtonText,
                              modalSendInvitationButtonText,
                              modalHeader,
                              modalContactCustomerServiceButtonText,
                              categoryId,
                              categoryLocation,
                              categoryEmail,
                              learnAboutText,
                              clickHereLinkText,
                              learnAboutEnrollmentText,
                              clickHereEnrollmentLink,
                              clickHereEnrollmentLinkText
                            )
                          : enrollmentStatus === pendingInvitation
                          ? userEnrolledNotActivated(
                              resendInviteText,
                              resendInvitationText,
                              resendInvitationClickedText,
                              resendInviteCall,
                              resendInviteClicked,
                              setResendInviteClicked
                            )
                          : enrollmentStatus === directDepositNotSetup
                          ? userEnrolledDirectDepositNotSetup(directDepositNotSetupText)
                          : enrollmentStatus === enrolled
                          ? userEnrolledAndActivated(subTitle)
                          : cantFindEnrollmentStatus}
                      </styles.TableRow>
                      {enrollmentStatus === enrolled
                        ? setCheckAmount(
                            getCheckData,
                            setCheckData,
                            id,
                            inputValue,
                            description,
                            userPoints,
                            insufficientAmountText,
                            submit,
                            currencySymbol,
                            currencyAbbreviation,
                            showSubmissionModal,
                            setShowSubmissionModal,
                            imgUrl,
                            submissionModalText,
                            enrollmentUserInfo,
                            redemptionCall,
                            userInfoHeader,
                            categoryName,
                            categoryId,
                            categoryLocation,
                            categoryEmail,
                            modalHeader,
                            modalConfirmButtonText,
                            modalCancelButtonText,
                            modalContactCustomerServiceButtonText,
                            false
                          )
                        : null}
                    </styles.RedeemInfoTable>
                  </styles.RedeemInfoCellFrame>
                </styles.TableRow>
              </styles.RedeemTable>
            </styles.RedeemForm>
            {redemptionHistory !== undefined ? (
              <RebateModal
                image={imgUrl}
                value={null}
                quantity={null}
                orderTotal={redemptionHistory.amount}
                confirmationNumber={redemptionHistory.id}
              />
            ) : null}
          </styles.RedeemContainer>
        );
      } else if (windowSize < desktopSize && windowSize < tabletSize && windowSize <= mobileSize) {
        return (
          <styles.MobileRedeemContainer>
            <styles.RedeemForm {...props} onSubmit={handleSubmit}>
              <styles.RedeemTable>
                <styles.TableRow>
                  <styles.MobileRedeemInfoCell>
                    <styles.AmountHeader>{name}</styles.AmountHeader>
                  </styles.MobileRedeemInfoCell>
                </styles.TableRow>
                <styles.TableRow>
                  <styles.MobileRedeemInfoCell>
                    <styles.MobileRedeemInfoTable>
                      <styles.TableRow>
                        <styles.MobileImageCell>
                          <img src={mobileImgUrl} />
                        </styles.MobileImageCell>
                      </styles.TableRow>
                      <styles.TableRow>
                        {enrollmentStatus === notEnrolled
                          ? userNotEnrolled(
                              enroll,
                              enrollTodayTextp1,
                              enrollTodayTextp2,
                              imgUrl,
                              accountHasBillDotComAccountText,
                              accountDoesNotHaveBillDotComAccountText,
                              confirmationText,
                              achSearch,
                              showEnrollmentModal,
                              setShowEnrollmentModal,
                              sendInviteCall,
                              modalContinueButtonText,
                              modalDoneButtonText,
                              modalNoneMatchButtonText,
                              modalSendInvitationButtonText,
                              modalHeader,
                              modalContactCustomerServiceButtonText,
                              categoryId,
                              categoryLocation,
                              categoryEmail,
                              learnAboutText,
                              clickHereLinkText,
                              learnAboutEnrollmentText,
                              clickHereEnrollmentLink,
                              clickHereEnrollmentLinkText
                            )
                          : enrollmentStatus === pendingInvitation
                          ? userEnrolledNotActivated(
                              resendInviteText,
                              resendInvitationText,
                              resendInvitationClickedText,
                              resendInviteCall,
                              resendInviteClicked,
                              setResendInviteClicked
                            )
                          : enrollmentStatus === directDepositNotSetup
                          ? userEnrolledDirectDepositNotSetup(directDepositNotSetupText)
                          : enrollmentStatus === enrolled
                          ? userEnrolledAndActivated(subTitle)
                          : cantFindEnrollmentStatus}
                      </styles.TableRow>
                      {enrollmentStatus === enrolled
                        ? setCheckAmount(
                            getCheckData,
                            setCheckData,
                            id,
                            inputValue,
                            description,
                            userPoints,
                            insufficientAmountText,
                            submit,
                            currencySymbol,
                            currencyAbbreviation,
                            showSubmissionModal,
                            setShowSubmissionModal,
                            imgUrl,
                            submissionModalText,
                            enrollmentUserInfo,
                            redemptionCall,
                            userInfoHeader,
                            categoryName,
                            categoryId,
                            categoryLocation,
                            categoryEmail,
                            modalHeader,
                            modalConfirmButtonText,
                            modalCancelButtonText,
                            modalContactCustomerServiceButtonText,
                            true
                          )
                        : null}
                    </styles.MobileRedeemInfoTable>
                  </styles.MobileRedeemInfoCell>
                </styles.TableRow>
              </styles.RedeemTable>
            </styles.RedeemForm>
            {redemptionHistory !== undefined ? (
              <RebateModal
                image={imgUrl}
                value={null}
                quantity={null}
                orderTotal={redemptionHistory.amount}
                confirmationNumber={redemptionHistory.id}
              />
            ) : null}
          </styles.MobileRedeemContainer>
        );
      } else if (windowSize < desktopSize && windowSize < tabletSize && windowSize < mobileSize && windowSize <= smallMobileSize) {
        return (
          <styles.MobileRedeemContainer>
            <styles.RedeemForm {...props} onSubmit={handleSubmit}>
              <styles.RedeemTable>
                <styles.TableRow>
                  <styles.MobileImageCell>
                    <img src={smallMobileImgUrl} />
                  </styles.MobileImageCell>
                </styles.TableRow>
                <styles.TableRow>
                  <styles.MobileRedeemInfoCell>
                    <styles.MobileRedeemInfoTable>
                      <styles.TableRow>
                        <styles.AmountHeader>{name}</styles.AmountHeader>
                      </styles.TableRow>

                      <styles.TableRow>
                        {enrollmentStatus === notEnrolled
                          ? userNotEnrolled(
                              enroll,
                              enrollTodayTextp1,
                              enrollTodayTextp2,
                              imgUrl,
                              accountHasBillDotComAccountText,
                              accountDoesNotHaveBillDotComAccountText,
                              confirmationText,
                              achSearch,
                              showEnrollmentModal,
                              setShowEnrollmentModal,
                              sendInviteCall,
                              modalContinueButtonText,
                              modalDoneButtonText,
                              modalNoneMatchButtonText,
                              modalSendInvitationButtonText,
                              modalHeader,
                              modalContactCustomerServiceButtonText,
                              categoryId,
                              categoryLocation,
                              categoryEmail,
                              learnAboutText,
                              clickHereLinkText,
                              learnAboutEnrollmentText,
                              clickHereEnrollmentLink,
                              clickHereEnrollmentLinkText
                            )
                          : enrollmentStatus === pendingInvitation
                          ? userEnrolledNotActivated(
                              resendInviteText,
                              resendInvitationText,
                              resendInvitationClickedText,
                              resendInviteCall,
                              resendInviteClicked,
                              setResendInviteClicked
                            )
                          : enrollmentStatus === directDepositNotSetup
                          ? userEnrolledDirectDepositNotSetup(directDepositNotSetupText)
                          : enrollmentStatus === enrolled
                          ? userEnrolledAndActivated(subTitle)
                          : cantFindEnrollmentStatus}
                      </styles.TableRow>
                      {enrollmentStatus === 'Enrolled'
                        ? setCheckAmount(
                            getCheckData,
                            setCheckData,
                            id,
                            inputValue,
                            description,
                            userPoints,
                            insufficientAmountText,
                            submit,
                            currencySymbol,
                            currencyAbbreviation,
                            showSubmissionModal,
                            setShowSubmissionModal,
                            imgUrl,
                            submissionModalText,
                            enrollmentUserInfo,
                            redemptionCall,
                            userInfoHeader,
                            categoryName,
                            categoryId,
                            categoryLocation,
                            categoryEmail,
                            modalHeader,
                            modalConfirmButtonText,
                            modalCancelButtonText,
                            modalContactCustomerServiceButtonText,
                            true
                          )
                        : null}
                    </styles.MobileRedeemInfoTable>
                  </styles.MobileRedeemInfoCell>
                </styles.TableRow>
              </styles.RedeemTable>
            </styles.RedeemForm>
            {redemptionHistory !== undefined ? (
              <RebateModal
                image={imgUrl}
                value={null}
                quantity={null}
                orderTotal={redemptionHistory.amount}
                confirmationNumber={redemptionHistory.id}
              />
            ) : null}
          </styles.MobileRedeemContainer>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ saveMagnifeyeRedeem, achSendInvite, achResendInvite }, dispatch),
});

const mapStateToProps = (state) => ({
  directDepositOption: redeemOptionSelector.selectMany(state),
  pointSummary: pointSummarySelector.selectMany(state)[0],
  redemptionHistory: redemptionHistorySelector.selectMany(state)[0],
  enrollmentStatus: getEnrollmentStatus(state),
  enrollmentUserInfo: getEnrollmentUserInfo(state),
  achSearch: getAchSearchResults(state),
});

const mapContentToProps = (getContent) => ({
  enrollTodayIntroTextp1: getContent('enrollTodayIntroTextp1'),
  enrollTodayIntroTextp2: getContent('enrollTodayIntroTextp2'),
  productDetailType: getContent('productDetailType'),
  directDepositDescription: getContent('directDepositDescription'),
  insufficientAmountText: getContent('insufficientAmountText'),
  accountHasBillDotComAccountText: getContent('enrollModalHaveBillDotComAccountText'),
  accountDoesNotHaveBillDotComAccountText: getContent('enrollModalDoesNotHaveBillDotComAccountText'),
  directDepositNotSetupText: getContent('directDepositNotSetupText'),
  resendInvitationText: getContent('resendInvitationText'),
  resendInvitationClickedText: getContent('resendInvitationClickedText'),
  confirmationText: getContent('confirmationText'),
  submissionModalText: getContent('submissionModalText'),
});

const mapResourceToProps = (getResource) => ({
  subTitle: getResource('DirectDeposit.subTitle', 'Rebate Payout'),
  submit: getResource('DirectDeposit.submit', 'Continue'),
  enroll: getResource('DirectDeposit.enroll', 'Enroll Today!'),
  learnAboutText: getResource('DirectDeposit.learnAboutText', 'to learn more about bill.com'),
  clickHereLinkText: getResource('DirectDeposit.clickHereLinkText', 'Click here'),
  learnAboutEnrollmentText: getResource('DirectDeposit.learnAboutText', 'to learn more about the enrollment process'),
  clickHereEnrollmentLink: getResource(
    'DirectDeposit.enrollmentLink',
    '/clientassets/100114/pdfs/Direct_Deposit_Enrollment_Process_V03.pdf'
  ),
  clickHereEnrollmentLinkText: getResource('DirectDeposit.clickHereLinkText', 'Click here'),
  resendInviteText: getResource('DirectDeposit.resendInviteText', 'Resend Invitation'),
  currencySymbol: getResource('DirectDeposit.currencySymbol', '$'),
  currencyAbbreviation: getResource('DirectDeposit.currencyAbbreviation', 'USD'),
  cantFindEnrollmentStatus: getResource(
    'DirectDeposit.cantFindEnrollmentStatus',
    'Please try again later or contact Customer Support by clicking on the help icon in the bottom corner of your screen.'
  ),
  userInfoHeader: getResource('DirectDeposit.SubmissionModal.userInfoHeader', 'YOUR INFORMATION'),
  categoryName: getResource('DirectDeposit.SubmissionModal.categoryName', 'Practice Name'),
  categoryId: getResource('DirectDeposit.SubmissionModal.categoryId', 'Network Id'),
  categoryLocation: getResource('DirectDeposit.SubmissionModal.categoryLocation', 'Practice Location'),
  categoryEmail: getResource('DirectDeposit.SubmissionModal.categoryEmail', 'Email Address'),
  modalHeader: getResource('DirectDeposit.ModalHeader', 'Direct Deposit'),
  modalConfirmButtonText: getResource('DirectDeposit.ConfirmButtonText', 'CONFIRM & SUBMIT'),
  modalCancelButtonText: getResource('DirectDeposit.CancelButtonText', 'CANCEL'),
  modalContactCustomerServiceButtonText: getResource(
    'DirectDeposit.ContactCustomerServiceButtonText',
    'CONTACT CUSTOMER SERVICE FOR ASSISTANCE'
  ),
  modalContinueButtonText: getResource('DirectDeposit.ContinueButtonText', 'CONTINUE'),
  modalDoneButtonText: getResource('DirectDeposit.DoneButtonText', 'DONE'),
  modalNoneMatchButtonText: getResource('DirectDeposit.NoneMatchButtonText', 'NONE OF THESE MATCH MY PRACTICE'),
  modalSendInvitationButtonText: getResource('DirectDeposit.SendInvitationButtonText', 'SEND INVITATION'),
});

export default register('alcon-products/DirectDeposit')(
  connect(mapStateToProps, mapDispatchToProps),
  withApiDataProvider(fetchRedeemOptions, redeemOptionSelector),
  withApiDataProvider(getEnrolledStatus, enrollmentStatusSelector),
  withApiDataProvider(getAchSearch, achSearchInformation),
  withTheme(),
  withContent(mapContentToProps),
  withResource(mapResourceToProps)
)(DirectDeposit);
