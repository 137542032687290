import { authStateSelectors, userStateSelectors } from 'rsv8-auth';
import { clientStateSelectors } from 'rsv8-client';
import { getConfigurationProperty } from 'xcel-config';
import { cmsApi } from '../../../app/api';
import {
  contentCultureSelector,
  contentDetailSelector,
  contentSelector,
  contentTypeSelector,
  contentVersionSelector,
  routeSelector
  } from '../../redux';
import contentActions from '../../redux/actions/content';
import contentCultureActions from '../../redux/actions/contentCulture';
import contentTypeActions from '../../redux/actions/contentType';
import contentVersionActions from '../../redux/actions/contentVersion';
import routeActions from '../../redux/actions/route';
import { PLUGIN_UPDATED } from './events';
export const pluginUpdated = (contentTypeId: string, data: any, method: string) => {
  let updatedTimeout;
  return (dispatch, getState) => {
    clearTimeout(updatedTimeout);
    updatedTimeout = setTimeout(() => {
      dispatch({
        type: PLUGIN_UPDATED,
        payload: { data: data, type: contentTypeId, method },
        contentTypeId
      });
    }, 100);
  };
};
let plugins = {};
export const getPlugins = () => plugins;
const singleContentActions = (contentTypeId, contentKey, forceCultureId) => {
  return {
    init: ({ store }) => {
      const { subscribe, dispatch, getState } = store;
      // register self with plugins
      plugins[contentTypeId] = singleContentActions(contentTypeId, contentKey, forceCultureId);
      console.log(`Starting plugin ${contentTypeId}`);
      const plugin = singleContentActions(contentTypeId, contentKey, forceCultureId);
      let current = { language: undefined, clientId: undefined };
      let isRequesting = false;
      let hasInitialized = false;
      // by using client module it will watch the user and will automatically pull client information
      // so that we have the current client once the page loads.
      subscribe(async () => {
        const state = getState();
        const culture = clientStateSelectors.getCultureActive(state);
        const client = clientStateSelectors.getClientActive(state);
        const isAdminUser = userStateSelectors.isAdminUser(state);
        const cultureChanged = culture && current.language !== culture.language && culture.language !== undefined;
        const clientChanged = client && current.clientId !== client.id;
        const auth = authStateSelectors.getAuth(state);
        if (cultureChanged) {
          current.language = culture.language;
        }
        if (clientChanged) {
          current.clientId = client.id;
        }
        let identityServerConfigData = getConfigurationProperty(['identityServerConfigData']) as any;
        if (
          (clientChanged || cultureChanged || !hasInitialized) &&
          !isRequesting &&
          (identityServerConfigData ? true : auth.accessToken && auth.isValid)
        ) {
          if (!isAdminUser && current.clientId !== 0) {
            isRequesting = true;
            hasInitialized = true;
            console.log(`Loading plugin ${contentTypeId} data`);
            try {
              await dispatch(plugin.get());
            } catch (e) {
              console.warn(e);
            } finally {
              isRequesting = false;
            }
          }
        }
      });
    },
    get: () => async (dispatch, getState) => {
      const state = getState();
      const user = userStateSelectors.getUser(state);
      const auth = authStateSelectors.getAuth(state);
      let response;
      if ((!auth || auth.isOAuth || !auth.isValid) && user.clientId) {
        response = await cmsApi.getContentsPublic(
          { path: `/${contentKey}`, contentType: contentTypeId },
          { forceCultureId }
        );
      } else {
        response = await cmsApi.getContents({ path: `/${contentKey}`, contentType: contentTypeId }, { forceCultureId });
      }
      dispatch({
        type: PLUGIN_UPDATED,
        payload: { data: response && response.content, type: contentTypeId }
      });
    },
    adminGet: () => async (dispatch, getState) => {
      // first determine if type exists.
      await dispatch(contentTypeActions.get());
      const contentType = contentTypeSelector.searchOne(
        getState(),
        (contentTypeModel) => contentTypeModel.id === contentTypeId
      );
      // if this content type doesn't exist create it.
      if (contentType === null) {
        console.log('creating content type ', contentTypeId);
        await dispatch(contentTypeActions.create(contentTypeId, contentTypeId));
      }
      await dispatch(routeActions.getRoot(contentTypeId));
      let rootRoute = routeSelector.searchOne(getState(), (r) => r.contentType && r.contentType.id === contentTypeId);
      let content = contentSelector.searchOne(getState(), (contentTypeModel) => contentTypeModel.key === contentKey);

      if (!rootRoute) {
        console.log('failed to find root route');
        await dispatch(contentTypeActions.createRootRoute(contentTypeId));
        rootRoute = routeSelector.searchOne(getState(), (r) => r.contentType && r.contentType.id === contentTypeId);
      }
      if (!content) {
        // we need to create content.
        await dispatch(contentActions.create(contentTypeId, rootRoute.id, contentKey));
        content = contentSelector.searchOne(getState(), (contentTypeModel) => contentTypeModel.key === contentKey);
      }
      dispatch(contentActions.setActive(contentTypeId, content.id.toString()));
      // now we need content detail.
      await dispatch(contentActions.getById(contentTypeId, content.id));
      let culture = contentCultureSelector.getActive(getState(), contentTypeId);
      let contentDetail = contentDetailSelector.selectOne(getState(), content.id.toString());
      if (!culture) culture = contentDetail.cultures.find((c) => c.id === 0) as any;
      if (culture === undefined) {
        // we need to create content culture
        await dispatch(contentCultureActions.create(contentTypeId, content.id, 0, 'Default'));
        contentDetail = contentDetailSelector.selectOne(getState(), content.id.toString());
        culture = contentDetail.cultures[0] as any;
      }
      await dispatch(contentVersionActions.get(contentTypeId, culture.id));
      const version = contentVersionSelector.getActiveVersion(getState(), contentTypeId);

      dispatch(contentActions.setEdit(contentTypeId, version));
    },
    setProperty: (data: any) => (dispatch, getState) => {
      dispatch(contentActions.setEditContentProperty(contentTypeId, data));
      dispatch(
        pluginUpdated(contentTypeId, contentSelector.getEditContentProperties(getState(), contentTypeId), 'setProperty')
      );
    },
    setActive: () => contentTypeActions.setActive(contentTypeId),
    setCultureId: (cultureId: string) => contentCultureActions.setActiveByCultureId(contentTypeId, cultureId),
    setProviderId: (providerId: string) => contentCultureActions.setActiveByProviderId(contentTypeId, providerId),
    setActiveVersion: (versionId: any) => contentVersionActions.setActive(contentTypeId, versionId),
    setRaw: (raw: any) => contentActions.setRaw(contentTypeId, raw),
    setEdit: (content: any) => contentActions.setEdit(contentTypeId, content),
    publish: () => contentVersionActions.publish(contentTypeId),
    update: () => contentVersionActions.update(contentTypeId),
    updatePublishStatus: (isPublished: boolean) => (dispatch, getState) => {
      const version = contentVersionSelector.getActiveVersion(getState(), contentTypeId);
      dispatch(contentVersionActions.updatePublishStatus(contentTypeId, version.id, isPublished));
    }
  };
};
export default singleContentActions;
