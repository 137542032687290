import { ensureArray } from 'xcel-util';
import { Resources } from '../../../types';
import * as events from '../../events';
import { teamNominationSelectors } from '../../selectors/index';
import { nominationActivityActions } from '../nominationActivity';
import { clearTeamNomination } from './clearCurrent';

export const selectAwardBase = (appKey) => {
  return {
    type: events.SELECT_TEAM_AWARD,
    payload: appKey
  };
};

const selectAward = (value, confirmText = 'Changing award now will clear later steps.') => (dispatch, getState) => {
  const state = getState();
  if (teamNominationSelectors.getCurrentAwardId(state) !== value) {
    if (teamNominationSelectors.hasCustomFieldsOrNominees(state)) {
      if (confirm(confirmText)) {
        dispatch(clearTeamNomination());
        const currentNominationId = teamNominationSelectors.getCurrentNominationId(state);
        if (currentNominationId !== null) {
          dispatch(nominationActivityActions.destroyNomination(currentNominationId.toString()));
        }
        dispatch(selectAwardBase(value));
      }
    } else {
      dispatch(selectAwardBase(value));
    }
  }
};

const selectNominee = (value: Resources.Nominee) => ({
  type: events.TOGGLE_TEAM_NOMINEE,
  payload: value.id
});

const selectCustomFields = (fields) => {
  return {
    type: events.SELECT_TEAM_CUSTOM_FIELDS,
    payload: Object.keys(fields).map((k) => ({ id: k.replace(/'/g, ''), values: ensureArray(fields[k]) }))
  };
};

const setDefaultTeamMemberValues = (key, teamMemberKey) => (value) => ({
  type: events.SET_DEFAULT_TEAM_MEMBER_VALUE,
  payload: { key, teamMemberKey, value }
});

export const setDefaultTeamMemberAwardValue = setDefaultTeamMemberValues('defaultAwardValue', 'awardValue');
export const setDefaultTeamMemberProductValue = setDefaultTeamMemberValues('defaultProductValue', 'productValue');
export const setDefaultTeamMemberRoleId = setDefaultTeamMemberValues('defaultRoleId', 'roleId');

export { selectAward, selectCustomFields, selectNominee };
