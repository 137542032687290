import { toast } from 'rsv8-components';
import { destroy, destroyRequest } from 'xcel-redux-orm';
import { getAccountSummary } from '../../actions/account/getSummary';
import { cartApiActions } from './cartApiActions';
import { getCart } from './getCart';

const deleteCartById = (product: any) => async (dispatch, getState) => {
  await dispatch(cartApiActions.deleteCartById({ id: product.id }, { jsonApi: true }));

  toast({
    template: 'CatalogBody',
    productImage: product.imageSm,
    productName: product.name || product.productName,
    productManufacturer: product.manufacturer,
    actionText: `has been removed from cart`
  });
  await dispatch(destroy('cartItem', product.id));
  dispatch(destroyRequest('getSummary'));
  dispatch(getAccountSummary());
  const response = dispatch(getCart());

  return response.data;
};

export { deleteCartById };
