import * as React from 'react';
import styled from 'styled-components';

const TranslationIconBase = styled.div`
  position: relative;
  svg {
    position: absolute;
    fill: white;
    width: 40px;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
`;

const TranslationIcon = (props) => {
  return (
    <TranslationIconBase {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 224">
        <title>Translate</title>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path
              className="cls-1"
              d="M240,0H120a16,16,0,0,0-16,16V64h8V16a8,8,0,0,1,8-8H240a8,8,0,0,1,8,8V96a8,8,0,0,1-8,8h-8v28.69l-26.34-26.35L203.31,104H160v8h40l40,40V112a16,16,0,0,0,16-16V16A16,16,0,0,0,240,0Z"
            />
            <path
              className="cls-1"
              d="M160,88v2.22a112.6,112.6,0,0,0,20-15.68,113.92,113.92,0,0,0,21.93,16.88,4,4,0,1,0,4.14-6.84,106.81,106.81,0,0,1-20.68-15.94C196.85,54.81,199.44,42.35,199.94,36H208a4,4,0,0,0,0-8H184V24a4,4,0,0,0-8,0v4H152a4,4,0,0,0,0,8h39.93c-.49,5.14-2.71,15.16-11.93,26.63A57.12,57.12,0,0,1,169.73,44h-8.28c1.74,6.63,5.44,15.32,13.16,24.64A100.86,100.86,0,0,1,159,81.31,23.83,23.83,0,0,1,160,88Z"
            />
            <path
              className="cls-1"
              d="M136,72H16A16,16,0,0,0,0,88v80a16,16,0,0,0,16,16v40l40-40h80a16,16,0,0,0,16-16V88A16,16,0,0,0,136,72Zm8,96a8,8,0,0,1-8,8H52.69l-2.35,2.34L24,204.69V176H16a8,8,0,0,1-8-8V88a8,8,0,0,1,8-8H136a8,8,0,0,1,8,8Z"
            />
            <path
              className="cls-1"
              d="M79.75,94.6a4,4,0,0,0-7.49,0l-24,64a4,4,0,1,0,7.49,2.8l5-13.4H91.23l5,13.4A4,4,0,0,0,100,164a4.11,4.11,0,0,0,1.41-.25,4,4,0,0,0,2.34-5.15Zm-16,45.4L76,107.39,88.23,140Z"
            />
          </g>
        </g>
      </svg>
    </TranslationIconBase>
  );
};
export default TranslationIcon;
