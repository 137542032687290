import * as React from 'react';
import { Radio } from 'react-bootstrap';
import styled from 'styled-components';
import { FIRST_COLUMN_WIDTH } from './styles';

const MyRadio = styled(Radio)`
  input[type='radio'] {
    width: ${FIRST_COLUMN_WIDTH};
    height: ${FIRST_COLUMN_WIDTH};
  }
`;

const RadioComponent = (props) => <MyRadio readOnly={true} {...props} />;

export default RadioComponent;
