import * as React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Card, CardBody, toast } from 'rsv8-components';
import { Amount, CustomerProduct, Patient } from 'xcel-api-generator/dist/alcon';
import { assetService } from 'xcel-asset-service';
import { register, withContent, withResource, withTheme } from 'xcel-react-core';
import { withApiDataProvider } from 'xcel-redux-orm';
import { convertCustomerProductToPatient, getRoundedValue, getValueOrZero, isNumber } from '../../../utils';
import {
  createMagnifeyeProductClearCustomValues,
  createMagnifeyeProductV2Save,
  getMagnifeyeV2Summary,
  updatePatients,
} from '../../redux/actions';
import * as selectors from '../../redux/selectors';
import NoDataSpinner from '../NoDataSpinner';
import KeystoneProducts from './KeystoneProducts';
import ProductComponent from './ProductComponent';
import ProductFamilyComponent from './ProductFamilyComponent';
import {
  AlignedCol,
  ContentRow,
  HeaderRowWithBackground,
  PopoverContent,
  ProjectionValue,
  StyledNames,
  StyledNote,
  StyledProductContainer,
} from './styles';

const ProductBreakdown: React.FC<Props> = ({
  className,

  actions: { createMagnifeyeProductV2Save, createMagnifeyeProductClearCustomValues, updatePatients },

  resource: {
    headerBackground,
    headerLogo,
    headerNote,
    totalPatientsLabel,
    totalPatientsTooltip,
    totalPatientsTooltipArchived,
    totalPatientsNote,
    calculateBtnLabel,
    resetBtnLabel,
    saveProductsMessage,
    saveProductsFailedMessage,
    resetProductsMessage,
    resetProductsFailedMessage,
  },

  target,
  custom,
  runRate,
  customerProducts,
  patientsUpdated,
  keystonePatientsUpdated,
}) => {
  const productFamilyKeySet = React.useMemo(
    () =>
      !Array.isArray(customerProducts)
        ? []
        : Array.from(customerProducts.reduce((set, product) => set.add(product.familyKey), new Set<string>())).sort(),
    [customerProducts]
  );

  React.useEffect(() => {
    const patients = !Array.isArray(customerProducts) ? [] : customerProducts.map((product) => convertCustomerProductToPatient(product));
    if (patients.length > 0) {
      updatePatients(patients);
    }
  }, [customerProducts]);

  if (!target || !customerProducts || !Array.isArray(customerProducts)) {
    return <NoDataSpinner />;
  }

  const targetPatients = getValueOrZero(target.patients);
  const totalPatients = getValueOrZero(custom.patients) > 0 ? custom.patients : runRate.patients;
  const isTargetAvailable = isNumber(target.patients) && target.patients > 0;
  const neededPatients = targetPatients - totalPatients;
  const isTargetArchived = totalPatients >= targetPatients;

  const onSaveClick = async () => {
    try {
      await createMagnifeyeProductV2Save({ patients: patientsUpdated, keystonePatients: keystonePatientsUpdated });
      toast(
        {
          template: 'Default',
          message: saveProductsMessage,
        },
        {
          className: 'success-toast-body',
          closeButton: false,
        }
      );
    } catch (error) {
      console.error(error);
      toast(
        {
          template: 'Default',
          themeVariation: 'error-text',
          message: saveProductsFailedMessage,
        },
        {
          className: 'error-toast-body',
          closeButton: false,
        }
      );
    }
  };

  const onResetClick = async () => {
    try {
      await createMagnifeyeProductClearCustomValues();
      toast(
        {
          template: 'Default',
          message: resetProductsMessage,
        },
        {
          className: 'success-toast-body',
          closeButton: false,
        }
      );
    } catch (error) {
      console.error(error);
      toast(
        {
          template: 'Default',
          themeVariation: 'error-text',
          message: resetProductsFailedMessage,
        },
        {
          className: 'error-toast-body',
          closeButton: false,
        }
      );
    }
  };

  return (
    <div className={className}>
      <Card themeVariation="my-dashboard">
        <CardBody themeVariation="my-dashboard">
          <HeaderRowWithBackground background={assetService.getImage(headerBackground)}>
            <AlignedCol xs={24}>
              <img src={assetService.getImage(headerLogo)} />
            </AlignedCol>

            <AlignedCol xs={24} sm={22} smOffset={1} md={18} mdOffset={3} lg={14} lgOffset={5}>
              <StyledNote dangerouslySetInnerHTML={{ __html: headerNote }} />
            </AlignedCol>

            <AlignedCol xs={16} xsOffset={4} sm={10} smOffset={7} lg={6} lgOffset={9}>
              <StyledNames themeVariation="my-dashboard" fontSize={16} lineHeight={18}>
                {totalPatientsLabel}
              </StyledNames>

              {!isTargetAvailable && (
                <ProjectionValue color="#FFFFFF" textColor="#505d6f">
                  {getRoundedValue(totalPatients, 1)}
                </ProjectionValue>
              )}
              {isTargetAvailable && (
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Popover id="product-breakdown-overlay">
                      <PopoverContent color={isTargetArchived ? '#00AE44' : '#C73232'}>
                        {isTargetArchived
                          ? totalPatientsTooltipArchived
                          : totalPatientsTooltip.replace('{}', String(getRoundedValue(neededPatients, 1)))}
                      </PopoverContent>
                    </Popover>
                  }
                >
                  <ProjectionValue color={isTargetArchived ? '#00AE44' : '#C73232'}>{getRoundedValue(totalPatients, 1)}</ProjectionValue>
                </OverlayTrigger>
              )}

              <StyledNote dangerouslySetInnerHTML={{ __html: totalPatientsNote }} />
            </AlignedCol>
          </HeaderRowWithBackground>

          <ContentRow>
            <AlignedCol xs={24}>
              <StyledProductContainer>
                {productFamilyKeySet.map((familyKey) => (
                  <div key={familyKey} className="product">
                    <ProductFamilyComponent familyKey={familyKey} />

                    {customerProducts
                      .filter((product) => product.familyKey == familyKey)
                      .map((product) => (
                        <ProductComponent key={product.productTypeKey} product={product} />
                      ))}
                  </div>
                ))}
              </StyledProductContainer>
            </AlignedCol>
          </ContentRow>
        </CardBody>
      </Card>

      <Card themeVariation="my-dashboard">
        <CardBody themeVariation="my-dashboard">
          <KeystoneProducts />
        </CardBody>
      </Card>

      <ContentRow padding="40px 0 0">
        <AlignedCol xs={20} xsOffset={2} sm={10} smOffset={1} lg={6} lgOffset={6}>
          <Button themeVariation="my-dashboard" onClick={onSaveClick}>
            {calculateBtnLabel}
          </Button>
        </AlignedCol>

        <AlignedCol xs={24} smHidden={true} mdHidden={true} lgHidden={true}>
          &nbsp;
        </AlignedCol>

        <AlignedCol xs={20} xsOffset={2} sm={10} smOffset={2} lg={6} lgOffset={0}>
          <Button themeVariation="my-dashboard" onClick={onResetClick}>
            {resetBtnLabel}
          </Button>
        </AlignedCol>
      </ContentRow>
    </div>
  );
};

const mapContentToProps = (getContent) => ({
  title: getContent('title', { type: 'string', label: 'Title' }),
  infoIconText: getContent('infoIconText', { type: 'string', label: 'Info Icon Text' }),
});

const mapResourceToProps = (getResource) => ({
  resource: {
    headerBackground: getResource('alcon.dashboard.productBreakdown.headerBackground', '/backgrounds/water-innovations-background.jpg'),
    headerLogo: getResource('alcon.dashboard.productBreakdown.headerLogo', '/logos/water-innovations-logo.png'),
    headerNote: getResource(
      'alcon.dashboard.productBreakdown.headerNote',
      'This section allows you to see your Total Projected Patients in Alcon’s Dailies Total1, Precision1 and Total30 brand family of contact lenses (collectively, “WaterInnovations”) for the rest of the year based on your current quarter purchases. If you would like to customize these based on product specific sales, input your own numbers below and click <b>Calculate and Save</b>. All Alcon products are used to create the total.'
    ),
    totalPatientsLabel: getResource('alcon.dashboard.productBreakdown.totalPatientsLabel', 'Total Patients'),
    totalPatientsTooltip: getResource(
      'alcon.dashboard.productBreakdown.totalPatientsTooltip',
      'You will need {} more patients to meet your set goal above.'
    ),
    totalPatientsTooltipArchived: getResource(
      'alcon.dashboard.productBreakdown.totalPatientsTooltipArchived',
      'This projected value has met or exceeded your set goal above.'
    ),
    totalPatientsNote: getResource('alcon.dashboard.productBreakdown.totalPatientsNote', '(Projected Patients)'),
    calculateBtnLabel: getResource('alcon.dashboard.productBreakdown.calculateBtnLabel', 'CALCULATE AND SAVE'),
    resetBtnLabel: getResource('alcon.dashboard.productBreakdown.resetBtnLabel', 'RESET TO PROJECTED VALUES'),
    saveProductsMessage: getResource('alcon.dashboard.productBreakdown.saveProductsMessage', 'Your patients have successfully been saved'),
    saveProductsFailedMessage: getResource(
      'alcon.dashboard.productBreakdown.saveProductsFailedMessage',
      'There was an error completing your request. Please contact the portal support team for assistance.'
    ),
    resetProductsMessage: getResource(
      'alcon.dashboard.productBreakdown.resetProductsMessage',
      'Your patients have successfully been reset'
    ),
    resetProductsFailedMessage: getResource(
      'alcon.dashboard.productBreakdown.resetProductsFailedMessage',
      'There was an error completing your request. Please contact the portal support team for assistance.'
    ),
  },
});

const mapStateToProps = (state) => ({
  target: selectors.performanceAmountSelector(state, 'Target'),
  custom: selectors.performanceAmountSelector(state, 'Custom'),
  runRate: selectors.performanceAmountSelector(state, 'Run Rate'),
  customerProducts: selectors.customerProductsSelector.searchMany(
    state,
    (p: CustomerProduct) => p.id > 0 && !p.isKeystone && p.familyName != null && p.familyKey != null
  ),
  patientsUpdated: selectors.patientsSelector(state)?.patients,
  keystonePatientsUpdated: selectors.patientsSelector(state)?.keystonePatients,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ createMagnifeyeProductV2Save, createMagnifeyeProductClearCustomValues, updatePatients }, dispatch),
});

interface StateProps {
  target: Amount;
  custom: Amount;
  runRate: Amount;
  customerProducts: CustomerProduct[];
  patientsUpdated: Patient[];
  keystonePatientsUpdated: number;
}
interface DispatchProps {
  actions: {
    createMagnifeyeProductV2Save: Function;
    createMagnifeyeProductClearCustomValues: Function;
    updatePatients: Function;
  };
}
interface ContentProps {
  title: string;
  infoIconText: string;
}
interface ResourceProps {
  resource: {
    headerBackground: string;
    headerLogo: string;
    headerNote: string;
    totalPatientsLabel: string;
    totalPatientsTooltip: string;
    totalPatientsTooltipArchived: string;
    totalPatientsNote: string;
    calculateBtnLabel: string;
    resetBtnLabel: string;
    saveProductsMessage: string;
    saveProductsFailedMessage: string;
    resetProductsMessage: string;
    resetProductsFailedMessage: string;
  };
}
type Props = StateProps & DispatchProps & ContentProps & ResourceProps & React.HtmlHTMLAttributes<any>;

export default register('rsv8-alcon/ProductBreakdown')(
  withApiDataProvider(getMagnifeyeV2Summary, selectors.performanceSelector),
  connect<StateProps>(mapStateToProps, mapDispatchToProps),
  withContent(mapContentToProps),
  withResource(mapResourceToProps),
  withTheme()
)(ProductBreakdown);
