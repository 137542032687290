import { contentActions, socialActions } from '.';
import { ContentTypeModel, VersionInputModel } from 'xcel-api-generator/dist/cms';
import { destroy, destroyRequest } from 'xcel-redux-orm';
import { convertToPatchOperations } from 'xcel-util';
import { pluginUpdated } from '../../plugin/single/actions';
import { sortVersions } from '../../util';
import * as events from '../events';
import {
  contentCultureSelector,
  contentSelector,
  contentTypeSelector,
  contentVersionSelector,
  socialSelector
  } from '../selectors';
import cmsAdminApi from './cmsAdminApi';

const {
  getAdminContentversionsByContentCultureId,
  createAdminContentcultureByIdVersions,
  patchAdminContentversionsById,
  deleteAdminContentversionsById
} = cmsAdminApi;
const contentVersionActions = {
  get: (contentTypeId, contentCultureId) => async (dispatch, getState) => {
    const response = await dispatch(
      getAdminContentversionsByContentCultureId({
        contentCultureId,
        offset: 0,
        limit: 10,
        sort: 'id-'
      })
    );
    const state = getState();
    const activeVersion = contentVersionSelector.getActiveVersion(state, contentTypeId);

    let versions = contentVersionSelector.getVersionsByContentCultureId(state, contentCultureId);
    versions = !!versions && !Array.isArray(versions) ? [versions] : versions;

    if (versions && versions.length > 0) {
      if (
        !activeVersion ||
        (activeVersion && activeVersion.contentCulture && activeVersion.contentCulture.id !== contentCultureId)
      ) {
        const version = versions.sort(sortVersions)[0];
        dispatch(contentVersionActions.setActive(contentTypeId, version.id));
        dispatch(contentActions.setEdit(contentTypeId, version));
      }
    } else {
      await dispatch(
        contentVersionActions.create(contentTypeId, contentCultureId, {
          title: 'Enter Title',
          description: 'Enter Description',
          content: [],
          isPublished: false,
          unPublishedDateTimeUtc: null,
          publishedDateTimeUtc: null
        })
      );
    }
    return response;
  },
  updatePublishStatus: (contentTypeId, versionId, isPublished) => {
    return contentVersionActions.patch(contentTypeId, versionId, { isPublished });
  },
  unPublish: (contentTypeId, versionId) => (dispatch, getState) => {
    const state = getState();
    let versions = contentVersionSelector.getActiveVersions(state, contentTypeId);
    let version = undefined;
    for (let i = 0; i < versions.length; i++) {
      if (versions[i].isPublished !== true) {
        continue;
      } else {
        version = versions[i];
      }
    }
    dispatch(contentVersionActions.patch(contentTypeId, version.id, { isPublished: false }));
  },
  update: (contentTypeId = undefined) => (dispatch, getState) => {
    const state = getState();

    if (contentTypeId === undefined) {
      contentTypeId = contentTypeSelector.getActive(state);
    }
    const updated = contentSelector.getEdit(state, contentTypeId);

    if (contentTypeId === 'Activity') {
      const activityId = socialSelector.activityId(state);
      const version = socialSelector.currentVersion(state);

      if (version.isPublished) {
        dispatch(
          socialActions.createNewVersion(activityId, {
            isPublished: false,
            publishStatus: 'Draft',
            content: updated.content,
            title: version.title,
            description: version.description
          })
        );
      } else {
        // update the version component content before make the request
        dispatch(
          socialActions.editVersion(activityId, version.id, {
            content: updated.content,
            title: version.title,
            description: version.description
          })
        );
      }
    } else {
      let version = contentVersionSelector.getActiveVersion(state, contentTypeId) || ({} as any);
      version = Array.isArray(version) && version.length > 0 ? version[0] : version;

      if (
        version.description === updated.description &&
        version.title === updated.title &&
        JSON.stringify(version.content) === JSON.stringify(updated.content)
      ) {
        return;
      }

      if (version.publishStatus === 'Draft') {
        dispatch(
          contentVersionActions.patch(contentTypeId, version.id, {
            content: updated.content,
            title: updated.title,
            description: updated.description
          })
        );
      } else if (
        version.publishStatus === 'Published' ||
        (version.publishStatus === 'History' && contentTypeId === 'Resource')
      ) {
        const contentCulture = contentCultureSelector.getActive(state, contentTypeId);
        // we need to create a new version with this content as a draft
        dispatch(
          contentVersionActions.create(contentTypeId, contentCulture.id, {
            content: updated.content,
            title: updated.title,
            description: updated.description,
            isPublished: false,
            publishedDateTimeUtc: null,
            unPublishedDateTimeUtc: null
          })
        );
      }
    }
  },
  publish: (contentTypeId) => (dispatch, getState) => {
    const state = getState();
    const version = contentVersionSelector.getActiveVersion(state, contentTypeId);
    if (version.publishStatus === 'Draft') {
      return dispatch(contentVersionActions.patch(contentTypeId, version.id, { isPublished: true }));
    }
    const updated = contentSelector.getEdit(state, contentTypeId);
    const contentCulture = contentCultureSelector.getActive(state, contentTypeId);
    return dispatch(
      contentVersionActions.create(contentTypeId, contentCulture.id, {
        ...updated,
        isPublished: true,
        publishedDateTimeUtc: null,
        unPublishedDateTimeUtc: null
      })
    );
  },
  delete: (contentTypeId, contentVersionId) => async (dispatch, getState) => {
    const response = await dispatch(deleteAdminContentversionsById({ id: contentVersionId }));
    const versions = contentVersionSelector.getActiveVersions(getState(), contentTypeId);
    let index = versions.map((v) => v.id).indexOf(contentVersionId);
    if (index > 0) {
      index--;
    }
    const version = versions[index];
    dispatch(destroyRequest('getContentversionsByContentCultureId'));
    dispatch(destroy('versionDto', [contentVersionId]));
    dispatch(contentVersionActions.setActive(contentTypeId, version.id));
    return response;
  },
  patch: (contentTypeId, versionId: number, version: Partial<VersionInputModel>) => async (dispatch, getState) => {
    const contentCulture = contentCultureSelector.getActive(getState(), contentTypeId);
    const operations = convertToPatchOperations(version);
    const response = await dispatch(patchAdminContentversionsById({ id: versionId, body: operations }));
    setTimeout(() => {
      dispatch(destroyRequest('getContentversionsByContentCultureId'));
      dispatch(contentVersionActions.get(contentTypeId, contentCulture.id));
    }, 200);

    return response;
  },
  create: (contentTypeId, contentCultureId, version: VersionInputModel) => async (dispatch) => {
    const response = await dispatch(createAdminContentcultureByIdVersions({ id: contentCultureId, body: version }));
    dispatch(destroyRequest('getContentversionsByContentCultureId'));
    dispatch(contentVersionActions.get(contentTypeId, contentCultureId));
    dispatch(contentVersionActions.setActive(contentTypeId, response.data.id));
    return response;
  },
  setActive: (contentTypeId, versionId) => (dispatch, getState) => {
    const state = getState();
    dispatch(contentActions.setActiveProperty(contentTypeId, 'versionId', versionId));
    const version = contentVersionSelector.selectOne(state, versionId);
    dispatch(contentActions.setEdit(contentTypeId, version));
    // TODO: this doesn't belong here...i need to integrate with standalone control,
    // possibly by passing my own setActiveVersion which would allow me to add this
    dispatch(
      pluginUpdated(contentTypeId, contentSelector.getEditContentProperties(state, contentTypeId), 'setActiveVersion')
    );
  },
  setProperty: (data) => ({
    type: events.CONTENT_EDIT_PROPERTY_SET,
    payload: { data }
  })
};
export { VersionInputModel, ContentTypeModel };
export default contentVersionActions;
