import * as React from 'react';
import { connect } from 'react-redux';
import { Field } from 'rsv8-forms';
import countrySelector from '../../redux/selectors/country';
export interface CountryDropdownProps {
  countrySet: any;
  list: any;
  label?: string;
  props?: any;
  component: any;
  [index: string]: any;
  countryId: any;
}
export interface Country {
  id: number;
  name: string;
  description: string;
  language: string;
  isDefault: boolean;
}

const CountryDropdown: React.SFC<CountryDropdownProps> = ({ list, ...rest }) => {
  let options = { label: 'Select Country', value: '' };
  let countriesMap =
    list &&
    list.map((country, index) => {
      return { label: country && country.name, value: country && country.countryCode };
    });
  countriesMap.unshift(options);
  return (
    <React.Fragment>
      <Field name="CountryDropdown" component="bs-select" {...rest} options={countriesMap} />
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({
  list: countrySelector.selectMany(state, null)
});

export default connect(mapStateToProps)(CountryDropdown) as any;
