import * as React from 'react';
import { register, withContent } from 'xcel-react-core';
import SalesActivityDashboard from './SalesActivityDashboard';

type QueryString = {
  [index: string]: any;
};

interface ContextProps {
  qs: QueryString;
  setQueryString: (qs: QueryString) => void;
}

export const SalesActivityContext = React.createContext({
  qs: {},
  setQueryString: (qs) => {
    return;
  }
} as ContextProps);

export const SalesActivityProvider = ({ children }) => {
  const data: ContextProps = {
    qs: {},
    setQueryString: (qs) => {
      setContextData({
        ...contextData,
        qs
      });
    }
  };

  const [contextData, setContextData] = React.useState<ContextProps>(data);

  return <SalesActivityContext.Provider value={contextData}>{children}</SalesActivityContext.Provider>;
};

type Props = Pick<ContextProps, 'qs'>;

const SalesActivity: React.FunctionComponent<Props> = (props) => (
  <SalesActivityProvider>
    <SalesActivityDashboard qs={props.qs} />
  </SalesActivityProvider>
);

export default register('rsv8-sales-entry/SalesActivityTable')(withContent())(SalesActivity);
