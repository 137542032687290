import { reduxObserver } from 'xcel-observer';
import { createReduxModule, ReduxModule } from 'xcel-redux-module';
import { reducers } from './app/redux/';

const adminAssetModule: ReduxModule = createReduxModule({
  name: 'rsv8-assets-admin',
  reducers
});

reduxObserver.addModule(adminAssetModule);
export default adminAssetModule;
