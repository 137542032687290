import * as events from './events';
// export const create = (type, { title, message, duration }) => (
export const create = (type, { title, message, duration }) => {
  return {
    type: events.CREATE_ALERT,
    payload: { type, title, message, duration }
  };
};

export const remove = (id) => ({ type: events.REMOVE_ALERT, payload: id });
