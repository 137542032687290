import * as React from 'react';
import {
  Alert,
  Col,
  Grid,
  Row
  } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PageRenderer } from 'rsv8-cms';
import { toast } from 'rsv8-components';
import { Context, withWizard } from 'rsv8-wizard';
import styled from 'styled-components';
import { register, withResource } from 'xcel-react-core';
import NominationCollapse from '../../components/NominationCollapse/index';
import { ErrorFormContainer } from '../../components/SearchBar/SearchBar';
import { nominationActions } from '../../redux/actions/getApplication';
import { teamNominationActions } from '../../redux/actions/index';
import { teamNominationSelectors } from '../../redux/selectors/index';
import { Resources } from '../../types';
import ProductSelection from './ProductSelection';

const {
  selectProduct,
  getProductDetails,
  getProducts,
  selectProductOptionPointValue,
  selectProductOptionId
} = teamNominationActions;

const ColFlexCentered = styled(Row)`
  display: block;
  width: 100%;
  text-align: center;
` as any;

const ProductWrapper = styled.div`
  padding-top: 50px;
` as any;

interface InternalProps {
  products: Array<Resources.Product>;
  wizard: Context;
  currentProduct: string;
  errorText: any;
  buttonTitle: string;
  product: any;
  award: {
    incentiveType: string;
  };
  selectedOptionId: any;
  actions: {
    selectProduct: (productId: string | number, confirmText: string) => void;
    getProductDetails: (productId: string, nominee: number) => void;
    selectProductOptionId: (optionId: string) => void;
    selectProductValue: (optionPointValue: number) => void;
    getNominationApplication: any;
    setClearNominationData: (value: boolean) => void;
  };
  productFromDraft: boolean;
  clearNominationData: string;
}

interface ProductPageState {
  hasError: boolean;
}

class ProductPage extends React.Component<InternalProps, ProductPageState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidMount() {
    const { award, wizard, actions, clearNominationData } = this.props;
    if (award.incentiveType !== 'Product') {
      this.skipWizardStep();
    }

    if (wizard) {
      wizard.registerIncrement(this.checkValid);
    }

    actions.setClearNominationData(clearNominationData === 'true');
  }

  skipWizardStep = () => {
    if (this.props.wizard) {
      this.props.wizard.removeWizardStep();
      this.props.wizard.increment();
    }
  };

  checkValid = () => {
    const { product, selectedOptionId, productFromDraft } = this.props;
    if (!product) {
      this.setState({ hasError: true });
      return Promise.reject('Please select a product!');
    }
    if (product && (productFromDraft ? false : product.options && product.options.length > 0 && !selectedOptionId)) {
      toast({
        template: 'ContentToast',
        contentTemplate: (
          <PageRenderer
            path={'toast/nomination/individual/optionError'}
            onNotFound={() => <PageRenderer path={`toast/nomination/individual/error`} cultureId="0" />}
          />
        )
      });
      return Promise.reject('Please select an product!');
    }
    this.setState({ hasError: false });
    return Promise.resolve();
  };

  render() {
    return (
      <Grid>
        <Row>
          <Col lgOffset={2} mdOffset={2} lg={20} md={20} xs={24}>
            <ColFlexCentered xs={24}>
              <PageRenderer path={`partial/nomination/team/recognition/header/awardSelectionWizard`} />
            </ColFlexCentered>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            {this.state.hasError && !this.props.currentProduct ? (
              <ErrorFormContainer>
                <Alert bsStyle="danger">{this.props.errorText}</Alert>
              </ErrorFormContainer>
            ) : null}
            <ProductWrapper>
              <ProductSelection products={this.props.products} />
              <NominationCollapse title={this.props.buttonTitle}>
                <PageRenderer path={`partial/nomination/team/productApprovalDetails`} />
              </NominationCollapse>
            </ProductWrapper>
          </Col>
        </Row>
      </Grid>
    );
  }
}

const mapState = (state) => {
  const products = teamNominationSelectors.getProducts(state);
  const award = teamNominationSelectors.getCurrentAward(state);

  let currentProductOptionId = teamNominationSelectors.getCurrentOptionId(state);
  let currentProductId = teamNominationSelectors.getCurrentProductId(state);
  let product = teamNominationSelectors.getCurrentProduct(state);
  let productFromDraft = null;
  if (!currentProductId) {
    const info = teamNominationSelectors.getProductInfoFromDraft(state);
    if (info && info.product) {
      productFromDraft = info.product;
      product = productFromDraft;
      currentProductId = info.productId;
      currentProductOptionId = info.optionId;
    }
  }

  return {
    selectedOptionId: currentProductOptionId,
    selectedProduct: currentProductId,
    product: product,
    productFromDraft: !!productFromDraft,
    products: !Array.isArray(products) ? [products] : products,
    award: Array.isArray(award) ? award[0] : award
  };
};

const mapDispatch = (dispatch) => ({
  actions: bindActionCreators(
    {
      selectProduct,
      getProductDetails,
      getProducts,
      selectProductOptionPointValue,
      selectProductOptionId,
      getNominationApplication: nominationActions.getApplication,
      setClearNominationData: teamNominationActions.setClearNominationData
    },
    dispatch
  )
});

const mapResource = (getResource) => ({
  errorText: getResource('teamNomination.selectProductError', 'Please Select an Product'),
  buttonTitle: getResource('nominationProcess.productDetailsButtonTitle', 'Product Approval Details'),
  clearNominationData: getResource('nominationProcess.clearNominationData', 'false')
});

const compose = register('rsv8-nomination/TeamSelectProduct');

export default compose(
  connect(mapState, mapDispatch),
  withResource(mapResource),
  withWizard()
)(ProductPage) as React.ComponentClass<{}>;
