import * as React from 'react';
import { connect } from 'react-redux';
import { GridColumn, HeaderTwo, Link } from 'rsv8-components';
import {
  register,
  withContent,
  withResource,
  withTheme
  } from 'xcel-react-core';
import metricApiActions from '../../redux/actions/metricApiActions';
import * as selectors from '../../redux/selectors/metricSelectors';
import GoalsModal from './GoalsModal';
import { CompletedContainer, CompletedNumber } from './styles';
import { numberFormat } from 'xcel-util';
import {
  MyGoalsContainer,
  NewIntro,
  ResultsContainer,
  StyledText,
  StyledTextIntro
  } from './styles/index';

export interface GoalsProps {
  goals: selectors.CalculatorMetric[];
  total: selectors.CalculatorResult;
  timePeriod: selectors.TimePeriod[];
  dispatch: any;
  programKey: string;
  calculatorKey: string;
  existingIntro: string;
  projectedResult: string;
  currency: string;
  newIntro: string;
  newIntroTagline: string;
  editYourGoals: string;
  setYourGoals: string;
  className: string;
}

export interface GoalsState {
  showGoalsModal: boolean;
}

class Goals extends React.Component<GoalsProps, GoalsState> {
  constructor(props: any) {
    super(props);

    this.state = {
      showGoalsModal: false
    };
  }

  canRender = () => {
    return this.props.programKey && this.props.calculatorKey;
  };
  componentDidMount() {
    if (this.canRender()) {
      this.getTotalGoals();
    }
  }

  getTotalGoals = () => {
    const { dispatch, programKey, calculatorKey } = this.props;
    dispatch(metricApiActions.getCalculatorGoalsByCalculatorKey({ programKey, calculatorKey }));
  };

  showGoalsModal = () => {
    this.setState({ showGoalsModal: true });
  };

  hideGoalsModal = () => {
    const { dispatch, programKey, calculatorKey } = this.props;
    this.setState({ showGoalsModal: !this.state.showGoalsModal });
    dispatch(metricApiActions.createCalculatorGoalsCalculateByCalculatorKey({ programKey, calculatorKey, body: [] }));
    this.getTotalGoals();
  };

  getContent = () => {
    const { goals, total, timePeriod } = this.props;
    const fetchNames = timePeriod.map((time) => time.name);
    const firstMonth = fetchNames[0];
    const lastMonth = fetchNames[fetchNames.length - 1];

    if (total && total.value !== '0') {
      return (
        <React.Fragment>
          <StyledText>{this.props.existingIntro}</StyledText>
          <HeaderTwo className="goal-header">{`${firstMonth} - ${lastMonth}`}</HeaderTwo>
          {goals
            .sort((a, b) => (a.sortOrder < b.sortOrder ? -1 : 1))
            .map((item, i) => (
              <CompletedContainer key={item.key} fluid="true">
                <GridColumn xs={20} sm={20} md={20} lg={20}>
                  <StyledText>{item.label}</StyledText>
                </GridColumn>
                <CompletedNumber xs={4} sm={4} md={4} lg={4}>
                  <StyledText className="goal-value">{item.goalValue}</StyledText>
                </CompletedNumber>
              </CompletedContainer>
            ))}
          <ResultsContainer>
            <StyledText>{this.props.projectedResult}</StyledText>
            <HeaderTwo className="goal-result">
              {numberFormat(total.value)} {this.props.currency}
            </HeaderTwo>
          </ResultsContainer>
        </React.Fragment>
      );
    } else {
      return (
        <NewIntro>
          <HeaderTwo className="newIntro">{this.props.newIntro}</HeaderTwo>
          <StyledTextIntro>{this.props.newIntroTagline}</StyledTextIntro>
        </NewIntro>
      );
    }
  };

  render() {
    const { total, className } = this.props;
    if (!this.canRender()) {
      return <span>must specify program key and calculator key to render</span>;
    }
    return (
      <MyGoalsContainer className={className} largePadding={total && total.value === '0'}>
        {this.getContent()}

        <Link themeVariation="secondary-button-full-width" onClick={this.showGoalsModal}>
          {total && total.value !== '0' ? (
            <span>{this.props.editYourGoals}</span>
          ) : (
            <span>{this.props.setYourGoals}</span>
          )}{' '}
        </Link>
        <GoalsModal
          show={this.state.showGoalsModal}
          onHide={this.hideGoalsModal}
          programKey={this.props.programKey}
          calculatorKey={this.props.calculatorKey}
        />
      </MyGoalsContainer>
    );
  }
}

const mapResourceToProps = (getResource) => ({
  existingIntro: getResource('myGoals.existingIntro', 'My program goal'),
  projectedResult: getResource('myGoals.projectedResult', 'Projected Result'),
  currency: getResource('myGoals.currency', 'pts.'),
  newIntro: getResource('myGoals.newIntro', 'Set Your Program Goal'),
  newIntroTagline: getResource('myGoals.newIntroTagline', 'Set your goal and see what you can achieve.'),
  editYourGoals: getResource('myGoals.editYourGoal', 'EDIT YOUR GOAL'),
  setYourGoals: getResource('myGoals.setYourGoal', 'SET YOUR GOAL')
});

const mapContentToProps = (getContent) => {
  return {
    programKey: getContent('programKey', { type: 'none', label: 'ProgramKey' }),
    calculatorKey: getContent('calculatorKey', { type: 'none', label: 'CalculatorKey' })
  };
};

const mapStateToProps = (state) => ({
  goals: selectors.calculatorMetric.searchMany(state, (goal) => goal.timePeriodKey === 'TOTAL'),
  total: selectors.calculatorResult.selectOne(state, 'TOTAL_TOTAL'),
  timePeriod: selectors.timePeriod.selectMany(state, null)
});

export default register('rsv8-metrics/Goals')(
  connect(mapStateToProps),
  withTheme(),
  withContent(mapContentToProps),
  withResource(mapResourceToProps)
)(Goals);
