import * as moment from 'moment';
import * as React from 'react';
import { compose, withPropsOnChange } from 'recompose';
import { Link, Text } from 'rsv8-components';
import styled from 'styled-components';
import { DivProps } from 'xcel-react-core';
import { NominationActivity, Resources } from '../../../types/index';
import PointCost from '../../PointCost/index';
import { TextRow } from '../../TextRow';
import {
  CriteriaAndDetails,
  InfoArrayBlock,
  NomineePanel,
  withModalResources,
  WithModalResourcesProps
  } from '../util/index';
import {
  Body,
  Certificate,
  NomineeContainer,
  Status,
  StatusContainer,
  StyledCollapse
  } from './styles';

export interface NominatorBodyProps extends DivProps {
  nomination: Resources.NominatorDetail;
  nominationType: NominationActivity.ActivityTables;
  customFields: Record<string, string>;
}
const PointSummary = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  > div {
    flex-basis: 50%;
  }
`;
const PointSummaryBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const NominatorBody: React.SFC<NominatorBodyProps & WithModalResourcesProps> = ({
  nomination,
  headers,
  labels,
  customFields,
  ...rest
}) => (
  <Body {...rest}>
    <NomineeContainer>
      <NomineePanel nominee={nomination.nomineeUser}>
        <InfoArrayBlock content={nomination.nomineeInfo} />
      </NomineePanel>
    </NomineeContainer>
    <StyledCollapse title={headers.details}>
      <TextRow head={labels.createDate}>{moment(nomination.nomination.createDate).format('MM/DD/YYYY')}</TextRow>
      <TextRow head={labels.award}>{nomination.awardName}</TextRow>
      <PointSummary>
        <PointSummaryBlock>
          <Text variationName="field-label">{labels.pointValue}</Text>
          {nomination.awardValue}
        </PointSummaryBlock>
        <PointCost
          flexDirection={'column'}
          padding={'0px'}
          showCost={false}
          id={nomination.applicationId}
          pointValue={nomination.awardValue}
          nomineeId={nomination.nomination.nomineeId}
        />
      </PointSummary>
      <TextRow head={labels.type}>{nomination.awardType}</TextRow>
      <TextRow head={labels.status}>
        <StatusContainer>
          <Status status={nomination.statusId} />
        </StatusContainer>
      </TextRow>
      <CriteriaAndDetails formType="NominationForm" appKey={nomination.applicationId} customFields={customFields} />
    </StyledCollapse>
    {nomination &&
      nomination.nominationCertificate &&
      nomination.nominationCertificate.length > 0 && (
        <Certificate>
          <Link href={nomination.nominationCertificate[0]} themeVariation="primary-button" target="_blank">
            {labels.downloadCertificate}
          </Link>
        </Certificate>
      )}
  </Body>
);

const mapProps = (props: NominatorBodyProps): Partial<NominatorBodyProps> => ({
  customFields:
    props.nomination &&
    props.nomination.customFields &&
    props.nomination.customFields.reduce((acc, field) => ({ ...acc, [field.customFieldId]: field.value }), {})
});

export default compose<NominatorBodyProps, Pick<NominatorBodyProps, 'nomination' | 'nominationType'> & DivProps>(
  withPropsOnChange(['nomination'], mapProps),
  withModalResources
)(NominatorBody);
