import * as React from 'react';
import { connect } from 'react-redux';
import { compose, withPropsOnChange } from 'recompose';
import { bindActionCreators } from 'redux';
import { PageRenderer } from 'rsv8-cms';
import { Link, Text } from 'rsv8-components';
import { actions, getFieldsByGroup } from 'rsv8-customfields';
import styled from 'styled-components';
import { DivProps, withResource } from 'xcel-react-core';
import { selectOne } from 'xcel-redux-orm';
import { nominationActions, nominationActivitySelectors, teamMemberActions } from '../../../redux';
import { nominationDetailSelectors, pendingApprovalSelectors } from '../../../redux/selectors/nomination';
import { NominationActivity, Resources } from '../../../types/index';
import { FormDisplay } from '../../ApproveDenyForms';
import PointCost from '../../PointCost/index';
import { TextRow } from '../../TextRow';
import {
  CriteriaAndDetails,
  InfoArrayBlock,
  isPendingApproval,
  NomineePanel,
  withModalResources,
  WithModalResourcesProps
  } from '../util/index';
import {
  Body,
  Certificate,
  NomineeContainer,
  Status,
  StatusContainer,
  StyledCollapse
  } from './styles';

export interface ApproverBodyProps extends DivProps {
  nomination: Resources.ApproverDetail;
  nominationType: NominationActivity.ActivityTables;
  customFields: Record<string, string>;
  helpfulHints: string;
  loadApproval: typeof nominationActions.getProcessById;
  approveNomination: typeof nominationActions.createProcess;
  approval: Resources.ProcessForm;
  nominations?: Resources.PendingApprovalAttributes;
  teamMembers: any;
  getTeamMembers: typeof teamMemberActions.getTeamMembers;
  getCustomFields: any;
  dispatch?: any;
  showApprovalFields: boolean;
}
const PointSummary = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  > div {
    flex-basis: 33%;
  }
`;
const PointSummaryBlock = styled.div`
  display: flex;
  flex-direction: column;
`;
const GROUP_KEY = ['approve', 'deny'];
class ApproverBody extends React.Component<ApproverBodyProps & WithModalResourcesProps, {}> {
  componentWillMount() {
    this.props.getCustomFields({
      formType: 'NominationProcess',
      groupKey: GROUP_KEY,
      appKey: this.props.nomination.applicationId
    } as any);
  }
  componentDidMount() {
    this.handleLoad();
  }

  componentDidUpdate(prevProps: ApproverBodyProps, prevState: {}) {
    if (prevProps.nomination.id !== this.props.nomination.id) {
      this.handleLoad();
    }
  }

  handleLoad = () => {
    const { nominationType, loadApproval, nominations, nomination, getTeamMembers } = this.props;

    if (isPendingApproval({} as any, nominationType)) {
      loadApproval({ id: Number(nominations ? nominations.id : nomination.id) });
    }
    if (nomination.teamId) {
      getTeamMembers(nomination.teamId);
    }
  };

  contentIsPresent = (content) => content && Array.isArray(content) && content.length > 0;

  render() {
    const {
      approval,
      helpfulHints,
      nomination,
      nominations,
      headers,
      labels,
      customFields,
      teamMembers,
      ...rest
    } = this.props;
    return (
      <Body {...rest}>
        <NomineeContainer>
          <NomineePanel nominee={nomination.nomineeUser} teamMembers={teamMembers}>
            <InfoArrayBlock content={nomination.nomineeInfo} />
          </NomineePanel>
        </NomineeContainer>
        <StyledCollapse themeVariation="secondary" title={helpfulHints} disableAutoScroll={true}>
          <PageRenderer path={`/partial/nomination/approval-modal-helpful-hints`} />
        </StyledCollapse>
        {this.contentIsPresent(nomination.nominatorInfo) && (
          <StyledCollapse themeVariation="secondary" title={headers.nominator} disableAutoScroll={true}>
            <InfoArrayBlock content={nomination.nominatorInfo} />
          </StyledCollapse>
        )}

        <StyledCollapse themeVariation="secondary" title={headers.details} disableAutoScroll={true}>
          <TextRow head={<strong>{labels.createDate}</strong>}>{nomination.createDate}</TextRow>
          <TextRow head={<strong>{labels.award}</strong>}>{nomination.awardName}</TextRow>
          {/* <TextRow head={<strong>{labels.pointValue}</strong>}>{nomination.awardValue}</TextRow> */}
          <PointSummary>
            <PointSummaryBlock>
              <Text variationName="field-label">{labels.pointValue}</Text>
              {nomination.awardValue}
            </PointSummaryBlock>
            <PointCost
              flexDirection={'column'}
              padding={'0px'}
              id={nomination.applicationId}
              pointValue={nomination.awardValue}
              nomineeId={nomination.nomineeUser.id}
            />
          </PointSummary>
          <TextRow head={<strong>{labels.type}</strong>}>{nomination.awardType}</TextRow>
          <TextRow head={<strong>{labels.status}</strong>}>
            <StatusContainer>
              <Status status={nomination.statusId}>{nomination.status}</Status>
            </StatusContainer>
          </TextRow>
          <CriteriaAndDetails formType="NominationForm" appKey={nomination.applicationId} customFields={customFields} />
        </StyledCollapse>
        {approval && isPendingApproval({} as any, this.props.nominationType) ? (
          <StyledCollapse themeVariation="secondary" title={headers.approval} disableAutoScroll={true}>
            <FormDisplay
              actions={approval.actions}
              applicationId={nomination.applicationId}
              processId={approval.actions.processId}
              approveNomination={this.props.approveNomination}
              showApprovalFields={this.props.showApprovalFields}
            />
          </StyledCollapse>
        ) : null}
        {approval && approval.nextApprover && (
          <StyledCollapse themeVariation="secondary" title={headers.finalApproverDecision} disableAutoScroll={true}>
            <TextRow head={labels.finalApprover}>
              {`${approval.nextApprover.items[0].value} ${approval.nextApprover.items[1].value}`}
            </TextRow>
          </StyledCollapse>
        )}
        {nomination &&
          nomination.nominationCertificate &&
          nomination.nominationCertificate.length > 0 &&
          Object.keys(nomination.nominationCertificate[0]).length > 0 && (
            <Certificate>
              <Link href={nomination.nominationCertificate[0]} themeVariation="primary-button" target="_blank">
                {labels.downloadCertificate}
              </Link>
            </Certificate>
          )}
      </Body>
    );
  }
}

const mapProps = (props: ApproverBodyProps) => ({
  customFields:
    props.nomination &&
    props.nomination.customFields &&
    props.nomination.customFields.reduce((acc, field) => ({ ...acc, [field.customFieldId]: field.value }), {})
});

const mapState = (state, ownProps: ApproverBodyProps) => {
  const pendingApproval = pendingApprovalSelectors.searchOne(
    state,
    (approvals) => approvals.nominationDetailId === ownProps.nomination.id
  );

  const showApprovalFields =
    getFieldsByGroup(state, GROUP_KEY, 'NominationProcess', ownProps.nomination.applicationId).length > 0
      ? true
      : false;

  if (ownProps.nomination.teamId) {
    const teamMemberIds = nominationActivitySelectors
      .getTeamMemberIds(state)
      .filter((id) => id !== ownProps.nomination.id);
    return {
      nominations: pendingApproval,
      approval: selectOne(
        state,
        'processForm',
        pendingApproval ? pendingApproval.id.toString() : ownProps.nomination.id.toString()
      ),
      teamMembers: nominationDetailSelectors.selectMany(state, teamMemberIds),
      showApprovalFields
    };
  }

  return {
    nominations: pendingApproval,
    approval: selectOne(
      state,
      'processForm',
      pendingApproval ? pendingApproval.id.toString() : ownProps.nomination.id.toString()
    ),
    showApprovalFields
  };
};

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      loadApproval: nominationActions.getProcessById,
      approveNomination: nominationActions.createProcess,
      getTeamMembers: teamMemberActions.getTeamMembers,
      getCustomFields: actions.getCustomFields
    },
    dispatch
  );
const mapResource = (getResource) => ({
  helpfulHints: getResource('nominationActivity.helpfulHints', 'Helpful Hints')
});

export default compose<ApproverBodyProps, Pick<ApproverBodyProps, 'nomination' | 'nominationType'> & DivProps>(
  withModalResources,
  withPropsOnChange(['nomination'], mapProps),
  connect(
    mapState,
    mapDispatch
  ),
  withResource(mapResource)
)(ApproverBody);
