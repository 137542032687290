import { resourceSelectors } from 'rsv8-resource';
import { themeSelectors } from 'rsv8-theme';
import { withGuard } from 'xcel-react-core';
const guardAction = () => Promise.resolve();

const guardCheck = (props) => {
  const { theme, resource } = props;
  if (theme && resource && (Object.keys(theme).length > 0 && Object.keys(resource).length > 0)) {
    return undefined;
  }
  return null;
};
// using these directly is typically bad, however it would require a refactor of code to pull these values
const mapStateToProps = (state) => ({
  theme: themeSelectors.getProcessed(state),
  resource: resourceSelectors.getResources(state)
});
export const ApplicationGuard = withGuard(null)(guardCheck, guardAction, mapStateToProps);
