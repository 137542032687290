import { getAwardDetails, getAwards } from '../getAwards/index';
import { getProductDetails, getProducts } from '../getProducts/index';
import { clearIndividualNomination, setIndividualNomination } from './clearIndividualNomination';
import { clearCurrentNomination, saveNomination, submitNomination } from './nomineeSave/index';
import {
  selectAward,
  selectCustomFields,
  selectNominee,
  selectPointValue,
  selectProductOptionPointValue,
  selectProductOptionId,
  selectProduct
  } from './nomineeSelect/index';

const individualNominationActions = {
  getAwards: getAwards,
  getProducts: getProducts,
  clearState: clearIndividualNomination,
  deleteNomination: clearCurrentNomination,
  save: saveNomination,
  submit: submitNomination,
  setNomination: setIndividualNomination,
  selectAward,
  selectProduct,
  selectProductOptionPointValue,
  selectCustomFields,
  selectNominee,
  selectProductOptionId,
  selectPoints: selectPointValue,
  getAwardDetails: (award: string, nominee: string) => getAwardDetails(award, nominee, { team: false }),
  getProductDetails: (product: string, nominee: string) => getProductDetails(product, nominee, { team: false })
};

export default individualNominationActions;