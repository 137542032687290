import * as React from 'react';
import styled from 'styled-components';
import { allowDollarChars } from '../../../utils';
import { CenteredCell, InputFieldContainer, ProductAttrHeaderText, ProductInputField, ProductTableCellWithInput } from './Products/styles';

const ShareGoalContainer = styled.div`
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0px;
  max-width: 140px;
  margin-left: 40px;
`;

const FieldContainer = styled(InputFieldContainer)`
  input {
    width: 80%;
  }
`;

const ShareGoalInput: React.FC<Props> = ({
  marketShare,
  setMarketShare
}) => (
  <ShareGoalContainer>
    <CenteredCell>
      <ProductAttrHeaderText>
        YTD Share Goal
      </ProductAttrHeaderText>
    </CenteredCell>

    <ProductTableCellWithInput>
      <FieldContainer>
        <ProductInputField
          component="bs-input"
          type="input"
          className="product-input-field"
          key='product-ytd-share-goal'
          name='product-ytd-share-goal'
          value={marketShare}
          onChange={(e) => { setMarketShare(allowDollarChars(e.target.value)); }}
        />
        %
      </FieldContainer>
    </ProductTableCellWithInput>
  </ShareGoalContainer>
)

type Props = {
  marketShare: string;
  setMarketShare: Function;
};

export default ShareGoalInput;
