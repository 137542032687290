import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { HeaderTwo, Link, Loader } from 'rsv8-components';
import {
  register,
  withContent,
  withResource,
  withTheme
  } from 'xcel-react-core';
import { getWinners } from '../../redux/actions/index';
import { winnersSelectors } from '../../redux/selectors/index';
import {
  AsideButtons,
  Background,
  Button,
  StarAwardHeader,
  StarAwardHeaderImg,
  WinnerCaption,
  WinnerName,
  WinnersContainer,
  WinnersSection,
  WinnerWrapper
  } from './styles';

const HEADER_CLASSNAME = 'header-title';
const HEADER_IMAGE_CLASSNAME = 'header-image';
const WINNERS_CLASSNAME = 'winners-section';

export interface Props {
  title: string;
  submitButtonLabel: string;
  submitLink: string;
  viewAllLabel: string;
  winnersLink: string;
  getWinners: Function;
  headerImage?: string;
  bgImage?: string;
  bgImageMobile?: string;
  winners?: any[];
  speed?: any;
}

class StarWinners extends React.Component<Props> {
  winnersContainer: any;
  winnersRender() {
    const { winners = [] } = this.props;

    return winners.map(({ firstName, lastName, city, teamName, id }) => (
      <WinnerWrapper key={id} xs={12} md={8}>
        <div>
          <WinnerName themeVariation="primary-link">
            {firstName} {lastName}
          </WinnerName>
        </div>
        <div>
          <WinnerCaption themeVariation="primary-link">{city}</WinnerCaption>
          <WinnerCaption themeVariation="primary-link">{teamName}</WinnerCaption>
        </div>
      </WinnerWrapper>
    ));
  }

  componentDidMount() {
    this.props.getWinners();
  }

  render() {
    const {
      bgImage,
      bgImageMobile,
      title,
      submitButtonLabel,
      submitLink,
      viewAllLabel,
      headerImage,
      winners,
      winnersLink,
      speed
    } = this.props;
    return (
      <Background bgImage={bgImage} bgMobileImage={bgImageMobile}>
        <Row>
          {headerImage ? <StarAwardHeaderImg className={HEADER_IMAGE_CLASSNAME} src={headerImage} /> : null}
          <Col xs={24}>
            <StarAwardHeader className={HEADER_CLASSNAME}>
              <HeaderTwo themeVariation="dark-background">{title}</HeaderTwo>
            </StarAwardHeader>
          </Col>
        </Row>
        <Row>
          <WinnersSection xs={24} md={16} className={WINNERS_CLASSNAME}>
            {!winners.length ? <Loader /> : null}
            <WinnersContainer time={speed ? speed : undefined}>{this.winnersRender()}</WinnersContainer>
          </WinnersSection>
          <AsideButtons xs={24} md={8}>
            <Link href={submitLink} themeVariation="content-block">
              <Button>{submitButtonLabel}</Button>
            </Link>
            <Link href={winnersLink} themeVariation="content-block">
              <Button themeVariation="secondary">{viewAllLabel}</Button>
            </Link>
          </AsideButtons>
        </Row>
      </Background>
    );
  }
}

const mapStateToProps = (state) => ({
  winners: winnersSelectors.selectMany(state) || []
});

const mapDispatchToProps = (dispatch) => ({
  getWinners: bindActionCreators(getWinners, dispatch)
});

const mapResourceToProps = (getResource) => ({
  submitButtonLabel: getResource('nomineeStarWinners.submitButtonLabel', 'Submit Nomination'),
  viewAllLabel: getResource('nomineeStarWinners.viewAllLabel', 'View All Winners')
});

const mapContentToProps = (getContent) => ({
  bgImage: getContent('bgImage', { type: 'none', label: 'Background Image' }),
  bgImageMobile: getContent('bgImageMobile', {
    type: 'none',
    label: 'Background Mobile Image'
  }),
  headerImage: getContent('headerImage', { type: 'none', label: 'Header Image' }),
  title: getContent('title', { type: 'html', label: 'Header Title' }),
  submitLink: getContent('submitLink', { type: 'none', label: 'Submit Link' }),
  winnersLink: getContent('winnersLink', { type: 'none', label: 'winnersLink' }),
  speed: getContent('speed', { type: 'none', label: 'Scroll Speed' })
});

export default register('rsv8-nomination/StarWinners')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withContent(mapContentToProps),
  withResource(mapResourceToProps),
  withTheme([`.${HEADER_CLASSNAME}`, `.${HEADER_IMAGE_CLASSNAME}`, `.${WINNERS_CLASSNAME}`])
)(StarWinners);
