import * as React from 'react';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Context } from 'rsv8-wizard';
import { register, withResource } from 'xcel-react-core';
import { NarrowGrid, TeamDetailsNomineeInstructions } from '../../components/index';
import { ErrorFormContainer } from '../../components/SearchBar/SearchBar';
import { teamMemberActions, teamNominationActions, teamNominationSelectors } from '../../redux/index';
import TeamNominationContext from '../TeamNominationContext';
import NomineeDetailForm from './NomineeDetailForm';
interface Props {
  wizard?: Context;
  saveNomination: () => void;
  saveTeamMembers: () => void;
  errorTextNominees: string;
  errorTextCustomFields: string;
  errorTextAwardValues: string;
  memberCount: number;
  errorTextRoles: string;
  roleIds: any;
  // awardValues: any;
  // hasAwardOptions: boolean;
  hasRoleOptions: boolean;
  teamMembers: any;
  currentNomination: any;
}

class TeamNomineeDetailPage extends React.Component<Props, any> {
  forms = {};
  constructor(props: any) {
    super(props);
    this.state = { errors: [] };
  }

  componentDidMount() {
    if (this.props.wizard) {
      this.props.wizard.registerIncrement(this.checkValid);
    }
  }
  addForm = (form): any => {
    this.forms = {
      ...this.forms,
      [form.form]: form
    };
    return this.forms;
  };

  checkValid = () => {
    this.setState({ errors: [] });

    const {
      memberCount,
      roleIds,
      errorTextNominees,
      errorTextCustomFields,
      errorTextRoles,
      // errorTextAwardValues,
      // awardValues,
      // hasAwardOptions,
      hasRoleOptions
    } = this.props;

    let canSubmit = true;
    let errors = [];

    // Check if all custom fields are filled out for each nominee
    const teamMemberKeys = Object.keys(this.props.teamMembers);
    for (let i = 0; i < teamMemberKeys.length; i++) {
      if (!canSubmit) {
        break;
      }
      let key = teamMemberKeys[i];
      let nominee = this.props.teamMembers[key].customFields;
      for (let x = 0; x < nominee.length; x++) {
        const values = nominee[x].values;
        if (values.includes(null) || values.includes('') || values.includes(0) || values.length < 1) {
          errors.push(errorTextCustomFields);
          canSubmit = false;
          break;
        }
      }
    }

    // // Check if award requires values and if they are selected for each nominee
    // if (hasAwardOptions) {
    //   if (awardValues.includes(null) || awardValues.includes('') || awardValues.includes(0)) {
    //     errors.push(errorTextAwardValues);
    //   }
    // }

    // Check if award requires roles and if they are selected for each nominee
    if (hasRoleOptions) {
      if (roleIds.includes(null) || roleIds.includes('') || roleIds.includes(0)) {
        errors.push(errorTextRoles);
      }
    }

    // Check member count
    if (memberCount === undefined || memberCount === null || memberCount < 2) {
      errors.push(errorTextNominees);
    }

    // Check for total errors
    if (errors.length > 0) {
      this.setState({
        errors: errors
      });
      return Promise.reject(this.state.errors);
    } else {
      this.save();
      this.props.wizard.registerIncrement(this.save);
      return Promise.resolve();
    }
  };

  save = () => {
    return Promise.all([this.props.saveNomination(), this.props.saveTeamMembers()]);
  };

  render() {
    return (
      <NarrowGrid>
        <TeamDetailsNomineeInstructions />
        {this.state.errors.length > 0 && (
          <ErrorFormContainer>
            <Alert bsStyle="danger">
              <ul>
                {this.state.errors.map((error, id) => {
                  return <li key={id}>{error}</li>;
                })}
              </ul>
            </Alert>
          </ErrorFormContainer>
        )}
        <TeamNominationContext.Provider value={{ addForm: this.addForm }}>
          <NomineeDetailForm openOnError={this.state.errors.length > 0 ? true : false} />
        </TeamNominationContext.Provider>
      </NarrowGrid>
    );
  }
}

const mapState = (state) => {
  const currentNomineeIds = teamNominationSelectors.getCurrentNomineeIds(state);
  const teamMembers = teamNominationSelectors.getCurrentTeamMembers(state);

  return {
    currentNomination: teamNominationSelectors.getCurrentNomination(state),
    memberCount: teamMembers ? Object.keys(teamMembers).length : 0,
    roleIds: currentNomineeIds.map((id) => teamNominationSelectors.getNomineeRoleDetail(state, id)),
    hasRoleOptions: teamNominationSelectors.getAwardHasRoles(state),
    teamMembers: teamNominationSelectors.getCurrentTeamMembers(state)
  };
};

const mapDispatch = (dispatch) =>
  bindActionCreators(
    { saveNomination: teamNominationActions.save, saveTeamMembers: teamMemberActions.saveAll },
    dispatch
  );

const mapResource = (getResource) => ({
  errorTextNominees: getResource('teamNomination.teamMemberCountDetailsError', 'Please Select at least two Nominees'),
  errorTextRoles: getResource('teamNomination.teamMemberRolesError', 'Please Select a role for all Nominees'),
  errorTextAwardValues: getResource(
    'teamNomination.teamMemberAwardValuesError',
    'Please Select an award value for all Nominees'
  ),
  errorTextCustomFields: getResource(
    'teamNomination.teamMemberCustomFieldsError',
    'Please fill out all fields for all Nominees'
  )
});

const compose = register('rsv8-nomination/TeamNominationNomineeDetails');

export default compose(
  connect(
    mapState,
    mapDispatch
  ),
  withResource(mapResource)
)(TeamNomineeDetailPage) as React.ComponentType<{}>;
