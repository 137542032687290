import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Loader, Text } from 'rsv8-components';
import { register, withResource } from 'xcel-react-core';
import { FavoritesCarousel } from '../../components/CarouselWrappers';
import { EmptyContainer } from '../../components/EmptyContainer';
import {
  deleteCartById,
  getAccountSummary,
  getCart,
  getFavoriteProducts,
  patchCartItemOption,
  patchCartItemQuantity
  } from '../../redux/actions';
import {
  getCartProducts,
  getCartRequest,
  getCurrentAccountSummary,
  getFavoriteList,
  getFavoriteProductList
  } from '../../redux/selectors';
import CartPanels from './CartPanels/CartPanels';
import CartTable from './CartTable/CartTable';
import {
  CartHeading,
  CartHeadingIcon,
  CheckoutButton,
  EmptyCartImage,
  FlexContainer,
  PointTotalContainer
  } from './styles';

interface ShoppingCartProps {
  accountSummary: any;
  actions: {
    deleteCartById: any;
    getAccountSummary: any;
    getCart: any;
    getFavoriteProducts: any;
    patchCartItemOption: any;
    patchCartItemQuantity: any;
    toggleCheckout: any;
  };
  emptyCartIcon: string;
  cartProducts: Array<any>;
  cartRequest: any;
  cartTitle: string;
  emptyCartText: string;
  favoriteProducts: Array<any>;
  favoritesList: any;
  shoppingCartIcon: any;
  pointValueTotalText: string;
  checkoutButtonPostLabel: string;
  checkoutButtonPreLabel: string;
  favoriteCarouselTitle: string;
  favoriteCarouselCardLabel: string;
  pointsLabel: string;
}

class ShoppingCart extends React.Component<ShoppingCartProps, any> {
  componentDidMount() {
    this.props.actions.getCart();
    this.props.actions.getFavoriteProducts({ id: 'favorite' });
    this.props.actions.getAccountSummary();
  }

  handleOptionChange = (e, productId) => {
    this.props.actions.patchCartItemOption(e.target.value, productId);
  };

  handleQuantityChange = (e, productId) => {
    this.props.actions.patchCartItemQuantity(e.target.value, productId);
  };

  removeFromCart = (product) => (e) => {
    this.props.actions.deleteCartById(product);
  };

  getPointTotal = () => {
    if (this.props.cartProducts && this.props.cartProducts.length > 0) {
      return parseFloat(
        this.props.cartProducts.map((product) => product && product.totalPoints).reduce((prev, next) => prev + next) ||
          0
      ).toLocaleString('en');
    } else {
      return '';
    }
  };

  getCartItemTotal = () => {
    if (this.props.cartProducts && this.props.cartProducts.length > 0) {
      return this.props.cartProducts.map((product) => product && product.quantity).reduce((prev, next) => prev + next);
    } else {
      return '';
    }
  };

  render() {
    const {
      cartProducts,
      cartRequest,
      emptyCartIcon,
      cartTitle,
      emptyCartText,
      pointValueTotalText,
      checkoutButtonPostLabel,
      favoriteCarouselTitle,
      favoriteCarouselCardLabel,
      checkoutButtonPreLabel,
      shoppingCartIcon,
      accountSummary,
      pointsLabel
    } = this.props;
    return (
      <Grid themeVariation="page-wrapper">
        <Row>
          <Col xs={22} xsOffset={1} sm={24} smOffset={0} md={20} mdOffset={2}>
            <FlexContainer>
              <CartHeading>
                <CartHeadingIcon src={shoppingCartIcon} /> {cartTitle}
              </CartHeading>
              {cartRequest.loading && cartProducts && cartProducts.length === 0 ? (
                <Loader />
              ) : (
                <React.Fragment>
                  {cartProducts && cartProducts.length > 0 ? (
                    <React.Fragment>
                      <CheckoutButton themeVariation="primary-button" to={{ pathname: '/catalog/checkout' }}>
                        {`${checkoutButtonPreLabel}${this.getCartItemTotal()} ${checkoutButtonPostLabel}`}
                      </CheckoutButton>
                      <CartTable
                        removeFromCart={this.removeFromCart}
                        cartProducts={cartProducts}
                        changeOption={this.handleOptionChange}
                        changeQuantity={this.handleQuantityChange}
                        accountSummary={accountSummary}
                      />
                      <CartPanels
                        changeOption={this.handleOptionChange}
                        removeFromCart={this.removeFromCart}
                        cartProducts={cartProducts}
                        changeQuantity={this.handleQuantityChange}
                        accountSummary={accountSummary}
                      />
                      <PointTotalContainer>
                        <Text themeVariation="primary-lg-bold-text">{pointValueTotalText}</Text>
                        <Text themeVariation="primary-link-3xl-bold-text">
                          {this.getPointTotal()} {pointsLabel}
                        </Text>
                      </PointTotalContainer>
                    </React.Fragment>
                  ) : (
                    <EmptyContainer>
                      <EmptyCartImage src={emptyCartIcon} />
                      <Text themeVariation="alternate-jumbo-light-text">{emptyCartText}</Text>
                    </EmptyContainer>
                  )}
                </React.Fragment>
              )}
            </FlexContainer>
          </Col>
        </Row>
        <FavoritesCarousel
          cardText={favoriteCarouselCardLabel}
          cardPath={`/catalog/favorites`}
          allLink={true}
          title={favoriteCarouselTitle}
          titleAlign={true}
        />
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  accountSummary: getCurrentAccountSummary(state),
  cartProducts: getCartProducts(state),
  favoritesList: getFavoriteList(state),
  favoriteProducts: getFavoriteProductList(state),
  cartRequest: getCartRequest(state)
});

const mapResourceToProps = (getResource) => ({
  pointsLabel: getResource('catalog.shoppingCart.pointsAbbrevLabel', 'pts.'),
  favoriteCarouselTitle: getResource('catalog.ShoppingCart.favoriteCarouselTitle', 'My Favorites'),
  favoriteCarouselCardLabel: getResource('catalog.shoppingCart.favoriteCarouselCardLabel', 'Manage Your Favorites'),
  checkoutButtonPreLabel: getResource('catalog.shoppingCart.checkoutButtonPreLabel', 'Proceed to Checkout ('),
  checkoutButtonPostLabel: getResource('catalog.shoppingCart.checkoutButtonPostLabel', 'items)'),
  pointValueTotalText: getResource('catalog.pointValueTotalText', 'Point Value Total: '),
  emptyCartText: getResource('catalog.emptyShoppingCartText', 'Your Shopping Cart is Empty.'),
  cartTitle: getResource('catalog.shoppingCartTitle', 'Shopping Cart'),
  emptyCartIcon: getResource('catalog.shoppingCart.emptyCartIcon', '/clientassets/images/icons/no-items-cart.svg'),
  shoppingCartIcon: getResource('catalog.shoppingCartIcon', '/clientassets/images/icons/icon-cart.svg')
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      deleteCartById,
      getAccountSummary,
      getFavoriteProducts,
      getCart,
      patchCartItemOption,
      patchCartItemQuantity
    },
    dispatch
  )
});

const compose = register('rsv8-catalog/ShoppingCart');

export default compose(withResource(mapResourceToProps), connect(mapStateToProps, mapDispatchToProps))(ShoppingCart);
