import * as React from 'react';
import { Loader } from 'rsv8-components';
import { componentObserver } from 'xcel-observer';
import { registry } from 'xcel-react-core';
import { ErrorBoundaryComponent } from 'xcel-react-core';
import { getValue } from 'xcel-util';
import withLegacyContent from '../../../../components/withLegacyContent';
import DataContext from '../../../../config/DataContext';
import NoPageFound from '../NoPageFound';
const ComponentRenderer = ({ data, onNotFound = NoPageFound, childrenProperty = 'children', ...rest }: any) => {
  const NotFoundComponent = onNotFound;
  const renderComponents = (content: any) => (property: string) => {
    const childProperty = property.replace(/.+\./, '');
    const children = getValue(content, property);
    if (!children || children.length === 0) {
      return null;
    }
    return <ComponentRenderer childrenProperty={childProperty} data={children} />;
  };
  const registryData = registry.get();
  return data !== undefined ? (
    data.length > 0 ? ( 
      data.map((config:any, index:any) => {
        let { component: name, content } = config;
        let children = config[childrenProperty];
        let Component = registryData.components[name];

        if (registryData.components[name] === undefined) {
          return <div key={index}>No Component Found ({name})</div>;
        } else if (registryData.content[name] === undefined) {
          Component = withLegacyContent(name)(Component);
          componentObserver.addComponent(name, Component);
        }
        return (
          <DataContext.Consumer key={index}>
            {(dataContext) => {
              return (
                <ErrorBoundaryComponent
                  {...rest}
                  {...dataContext}
                  key={index}
                  renderComponents={renderComponents(content)}
                  content={content}
                  component={Component}
                >
                  {children && children.length > 0 && <ComponentRenderer {...rest} data={children} />}
                </ErrorBoundaryComponent>
              );
            }}
          </DataContext.Consumer>
        );
      })
    ) : (
      <NotFoundComponent />
    )
  ) : (
    <Loader />
  );
};
export default ComponentRenderer;
