import * as events from '../events';

const initialState = {
  userInfo: {}
};

const constructUserInfo = (state, payload) => {
  return {
    ...state,
    userInfo: {
      ...state.userInfo,
      firstName: payload.firstName || state.firstName,
      lastName: payload.lastName || state.lastName,
      businessName: payload.businessName || state.businessName,
      address1: payload.addressOne || state.addressOne,
      city: payload.city || state.city,
      stateProvince: payload.state || state.stateProvince,
      postalCode: payload.postalCode || state.postalCode,
      province: payload.province || state.province,
      email: payload.email || state.email,
      phone: payload.province || state.phone,
    }
  };
};

const redeemReducer = (state: any = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case events.SET_USER_INFO:
      return {
        ...state,
        userInfo: payload
      };
    case events.SAVE_PROFILE_INFO:
      return constructUserInfo(state, payload);
    default:
      return {
        ...state
      };
  }
};

export { redeemReducer };
