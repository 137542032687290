import Alert from './AlertItem';
import { connect } from 'react-redux';
import * as React from 'react';
import { getAlerts } from '../../redux/alert.selector';
import { Alert as AlertType } from '../../types';

const AlertList: React.SFC<{ alerts: Array<AlertType> }> = ({ alerts }) => (
  <React.Fragment>{alerts.map((a) => <Alert key={a.id} alert={a} />)}</React.Fragment>
);

const mapStateToProps = (state) => ({ alerts: getAlerts(state) });

export default connect(mapStateToProps, null)(AlertList) as React.ComponentClass<{}>;
