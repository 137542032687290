import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { orderBy } from 'lodash';
import { Container, Content } from 'rsv8-components';
import { assetService } from 'xcel-asset-service';
import { register, withContent, withTheme } from 'xcel-react-core';
import { withApiDataProvider } from 'xcel-redux-orm';
import { RedeemOption } from 'xcel-api-generator/dist/alcon';
import { getRedeemOptions } from '../../redux/actions';
import { redeemOptionSelector } from '../../redux/selectors';
import * as styles from './styles/';

interface ComponentProps {
  redeemOptions?: RedeemOption[];
}

const createRebateCards = (redeemOptions: RedeemOption[]) => {
  return orderBy(redeemOptions, (redeemOption) => redeemOption.sequence).map((redeemOption, index: any) => {
    const cursor = redeemOption.canRedeem ? 'pointer' : null;
    return (
      <styles.ProductCardDiv
        cursor={cursor}
        onClick={() => {
          if (redeemOption.canRedeem) {
            window.location.assign('/redeem/' + redeemOption.id);
          }
        }}
        key={index}
        style={{ margin: '10px 0 !important' }}
      >
        <styles.ProductCardBody themeVariation="rebatoptionscard">
          <styles.CardImage backgroundImage={assetService.getImage('content/' + redeemOption.imageUrlLarge)} />
          <div>
            <h3 style={{ fontFamily: 'open-sans-bold', color: '#505d6f', fontSize: '24px', textAlign: 'center' }}>{redeemOption.name}</h3>
            <Content themeVariation="rebateoptionscards" content={{ content: redeemOption.description }} />
          </div>
        </styles.ProductCardBody>
      </styles.ProductCardDiv>
    );
  });
};
const rebateCard: React.FC<ComponentProps> = ({ redeemOptions, ...rest }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!redeemOptions) {
      return;
    }
    setLoading(false);
  }, [redeemOptions]);

  return (
    <div {...rest}>
      {loading && <h3>Loading</h3>}
      {!loading && redeemOptions && <Container themeVariation="rebate-option-cards">{createRebateCards(redeemOptions)}</Container>}
    </div>
  );
};

const mapStateToProps = (state) => ({
  redeemOptions: redeemOptionSelector.selectMany(state),
});

export default register('alcon-information/rebateCard')(
  connect(mapStateToProps),
  withApiDataProvider(getRedeemOptions, redeemOptionSelector),
  withTheme(),
  withContent()
)(rebateCard);
