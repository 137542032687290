import * as React from 'react';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import { register, withTheme } from 'xcel-react-core';

const ToastWrapper = styled.div`
  .default-toast-body {
    border-left: 10px solid #4192a5;
  }
  .admin-warning {
    background: #ff9800;
    color: #fff;
    font-size: 16px;
  }
  .admin-success {
    background: #28a745;
    color: #fff;
    font-size: 16px;
  }
` as any;

const Container = styled(ToastContainer)`
  @media (max-width: 480px) {
    width: 100%;
  }
` as any;

const Toast = ({ className, children, ...rest }) => (
  <ToastWrapper className={className}>
    <Container {...rest} />
    {children}
  </ToastWrapper>
);

const Toastify = register('rsv8-components/Toastify')(withTheme('.default-toast-body'))(Toast);

export { Toastify };
