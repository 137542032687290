import * as React from 'react';
import { ModularMenu } from 'rsv8-navigation';
import { NominationDashboard } from 'rsv8-nomination';
import { withResource } from 'xcel-react-core';
// import { toast } from 'rsv8-components';

type Props = {
  modularMenuTitleText: string;
};

const NominationDashboardComposite: React.SFC<Props> = ({ modularMenuTitleText }) => (
  <React.Fragment>
    <ModularMenu appKey="nominationNav" title={modularMenuTitleText} template="NominationsHeaderNav" />
    <NominationDashboard />
  </React.Fragment>
);

const mapResourceTopProps = (getResource) => ({
  modularMenuTitleText: getResource('headerMenu.headingText', 'Recognition')
});

export default withResource(mapResourceTopProps)(NominationDashboardComposite);
