import * as React from 'react';
import { Clearfix, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { cultureSelector } from 'rsv8-client';
import { Grid, HeaderTwo, Image } from 'rsv8-components';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';
import { destroyRequest, getRequestEndpoint } from 'xcel-redux-orm';
import myAwardActions from '../../redux/actions/myAwards';
import myAwardSelector from '../../redux/selectors/myAward';
import * as MyAwardsFunc from './MyAwardsFunc';
import MyAwardsList from './MyAwardsList';
import { PlaceholderEmpty } from './Placeholder/index';
import { PlaceholderLoading } from './Placeholder/index';

export interface MyAwardsProps {
  culture: string;
  myAwardsHeadingText: string;
  myAwardsEmptyText: string;
  myAwards: any;
  dispatch: any;
  view: string;
  requestData: any;
}

const MyAwardsContainer = styled(Col)``;

const StyledHeaderTwo = styled(HeaderTwo)`
  margin: 0 !important;
`;

const TitleIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

class MyAwards extends React.Component<MyAwardsProps, any> {
  static getDerivedStateFromProps(nextProps: MyAwardsProps, prevState: any) {
    let displayEmptyBool = false;
    let displayNoResultsBool = false;
    let displayListBool = false;
    let displayLoading = true;
    let isFiltering = false;
    let filterText = 'Filtering Awards';
    let noFilterResults;
    let awardsCount = nextProps.myAwards && nextProps.myAwards && nextProps.myAwards.length;
    // AWARDS HAVE NOT LOADED
    if (!nextProps.requestData || (nextProps.requestData.loading && !nextProps.requestData.succeeded)) {
      // IF NOT FILTERING
      if (prevState.filterInProgress !== true) {
        // DISPLAY EMPTY PLACEHOLDER
        if (awardsCount <= 0) {
          displayEmptyBool = true;

          // DISPLAY LOADING
        } else {
          displayLoading = true;
        }
      }

      // AWARDS HAVE LOADED
    } else {
      // DISPLAY NO SEARCH RESULTS
      if (awardsCount <= 0) {
        displayNoResultsBool = true;
      }

      // DISPLAY AWARDS LIST
      displayLoading = false;
      displayListBool = true;
    }

    // DISPLAY CLEARING FILTER LOADER
    if (!prevState.noFilterResults) {
      if (prevState.awardsList && prevState.awardsList.length === 0 && prevState.filterInProgress === true) {
        isFiltering = true;
        displayNoResultsBool = false;
        filterText = 'Clearing Filter';
      } else {
        isFiltering = false;
        filterText = 'Filtering Awards';
      }
    }
    // NO FILTER RESULTS - special conditional state
    if (prevState.awardsList && prevState.awardsList.length === 0 && awardsCount === 0) {
      noFilterResults = true;
    } else {
      noFilterResults = false;
    }

    return {
      noFilterResults: noFilterResults,
      filterMsgText: filterText,
      awardsList: nextProps.myAwards,
      displayEmpty: displayEmptyBool,
      displayNoSearchResults: displayNoResultsBool,
      displayList: displayListBool,
      requestInProgress: displayLoading,
      filterInProgress: isFiltering
    };
  }
  constructor(props: MyAwardsProps) {
    super(props);

    this.state = {
      displayEmpty: false,
      displayList: false,
      displayNoSearchResults: false,
      requestInProgress: true,
      filterInProgress: false,
      filterMsgText: 'Filtering Awards',
      noFilterResults: false,
      clearInProgress: false,
      awardsCount: 0,
      awardsList: []
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(myAwardActions.get({}));
    this.setState({
      requestInProgress: true,
      displayList: false,
      awardsList: this.props.myAwards
    });
  }
  componentDidUpdate(prevProps: any) {
    const { dispatch } = this.props;
    if (this.props.culture !== prevProps.culture) {
      dispatch(destroyRequest('getNominationsMyawards'));
      dispatch(myAwardActions.get({})).then(() => {
        this.setState({
          requestInProgress: true,
          displayList: false,
          awardsList: this.props.myAwards
        });
      });
    }
  }
  willClickApply = (formProps) => {
    this.setState(
      {
        filterInProgress: true,
        displayNoSearchResults: false
      },
      () => {
        MyAwardsFunc.clickApply({ ...formProps, dispatch: this.props.dispatch });
      }
    );
  };

  clearFilter = (props) => {
    this.setState(
      {
        awardsList: [],
        filterInProgress: true
      },
      () => {
        this.willClickApply({
          actions: props.actions,
          values: {}
        });
      }
    );
  };

  render() {
    return (
      <Grid themeVariation="page-wrapper">
        <MyAwardsContainer lg={24} md={24} sm={24} xs={24}>
          <TitleIconWrapper>
            <Image themeVariation="nomination-ribbon" />
            <StyledHeaderTwo themeVariation="awards-ribbon-text">{this.props.myAwardsHeadingText}</StyledHeaderTwo>
          </TitleIconWrapper>

          <Clearfix />

          <PlaceholderEmpty
            isDisplayed={this.state.displayEmpty && !this.state.requestInProgress}
            emptyText={this.props.myAwardsEmptyText}
          />
          <PlaceholderLoading isDisplayed={this.state.requestInProgress} />

          <MyAwardsList
            isFiltering={this.state.filterInProgress}
            filterMsgText={this.state.filterMsgText}
            isDisplayed={this.state.displayList}
            noSearchResults={this.state.displayNoSearchResults}
            clickApply={this.willClickApply}
            clickClear={this.clearFilter}
            awards={this.state.awardsList}
            selectList={this.props.myAwards.selectList}
            culture={this.props.culture}
            view={this.props.view}
          />
        </MyAwardsContainer>
      </Grid>
    );
  }
}

const mapState = (state) => ({
  requestData: getRequestEndpoint(state, 'getNominationsMyawards'),
  myAwards: myAwardSelector.selectLast(state, 'getNominationsMyawards'),
  culture: cultureSelector.getActive(state).language
});

const mapResource = (getResource) => ({
  myAwardsHeadingText: getResource('nominations.myAwardsHeadingText', 'My Awards'),
  myAwardsEmptyText: getResource('nominations.myAwardsEmptyText', 'When you get your first award you can see it here.')
});

export default compose<React.Component<MyAwardsProps>>(
  withResource(mapResource),
  connect(mapState)
)(MyAwards);
