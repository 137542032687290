import * as React from 'react';
import { Text } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import CartOptions from '../../../components/Cart/CartOptions';
import CartQuantity from '../../../components/Cart/CartQuantity';
import {
  CatalogTableBody,
  CatalogTableData,
  CatalogTableDataPoints,
  CatalogTableHead,
  CatalogTableHeadRow,
  CatalogTableInfo,
  CatalogTableInfoContainer,
  CatalogTableInfoImage,
  CatalogTableInfoLink,
  CatalogTableInfoProduct,
  CatalogTableRow
  } from '../../../components/ProductTable/ProductTableStyles';
import {
  OptionsTableHeading,
  PointTableHeading,
  ProductTableHeading,
  QuantityTableHeading,
  RemoveTableHeading,
  ShoppingCartTable,
  TableRemoveButton
  } from './styles';

interface CartTableProps {
  cartProducts: any;
  changeOption: any;
  changeQuantity: any;
  removeFromCart: (product: any) => any;
  tableQuantityHeading: string;
  tablePointValueHeading: string;
  tableProductHeading: string;
  tableOptionsHeading: string;
  pointsAbbrevLabel: string;
  removeFromCartLabel: string;
  accountSummary: any;
}

class CartTable extends React.Component<CartTableProps, any> {
  render() {
    const {
      cartProducts,
      changeQuantity,
      removeFromCart,
      changeOption,
      tableQuantityHeading,
      tablePointValueHeading,
      tableProductHeading,
      tableOptionsHeading,
      pointsAbbrevLabel,
      removeFromCartLabel,
      accountSummary
    } = this.props;

    return (
      <ShoppingCartTable className="hidden-xs">
        <CatalogTableHead>
          <CatalogTableHeadRow>
            <ProductTableHeading>
              <Text themeVariation="primary-table-heading">{tableProductHeading}</Text>
            </ProductTableHeading>
            <OptionsTableHeading>
              <Text themeVariation="primary-table-heading">{tableOptionsHeading}</Text>
            </OptionsTableHeading>
            <PointTableHeading>
              <Text themeVariation="primary-table-heading">{tablePointValueHeading}</Text>
            </PointTableHeading>
            <QuantityTableHeading>
              <Text themeVariation="primary-table-heading">{tableQuantityHeading}</Text>
            </QuantityTableHeading>
            <RemoveTableHeading />
          </CatalogTableHeadRow>
        </CatalogTableHead>
        <CatalogTableBody>
          {cartProducts &&
            cartProducts.filter((product) => product !== null).map((product) => (
              <CatalogTableRow key={product.id}>
                <CatalogTableInfo>
                  <CatalogTableInfoImage src={product.imageLg} />
                  <CatalogTableInfoContainer>
                    <CatalogTableInfoLink to={`/catalog/search?filterBrands=${product.manufacturerId}`}>
                      {product.manufacturer}
                    </CatalogTableInfoLink>
                    <CatalogTableInfoProduct
                      themeVariation="text-link"
                      to={`/catalog/product/${encodeURIComponent(product.encodedProductId)}`}
                      dangerouslySetInnerHTML={{ __html: product.productName }}
                    />
                  </CatalogTableInfoContainer>
                </CatalogTableInfo>
                <CatalogTableData>
                  {product.productOptionItems.length > 0 && (
                    <CartOptions product={product} changeOption={changeOption} />
                  )}
                </CatalogTableData>
                <CatalogTableDataPoints>
                  <Text themeVariation="secondary-text-bold">
                    {`${Number(product.pointValue || product.productPoints).toLocaleString()} ${pointsAbbrevLabel}`}
                  </Text>
                </CatalogTableDataPoints>
                <CatalogTableData>
                  <CartQuantity
                    product={product}
                    changeQuantity={changeQuantity}
                    accountSummary={accountSummary}
                    cartProducts={cartProducts}
                  />
                </CatalogTableData>
                <CatalogTableData>
                  <TableRemoveButton themeVariation="primary-link" onClick={removeFromCart(product)} bsStyle="link">
                    {removeFromCartLabel}
                  </TableRemoveButton>
                </CatalogTableData>
              </CatalogTableRow>
            ))}
        </CatalogTableBody>
      </ShoppingCartTable>
    );
  }
}

const mapResourceToProps = (getResource) => ({
  tableProductHeading: getResource('catalog.shoppingCart.tableProductHeading', 'Product'),
  tableOptionsHeading: getResource('catalog.shoppingCart.tableOptionsHeading', 'Options'),
  tablePointValueHeading: getResource('catalog.shoppingCart.tablePointValueHeading', 'Point Value'),
  tableQuantityHeading: getResource('catalog.shoppingCart.tableQuantityHeading', 'Quantity'),
  pointsAbbrevLabel: getResource('catalog.shoppingCart.pointsAbbrevLabel', 'pts.'),
  removeFromCartLabel: getResource('catalog.shoppingCart.removeFromCartLabel', 'Remove')
});

export default withResource(mapResourceToProps)(CartTable);
