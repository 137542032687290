import * as React from 'react';
import MediaQuery from 'react-responsive';
import { HeaderTwo } from 'rsv8-components';
import { register, withContent, withTheme } from 'xcel-react-core';
import { OverlayPopover } from '../Overlay';
import * as styles from './styles';

interface Props extends React.HTMLProps<any> {
  dataTableTitle: any;
  isTitleSet: boolean;
  infoButtonText: string;
  tiers: any[];
  columns: any[];
}

const TierTemplateTable: React.FC<Props> = ({ dataTableTitle, isTitleSet, infoButtonText, tiers, columns, className }) => {
  if (!tiers || tiers.length === 0 || !columns) {
    return null;
  }

  return (
    <div className={className}>
      {isTitleSet && (
        <styles.TierBreakdownTitle>
          <HeaderTwo className="tier-breakdown-title" dangerouslySetInnerHTML={{ __html: dataTableTitle }} />

          <OverlayPopover id="tier-table-popover" placement="bottom">
            {infoButtonText}
          </OverlayPopover>
        </styles.TierBreakdownTitle>
      )}

      <MediaQuery query="(min-width: 768px)">
        <styles.TemplateTableHeaderRow className="tier-breakdown-header-row" columns={columns}>
          {columns.map((column, index) => (
            <styles.TemplateTableHeaderCell
              key={'header-column-' + index}
              className="tier-breakdown-header"
              dangerouslySetInnerHTML={{ __html: column }}
            />
          ))}
        </styles.TemplateTableHeaderRow>
      </MediaQuery>

      <styles.TierBreakdownTable className="tier-breakdown-table">
        {tiers.map((tier, i) => (
          <styles.TemplateTableCellContainer key={'tier-' + i} className="tier-breakdown-table-cell" columns={columns}>
            {!!tier.name && (
              <styles.TemplateTableCellHeader
                className="tier-breakdown-table-cell-header"
                columns={columns}
                dangerouslySetInnerHTML={{ __html: tier.name }}
              />
            )}

            {!!tier.values &&
              tier.values.map((value, j) => (
                <styles.TemplateTableCellValue className="tier-breakdown-table-cell-value" key={'tier-value' + j}>
                  <MediaQuery query="(max-width: 767px)">
                    {!tier.name && (
                      <styles.TemplateTableHeaderCell className="tier-breakdown-header" dangerouslySetInnerHTML={{ __html: columns[j] }} />
                    )}
                    {!!tier.name && (
                      <styles.TemplateTableHeaderCell
                        className="tier-breakdown-header"
                        dangerouslySetInnerHTML={{ __html: columns[j + 1] }}
                      />
                    )}
                  </MediaQuery>

                  <span dangerouslySetInnerHTML={{ __html: value }} />
                </styles.TemplateTableCellValue>
              ))}
          </styles.TemplateTableCellContainer>
        ))}
      </styles.TierBreakdownTable>
    </div>
  );
};

const mapContentToProps = (getContent) => ({
  dataTableTitle: getContent('dataTableTitle', { type: 'html', label: 'Data Table Title:' }),
  isTitleSet: !!getContent('dataTableTitle', { type: 'none' }),
  infoButtonText: getContent('infoButtonText', { type: 'string', label: 'Add info button text' }),
  tiers: getContent('tiers', { type: 'array', label: 'Tiers' }),
  columns: getContent('columns', {
    type: 'array',
    label: 'Columns',
    schema: [
      { model: 'name', type: 'html', label: 'Column Name' },
      { model: 'values', type: 'array', label: 'Column Values' },
    ],
  }),
});

export default register('alcon-data-vis/TierTemplateTable')(withContent(mapContentToProps), withTheme())(TierTemplateTable);
