import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  HeaderDecoration,
  HeaderOne,
  Image,
  Link,
  Paragraph
  } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import { LayoutContainer } from '../../../components/Layout';

const MultipleUsersFound = ({
  hrEmailAddress,
  loginHelpLinkPath,
  resource: { headerText, paragraphText, backToLoginPageText }
}) => (
  <div>
    <HeaderDecoration />
    <Col xs={22} xsOffset={1} lg={22} lgOffset={1} md={22} mdOffset={1}>
      <Image themeVariation="primary-company-logo1" />
    </Col>
    <LayoutContainer>
      <Row>
        <Col xs={22} xsOffset={1} lg={8} lgOffset={8} md={7} mdOffset={8}>
          <Image themeVariation="icon-oops" />
        </Col>
      </Row>
      <Row>
        <Col xs={22} xsOffset={1} lg={12} lgOffset={6} md={12} mdOffset={6}>
          <HeaderOne textalign="center">{headerText}</HeaderOne>
        </Col>
      </Row>
      <Row>
        <Col xs={22} xsOffset={1} lg={12} lgOffset={6} md={12} mdOffset={6}>
          <Paragraph textalign="center">
            {paragraphText} <a href={`mailto:${hrEmailAddress}`}>{hrEmailAddress}</a>
          </Paragraph>
        </Col>
      </Row>
      <Row>
        <Col xs={22} xsOffset={1} lg={8} lgOffset={8} md={8} mdOffset={7}>
          <Link href={loginHelpLinkPath || '/login'} textalign="center" display="block">
            {backToLoginPageText}
          </Link>
        </Col>
      </Row>
    </LayoutContainer>
  </div>
);

const mapState = () => ({});

const mapDispatch = (dispatch) => ({});

const mapResourceToProps = (getResource) => ({
  resource: {
    headerText: getResource(
      'multipleUsersFound.headerText',
      'There are multiple users associated with this email address.'
    ),
    paragraphText: getResource('multipleUsersFound.paragraphText', 'Contact your HR Service Center at '),
    backToLoginPageText: getResource('multipleUsersFound.backToLoginPageText', 'Back to the login page >')
  }
});

export default compose(
  withResource(mapResourceToProps),
  connect(
    mapState,
    mapDispatch
  )
)(MultipleUsersFound) as any;
