import * as React from 'react';
import { ComponentType } from 'react';
import { Col, Row } from 'react-bootstrap';
import { renderDate, Text } from 'rsv8-components';
import { DataTableColumn, DataTableDefault, SORT_DIRECTIONS } from 'rsv8-components';
import { register, withContent, withResource } from 'xcel-react-core';
import { ApiDataProviderProps, withApiDataProvider } from 'xcel-redux-orm';
import { NominationStatusBlock, ViewDetailsIcon } from '../../../components/index';
import { nominationActions } from '../../../redux/index';
import { pendingApprovalSelectors } from '../../../redux/selectors/nomination';

const defaultSort = { field: 'createDate', direction: SORT_DIRECTIONS.desc };
const renderStatus = ({ record, column }) => <NominationStatusBlock data={record} field={column.field} />;

const renderContextMenu = ({ record, isMobile }) => (
  <React.Fragment>
    <ViewDetailsIcon detailType={'PendingApprovalTeamTable'} data={record} />
    {isMobile ? (
      <Text themeVariation="nomination-name">
        {record.nomineeFirstName} {record.nomineeLastName}
      </Text>
    ) : null}
  </React.Fragment>
);

const NominationActivityTableLayout: React.SFC<
  ApiDataProviderProps<ReturnType<typeof pendingApprovalSelectors.selectMany>> & ReturnType<typeof mapResource>
> = (props) => {
  const { columnLabels, emptyText } = props;

  return (
    <Row>
      <Col sm={24}>
        <DataTableDefault {...props} defaultSort={defaultSort} emptyIcon="icon-complete" emptyText={emptyText}>
          <DataTableColumn renderCell={renderContextMenu} width={'45px'} />
          <DataTableColumn field="createDate" renderCell={renderDate}>
            {columnLabels.createDate}
          </DataTableColumn>
          <DataTableColumn field="nomineeFirstName" className="hidden-xs">
            {columnLabels.firstName}
          </DataTableColumn>
          <DataTableColumn field="nomineeLastName" className="hidden-xs">
            {columnLabels.lastName}
          </DataTableColumn>
          <DataTableColumn field="status" renderCell={renderStatus}>
            {columnLabels.status}
          </DataTableColumn>
          <DataTableColumn field="applicationName">{columnLabels.applicationName}</DataTableColumn>
          <DataTableColumn field="awardType">{columnLabels.awardType}</DataTableColumn>
        </DataTableDefault>
      </Col>
    </Row>
  );
};

const mapResource = (getResource) => ({
  columnLabels: {
    createDate: getResource('nominationActivity.dateCreatedTableHeader', 'Date'),
    firstName: getResource('nominationActivity.firstNameTableHeader', 'First Name'),
    lastName: getResource('nominationActivity.lastNameTableHeader', 'Last Name'),
    status: getResource('nominationActivity.awardStatusTableHeader', 'Status'),
    applicationName: getResource('nominationActivity.awardNameTableHeader', 'Award'),
    awardType: getResource('nominationActivity.awardTypeTableHeader', 'Type')
  },
  emptyText: getResource('teamPendingApprovalTable.emptyText', 'There are no approvals that need your attention')
});

export default register('rsv8-nomination/TeamPendingApprovalTable')(
  withContent(),
  withResource(mapResource),
  withApiDataProvider(nominationActions.getTeamPendingApproval, pendingApprovalSelectors, {
    sort: defaultSort
  })
)(NominationActivityTableLayout) as ComponentType<{}>;
