import * as React from 'react';
import { connect } from 'react-redux';
import { CustomerProduct, Program } from 'xcel-api-generator/dist/alcon';
import { ProductFieldType, ProgramName } from '../../constants';
import { baselineYearLabelSelector, currentYearLabelSelector, productSummarySelector, programSelector } from '../../redux/selectors';
import { OverlayPopover } from '../Overlay';
import ProductAttrBlock from './Products/ProductAttrBlock';
import * as styles from './styles';

const SummaryStatisticsPanel: React.FC<Props> = ({
  product,
  program,
  baselineYearLabel,
  currentYearLabel,
  handleCalcButton,
  infoButtonText,
  reCalculateButton,
}) => {
  if (!product) {
    return null;
  }

  return (
    <styles.SummaryStatisticsCard textAlign="left">
      <styles.ProductTableWithBorder>
        <styles.ProductTableRow>
          <styles.SummaryStatisticsRow>
            <styles.ReCalculateButton id="calculateButton" key="calculateButton" onClick={handleCalcButton}>
              {reCalculateButton}
            </styles.ReCalculateButton>

            {(ProgramName.match(ProgramName.Magnifeye, program) || ProgramName.match(ProgramName.Platinum, program)) && (
              <ProductAttrBlock product={product} field={ProductFieldType.BaseLine} headerText={baselineYearLabel} />
            )}
            {(ProgramName.match(ProgramName.OptiPort, program) ||
              ProgramName.match(ProgramName.VisionSource, program) ||
              ProgramName.match(ProgramName.MarketShare, program) ||
              ProgramName.match(ProgramName.TotalPartner, program)) && (
              <ProductAttrBlock product={product} field={ProductFieldType.BaseLine} headerText={'YTD ' + baselineYearLabel} />
            )}

            {(ProgramName.match(ProgramName.Magnifeye, program) || ProgramName.match(ProgramName.Platinum, program)) && (
              <ProductAttrBlock product={product} field={ProductFieldType.Current} headerText="CURRENT" />
            )}
            {(ProgramName.match(ProgramName.OptiPort, program) ||
              ProgramName.match(ProgramName.VisionSource, program) ||
              ProgramName.match(ProgramName.MarketShare, program) ||
              ProgramName.match(ProgramName.TotalPartner, program)) && (
              <ProductAttrBlock product={product} field={ProductFieldType.Current} headerText={'YTD ' + currentYearLabel} />
            )}

            <ProductAttrBlock product={product} field={ProductFieldType.RunRate} headerText="RUN RATE" />
            <ProductAttrBlock product={product} field={ProductFieldType.Target} headerText="TARGET" customAttributes="custompoints" />
            <ProductAttrBlock product={product} field={ProductFieldType.Custom} headerText="CUSTOM" />

            <styles.ProductSummaryInfoIconWrapper>
              <OverlayPopover id="summary-statistics-card-popover" placement="bottom">
                {infoButtonText}
              </OverlayPopover>
            </styles.ProductSummaryInfoIconWrapper>
          </styles.SummaryStatisticsRow>
        </styles.ProductTableRow>
      </styles.ProductTableWithBorder>
    </styles.SummaryStatisticsCard>
  );
};

const mapStateToProps = (state) => ({
  product: productSummarySelector(state),
  program: programSelector.selectMany(state)[0],
  baselineYearLabel: baselineYearLabelSelector(state),
  currentYearLabel: currentYearLabelSelector(state),
});

interface StateProps {
  product: CustomerProduct;
  program: Program;
  baselineYearLabel: string;
  currentYearLabel: number;
}
interface DispatchProps {}
interface OwnProps {
  handleCalcButton: Function;
  infoButtonText: string;
  reCalculateButton: string;
}
type Props = StateProps & DispatchProps & OwnProps;

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, null)(SummaryStatisticsPanel);
