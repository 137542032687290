import * as React from 'react';
import styled from 'styled-components';
import { Text } from 'rsv8-components';

const Label = styled(Text)`
  color: ${(props: any) => (props.color || '#0c0c0c')};
  text-transform: uppercase;
  font-weight: bold;
  a {
    color: inherit;
    text-decoration: underline;
  }
`;

const Required = styled.span`
  color: #db504d;
`;

export interface FieldLabelProps {
  labelName?: string;
  required?: boolean;
  isCheckbox?: boolean;
  isRadio?: boolean;
  color?: string;
  children?: any;
}

export const FieldLabel: React.SFC<FieldLabelProps> = ({ children, isCheckbox, labelName, required, ...rest }) => (
  <React.Fragment>
    <Label isCheckbox={isCheckbox} {...rest} themeVariation="tertiary-text-bold">
      {labelName || children}
      {required && <Required>*</Required>}
    </Label>
    {isCheckbox && <span className="catalog-checkmark" />}
  </React.Fragment>
);
