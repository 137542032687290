import * as React from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import * as styles from './styles';

const ForecastButtonPanel: React.FC<Props> = ({
  handleSaveButton,
  handleShareButton,
  saveForecastButton,
  shareForecastButton,
  saveOrshareForecastTitle,
  saveOrshareForecastDescription,
  saveForecastTitle,
  saveForecastDescription,
  saveForecastNote,
}) => (
  <styles.SaveOrShareCard colSpan="100%">
    {saveForecastNote && <styles.SaveOrShareNote>{saveForecastNote}</styles.SaveOrShareNote>}

    <styles.SaveOrShareTable>
      <div>
        <styles.ShareOrSaveTableCell colSpan="100%">
          {/*print product rows */}
          <styles.SaveOrShareTitle>
            <MediaQuery query="(max-width: 1024px)">{saveForecastTitle}</MediaQuery>
            <MediaQuery query="(min-width: 1025px)">{saveOrshareForecastTitle}</MediaQuery>
          </styles.SaveOrShareTitle>
        </styles.ShareOrSaveTableCell>
      </div>

      <div>
        <styles.ShareOrSaveTableCell colSpan="100%">
          {/*print product rows */}
          <styles.SaveOrShareDescription>
            <MediaQuery query="(max-width: 1024px)">{saveForecastDescription}</MediaQuery>
            <MediaQuery query="(min-width: 1025px)">{saveOrshareForecastDescription}</MediaQuery>
          </styles.SaveOrShareDescription>
        </styles.ShareOrSaveTableCell>
      </div>

      <MediaQuery query="(min-width: 1025px)">
        <styles.ShareOrSaveButtons>
          <styles.ShareOrSaveTableCell>
            {/*print product rows */}
            <styles.SaveButton id={'saveButton'} key={'saveButton'} onClick={handleSaveButton}>
              {saveForecastButton}
            </styles.SaveButton>
          </styles.ShareOrSaveTableCell>
          <styles.ShareOrSaveTableCell>
            {/*print product rows */}
            <styles.ShareButton id={'shareButton'} key={'shareButton'} onClick={handleShareButton}>
              {shareForecastButton}
            </styles.ShareButton>
          </styles.ShareOrSaveTableCell>
        </styles.ShareOrSaveButtons>
      </MediaQuery>

      <MediaQuery query="(max-width: 1024px)">
        <div>
          <styles.ShareOrSaveTableCell>
            {/*print product rows */}
            <styles.SaveButtonMobile id={'saveButton'} key={'saveButton'} onClick={handleSaveButton}>
              {saveForecastButton}
            </styles.SaveButtonMobile>
          </styles.ShareOrSaveTableCell>
        </div>

        <div>
          <styles.ShareOrSaveTableCell>
            {/*print product rows */}
            <styles.ShareButtonMobile id={'shareButton'} key={'shareButton'} onClick={handleShareButton}>
              {shareForecastButton}
            </styles.ShareButtonMobile>
          </styles.ShareOrSaveTableCell>
        </div>
      </MediaQuery>
    </styles.SaveOrShareTable>
  </styles.SaveOrShareCard>
);

const mapStateToProps = (state) => ({});

interface StateProps { };
interface DispatchProps { };
interface OwnProps {
    handleSaveButton: Function,
    handleShareButton: Function,
    saveForecastButton: string,
    shareForecastButton: string,
    saveOrshareForecastTitle: string,
    saveOrshareForecastDescription: string,
    saveForecastTitle: string,
    saveForecastDescription: string,
    saveForecastNote: string,
};
type Props = StateProps & DispatchProps & OwnProps;

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps)(ForecastButtonPanel);