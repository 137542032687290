import {
  assoc,
  concat,
  dissoc,
  evolve,
  has,
  mapObjIndexed as map,
  omit,
  uniqBy,
  zipObj
  } from 'ramda';
import { ensureArray } from 'xcel-util';
import { TeamNomination } from '../../../types';
import * as events from '../../events';

const initialState: TeamNomination.CurrentNominationState = {
  award: null,
  product: null,
  id: null,
  teamName: null,
  customFields: [],
  defaultRoleId: null,
  defaultAwardValue: 0,
  defaultProductValue: 0,
  optionId: null,
  optionPointValue: null,
  teamMembers: {},
  incentive: null,
  clearCurrentNominationData: false
};

const currentNominationReducer = (
  state: TeamNomination.CurrentNominationState = initialState,
  action: TeamNomination.Action
) => {
  switch (action.type) {
    case events.CLEAR_TEAM_NOMINATION:
      return initialState;
    case events.SET_TEAM_NOMINATION:
      return action.payload;
    case events.SET_TEAM_NAME:
      return { ...state, teamName: action.payload };
    case events.SELECT_TEAM_NOMINATION_POINTS:
      return { ...state, points: action.payload };
    case events.SELECT_TEAM_AWARD:
      return { ...state, award: action.payload };
    case events.SELECT_TEAM_PRODUCT:
      return { ...state, product: action.payload };
    case events.SELECT_PRODUCT_OPTION_ID:
      return { ...state, optionId: action.payload };
    case events.SELECT_PRODUCT_OPTION_POINT_VALUE:
      return { ...state, optionPointValue: action.payload };
    case events.SET_DEFAULT_TEAM_MEMBER_VALUE:
      const newTeamMembers = map(
        (v: any) =>
          !v[action.payload.teamMemberKey] ? { ...v, [action.payload.teamMemberKey]: action.payload.value } : v,
        state.teamMembers
      );
      return { ...state, [action.payload.key]: action.payload.value, teamMembers: newTeamMembers };
    case events.ADD_TEAM_MEMBERS:
      const teamMembers = zipObj(action.payload.ids, action.payload.teamMembers);
      const defaultTeamMembers = map(
        (v: any) => ({
          ...v,
          roleId: state.defaultRoleId,
          awardValue: state.points,
          productValue: state.defaultProductValue
        }),
        teamMembers
      );
      return { ...state, teamMembers: { ...state.teamMembers, ...defaultTeamMembers } };
    case events.REMOVE_TEAM_MEMBERS:
      return { ...state, teamMembers: dissoc(action.payload.id, state.teamMembers) };
    case events.TRANSFORM_TEAM_MEMBERS:
      const { key, value, member: members } = action.payload;
      const changeKey = assoc(key, value);
      if (members.length > 0) {
        const evolvePredicate = members.reduce((obj, member) => {
          obj[member] = changeKey;
          return obj;
        }, {});
        return {
          ...state,
          teamMembers: evolve(evolvePredicate, state.teamMembers)
        };
      } else {
        return state;
      }
    case events.TOGGLE_TEAM_NOMINEE:
      const hasNominee = has(action.payload, state.teamMembers);
      return {
        ...state,
        teamMembers: hasNominee
          ? omit([action.payload], state.teamMembers)
          : {
              ...state.teamMembers,
              [action.payload]: { userId: action.payload, roleId: '', customFields: [], awardValue: '' }
            }
      };
    case events.SAVE_TEAM_NOMINATION_SUCCESS:
      return { ...state, id: action.payload.id };
    case events.SELECT_TEAM_CUSTOM_FIELDS:
      const newCustomFields = uniqBy((v: any) => v.id, concat(action.payload, state.customFields));
      return { ...state, customFields: newCustomFields };
    case events.DELETE_TEAM_MEMBER:
      return { ...state, teamMembers: omit([action.payload], state.teamMembers) };
    case events.SELECT_TEAM_MEMBER_CUSTOM_FIELDS:
      if (action.payload.fields && Array.isArray(action.payload.fields)) {
        action.payload.fields.forEach((field) => (field.values = ensureArray(field.values)));
      }
      if (action.payload.id) {
        return {
          ...state,
          teamMembers: {
            ...state.teamMembers,
            [action.payload.id]: {
              ...state.teamMembers[action.payload.id],
              customFields: uniqBy(
                (v: any) => v.id,
                concat(action.payload.fields, state.customFields ? state.customFields : [])
              )
            }
          }
        };
      } else {
        return {
          ...state,
          teamMembers: map(
            (v: any, k: any) => ({
              ...v,
              customFields: uniqBy(
                (val: any) => val.id,
                concat(action.payload.fields, state.teamMembers[k].customFields)
              )
            }),
            state.teamMembers
          )
        };
      }
    case events.CLEAR_CURRENT_CUSTOM_FIELDS:
      return { ...state, customFields: [] };
    case events.SET_CLEAR_CURRENT_NOMINATION_DATA:
      return { ...state, clearCurrentNominationData: action.payload };
    default:
      return state;
  }
};

export default currentNominationReducer;
