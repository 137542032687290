import * as React from 'react';
import { Link } from 'rsv8-components';
import styled from 'styled-components';
import { assetService } from 'xcel-asset-service';
const NominationsHeaderNavStyledWrap = styled.div`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  position: relative;
  z-index: 10;
  .nav-link-selected {
    .icon-wrap {
      span {
        border-bottom: 4px solid #b5be35;
        padding-left: 5px;
        padding-right: 5px;
        display: inline-block;
        padding-bottom: 5px;
      }
    }
  }
  .icon-wrap {
    text-align: center;
    padding-top: 10px;
    .nav-icon {
      max-width: 24px;
      max-height: 24px;
    }
    span {
      border-bottom: 4px solid transparent;
      padding-left: 5px;
      padding-right: 5px;
      display: inline-block;
      padding-bottom: 5px;
    }
  }
` as any;

const FlexContainer = styled.div`
  display: flex;
}
` as any;

const FlexCol = styled.div`
  flex: 1;
` as any;

interface PrimaryNavigationMobileIconNavProps {
  menuItem: any;
  routing: any;
}

class PrimaryNavigationMobileIconNav extends React.Component<PrimaryNavigationMobileIconNavProps, any> {
  render() {
    let { menuItem, routing } = this.props;

    return (
      <NominationsHeaderNavStyledWrap>
        <FlexContainer>
          {menuItem &&
            menuItem.map((item, i) => {
              let parsedMeta = JSON.parse(item.meta);
              let imageURL = parsedMeta.menuItemImage;

              return (
                <FlexCol key={i} className={routing.location.pathname === item.targetUrl ? 'nav-link-selected ' : ''}>
                  <div className="icon-wrap">
                    <Link to={item.targetUrl} themeVariation="secondary-text-link">
                      <img src={assetService.getImage(imageURL)} className="nav-icon" />
                      <br />
                      <span>{item.label}</span>
                    </Link>
                  </div>
                </FlexCol>
              );
            })}
        </FlexContainer>
      </NominationsHeaderNavStyledWrap>
    );
  }
}

export default PrimaryNavigationMobileIconNav as any;
