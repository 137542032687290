import { assetService } from 'xcel-asset-service';
import { pseudoSelectors } from '../../config';
export const getStyle = (style) => {
  return `${style} !important;`;
};
export const is = (set) => (key) => set.indexOf(key) !== -1;
export const isInSet = (set) => (key) => set.filter(is(key)).length !== 0;

export const isPseudoSelector = isInSet(pseudoSelectors.map((selector) => selector.value));
// transforms
const transformPixelValue = ({ property, value }) => `${value}px`;
const transformNoneValueStyle = ({ property, value }) => {
  if (value === true) return '';
  if (value === false) {
    value = 'none';
  }
  return getStyle(`${property}: ${value}`);
};
const transformPixelStyle = ({ property, value }) =>
  value === '' ? '' : getStyle(`${property}:${transformPixelValue({ property, value })}`);
const transformColorValue = ({ colors, value }) => colors[value] && colors[value].color;
const transformColorStyle = ({ property, colors, value }) =>
  colors[value] && colors[value].color && getStyle(`${property}:${transformColorValue({ colors, value })}`);
const transformFontStyle = ({ fonts, value }) => {
  const font = fonts[value];
  if (!font) return undefined;
  let properties = [`font-family:${font.family}`];
  if (font.lineHeight) {
    properties.push(`line-height:${font.lineHeight}px`);
  }
  return properties.map(getStyle).join(' ');
};
const transformProperty = ({ fonts, colors, styles, transform }) => (property) =>
  transform({ fonts, colors, value: styles[property], styles, property });

const externalLinkRegex = /^(https?:\/\/|mailto?:|url\()/;
const transformUrl = ({ property, value }) => {
  let url = value;
  if (!externalLinkRegex.test(value)) {
    url = assetService.getImage(value);
  }
  return getStyle(`background-image: url("${url}")`);
};
const createStyleTransform = (properties, transform) => {
  return {
    toCSS: (styles, colors, fonts) => {
      const css = properties
        .filter((property) => styles[property] !== undefined)
        .map(transformProperty({ fonts, colors, styles, transform }))
        .filter((value) => value !== undefined)
        .join(' ');
      return css === '' ? undefined : css;
    },
    isProperty: is(properties)
  };
};
const createStyle = (style, properties: any) => {
  console.log(style);
  return {
    toCSS: (styles, colors, fonts) => {
      const css = properties
        .filter((property) => styles[property.name] !== undefined)
        .map((property) => transformProperty({ fonts, colors, styles, transform: property.transform })(property.name))
        .join(' ');
      return css === '' ? undefined : getStyle(`${style}:${css}`);
    },
    isProperty: is(properties.map((property) => property.name))
  };
};
const styleGroup = (...list: any[]) => {
  return {
    isProperty: (property) => list.filter((item) => item.isProperty(property)).length > 0,
    toCSS: (styles, colors, fonts) =>
      list
        .map((item) => item.toCSS(styles, colors, fonts))
        .filter((style) => style !== undefined)
        .join(' ')
  };
};
const boxShadow = createStyle('box-shadow', [
  { name: 'box-shadow-inset', transform: ({ value }) => value },
  { name: 'box-shadow-x', transform: transformPixelValue },
  { name: 'box-shadow-y', transform: transformPixelValue },
  { name: 'box-shadow-blur', transform: transformPixelValue },
  { name: 'box-shadow-spread', transform: transformPixelValue },
  { name: 'box-shadow-color', transform: transformColorValue }
]);
const backgroundImageTransform = createStyleTransform(['background-image'], transformUrl);
const noneTransforms = createStyleTransform(
  ['border-left', 'border-right', 'border-top', 'border-bottom'],
  transformNoneValueStyle
);
const colorTransforms = createStyleTransform(['background-color', 'color', 'border-color'], transformColorStyle);
const fontTransforms = createStyleTransform(['font'], transformFontStyle);
const pixelTransforms = createStyleTransform(
  ['border-radius', 'border-width', 'font-size', 'line-height'],
  transformPixelStyle
);
// gives us abiltiy to group all the styles used
export const customStyles = styleGroup(
  boxShadow,
  colorTransforms,
  fontTransforms,
  pixelTransforms,
  noneTransforms,
  backgroundImageTransform
);
