import * as React from 'react';
import styled from 'styled-components';
import { register, withContent } from 'xcel-react-core';
import TemplateContext from './TemplateContext';
const TemplateChildrenFont = styled.span`
  color: rgba(0, 0, 0, 1);
  font-weight: bold;
  ${(props: any) => props.size && `font-size: ${props.size};`};
` as any;
const TemplateChildren = (props) => {
  return (
    <TemplateContext.Consumer>
      {({ children }: any) => {
        return children ? (
          children
        ) : props['data-cms-editing'] !== true ? null : (
          <TemplateChildrenFont title="This is the placeholder where all your children content will go.(when you use this template)">
            [Template Children]
          </TemplateChildrenFont>
        );
      }}
    </TemplateContext.Consumer>
  );
};

export default register('rsv8-cms/TemplateChildren')(withContent())(TemplateChildren);
