import * as React from 'react';
import styled from 'styled-components';
import { register, withContent, withTheme } from 'xcel-react-core';

const CardBodyWrap = styled.div``;

const CardBody: React.SFC = ({ children, ...rest }) => {
  return (
    <CardBodyWrap className="card-body-container" {...rest}>
      {children}
    </CardBodyWrap>
  );
};

export default register('rsv8-components/CardBody')(withContent(), withTheme())(CardBody);
export { CardBody };
