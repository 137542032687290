import * as React from 'react';
import { register, withContent, withTheme } from 'xcel-react-core';
import { AgGridReact } from '@ag-grid-community/react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';
import styled, { injectGlobal } from 'styled-components';
import UserProfile from './CellRenderers/UserProfile';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getUserMetricDataByUserMetricKeyFromDate } from '../../redux/actions/userMetricDataByUserMetricKeyFromDate'
import Status from './CellRenderers/Status'
import * as moment from 'moment';
import { ResponsiveDynamicTable } from './responsiveDynamicTable'
import { AnimatedCircle, Text } from 'rsv8-components'

const agGridThemeName = '.ag-theme-balham'

const StyledAnimatedCircle = styled(AnimatedCircle)`
`;

injectGlobal`
    ${agGridThemeName} .ag-root {
        border: none;
    }
    ${agGridThemeName} .ag-header {
        color: #000;
        background-color: #f1f1f1;
        border: none;
    }
    .ag-paging-panel .ag-paging-row-summary-panel{
        display:none;
    }
    ${agGridThemeName} .ag-paging-panel > span {
        margin: 0 auto;
    }
    ${agGridThemeName} .ag-paging-panel {
        height: 48px;
    }
    ${agGridThemeName} .ag-header-cell-label {
        justify-content: center;
    }
    
    ${agGridThemeName} .ag-header-cell::after {
        border: none
    }
    
    ${agGridThemeName} .ag-paging-panel { 
        background-color: #f1f1f1;
        border-top: 0;
    }
    
    ${agGridThemeName} .ag-ltr .ag-cell  {
        display: flex;
        align-items: center;
        border-color: #f1f1f1;
        border-width: 0px 1px 0px 0px;
            &:first-of-type {
                border-width: 0px 1px 0px 1px;
            }
    }
     
    ${agGridThemeName} .ag-icon {
        font-family: "FontAwesome";
    }
    
    ${agGridThemeName} .ag-icon .ag-icon-next { 
       font-family: "FontAwesome",
    }
    
    ${agGridThemeName} .ag-paging-panel > span {
        color: black;
    }
    
    .ag-icon.ag-icon-previous::before {
       content: '\\f104';
        color: black;
    }
    .ag-icon.ag-icon-next::before {
       content: '\\f105';
        color: black;
    }
    
    .ag-icon.ag-icon-first {
       display: none;
    }
    .ag-icon.ag-icon-last {
       display: none;
    }
    
    .ag-icon.ag-icon-asc::before {
       content: '\\f0dc';
       color: black;
    }
    
    .ag-icon.ag-icon-desc::before {
       content: '\\f0dc';
       color: black;
    }
    
    .ag-icon.ag-icon-none::before {
       content: '\\f0dc';
       color: black;
    }
`;

const gridOptions = {
    localeText: {
        noRowsToShow: 'No data'
    }
};

interface DynamicTableProps {
    className?: any
    itemsPerPage?: string
    allowPaging: boolean
    allowSorting: boolean
    columnsDefinition: Array<object>
    margin: string
    padding: string
    width: string
    programKey: string
    noDataText: string
    metricKey: string
    userMetricKey: string
    monthOffset: number
    actions: any
    statusesDefinition: Array<any>
}

interface DynamicTableState {
    columnDefs: Array<object>
    rowData: Array<object>
    loading: boolean,
    mybigdata:Array<object>,
}

interface ColumnInterface {
    allowSorting: string
    align: string
    sortable: boolean
    customCellComponent?: string
    cellRendererFramework?: any
    cellStyle?: any
    color?: string
    statuses?: Array<any>
    name?: string
    comparator: (val1: string, val2: string) => number
    sort: 'asc'|'desc'
    unSortIcon: boolean
}

const getCustomRenderer = (componentName) => {
    let component
    switch (componentName) {
        case 'userProfile':
            component = UserProfile;
            break
        case 'status':
            component = Status;
            break
        default:
            component = null;
            break
    }
    return component
};

const customComparator = (val1, val2): number => (!val1 || !val2) ? 0 : val1.toLowerCase().localeCompare(val2.toLowerCase());

class DynamicTable extends React.Component<DynamicTableProps, DynamicTableState> {

    static defaultProps = {
        allowSorting: '',
        statusesDefinition: [],
        columnsDefinition: [],
    };

    onGridSizeChanged = (params: any) => params.api.sizeColumnsToFit()

    onFirstDataRendered = (params: any) => params.api.sizeColumnsToFit()

    constructor(props: DynamicTableProps) {
        super(props);

        const statuses = props.statusesDefinition.reduce((obj, item) => ({
            ...obj,
            [ item[ 'status' ] ]: item.color,
        }), {});

        const initialColumnDefs = props.columnsDefinition.map((column: ColumnInterface) => {
            if (column.allowSorting.trim() === 'allow') {
                column.sortable = true;
            }
            if (column.customCellComponent) {
                column.cellRendererFramework = getCustomRenderer(column.customCellComponent.trim());
            }

            column.comparator = customComparator;

            if (column.name === 'Name') {
                column.sort = 'asc';
            }
            column.unSortIcon = true
            column.cellStyle = column.align ? { justifyContent: column.align } : { justifyContent: 'center' }

            if (Object.keys(statuses).length) {
                column.statuses = statuses
            }
            return column;
        });

        this.state = {
            columnDefs: initialColumnDefs,
            rowData: [],
            loading: true,
            mybigdata:[],

        }
    }

    async componentWillMount() {
        const { props } = this
        const { actions, metricKey, userMetricKey, programKey, monthOffset } = props
        const date = moment().add(monthOffset, 'month').format('MM/DD/YYYY');
        this.setState({ loading: true })
        try {
            const { data } = await actions.getUserMetricDataByUserMetricKeyFromDate({
                metricKey,
                userMetricKey,
                programKey,
                date
            }, { jsonApi: true });
            const rowData = JSON.parse(data.attributes.value)
            this.setState({ rowData }, () => this.setState({ loading: false }))
        } catch (e) {
            console.error(e)
            this.setState({ loading: false })
        }
    }

    render() {
        const { state, props } = this;
        const { loading } = state;
        const { className, itemsPerPage, allowPaging } = props;
        
        if (state.rowData.length === 0 || state.columnDefs.length === 0) {
            return (
            <Text variationName="metric-nodata-text">{props.noDataText || gridOptions.localeText.noRowsToShow}</Text>
            )
        }
        
        const paginationPageSize = parseInt(itemsPerPage, 10) || 5;

        var mybigdata = [];
        for(let i = 0; i < state.rowData.length;i++) {
             mybigdata.push(this.state.rowData[i])
         }
        if(state.rowData.length < Number(itemsPerPage)) {
            for(let i = state.rowData.length; i < Number(itemsPerPage);i++) {
                mybigdata.push({});
            }
        }
        return (
          <React.Fragment>
              {loading && <StyledAnimatedCircle/>}

              {!loading && <React.Fragment>
                  <div className={`${className || ''} hidden-sm hidden-xs`} style={{ width: '100%' }}>
                      <div
                        className="ag-theme-balham"
                        style={{ width: '100%' }}
                      >
                          <AgGridReact
                            onGridSizeChanged={this.onGridSizeChanged}
                            domLayout='autoHeight'
                            pagination={allowPaging}
                            paginationPageSize={paginationPageSize}
                            rowHeight={50}
                            headerHeight={50}
                            columnDefs={state.columnDefs}
                            rowData={mybigdata}
                            modules={AllCommunityModules}
                            onFirstDataRendered={this.onFirstDataRendered}
                            gridOptions={gridOptions}
                          />
                      </div>
                  </div>
              </React.Fragment>}
              {!loading && <React.Fragment>
                  <ResponsiveDynamicTable
                    paginationPageSize={paginationPageSize}
                    colDef={state.columnDefs}
                    data={state.rowData}
                    columns={state.columnDefs}
                    className={`${className || ''} visible-sm visible-xs`}
                  />
              </React.Fragment>}
          </React.Fragment>
        );
    }
}

export { DynamicTable };

const mapContentToProps = (getContent) => ({
    itemsPerPage: getContent('itemsPerPage', { type: 'none', label: 'Items per page' }),
    allowPaging: getContent('allowPaging', { type: 'checkbox', label: 'Allow paging' }),
    columnsDefinition: getContent('columnsDefinition', {
        type: 'array',
        label: 'Columns',
        schema: [
            { model: 'headerName', label: 'Name' },
            { model: 'field', label: 'JSON mapping' },
            { model: 'align', label: 'Align ("left", "right", "center")' },
            { model: 'allowSorting', label: 'Allow sorting (print "allow" to enable)' },
            { model: 'customCellComponent', label: 'Component renderer name for that field' }
        ]
    }),
    statusesDefinition: getContent('statusesDefinition', {
        type: 'array',
        label: 'Statuses',
        schema: [
            { model: 'status', label: 'Status' },
            { model: 'color', label: 'Color' }
        ]
    }),
    noDataText: getContent('noDataText', { type: 'none', label: 'No Data Text' }),
    programKey: getContent('programKey', { type: 'none', label: 'Program key' }),
    metricKey: getContent('metricKey', { type: 'none', label: 'Metric Key' }),
    userMetricKey: getContent('userMetricKey', { type: 'none', label: 'User Metric Key' }),
    monthOffset: getContent('monthOffset', { type: 'none', label: 'Month Offset(Enter a Number between -12 and 12 ' })
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ getUserMetricDataByUserMetricKeyFromDate }, dispatch)
});

export default register('rsv8-metrics/DynamicTable')
(
  connect(
    null,
    mapDispatchToProps
  ),
  withTheme(),
  withContent(mapContentToProps)
)
(DynamicTable);
