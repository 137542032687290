import * as React from 'react';
import { ModalProps } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Button,
  HeaderThree,
  Modal as StyledModal,
  ModalBody,
  ModalFooter,
  ModalHeader
  } from 'rsv8-components';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';

const StyledHeader = styled(ModalHeader as any)`
  margin: 0 30px;
  padding: 15px 0;
` as any;

const StyledBody = styled(ModalBody as any)`
  margin: 0 30px;
  padding: 0;
` as any;

export interface Props extends Partial<ModalProps> {
  toggleModal: any;
  modalHeading: string;
  closeLabel: string;
}

const Modal: React.SFC<Props> = ({ toggleModal, modalHeading, closeLabel, children, ...rest }) => (
  <StyledModal onHide={toggleModal} {...rest}>
    <StyledHeader closeButton={true}>
      <HeaderThree themeVariation="no-margins">{modalHeading}</HeaderThree>
    </StyledHeader>
    <StyledBody>{children}</StyledBody>
    <ModalFooter>
      <Button themeVariation="secondary" onClick={toggleModal}>
        {closeLabel}
      </Button>
    </ModalFooter>
  </StyledModal>
);

const mapResourceToProps = (getResource) => ({
  closeLabel: getResource('catalog.orderHistory.modalCloseLabel', 'Close')
});

export default compose<any>(
  connect(),
  withResource(mapResourceToProps)
)(Modal);
