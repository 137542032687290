import * as React from 'react';
import { Alert, FormGroup } from 'react-bootstrap';
import { compose } from 'redux';
import { Field, reduxForm } from 'rsv8-forms';
import { withResource } from 'xcel-react-core';
import { FieldContainer, SubmitButton } from '../../../components/FormControls';
import { LoginMaxWidth } from '../../../components/LoginMaxWidth';

interface RequestPasswordResetFormProps {
  resource: {
    usernameLabel: any;
    submitButtonLabel: any;
  };
  handleSubmit: any;
  error: any;
}

interface RequestPasswordResetFormState {
  usernameValue: boolean;
}

class RequestPasswordResetForm extends React.Component<RequestPasswordResetFormProps, RequestPasswordResetFormState> {
  constructor(props) {
    super(props);
    this.state = { usernameValue: false };
  }

  handleChange = (e) => {
    this.setState({ usernameValue: e.target.value });
  };

  render() {
    const {
      resource: { usernameLabel, submitButtonLabel }
    } = this.props;
    return (
      <LoginMaxWidth>
        <form onSubmit={this.props.handleSubmit}>
          {this.props.error && !this.state.usernameValue && <Alert bsStyle="danger">{this.props.error}</Alert>}
          <FormGroup
            controlId="formValidationError3"
            validationState={this.props.error && !this.state.usernameValue ? 'error' : null}
          >
            <Field
              name="userName"
              component={'bs-input'}
              label={usernameLabel}
              type="text"
              value={this.state.usernameValue}
              onChange={this.handleChange}
            />
            <FieldContainer>
              <SubmitButton
                themeVariation="full-width"
                value={submitButtonLabel}
                disabled={this.props.error && !this.state.usernameValue}
              />
            </FieldContainer>
          </FormGroup>
        </form>
      </LoginMaxWidth>
    );
  }
}

const mapResourceToProps = (getResource) => ({
  resource: {
    usernameLabel: getResource('requestPasswordReset.usernameLabelText', 'Username*'),
    submitButtonLabel: getResource('requestPasswordReset.submitButtonText', 'Reset Password')
  }
});

export default compose(
  withResource(mapResourceToProps),
  reduxForm({ form: 'resetPassword' })
)(RequestPasswordResetForm as any) as any;
