import * as React from "react";
import { connect } from "react-redux";
import { Button } from "rsv8-components";
import { getClientId } from "../../redux/selectors";
import { getConfigurationProperty } from "xcel-config";
import { register, withContent, withTheme } from "xcel-react-core";
import { CodeInput, FlexWrapper, FormContainer } from "./styles";

interface Props {
  className: string;
  clientId: string;
  formTitle: string;
  formDescription: string;
  inputPlaceholder: string;
  buttonText: string;
}

interface State {
  value: string;
}

class RegisterByCode extends React.Component<Props, State> {
  state = { value: "" };

  handleChange = (event: React.FormEvent) => {
    this.setState({ value: (event.target as HTMLInputElement).value });
  };

  handleSubmit = (event: React.FormEvent) => {
    const { clientId } = this.props;
    const formPath = getConfigurationProperty(["registrationUrl"]);
    event.preventDefault();
    window.location.href = `${formPath}?clientid=${clientId}&regcode=${this.state.value}`;
  };

  render() {
    const { children, className, inputPlaceholder, buttonText } = this.props;

    return (
      <FormContainer className={className}>
        <form onSubmit={this.handleSubmit}>
          {children}
          <FlexWrapper>
            <CodeInput
              className="registration-code-input"
              type="text"
              value={this.state.value}
              onChange={this.handleChange}
              placeholder={inputPlaceholder}
            />
            <Button type="submit">{buttonText}</Button>
          </FlexWrapper>
        </form>
      </FormContainer>
    );
  }
}

const mapState = (state) => ({
  clientId: getClientId(state)
});

const mapContentToProps = (getContent) => ({
  formDescription: getContent("formDescription", {
    type: "string",
    label: "Form Description",
    width: "full"
  }),
  formTitle: getContent("formTitle", {
    type: "string",
    label: "Form Title"
  }),
  inputPlaceholder: getContent("inputPlaceholder", {
    type: "string",
    label: "Input Placeholder"
  }),
  buttonText: getContent("buttonText", {
    type: "string",
    label: "Button Text"
  })
});

export default register("rsv8-auth/RegisterByCode")(
  connect(mapState),
  withContent(mapContentToProps),
  withTheme([".registration-code-input"])
)(RegisterByCode);
