import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ModalProps } from 'react-bootstrap';
import {
  HeaderThree,
  Modal as ModalCmp,
  ModalBody,
  ModalHeader
  } from 'rsv8-components';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';

const StyledModal = styled(ModalCmp as any)`
  
  .modal-content {
    width: 455px;
    height: 455px;    
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 100% !important;    
    opacity: 0.8; 
    filter: alpha(Opacity=80); 
  }
`;

const StyledHeader = styled(ModalHeader as any)`
  margin-top: 12rem;  
`;

const StyledBody = styled(ModalBody as any)`
  margin: 0 30px;
  padding: 4px;
  @media (min-width: 768px) {
    padding-bottom: 8rem;
  }
`;

export const StyledLabel = styled.div`
  width: 222px;
  height: 36px;
  font-family: Gotham;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  padding-top: 1rem;
  color: #000000;
  &:hover {
    cursor: pointer;
    border: solid 2px #000000;  
  }
` as any;

export interface Props extends Partial<ModalProps> {
  toggleModal: any;
  removeHandler: any;
  modalHeaderText: string;
  confirmDeletionText: string;
  keepPostText: string;
}

const ConfirmFeedCardDeletionModal: React.SFC<Props> = ({ toggleModal, removeHandler, modalHeaderText, confirmDeletionText, keepPostText, children, ...rest }) => (
  <StyledModal onHide={toggleModal} {...rest}>
    <StyledHeader closeButton={false}>
      <HeaderThree>{modalHeaderText}</HeaderThree>
    </StyledHeader>
    <StyledBody>
      <StyledLabel onClick={removeHandler}>
        {confirmDeletionText}
      </StyledLabel>
      <StyledLabel onClick={toggleModal}>
        {keepPostText}
      </StyledLabel>
    </StyledBody>

  </StyledModal>
);

const mapResourceToProps = (getResource) => ({
  modalHeaderText: getResource('social.confirmFeedCardDeletion.modalHeader', 'Are You sure You want to delete this post?'),
  confirmDeletionText: getResource('social.confirmFeedCardDeletion.confirmDeletionText', 'Yes, Delete post'),
  keepPostText: getResource('social.confirmFeedCardDeletion.keepPostText', 'Nevermind, keep post.')
});

export default compose<any>(
  connect(),
  withResource(mapResourceToProps)
)(ConfirmFeedCardDeletionModal);
