import { combineReducers } from 'redux';
import * as constant from '../../constants';
import { dashboardReducer } from './dashboardReducer';
import { directDepositReducer } from './directDepositReducer';
import { patientsReducer } from './patientsReducer';
import { summaryReducer } from './summaryReducer';

export const reducers = {
  [constant.ROOT_STATE]: combineReducers({
    [constant.DASHBOARD_REDUCER_KEY]: dashboardReducer,
    [constant.SUMMARY_REDUCER_KEY]: summaryReducer,
    [constant.DIRECT_DEPOSIT_REDUCER_KEY]: directDepositReducer,
    [constant.PATIENTS_STATE]: patientsReducer,
  }),
};
