import * as React from 'react';
import { connect } from 'react-redux';
import { Text } from 'rsv8-components';
import styled from 'styled-components';
import {
  register,
  withContent,
  withResource,
  withTheme
  } from 'xcel-react-core';
import UserPoints from './UserPoints';
import { RequirePermissions } from 'rsv8-alcon';

const PointsValue = styled(Text)`
  font-weight: 700;
  font-size: 20px;
`;

const PointsLabel = styled(Text)`
  font-weight: 100;
  font-size: 12px;
  padding-left: 5px;
`;

const PointsContainer = styled.div`
  display: block;
  text-align: center;
  height: auto;
  padding-bottom: 20px;
  @media (min-width: 768px) {
    padding-bottom: 0;
    display: inline-block;
    text-align: inherit;
    margin-right: 10px;
  }
`;

const PointsSpan: React.FC<any> = ({permissions,pointsLonghand,...rest }) => {
  return (
    <RequirePermissions permissionsOptions={{canViewAccount: true, canViewAccountBalance: true}}>
      <PointsContainer {...rest}>
        <PointsValue className="points-value">
          <UserPoints variationName={rest.variationName} />
        </PointsValue>
        <PointsLabel themeVariation="header-points-label">{pointsLonghand}</PointsLabel>
      </PointsContainer>
    </RequirePermissions>
  );
};

const mapStateToProps = (state) => ({
  points: state.points
});

const mapResourceToProps = (getResource) => ({ 
  pointsLonghand: getResource('account.pointsLonghand', 'POINTS')
});

export default register('rsv8-account/UserPointsLonghand')(
  withResource(mapResourceToProps),
  connect(mapStateToProps),
  withTheme(),
  withContent()
)(PointsSpan);
