import * as React from 'react';
import { Grid as BSGrid, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { register, withContent, withTheme } from 'xcel-react-core';

export type Props = {
  margin?: string;
  padding?: string;
  display?: string;
  alignItems?: string;
  position?: string;
  fullHeight?: boolean;
};

const EditableGrid = styled(BSGrid)`
  ${(props: Props) => props.fullHeight && `height: 100%`};
`;

const StyledRow = styled(Row)`
  align-items: ${(props: Props) => (props.alignItems ? `${props.alignItems}` : 'normal')};
  display: ${(props: Props) => (props.display ? `${props.display}` : 'block')};
  margin: ${(props: Props) => (props.margin ? `${props.margin}` : 'inherit')};
  padding: ${(props: Props) => (props.padding ? `${props.padding}` : 'inherit')};
  position: ${(props: Props) => (props.position ? `${props.position}` : 'static')};
`;
const Grid = ({ children, className, alignItems, content, fullHeight, fluid = 'false' as any }) => {
  // conver string to boolean
  let fluidBoolean;
  try {
    fluidBoolean = JSON.parse(fluid);
  } catch (err) {
    console.log('rsv8-components/Grid error', err);
    return null;
  }
  return (
    <EditableGrid fullHeight={fullHeight} fluid={fluidBoolean}>
      <StyledRow alignItems={alignItems} className={className} {...content}>
        {' '}
        {children}{' '}
      </StyledRow>
    </EditableGrid>
  );
};

const mapContentToProps = (getContent) => ({
  fluid: getContent('fluid', { type: 'none', label: 'Grid fluid' }),
  fullHeight: getContent('fullHeight', { type: 'checkbox', label: 'Full Height?' })
});
export default register('rsv8-components/layout/Grid')(withContent(mapContentToProps), withTheme())(Grid);
