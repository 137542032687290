import * as React from 'react';
import { Chevrons, Container, Dot } from './styles';

export const Navigation = ({ increment, decrement, items, selected, scrollTo, nextBtnEnabled, prevBtnEnabled }) => {
  return (
    <Container>
      {items.length > 1 && <Chevrons name="angle-left" onClick={decrement} isVisible={prevBtnEnabled} />}
      {items.map((_, index) => (
        <Dot isSelected={index === selected} onClick={() => scrollTo(index)} key={index} />
      ))}
      {items.length > 1 && <Chevrons name="angle-right" onClick={increment} isVisible={nextBtnEnabled} />}
    </Container>
  );
};
