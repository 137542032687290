import * as React from 'react';
import styled from 'styled-components';
import { register, withTheme } from 'xcel-react-core';
import { Text } from '../../Typography';
// tslint:disable

const Container = styled.div`
  align-items: center;
  display: flex;
  height: auto;
  padding: 5px 5px 5px 10px;
  word-break: break-word;
` as any;

export interface Props {
  message: string;
  themeVariation?: string;
}

const Default: React.SFC<Props> = ({ message, themeVariation, ...rest }) => (
  <React.Fragment>
    <Container {...rest}>
      <div>
        <Text
          themeVariation={themeVariation ? themeVariation : 'primary-text'}
          dangerouslySetInnerHTML={{
            __html: `${message}`
          }}
        />
      </div>
    </Container>
  </React.Fragment>
);

const CatalogBody = register('rsv8-components/Toastify/templates/Default')(withTheme())(Default);

export default CatalogBody;
