import * as React from 'react';
import * as utils from '../../../../utils';
import * as styles from './styles';

export const AttributeValue: React.FC<{
  unitLabel: string;
  unitData: string | number;
}> = ({ unitLabel, unitData }) => (
  <styles.ProductTableCell className="product__attribute-value">
    <styles.CenteredCell>
      <styles.ProductAttrText>{unitLabel.toUpperCase()}</styles.ProductAttrText>
    </styles.CenteredCell>

    <styles.CenteredCell>
      <styles.ProductAttrText>{utils.getDecimalValue(unitData)}</styles.ProductAttrText>
    </styles.CenteredCell>
  </styles.ProductTableCell>
)