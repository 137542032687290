export { getCalculatorGoalsCombinedByCalculatorKey } from './getCalculatorGoalsCombinedByCalculatorKey';
export { getTimePeriod, dispatchTimePeriod  } from './getTimePeriod';
export { getCalculations } from './getCalculations';
import { promiseDispatcher } from 'xcel-react-core';

import { createJsonApiActions } from 'xcel-redux-orm';
import {metricApiAction}  from '../../api/'
import {getUserMetrics} from '../actions/userMetricsApiActions'
import {getUserProfile} from '../actions/profileActions'

const actions = createJsonApiActions('getGoals');
const actions2 = createJsonApiActions('getMetrics');
const getCreatGoalsCall = async (programKey:string,calculatorKey:string) => {
  return  await metricApiAction.createCalculatorGoalsCalculateByCalculatorKey({programKey:programKey,calculatorKey:calculatorKey,body:[]});
};

const getCreateMetricsCall = async (programKey:any,calculatorKey:any,status:any) => {

  if (status!==undefined) {
    if(calculatorKey!==undefined &&  programKey !==undefined) {
      return await  metricApiAction.createCalculatorMetricsCalculateByCalculatorKey({programKey:programKey,calculatorKey:calculatorKey,body:[status]});
    }
    return await  metricApiAction.createCalculatorMetricsCalculateByCalculatorKey({programKey:"RFREWARDS",calculatorKey:"RFREWARDS",body:[]});

    }
    if (status === undefined)  {
      if(calculatorKey!==undefined  &&  programKey !==undefined) {
      return  await metricApiAction.createCalculatorMetricsCalculateByCalculatorKey({programKey:programKey,calculatorKey:calculatorKey,body:[]});
      }
      return  await metricApiAction.createCalculatorMetricsCalculateByCalculatorKey({programKey:"RFREWARDS",calculatorKey:"RFREWARDS",body:[]});
    }

};
const getGoals = promiseDispatcher(getCreatGoalsCall, actions);
const getMetrics = promiseDispatcher(getCreateMetricsCall, actions2);

export { getGoals,getMetrics,getUserMetrics,getUserProfile};

export { getPoints, } from './metric';
