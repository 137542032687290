import { single } from 'validate.js';

import { Validator } from './types';

// Create single-value validation functions using the validate.js API
const validateJS = (obj: any): Validator => (value: string) => {
  return single(value, obj);
};

export default validateJS;
