import { Parser } from 'html-to-react';
import * as React from 'react';
import styled from 'styled-components';
import { getValue } from 'xcel-util';
const ErrorText = styled.div`
  color: red;
`;
const htmlToReactParser = new Parser({ lowerCaseTags: false, lowerCaseAttributeNames: false });
const isValidNode = function() {
  return true;
};

class ComponentErrorBoundary extends React.Component<any> {
  state = { error: '' };

  componentDidCatch(error: any, info: any) {
    // Display fallback UI
    this.setState({ error });
    console.log(JSON.stringify(error));
    return false;
  }

  render() {
    const { name, component, ...rest } = this.props;
    if (this.state.error !== '') {
      // You can render any custom fallback UI
      return (
        <span>
          Failed to render {name}.
          <ErrorText>
            <strong>Error:</strong> {this.state.error.toString().split('\n')[0]}
          </ErrorText>
        </span>
      );
    }
    const Component = component;
    return <Component {...rest} />;
  }
}

const componentParser = ({ components, componentProperty, jsx }) => {
  if (jsx === undefined) {
    console.warn('jsx provided was undefined, no component will be rendered');
    return null;
  }
  // recursive function that walks the children
  const parseNode = ({ tag, type, data, name, attribs, children }, key) => {
    if (type === 'text') {
      return data;
    }
    let component;
    const withThemeComponent = getValue(components, `${name}.${componentProperty}`);
    children =
      children &&
      children.map((child, index) => {
        return parseNode(child, index);
      });
    children = children.length !== 0 ? children : undefined;

    attribs = Object.keys(attribs).reduce(
      (obj, property) => {
        if (obj[property].indexOf('{') === 0) {
          let propertyValue = obj[property].substring(1, obj[property].length - 1);
          if (/[\[\]{}]/.test(propertyValue)) {
            propertyValue = propertyValue.replace(/([\{,])([a-zA-Z0-9]+):/g, '$1"$2":');
            propertyValue = propertyValue.replace(/:\s*'([^']+)'/g, ':"$1"');
            try {
              propertyValue = JSON.parse(propertyValue);
            } catch (e) {
              // we just want to eat this bad boy.
              console.warn('failed to parse property', property, propertyValue, e);
            }
          }
          obj[property] = propertyValue;
        }
        return obj;
      },
      { ...attribs }
    );
    if (withThemeComponent) {
      component = withThemeComponent;
    } else {
      component = () => {
        // map attributes
        return React.createElement(name, attribs, children);
      };
    }
    const cprops = { ...attribs, children, name, component, key };
    return <ComponentErrorBoundary {...cprops} />;
  };
  // Order matters. Instructions are processed in
  // the order they're defined
  const processingInstructions = [
    {
      shouldProcessNode: function(node: any) {
        return true;
      },
      processNode: function(node: any, children: any, index: any) {
        // console.log('Processing node', node, children, index);
        return parseNode(node, index);
      }
    }
  ];
  if (!jsx.trim || jsx.trim() === '') {
    return <span />;
  }
  try {
    return htmlToReactParser.parseWithInstructions(jsx, isValidNode, processingInstructions);
  } catch (e) {
    console.warn(e);
  }
};

export default componentParser;
