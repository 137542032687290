import * as React from 'react';
import { connect } from 'react-redux';
import HoverContext from '../../../../hoc/withHover/HoverContext';
import { componentActions } from '../../../../redux/actions';
import ComponentSettings from '../Modals/ComponentSettings';
import Toolbar from './Toolbar';

class EditToolbar extends React.Component<any> {
  state = { active: undefined };
  handleDuplicate = (from) => (e) => {
    const { dispatch } = this.props;
    dispatch(componentActions.duplicate({ from }));
  };
  handleRemove = (from) => (e) => {
    const { dispatch } = this.props;
    dispatch(componentActions.remove({ from }));
  };
  handleToggleSettings = (active) => (e) => {
    this.setState({ active });
  };
  render() {
    return (
      <HoverContext.Consumer>
        {({ isOver, getActive, onMouseOver, onMouseLeave, ...rest }: any) => {
          let active = getActive();
          if (!active || !active.element) {
            if (this.state.active) {
              active = this.state.active;
            } else {
              return null;
            }
          }
          if (active.data === undefined) return null;
          const data = active.data;
          const name = data.name && data.name.replace(/.+\//, '');
          const showName = data.showName !== undefined ? data.showName : true;
          const position = active.element;
          const scrollTop = (document.body.parentNode as any).scrollTop;
          const handleOnMouseOver = (e) => {
            onMouseOver(active.data)({ stopPropagation: e.stopPropagation, target: active.element });
          };
          const handleOnMouseLeave = (e) => {
            onMouseLeave(active.data)({ stopPropagation: e.stopPropagation, target: active.element });
          };
          return (
            <React.Fragment>
              <Toolbar
                position={position}
                scrollTop={scrollTop}
                onMouseOver={handleOnMouseOver}
                onMouseLeave={handleOnMouseLeave}
              >
                {showName === false ? '' : name}
                &nbsp;
                <i className="fa fa-cog" title={'Settings'} onClick={this.handleToggleSettings(active)} />
                &nbsp;
                <i className="fa fa-copy" title={'Duplicate'} onClick={this.handleDuplicate(active.property)} />
                &nbsp;
                <i className="fa fa-trash" title={'Remove'} onClick={this.handleRemove(active.property)} />
              </Toolbar>
              <ComponentSettings
                show={this.state.active !== undefined}
                onHide={this.handleToggleSettings(undefined)}
                component={this.state.active}
              />
            </React.Fragment>
          );
        }}
      </HoverContext.Consumer>
    );
  }
}
const mapStateToProps = () => undefined;
export default connect(mapStateToProps)(EditToolbar);
