import * as React from 'react';
import { RandomRange, generateDelay, defaultDuration } from './../../util';
import { Particle, ParticleContainer } from './styles';
const Particles = ({
  count = undefined,
  size = undefined,
  top = undefined,
  left = undefined,
  delay = generateDelay,
  images = undefined,
  duration = defaultDuration,
  animation = undefined,
  children
}) => {
  let particles = [];
  if (animation !== undefined) {
    for (let i = 0; i < count; i++) {
      let rand = {
        size: size ? size() : RandomRange(10, 20),
        top: top ? top() : RandomRange(90, 100),
        left: left ? left() : RandomRange(0, 95),
        delay: delay().delay
      };
      let randomIndex = Math.round(Math.random() * images.length);
      let image = images[randomIndex];
      particles.push(
        <Particle key={`particle-${i}`} image={image} animation={animation(rand)} duration={duration} {...rand} />
      );
    }
  }
  return (
    <ParticleContainer>
      {particles}
      {children}
    </ParticleContainer>
  );
};

export default Particles;
