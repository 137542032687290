import * as d3 from 'd3';
import * as React from 'react';

interface Props {
  id: any;
}
export const StackedBarChart: React.FunctionComponent<Props> = ({ id }) => {
  function groupData(data, total) {
    // use scale to get percent values
    const percent = d3
      .scaleLinear()
      .domain([0, total])
      .range([0, 100]);
    // filter out data that has zero values
    // also get mapping for next placement
    // (save having to format data for d3 stack)
    let cumulative = 0;
    const _data = data
      .map((d) => {
        cumulative += d.value;
        return {
          value: d.value,
          // want the cumulative to prior value (start of rect)
          cumulative: cumulative - d.value,
          label: d.label,
          percent: percent(d.value)
        };
      })
      .filter((d) => d.value > 0);
    return _data;
  }
  const data = [
    { label: 'Company Ownership', value: 17 },
    { label: 'Embracing Innovation & Collaboration', value: 13 },
    { label: 'Global Enterprise Capabilities', value: 8 },
    { label: 'Competitive in All the Right Ways/Client Focused', value: 14 },
    { label: 'Demonstrating Vigilance & Integrity', value: 9 },
    { label: 'Working Hard and Enjoying the Journey', value: 39 }
  ];

  const config = {
    f: d3.format('.1f'),
    margin: { top: 20, right: 10, bottom: 20, left: 10 },
    width: 550,
    height: 110,
    barHeight: 100,
    colors: ['#158ac2', '#fff', '#5fbbc0', '#073da3', '#75b5cb', '#022771'],
    textColors: ['#0e2f6d', '#273c74', '#073da3', '#cacedf', '#183473', '#5D94FF']
  };
  const { margin, width, height, barHeight, colors, textColors } = config;
  const w = width - margin.left - margin.right;
  const h = height;
  const halfBarHeight = barHeight / 2;

  const total = d3.sum(data, (d) => d.value);
  const _data = groupData(data, total);

  // set up scales for horizontal placement
  const xScale = d3
    .scaleLinear()
    .domain([0, total])
    .range([0, w]);

  React.useEffect(() => {
    const svg = d3
      .select(`#${id}`)
      .append('svg')
      .style('font-family', 'SimpleSans');

    const background = svg
      .append('rect')
      .attr('width', '100%')
      .attr('height', '100%');
    background.attr('fill', '#fad37c');

    const selection = svg
      .attr('width', width)
      .attr('height', height)
      .append('g');

    // stack rect for each data value
    selection
      .selectAll('rect')
      .data(_data)
      .enter()
      .append('rect')
      .attr('class', 'rect-stacked')
      .attr('x', (d) => xScale(d.cumulative))
      .attr('y', h / 2 - halfBarHeight)
      .attr('height', barHeight)
      .style('fill', (d, i) => colors[i])
      .attr('width', 0) //this is the initial value
      .transition()
      .duration(1000) //time in ms
      .attr('width', function(d) {
        return xScale(d.value);
      });

    // add values on bar
    selection
      .selectAll('.text-value')
      .data(_data)
      .enter()
      .append('text')
      .attr('class', 'text-value')
      .attr('text-anchor', 'middle')
      .style('font-size', '17px')
      .style('font-weight', '300')
      .attr('y', h / 2 + 5)
      .attr('x', (d) => xScale(d.cumulative) + margin.left + 2)
      .text((d) => d.value + '%')
      .style('fill', (d, i) => textColors[i])
      .transition()
      .duration(1000) //time in ms
      .attr('x', function(d) {
        return xScale(d.cumulative) + xScale(d.value) / 2;
      });
  }, []);
  return <span id={id} />;
};
