import { compose, withProps } from 'recompose';
import { withTheme } from 'xcel-react-core';

const statusVariationMap = {
  default: 'default',
  nom_status_new: 'new',
  nom_status_approved: 'approved',
  nom_status_awarded: 'awarded',
  nom_status_denied: 'denied',
  nom_status_pending_approval: 'pending',
  nom_status_draft: 'draft',
  nom_detail_status_draft: 'draft',
  nom_detail_status_submitted: 'submitted',
  team_status_draft: 'draft',
  team_status_submitted: 'submitted',
  team_status_approved: 'approved',
  team_status_denied: 'denied',
  team_status_released: 'released'
};

const mapStatusToVariation = (props) => ({
  themeVariation: statusVariationMap[props.status] || 'default'
});

const withThemedStatus = compose(
  withProps(mapStatusToVariation),
  withTheme()
);

export default withThemedStatus;
