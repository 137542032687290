import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { register, withContent, withResource } from 'xcel-react-core';
import { ProgramLevel } from 'xcel-api-generator/dist/achievement';
import { AchievementLevelsList } from '../AchievementLevelsList';
import { AchievementRewards } from '../AchievementRewards';
import ProductDetailsModal from './ProductDetailsModal';
import OrderDetailsModal from './OrderDetailsModal';
import {
  getProductDetailsModalVisibility,
  getOrderDetailsModalVisibility,
  getAchievementProgramLevels
} from '../../redux/selectors';
import { getFavoriteProducts, getAchievementProgramsByIdLevel } from '../../redux/actions';

interface AchievementLevelsProps {
  dispatch: any;
  programId: string;
  programLevels: ProgramLevel[];
  earnedTitle: string;
  earnedText: string;
  achievedTitle: string;
  achievedText: string;
  othersTitle: string;
  othersText: string;
  showProductDetails: boolean;
  showOrderDetails: boolean;
}

class AchievementLevels extends React.Component<AchievementLevelsProps> {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getFavoriteProducts({ id: 'favorite' }));
    this.reloadLevels();
  }

  componentDidUpdate(prevProps: AchievementLevelsProps) {
    const { programId: id, dispatch } = this.props;
    if (prevProps.programId !== id) {
      dispatch(getAchievementProgramsByIdLevel({ id, returnProducts: true }));
    }
  }

  reloadLevels = () => {
    const { programId: id, dispatch } = this.props;
    dispatch(getAchievementProgramsByIdLevel({ id, returnProducts: true }));
  }

  getSortedLevels() {
    const achieved = [];
    const redeemed = [];
    const other = [];
    this.props.programLevels.forEach(level => {
      if (level.achievedDate) {
        if (level.redeemedDate) {
          redeemed.push(level);
        } else {
          achieved.push(level);
        }
      } else {
        other.push(level);
      }
    });
    return { achieved, redeemed, other };
  }

  render() {
    const {
      earnedTitle, earnedText,
      achievedText, achievedTitle,
      othersText, othersTitle,
      showProductDetails, showOrderDetails
    } = this.props;
    const { achieved, redeemed, other } = this.getSortedLevels();
    return (
      <React.Fragment>
        <AchievementLevelsList
          levels={[...redeemed, ...achieved, ...other]}
        />
        <AchievementRewards
          levels={achieved}
          achieved={true}
          title={earnedTitle}
          text={earnedText}
        />
        <AchievementRewards
          levels={redeemed.slice(0, 2)}
          redeemed={true}
          title={achievedTitle}
          text={achievedText}
        />
        <AchievementRewards
          levels={other}
          title={othersTitle}
          text={othersText}
        />
        {showProductDetails && <ProductDetailsModal reloadLevels={this.reloadLevels} />}
        {showOrderDetails && <OrderDetailsModal />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  programLevels: getAchievementProgramLevels(state),
  showProductDetails: getProductDetailsModalVisibility(state),
  showOrderDetails: getOrderDetailsModalVisibility(state),
});

const mapResourceToProps = (getResource) => ({
  earnedTitle: getResource('achievement.programLanding.levels.earnedTitle', 'You\'ve earned it!'),
  earnedText: getResource('achievement.programLanding.levels.earnedText', ''),
  achievedTitle: getResource('achievement.programLanding.levels.achievedTitle', 'Wow! You\'re on fire.'),
  achievedText: getResource('achievement.programLanding.levels.achievedText', ''),
  othersTitle: getResource('achievement.programLanding.levels.othersTitle', 'Get excited!'),
  othersText: getResource('achievement.programLanding.levels.othersText', ''),
});

export default register('rsv8-achievement/AchievementLevels')(
  connect(mapStateToProps),
  withRouter,
  withContent(),
  withResource(mapResourceToProps)
)(AchievementLevels) as any;
