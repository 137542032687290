import * as React from 'react';
import { register, withResource } from 'xcel-react-core';

class PageTitle extends React.Component<any> {
  componentDidMount() {
    document.title = this.props.title;
  }
  componentDidUpdate() {
    document.title = this.props.title;
  }
  render() {
    return null;
  }
}

const mapResources = (getResource) => {
  return {
    title: getResource('pageTitle', 'RewardStation')
  };
};

export default register('rsv8-app/PageTitle')(withResource(mapResources))(PageTitle);
