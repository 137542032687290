import { pendingApprovalSelectors, approvalHistorySelectors } from './nomination';
import { whereEq } from 'ramda';

export const getPendingApprovals = (
  state: any,
  query: Partial<ReturnType<typeof pendingApprovalSelectors.selectOne>>
) => pendingApprovalSelectors.searchMany(state, whereEq(query)) || [];

export const getApprovalHistory = (state: any, query: Partial<ReturnType<typeof approvalHistorySelectors.selectOne>>) =>
  approvalHistorySelectors.searchMany(state, whereEq(query)) || [];
