import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Grid,
  HeaderThree,
  Image,
  Link
  } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import { CountBadge } from '../../components/index';

const CompleteNominationsButton = (props) => <Link {...props} />;

export interface CompleteNominationsProps {
  resource?: {
    completeUnfinishedNominationsButtonText: string;
    completeNominationsButtonUrl: string;
  };
  unfinishedNominationsCount?: number;
  onClick: () => any;
}

const mapResourceToProps = (getResource) => ({
  resource: {
    completeUnfinishedNominationsButtonText: getResource(
      'recognize.completeUnfinishedNominationsButtonText',
      'Complete Unfinished Nominations'
    ),
    completeNominationsButtonUrl: getResource('recognize.completeNominationsButtonUrl', '/nominations/activity')
  }
});

const mapState = (state) => ({
  unfinishedNominationsCount: state.nominationDashboard.unfinishedNominationsCount
});

const mapDispatch = (dispatch) => ({});

const CompleteNominations: React.StatelessComponent<CompleteNominationsProps> = (props) => (
  <React.Fragment>
    <CompleteNominationsButton
      href={props.resource.completeNominationsButtonUrl}
      variationName="complete-unfinished-nominations"
    >
      <Grid display="flex" alignItems="center" fluid="true">
        <HeaderThree variationName="padding-right">
          {props.resource.completeUnfinishedNominationsButtonText}
        </HeaderThree>
        {props.unfinishedNominationsCount ? <CountBadge>{props.unfinishedNominationsCount}</CountBadge> : null}
        <Image themeVariation="right-arrow" />
      </Grid>
    </CompleteNominationsButton>
  </React.Fragment>
);

export default compose(
  withResource(mapResourceToProps),
  connect(
    mapState,
    mapDispatch
  )
)(CompleteNominations as any) as React.SFC<CompleteNominationsProps>;
