import { nominationApiActions } from './nominationApiActions';

export type GetNominationApplicationRequestType = {
  nomineeId?: number | undefined;
};

export const getNominationApplication = (params: GetNominationApplicationRequestType) => async (dispatch, getState) => {
  await dispatch(nominationApiActions.getApplication(params, { jsonApi: true }));
};

export default getNominationApplication;