import { Text } from 'rsv8-components';
import { Button } from 'rsv8-components';
import styled from 'styled-components';

export const RewardCardContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 300px;
  border-radius: 20px;
  padding: 3rem 2rem 2rem;
  text-align: center;
  position: relative;
  min-height: 300px;
  &:hover {
    background: #ffffff;
    & > footer > button {
      display: block;
    }
  }
  @media screen and (max-width: 768px) {
    background: #ffffff;
    & > footer > button {
      display: block;
    }
  }
`;

export const ProductName = styled(Text)`
  color: #000;
  display: block;
  padding-bottom: 10px;
`;

export const Details = styled(Button)`
  display: none;
`;

export const CardFooter = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  width: 100%;
  & > a {
    & > div {
      width: 32px;
      height: 32px;
    }
    & + button {
      margin-left: 10px;
    }
  }
`;

export const ProductContainer = styled.div`
  width: 100%;
`;

export const CardImage = styled.div`
  background-image: url(${(props: any) => props.imageurl});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 120px;
  width: 170px;
` as any;

export const ImageContainer = styled.div`
  margin: 0 auto 1.5rem;
`;
