import * as React from 'react';
import { ResponsiveEmbed } from 'react-bootstrap';
import styled from 'styled-components';
import { assetService } from 'xcel-asset-service';
import { register, withContent, withTheme } from 'xcel-react-core';
import ModalAnchoredContext from '../Modal/ModalAnchoredContext';

export type Props = {
  videoId: string;
  playerType: string;
  poster?: string;
  playButtonImage?: string;
};

const PlayButton = styled.div`
  position: absolute;
  display: block;
  z-index: 1;
  top: 50%;
  left: 50%;
  opacity: 50%;
  transform: translate(-50%, -50%);
  img {
    width: 100px;
    height: 100px;
  }
`;

const VideoContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

class VideoPlayer extends React.Component<Props> {
  static contextType = ModalAnchoredContext;
  state = { videoPlaying: false, videoReady: false };
  private myRef: any = React.createRef();

  componentDidMount() {
    if (this.context) {
      this.myRef && this.myRef.current.play();
    }
    this.props.playButtonImage &&
      document.getElementById('video-player').addEventListener('canplaythrough', () => {
        this.setState({ videoReady: true });
      });
  }

  toggleVideoPlaying = () => {
    this.setState({ videoPlaying: !this.state.videoPlaying });
  };

  handleVideoPlay = () => {
    (document.getElementById('video-player') as any).play();
  };
  render() {
    const { videoId, playerType, playButtonImage, poster, ...rest } = this.props;

    switch (playerType) {
      case 'youtube':
        return (
          <div>
            <div {...rest}>
              <ResponsiveEmbed a16by9={true}>
                <iframe src={`https://www.youtube.com/embed/${videoId}`} allowFullScreen={true} />
              </ResponsiveEmbed>
            </div>
          </div>
        );
      case 'vimeo':
        return (
          <div {...rest}>
            <ResponsiveEmbed a16by9={true}>
              <iframe src={`https://player.vimeo.com/video/${videoId}`} allowFullScreen={true} />
            </ResponsiveEmbed>
          </div>
        );
      case 's3':
        return (
          <VideoContainer {...rest}>
            {!this.state.videoPlaying &&
              this.state.videoReady &&
              playButtonImage && (
                <PlayButton onClick={() => this.handleVideoPlay()}>
                  <img src={assetService.getImage(playButtonImage)} />
                </PlayButton>
              )}
            <ResponsiveEmbed a16by9={true}>
              <video
                id="video-player"
                controls={true}
                ref={this.myRef}
                poster={assetService.getImage(poster)}
                onPlay={() => this.toggleVideoPlaying()}
                onPause={() => this.toggleVideoPlaying()}
                style={{ objectFit: 'fill' }}
              >
                <source src={videoId} type="video/mp4" />
              </video>
            </ResponsiveEmbed>
          </VideoContainer>
        );
      default:
        return <p>insert video ID or URL</p>;
    }
  }
}

const mapContentToProps = (getContent) => ({
  videoId: getContent('videoId', { type: 'none' }),
  playerType: getContent('playerType', { type: 'none' }),
  poster: getContent('poster', { type: 'none', label: 'Initial Display Image' }),
  playButtonImage: getContent('playButtonImage', { type: 'none', label: 'Play Button Image' })
});
export default register('rsv8-components/VideoPlayer')(withContent(mapContentToProps), withTheme())(VideoPlayer);
export { VideoPlayer };
