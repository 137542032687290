import * as events from '../../../events';
import { IndividualNomination } from '../../../../types';
import { ensureArray } from 'xcel-util';

const selectCustomFields = (fields = {}): IndividualNomination.Action => {
  return {
    type: events.SELECT_CUSTOM_FIELDS,
    payload: Object.keys(fields).map((k) => ({ id: k, values: ensureArray(fields[k]) }))
  };
};

export default selectCustomFields;
