import { getEnvironment } from 'xcel-env';

const Pendo = ({clientId, clientName, ...rest}) => {
  const environment = getEnvironment(location.host);
  const { firstName, lastName, emailAddress, id } = rest.user;
  (window as any).pendo.initialize({
    visitor: {
      id: `${environment} - ${id}`, // Required if user is logged in
      email: `${emailAddress}`, // Recommended if using Pendo Feedback, or NPS Email
      full_name: `${firstName} ${lastName}`, // Recommended if using Pendo Feedback
    },

    account: {
      id: `${clientId}`, // Highly recommended
      name: `${clientName}` 
    },
  });
};

export const UpdatePendo = (pendoValue: string, value: string) => {

  (window as any).pendo.updateOptions({
      visitor: {
        [pendoValue]: `${value}`
      }
  })
}

export default Pendo