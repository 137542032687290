import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Redirect, Route } from "react-router-dom";
import { getConfigurationProperty } from "xcel-config";
import { createTypeSelector } from "xcel-redux-orm";
import authPath from "../paths/Auth";
import { getAuth, getUser, getClient, getClientId } from "../redux/selectors";
import Pendo from "../../components/Pendo";

const clientSelector = createTypeSelector("clientModel");
let AuthRoute = ({
  auth,
  user,
  client,
  clientTest,
  clientId,
  component,
  render,
  unAuthorizedRedirectPath = "/login",
  router,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      const Component = component || render;
      if (Component === undefined) {
        throw Error("AuthRoute was passed a component that was undefined, and failed to render");
      }
      if (!auth.isValid || auth.isAnonymous) {
        if (auth.isSSO && client && client.loginURL) {
          window.location = client.loginURL;
          return null;
        }
        // user isn't logged in
        let identityServerConfigData = getConfigurationProperty(["identityServerConfigData"]) as any;
        return (
          <Redirect
            to={{
              pathname: identityServerConfigData ? "/oauth" : unAuthorizedRedirectPath,
              state: { from: props.location }
            }}
          />
        );
      } else if (auth.isValid && user.mustResetPassword) {
        return (
          <Redirect
            to={{
              pathname: authPath.confirmPassword,
              state: {
                from: props.location
              }
            }}
          />
        );
      } else if (user.hasConsented === false && user.tokenType !== "AdminUser" && user.clientId !== 0) {
        // user hasn't consented
        return (
          <Redirect
            to={{
              pathname: "/consent",
              state: { from: props.location }
            }}
          />
        );
      } else {
        // Initialize Pendo
        const pendoEnabled = getConfigurationProperty(["clients", clientId, "enablePendo"]);
        if (pendoEnabled && client) {
          const clientName = client.name;
          Pendo({ user, clientId, clientName });
        }
        // End of Pendo Snippet
        // both consented and logged inw
        return <Component {...props} />;
      }
    }}
  />
);

const mapState = (state) => ({
  auth: getAuth(state),
  user: getUser(state),
  clientId: getClientId(state),
  client: clientSelector.selectMany(state)[0],
  clientTest: getClient(state)
});
const mapDispatch = (dispatch) => ({});
export default withRouter(connect(
  mapState,
  mapDispatch
)(AuthRoute) as any) as any;
