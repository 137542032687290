import styled from 'styled-components';
// import DragHandle from './DragHandle';
// import Toolbar from './Toolbar';
const DragContainer = styled.span`
  transition: all 200ms;
  position: static;

  min-width: 15px;
  ${(props: any) => {
    return (
      props.over &&
      `
        & > span > * {
        transition: opacity 0 200ms padding 0 200ms border 200ms;
        outline: 5px solid #B4BD38 !important;
        z-index: 999;
        position:static;
        }
      `
    );
  }};
` as any;

export default DragContainer;
/*

& > span > * {
          box-shadow: -5px 0 0 #1D89E7 !important;
        }

        */
