import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators, compose } from 'redux';
import { ReactField } from 'rsv8-forms';
import { withResource } from 'xcel-react-core';
import { toggleValue } from 'xcel-util';
import { FieldContainer } from '../../../../components/Label';
import PointRangeFilterError from '../../../../components/PointRangeSearch/PointRangeFilterError';
import PointRangeSearch from '../../../../components/PointRangeSearch/PointRangeSearch';
import { categorySearch } from '../../../../redux/actions';
import {
  BrandFilterContainer,
  BrandHeading,
  CategoryFilterContainer,
  ClearRangeButton,
  Divider,
  FacetFilterContainer,
  FilterHeading,
  FilterTitle,
  PointRangeContainer,
  PointValueFilterContainer,
  ResetFilterButton,
  ShowAllButton,
  ViewMoreCategoriesIcon
  } from '../../styles';
import CategoryFilter from '../Filters/Category/Category';

export interface FilterColumnProps {
  brandFilters: any;
  brandOptions: any;
  categoryFilters: any;
  checkedBrands: any;
  displayError: any;
  exposedCategories: any;
  handleBrandChange: any;
  handleMaxChange: any;
  handleMinChange: any;
  checkedCategories: any;
  handleSubmit: any;
  resetFilters: any;
  showCategoriesText: any;
  showMoreBrands: any;
  toggleBrandModal: any;
  toggleCategoryModal: any;
  actions: {
    categorySearch: any;
  };
}

class FilterColumn extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      currentlyCheckedCategories: []
    };
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.checkedCategories !== prevProps.checkedCategories) {
      this.setState({ currentlyCheckedCategories: this.props.checkedCategories });
    }
  }

  categorySearch = (id) => {
    this.setState(
      { currentlyCheckedCategories: toggleValue(id.toString(), this.state.currentlyCheckedCategories) },
      () => {
        this.props.actions.categorySearch(this.state.currentlyCheckedCategories, this.props.history);
      }
    );
  };

  renderCategories = () => {
    const {
      exposedCategories,
      allCategoriesPreLabel,
      allCategoriesPostLabel,
      showCategoriesText,
      categoryFilterTitle,
      toggleCategoryModal,
      categoryFilters
    } = this.props;

    return (
      <CategoryFilterContainer>
        <FilterHeading>{categoryFilterTitle}</FilterHeading>
        <CategoryFilter
          categories={exposedCategories}
          changeCategory={this.categorySearch}
          currentlyCheckedCategories={this.state.currentlyCheckedCategories}
        />
        {showCategoriesText && (
          <ShowAllButton themeVariation="primary-link" onClick={toggleCategoryModal}>
            <ViewMoreCategoriesIcon name="angle-down" />
            {`${allCategoriesPreLabel} ${categoryFilters.length} ${allCategoriesPostLabel}`}
          </ShowAllButton>
        )}
      </CategoryFilterContainer>
    );
  };

  renderPointRange = () => {
    const {
      displayError,
      handleSubmit,
      pointValueButtonLabel,
      minValueLabel,
      maxValueLabel,
      pointValueTitle,
      handleMaxChange,
      maxVal,
      minVal,
      handleMinChange,
      handleRangeClear,
      clearButtonLabel
    } = this.props;

    return (
      <PointValueFilterContainer>
        <FilterHeading>{pointValueTitle}</FilterHeading>
        {displayError && <PointRangeFilterError />}
        <PointRangeContainer>
          <PointRangeSearch
            isFilter={true}
            displayError={displayError}
            handleSubmit={handleSubmit}
            handleMaxChange={handleMaxChange}
            handleMinChange={handleMinChange}
            buttonText={pointValueButtonLabel}
            maxText={maxValueLabel}
            minText={minValueLabel}
            minVal={minVal}
            maxVal={maxVal}
          />
          <ClearRangeButton themeVariation="primary-link" onClick={handleRangeClear}>
            {clearButtonLabel}
          </ClearRangeButton>
        </PointRangeContainer>
      </PointValueFilterContainer>
    );
  };

  renderBrands = () => {
    const {
      brandOptions,
      handleBrandChange,
      checkedBrands,
      showMoreBrands,
      toggleBrandModal,
      brandFilters,
      allBrandsPostLabel,
      brandFilterTitle,
      allBrandsPreLabel
    } = this.props;

    return (
      <BrandFilterContainer>
        <BrandHeading>{brandFilterTitle}</BrandHeading>
        {brandOptions && (
          <FieldContainer>
            <ReactField
              component={'checkbox-group'}
              name="brandFilters"
              options={brandOptions
                .sort((a, b) => {
                  const textA = a.filterLabel.toUpperCase();
                  const textB = b.filterLabel.toUpperCase();
                  return textA < textB ? -1 : textA > textB ? 1 : 0;
                })
                .slice(0, 7)}
              onChange={handleBrandChange}
              value={checkedBrands}
            />
          </FieldContainer>
        )}
        {showMoreBrands && (
          <ShowAllButton themeVariation="primary-link" onClick={toggleBrandModal}>
            {`${allBrandsPreLabel} ${brandFilters.length} ${allBrandsPostLabel}`}
          </ShowAllButton>
        )}
      </BrandFilterContainer>
    );
  };

  render() {
    const { resetFilters, resetFiltersLabel, filterByLabel } = this.props;

    return (
      <FacetFilterContainer>
        <FilterTitle>{filterByLabel}</FilterTitle>
        <Divider />
        {this.renderCategories()}
        <Divider />
        {this.renderPointRange()}
        <Divider />
        {this.renderBrands()}
        <Divider />
        <ResetFilterButton onClick={resetFilters} themeVariation="primary-link">
          {resetFiltersLabel}
        </ResetFilterButton>
      </FacetFilterContainer>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({});

const mapResourceToProps = (getResource) => ({
  resetFiltersLabel: getResource('catalog.search.resetFiltersLabel', 'Reset Filters'),
  filterByLabel: getResource('catalog.search.filterByLabel', 'Filter By'),
  allBrandsPreLabel: getResource('catalog.search.allBrandsPreLabel', 'View All'),
  allBrandsPostLabel: getResource('catalog.search.allBrandsPostLabel', 'Brands'),
  brandFilterTitle: getResource('catalog.search.brandFilterTitle', 'Brand'),
  pointValueTitle: getResource('catalog.search.pointValueTitle', 'Point Value'),
  categoryFilterTitle: getResource('catalog.search.categoryFilterTitle', 'Category'),
  allCategoriesPreLabel: getResource('catalog.search.allCategoriesPreLabel', 'View All'),
  allCategoriesPostLabel: getResource('catalog.search.allCategoriesPostLabel', 'Categories'),
  maxValueLabel: getResource('catalog.search.maxPointSearchLabel', 'To'),
  minValueLabel: getResource('catalog.search.minPointSearchLabel', 'From'),
  pointValueButtonLabel: getResource('catalog.search.pointValueButtonLabel', 'View'),
  clearButtonLabel: getResource('catalog.search.clearButtonLabel', 'Clear')
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      categorySearch
    },
    dispatch
  )
});

export default withRouter(
  compose<any>(
    connect(
      mapStateToProps,
      mapDispatchToProps
    ),
    withResource(mapResourceToProps)
  )(FilterColumn)
) as any;
