import * as React from 'react';
import { SessionExpireWarning } from 'rsv8-auth';
import { Toastify } from 'rsv8-components';
import styled from 'styled-components';
import { RouteMatch } from 'xcel-react-core';
import { Navigation, PrimaryMenu } from '../components';
import { LayoutProps } from './types';

const MainContainer = styled.div`
  margin: auto;
  min-height: 85vh;
  @media (max-width: 575px) {
    min-height: 93vh;
  }
  @media (max-width: 768px) {
    min-height: 93vh;
  }
`;

const NoFooterLayout = ({ children, ...rest }: LayoutProps) => {
  return (
    <RouteMatch>
      <div {...rest}>
        <SessionExpireWarning />
        <PrimaryMenu />
        <Navigation />
        <Toastify hideProgressBar={true} autoClose={2500} />
        <MainContainer>{children}</MainContainer>
      </div>
    </RouteMatch>
  );
};

export default NoFooterLayout;
