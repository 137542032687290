import { HeaderTwo, Link } from 'rsv8-components';
import styled from 'styled-components';

export const DepartmentLink = styled(Link)`
  color: #4c4f4f;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 15px;
  padding-top: 31px;

  &:active,
  &:focus,
  &:hover {
    color: #4c4f4f;
    outline: none;
    text-decoration: none;
  }
` as any;

export const DepartmentHeading = styled(HeaderTwo)`
  margin-bottom: 55px;
` as any;

export const SubCategoryList = styled.ul`
  list-style-type: none;
  margin-bottom: 0;
  padding-bottom: 35px;
  padding-left: 0;
` as any;

export const SubCategory = styled.li`` as any;

export const SubCategoryLink = styled(Link)`
  color: #4c4f4f;
  font-size: 14px;
  line-height: 2.29;

  &:active,
  &:focus,
  &:hover {
    color: #4c4f4f;
    outline: none;
    text-decoration: none;
  }
` as any;

export const DepartmentCard = styled.ul`
  box-shadow: 0 2px 17px 2px rgba(28, 39, 76, 0.09);
  list-style-type: none;
  margin-bottom: 25px;
  padding-left: 28px;
` as any;

export const Department = styled.li`
  padding-top: 30px;
  padding-bottom: 20px;
` as any;
