import { connect } from 'react-redux';
import clientSelector from '../redux/selectors/client';

const mapClientToProps = (state) => ({
  client: clientSelector.getActive(state)
});

export default connect(
  mapClientToProps,
  null
) as any;
