import * as events from './events';
export default function notificationsReducer(state, action) {  
  state = state || {
    notifications: {}
  };
  switch (action.type) {
    case events.NOTIFICATIONS_GET: {      
      return {
        ...state,
        notifications: action.payload
      };
    }   

    default: {
      return state;
    }
  }
}
