import * as React from 'react';
import { assetService } from 'xcel-asset-service';
import * as styles from './style';

interface Props {
  chartWidgetSize: number;
  color: string;
  isDisplayBar: boolean;
}

export const GoalAchieved: React.FC<Props> = ({
  chartWidgetSize,
  color,
  isDisplayBar
}) => (
  <styles.GoalsLeftContainer className="goal-achieved" color={color}>
    <styles.GoalsCheckmark src={assetService.getImage('icons/checkmark.svg')} />

    {isDisplayBar && <styles.CompletedBar color="white" width={`${chartWidgetSize - 10 * 2}px`} />}
  </styles.GoalsLeftContainer>
)