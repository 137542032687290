import * as React from 'react';
import { FormControl } from 'react-bootstrap';
import { Text } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import {
  SearchResultsHeading,
  SortByContainer,
  SortByFormGroup,
  TotalResultsContainer
  } from '../styles';

export interface HeadingRowProps {
  categorySearchQuery: { name: string };
  changeSort: (v: any) => void;
  searchSort: string;
  searchString: string;
  totalResults: number;
  searchResultsTitle: string;
  searchResultsForTitle: string;
  totalResultsLabel: string;
  sortByTitle: string;
  popularSortLabel: string;
  lowHighSortLabel: string;
  highLowSortLabel: string;
}

class HeadingRow extends React.Component<HeadingRowProps, any> {
  calculateTitle = () => {
    const { searchString, categorySearchQuery, searchResultsForTitle } = this.props;

    if (searchString) {
      return ` ${searchResultsForTitle} "${searchString.charAt(0).toUpperCase() + searchString.substr(1)}"`;
    } else if (
      categorySearchQuery &&
      !Array.isArray(categorySearchQuery) &&
      Object.keys(categorySearchQuery).length > 0
    ) {
      return ` ${searchResultsForTitle} "${categorySearchQuery.name}"`;
    } else {
      return null;
    }
  };

  render() {
    const {
      changeSort,
      searchSort,
      totalResults,
      highLowSortLabel,
      lowHighSortLabel,
      searchResultsTitle,
      popularSortLabel,
      sortByTitle,
      totalResultsLabel
    } = this.props;

    return (
      <React.Fragment>
        <SearchResultsHeading>
          {searchResultsTitle}
          {this.calculateTitle()}
        </SearchResultsHeading>
        {totalResults && <TotalResultsContainer>{`${totalResults} ${totalResultsLabel}`}</TotalResultsContainer>}
        <SortByContainer className="hidden-xs hidden-sm">
          <div style={{ marginTop: '5px' }}>
            <Text>{sortByTitle}</Text>
          </div>
          <SortByFormGroup>
            <FormControl
              componentClass="select"
              defaultValue={searchSort ? searchSort : 'none'}
              placeholder="select"
              name="sortBySelect"
              onChange={changeSort}
            >
              <option value="none">{popularSortLabel}</option>
              <option value="Asc">{lowHighSortLabel}</option>
              <option value="Desc">{highLowSortLabel}</option>
            </FormControl>
          </SortByFormGroup>
        </SortByContainer>
      </React.Fragment>
    );
  }
}

const mapResourceToProps = (getResource) => ({
  searchResultsTitle: getResource('catalog.search.searchResultsTitle', 'Search Results'),
  searchResultsForTitle: getResource('catalog.search.searchResultsForTitle', 'for'),
  totalResultsLabel: getResource('catalog.search.totalResultsLabel', 'Results'),
  sortByTitle: getResource('catalog.search.sortByTitle', 'Sort By'),
  popularSortLabel: getResource('catalog.search.popularSortLabel', 'Popular'),
  lowHighSortLabel: getResource('catalog.search.lowHighSortLabel', 'Point Value: Low to High'),
  highLowSortLabel: getResource('catalog.search.highLowSortLabel', 'Point Value: High to Low')
});

export default withResource(mapResourceToProps)(HeadingRow);
