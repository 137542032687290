import * as React from 'react';
import styled from 'styled-components';
import Card from './Card';
const ComponentCardContainer = styled(Card)`
  overflow: hidden;
  margin: 10px;
`;
const ComponentTitle = styled.h4`
  position: absolute;
  bottom: 0;
  z-index: 1;
  text-align: center;
  font-size: 12px;
  width: 100%;
  background-color: white;
  margin: 0px;
  padding: 2px 0px;
`;
const ComponentBody = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  vertical-align: text-top;
  min-width: 130px;
  text-align: center;

  transform: translateX(-50%) translateY(-50%);
`;
const ComponentOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
`;
const ComponentCard = ({ title, children, ...rest }) => {
  return (
    <ComponentCardContainer {...rest} width={'200px'} height={'200px'} borderRadius={'5px'}>
      <ComponentOverlay />
      <ComponentBody>{children}</ComponentBody>
      <ComponentTitle>{title}</ComponentTitle>
    </ComponentCardContainer>
  );
};
export default ComponentCard;
