import * as React from 'react';
import { Checkbox } from 'react-bootstrap';
import { Text } from 'rsv8-components';
import { Forms } from '../../types';
import addValidationState from '../HOC/addValidationState';
import { TooltipContainer } from './TooltipContainer';

const checkboxOnChange = (onChange) => (e) => {
  e.target = { ...e.target, value: e.target.checked };
  return onChange(e);
};

const Input: Forms.Inputs.Checkbox = (props) => {
  const CheckboxComponent = props.checkboxRenderer || Checkbox;

  return (
    <CheckboxComponent {...props.input} checked={props.input.value} onChange={checkboxOnChange(props.input.onChange)}>
      <Text>{props.label}</Text>
      {props.helpText && <TooltipContainer tooltipChildren={props.helpText} />}
    </CheckboxComponent>
  );
};

export default addValidationState(Input);
