import * as React from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import timeline from "highcharts/modules/timeline"
import gantt from "highcharts/modules/gantt"
timeline(Highcharts);
gantt(Highcharts);
// import highchartsGantt from "highcharts/modules/gantt";
import { connect } from "react-redux";
import {
  register,
  withTheme,
  withContent
} from "xcel-react-core";
import {
  mapDispatchToProps,
  mapContentToProps,
  createProgressBar,
} from "./Util/profileProgressBarFunctions"; 
// highchartsGantt(Highcharts);

export interface Props {
  getUserProfile: Function;
  myStatuses: Array<string>;
  Goals: Array<Object>;
  profileProgressBarContent: Array<object>;
  myProfileData: Array<object>;
  mobileStyle: object;
  profileGoalsBarSize:string;
  profileAwardText:string;
  CompletedColor:string;
  UnCompletedColor:string;
  CircleTextColor:string;
  flipped:string;
  progressBarOptions:Object,

}
/**
 *
 * Creates ProgessBar Component
 * @export
 * @class profileProgressBar
 * @extends {React.Component<Props>}
 */
export class ProfileProgressBar extends React.Component<Props> {
  state = {
    myProfileData:null,
    mobileStyle: null,
    profileProgressBarContent:null,
    profileGoalsBarSize:null,
    profileAwardText:null,
    CircleTextColor:null,
    progressBarOptions:null,
    flipped:'true',
  };

  CheckforMobileView(other: any) {
    if (this.props.flipped === 'true') {
      // alert('its true');
     // alert(JSON.stringify(other.xAxis.categories))
     other.chart.width = 550;
     other.chart.height = 450;
      other.chart.inverted = true;
      other.xAxis.labels.y =50;
      other.xAxis.labels.x = 350;
      // this.setState({progressBarOptions:other});

    }
  }
  async componentWillMount() {
    try {
      await this.props.getUserProfile().then((response:any)=> {this.setState({myProfileData:response.data})});

    } catch(error) {
      console.log(error);
    }
var progressBarOptions =createProgressBar(this.props,this.state.myProfileData);
   // if(this.state.myProfileData){
      this.CheckforMobileView(progressBarOptions);
      this.setState({progressBarOptions:progressBarOptions});
   // }
   
     // console.log(Data);
   //  this.setState({progressBar:createProgressBar(this.props)});
}
  render() {
    return (
      <div {...this.props}>
        <HighchartsReact
          highcharts={Highcharts}
          options={this.state.progressBarOptions}
        />
      </div>
    );
  }
}

/* This function is the one that connects everything via redux
 * aka it it maps things to prop values
 */
export default register("rsv8-metrics/profileProgressBar")(
  connect(null, mapDispatchToProps),
  withTheme(),
  withContent(mapContentToProps)
)(ProfileProgressBar);
